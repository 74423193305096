import * as React from "react";
import { observer } from "mobx-react-lite";
import Chart from "chart.js";
import { formatCurrency } from "utils";

interface Props {
    chartType: string;
    data: any;
    options?: any;
}

let chart: any = undefined;

const ChartComponent: React.FC<Props> = observer(({ chartType, data, options }) => {
    const chartRef: any = React.createRef();

    const buildChart = () => {
        const myChartRef = chartRef.current.getContext("2d");

        if (typeof chart !== "undefined") chart.destroy();

        chart = new Chart(myChartRef, {
            type: chartType,
            data,
            options: {
                ...options,
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 3,
                legend: {
                    position: "top",
                    align: "end",
                    labels: {
                        fontFamily: "museo sans",
                        fontColor: "#000000",
                        fontSize: 9,
                        boxWidth: 7,
                        fontWeight: 300,
                        marginTop: -50,
                    },
                },
                tooltips: {
                    callbacks: {
                        title: function() {
                            return null;
                        },
                        label: function(tooltipItem) {
                            return `${formatCurrency(tooltipItem.value || 0)}`;
                        },
                    },
                    titleSpacing: 0,
                    backgroundColor: "#ffffff",
                    bodyAlign: "center",
                    borderColor: "rgba(41, 45, 70, 0.1)",
                    borderWidth: 0,
                    cornerRadius: 4,
                    bodyFontColor: "#22232e",
                    bodyFontFamily: "Roobert",
                    yAlign: "bottom",
                },
            },
        });

        console.log(chart);
    };

    React.useEffect(() => {
        if (data && data.labels && data.datasets) {
            buildChart();
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <div>
            <canvas ref={chartRef} />
        </div>
    );
});

export default ChartComponent;
