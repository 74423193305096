import React from "react";
import { FullQuestion, useApplicationContext } from "services/application";
import { InputContainer, QuestionRowContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";

import styled from "styled-components/macro";
import { Body2 } from "components/typography";
import { useField, useFormikContext } from "formik";

import { clearFieldVerificationRequired } from "../../sectionUtils";

import { ReactComponent as RadioCheckMark } from "assets/circle-white.svg";

interface CheckboxProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

type Props = {
    label: string;
    type: string;
    name: string;
    value: string;
    className?: string;
    parentQuestion?: FullQuestion;
    extraProps?: any;
};

const Checkmark = styled.span`
    margin-left: 20px;
    height: 20px;
    width: 20px;
    border: 2px solid #e5eaef;
    align-self: center;
    flex: 0 0 20px;

    &:after {
        content: "";
        display: none;
        margin-left: 6px;
        margin-top: 2px;
        border: solid white;
    }
    @media (max-width: 700px) {
        margin-left: 10px;
    }
`;

const RadioMark = styled(RadioCheckMark)`
    align-self: center;
    margin-left: 15px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute;
        top: 28%;
    }
`;

const Label = styled.span`
    ${Body2}
    font-family: Arial;
    color: #22232e;
    font-size: 16px;
    display: block;
    margin-left: 10px;
    align-self: center;
    margin-right: 10px;
    font-weight: 500;
    line-height: 160%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute;
        left: 35px;
        top: 28%;
    }
`;

const DivContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #e5eaef;
    box-sizing: border-box;
`;

const Input = styled.input`
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &[type="checkbox"] ~ ${Checkmark} {
        &:after {
            width: 5px;
            height: 10px;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    &:checked ~ ${Label} {
        opacity: 1;
    }

    &:checked ~ ${Checkmark} {
        background: ${(props) => props.theme.colors.accentColor};
        border-color: ${(props) => props.theme.colors.accentColor};
        &:after {
            display: block;
        }
    }

    &:checked ~ ${RadioMark} {
        & > .radioOutline {
            fill: ${(props) => props.theme.colors.accentColor};
        }
    }
`;

const Container = styled.label`
    position: relative;
    display: inline-flex;
    min-height: 54px;
    height: fit-content;
    cursor: pointer;
    user-select: none;

    &.on-error > ${DivContainer} {
        border: 2px solid #eb0f21 !important;
    }

    &.on-error > ${RadioMark} {
        & > .radioOutline {
            fill: #eb0f21;
        }
    }

    &.on-error > ${Label} {
        opacity: 1;
        color: #eb0f21;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
        height: 54px;
        width: 100%;
    }
`;

export const RadioCheckButton: React.FC<Props> = ({
    label,
    type,
    name,
    value,
    className = "",
    parentQuestion = {},
    extraProps = {},
    ...rest
}) => {
    const { setFieldValue, getFieldMeta } = useFormikContext<string>();
    const fieldName = type === "checkbox" ? `${name}.${value}` : name;
    const { value: formikValue } = getFieldMeta(fieldName);

    const { sections: _sec, setSections } = useApplicationContext();
    const sections = _sec as NonNullable<typeof _sec>;

    const { questionsOnError, setQuestionsOnError } = extraProps;

    const [fieldProps, meta] = useField(fieldName);
    const onClick = (e: any) => {
        clearFieldVerificationRequired(
            parentQuestion?.id,
            sections,
            setSections,
            setQuestionsOnError,
            questionsOnError,
        );
        if (type === "radio" && formikValue === value) {
            e.target.checked = false;
            setFieldValue(fieldName, null);
            e.stopPropagation();
        }
    };

    return (
        <Container
            className={`label-container ${className} ${
                !!meta.touched && !!meta.error ? "on-error" : ""
            }`}
            {...rest}
        >
            <Input
                type={type}
                {...fieldProps}
                value={value}
                checked={formikValue === value}
                onClick={onClick}
            />
            {type === "checkbox" && <Checkmark />}
            {type === "radio" && <RadioMark />}
            <Label>{label}</Label>
            <DivContainer />
        </Container>
    );
};

const Checkbox: React.SFC<CheckboxProps> = ({ question, extraProps = {} }) => {
    const fieldName = `${question.id}__${extraProps?.questionIndex || 0}`;
    const { getFieldMeta } = useFormikContext<string>();
    const { questionsOnError: contextQuestionsOnError } = useApplicationContext();
    const { questionsOnError: extraQuestionsOnError } = extraProps;
    const meta = getFieldMeta(fieldName);

    let errorClassName = "";
    if (contextQuestionsOnError) {
        errorClassName = contextQuestionsOnError?.includes(question.id) ? "on-error" : "";
    } else if (extraQuestionsOnError) {
        if (meta.touched && extraQuestionsOnError.includes(question.id)) {
            errorClassName = "on-error";
        }
    }

    return (
        <QuestionRowContainer data-name="QuestionRowContainer">
            <QuestionTitleRender question={question} />
            <InputContainer id={"q-" + fieldName}>
                {question.options?.map((o) => (
                    <RadioCheckButton
                        key={o.id}
                        value={o.id as string}
                        type={question.type}
                        name={fieldName}
                        label={o.text}
                        parentQuestion={question}
                        className={`question-${question.id} ${errorClassName} ${
                            question.required ? "field-required" : ""
                        }`}
                    />
                ))}
            </InputContainer>
        </QuestionRowContainer>
    );
};

export default React.memo(Checkbox);
