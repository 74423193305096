import { types, applySnapshot, flow } from "mobx-state-tree";
import { Broker } from "CounterpartApiTypes";
import * as auth from "../auth";

import { AlertsStore } from "./alerts";
import { getAdditionalStartInfo } from "services/application";

export const UserInfoStore = types
    .model("UserInfoStore", {
        userID: types.string,
        email: types.maybeNull(types.string),
        firstName: types.string,
        lastName: types.string,
        brokerageName: types.maybeNull(types.string),
        photo: types.maybeNull(types.string),
        brokerAdminOverride: types.maybe(types.boolean),
        adminUser: types.maybe(types.string),
    })
    .volatile(() => ({
        isVPN: false,
    }))
    .views((self) => ({
        get fullName() {
            return `${self.firstName} ${self.lastName}`;
        },
    }))
    .actions((self) => ({
        verifyVPN: flow(function* verifyVPN() {
            try {
                yield getAdditionalStartInfo();
                self.isVPN = true;
            } catch (error) {
                self.isVPN = false;
            }
        }),
    }))
    .actions((self) => ({
        updateInfo(data: Broker) {
            const newData = {
                userID: self.userID,
                brokerageName: data.brokerageName,
                firstName: data.firstName || "",
                lastName: data.lastName || "",
                adminUser: self.adminUser,
                brokerAdminOverride: self.brokerAdminOverride,
                ...data,
            };
            applySnapshot(self, newData);
        },
        loadFromToken(data: auth.DecodeToken | null) {
            if (!data) {
                self.userID = "";
                self.firstName = "";
                self.lastName = "";
            } else {
                self.userID = data.user_id;
                self.firstName = self.firstName || data.firstName || "";
                self.lastName = self.lastName || data.lastName || "";
                self.email = self.email || data.email || "";
                self.photo = self.photo || data.avatar || null;
                self.brokerageName = self.brokerageName || data.brokerageName || null;
                self.brokerAdminOverride = data.brokerAdminOverride ?? false;
                self.adminUser = data.adminUser ?? undefined;
            }
        },
    }))
    .actions((self) => ({
        afterCreate() {
            auth.onAuthStateChange(self.loadFromToken);
            self.verifyVPN();
        },
    }));

export const RootStore = types
    .model("RootStore", {
        userInfo: types.optional(UserInfoStore, { userID: "", firstName: "", lastName: "" }),
        alerts: types.optional(AlertsStore, () => ({ alerts: {} })),
    })
    .actions((self) => ({
        clearUserInfo() {
            self.userInfo.userID = "";
            self.userInfo.firstName = "";
            self.userInfo.lastName = "";
        },
    }));

const storeInstance = RootStore.create({});
(window as any).rootStore = storeInstance;
export type RootStoreType = typeof storeInstance;

export default storeInstance;
