export const DEFAULT_PAGE_TEXT = {
    title: "Subjectivities",
    description1:
        "Thank you for submitting an application to Counterpart. We are excited about the opportunity to support you with our proprietary products and services.",
    description2:
        "In order to bind coverage, we will first need a little bit more information about the company and a signed application. Please complete the additional questions shown below so that our team can complete our review.",
    requiredDescription:
        "The questions in this section must be completed. Please note that your answers may impact the coverages and premium we can offer.",
    optionalDescription:
        "The questions in this section are optional, but answering can help us more accurately price your quote.",
};

export const SUPPLEMENTAL_PAGE_TEXT = {
    title: "Supplemental Application",
    description2:
        "In order for our team to provide you with a quote, we will first need a little bit more information about the company. Please complete the additional questions shown below so that our team can complete our review.",
} as const;

export type PageTextType = typeof DEFAULT_PAGE_TEXT;
