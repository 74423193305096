// frontend/src/services/generated/users.ts
// Auto Generated File, Do not edit.

import apiClient from "./../apiClient";
import * as apiTypes from "CounterpartApiTypes";
import * as logger from "./../logger";

type RemoveUndefined<T> = T extends undefined ? never : T;

type RemoveUndefinedKeys<T> = T extends {}
    ? {
          [P in keyof T]-?: RemoveUndefined<T[P]>;
      }
    : T;

type ApiReturnType<T> = T extends Array<any> ? RemoveUndefinedKeys<T[0]>[] : RemoveUndefinedKeys<T>;

/**
 * brokerConfirmEmail
 * ---
 * Confirm the Broker email
 *
 *
 * @api POST /api/users/confirm_email/
 *
 * @param {apiTypes.ConfirmEmail} data
 */
export async function brokerConfirmEmail(
    data: apiTypes.ConfirmEmail,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/confirm_email/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "brokerConfirmEmail"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * brokerRegister
 * ---
 * Creates a new Broker
 *
 *
 * @api POST /api/brokers/register/
 *
 * @param {apiTypes.Broker} data
 */
export async function brokerRegister(
    data: apiTypes.Broker,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Broker>> {
    try {
        const url = `/api/brokers/register/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "brokerRegister"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * brokerUpdateProfile
 * ---
 * Updates broker profile
 *
 *
 * @api PUT /api/brokers/{id}/update_profile/
 *
 * @param {apiTypes.Broker} data
 * @param {string} id A UUID string identifying this broker.
 */
export async function brokerUpdateProfile(
    data: apiTypes.Broker,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Broker>> {
    try {
        const url = `/api/brokers/${id}/update_profile/`;
        const resp = await apiClient.put(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "brokerUpdateProfile"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}

/**
 * changePassword
 * ---
 * Change user password
 *
 *
 * @api POST /api/users/change_password/
 *
 * @param {apiTypes.ChangePassword} data
 */
export async function changePassword(
    data: apiTypes.ChangePassword,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/change_password/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "changePassword"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * checkQuickToken
 * ---
 *
 *
 * @api POST /api/brokers/check-quick-token
 *
 * @param {apiTypes.CheckQuickToken} data
 */
export async function checkQuickToken(
    data: apiTypes.CheckQuickToken,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/brokers/check-quick-token`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "checkQuickToken"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * forgotPassword
 * ---
 * Send forgot password email to user
 *
 *
 * @api POST /api/users/forgot_password/
 *
 * @param {apiTypes.ForgotPassword} data
 */
export async function forgotPassword(
    data: apiTypes.ForgotPassword,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/forgot_password/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "forgotPassword"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * getBrokerProfile
 * ---
 * Get Broker information
 *
 *
 * @api GET /api/brokers/profile
 *
 */
export async function getBrokerProfile(apiOptions = {}): Promise<ApiReturnType<apiTypes.Broker>> {
    try {
        const url = `/api/brokers/profile`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getBrokerProfile"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * listBrokerCompanies
 * ---
 *
 *
 * @api GET /api/brokers/companies
 *
 */
export async function listBrokerCompanies(
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.BrokerCompany[]>> {
    try {
        const url = `/api/brokers/companies`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listBrokerCompanies"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * logout
 * ---
 *
 *
 * @api POST /api/users/logout/
 *
 * @param {apiTypes.Logout} data
 */
export async function logout(data: apiTypes.Logout, apiOptions = {}): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/logout/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "logout"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * optInToCounterpartHR
 * ---
 *
 *
 * @api POST /api/users/opt-into-counterpart-hr/
 *
 * @param {apiTypes.OptInToCounterpartHR} data
 */
export async function optInToCounterpartHR(
    data: apiTypes.OptInToCounterpartHR,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/opt-into-counterpart-hr/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "optInToCounterpartHR"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * requestRiskAssessment
 * ---
 *
 *
 * @api POST /api/brokers/companies/request-risk-assessment
 *
 * @param {apiTypes.RequestRiskAssessment} data
 */
export async function requestRiskAssessment(
    data: apiTypes.RequestRiskAssessment,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/brokers/companies/request-risk-assessment`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "requestRiskAssessment"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * setPassword
 * ---
 *
 *
 * @api POST /api/users/set_password/
 *
 * @param {apiTypes.SetPassword} data
 */
export async function setPassword(
    data: apiTypes.SetPassword,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/set_password/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "setPassword"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * uploadAvatar
 * ---
 * Change broker avatar
 *
 *
 * @api POST /api/users/upload_avatar/
 *
 * @param {apiTypes.BrokerAvatarUpload} data
 */
export async function uploadAvatar(
    data: apiTypes.BrokerAvatarUpload,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/upload_avatar/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "uploadAvatar"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * uploadLogo
 * ---
 * Change broker avatar
 *
 *
 * @api POST /api/users/upload_logo/
 *
 * @param {apiTypes.BrokerageLogoUpload} data
 */
export async function uploadLogo(
    data: apiTypes.BrokerageLogoUpload,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/upload_logo/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "uploadLogo"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * validateHRContactToRegisterForZeroHR
 * ---
 *
 *
 * @api POST /api/users/validate-hr-data-for-registration/
 *
 * @param {apiTypes.ValidateHRContactToRegisterForZeroHR} data
 */
export async function validateHRContactToRegisterForZeroHR(
    data: apiTypes.ValidateHRContactToRegisterForZeroHR,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/validate-hr-data-for-registration/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "validateHRContactToRegisterForZeroHR"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}
