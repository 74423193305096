import React, { useCallback, useState } from "react";
import { Modal, Button, Row, Input, FormButton } from "components/common";
import styled from "styled-components/macro";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import { changePassword } from "services/users";
import { ChangePassword } from "CounterpartApiTypes";
import { toast } from "react-toastify";
import { Body1, H2 } from "components/typography";

interface PasswordChangerProps {
    afterSave?: () => any;
}

const Container = styled.div`
    margin-top: -50px;
`;

const List = styled.ul`
    margin: 0;
    padding: 0 0 0 16px;
    margin-bottom: 30px;

    & > li {
        list-style-type: none;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: -16px;
            height: 6px;
            width: 6px;
            background-color: #6E6E6E;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
        }
    }
`;

const StyledRow = styled(Row as any)`
    @media (max-width: 767px) {
        margin: 0 0 20px 0;
    }
`;

const initialValues: ChangePassword = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
};

const _onSubmit = (afterSave?: () => any) => async (
    values: ChangePassword,
    actions: FormikHelpers<ChangePassword>,
) => {
    actions.setSubmitting(true);
    try {
        await changePassword(values).then(() => {
            toast.success("Your password was changed!");
        });
        afterSave && afterSave();
    } catch (error) {
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    } finally {
        actions.setSubmitting(false);
    }
};

type FormFieldProps = {
    onSubmit: (v: ChangePassword, a: FormikHelpers<ChangePassword>) => void;
};

export const ChangePasswordFormFields: React.FC<FormFieldProps> = ({ onSubmit }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting }: FormikProps<ChangePassword>) =>
            (
                <Form>
                    <StyledRow>
                        <Input
                            placeholder="Old Password"
                            name="oldPassword"
                            ariaLabel="password"
                            type="password"
                        />
                    </StyledRow>
                    <StyledRow>
                        <Input
                            placeholder="New Password"
                            name="newPassword"
                            ariaLabel="password"
                            type="password"
                        />
                    </StyledRow>
                    <StyledRow>
                        <Input
                            placeholder="Repeat New Password"
                            name="newPasswordRepeat"
                            ariaLabel="password"
                            type="password"
                        />
                    </StyledRow>
                    <FormButton
                        loading={isSubmitting}
                        disabled={isSubmitting }
                        style={{ height: 50 }}
                        type="submit"
                    >
                        Change Password
                    </FormButton>
                </Form>
            )
        }
    </Formik>
);

const PasswordChanger: React.FC<PasswordChangerProps> = ({ afterSave: _afterSave }) => {
    const [open, setOpen] = useState(false);
    const afterSave = _afterSave ?? (() => setOpen(false));
    const onSubmit = useCallback(_onSubmit(afterSave), [_afterSave, setOpen]);

    return (
        <>
            <Button onClick={() => setOpen(true)}>Change Password</Button>
            {open && (
                <Modal
                    isOpen={true}
                    closeable
                    closeAction={() => setOpen(false)}
                    title=""
                >
                    <Container>
                        <div>
                            <H2>Change Password</H2>
                            <Body1 style={{ marginBottom: 20 }}>
                                In order to <strong>protect your account</strong>, make sure your
                                password:
                            </Body1>
                            <List>
                                <Body1 as="li">Make your password eight characters or longer</Body1>
                                <Body1 as="li">Contains both lowercase and uppercase letters</Body1>
                                <Body1 as="li">Contains at least one numerical character.</Body1>
                                <Body1 as="li">
                                    Contains at least one special character: !@#$%&*().
                                </Body1>
                            </List>
                        </div>
                        <ChangePasswordFormFields onSubmit={onSubmit}></ChangePasswordFormFields>
                    </Container>
                </Modal>
            )}
        </>
    );
};
export default React.memo(PasswordChanger);
