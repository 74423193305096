import React from "react";
import styled from "styled-components/macro";

import { H4, Body2 } from "components/typography";

export interface PageItem {
    title: string;
    requiresLogin: boolean;
    text: string;
    linkText: string;
    icon: any;
    onclick: any;
}

const CardWrapper = styled.div`
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(38, 47, 147, 0.08);
    border-bottom: 2px solid ${(props) => props.theme.colors.primary};
    width: auto;
    padding: 30px 20px;
    transition: ease-in-out all 0.2s;
    user-select: none;
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 980px) {
        width: 33%;
    }
`;

const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-width: 480px) {
        align-items: flex-start;

        & p {
            text-align: left;
            font-weight: 300;
            line-height: 160%;
        }
    }
`;

const StyledLink = styled.a`
    font-weight: bold;
    font-size: 14px;
    padding-top: 14px;
    display: inline-block;
    margin-top: auto;
    &,
    &:hover,
    &:visited {
        text-decoration: none;
    }
`;

const IconWrapper = styled.span`
    svg {
        display: initial;
    }
`;

const LoginLabel = styled.span`
    font-size: 14px;
    color: #a5b2c0;
    white-space: nowrap;
`;

function Card({ item }: { item: PageItem }) {
    return (
        <CardWrapper onClick={item.onclick}>
            <CardBody>
                <H4 style={{ marginBottom: 8, fontSize: 16 }}>
                    {item.title} {item.requiresLogin && <LoginLabel>(requires login)</LoginLabel>}
                </H4>
                <Body2>{item.text}</Body2>
                <StyledLink style={{ color: "#111DA1" }}>
                    {item.linkText}
                    <IconWrapper>{item.icon}</IconWrapper>
                </StyledLink>
            </CardBody>
        </CardWrapper>
    );
}

export default Card;
