import React from "react";
import { FullQuestion, useApplicationContext } from "services/application";
import { InputContainer, QuestionRowContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import { FormikInputField } from "components/common/InputField";
import { useField } from "formik";

import { clearFieldVerificationRequired } from "../../sectionUtils";
import { formatCurrency } from "utils";

interface CurrencyProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

const CURRENCY_REGEX = /^-?[\d,]*$/;
const CURRENCY_ACCEPTED_INPUT_REGEX = /[\d-]/;

const Currency: React.SFC<CurrencyProps> = ({ question, extraProps = {} }) => {
    const fieldName = `${question.id}__${extraProps?.questionIndex || 0}`;

    const { sections: _sec, setSections } = useApplicationContext();
    const { questionsOnError, setQuestionsOnError } = extraProps;
    const sections = _sec as NonNullable<typeof _sec>;
    const [meta, field] = useField(fieldName);

    const classNames = [`question-${question.id}`, question.required && "field-required"];

    const containerClasses = extraProps.extraClass ?? "";

    let value = field.value ?? "";
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
        value = formatCurrency(parsedValue).replace("$", "");
    }

    if (questionsOnError && questionsOnError.includes(question.id)) {
        classNames.push("on-error");
    }
    return (
        <QuestionRowContainer
            className={`${question.type} ${containerClasses}`}
            data-name="QuestionRowContainer"
        >
            <QuestionTitleRender question={question} extraProps={extraProps} />
            <InputContainer>
                <FormikInputField
                    className={classNames.filter(Boolean).join(" ")}
                    name={fieldName}
                    type="text"
                    id={"q-" + fieldName}
                    data-testid={`currency__${fieldName}`}
                    placeholder={question.placeholder}
                    visibleHelper
                    labelHelper="$"
                    helperPosition="prefix"
                    onKeyPress={(evt: any) => {
                        if (!evt.key.match(CURRENCY_ACCEPTED_INPUT_REGEX)) evt.preventDefault();
                    }}
                    value={value}
                    onChange={(e: any) => {
                        if (e.target.value.match(CURRENCY_REGEX)) {
                            clearFieldVerificationRequired(
                                question.id,
                                sections,
                                setSections,
                                setQuestionsOnError,
                                questionsOnError,
                            );
                            e.target.value = e.target.value.replace(/[^0-9-]+/g, "");
                            meta.onChange(e);
                        }
                    }}
                    {...extraProps}
                />
            </InputContainer>
        </QuestionRowContainer>
    );
};

export default React.memo(Currency);
