import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { auth } from "services";
import rootStore from "services/store"
import { getAdditionalStartInfo } from "services/application";

/**
 * When the user hits a route marked as private, we check if he is authenticated
 * and if not redirect the user to the login page.
 */

async function verifyOnVPN(isVPN: any) {
    console.log("Verifing if we are on VPN")
    if(!isVPN) {
        try {
            await getAdditionalStartInfo();
            isVPN = true;
        } catch (error) {
            // Confirmed Not on VPN
        }
    }
    console.log("Verified")
    return isVPN
}

const VPNAuthenticatedComponent: React.SFC<RouteProps> = (props: RouteProps) => {
    const { component, ...rest } = props;
    const Component = component as React.ComponentType<RouteProps>;

    // For Authorized Page Overrides, there is a chance that the VPN check has not run
    const [verified, setVerified] = React.useState(!!auth.getUserID() || rootStore.userInfo.isVPN)
    const [checkedVPNAgain, setCheckedVPNAgain] = React.useState(false);

    if(!verified && !checkedVPNAgain) {
        verifyOnVPN(rootStore.userInfo.isVPN).then((result) => {
            setVerified(result)
            setCheckedVPNAgain(true)
        })
    }

    if(verified || checkedVPNAgain) {
        const render = (props: RouteProps): React.ReactNode =>
        verified ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            );

        return <Route {...rest} render={render} />;
    }
    return (
        <></>
    )
};

export default VPNAuthenticatedComponent;
