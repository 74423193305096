import React from "react";
import responsive from "services/responsive";

import { Field } from "formik";

import styled from "styled-components/macro";
import { Body2 } from "components/typography";

import { TooltipContainer } from "../../counterpart-theme";

import { UncontrolledTooltip } from "reactstrap";

import { ReactComponent as RadioCheckMark } from "assets/circle-white.svg";

type Props = {
    label: string;
    type: string;
    value?: string;
    tooltipText?: string;
    name?: string;
    className?: string;
    // onClick?: any;
    disabled?: boolean;
    checkboxStyle?: any;
    checked?: boolean;
    onChange?: any;
    onClick?: any;
    style?: any;
};

const ModifiedTooltipContainer = styled(TooltipContainer as any)`
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    margin-left: 0;
    padding: 0;
`;

const Tooltip = styled.span`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
`;

const Checkmark = styled.span`
    margin-left: 20px;
    height: 20px;
    width: 20px;
    border: 2px solid #e5eaef;
    align-self: center;
    flex: 0 0 20px;

    &:after {
        content: "";
        display: none;
        margin-left: 6px;
        margin-top: 2px;
        border: solid white;
    }
    @media (max-width: 700px) {
        margin-left: 10px;
    }
`;

const RadioMark = styled(RadioCheckMark)`
    align-self: center;
    margin-left: 15px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute;
        top: 28%;
    }
`;

const Label = styled.span`
    ${Body2}
    display: block;
    margin-left: 10px !important;
    align-self: center;
    margin-right: 10px;
    font-weight: 500;
    line-height: 160%;
    padding: 5px;
    padding-left: 0px;
    font-size: 14px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute;
        left: 35px;
        top: 28%;
    }
`;

const DivContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const Container = styled.label`
    position: relative;
    display: inline-flex;
    height: fit-content;
    cursor: pointer;
    user-select: none;

    &:not(.auto-height) {
        min-height: 40px;
    }

    &:not(.no-border) > ${DivContainer} {
        border: 1px solid #e5eaef;
        box-sizing: border-box;
    }

    &:not(.no-border).on-error > ${DivContainer} {
        border: 1px solid #eb0f21 !important;
    }

    &.left-align > ${Label} {
        align-self: flex-start;
        justify-self: flex-start;
        text-align: left;
        padding-top: 15px;
        margin-left: 0;
        left: 0;
    }

    &.single-check {
        white-space: nowrap;
        min-height: 0px;
        & > ${DivContainer} {
            border: none;
        }
    }

    &.top-align > ${Checkmark}, &.top-align > ${RadioMark}, &.top-align > ${Label} {
        align-self: flex-start;
        justify-self: flex-start;
        padding-top: 2px;
        margin-left: 0;
        left: 0;
        ${responsive.InternetExplorer} & {
            padding-top: 10px;
        }
    }

    &.top-align > ${Checkmark}, &.top-align > ${RadioMark} {
        margin-top: 5px;
    }

    & > input {
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &[type="checkbox"]:not(:disabled) ~ ${Checkmark} {
            &:after {
                width: 5px;
                height: 10px;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }

        &[type="checkbox"]:disabled ~ ${Checkmark} {
            background: #bfcad6;
            border-color: #bfcad6;
            &:after {
                content: "+";
                font-size: 19px;
                font-weight: bold;
                display: block;
                color: white;
                margin-left: 3px;
                margin-top: -8px;
                border: none;
                transform: rotate(45deg);
            }
        }

        &:checked ~ ${Checkmark} {
            background: ${(props) => props.theme.colors.primary};
            border-color: ${(props) => props.theme.colors.primary};
            &:after {
                display: block;
            }
        }

        &:checked ~ ${RadioMark} {
            & > .radioOutline {
                fill: ${(props) => props.theme.colors.primary};
            }
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
        height: 54px;
        width: 100%;
    }
`;

const RadioCheckButton: React.FC<Props> = ({
    label,
    type,
    name,
    disabled,
    tooltipText,
    className = "",
    checkboxStyle,
    ...rest
}) => {
    const tooltipID = `${name}_tooltip`.replace(".", "_");
    return (
        <Container className={`label-container ${className}`} {...rest}>
            {disabled && tooltipText && (
                <ModifiedTooltipContainer>
                    <Tooltip id={`uncontrolled${tooltipID}`} tabIndex={-1} />
                    <UncontrolledTooltip placement="top" target={`uncontrolled${tooltipID}`}>
                        <>{tooltipText}</>
                    </UncontrolledTooltip>
                </ModifiedTooltipContainer>
            )}
            <Field name={name} type={type} />
            {type === "checkbox" && <Checkmark style={checkboxStyle} />}
            {type === "radio" && <RadioMark />}
            {label && <Label>{label}</Label>}
            <DivContainer />
        </Container>
    );
};

export default React.memo(RadioCheckButton);
