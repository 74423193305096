import React from "react";
import { RouteComponentProps } from "react-router";

type Props = RouteComponentProps<{}>;

const PageNotFound: React.SFC<Props> = ({ location }) => {
    return (
        <div className="text-center">
            <h1 style={{ fontSize: "90px" }}>404</h1>
            <h3 className="text-center">
                No match for <code>{location ? location.pathname : ""}</code>
            </h3>
        </div>
    );
};

export default PageNotFound;
