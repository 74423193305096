import React from "react";
import styled from "styled-components/macro";
import { Surface } from "components/common";
import { H4, CaptionText } from "components/typography";
import { useHistory } from "react-router-dom";
import rootStore from "services/store";
import { observer } from "mobx-react-lite";
import responsive from "services/responsive";

const VerticalDivider = styled.div`
    width: 1px;
    height: 30px;
    background: #bfcad6;
    opacity: 0.4;
    margin: auto 20px;
`;

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 30px;
`;
const BACK_ARROW =
    "data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 6.00019H3.41002L6.71002 2.71019C6.89832 2.52188 7.00411 2.26649 7.00411 2.00019C7.00411 1.73388 6.89832 1.47849 6.71002 1.29019C6.52171 1.10188 6.26632 0.996094 6.00002 0.996094C5.73372 0.996094 5.47832 1.10188 5.29002 1.29019L0.290018 6.29018C0.198978 6.38529 0.127613 6.49743 0.0800184 6.62018C-0.0199996 6.86365 -0.0199996 7.13672 0.0800184 7.38019C0.127613 7.50294 0.198978 7.61508 0.290018 7.71019L5.29002 12.7102C5.38298 12.8039 5.49358 12.8783 5.61544 12.9291C5.7373 12.9798 5.86801 13.006 6.00002 13.006C6.13203 13.006 6.26274 12.9798 6.38459 12.9291C6.50645 12.8783 6.61706 12.8039 6.71002 12.7102C6.80375 12.6172 6.87814 12.5066 6.92891 12.3848C6.97968 12.2629 7.00582 12.1322 7.00582 12.0002C7.00582 11.8682 6.97968 11.7375 6.92891 11.6156C6.87814 11.4937 6.80375 11.3831 6.71002 11.2902L3.41002 8.00019H11C11.2652 8.00019 11.5196 7.89483 11.7071 7.70729C11.8947 7.51976 12 7.2654 12 7.00019C12 6.73497 11.8947 6.48062 11.7071 6.29308C11.5196 6.10554 11.2652 6.00019 11 6.00019Z' fill='black'/%3E%3C/svg%3E%0A";

const USER_ICON =
    "data:image/svg+xml,%3Csvg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7105 11.71C14.6909 10.9387 15.4065 9.88092 15.7577 8.68394C16.109 7.48697 16.0784 6.21027 15.6703 5.03147C15.2621 3.85267 14.4967 2.83039 13.4806 2.10686C12.4644 1.38332 11.2479 0.994507 10.0005 0.994507C8.75303 0.994507 7.5366 1.38332 6.52041 2.10686C5.50423 2.83039 4.73883 3.85267 4.3307 5.03147C3.92257 6.21027 3.892 7.48697 4.24325 8.68394C4.59449 9.88092 5.31009 10.9387 6.29048 11.71C4.61056 12.383 3.14477 13.4994 2.04938 14.9399C0.953983 16.3805 0.270048 18.0913 0.070485 19.89C0.0560396 20.0213 0.0676015 20.1542 0.10451 20.2811C0.141419 20.4079 0.202952 20.5263 0.285596 20.6293C0.452504 20.8375 0.695269 20.9708 0.960485 21C1.2257 21.0292 1.49164 20.9518 1.69981 20.7849C1.90798 20.618 2.04131 20.3752 2.07049 20.11C2.29007 18.1552 3.22217 16.3498 4.6887 15.0388C6.15524 13.7278 8.05338 13.003 10.0205 13.003C11.9876 13.003 13.8857 13.7278 15.3523 15.0388C16.8188 16.3498 17.7509 18.1552 17.9705 20.11C17.9977 20.3557 18.1149 20.5827 18.2996 20.747C18.4843 20.9114 18.7233 21.0015 18.9705 21H19.0805C19.3426 20.9698 19.5822 20.8373 19.747 20.6313C19.9119 20.4252 19.9886 20.1624 19.9605 19.9C19.76 18.0962 19.0724 16.381 17.9713 14.9382C16.8703 13.4954 15.3974 12.3795 13.7105 11.71ZM10.0005 11C9.20936 11 8.436 10.7654 7.7782 10.3259C7.12041 9.88636 6.60772 9.26164 6.30497 8.53074C6.00222 7.79983 5.923 6.99557 6.07734 6.21964C6.23168 5.44372 6.61265 4.73099 7.17206 4.17158C7.73147 3.61217 8.4442 3.2312 9.22012 3.07686C9.99605 2.92252 10.8003 3.00173 11.5312 3.30448C12.2621 3.60724 12.8868 4.11993 13.3264 4.77772C13.7659 5.43552 14.0005 6.20888 14.0005 7C14.0005 8.06087 13.5791 9.07828 12.8289 9.82843C12.0788 10.5786 11.0614 11 10.0005 11Z' fill='white'/%3E%3C/svg%3E%0A";

type AvatarContainerProps = { size?: number };
const AvatarContainer = styled.img<AvatarContainerProps>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.secondary};
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("${USER_ICON}");

    ${responsive.Mobile} & {
        display: none;
    }

`;
AvatarContainer.defaultProps = { size: 50 };

const BrokerName = H4.withComponent("div");
const BrokerageName = CaptionText.withComponent("div");

const HeaderContainer = styled(Surface as any)`
    margin-bottom: 20px;
    align-items: center;
`;

const UserInfoTextContainer = styled.div`
    margin-left: 20px;

    ${responsive.Tablet} &,
    ${responsive.Mobile} & {
        display: none;
    }
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

export const UserInfo: React.FC = observer(function UserInfo() {
    const { userInfo } = rootStore;
    if (!userInfo) return null;
    return (
        <UserInfoContainer>
            <AvatarContainer src={userInfo.photo ?? undefined} alt="" />
            <UserInfoTextContainer>
                <BrokerName>{userInfo.fullName}</BrokerName>
                <BrokerageName>{userInfo.brokerageName}</BrokerageName>
            </UserInfoTextContainer>
        </UserInfoContainer>
    );
});

const BackLink = ({ children, ...props }: React.HTMLProps<HTMLSpanElement>) => {
    const history = useHistory();
    return (
        <span onClick={history.goBack} {...props}>
            {children}
        </span>
    );
};
const H4BackLink = H4.withComponent(BackLink as any);

const StyledBackLink = styled(H4BackLink as any)`
    cursor: pointer;
    display: flex;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }

    &:hover {
        opacity: 0.7;
    }

    & img {
        margin-right: 10px;
    }
`;

const Header: React.FC = () => {
    return (
        <HeaderContainer>
            <StyledBackLink>
                <img src={BACK_ARROW} alt="" />
                Back
            </StyledBackLink>
            <Spacer />
            <VerticalDivider />
            <UserInfo />
        </HeaderContainer>
    );
};

export default React.memo(Header);
