import { types, getSnapshot, flow, applySnapshot, Instance, TypeOfValue } from "mobx-state-tree";
import rootStore from "services/store";
import { logger } from "services";
import { getDashboardQuotes, DashboardQuote } from "services/brokers";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

export const DashboardRowType = types.model("DashboardRowType", {
    id: types.identifier,
    statusText: types.string,
    company: types.model({
        name: types.string,
        info: types.maybeNull(
            types.model({
                url: types.maybeNull(types.string),
                address1: types.maybeNull(types.string),
                address2: types.maybeNull(types.string),
                city: types.maybeNull(types.string),
                state: types.maybeNull(types.string),
                zipcode: types.maybeNull(types.string),
            }),
        ),
        broker: types.string,
    }),
    accountType: types.maybeNull(types.string),
    finalPremium: types.maybeNull(types.string),
    totalComponentPremium: types.maybeNull(types.string),
    status: types.string,
    commission: types.maybeNull(types.number),
    policyEffectiveDate: types.maybeNull(types.string),
    products: types.array(types.string),
    application: types.model({
        id: types.string,
        status: types.string,
        appSecurityToken: types.maybeNull(types.string),
    }),
    finalized: types.boolean,
    pdfSigned: types.boolean,
    createdAt: types.string,
    expirationDate: types.maybeNull(types.string),
    broker: types.string,
    agency: types.string,
    submissionDate: types.maybeNull(types.string),
    alerts: types.array(
        types.model({
            id: types.identifier,
            model: types.string,
            uuid: types.string,
            eventType: types.string,
            eventTypeDisplay: types.string,
        }),
    ),
    accountLost: types.boolean,
});

export const DashboardTableStore = types
    .model({
        results: types.array(
            types.snapshotProcessor(DashboardRowType, {
                preProcessor(sn: DashboardQuote) {
                    const alertsArray = rootStore.alerts.alerts.get(sn.id);
                    const alerts = alertsArray ? getSnapshot(alertsArray) : [];
                    return {
                        ...sn,
                        alerts,
                    };
                },
            }),
        ),
        count: types.number,
    })
    .volatile(() => ({
        loading: true,
        error: false,
        query: "",
        types: "",
        page: 1,
        sortColumn: "",
        sortDirection: "asc",
        showFilters: false,
        alertsOnly: false,
    }))
    .actions((self) => ({
        load: flow(function* load() {
            try {
                self.loading = true;
                const data = yield getDashboardQuotes(
                    self.page,
                    self.query,
                    self.sortColumn,
                    self.sortDirection,
                    self.types,
                    self.alertsOnly,
                );
                applySnapshot(self, data);
                self.error = false;
            } catch (error) {
                logger.error(error);
                self.error = true;
                throw error;
            } finally {
                self.loading = false;
            }
        }),
    }))
    .actions((self) => ({
        search(page?: number) {
            self.page = page || 1;
            self.sortColumn = "";
            self.sortDirection = "asc";
            self.load();
        },
    }))
    .actions((self) => {
        const searchDebounce = debounce(() => {
            const savedPage = sessionStorage.getItem("dashboardPage") || undefined;
            if (savedPage) {
                self.search(+savedPage);
            } else {
                self.search();
            }
        }, 750);

        const filterTypes = sessionStorage.getItem("AccountsFilterTypes");

        if (filterTypes === null) {
            const defaultSelectedTypes = 'quote,policy,application';
            sessionStorage.setItem("AccountsFilterTypes", defaultSelectedTypes);
            self.types = defaultSelectedTypes;
        }  else if (filterTypes && filterTypes !== self.types) {
            self.types = filterTypes;
        }

        return {
            setLoadingScreen() {
                self.loading = true;
            },
            toggleShowFilters() {
                self.showFilters = !self.showFilters;
            },
            showFiltersOnly() {
                self.alertsOnly = true;
                searchDebounce();
            },
            reloadAllItems() {
                self.alertsOnly = false;
                searchDebounce();
            },
            changeQuery(query: string) {
                self.query = query;
                searchDebounce();
            },
            toggleType(type: string) {
                const types = self.types.length > 0 ? self.types.split(",") : [];
                types.includes(type) ? types.splice(types.indexOf(type), 1) : types.push(type);
                self.types = types.sort().join(",");
                sessionStorage.setItem("AccountsFilterTypes", types.sort().join(","));
                self.page = 1;
                searchDebounce();
            },
            setTypes(types: string) {
                if (types) {
                    self.types = types;
                    sessionStorage.setItem("AccountsFilterTypes", types.split(' ').sort().join(","));
                    searchDebounce();
                }
            },
            changeSortColumn(column: any, sortDirection = "asc") {
                self.sortColumn = column.selector;
                self.sortDirection = sortDirection;
                self.load();
            },
            afterCreate() {
                self.load().catch((error) => {
                    logger.error(error);
                });
            },
            changePage: flow(function* changePage(page: number | null) {
                const oldPage = self.page;
                if (page) {
                    sessionStorage.setItem("dashboardPage", page.toString());
                }
                try {
                    if (page !== null) {
                        self.page = page;
                        yield self.load();
                    }
                } catch (error) {
                    logger.error("DashboardTableStore.changePage", error);
                    toast.error("An error happing while trying to get the data");
                    self.page = oldPage;
                }
            }),
            clearFilters() {
                self.types = "";
                sessionStorage.setItem("AccountsFilterTypes", "");
                searchDebounce();
            },
        };
    });

export type IDashboardTableStore = Instance<typeof DashboardTableStore>;
export type IDashboardTableSnapshot = TypeOfValue<IDashboardTableStore>["CreationType"];

export const createDashboardTableStore = () => {
    const newStore = DashboardTableStore.create({ results: [], count: 0 });
    (window as any).dashboardTableStore = newStore;
    return newStore;
};
