import React from "react";
import { FullQuestion, useApplicationContext } from "services/application";
import { InputContainer, QuestionRowContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import { FormikInputField } from "components/common";
import { useField } from "formik";

import { clearFieldVerificationRequired } from "../../sectionUtils";

interface TextProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

const Text: React.SFC<TextProps> = ({ question, extraProps = {} }) => {
    const fieldName = `${question.id}__${extraProps?.questionIndex || 0}`;
    let type = question.type.toLowerCase();
    let hasHelper = false;
    let helperText = "";
    let helperPosition = "prefix";
    let min: any = Number.MIN_SAFE_INTEGER.toString();
    let max: any = Number.MAX_SAFE_INTEGER.toString();
    if (type === "year") {
        type = "number";
        min = "1500";
        max = "2999";
    }
    if (type === "percentage") {
        type = "number";
        hasHelper = true;
        helperText = "%";
        helperPosition = "suffix";
        max = "100";
    }
    const [meta, field] = useField(fieldName);

    const { sections: _sec, setSections } = useApplicationContext();
    const sections = _sec as NonNullable<typeof _sec>;

    const { questionsOnError, setQuestionsOnError } = extraProps;

    return (
        <QuestionRowContainer className={question.type} data-name="QuestionRowContainer">
            <QuestionTitleRender question={question} extraProps={extraProps} />
            <InputContainer>
                <FormikInputField
                    className={`question-${question.id} ${
                        questionsOnError?.includes(question.id) ? "on-error" : ""
                    } ${question.required ? "field-required" : ""}`}
                    name={fieldName}
                    type={type}
                    id={"q-" + fieldName}
                    placeholder={question.placeholder}
                    visibleHelper={hasHelper}
                    labelHelper={helperText}
                    min={min}
                    max={max}
                    helperPosition={helperPosition}
                    onKeyPress={(evt: any) => {
                        if (
                            type === "number" &&
                            question.type.toLowerCase() !== "currency" &&
                            ((evt.which < 48 && evt.which > 57 && evt.which !== 8) ||
                                evt.key === "e")
                        )
                            evt.preventDefault();
                        if (question.type.toLowerCase() === "year" && evt.target.value.length >= 4)
                            evt.preventDefault();
                        if (
                            question.type.toLowerCase() === "percentage" &&
                            evt.target.value.length >= 4
                        )
                            evt.preventDefault();
                    }}
                    value={field.value ?? ""}
                    onChange={(e: any) => {
                        clearFieldVerificationRequired(
                            question.id,
                            sections,
                            setSections,
                            setQuestionsOnError,
                            questionsOnError,
                        );
                        meta.onChange(e);
                    }}
                    {...extraProps}
                />
            </InputContainer>
        </QuestionRowContainer>
    );
};

export default React.memo(Text);
