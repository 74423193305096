type classNamesArg = { [key: string]: any } | string;

export function classNames(...args: classNamesArg[]): string {
    const classes = [];

    for (const arg of args) {
        if (!arg) continue;

        if (typeof arg === "string") {
            classes.push(arg);
        } else {
            for (const [key, value] of Object.entries(arg)) {
                if (value) {
                    classes.push(key);
                }
            }
        }
    }

    return classes.join(" ");
}
