import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { auth, users } from "services";
import { observer } from "mobx-react-lite";
import {
    Button,
    HeaderSingle,
    ValueBlock,
    ImgProfileViewer,
    ProfileNameViewer,
    Modal,
    FormikInputField,
    Row,
    CancelButton,
} from "components/common";

import rootStore from "services/store";

import { H4, Body1 } from "components/typography";

import responsive from "services/responsive";

import BrokerPageContainer from "./BrokerPageContainer";
import SideMenu from "./SideMenu";

import MenuIcon from "assets/mobile-menu-icon.svg";
import * as apiTypes from "CounterpartApiTypes";
import { useHistory } from "react-router-dom";
import { UserInfo } from "features/BrokerProfile/components/Header";
// import { sendRetailBrokerInvite } from "services/application";
// import { SendRetailBrokerInvite } from "CounterpartApiTypes";
import { sendStartApplicationLinkEmail } from "services/quotes";
import { Formik, Form, FormikHelpers } from "formik";
import { toast } from "react-toastify";

const StyledAppetiteBar = styled.div<{ hide: boolean, hideTransition: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.shipGrey};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;


    @media (min-width: 769px) {
        ${({hide}) => hide && css`
            display: none;
        `}
    }

    @media (max-width: 768px) {
        height: 40px;
        ${({hide, hideTransition}) => hide && !hideTransition && css`
            display: none;
        `}
    }

    @media (max-width: 553px) {
        height: 40px;
        ${({hideTransition}) => hideTransition && css`
            height: 62px;
        `}
    }
`;

const CloseIcon = styled.button<{ hideTransition: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    border-radius: 50%;
    background-color: #fff;
    transform: rotate(45deg);
    height: 16px;
    width: 16px;
    border: none;
    position: absolute;
    right: 20px;
    padding: 0;

    ${({hideTransition}) => hideTransition && css`
        @media (max-width: 768px) {
            display: none;
        }
    `}

    &:before {
        content: '';
        height: 6px;
        width: 1px;
        position: absolute;
        background-color: #6E6E6E;
    }

    &:after {
        content: '';
        height: 1px;
        width: 6px;
        position: absolute;
        background-color: #6E6E6E;
    }
`;

const StyledLink = styled.a<{hideAppetiteBannerTransition: boolean}>`
    &,
    &:hover,
    &:visited {
        text-decoration: none;
    }

    ${({hideAppetiteBannerTransition}) => hideAppetiteBannerTransition && css`
        display: none;
    `}
`;

const MobileTransitionMessage = styled.div<{ hideAppetiteBannerTransition: boolean }>`
    display: none;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
    padding: 10px;
    text-align: center;

    ${({hideAppetiteBannerTransition}) => hideAppetiteBannerTransition && css`
        display: block;
    `}
`;

const StyledAppetiteSpan = styled.span`
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
    margin-right: 30px;
`;

export const MenuContainer = styled.div<{ visible?: boolean }>`
    display: flex;
    height: 100%;
    bottom: 0;
    flex: 0 0 230px;

    @media (max-width: 767px) {
        flex: 1;
        ${({ visible }) =>
            visible
                ? `
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            flex: 0 0 100%;
            z-index: 100099;
        `
                : ""}
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        width: 230px;
        min-height: 100vh;
        height: 100%;
    }
`;

const StyledMenuContainer = styled.div<{ visible?: boolean }>`
    display: flex;
    flex: 0 0 230px;

    @media (max-width: 767px) {
        flex: 1;
    }
`;

export const MobileTitle = styled.span`
    ${H4}
    flex-grow: 1;
    text-align: center;
    color: #383838;
    line-height: 160%;
`;

export const MobileMenuContainer = styled.div<{ bannerHidden: boolean, hideTransition: boolean }>`
    display: none;
    flex-direction: row;
    align-items: center;

    @media (max-width: 767px) {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        flex-grow: 1;
        padding: 17px;
        top: 0;
        left: 0;
        background: #ffffff;
        z-index: 100009;
        top: 40px;

        @media (min-width: 769px) {
            ${({bannerHidden}) => bannerHidden && css`
                top: 0;
            `}
        }

        @media (max-width: 768px) {
            ${({bannerHidden, hideTransition}) => css`
                ${bannerHidden && !hideTransition && css`
                    top: 0;
                `}

                ${bannerHidden && hideTransition && css`
                    top: 40px;
                `}
            `}
        }

        @media (max-width: 553px) {
            ${({bannerHidden, hideTransition}) => css`
                ${bannerHidden && !hideTransition && css`
                    top: 0;
                `}

                ${bannerHidden && hideTransition && css`
                    top: 62px;
                `}
            `}
        }

        & ${ImgProfileViewer} {
            width: 30px;
            height: 30px;
            border-radius: 15px;
        }
    }
`;

const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
    padding: 0;

    ${responsive.Tablet} & {
        padding: 10px 0 90px;

        @media (max-width: 767px) {
            padding: 10px 20px 90px;
        }
    }

    ${responsive.Mobile} & {
        padding: 10px 10px 90px;
    }
`;

const ValuesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: left;
    padding: 0;
    @media (max-width: 767px) {
        flex-grow: 1;
        justify-content: space-around;
    }

    @media (max-width: 480px) {
        flex: 1;
        width: 50%;
        justify-content: center;

        & div.values-blocks {
            flex: 1;
            text-align: center;
            & span {
                width: 100%;
                text-align: center;
            }
        }
    }
`;

const TopMenu = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;

    @media (min-width: 920px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (max-width: 920px) {
        padding-left: 16px;
        padding-right: 16px;

        ${CancelButton} {
            min-width: 80px;
        }

        ${Button} {
            min-width: 80px;
        }
    }

    @media (max-width: 700px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 480px) {
    }

    & div.content-container.second-container {
        display: flex;
        height: 100%;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        z-index: 1009999;
        padding-left: 10px;

        @media (max-width: 767px) {
            width: 50%;

            .dropdown {
                display: none;
            }
        }

        @media (max-width: 480px) {
            width: 100%;
            padding-left: 0;
        }

        ${Button} {
            flex-grow: 1;
            max-width: 150px;
            padding: 10px;

            @media (max-width: 480px) {
                max-width: 100%;
                padding: 16px 10px;
            }
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        margin-top: -20px;

        & div.content-container {
            width: 100%;
            &.second-container {
                padding-top: 20px;
                justify-content: space-between;
                & button {
                    flex: 1;
                    max-width: 50%;
                }
            }
        }
    }
`;

const ContentContainer = styled.div<{bannerHidden: boolean}>`
    display: flex;
    flex-direction: column;
    width: calc(100% - 230px);
    flex: 0 0 calc(100% - 230px);
    padding: 0px 20px;

    @media (max-width: 767px) {
        padding: 50px 0;
        flex: 0 0 100%;
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        width: calc(100% - 230px);
        min-width: calc(100% - 230px);
        margin-top: 20px;
    }
`;

const ValuesMainContainer = styled.div`
    display: flex;
    align-items: center;

    @media (min-width: 768px) and (max-width: 800px) {
        max-width: 176px;
    }

    @media (max-width: 768px) {
        width: 50%;
    }

    ${ValuesContainer} {
        @media (max-width: 700px) {
            justify-content: flex-start;
        }

        & > .values-blocks {
            &.padding-right {
                @media (max-width: 1060px) {
                    padding-right: 10px;
                }

                @media (max-width: 820px) {
                    padding-right: 6px;
                }
            }

            &.padding-left {
                @media (max-width: 1060px) {
                    padding-left: 10px;
                }

                @media (max-width: 820px) {
                    padding-left: 6px;
                }
            }
        }
    }
`;

const StyledRow = styled(Row as any)`
    & > button {
        margin-left: 10px;
        margin-top: 25px;
        height: 54px;

        @media (max-width: 480px) {
            margin-left: 0;
        }
    }

    @media (max-width: 480px) {
        & > div {
            margin-right: 0 !important;
        }
    }
`;

export const getShareApplicationUrl = () => {
    return `${window.location.protocol}//${
        window.location.host
    }/startapplication?b=${auth.getUserID()}`;
};

interface Props {
    dashboardDataObject: apiTypes.BrokerDashboard;
}

const formikEmail: any = {
    email: "",
};

const _onSendStartApplication = (setModalShareOpen: any) => async (
    values: typeof formikEmail,
    actions: FormikHelpers<typeof formikEmail>,
) => {
    try {
        actions.setSubmitting(true);
        await sendStartApplicationLinkEmail(values);
        actions.setValues(formikEmail);
        setModalShareOpen(false);
        toast.success("Invite Sent!");
    } catch (error) {
        actions.setSubmitting(false);
        toast.error("An error ocurred, email not sent!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

type AvatarSectionHeaderProps = { history: ReturnType<typeof useHistory> };
const AvatarSectionHeader = ({ history }: AvatarSectionHeaderProps) => (
    <UncontrolledDropdown>
        <DropdownToggle
            tag="div"
            style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                alignItems: "space-between",
                justifyContent: "space-between",
            }}
        >
            <UserInfo />
        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem onClick={() => history.push("/broker-profile")}>My Profile</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => auth.logout()}>Logout</DropdownItem>
        </DropdownMenu>
    </UncontrolledDropdown>
);

const BrokerPage: React.FC<Props> = observer(({ children, dashboardDataObject }) => {
    interface UserStore {
        email: string;
        firstName: string;
        lastName: string;
    }

    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const [modalShareOpen, setModalShareOpen] = useState(false);
    const [hideAppetiteBanner, setHideAppetiteBanner] = useState(localStorage.getItem('hide_appetite_guide_banner'));
    const [hideAppetiteBannerTransition, setHideAppetiteBannerTransition] = useState(false);

    const history = useHistory();
    const [brokerDataStore, setBrokerDataStore] = useState<UserStore>({
        firstName: "",
        lastName: "",
        email: "",
    });
    // const [userBrokerageLogo, setUserBrokerageLogo] = useState(NoPhoto);

    useEffect(() => {
        //    brokerStore.loadBrokerData();
        users.getBrokerProfile().then((broker) => {
            const data: UserStore = {
                firstName: broker?.firstName ? broker?.firstName : "",
                lastName: broker?.lastName ? broker?.lastName : "",
                email: "",
            };
            // if (broker?.brokerage?.logo) {
            //     setUserBrokerageLogo(broker?.brokerage?.logo);
            // }
            setBrokerDataStore(data);
        });
    }, []);

    const hideBanner = () => {
        setHideAppetiteBannerTransition(true);
        setHideAppetiteBanner('true');
        localStorage.setItem('hide_appetite_guide_banner', "true");

        setTimeout(() => {
            setHideAppetiteBannerTransition(false);
        }, 5000)
    }

    return (
        <BrokerPageContainer noPaddingTop={!!hideAppetiteBanner} hideTransition={hideAppetiteBannerTransition}>
            {(!rootStore.userInfo || !rootStore.userInfo.brokerAdminOverride) && (
                <StyledAppetiteBar hide={!!hideAppetiteBanner} hideTransition={hideAppetiteBannerTransition}>
                    <StyledLink
                        href="https://counterpart-public.s3.us-west-2.amazonaws.com/2024/appetite_guides/Management-Liability-Appetite-Guide-2024.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        hideAppetiteBannerTransition={hideAppetiteBannerTransition}
                    >
                        <StyledAppetiteSpan>Download Counterpart Appetite Guide</StyledAppetiteSpan>
                    </StyledLink>
                    <MobileTransitionMessage hideAppetiteBannerTransition={hideAppetiteBannerTransition}>
                        You can download and view the Targeted Industries list at any time in side menu
                    </MobileTransitionMessage>
                    <CloseIcon hideTransition={hideAppetiteBannerTransition} onClick={hideBanner} />
                </StyledAppetiteBar>
            )}
            <StyledMenuContainer visible={sideMenuOpen}>
                <MobileMenuContainer hideTransition={hideAppetiteBannerTransition} bannerHidden={!!hideAppetiteBanner}>
                    <button
                        style={{ border: "none", background: "none" }}
                        onClick={() => setSideMenuOpen(true)}
                    >
                        <img src={MenuIcon} alt="" />
                    </button>
                    <MobileTitle>Dashboard</MobileTitle>
                    <div style={{ position: 'relative', top: 0, right: 0 }}>
                        <UncontrolledDropdown align="end">
                            <DropdownToggle
                            align="end"
                                tag="div"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexGrow: 1,
                                    alignItems: "space-between",
                                    justifyContent: "space-between",
                                }}
                            >
                                <ImgProfileViewer src={rootStore.userInfo.photo || undefined} alt="" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => history.push("/broker-profile")}>
                                <ProfileNameViewer style={{ fontSize: 16 }} userInfo={brokerDataStore} />
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => auth.logout()}>Logout</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </MobileMenuContainer>
                <SideMenu showAppetiteGuideBanner={Boolean(hideAppetiteBanner)} visible={sideMenuOpen} closeAction={() => setSideMenuOpen(false)} />
            </StyledMenuContainer>
            <ContentContainer bannerHidden={!!hideAppetiteBanner}>
                <HeaderSingle
                    style={{ zIndex: 100999, padding: 0, maxWidth: "unset", margin: 0 }}
                    detached={true}
                >
                    <TopMenu>
                        <ValuesMainContainer className="content-container">
                            <ValuesContainer>
                                <ValueBlock
                                    className="values-blocks padding-right"
                                    title="total premium"
                                    value={dashboardDataObject.totalPremium.toFixed(0)}
                                />
                            </ValuesContainer>
                            <ValuesContainer>
                                <ValueBlock
                                    className="values-blocks padding-left"
                                    title="average premium"
                                    value={dashboardDataObject.averagePremium.toFixed(0)}
                                />
                            </ValuesContainer>
                        </ValuesMainContainer>
                        <div
                            className="content-container second-container"
                            style={{ display: "flex", gap: "10px" }}
                        >
                            <CancelButton onClick={() => setModalShareOpen(true)}>
                                Email App
                            </CancelButton>
                            <Button onClick={() => window.open(getShareApplicationUrl(), "_blank")}>
                                Start App
                            </Button>
                            <AvatarSectionHeader history={history} />
                        </div>
                    </TopMenu>
                </HeaderSingle>
                <BodyContent>{children}</BodyContent>
            </ContentContainer>
            <Modal
                isOpen={modalShareOpen}
                closeable={true}
                title="Email Application"
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalShareOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <Body1>
                        {`Invite a retail agent or client to start Counterpart's digital application. We will email a link to the designated email address on your behalf. All submissions from that link will appear in your broker dashboard.`}
                    </Body1>
                    <Formik
                        initialValues={formikEmail}
                        onSubmit={_onSendStartApplication(setModalShareOpen)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <StyledRow>
                                    <FormikInputField
                                        name="email"
                                        label="Broker Email"
                                        type="email"
                                        placeholder="e.g. john@company.com"
                                    />
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Send Email
                                    </Button>
                                </StyledRow>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </BrokerPageContainer>
    );
});

export default BrokerPage;
