import React from "react";
import styled from "styled-components/macro";

import { MuseoSansBaseCSS } from "components/typography";

interface Props {
    title: string;
    value: string | number;
    className?: string;
    style?: any;
}

const ValueBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    height: auto;

    &.padding-right {
        padding-right: 30px;
    }

    &.padding-left {
        padding-left: 30px;
    }

    @media (max-width: 480px) {
        &.padding-left {
            padding-left: 15px;
        }

        &.padding-right {
            padding-right: 15px;
        }
    }
`;

const BlockTitle = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 160%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 0;
    color: ${({ theme }) => theme.colors.darkGray};
`;

const BlockValue = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    padding: 0;
`;

const ValueBlock: React.FC<Props> = ({ title, value, className = "", style }) => {
    return (
        <ValueBlockContainer className={className} style={style}>
            <BlockTitle className={className}>{title}</BlockTitle>
            <BlockValue className={className}>
                ${("" + value).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </BlockValue>
        </ValueBlockContainer>
    );
};

export default ValueBlock;
