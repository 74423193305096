import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Exclamation from "assets/ExclamationMark.svg";
import { useField } from "formik";
import OpenEye from "assets/EyeIcon.svg";
import CrossedEye from "assets/CrossedEyeIcon.svg";

interface Props {
    handleInput?: (e: any, value?: string) => void;
    name: string;
    placeholder: string;
    type?: string;
    className?: string;
    touched?: boolean;
    ariaLabel?: string;
    validate?: (a: string) => void;
    [key: string]: any;
}

const P2CSS = css`
    font-size: 20px;
    line-height: 130%;

    @media (max-width: 1440px) {
        font-size: 16px;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const ErrorText = styled.p`
    display: none;
    font-size: 12px;
    color: #ed0000;
    margin: 0;
    line-height: 11px;
    letter-spacing: 0.02em;
    margin-top: 5px;

    @media (max-width: 1440px) {
        font-size: 10px;
    }
`;

const onAutoFillStart = keyframes`
  from {}
`;

const InputWrapper = styled.div`
    position: relative;
    width: 100%;

    &:hover {
        @media (min-width: 769px) {
            input,
            textarea {
                border-color: ${({ theme }) => theme.colors.primary};
            }

            &:before {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    &:focus-within {
        input,
        textarea {
            border-color: ${({ theme }) => theme.colors.primary};
        }

        &:before {
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    input,
    textarea {
        ${P2CSS};
        font-size: 16px;
        padding: 20px 32px 8px 20px;
        outline: none;
        border: 1px solid ${({ theme }) => theme.colors.gray};
        box-sizing: border-box;
        width: 100%;
        z-index: 2;
        position: relative;
        background: transparent;
        transition: border-color 0.3s;
        outline: none;

        &[type="password"] {
            padding: 20px 50px 8px 20px;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        &::-webkit-contacts-auto-fill-button {
            visibility: hidden;
            position: absolute;
            right: 0;
        }

        &[value=""]:not(.autoFilled):-webkit-autofill {
            animation-name: ${onAutoFillStart};
            animation-iteration-count: infinite;
            animation-duration: .1s;
            transition: background-color 50000s ease-in-out 0s;
        }
    }

    textarea {
        resize: none;
        height: 120px;
    }

    &:before {
        content: attr(data-placeholder);
        position: absolute;
        top: 14px;
        left: 21px;
        z-index: 0;
        transition: 0.2s;
        color: #6e6e6e;
        z-index: 3;
        pointer-events: none;
        ${P2CSS};
    }

    &:focus-within,
    &.notEmpty,
    &.autoFilled {
        &:before {
            top: 9px;
            font-size: 12px;
            line-height: 110%;

            @media (max-width: 1440px) {
                font-size: 10px;
            }
        }

    }

    &:after {
        content: "";
        position: absolute;
        top: 22px;
        right: 20px;
        height: 15px;
        width: 6px;
        z-index: 2;
        pointer-events: none;

        @media (max-width: 1440px) {
            top: 22px;
        }
    }

    &.error {
        input,
        textarea {
            border-color: #ed000066;

            &:hover {
                border-color: ${({ theme }) => theme.colors.primary};
            }
        }

        &:focus-within {
            input,
            textarea {
                border-color: #c2c2c2;

                &:hover {
                    border-color: ${({ theme }) => theme.colors.primary};
                }
            }
        }

        ${ErrorText} {
            display: flex;
        }

        &:after {
            background: url(${Exclamation});
            background-position: center top;
            background-repeat: no-repeat;
        }
    }

    &.error.notEmpty.password:after {
        right: 40px;
    }
`;

const EyeIcon = styled.img`
    height: 20px;
    width: 20px;
`;

const EyeButton = styled.button<{ visible: boolean }>`
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    position: absolute;
    top: 16px;
    right: 9px;
    z-index: 10;

    ${({ visible }) =>
        !visible &&
        css`
            display: none;
        `}
`;

const EyeComponent: React.FC<{
    type: string;
    handleClick: (a: string) => void;
    visible: boolean;
}> = ({ type, handleClick, visible }) => {
    const handleEyeButtonClick = (e: any) => {
        e.preventDefault();
        handleClick(type);
    };

    return (
        <EyeButton onClick={handleEyeButtonClick} visible={visible}>
            <EyeIcon
                src={type === "password" ? OpenEye : CrossedEye}
                alt={type === "password" ? "show password" : "hide password"}
            />
        </EyeButton>
    );
};

const Input: React.FC<Props> = ({
    name,
    placeholder,
    type = "text",
    className,
    autoComplete,
    ariaLabel,
    ...rest
}) => {
    const getClass = (hasError: boolean, inputValue: string) => {
        return `${hasError ? "error" : ""} ${inputValue ? "notEmpty" : ""} ${type}`;
    };

    const [field, meta] = useField(name);
    const [passwordType, setPasswordType] = useState("password");
    const [autoFilled, setAutoFilled] = React.useState(false);

    const handleShowPassword = (type: string) => {
        if (type === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    };

    const handleAutoFilledAnimation = () => {
        if (!autoFilled) {
            setAutoFilled(true);
        }
    };

    const handleBlur = (handleBlur: any, e: any) => {
        handleBlur(e);
        setAutoFilled(false);
    };

    const inputType = type === "password" ? passwordType : type;

    return (
        <InputWrapper
            data-placeholder={placeholder}
            className={`${className || ""} ${getClass(
                !!meta.touched && !!meta.error,
                field.value,
            )}${autoFilled ? ' autoFilled' : ""}`}
        >
            <div style={{ position: "relative" }}>
                <EyeComponent
                    visible={!!field.value && type === "password"}
                    handleClick={handleShowPassword}
                    type={passwordType}
                />
                <input
                    aria-label={ariaLabel}
                    className={autoFilled ? 'autoFilled' : ""}
                    autoComplete={autoComplete}
                    placeholder=""
                    type={inputType}
                    onAnimationIteration={handleAutoFilledAnimation}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e: any) => handleBlur(field.onBlur, e)}
                    {...rest}
                />
            </div>
            <ErrorText>{meta.error}</ErrorText>
        </InputWrapper>
    );
};

export default Input;
