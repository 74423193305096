import * as Sentry from "@sentry/browser";
import { isAxiosError } from "utils";

const __DEV__ = process.env.NODE_ENV !== "production";

export function log(message: string, ...args: Array<any>) {
    if (__DEV__) {
        console.log(message, ...args);
    } else {
        Sentry.addBreadcrumb({
            level: Sentry.Severity.Log,
            data: args,
            message: message,
        });
    }
}

export function debug(message: string, data?: Record<string, any>) {
    if (__DEV__) {
        const args = [message, data].filter(Boolean);
        console.debug(...args);
    } else {
        Sentry.addBreadcrumb({
            level: Sentry.Severity.Debug,
            data: data,
            message: message,
        });
    }
}

export function info(message: string, data?: Record<string, any>) {
    if (__DEV__) {
        const args = [message, data].filter(Boolean);
        console.debug(...args);
    } else {
        Sentry.addBreadcrumb({
            level: Sentry.Severity.Info,
            data: data,
            message: message,
        });
    }
}

export function warn(message: string, data?: Record<string, any>) {
    if (__DEV__) {
        const args = [message, data].filter(Boolean);
        console.debug(...args);
    } else {
        Sentry.addBreadcrumb({
            level: Sentry.Severity.Warning,
            data: data,
            message: message,
        });
    }
}

export function error(messageOrError: Error | string, data?: Record<string, any>) {
    if (__DEV__) {
        const args = [messageOrError, data].filter(Boolean);
        console.error(...args);
    } else {
        if (typeof messageOrError === "string") {
            Sentry.addBreadcrumb({
                level: Sentry.Severity.Error,
                data: data,
                message: messageOrError,
            });
        } else if (messageOrError instanceof Error) {
            let extra = undefined;
            if (isAxiosError(messageOrError)) {
                extra = messageOrError.toJSON();
            }
            Sentry.captureException(messageOrError, {
                extra: extra,
            });
        }
    }
}
