import { types, flow, getParent, applySnapshot, Instance } from "mobx-state-tree";
import {
    patchQuoteComponent,
    removePermutation,
    runEngineForQuote,
    changeSubcoverages,
} from "services/quotes";
import { ChangeSubcoveragesCoveragelineEnum } from "definitions/enums";
import { toast } from "react-toastify";

import { logger } from "services";
import { assert, cancelable, formatCurrency, mobxCustomTypes } from "utils";
import { QuoteStore } from ".";

const CALCULATED_RATES_INDEX = {
    RECOMMENDED: "0",
    ALTERNATIVE_1: "1",
    ALTERNATIVE_2: "2",
};

enum InsuranceLineKeyEnum {
    DO = "do",
    EPLI = "epli",
    FIDUCIARY = "fiduciary",
    CRIME = "crm",
    MPL = "mpl",
}

const CALCULATED_RATES_NAMES = {
    [CALCULATED_RATES_INDEX.RECOMMENDED]: "Option 1",
    [CALCULATED_RATES_INDEX.ALTERNATIVE_1]: "Option 2",
    [CALCULATED_RATES_INDEX.ALTERNATIVE_2]: "Option 3",
};

const DEFAULT_RATES_WHEN_MISSING = {
    [CALCULATED_RATES_INDEX.RECOMMENDED]: null,
    [CALCULATED_RATES_INDEX.ALTERNATIVE_1]: null,
    [CALCULATED_RATES_INDEX.ALTERNATIVE_2]: null,
};

const BASE_LIMITS = [
    250_000,
    500_000,
    750_000,
    1_000_000,
    1_500_000,
    2_000_000,
    2_500_000,
    3_000_000,
];
const BASE_RETENTIONS = [
    5_000,
    10_000,
    15_000,
    20_000,
    25_000,
    35_000,
    50_000,
    75_000,
    100_000,
    125_000,
    150_000,
    200_000,
    250_000,
];

const LIMITS_AND_RETENTION_OPTIONS = {
    [InsuranceLineKeyEnum.DO]: {
        limits: BASE_LIMITS,
        retention: BASE_RETENTIONS,
    },
    [InsuranceLineKeyEnum.EPLI]: {
        limits: BASE_LIMITS,
        retention: BASE_RETENTIONS,
    },
    [InsuranceLineKeyEnum.FIDUCIARY]: {
        limits: BASE_LIMITS,
        retention: [0, 5_000, ...BASE_RETENTIONS],
    },
    [InsuranceLineKeyEnum.CRIME]: {
        limits: [
            100000,
            150000,
            200000,
            250000,
            500000,
            750000,
            1000000,
            1500000,
            2000000,
            2500000,
            3000000,
        ],
        retention: [
            0,
            1000,
            2500,
            5000,
            10000,
            15000,
            20000,
            25000,
            35000,
            50000,
            75000,
            100000,
            125000,
            150000,
            200000,
            250000,
        ],
    },
    [InsuranceLineKeyEnum.MPL]: {
        limits: [100_000, 250_000, 500_000, 1_000_000, 2_000_000, 3_000_000, 4_000_000, 5_000_000],
        retention: [
            0,
            1_000,
            2_500,
            5_000,
            10_000,
            15_000,
            25_000,
            50_000,
            75_000,
            100_000,
            150_000,
            250_000,
        ],
    },
} as const;


// ADMITTED
const ADMITTED_BASE_LIMITS = [
    0,
    1_000,
    2_500,
    5_000,
    10_000,
    25_000,
    50_000,
    100_000,
    250_000,
    500_000,
    750_000,
    1_000_000,
    1_500_000,
    2_000_000,
    2_500_000,
    3_000_000,
    3_500_000,
    4_000_000,
    4_500_000,
    5_000_000,
    7_500_000,
    10_000_000,
    15_000_000,
    20_000_000,
    25_000_000
];

const ADMITTED_BASE_RETENTIONS = [
    0,
    1_000,
    2_500,
    5_000,
    10_000,
    25_000,
    50_000,
    100_000,
    250_000,
    500_000,
    750_000,
    1_000_000,
    2_000_000,
    3_000_000,
    4_000_000,
    5_000_000,
    7_500_000,
    10_000_000,
    15_000_000,
    20_000_000,
    25_000_000
];

const ADMITTED_LIMITS_AND_RETENTION_OPTIONS = {
    [InsuranceLineKeyEnum.DO]: {
        limits: ADMITTED_BASE_LIMITS,
        retention: ADMITTED_BASE_RETENTIONS,
    },
    [InsuranceLineKeyEnum.EPLI]: {
        limits: ADMITTED_BASE_LIMITS,
        retention: ADMITTED_BASE_RETENTIONS,
    },
    [InsuranceLineKeyEnum.FIDUCIARY]: {
        limits: [...ADMITTED_BASE_LIMITS, 6_000_000, 7_000_000, 8_000_000, 9_000_000, 11_000_000, 12_000_000, 13_000_000, 14_000_000],
        retention: [...ADMITTED_BASE_RETENTIONS, 6_000_000, 7_000_000, 8_000_000, 9_000_000, 11_000_000, 12_000_000, 13_000_000, 14_000_000],
    },
    [InsuranceLineKeyEnum.CRIME]: {
        limits: ADMITTED_BASE_LIMITS,
        retention: ADMITTED_BASE_RETENTIONS,
    }
};


const InsuranceLineModel = types
    .model("InsuranceLineModel", {
        id: types.identifier,
        name: types.string,
        shortName: types.string,
        value: types.string,
        description: types.optional(types.string, ""),
        canShareLimit: types.boolean,
    })
    .views((self) => ({
        get valueCapitalizationString() {
            return self.value.toUpperCase();
        },
    }));

const CoveragesModel = types
    .model("CoveragesModel", {
        limit: types.maybeNull(types.number),
        retention: types.maybeNull(types.number),
        limitChoices: types.array(types.maybeNull(types.number)),
        retentionChoices: types.array(types.maybeNull(types.number)),
        limitKey: types.maybeNull(types.string),
        verboseName: types.maybeNull(types.string),
        retentionKey: types.maybeNull(types.string),
    })
    .views((self) => ({
        get getLimitChoices() {
            const coverageLimit = self.limit;
            return self.limitChoices.filter((l) => {
                return l && coverageLimit && l <= coverageLimit;
            });
        },
        get getRetentionChoices() {
            const coverageRetention = self.retention;
            return self.limitChoices.filter((r) => {
                return r && coverageRetention && r >= coverageRetention;
            });
        },
    }));

const CalculatedRate = types
    .model("CalculatedRate", {
        index: types.string,
        premium: mobxCustomTypes.currencyType,
        limit: mobxCustomTypes.currencyType,
        retention: mobxCustomTypes.currencyType,
        coverages: types.maybeNull(types.array(CoveragesModel)),
    })
    .volatile(() => ({
        loading: false,
        editing: false,
        saved: true,
    }))
    .views((self) => ({
        get isSelected(): boolean {
            return (
                String(getParent<typeof QuoteComponentModel>(self, 2).selectedIndex) === self.index
            );
        },
        get isRemovable(): boolean {
            let remove = false;
            const selected = String(getParent<typeof QuoteComponentModel>(self, 2).selectedIndex);
            if (self.index !== CALCULATED_RATES_INDEX.RECOMMENDED && self.index !== selected) {
                remove = true;
            }
            return remove;
        },
        get name(): string {
            return CALCULATED_RATES_NAMES[self.index];
        },
        get canClick(): boolean {
            return !(getParent(self, 4) as any).sharedLimits;
        },
    }))
    .actions((self) => ({
        remove: flow(function* remove() {
            if (!self.isRemovable) return;
            try {
                self.loading = true;
                const quoteComponent = getParent(self, 2) as any;
                yield removePermutation(self.index, quoteComponent.id);
                quoteComponent.removeRate(self.index);
            } catch (error) {
                logger.error(error);
                toast.error("Not possible to remove this item");
                self.loading = false;
            }
        }),
        save: flow(function* save() {
            const quoteComponent = getParent(self, 2) as any;
            try {
                self.loading = true;
                quoteComponent.setLoading(true);
                const quote = getParent(quoteComponent, 2) as any;
                const newData = yield runEngineForQuote(
                    {
                        limit: self.limit,
                        retention: self.retention,
                        index: parseInt(self.index),
                        quoteComponentId: quoteComponent.id,
                    },
                    quote.id,
                );
                applySnapshot(quoteComponent, newData);
                self.editing = false;
                return true;
            } catch (error) {
                logger.error(error);
                return false;
            } finally {
                quoteComponent.setLoading(false);
                self.loading = false;
            }
        }),
        changeLimit(ev: any) {
            self.limit = parseInt(ev.target.value);
        },
        changeRetention(ev: any) {
            self.retention = parseInt(ev.target.value);
        },
        edit() {
            self.editing = true;
        },
    }));

const QuoteComponentModel = types
    .model("QuoteComponent", {
        id: types.identifier,
        insuranceLine: InsuranceLineModel,
        premium: mobxCustomTypes.currencyType,
        limits: mobxCustomTypes.currencyType,
        retention: mobxCustomTypes.currencyType,
        coverageDenied: types.boolean,
        selectedIndex: types.integer,
        attachmentPoint: types.maybeNull(types.integer),
        calculatedRates: types.optional(
            types.model("CalculatedRatesContainer", {
                [CALCULATED_RATES_INDEX.RECOMMENDED]: types.maybeNull(CalculatedRate),
                [CALCULATED_RATES_INDEX.ALTERNATIVE_1]: types.maybeNull(CalculatedRate),
                [CALCULATED_RATES_INDEX.ALTERNATIVE_2]: types.maybeNull(CalculatedRate),
            }),
            DEFAULT_RATES_WHEN_MISSING,
        ),
        endorsements: types.array(
            types.maybeNull(
                types.model({
                    endorsementId: types.string,
                    name: types.string,
                    insuranceLine: types.string,
                    description: types.string,
                }),
            ),
        ),
        hiddenOptions: types.array(types.string),
        priorAndPendingDate: types.maybeNull(types.string),
        coverage: types.maybeNull(
            types.model({
                sublimits: types.array(
                    types.model({
                        name: types.string,
                        value: types.maybeNull(types.number),
                    }),
                ),
                retentions: types.array(
                    types.model({
                        name: types.string,
                        value: types.maybeNull(types.number),
                    }),
                ),
            }),
        ),
        meta: types.optional(
            types.model({
                isOpen: false,
            }),
            {},
        ),
        maximumLimit: types.maybeNull(types.number),
        minimumRetention: types.maybeNull(types.number),
    })
    .volatile((self) => ({
        isOpen: !self.coverageDenied,
        loading: false,
    }))
    .views((self) => ({
        get emptyIndexes() {
            return Object.keys(self.calculatedRates)
                .filter((k) => self.calculatedRates[k] === null)
                .map((s) => parseInt(s))
                .sort()
                .map((s) => String(s));
        },
        get allowedLimits(): number[] {
            const quote = getParent(self, 2) as any;
            const insuranceLineValue = self.insuranceLine.value as InsuranceLineKeyEnum;
            const maximumLimit = self.maximumLimit ?? Infinity;
            let options: any = LIMITS_AND_RETENTION_OPTIONS[insuranceLineValue];
            if (quote.isAdmitted && insuranceLineValue !== InsuranceLineKeyEnum.MPL) {
                options = ADMITTED_LIMITS_AND_RETENTION_OPTIONS[insuranceLineValue];
                return options.limits.filter((value: number) => value <= maximumLimit);
            }
            return options.limits.filter((value: number) => value <= maximumLimit);
        },

        get allowedRetention(): number[] {
            const quote = getParent(self, 2) as any;
            const insuranceLineValue = self.insuranceLine.value as InsuranceLineKeyEnum;
            const minimumRetention = self.minimumRetention ?? 0;
            let options: any = LIMITS_AND_RETENTION_OPTIONS[insuranceLineValue];
            if (quote.isAdmitted && insuranceLineValue !== InsuranceLineKeyEnum.MPL) {
                options = ADMITTED_LIMITS_AND_RETENTION_OPTIONS[insuranceLineValue];
                return options.retention.filter((value: number) => value >= minimumRetention);
            }
            return options.retention.filter((value: number) => value >= minimumRetention);
        },
    }))
    .views((self) => ({
        get canAddAnotherRate(): boolean {
            return !!self.emptyIndexes.length;
        },
    }))
    .actions((self) => {
        let cancelSetSelectedIndex: (() => void) | undefined;

        return {
            setLoading(isLoading: boolean) {
                self.loading = isLoading;
            },
            toggleCoverage: flow(function* toggleCoverage() {
                const oldValue = self.coverageDenied;
                try {
                    self.coverageDenied = !self.coverageDenied;
                    yield patchQuoteComponent(self.id, {
                        coverageDenied: self.coverageDenied,
                    });
                    const store = getParent(self, 3) as any;
                    if (self.coverageDenied) {
                        self.isOpen = false;
                    }
                    if (store.quote.sharedLimits) {
                        store.changeSharedLimits(null, false);
                    }
                } catch (error) {
                    console.error(error);
                    toast.error("A error happened when trying to change the coverage");
                    self.coverageDenied = oldValue; //eslint-disable-line require-atomic-updates
                }
            }),
            removeRate(index: string | number) {
                self.calculatedRates[index] = null;
            },
            calculateVisualPremium(_quote: QuoteStore) {
                return formatCurrency(self.premium);
            },
            setSelectedIndex: flow(function* setSelectedIndex(selectedIndex: number, publicSecurityToken: string, appSecurityToken: string) {
                if (cancelSetSelectedIndex) cancelSetSelectedIndex();

                const oldSelectedIndex = self.selectedIndex;
                const oldLimit = self.limits;
                const oldRetention = self.retention;
                const oldPremium = self.premium;
                try {
                    self.selectedIndex = selectedIndex;
                    self.loading = true;
                    const newRate = self.calculatedRates[String(selectedIndex)];
                    assert(newRate);
                    self.limits = newRate.limit;
                    self.retention = newRate.retention;
                    self.premium = newRate.premium;
                    const { result, cancel } = cancelable(patchQuoteComponent)(self.id, {
                        selectedIndex,
                    }, {params: {appSecurityToken, publicSecurityToken}});
                    cancelSetSelectedIndex = cancel;
                    const data = yield result;
                    applySnapshot(self, data);
                    cancelSetSelectedIndex = undefined;
                } catch (error) {
                    if (error.isCancelled) {
                        return;
                    }
                    toast.error("Was not possible to change the selected index");
                    cancelSetSelectedIndex = undefined;
                    self.selectedIndex = oldSelectedIndex;
                    self.limits = oldLimit;
                    self.retention = oldRetention;
                    self.premium = oldPremium;
                } finally {
                    self.loading = false;
                }
            }),
            setHiddenOptions: flow(function* setHiddenOptions(optionIndex: string) {
                const oldValue = self.hiddenOptions;

                try {
                    if (self.hiddenOptions.includes(optionIndex)) {
                        self.hiddenOptions.splice(self.hiddenOptions.indexOf(optionIndex), 1);
                    } else {
                        self.hiddenOptions.push(optionIndex);
                    }
                    yield patchQuoteComponent(self.id, {
                        hiddenOptions: self.hiddenOptions,
                    });
                } catch (error) {
                    console.error(error);
                    toast.error("Something went wrong, please try again");
                    self.hiddenOptions = oldValue;
                }
            }),
            setNewSubcoverage: flow(function* setNewSubcoverage(
                key: string,
                e: any,
                quoteId: string,
            ) {
                const { value } = e.target;
                let insuranceLine = self.insuranceLine.value.toLowerCase();
                if (insuranceLine === "fiduciary") {
                    insuranceLine = "fi";
                }

                const coverage: ChangeSubcoveragesCoveragelineEnum = insuranceLine as ChangeSubcoveragesCoveragelineEnum;

                if (coverage === undefined) {
                    console.error(insuranceLine + " is not a valid option for a component");
                    toast.error("Something went wrong, please try again");
                    return false;
                }

                try {
                    yield changeSubcoverages(
                        {
                            coverageOverrides: { [key]: value },
                            coverageLine: coverage,
                        },
                        quoteId,
                    );

                    const { result, cancel } = cancelable(patchQuoteComponent)(self.id, {
                        selectedIndex: self.selectedIndex,
                    });
                    cancelSetSelectedIndex = cancel;
                    const data = yield result;
                    applySnapshot(self, data);
                    return true;
                } catch (er) {
                    console.error(er);
                    toast.error("Something went wrong, please try again");
                    return false;
                }
            }),
            addNewRate() {
                if (!self.canAddAnotherRate) return;
                const index = self.emptyIndexes[0];
                try {
                    const limit = self.allowedLimits[0];
                    const retention = self.allowedRetention[self.allowedRetention.length - 1];
                    const newRate = CalculatedRate.create({
                        limit,
                        retention,
                        index: String(index),
                        premium: "0",
                    });
                    newRate.edit();
                    self.calculatedRates[String(index)] = newRate;
                } catch (error) {
                    logger.error(error);
                    toast.error("Was not possible to add a new rate");
                    self.calculatedRates[String(index)] = null;
                }
            },
        };
    })
    .actions((self) => ({
        toggleOpen() {
            if (self.coverageDenied) {
                self.toggleCoverage();
            } else {
                self.isOpen = !self.isOpen;
            }
        },
    }));

export type CalculatedRateType = Instance<typeof CalculatedRate>;
export type QuoteComponentModelType = Instance<typeof QuoteComponentModel>;

export default QuoteComponentModel;
