import React from "react";
import { Form } from "formik";

import { SectionTitle, Text } from "./components/styled";
import QuestionComponent from "./components/QuestionComponent";
import MultipleUploadComponent from "./components/UploadComponent";

import { SubjectivitiesStoreType } from "./store";
import { PageTextType } from "./constants";
import styled from "styled-components";

const PageText = styled(Text as any)`
    font-size: 16px;
`;

export const SupplementalTab: React.FC<{
    store: SubjectivitiesStoreType;
    pageText: PageTextType;
}> = ({ store, pageText }) => {
    let description1 = pageText.description1
    let description2 = pageText.description2

    if (store.appWasSubmitted || store.supplementalApplicationSubmitted) {
        description1 = "Thank you for completing all subjectivities."
        if(store.isSigned) {
            description1 += " Your Broker will be in contact with final steps."
            description2 = ""
        }
    }
    return (
        <>
            {!(store.appWasSubmitted || store.supplementalApplicationSubmitted) && (
                <PageText style={{fontWeight: 'bold'}}>NOTE: Subjectivities cannot be edited once submitted</PageText>
            )}
            <PageText>{description1}</PageText>
            <PageText>{description2}</PageText>
            <Form>
                <SectionTitle style={{ marginTop: "30px" }}>Required Questions</SectionTitle>
                <PageText>{pageText.requiredDescription}</PageText>
                {store.requiredUploadSubjectivities.map((item) => (
                    <MultipleUploadComponent key={item.id} subjectivity={item} store={store} />
                ))}
                {store.requiredQuestionSubjectivities.map((item) => (
                    <QuestionComponent key={item.id} item={item} />
                ))}
                {store.optionalQuestionSubjectivities.length > 0 && (
                    <>
                        <SectionTitle>Optional Questions</SectionTitle>
                        <PageText>{pageText.optionalDescription}</PageText>
                        {store.optionalUploadSubjectivities.map((item) => (
                            <MultipleUploadComponent
                                key={item.id}
                                subjectivity={item}
                                store={store}
                            />
                        ))}
                        {store.optionalQuestionSubjectivities.map((item) => (
                            <QuestionComponent key={item.id} item={item} />
                        ))}
                    </>
                )}
            </Form>
        </>
    );
};
