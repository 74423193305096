import { createContext, useContext, useState } from "react";
import {
    FullApplicationSection,
    getInitialValues,
    AnswerFormat,
    checkJSONExpression,
    getAnswersContext,
    flatQuestionsAll,
    getBrokerageCompanyName,
} from "./helpers";
import { useApiGet, assert } from "utils";
import { sectionsWithAnswers } from ".";
import { calculateMissingAnswers } from "features/Application/sectionUtils";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

type ApplicationContextType = {
    loading: boolean;
    brokerageName?: string;
    companyName?: string;
    sections?: FullApplicationSection[];
    appSecurityToken?: string;
    error?: Error;
    initialValues?: AnswerFormat;
    additionalQuestions?: any[];
    setAdditionalQuestions?: any;
    guidelines?: { [key: string]: string } | null;
    setGuidelines?: (a: { [key: string]: string } | null) => void;
    setSections?: (sec: FullApplicationSection[]) => void;
    requiredWithError?: any;
    questionsOnError?: string[];
    setQuestionsOnError?: any;
    checkNewGuidelines?: (
        questions: FullApplicationSection,
        values: AnswerFormat,
        currentValue: any,
    ) => void;
};

export const ApplicationContext = createContext<ApplicationContextType>({ loading: true });

export function useApplicationContext() {
    return useContext(ApplicationContext);
}

export const checkGuidelinesOutsideContext = (
    oldGuidelines: Record<string, string> | null,
    section: FullApplicationSection,
    values: AnswerFormat,
): Record<string, string> | null => {
    const currentGuidelines: Record<string, string> = { ...(oldGuidelines ?? {}) };
    const context = getAnswersContext(values);
    const questions = flatQuestionsAll(section.questions);
    for (const question of questions) {
        if (!question.guidelines) continue;
        const currentValue = context[question.ratingEngineKey];
        const result = checkJSONExpression(question, { context, currentValue });
        if (result) {
            currentGuidelines[`${question.id}__0`] = result;
        } else {
            delete currentGuidelines[`${question.id}__0`];
        }
    }
    return isEmpty(currentGuidelines) ? null : currentGuidelines;
};

/**
 * Custom hook to get the initial information for the application screen
 * @param applicationID
 */
export function useApplicationInfo(
    applicationID: string,
    appSecurityToken: any,
): ApplicationContextType {
    const [guidelines, setGuidelines] = useState<{ [key: string]: string } | null>(null);
    const [loading, _sections, error] = useApiGet(
        sectionsWithAnswers,
        applicationID,
        appSecurityToken,
    );

    //eslint-disable-next-line
    const [sections, setSections] = useState<FullApplicationSection[]>();
    const [questionsOnError, setQuestionsOnError] = useState<string[]>([]);

    if (loading) return { loading };
    if (error) return { loading, error };

    const initialValues = getInitialValues(_sections as NonNullable<typeof _sections>);

    const checkNewGuidelines = (section: FullApplicationSection, values: AnswerFormat) => {
        const currentGuidelines = checkGuidelinesOutsideContext(guidelines, section, values);
        if (!isEqual(guidelines ?? {}, currentGuidelines)) {
            setGuidelines(currentGuidelines);
        }
        return currentGuidelines;
    };

    const namesFromApplication = getBrokerageCompanyName();

    return {
        loading,
        companyName: namesFromApplication["company_name"],
        brokerageName: namesFromApplication["brokerage_name"],
        error,
        sections: _sections,
        appSecurityToken,
        initialValues,
        setSections,
        guidelines,
        setGuidelines,
        checkNewGuidelines,
        questionsOnError,
        setQuestionsOnError,
    };
}

async function reviewPromise(
    applicationID: string,
    appSecurityToken: string,
    state?: { sections: FullApplicationSection[]; values: AnswerFormat },
) {
    let sectionsPromise = Promise.resolve(state || { sections: undefined, values: undefined });
    if (!state) {
        sectionsPromise = sectionsWithAnswers(applicationID, appSecurityToken).then((sections) => ({
            sections,
            values: getInitialValues(sections),
        }));
    }

    const { sections, values } = await sectionsPromise;

    assert(sections);
    assert(values);

    return {
        sections: sections,
        values: values,
    };
}

function runSectionsMissing(sections: FullApplicationSection[], values: AnswerFormat) {
    return sections.map((s) => calculateMissingAnswers(s, values)).filter((o) => o.count);
}

export function useReviewInfo(
    applicationID: string,
    appSecurityToken: string,
    state: { sections: FullApplicationSection[]; values: AnswerFormat } | undefined,
) {
    const [loading, data, error] = useApiGet(reviewPromise, applicationID, appSecurityToken, state);
    if (loading) return { loading };
    if (error) return { loading, error };
    const { sections, values } = data as NonNullable<typeof data>;

    const sectionsMissing = runSectionsMissing(sections, values);

    return { loading, sectionsMissing };
}

type AnswersContextType = {
    context?: ReturnType<typeof getAnswersContext>;
};

export const AnswersContext = createContext<AnswersContextType>({});

export function useAnswersContext() {
    const { context } = useContext(AnswersContext);
    return context;
}
