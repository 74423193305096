import React from "react";
import {
    FullApplicationSection,
    flatQuestionsWithFilter,
    AnswerFormat,
    FullQuestion,
    flatQuestions,
    isQuestionAnswered,
    checkGuidelinesOutsideContext,
} from "services/application";
import { checkDependentQuestion } from "./dependentQuestions";
import { toast } from "react-toastify";

export function getSectionUrl(section: FullApplicationSection) {
    return (
        window.location.pathname
            .split("/")
            .slice(0, -1)
            .join("/") +
        "/" +
        section.ratingEngineKey
    );
}

function getValueByQuestion(
    values: AnswerFormat,
    question: FullQuestion,
): string | string[] | null {
    const value = values[question.id + "__0"];
    if (typeof value === "object" && value !== null) {
        return Object.entries(value)
            .filter(([_, v]) => v)
            .map(([k, _]) => k);
    }
    return value;
}

function isAnswerEmpty(answer: string | number | string[] | null): boolean {
    if (answer === null) return true;
    if (Array.isArray(answer)) return !answer.length;
    return !answer;
}

export function getVisibleAndAnsweredQuestions(
    section: FullApplicationSection,
    values: AnswerFormat,
    filter?: (q: FullQuestion, v: AnswerFormat) => boolean,
): [FullQuestion[], FullQuestion[]] {
    const questionFilter = filter
        ? (q: FullQuestion, v: AnswerFormat) => checkDependentQuestion(q, v) && filter(q, v)
        : checkDependentQuestion;

    const questionsVisible = flatQuestionsWithFilter(section.questions, values, questionFilter);

    const questionsAnswered = questionsVisible.filter((question: any) => {
        const answer = getValueByQuestion(values, question);
        return !isAnswerEmpty(answer);
    });
    return [questionsVisible, questionsAnswered];
}

export function calculatePercentage(section: FullApplicationSection, values: AnswerFormat): number {
    const [questionsVisible, questionsAnswered] = getVisibleAndAnsweredQuestions(section, values);
    // Make sure if the visible questions are empty we do not divide by 0
    return Math.ceil((questionsAnswered.length / questionsVisible.length) * 100) || 0;
}

export function calculateMissingAnswers(
    section: FullApplicationSection,
    values: AnswerFormat,
    filter?: (q: FullQuestion, v: AnswerFormat) => boolean,
): { section: FullApplicationSection; count: number } {
    if (!section.isActive) return { section, count: 0 };
    const [questionsVisible, questionsAnswered] = getVisibleAndAnsweredQuestions(
        section,
        values,
        filter,
    );
    // Make sure if the visible questions are empty we do not divide by 0
    return { section, count: questionsVisible.length - questionsAnswered.length };
}

export function cantSaveAndProceedApplication(
    section: FullApplicationSection,
    values: AnswerFormat,
    setQuestionsOnError?: any,
): boolean {
    const questionsOnError: string[] = [];
    if (section.isActive) {
        const fieldsRequired = flatQuestionsWithFilter(
            section.questions,
            values,
            checkDependentQuestion,
        ).filter((q) => q.required);
        fieldsRequired?.forEach((question: any) => {
            if (isQuestionAnswered(question, values)) {
                section.onError = true;
                questionsOnError.push(question?.id);
            }
        });
    }
    setQuestionsOnError(questionsOnError);
    return !!section.onError;
}

export function checkRequiredQuestions(
    sections: FullApplicationSection[],
    values: AnswerFormat,
    setSections?: (secs: FullApplicationSection[]) => void,
    setQuestionsOnError?: any,
    questionsOnError?: any[],
): boolean {
    let questionsWithError = questionsOnError || [];
    const sectionsWithError: string[] = [];
    sections
        .filter((s: FullApplicationSection) => s.isActive)
        .forEach((s: FullApplicationSection) => {
            const fieldsRequired = flatQuestionsWithFilter(
                s.questions,
                values,
                checkDependentQuestion,
            ).filter((q) => q.required);
            fieldsRequired?.forEach((question: any) => {
                if (isQuestionAnswered(question, values)) {
                    if (!sectionsWithError.includes(s.id)) {
                        sectionsWithError.push(s.id);
                    }
                    s.onError = true;
                    questionsWithError.push(question?.id);
                } else {
                    s.onError = false;
                    questionsWithError = questionsWithError.filter(
                        (qId: string) => qId !== question?.id,
                    );
                }
            });
            if (sectionsWithError.includes(s.id)) {
                s.onError = true;
            }
        });

    if (setSections) setSections([...sections]);
    if (setQuestionsOnError) setQuestionsOnError(questionsWithError);
    return (
        sections.filter((sec: FullApplicationSection) => sec.isActive && sec.onError).length > 0 ||
        questionsWithError.length > 0
    );
}

export const canSubmitApplication = (
    sections: FullApplicationSection[],
    values: AnswerFormat,
    guidelines: Record<string, string> | null,
    setSections: (secs: FullApplicationSection[]) => void,
    setQuestionsOnError?: any,
    // eslint-disable-next-line
    questionsOnError?: any,
) => {
    const hasPendingGuidelines = !!sections
        .filter((sec) => sec.isActive)
        .map((sec) => checkGuidelinesOutsideContext(guidelines, sec, values))
        .filter(Boolean).length;
    const hasPendingRequiredQuestions = checkRequiredQuestions(
        sections.filter((sec) => sec.isActive),
        values,
        setSections,
        setQuestionsOnError,
        [],
    );
    if (hasPendingGuidelines) {
        toast.error("Sorry we are unable to offer coverage to your company based on your answers.");
    }
    if (hasPendingRequiredQuestions) {
        toast.error(
            <div>
                Please complete all required questions within the application in order to proceed.
            </div>,
        );
    }
    return !hasPendingGuidelines && !hasPendingRequiredQuestions;
};

export function clearFieldVerificationRequired(
    questionId: string | undefined,
    sections?: FullApplicationSection[],
    setSections?: (secs: FullApplicationSection[]) => void,
    setQuestionsOnError?: any,
    questionsOnError?: any,
) {
    if (sections && setSections) {
        setSections([
            ...sections.map((s: FullApplicationSection) => {
                s.onError = false;
                return s;
            }),
        ]);
    }

    document
        .querySelectorAll(`.section-required.on-error`)
        ?.forEach((e: any) => e.classList.remove("on-error"));

    document
        .querySelectorAll(`.question-${questionId}.field-required.on-error`)
        ?.forEach((e: any) => e.classList.remove("on-error"));

    if (questionId && setQuestionsOnError && questionsOnError) {
        setQuestionsOnError(questionsOnError?.filter((qId: any) => qId !== questionId));
    }
}

let flatQuestionsList: FullQuestion[] | undefined = undefined;

export function getFlatQuestionList(sections: FullApplicationSection[]) {
    if (flatQuestionsList === undefined) {
        flatQuestionsList = sections.reduce<FullQuestion[]>((list, section) => {
            for (const question of flatQuestions(section.questions)) {
                if (typeof question.validate === "function") {
                    list.push(question);
                }
            }
            return list;
        }, []);
    }
    return flatQuestionsList;
}

type AnswerMap = { [key: string]: { [key: string]: string | null } };

export function checkGuidelines(sections: FullApplicationSection[], values: AnswerFormat) {
    const questionsList = getFlatQuestionList(sections);
    const answersMap = Object.entries(values).reduce<AnswerMap>((obj, [key, value]) => {
        if (typeof value === "object" && value !== null) return obj;
        const [questionID, idx] = key.split("__");
        obj[questionID] = obj[questionID] || {};
        obj[questionID][idx] = value;
        return obj;
    }, {});
    const guidelines: { [key: string]: string } = {};

    for (const question of questionsList) {
        const answer = answersMap[question.id];
        if (!answer) continue;
        for (const [idx, value] of Object.entries(answer)) {
            if (question.type === "select" && value) {
                const optionsMap = Object.fromEntries(
                    question.options?.map((a) => [a.id, a.text]) || [],
                );
                const retValue = question.validate(optionsMap[value]);
                if (retValue) {
                    guidelines[`${question.id}__${idx}`] = retValue;
                }
            } else {
                const retValue = question.validate(value);
                if (retValue) {
                    guidelines[`${question.id}__${idx}`] = retValue;
                }
            }
        }
    }
    if (Object.keys(guidelines).length) {
        return guidelines;
    }
    return null;
}
