import {
    FormikInputField,
    LightBlueButton,
    Row,
    Tab,
    Tabs,
    CancelButton,
    InputField,
} from "components/common";
import { Body1 } from "components/typography";
import { OnePaperBackground } from "counterpart-theme";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import styled from "styled-components/macro";

import { sendRetailBrokerInvite } from "services/application";
import { checkIfRetailBrokerExists } from "services/quotes";
import { SendRetailBrokerInvite } from "CounterpartApiTypes";

import CommonContainer from "../../AccountPage/pages/CommonContainer";
import { toast } from "react-toastify";
import { getShareApplicationUrl } from "features/BrokerPage";
import { ObjectValues } from "utils/types";
import { observer } from "mobx-react-lite";
import rootStore from "services/store";

const HoverInput = styled.div`
    width: 100%;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

const CommissionTitleGray = styled.div`
    font-family: Museo Sans;
    font-style: normal;
    font-weight: 500;
    color: #cccccc;
    font-size: 14px;
`;

const CommissionTitleBlue = styled.div`
    font-family: Museo Sans;
    font-style: normal;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
`;

export function copyToClipboard(str: string) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}

const brokerInviteInitialValues: SendRetailBrokerInvite = {
    email: "",
    firstName: "",
    brokerageName: "",
    lastName: "",
    sendEmail: true,
};

const ACTIONS = {
    NONE: "NONE",
    COPY: "COPY",
    REDIRECT: "REDIRECT",
} as const;

const _onBrokerInviteSubmit = ({
    sendEmail = false,
    action = ACTIONS.NONE,
}: {
    sendEmail: boolean;
    action: ObjectValues<typeof ACTIONS>;
}) => async (values: SendRetailBrokerInvite, actions: FormikHelpers<SendRetailBrokerInvite>) => {
    try {
        values.sendEmail = sendEmail;
        actions.setSubmitting(true);
        const data = await sendRetailBrokerInvite(values);
        if (sendEmail) {
            toast.success("Invite Sent!");
        }

        if (action === ACTIONS.COPY) {
            copyToClipboard(getRetailLink(data.id));
            toast.success("Application Link Copied!");
        } else if (action === ACTIONS.REDIRECT) {
            window.open(getRetailLink(data.id));
        }
    } catch (error) {
        actions.setSubmitting(false);
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

async function _checkIfRetailBrokerExists(
    values: any,
    setRegistered: any,
    setNotRegisteredYet: any,
    setFieldValue: any,
    setFoundRetailBrokerID: any,
) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (values.email === null || !re.test(String(values.email).toLowerCase())) {
        alert("Please enter a valid email");
        return;
    }
    // Check if this retail broker exists
    try {
        const broker = await checkIfRetailBrokerExists(values.email);
        if (broker) {
            setRegistered(true);
            setFieldValue("firstName", broker.firstName);
            setFieldValue("lastName", broker.lastName);
            setFieldValue("brokerageName", broker.brokerageName);
            setFoundRetailBrokerID(broker.id);
            setNotRegisteredYet(false);
        }
    } catch (error) {
        console.log(error);
        console.log({ error });
        if (error.request && error.request.status === 490) {
            setRegistered(false);
            setNotRegisteredYet(true);
        } else {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error(
                    "Sorry, you cannot invite this person at this time as a retail broker on Counterpart",
                );
            }
        }
    }
}

function hideAndResetTheFormRetailer(
    setRegistered: any,
    setNotRegisteredYet: any,
    setFieldValue: any,
) {
    setRegistered(false);
    setFieldValue("firstName", "");
    setFieldValue("lastName", "");
    setFieldValue("brokerageName", "");
    setNotRegisteredYet(false);
}

function getRetailLink(foundRetailBrokerID: string) {
    return getShareApplicationUrl() + "&r=" + foundRetailBrokerID;
}

const RetailBrokerOptions = ({ submitYourself = false }) => {
    const [notRegisteredYet, setNotRegisteredYet] = React.useState(false);
    const [registered, setRegistered] = React.useState(false);
    const [foundRetailBrokerID, setFoundRetailBrokerID] = React.useState("");
    const [action, setAction] = React.useState<ObjectValues<typeof ACTIONS>>(ACTIONS.NONE);
    const [sendEmail, setSendEmail] = React.useState(true);
    return (
        <div style={{ paddingBottom: 50 }}>
            <Body1>
                {submitYourself && (
                    <div>
                        You can fill out and submit digital applications on behalf of your retail
                        broker&apos;s if you prefer. We still ask for the retailer&apos;s
                        information so it appears in your accounts table. You will receive a quote
                        same day when submitting an application yourself.
                    </div>
                )}
                {!submitYourself && (
                    <div>
                        By providing your retailers email address (and a few more details if they
                        have not yet completed an application) we can ensure that the application is
                        attributed to the appropriate retailer in your account.
                        <br />
                        <br />
                        After you enter these details you can select whether you would like for us
                        to email the application to the retailer directly [Send Application] or
                        generate an application for you to complete on their behalf [Start
                        Application].
                    </div>
                )}
            </Body1>
            <Formik
                initialValues={brokerInviteInitialValues}
                onSubmit={_onBrokerInviteSubmit({
                    sendEmail,
                    action,
                })}
            >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <Form>
                        <Row style={{ maxWidth: "600px" }}>
                            <FormikInputField
                                name="email"
                                label="Retail Agent Email"
                                placeholder="e.g. john@company.com"
                                type="email"
                                onChange={(e: any) => {
                                    handleChange(e);
                                    hideAndResetTheFormRetailer(
                                        setRegistered,
                                        setNotRegisteredYet,
                                        setFieldValue,
                                    );
                                }}
                            />
                            <LightBlueButton
                                style={{
                                    height: 54,
                                    marginTop: 24,
                                    display: !registered && !notRegisteredYet ? "block" : "none",
                                }}
                                onClick={() =>
                                    _checkIfRetailBrokerExists(
                                        values,
                                        setRegistered,
                                        setNotRegisteredYet,
                                        setFieldValue,
                                        setFoundRetailBrokerID,
                                    )
                                }
                                type={"button"}
                            >
                                Continue
                            </LightBlueButton>
                        </Row>
                        <div style={{ display: registered ? "block" : "none" }}>
                            {submitYourself && (
                                <Row style={{ justifyContent: "start" }}>
                                    <CancelButton
                                        disable={isSubmitting}
                                        style={{
                                            height: 54,
                                        }}
                                        onClick={() => {
                                            window.open(getRetailLink(foundRetailBrokerID));
                                        }}
                                    >
                                        START APPLICATION
                                    </CancelButton>
                                    <CancelButton
                                        className="no-hover with-border"
                                        style={{
                                            padding: "14px",
                                            fontSize: "14px",
                                        }}
                                        type="button"
                                        onClick={() => {
                                            copyToClipboard(getRetailLink(foundRetailBrokerID));
                                            toast.success("Application Link Copied!");
                                        }}
                                    >
                                        COPY LINK
                                    </CancelButton>
                                </Row>
                            )}
                            {!submitYourself && (
                                <Row style={{ justifyContent: "start" }}>
                                    <LightBlueButton
                                        disable={isSubmitting}
                                        style={{
                                            height: 54,
                                        }}
                                        type="submit"
                                        onClick={() => {
                                            setSendEmail(true);
                                            setAction(ACTIONS.NONE);
                                        }}
                                    >
                                        SEND APPLICATION
                                    </LightBlueButton>
                                    <CancelButton
                                        type="button"
                                        disable={isSubmitting}
                                        style={{
                                            height: 54,
                                        }}
                                        onClick={() => {
                                            setSendEmail(false);
                                            setAction(ACTIONS.REDIRECT);
                                            window.open(getRetailLink(foundRetailBrokerID));
                                        }}
                                    >
                                        START APPLICATION
                                    </CancelButton>
                                </Row>
                            )}
                        </div>
                        <div style={{ display: notRegisteredYet ? "block" : "none" }}>
                            <p>
                                We did not find a retail agent with this email registered to your
                                account yet. Share the following information to create an account
                                for your retail agent.
                            </p>
                            <Row>
                                <FormikInputField
                                    name="firstName"
                                    label="First Name"
                                    placeholder="e.g. John"
                                />
                                <FormikInputField
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="e.g. Smith"
                                />
                                <FormikInputField
                                    name="brokerageName"
                                    label="Brokerage Name"
                                    placeholder="e.g. John Insurance Brokers LLC"
                                />
                            </Row>
                            <Row style={{ justifyContent: "start" }}>
                                <CancelButton
                                    disable={isSubmitting}
                                    type="submit"
                                    style={{
                                        marginTop: 24,
                                        height: 54,
                                    }}
                                    onClick={() => {
                                        if (submitYourself) {
                                            setSendEmail(false);
                                            setAction(ACTIONS.REDIRECT);
                                        } else {
                                            setSendEmail(true);
                                            setAction(ACTIONS.NONE);
                                        }
                                    }}
                                >
                                    {submitYourself && <span>START APPLICATION</span>}
                                    {!submitYourself && <span>Send Email</span>}
                                </CancelButton>
                                <CancelButton
                                    disable={isSubmitting}
                                    type="submit"
                                    className="no-hover with-border"
                                    style={{
                                        fontSize: "14px",
                                        marginTop: 24,
                                        height: 54,
                                    }}
                                    onClick={() => {
                                        setSendEmail(false);
                                        setAction(ACTIONS.REDIRECT);
                                    }}
                                >
                                    START APPLICATION
                                </CancelButton>
                            </Row>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

interface HeaderProps {
    commission: string;
    issued: string;
}

const HeaderAdditionalComponent: React.FC<HeaderProps> = ({ commission, issued }) => {
    return (
        <table>
            <tr>
                <td style={{ textAlign: "right" }}>
                    <CommissionTitleGray>Commission</CommissionTitleGray>
                    <CommissionTitleBlue>{commission}</CommissionTitleBlue>
                </td>
                <td width="40px" align="center">
                    <div
                        style={{
                            width: "1px",
                            background: "#bfcad6",
                            opacity: "0.4",
                        }}
                    >
                        &nbsp;
                    </div>
                </td>
                <td style={{ textAlign: "right", maxWidth: "70px" }}>
                    <CommissionTitleGray>Issued</CommissionTitleGray>
                    <CommissionTitleBlue>{issued}</CommissionTitleBlue>
                </td>
            </tr>
        </table>
    );
};

function getHeader(commission: string, issued: string) {
    return <HeaderAdditionalComponent commission={commission} issued={issued} />;
}

const GetQuotePage = () => {
    const digitalCommission = getHeader("20%", "Same Day");
    const paperCommission = getHeader("18.5%", "2 days");
    const [currentTabCommission, setCurrentTabCommission] = React.useState(0);
    return (
        <OnePaperBackground
            style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
        >
            <CommonContainer
                title="Get A Quote"
                style={{ maxWidth: "1500px" }}
                headerAdditionalComponent={
                    currentTabCommission === 0 ? digitalCommission : paperCommission
                }
            >
                <Tabs currentActiveIndex={0} additionalHook={setCurrentTabCommission}>
                    <Tab label="Counterpart Digital App" tabIndex={0}>
                        <RetailBrokerOptions />
                    </Tab>
                    {rootStore.userInfo.adminUser && (
                        <Tab label="Start App For Wholesale Broker" tabIndex={1}>
                            <div>
                                <LightBlueButton
                                    onClick={() => {
                                        window.open(getShareApplicationUrl());
                                    }}
                                >
                                    Start Application for Wholesale Broker
                                </LightBlueButton>
                            </div>
                        </Tab>
                    )}
                    {/* <Tab label="Submit App Yourself" tabIndex={1}>
                        <RetailBrokerOptions submitYourself={true} />
                    </Tab> */}
                    <Tab label="Alternative PDF App" tabIndex={2}>
                        <div style={{ paddingBottom: 50 }}>
                            <Body1>
                                {`If you or your retail agents don't have the bandwidth to complete the Counterpart digital application, we can provide quotes using an alternative PDF application. Email the completed application pdf along with the retail broker's Name, Email, and Brokerage to:`}
                            </Body1>
                            <div style={{ marginTop: "10px" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        marginTop: 30,
                                        maxWidth: "800px",
                                    }}
                                >
                                    <HoverInput
                                        style={{ flex: 1 }}
                                        onClick={() => {
                                            window.location.href =
                                                "mailto:underwriting@yourcounterpart.com";
                                        }}
                                    >
                                        <a href="mailto: underwriting@yourcounterpart.com">
                                            <InputField
                                                label="Email"
                                                placeholder="eSignature Link"
                                                disabled={true}
                                                defaultValue={"underwriting@yourcounterpart.com"}
                                            />
                                        </a>
                                    </HoverInput>
                                    <LightBlueButton
                                        style={{
                                            marginLeft: 10,
                                            marginTop: 28,
                                            paddingLeft: 60,
                                            paddingRight: 60,
                                        }}
                                        onClick={() => {
                                            copyToClipboard("underwriting@yourcounterpart.com");
                                            toast.success("Email Address Copied");
                                        }}
                                    >
                                        COPY EMAIL
                                    </LightBlueButton>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </CommonContainer>
        </OnePaperBackground>
    );
};

export default observer(GetQuotePage);
