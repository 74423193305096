import React from "react";

import { Tabs, Tab } from "components/common";
import CommonContainer from "../CommonContainer";
import SendLink from "./SendLink";
import Download from "./Download";
import styled from "styled-components/macro";
import { H2, Body1 } from "components/typography";

import { subjectivitiesCompleted } from "../../utils";
import { formatDate } from "utils";

interface Props {
    quote: any;
}

const SharedLimitsOverlayText = styled.div``;

const Container = styled.div`
    width: 100%;
    & ${SharedLimitsOverlayText} {
        display: none;
    }

    &.outstanding-subjectivities {
        position: relative;
        padding: 10px 40px 30px 40px;
        margin-bottom: 60px;
        margin-top: 30px;
    }

    &.outstanding-subjectivities .outstanding-subjectivities-overlay {
        filter: blur(1.5px) grayscale(1);
        background-color: #fff;
        opacity: 0.4;
    }

    &.outstanding-subjectivities ${H2}, &.outstanding-subjectivities ${Body1} {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }
    &.outstanding-subjectivities ${H2} {
        margin-bottom: 30px;
    }

    &.outstanding-subjectivities ${Body1} {
        font-size: 17px;
    }

    &.outstanding-subjectivities ${SharedLimitsOverlayText} {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        cursor: not-allowed;
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        padding: 40px 100px;
        background-color: rgba(193, 202, 213, 0.2);
    }

    @media (max-width: 480px) {
        &.outstanding-subjectivities ${SharedLimitsOverlayText} {
            padding: 10px;
        }
    }
`;

const SignApplication: React.SFC<Props> = ({ quote }) => {
    const containerClass = subjectivitiesCompleted(quote, true) ? "" : "outstanding-subjectivities";
    return (
        <CommonContainer
            title="Sign Application"
            quoteInfo={{
                quoteDate: formatDate(quote.expirationDate) || "-",
                quoteNumber: quote.policyNumber || "-",
                commission: quote.commissionPercentage || "-",
            }}
            borderBottom={true}
        >
            <Container className={containerClass}>
                <div className="outstanding-subjectivities-overlay">
                    <Tabs currentActiveIndex={0}>
                        <Tab label="Send Link" tabIndex={0}>
                            <SendLink quoteId={quote.id} />
                        </Tab>
                        <Tab label="Download" tabIndex={1}>
                            <Download quoteId={quote.id} />
                        </Tab>
                    </Tabs>
                </div>
                <SharedLimitsOverlayText className="text-center">
                    <H2>Outstanding Subjectivities</H2>
                    <Body1>
                        All subjectivities must be answered and then reviewed by our underwriters
                        before you can sign the application. You will receive an email notification
                        when all subjectivities are cleared and the application is available to
                        sign.
                    </Body1>
                </SharedLimitsOverlayText>
            </Container>
        </CommonContainer>
    );
};

export default React.memo(SignApplication);
