/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { Redirect } from "react-router";

import { Formik, Form, FormikHelpers } from "formik";
import { RouteComponentProps } from "react-router";
import { parseQueryString } from "utils";

import { H1, Body1, Body3 } from "components/typography";
import { Button, Row, Alert, Input, FormButton } from "components/common";

import { auth } from "services";
import { ChangePassword } from "CounterpartApiTypes";
import { ChangePasswordFormFields } from "features/BrokerProfile/components/PasswordChanger";
import { Route } from "react-router-dom";
import { requestMagicLink } from "services/auth";
import rootStore from "services/store";
import loginBackgroundImage from "assets/login_background.png";
import counterpartLogo from "assets/counterpart_logo.svg";
import responsive from "services/responsive";
import SetRecoveryPassword from "features/Login/SetRecoveryPassword";
import { forgotPassword } from "services/users";
import SuccessIcon from "assets/SuccessIcon.svg";
import AttentionIcon from "assets/AttentionIcon.svg";
import * as Yup from "yup";

const DangerIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: 2px solid #000;
    transform: rotate(45deg);
    margin-right: 15px;

    &:before {
        content: "";
        height: 15px;
        width: 2px;
        background: #000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &:after {
        content: "";
        width: 15px;
        height: 2px;
        background: #000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`;

const initialValues = { email: "", password: "" };
const ForgotPasswordInitialValues = { email: "" };
type FormType = typeof initialValues;

const ErrorMessage: React.FC<{ formStatus: { type: string; message: string } }> = ({
    formStatus,
}) => {
    if (!formStatus.message) return null;

    if (formStatus.type === "error") {
        return (
            <Alert color="danger">
                <DangerIcon />
                {formStatus.message}
            </Alert>
        );
    } else if (formStatus.type === "success") {
        return (
            <Alert color="success">
                <img src={SuccessIcon} alt="Success icon" />
                {formStatus.message}
            </Alert>
        );
    } else {
        return (
            <Alert color="neutral">
                <img src={AttentionIcon} alt="attention icon" />
                {formStatus.message}
            </Alert>
        );
    }
};

const StyledRow = styled(Row as any)`
    @media (max-width: 767px) {
        margin: 0 0 20px 0;
    }
`;

const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: 20px;

    ${Button} {
        line-height: 16px;
        font-size: 16px;
        min-width: unset !important;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }

        @media (max-width: 768px) {
            font-size: 14px;
        }

        @media (max-width: 415px) {
            font-size: 14px;
        }
    }

    a {
        @media (max-width: 415px) {
            font-size: 14px;
        }
    }
`;

const Page = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    background: #fff;
    padding: 22px;

    grid-gap: 30px;

    @media (max-width: 1440px) {
        grid-gap: 20px;
    }
    ${responsive.Tablet} & {
        grid-gap: 0;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0;
        grid-template-rows: auto 1fr;
    }

    ${Body3} {
        color: #6e6e6e;
        @media (max-width: 415px) {
            font-size: 14px;
        }
    }

    ${Button} {
        line-height: 16px;
        width: auto;
        min-width: 200px;
        display: block;
        margin-right: auto;
    }

`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${responsive.Tablet} & {
        padding: 20px;

        @media (max-width: 768px) {
            padding-top: 50px;
        }
    }

    @media (max-width: 768px) {
        padding: 20px;
        align-items: flex-start;
    }
`;

const LoginFormContainer = styled.div`
    width: 452px;

    ${responsive.Mobile} &, ${responsive.Tablet} & {
        width: 100%;
    }

    ${Body1} {
        font-size: 26px;
        line-height: 130%;
        color: #6E6E6E;

        @media (max-width: 1440px) {
            font-size: 20px;
        }

        @media (max-width: 768px) {
            font-size: 19px;
        }
    }

    a:not(.asButton) {
        color: #6e6e6e;
        transition: color .3s;
        text-decoration: underline;

        &:hover {
            color: #242221;
        }
    }
`;
const WelcomeH1 = styled(H1 as any)`
    font-size: 60px;
    font-weight: bold;
    line-height: 130%;
    text-transformation: "uppercase";
    color: ${({ theme }) => theme.colors.primary};

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 43px;
    }

    ${responsive.Mobile} & {
        font-size: 36px;
        line-height: 43px;
    }
`;

const WelcomeBody1 = styled(Body1 as any)`
    margin: 20px 0 40px;

    @media (max-width: 414px) {
        margin: 7px 0 20px 0;
    }
`;

const LeftContainer = styled.div`
    background-image: url(${loginBackgroundImage});
    background-size: cover;
    background-position: center;
    display: flex;
    padding: 40px;

    @media (max-width: 768px) {
        align-items: center;
        padding: 20px;
        height: 200px;
    }

    a {
        display: block;
        align-self: flex-start;

        & img {
            width: 294px;

            @media (max-width: 415px) {
                width: 180px;
                height: auto;
            }
        }
    }
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: #c2c2c2;
    margin: 60px 0;

    @media (max-width: 415px) {
        margin: 30px 0;
    }
`;

const LinkLikeButton = styled(Button as any)`
    & {
        background: none;
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
        border: none;
        padding: 0;
        min-height: unset;
        min-width: unset !important;

        &:hover:not(:disabled) {
            background: none;
            color: ${({ theme }) => theme.colors.primary};
            border: none;
            opacity: 0.6;
        }
    }
`;

type LoginPageProps = RouteComponentProps<{}>;

const submitLogin = (history: any, location: any, setState: any) => async (
    { email, password }: FormType,
    actions: FormikHelpers<FormType>,
    setFormState: any,
) => {
    try {
        await auth.login(email, password);

        if (location.state && location.state.from) {
            history.push(location.state.from);
        } else {
            history.push("/dashboard");
        }
    } catch (error) {
        if (error instanceof auth.ChangePasswordRequiredError) {
            setState({
                needsToChangePassword: true,
                access: error.accessToken,
                refresh: error.refreshToken,
            });
        } else {
            const errorData = error.response && error.response.data;
            if (errorData) {
                if (errorData.detail) {
                    setFormState((s: any) => ({ ...s, type: "error", message: errorData.detail }));
                } else if (errorData.nonFieldErrors) {
                    if (typeof errorData.nonFieldErrors == "string") {
                        setFormState((s: any) => ({
                            ...s,
                            type: "error",
                            message: errorData.nonFieldErrors,
                        }));
                    } else if (Array.isArray(errorData.nonFieldErrors)) {
                        const msg = errorData.nonFieldErrors.join("\n");
                        setFormState((s: any) => ({ ...s, type: "error", message: msg }));
                    }
                } else {
                    setFormState((s: any) => ({ ...s, type: "error", message: errorData }));
                }
                actions.setFieldValue("password", "");
            } else {
                setFormState((s: any) => ({
                    ...s,
                    type: "error",
                    message: "Something went wrong",
                }));
                throw error;
            }
        }
    }
};

const submitChangePasswordForm = (history: any, location: any, state: any) => async (
    values: ChangePassword,
    actions: FormikHelpers<ChangePassword>,
) => {
    actions.setSubmitting(true);
    try {
        await auth.authChangePassword(values, state.access);
        auth.saveToken(state.access, state.refresh);
        if (location.state && location.state.from) {
            history.push(location.state.from);
        } else {
            history.push("/");
        }
    } catch (error) {
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    } finally {
        actions.setSubmitting(false);
    }
};

async function sendMagicLink(email: string, setLoading: any, setFormStatus: any) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
        return;
    }
    if (email === null || !re.test(email)) {
        setFormStatus((s: any) => ({
            ...s,
            type: "error",
            message: "Please enter a valid email",
            sent: false,
        }));
        return;
    }
    setLoading(true);
    try {
        await requestMagicLink({ email: email });
        setFormStatus((s: any) => ({
            ...s,
            type: "success",
            message: "If you’re registered, check your Email to Login",
            sent: true,
        }));
    } catch (error) {
        setFormStatus((s: any) => ({
            ...s,
            type: "error",
            message: "An error ocurred, email not sent!",
            sent: false,
        }));
    }
    setLoading(false);
}

interface SubFormType {
    onLogin: (v: FormType, a: FormikHelpers<FormType>, c: any) => void;
    setScreen: (a: string) => void;
    loading: boolean;
    setLoading: (a: boolean) => void;
}

const ForgotPasswordForm: React.FC<SubFormType> = ({ setScreen, loading, setLoading }) => {
    const [formStatus, setFormStatus] = useState({
        type: "neutral",
        message: "",
        sent: false,
    });

    return (
        <>
            <WelcomeH1>Forgot Password</WelcomeH1>
            <WelcomeBody1>Don’t worry! Happens to the best of us.</WelcomeBody1>
            <Formik
                initialValues={ForgotPasswordInitialValues}
                onSubmit={async (data) => {
                    setLoading(true);
                    try {
                        await forgotPassword(data);
                        setFormStatus((s: any) => ({
                            ...s,
                            sent: true,
                            type: "success",
                            message: "A recovery email was sent to your address",
                        }));
                        setLoading(false);
                    } catch (error) {
                        setFormStatus((s: any) => ({
                            ...s,
                            type: "error",
                            message: "Please try again in a few minutes",
                            sent: false,
                        }));
                        setLoading(false);
                        console.error(error);
                    }
                }}
                validateOnBlur={true}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email("Please enter correct email")
                        .required("Required"),
                })}
            >
                <Form
                    onChange={() =>
                        setFormStatus((s: any) => ({ ...s, type: "neutral", message: "" }))
                    }
                >
                    <ErrorMessage formStatus={formStatus} />
                    {formStatus.sent ? (
                        `If the email doesn't show up soon, check your spam folder. We sent it from confirm@counterpart.com.`
                    ) : (
                        <Row>
                            <Input placeholder="Email" name="email" ariaLabel="Email Address" />
                        </Row>
                    )}
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                        {formStatus.sent ? (
                            <Button
                                style={{ height: 50 }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setScreen("magicLinkScreen");
                                }}
                            >
                                Return to Login
                            </Button>
                        ) : (
                            <FormButton
                                loading={loading}
                                disabled={loading || formStatus.sent}
                                type="submit"
                            >
                                Send recovery link
                            </FormButton>
                        )}
                    </div>
                    <Divider />
                    <Body3>
                        Prefer a password-free sign in experience?
                        <br />
                        <a href="#" onClick={() => setScreen("magicLinkScreen")}>
                            Click here
                        </a>{" "}
                        to get a magic login link.
                    </Body3>
                </Form>
            </Formik>
        </>
    );
};

const ManualLoginForm: React.FC<SubFormType> = ({ onLogin, setScreen, loading, setLoading }) => {
    const [formStatus, setFormStatus] = useState({
        type: "neutral",
        message: "",
        sent: false,
    });

    return (
        <>
            <a href="https://yourcounterpart.com/">
                <img src={counterpartLogo} alt="Counterpart Logo"></img>
            </a>
            <WelcomeH1>Welcome Back!</WelcomeH1>
            <WelcomeBody1>If you’re a policyholder, please login <a href="https://app.zerohr.com/">here</a></WelcomeBody1>
            <Formik
                initialValues={initialValues}
                onSubmit={async (a: any, b: any) => {
                    setLoading(true);
                    await onLogin(a, b, setFormStatus);
                    setLoading(false);
                }}
                validateOnBlur={true}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email("Please enter correct email")
                        .required("Required"),
                    password: Yup.string().required("Required"),
                })}
            >
                <Form
                    onChange={() =>
                        setFormStatus((s: any) => ({ ...s, type: "neutral", message: "" }))
                    }
                >
                    <ErrorMessage formStatus={formStatus} />
                    <StyledRow>
                        <Input placeholder="Email" name="email" ariaLabel="Email Address" />
                    </StyledRow>
                    <StyledRow>
                        <Input
                            placeholder="Password"
                            name="password"
                            ariaLabel="password"
                            type="password"
                            style={{ paddingRight: 32 }}
                        />
                    </StyledRow>
                    <ButtonContainer>
                        <a href="#" onClick={() => setScreen("forgotPassword")}>
                            Forgot your password?
                        </a>
                        <FormButton
                            loading={loading}
                            disabled={loading || formStatus.sent}
                            style={{ width: "100%", height: 50 }}
                            type="submit"
                        >
                            Sign In
                        </FormButton>
                    </ButtonContainer>
                    <Divider />
                    <Body3>
                        Prefer a password-free sign in experience?
                        <br />
                        <a href="#" onClick={() => setScreen("magicLinkScreen")}>
                            Click here
                        </a>{" "}
                        to get a magic login link.
                    </Body3>
                </Form>
            </Formik>
        </>
    );
};

const MagicLinkForm: React.FC<SubFormType> = ({ setScreen, loading, setLoading }) => {
    const initialFormStatus = {
        type: "neutral",
        message: "",
        sent: false,
    };

    const [formStatus, setFormStatus] = useState(initialFormStatus);

    return (
        <>
                <a href="https://yourcounterpart.com/">
                    <img src={counterpartLogo} alt="Counterpart Logo"></img>
                </a>
            <WelcomeH1>Welcome Back!</WelcomeH1>
            <WelcomeBody1>If you’re a policyholder, please login <a href="https://app.zerohr.com/">here</a></WelcomeBody1>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    sendMagicLink(values.email, setLoading, setFormStatus);
                }}
                validateOnBlur={true}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email("Please enter correct email")
                        .required("Required"),
                })}
            >
                {(props) => (
                    <Form
                        onChange={() =>
                            setFormStatus((s: any) => ({ ...s, type: "neutral", message: "" }))
                        }
                    >
                        <ErrorMessage formStatus={formStatus} />
                        {formStatus.sent ? (
                            `If the email doesn't show up soon, check your spam folder. We sent it from confirm@counterpart.com.`
                        ) : (
                            <StyledRow>
                                <Input
                                    placeholder="Email"
                                    name="email"
                                    ariaLabel="Email Address"
                                    required
                                />
                            </StyledRow>
                        )}
                        <>
                            {formStatus.sent ? (
                                <div style={{ marginTop: 20 }}>
                                    <LinkLikeButton
                                        onClick={() => {
                                            props.resetForm();
                                            setFormStatus(initialFormStatus);
                                        }}
                                    >
                                        Re-send again
                                    </LinkLikeButton>
                                </div>
                            ) : (
                                <div style={{ textAlign: "center" }}>
                                    <FormButton
                                        loading={loading}
                                        disabled={loading || formStatus.sent}
                                        style={{ height: 50 }}
                                        type="submit"
                                    >
                                        Sign In
                                    </FormButton>
                                </div>
                            )}
                        </>
                        <Divider />
                        <Body3>
                            We&apos;ll email you a magic code for a password-free sign in.
                            <br />
                            Or you can{" "}
                            <a href="#" onClick={() => setScreen("manualLoginScreen")}>
                                sign in manually instead.
                            </a>
                        </Body3>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const Forms: any = {
    forgotPassword: ForgotPasswordForm,
    manualLoginScreen: ManualLoginForm,
    magicLinkScreen: MagicLinkForm,
};

const LoginForms: React.FC<any> = ({ location, history }) => {
    const [loading, setLoading] = React.useState(false);

    const [state, setState] = useState({
        needsToChangePassword: false,
        access: "",
        refresh: "",
    });

    const [screen, setScreen] = useState<string>(
        parseQueryString()["manual"] === "true" ? "manualLoginScreen" : "magicLinkScreen",
    );

    const onLogin = useCallback(
        async (a, b, c) => {
            const submitFunction = await submitLogin(history, location, setState);
            return submitFunction(a, b, c);
        },
        [location, history, setState],
    );

    const onChangePassword = useCallback(submitChangePasswordForm(history, location, state), [
        history,
        location,
        state,
    ]);

    const FormComponent = Forms[screen];

    return (
        <>
            {state.needsToChangePassword ? (
                <>
                    <WelcomeH1>Change your password</WelcomeH1>
                    <Alert color="neutral">
                        To ensure your account security, please update your password.
                    </Alert>
                    <ChangePasswordFormFields onSubmit={onChangePassword} />
                </>
            ) : (
                <FormComponent
                    onLogin={onLogin}
                    setScreen={setScreen}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
        </>
    );
};

const LoginPage: React.FC<LoginPageProps> = () => {
    if (rootStore.userInfo && rootStore.userInfo.email) {
        return <Redirect to="/" />;
    }

    return (
        <Page>
            <LeftContainer />
            <Container>
                <LoginFormContainer className="login-box-container">
                    <Route component={SetRecoveryPassword} path="/set_password" />
                    <Route component={LoginForms} path="/login" />
                </LoginFormContainer>
            </Container>
        </Page>
    );
};

export default LoginPage;
