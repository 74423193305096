import React from "react";
import styled from "styled-components/macro";
// import { useHistory, useRouteMatch } from "react-router-dom";

import { OnePaperBackground } from "counterpart-theme";
import { H4, SectionSelectorText, Body2, BaseFont } from "components/typography";

import CheckMark from "assets/round-check-green.svg";
import BlueMark from "assets/round-check-blue.svg";
import counterpartIcon from "assets/counterpart_icon.svg";
import pdfIcon from "assets/pdf-nopaper-icon.svg";
import classNames from "classnames";
import { useAccountStore, QuoteComponentStore } from "../store";

// import { effectiveCompleted, subjectivitiesCompleted } from "../utils";
import { observer } from "mobx-react-lite";
import { assert } from "utils";
import { getBaseURL } from "../../../utils";
import { logger } from "services";

interface MenuItemProps {
    label: string;
    completed: boolean;
    selected: boolean;
    enabled?: boolean;
    onClick?: () => void;
}

interface Props {
    quoteComponent?: QuoteComponentStore;
    publicSecurityToken?: string;
    appSecurityToken?: string;
}

export const MarkMenu = styled.div<{ completed: boolean; selected: boolean }>`
    position: absolute;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.textColor};
    box-sizing: border-box;
    border-radius: 50%;
    left: -11px;
    top: -5px;

    ${({ selected, completed }) =>
        selected && !completed
            ? `
            border-color: #111da1;
            background-image: url(${BlueMark});
            background-size: 8px 8px;
        `
            : ``}

    ${({ completed }) =>
        completed
            ? `
            width: 22px;
            height: 22px;
            background-image: url(${CheckMark});
            border-width: none;
            border-style: none;
            border-color: none;
        `
            : ``}
`;

export const SidemenuContainer = styled.div`
    ${BaseFont};
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-width: 340px;

    @media (max-width: 480px) {
        min-width: 0;
        width: 100%;
    }
`;

export const MenuItemContainer = styled.div`
    position: relative;
    display: flex;
    height: 50px;
    border-left: 2px solid ${(props) => props.theme.colors.third};
    padding: 0 0 30px 20px;
    margin-left: 11px;

    &:last-child {
        border-left: none;
        & > ${MarkMenu} {
            left: -9px;
        }
    }
`;

export const CustomButton = styled.button`
    margin-top: -10px;
    background-color: transparent;
    text-align: left;
    border: none;
    box-shadow: none;
    outline: none;

    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }
`;

const ButtonText = styled(SectionSelectorText as any)`
    &.selected {
        color: #111da1;
        font-weight: 500;
    }
`;

const StyledOnePaperBackground = styled(OnePaperBackground as any)`
    margin-top: 20px;

    @media (max-width: 768px) {
        margin-top: -20px;
    }
`;

export const StyledDownloadPdfButton = styled.a`
    cursor: pointer;
    background: #ffffff;
    &:not(:last-child) {
        border-bottom: 1px solid #dbdbdb;
    }
    cursor: pointer;
    text-transform: capitalize !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    color: ${(props) => props.theme.colors.textColor};

    & svg path {
        fill: ${({ theme }) => theme.colors.accentColor};
    }

    &:hover {
        color: ${({ theme }) => theme.colors.accentColor};

        & svg path {
            fill: #496197;
        }
    }

    & > span {
        display: inline-block;
        text-align: left;
        flex: auto;
        margin-left: 10px;
    }

    & > img {
        height: 20px;
        width: 20px;
    }

    @media (min-width: 767px) and (max-width: 1024px) {
        border-bottom: 1px solid ${(props) => props.theme.colors.third};
    }

    @media (max-width: 768px) {
        min-width: 100%;
    }
`;

type DownloadPdfButtonProps = React.ComponentProps<typeof StyledDownloadPdfButton>;

export const DownloadPdfButton: React.FC<DownloadPdfButtonProps> = ({
    href,
    children,
    publicSecurityToken,
    appSecurityToken,
    ...rest
}) => {
    const onClick = async (ev: React.MouseEvent) => {
        ev.preventDefault();
        try {
            // const token = await auth.getAccessTokenAsync();
            const url = `${href}?publicSecurityToken=${publicSecurityToken}&appSecurityToken=${appSecurityToken}`
            window.open(url);
        } catch (error) {
            logger.error(error);
            alert("Was not possible to download this file");
        }
    };
    return (
        <StyledDownloadPdfButton {...rest} onClick={onClick}>
            {children}
        </StyledDownloadPdfButton>
    );
};

export const MenuItem: React.FC<MenuItemProps> = ({
    label,
    onClick,
    completed,
    selected,
    enabled = true,
}) => {
    if (enabled)
        return (
            <MenuItemContainer>
                <MarkMenu completed={completed} selected={selected} />
                <CustomButton onClick={onClick}>
                    <ButtonText className={classNames({ selected })}>{label}</ButtonText>
                </CustomButton>
            </MenuItemContainer>
        );
    else return <></>;
};

const SidebarMenu: React.FC<Props> = observer(({publicSecurityToken, appSecurityToken}) => {
    // const match = useRouteMatch();
    // const history = useHistory();

    const { quote, policy } = useAccountStore();
    assert(quote);

    const getDownloadUrl = () => {
        const BASE_URL = getBaseURL();
        return `${BASE_URL}/api/quotes/${quote.id}/download`;
    };

    return (
        <StyledOnePaperBackground>
            <SidemenuContainer>
                <H4 style={{ fontWeight: 500, marginBottom: 20, fontSize: 22 }}>
                    <strong>Documents</strong>
                </H4>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        margin: "auto",
                        width: "100%",
                    }}
                >
                    {quote.isInBinderStatus && (
                        <DownloadPdfButton
                            publicSecurityToken={publicSecurityToken}
                            appSecurityToken={appSecurityToken}
                            style={{ maxWidth: "100%" }}
                            href={getDownloadUrl() + "/policy-bundle"}
                        >
                            <img src={counterpartIcon} alt="PDF Icon" width="25px" />
                            <Body2 as="span">Binder Bundle</Body2>
                            <svg
                                width="20"
                                height="20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                fill="#BFCAD6"
                            />
                        </svg>
                    </DownloadPdfButton>
                    )}
                    {quote.finalized && (
                        <>
                            <DownloadPdfButton
                                style={{ maxWidth: "100%" }}
                                publicSecurityToken={publicSecurityToken}
                                appSecurityToken={appSecurityToken}
                                href={getDownloadUrl() + "/policy-bundle"}
                            >
                                <img src={counterpartIcon} alt="PDF Icon" width="25px" />
                                <Body2 as="span">Policy Bundle</Body2>
                                <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                        fill="#BFCAD6"
                                    />
                                </svg>
                            </DownloadPdfButton>
                            {policy?.invoices.map((invoice: any) => {
                                if (invoice.invoicePdf) {
                                    return (
                                        <StyledDownloadPdfButton
                                            style={{ maxWidth: "100%" }}
                                            href={invoice.invoicePdf}
                                            target="_blank"
                                        >
                                            <img src={pdfIcon} alt="Invoice" />
                                            <Body2 style={{ textTransform: "none" }} as="span">
                                                Invoice.pdf
                                            </Body2>
                                            <svg
                                                width="20"
                                                height="20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                                    fill="#BFCAD6"
                                                />
                                            </svg>
                                        </StyledDownloadPdfButton>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                            {quote.accountType !== "EXCESS" && (
                                <StyledDownloadPdfButton
                                    // style={{ minWidth: "100%" }}
                                    href={
                                        "https://counterpart-public.s3.us-west-2.amazonaws.com/HR%2BCounterpart%2BOne-Pager.pdf"
                                    }
                                >
                                    <img src={pdfIcon} alt="PDF Icon" />
                                    <Body2 style={{ textTransform: "none" }} as="span">
                                        HR Counterpart.pdf
                                    </Body2>
                                    <div>
                                        <svg
                                            width="20"
                                            height="20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                                fill="#BFCAD6"
                                            />
                                        </svg>
                                    </div>
                                </StyledDownloadPdfButton>
                            )}
                            {/* <DownloadPdfButton href={getDownloadUrl() + "/signed-application"}>
                                <img src={pdfIcon} alt="PDF Icon" />
                                <Body2 as="span">Signed Application</Body2>
                                <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                        fill="#BFCAD6"
                                    />
                                </svg>
                            </DownloadPdfButton> */}
                            {/* <DownloadPdfButton
                                href={getDownloadUrl() + "/declaration-bundle"}
                                target="_blank"
                            >
                                <img src={pdfIcon} alt="Declaration" />
                                <Body2 as="span">Declaration Page</Body2>
                                <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                        fill="#BFCAD6"
                                    />
                                </svg>
                            </DownloadPdfButton> */}
                        </>
                    )}
                    {!quote.finalized && (
                        <>
                            <DownloadPdfButton
                                href={getDownloadUrl() + "/quote-bundle"}
                                publicSecurityToken={publicSecurityToken}
                                appSecurityToken={appSecurityToken}
                                target="_blank"
                            >
                                <img src={pdfIcon} alt="Declaration" />
                                <Body2 as="span">Quote</Body2>
                                <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                        fill="#BFCAD6"
                                    />
                                </svg>
                            </DownloadPdfButton>
                            <DownloadPdfButton
                                href={getDownloadUrl() + "/application"}
                                publicSecurityToken={publicSecurityToken}
                                appSecurityToken={appSecurityToken}
                                target="_blank"
                            >
                                <img src={pdfIcon} alt="Application" />
                                <Body2 as="span">Application</Body2>
                                <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                        fill="#BFCAD6"
                                    />
                                </svg>
                            </DownloadPdfButton>
                            <DownloadPdfButton
                                href={getDownloadUrl() + "/specimen"}
                                publicSecurityToken={publicSecurityToken}
                                appSecurityToken={appSecurityToken}
                                target="_blank"
                            >
                                <img src={pdfIcon} alt="Policy Specimen" />
                                <Body2 as="span">Policy Specimen</Body2>
                                <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                        fill="#BFCAD6"
                                    />
                                </svg>
                            </DownloadPdfButton>
                        </>
                    )}
                    {/* {quote.hasEndorsementData && (
                        <DownloadPdfButton
                            href={getDownloadUrl() + "/endorsements-bundle"}
                            target="_blank"
                        >
                            <img src={pdfIcon} alt="Endorsement" />
                            <Body2 as="span">Endorsements</Body2>
                            <svg
                                width="20"
                                height="20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
                                    fill="#BFCAD6"
                                />
                            </svg>
                        </DownloadPdfButton>
                    )} */}
                </div>
            </SidemenuContainer>
        </StyledOnePaperBackground>
    );
});

export default SidebarMenu;
