/* eslint-disable react/jsx-no-target-blank */
import { ArialBaseCSS } from "components/typography";
import React from "react";
import responsive from "services/responsive";
import styled from "styled-components/macro";

interface FooterProps {
    style?: any;
}

const FooterContainer = styled.div`
    ${ArialBaseCSS};
    width: 100%;
    max-width: 1350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 150%;
    color: rgb(34, 35, 46, 0.3);
    margin-top: 50px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 900px) {
        justify-content: center;
    }

    & a,
    & a:visited,
    & a:hover {
        color: rgb(34, 35, 46, 0.3);
        text-decoration: none;
        padding: 0 10px;
        white-space: nowrap;

        &:last-child {
            padding: 0;
        }
    }
`;

const MenuContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    ${responsive.Mobile} & {
        justify-content: center;
    }
`;

const Footer: React.FC<FooterProps> = ({ style }) => {
    return (
        <FooterContainer style={style}>
            <div>&copy; {new Date().getFullYear()}, Counterpart, Inc. All rights reserved.</div>
            <MenuContainer>
                <a href="//yourcounterpart.com/terms" rel="noopener noreferrer" target="_blank">
                    Terms of Service
                </a>
                <a href="//yourcounterpart.com/privacy" rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                </a>
            </MenuContainer>
        </FooterContainer>
    );
};

export default React.memo(Footer);
