import React from "react";
import styled from "styled-components/macro";
import { Page } from "components/common";
import { H4, MuseoSansBaseCSS, CaptionText, Body2, SmallLinkText } from "components/typography";
import { DownloadPdfButton } from "../../components/styled";

import pdfIcon from "assets/pdf-nopaper-icon.svg";
import arrowRightIcon from "assets/arrow-right-icon.svg";
import { getBaseURL } from "utils";
import { useAccountStore } from "features/AccountPage/store";

const Text = styled.span`
    ${MuseoSansBaseCSS}
    line-height: 150%;
`;

const StyledDiv = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 480px) {
        flex-direction: column;
    }
`;

interface Props {
    quoteId: string;
}

const Download: React.SFC<Props> = ({ quoteId }) => {
    const { quote, appSecurityToken, publicSecurityToken } = useAccountStore();

    const getDownloadUrl = () => {
        const BASE_URL = getBaseURL();
        return `${BASE_URL}/api/quotes/${quoteId}/download`;
    };

    return (
        <Page>
            <StyledDiv style={{ flexDirection: "column" }}>
                <H4 style={{ fontWeight: 500 }}>Download</H4>
                <Text>Download a digital copy of the application and email us a signed copy.</Text>
            </StyledDiv>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 30 }}>
                <CaptionText>Application PDF</CaptionText>
                <DownloadPdfButton
                    style={{ minWidth: "100%" }}
                    href={getDownloadUrl() + "/application-signature-bundle"}
                    appSecurityToken={appSecurityToken}
                    publicSecurityToken={publicSecurityToken}
                >
                    <img src={pdfIcon} alt="PDF Icon" />
                    <Body2 as="span">{`${quote?.company?.name} application PDF`}</Body2>
                    <div>
                        <SmallLinkText>DOWNLOAD</SmallLinkText>
                        <img
                            style={{ transform: "rotate(90deg)", marginLeft: 10 }}
                            src={arrowRightIcon}
                            alt="arrow right icon"
                        />
                    </div>
                </DownloadPdfButton>
            </div>
        </Page>
    );
};

export default React.memo(Download);
