import React from "react";
import styled from "styled-components/macro";
import { Formik, Form, FormikProps } from "formik";

import { noop } from "utils";
import { FormikInputField, Row, FormikRadioCheckButton as RadioCheckButton } from "components/common";
import { Body2, H4 } from "components/typography";
import responsive from "services/responsive";
import { SignatureValues, SubjectivitiesStoreType, getValidationSchema } from "./store";

const FormContainerFormik = styled.div`
    ${responsive.InternetExplorer} & {
        margin-top: 100px;
    }
`;

const List = styled.ul`
    padding-left: 24px;
`;

const ListItem = styled.li`
    font-size: 16px;
    line-height: 24px;
`;

const PageText = styled(Body2 as any)`
    font-size: 16px;
`;

const getInitialValues = (requireHrData = false): SignatureValues => ({
    title: "",
    name: "",
    email: "",
    phone: "",
    sameAsSignatory: false,
    ...(requireHrData ? { hrName: "", hrTitle: "", hrEmail: "", hrPhone: "" } : {}),
});

const FormFields: React.FC<{
    store: SubjectivitiesStoreType;
    formikProps: FormikProps<SignatureValues>;
}> = ({ store, formikProps }) => {
    const onSameAsSignatoryChange = (e: any) => {
        const { checked } = e.target

        if (checked) {
            Object.entries(formikProps.values).forEach(([key, value]) => {
                if (value) {
                    formikProps.setFieldValue(`hr${key.charAt(0).toUpperCase()}${key.slice(1)}`, value);
                }
            })
        } else {
            Object.entries(formikProps.values).forEach(([key, value]) => {
                if (value) {
                    formikProps.setFieldValue(`hr${key.charAt(0).toUpperCase()}${key.slice(1)}`, '');
                }
            })
        }
    }

    const handleFormChange = (e: any) => {
        const { name, value } = e.target;

        if (e.target.type !== 'checkbox' && formikProps.values.sameAsSignatory && !name.includes('hr')) {
            formikProps.setFieldValue(`hr${name.charAt(0).toUpperCase()}${name.slice(1)}`, value);
        } else if (e.target.type !== 'checkbox' && formikProps.values.sameAsSignatory && name.includes('hr')) {
            formikProps.setFieldValue(`sameAsSignatory`, false);
        }
    }

    store.setSignatureValues(formikProps.values);
    return (
         <Form style={{ margin: "auto", opacity: (store.appIsSubmitting ? .5 : 1) }} onChange={handleFormChange}>
            <H4>Signatory Info</H4>
            <PageText>
                Please provide the following information in order to sign the application.
            </PageText>
            <Row style={{ padding: "0px", margin: "0px" }}>
                <FormikInputField
                    className="row-field"
                    name="name"
                    placeholder="e.g. John Smith"
                    label="Full name"
                    tooltipText="Your full name"
                    disabled={store.appIsSubmitting}
                    style={{ minWidth: "200px" }}
                />
                <FormikInputField
                    className="row-field"
                    name="title"
                    placeholder="e.g. CEO"
                    label="Title"
                    tooltipText="Your title"
                    disabled={store.appIsSubmitting}
                    style={{ minWidth: "200px" }}
                />
            </Row>
            <Row style={{ paddingTop: "10px", marginTop: "10px" }}>
                <FormikInputField
                    className="row-field"
                    name="email"
                    placeholder="john@company.com"
                    label="Email"
                    tooltipText="Your business email address. NOTE: Your copy of the Application will be emailed here."
                    disabled={store.appIsSubmitting}
                    style={{ minWidth: "200px" }}
                />
                <FormikInputField
                    className="row-field"
                    name="phone"
                    placeholder="310-555-1209"
                    label="Phone"
                    tooltipText="Your business phone number."
                    disabled={store.appIsSubmitting}
                    style={{ minWidth: "200px" }}
                />
            </Row>
            <FormContainerFormik>
                <H4 style={{ marginTop: "50px" }}>Enroll in HR Counterpart</H4>
                <PageText style={{ marginBottom: 10 }}>
                    Whether you're handling a complex employee circumstance or you need help navigating state compliance laws, Counterpart offers a host of complimentary HR Employee services to help you stay protected and prevent lawsuits:
                </PageText>
                <List>
                    <ListItem>
                        <strong>HR Toolkit</strong>: Tools including a handbook builder, policy templates, and more
                    </ListItem>
                    <ListItem>
                        <strong>HR Experts</strong>: Fast answers to pressing HR questions
                    </ListItem>
                    <ListItem>
                        <strong>Content Library</strong>: Information on federal and state policies, regulations, and more
                    </ListItem>
                    <ListItem>
                        <strong>Employee Training</strong>: On-demand training modules for teams
                    </ListItem>
                </List>
                <PageText>
                    Please provide the contact information of the person in charge of HR at your company.
                </PageText>
                <Row style={{ justifyContent: 'flex-start', margin: '20px 0 10px 0', }}>
                    <RadioCheckButton
                        checkboxStyle={{ marginLeft: 0 }}
                        className="single-check"
                        name="sameAsSignatory"
                        type="checkbox"
                        label="Same as the signatory"
                        onChange={onSameAsSignatoryChange}
                    />
                </Row>
                <Row
                    style={{
                        paddingTop: "10px",
                        marginTop: "10px",
                    }}
                >
                    <FormikInputField
                        className="row-field"
                        name="hrName"
                        placeholder="e.g. John Smith"
                        label="HR Leader Full name"
                        disabled={store.appIsSubmitting}
                        style={{ minWidth: "200px" }}
                    />
                    <FormikInputField
                        className="row-field"
                        name="hrTitle"
                        placeholder="e.g. CEO"
                        label="HR Leader Title"
                        disabled={store.appIsSubmitting}
                        style={{ minWidth: "200px" }}
                    />
                </Row>
                <Row
                    style={{
                        paddingTop: "10px",
                        marginTop: "10px",
                    }}
                >
                    <FormikInputField
                        className="row-field"
                        name="hrEmail"
                        placeholder="john@company.com"
                        label="HR Leader's Email"
                        disabled={store.appIsSubmitting}
                        style={{ minWidth: "200px" }}
                    />
                    <FormikInputField
                        className="row-field"
                        name="hrPhone"
                        placeholder="310-555-1209"
                        label="HR Leader's Phone"
                        disabled={store.appIsSubmitting}
                        style={{ minWidth: "200px" }}
                    />
                </Row>
                <PageText>
                    Please be advised that the HR Counterpart services provided are subject to change without prior notice. The service provider reserves the right to modify, alter, or discontinue any aspect or feature of the services, including but not limited to its availability, functionality, content, or pricing, at any time and at its sole discretion. By utilizing these services, you acknowledge and agree that the service provider shall not be liable to you or any third party for any modification, suspension, or termination of the services.
                </PageText>
            </FormContainerFormik>
        </Form>
    );
};

export const SignatureTab: React.FC<{
    store: SubjectivitiesStoreType;
}> = ({ store }) => {
    return (
        <>
            <Formik
                initialValues={getInitialValues(true)}
                onSubmit={noop}
                validationSchema={getValidationSchema(store.hasEpliCoverage)}
                autoComplete="off"
            >
                {(formikProps) => <FormFields store={store} formikProps={formikProps} />}
            </Formik>
        </>
    );
};
