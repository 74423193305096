import * as React from "react";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";
// import { observer } from "mobx-react-lite";
import { H2, H3, Body3 } from "components/typography";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { QuoteStore } from "../store";

import Loading from "components/common/Loading";
import { SelectTypeAhead } from "components/common";

import {
    BlueDivider,
    Row,
    StackedPaper,
    Button,
    FormikInputField,
    RadioCheckButton,
} from "components/common";

import { parseQueryString } from "utils";

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 44% 56%;
    grid-row-gap: 10px;
    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-row-gap: 0;
    }
`;

const LeftAlignedRow = styled(Row as any)`
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    min-height: 54px;
    @media (max-width: 480px) {
        flex-direction: row;
        min-height: auto;
    }
`;

const HeadingRow = styled(Row as any)`
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 8px 0;
    p {
        font-size: 12px;
    }
    @media (max-width: 480px) {
        display: none;
    }
`;

const MobileHeadingRow = styled(Row as any)`
    justify-content: flex-start;
    text-transform: uppercase;
    margin: 0 0 8px 0;
    p {
        font-size: 12px;
    }
    @media (min-width: 481px) {
        display: none;
    }
`;

const ButtonWrapper = styled.div`
    grid-column: 1 / 3;
    @media (max-width: 480px) {
        grid-column: 1 / 2;
    }
`;

const FormButton = styled(Button as any)`
    font-weight: 900 !important;
    width: 240px !important;
    height: 50px;
    margin: 25px auto 0 auto;
    @media (max-width: 480px) {
        width: 100% !important;
    }
`;

const CustomRowLabel = styled.span`
    opacity: 0.4;
    @media (max-width: 480px) {
        display: none;
    }
`;

const InputRow = styled(LeftAlignedRow as any)`
    @media (max-width: 480px) {
        padding-bottom: ${(props) => (props.showRow ? "20px" : "0")};
    }
`;

const FormDivider = styled(BlueDivider as any)`
    margin: 10px auto 25px;
    width: 60px;
    @media (max-width: 480px) {
        margin: 10px auto 25px 0;
    }
`;

const FormInfo = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 620px;
    @media (max-width: 480px) {
        text-align: left;
    }
`;

const StyledStackedPaper = styled(StackedPaper as any)`
    text-align: center;
    position: relative;
    max-width: 760px;
    margin: auto;
`;

interface Checkboxes {
    [key: string]: boolean;
    pricing: boolean;
    retention: boolean;
    limit: boolean;
    coverage: boolean;
    other: boolean;
}

interface FormValues {
    pricing?: number;
    retention?: number;
    limit?: number;
    coverage?: string;
    other?: string;
    carrierName?: string;
}

const initialValues = {
    pricing: null,
    retention: null,
    limit: null,
    coverage: null,
    other: null,
    carrierName: null,
};

const initialCheckboxesValues = {
    pricing: false,
    retention: false,
    limit: false,
    coverage: false,
    other: false,
};

const ModifiedRow: React.FC<{ children: React.ReactNode; showRow: boolean }> = ({
    children,
    showRow,
}) => (
    <InputRow showRow={showRow}>
        {showRow ? <>{children}</> : <CustomRowLabel>-</CustomRowLabel>}
    </InputRow>
);

const QuoteFeedback = (quote: QuoteStore) => {
    const [checkboxes, setCheckboxes] = useState<Checkboxes>(initialCheckboxesValues);
    const [successfullySent, setSuccessfullySent] = useState(false);
    if (!quote) return null;

    if (quote["quote"]) {
        quote = quote["quote"];
    }

    // const checkIfFilled = (checkboxes: any, formValues: any) => {
    //     let res = true;
    //     for (const key in checkboxes) {
    //         if (checkboxes[key] && !formValues[key]) {
    //             res = false;
    //             break;
    //         }
    //     }
    //     return res;
    // };

    const onSubmit = async (formValues: any) => {
        if (
            !Object.values(formValues).length ||
            formValues.carrierName === "" ||
            formValues.carrierName === null
        ) {
            toast.error("Please select the Winning Terms of this Account on the form");
        } else {
            Object.keys(checkboxes).forEach((key) => {
                if (!checkboxes[key]) {
                    delete formValues[key];
                }
            });
            const updatedTheAccount = await quote.setQuoteFeedback(formValues);
            if (updatedTheAccount) {
                setSuccessfullySent(true);
            }
        }
    };

    const setChecked = (key: string) => {
        setCheckboxes((state) => {
            return {
                ...state,
                [key]: !state[key],
            };
        });
    };

    const isAnyChecked = (checkboxes: any) => !Object.values(checkboxes).some((element) => element);

    let { brokerId, token } = parseQueryString();
    brokerId = brokerId ?? "";
    token = token ?? "";

    let nextAccountComponent = "/accounts-needing-feedback/?thanks=true";

    if (brokerId !== "" && token !== "") {
        nextAccountComponent += "&brokerId=" + brokerId + "&token=" + token;
    }

    const carrierOptions = Object.fromEntries(
        quote.carrierOptions.map((key: string) => [key, key]),
    );

    return (
        <StyledStackedPaper>
            {successfullySent ? (
                <>
                    <Loading message="" />
                    <Redirect to={nextAccountComponent} />
                </>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <FormInfo>
                            <H2>Quote Feedback For:</H2>
                            <H3>{quote.company.name}</H3>
                            <Body3 style={{ lineHeight: "150%", opacity: 0.8 }}>
                                Now that we have closed the account, please help to share a few more
                                details so we can better support you and your clients in the future.
                            </Body3>
                        </FormInfo>
                        <FormDivider />
                    </div>
                    <Body3 style={{ textAlign: "left", fontWeight: "bold" }}>
                        Describe why the quote Counterpart presented was not selected along with
                        details about the winning quote:
                    </Body3>
                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        {(props) => {
                            const { values, setFieldValue } = props;
                            return (
                                <Form>
                                    <GridContainer>
                                        <HeadingRow>
                                            <Body3>REASON FOR LOSS</Body3>
                                        </HeadingRow>

                                        <HeadingRow>
                                            <Body3>WINNING TERMS</Body3>
                                        </HeadingRow>

                                        <MobileHeadingRow>
                                            <Body3>select Reasons and complete with details</Body3>
                                        </MobileHeadingRow>

                                        <LeftAlignedRow>
                                            <RadioCheckButton
                                                checkboxStyle={{ marginLeft: "0px" }}
                                                className="single-check"
                                                type="checkbox"
                                                label={"Pricing"}
                                                checked={checkboxes.pricing}
                                                onClick={() => setChecked("pricing")}
                                                value=""
                                            />
                                        </LeftAlignedRow>

                                        <ModifiedRow showRow={checkboxes.pricing}>
                                            <FormikInputField
                                                className="row-fields"
                                                name="pricing"
                                                placeholder="e.g. $8,500"
                                                value={values.pricing}
                                            />
                                        </ModifiedRow>

                                        <LeftAlignedRow>
                                            <RadioCheckButton
                                                checkboxStyle={{ marginLeft: "0px" }}
                                                className="single-check"
                                                type="checkbox"
                                                name="retention"
                                                label={"Retention"}
                                                checked={checkboxes.retention}
                                                onClick={() => setChecked("retention")}
                                                value=""
                                            />
                                        </LeftAlignedRow>

                                        <ModifiedRow showRow={checkboxes.retention}>
                                            <FormikInputField
                                                className="row-fields"
                                                name="retention"
                                                placeholder="e.g. $10,000"
                                                type="text"
                                                value={values.retention}
                                            />
                                        </ModifiedRow>

                                        <LeftAlignedRow>
                                            <RadioCheckButton
                                                checkboxStyle={{ marginLeft: "0px" }}
                                                className="single-check"
                                                type="checkbox"
                                                label={"Limit"}
                                                checked={checkboxes.limit}
                                                onClick={() => setChecked("limit")}
                                                value=""
                                            />
                                        </LeftAlignedRow>

                                        <ModifiedRow showRow={checkboxes.limit}>
                                            <FormikInputField
                                                className="row-fields"
                                                name="limit"
                                                placeholder="e.g. $2,000,000"
                                                value={values.limit}
                                            />
                                        </ModifiedRow>

                                        <LeftAlignedRow>
                                            <RadioCheckButton
                                                checkboxStyle={{ marginLeft: "0px" }}
                                                className="single-check"
                                                type="checkbox"
                                                label={"Coverage"}
                                                checked={checkboxes.coverage}
                                                onClick={() => setChecked("coverage")}
                                                value=""
                                            />
                                        </LeftAlignedRow>

                                        <ModifiedRow showRow={checkboxes.coverage}>
                                            <FormikInputField
                                                className="row-fields"
                                                name="coverage"
                                                placeholder="e.g. wage and hour limits of $150,000"
                                                value={values.coverage}
                                            />
                                        </ModifiedRow>

                                        <LeftAlignedRow>
                                            <RadioCheckButton
                                                checkboxStyle={{ marginLeft: "0px" }}
                                                className="single-check"
                                                type="checkbox"
                                                label={"Other"}
                                                checked={checkboxes.other}
                                                onClick={() => setChecked("other")}
                                                value=""
                                            />
                                        </LeftAlignedRow>

                                        <ModifiedRow showRow={checkboxes.other}>
                                            <FormikInputField
                                                className="row-fields"
                                                name="other"
                                                placeholder="e.g. bundled with other coverages"
                                                value={values.other}
                                            />
                                        </ModifiedRow>

                                        <LeftAlignedRow style={{ marginTop: 20 }}>
                                            <Body3
                                                style={{ alignSelf: "flex-end", margin: "auto 0" }}
                                            >
                                                Which carrier’s quote was selected?
                                            </Body3>
                                        </LeftAlignedRow>

                                        <LeftAlignedRow style={{ marginTop: 20 }}>
                                            <SelectTypeAhead
                                                name="carrierName"
                                                placeholder="Type to search carriers"
                                                value=""
                                                addEmptyOption
                                                options={carrierOptions}
                                                onChange={(ev: any) => {
                                                    console.log(ev[0]);
                                                    if (ev.length >= 1) {
                                                        setFieldValue("carrierName", ev[0]["id"]);
                                                    } else {
                                                        setFieldValue("carrierName", "");
                                                    }
                                                }}
                                                label="Winning Carrier"
                                            />
                                        </LeftAlignedRow>
                                        <ButtonWrapper>
                                            <FormButton
                                                type="submit"
                                                disabled={isAnyChecked(checkboxes)}
                                            >
                                                Share feedback
                                            </FormButton>
                                        </ButtonWrapper>
                                    </GridContainer>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </StyledStackedPaper>
    );
};

export default QuoteFeedback;
