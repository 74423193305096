import { types, applySnapshot, flow } from "mobx-state-tree";
import { clearAlerts, getAlertsList } from "../generated/alerts";
import * as logger from "../logger";
import { Alert } from "CounterpartApiTypes";
import groupBy from "lodash/groupBy";

export interface AlertID extends Alert {
    id: string;
}

export const AlertsStore = types
    .model({
        alerts: types.map(
            types.array(
                types.model({
                    id: types.identifier,
                    model: types.string,
                    uuid: types.string,
                    eventType: types.string,
                    eventTypeDisplay: types.string,
                }),
            ),
        ),
    })
    .views((self) => ({
        get alertCount(): number {
            return self.alerts.size;
        },
    }))
    .actions((self) => ({
        clear: flow(function* clear(model: string, uuid: string) {
            try {
                const newAlerts: AlertID[] = yield clearAlerts({ model, uuid });
                applySnapshot(self.alerts, groupBy(newAlerts, "uuid"));
            } catch (error) {
                logger.error(error);
            }
        }),
        load: flow(function* load() {
            try {
                const newAlerts: AlertID[] = yield getAlertsList();
                applySnapshot(self.alerts, groupBy(newAlerts, "uuid"));
            } catch (error) {
                logger.error(error);
            }
        }),
    }))
    .actions((self) => ({
        afterCreate() {
            self.load();
        },
    }));
