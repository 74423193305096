import React, { useState, useEffect, useCallback, useRef } from "react";
import throttle from "lodash/throttle";
import moment from "moment";
import styled from "styled-components/macro";
import { OnePaperBackground } from "counterpart-theme";
import { H4, H2, SectionSelectorText, Body3, BaseFont } from "components/typography";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { formatCurrency } from "utils";
import { observer } from "mobx-react-lite";
import { Tooltip, TooltipContainer } from "counterpart-theme";
import { Tooltip as RactTooltip } from "reactstrap";

import CheckMark from "assets/round-check-green.svg";
import { BlockWithLoader } from "./QuoteComponentDetails";
import BlueMark from "assets/round-check-blue.svg";
import classNames from "classnames";
import { useAccountStore, QuoteComponentStore } from "../store";

import { Button, Modal } from "components/common";
import { CancelButton } from "components/common/Button";

import { assert } from "utils";
import { canBindCoverages } from "../utils";
import { toast } from "react-toastify";
import { auth } from "services";

interface MenuItemProps {
    label: string;
    completed: boolean;
    selected: boolean;
    enabled?: boolean;
    onClick?: () => void;
}

interface Props {
    quoteComponent?: QuoteComponentStore;
    publicSecurityToken?: string;
    appSecurityToken?: string;
    bindQuoteToken?: string;
}

export const MarkMenu = styled.div<{ completed: boolean; selected: boolean }>`
    position: absolute;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.textColor};
    box-sizing: border-box;
    border-radius: 50%;
    left: -11px;
    top: -5px;

    ${({ selected, completed }) =>
        selected && !completed
            ? `
            border-color: #111da1;
            background-image: url(${BlueMark});
            background-size: 8px 8px;
        `
            : ``}

    ${({ completed }) =>
        completed
            ? `
            width: 22px;
            height: 22px;
            background-image: url(${CheckMark});
            border-width: none;
            border-style: none;
            border-color: none;
        `
            : ``}
`;

export const SidemenuContainer = styled.div<{ fixedPos?: boolean }>`
    ${BaseFont};
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-width: 340px;

    ${({ fixedPos }) =>
        fixedPos &&
        `
        position: fixed;
        top: 100px;
        z-index: 100;
    `}

    @media (max-width: 768px) {
        & > ${H4} {
            display: none;
        }
    }

    @media (max-width: 480px) {
        min-width: 0;
        width: 100%;
    }
`;

export const MenuItemContainer = styled.div`
    position: relative;
    display: flex;
    height: 50px;
    border-left: 2px solid ${(props) => props.theme.colors.third};
    padding: 0 0 30px 20px;
    margin-left: 11px;

    &:last-child {
        border-left: none;
        & > ${MarkMenu} {
            left: -9px;
        }
    }
`;

export const CustomButton = styled.button`
    margin-top: -10px;
    background-color: transparent;
    text-align: left;
    border: none;
    box-shadow: none;
    outline: none;

    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }
`;

const ButtonText = styled(SectionSelectorText as any)`
    &.selected {
        color: #111da1;
        font-weight: 500;
    }
`;

const StyledOnePaperBackground = styled(OnePaperBackground as any)<{ fixedPos?: boolean }>`
    ${({ fixedPos }) =>
        fixedPos &&
        `
        position: fixed;
        top: 111px;
        z-index: 100;
        width: 100%;

        @media (max-width: 480px) {
            top: 103px;
        }
    `}

    @media (max-width: 768px) {
        margin-bottom: 30px;
    }

    border-radius: 10px;
`;

const SidemenuRow = styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        display: grid;
        grid-template-columns: 175px 125px;
    }

    &.total-row {
        display: flex;
    }

    @media (max-width: 1024px) {
        display: flex !important;
        justify-content: space-between;
    }

    & > div > div {
        margin-left: auto;
    }

    strong {
        text-align: right;
        color: ${({ theme }) => theme.colors.darkGray};
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    a {
        color: #22232e;
        transition: color 0.3s;
        border-bottom: 1px dashed #22232e;

        &:hover {
            color: ${({ theme }) => theme.colors.accentColor};
            text-decoration: none;
        }

        @media (max-width: 768px) {
            color: #22232e;
            text-decoration: none;
            border-bottom: 1px dashed #22232e;
        }
    }
`;

const MobileSidebarTop = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 767px) {
        display: none;
    }
`;

const MobileTitleBlock = styled.div`
    margin-bottom: 10px;

    p {
        font-size: 18px;
        text-align: center;
    }
`;

const MobileDetailsBlock = styled.div`
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    padding: 0 calc((100% - 340px) / 2);

    span {
        color: rgba(34, 35, 46, 0.5);
        margin-right: 2px;
    }

    div {
        display: flex;
    }
`;

const StyledButton = styled(Button as any)<{ active: boolean }>`
    position: fixed;
    top: 61px;
    left: 0;
    z-index: 100;
    width: 100% !important;
    border-radius: 0 !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px !important;

    @media (max-width: 480px) {
        top: 52px;
    }

    ${({ active }) =>
        active &&
        `
        svg {
            transform: rotate(180deg);
        }
    `}

    &:hover {
        opacity: 1 !important;
    }
`;

const Overlay = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
    background: #00000045;
`;

const PriceChangeRow = styled.div`
    display: grid;
    grid-template-columns: 248px 1fr 1fr;
    padding: 0 20px;

    @media (max-width: 676px) {
        grid-template-columns: 1.8fr 1fr 1fr;
    }

    & > div {
        display: flex;
        align-items: center;

        @media (max-width: 678px) {
            font-size: 14px;
        }

        &:first-child {
            @media (max-width: 678px) {
                padding-right: 10px;
            }
        }
    }

    &:not(:first-child) {
        background-color: #c4c4c419;
        padding: 12px 20px;
    }

    &:nth-child(2) {
        position: relative;

        &:after {
            content: "";
            height: 1px;
            width: calc(100% - 40px);
            left: 20px;
            bottom: 0;
            position: absolute;
            background: #c4c4c433;
        }
    }
`;

const PriceChangeTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media (max-width: 678px) {
        font-size: 14px;
        margin-bottom: 20px;
    }
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 52px;

    @media (max-width: 678px) {
        padding: 0;
    }

    ${H2} {
        text-align: center;
        margin-top: -15px;
        margin-bottom: 20px;

        @media (max-width: 678px) {
            font-size: 18px;
        }
    }
`;

const ModalBottomText = styled.div`
    color: #22232ecc;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    padding: 0px 52px 38px;

    @media (max-width: 678px) {
        padding: 0px 0 38px;
    }
`;

const StyledBody3 = styled(Body3 as any)`
    margin-bottom: 40px;
    text-align: center;
    font-size: 18px;

    @media (max-width: 678px) {
        font-size: 14px;
        margin-bottom: 20px;
    }
`;

const Discount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7px;
    color: #fff;
    /* background-color: #12b3dc; */
    background-color: #4BAA8F;
    font-size: 10px;
    line-height: 20px;
    /* margin-left: 6px; */
    margin-right: 10px;
    align-self: center;
    min-width: 45px;
    border-radius: 10px;
`;

const Value = styled.div<{ premiumRow?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: 125px;

    @media (max-width: 340px) {
        display: flex;
        min-width: unset;
    }

    ${({ premiumRow }) =>
        premiumRow &&
        `
        min-width: unset;
        display: block;
    `}
`;

const DiscountName = styled.span`
    text-transform: capitalize;
`;

const StyledTooltip = styled(Tooltip as any)<{ noTooltip: boolean }>`
    background: none;
    transition: filter 0.2s;

    ${({ noTooltip }) =>
        !noTooltip &&
        `
        cursor: pointer;
    `}

    .tooltip-inner {
        max-width: unset;
    }

    &:hover {
        box-shadow: none;

        @media (min-width: 769px) {
            ${({ noTooltip }) =>
                !noTooltip &&
                `
                filter: brightness(80%);
            `}
        }
    }
`;

const QuestionIcon = styled.div`
    margin-left: 6px;
    display: flex;
    align-items: center;

    &:hover {
        svg {
            filter: brightness(120%);
            path {
                fill: #12b3dc;
            }
        }
    }
`;

export const MenuItem: React.FC<MenuItemProps> = ({
    label,
    onClick,
    completed,
    selected,
    enabled = true,
}) => {
    if (enabled)
        return (
            <MenuItemContainer>
                <MarkMenu completed={completed} selected={selected} />
                <CustomButton onClick={onClick}>
                    <ButtonText className={classNames({ selected })}>{label}</ButtonText>
                </CustomButton>
            </MenuItemContainer>
        );
    else return <></>;
};

const ValueComponent: React.FC<{
    discountAmount: any;
    value?: any;
    uniqueKey: string;
    name: string;
    discounts?: any;
    premiumRow?: boolean;
    message?: string;
    hideTooltip?: boolean;
    isSurplusLine?: boolean;
    loading?: boolean;
}> = ({
    discountAmount,
    value,
    uniqueKey,
    discounts,
    name,
    premiumRow,
    message,
    hideTooltip = false,
    isSurplusLine,
    loading = false,
}) => {
    const [opened, setOpened] = useState(false);

    const toggle = () => {
        setOpened(!opened);
    };

    const handleClose = useCallback(() => {
        if (opened) {
            setOpened(false);
        }
    }, [opened]);

    const throttled = useCallback(throttle(handleClose, 200), [opened]);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            document.addEventListener("scroll", throttled);
            document.addEventListener("click", handleClose);
            return () => {
                document.removeEventListener("scroll", throttled);
                document.removeEventListener("scroll", handleClose);
            };
        }
    }, [throttled, handleClose]);

    return (
        <BlockWithLoader loading={loading} style={{ marginTop: 5 }}>
            {discounts ? (
                <Value premiumRow={premiumRow}>
                    <TooltipContainer
                        style={{ zIndex: 1, display: "flex", justifyContent: "center" }}
                    >
                        <StyledTooltip id={"sidebarTooltip_" + uniqueKey} noTooltip={hideTooltip}>
                            {discountAmount && (
                                <>
                                    {typeof discountAmount === "number" ? (
                                        <Discount>
                                            {!isSurplusLine && "-"}
                                            {discountAmount.toFixed(1).replace(/\.0$/, "")}%
                                        </Discount>
                                    ) : (
                                        <span>{discountAmount}</span>
                                    )}
                                </>
                            )}
                        </StyledTooltip>
                        {!hideTooltip && (
                            <RactTooltip
                                placement="top"
                                target={"sidebarTooltip_" + uniqueKey}
                                style={{ maxWidth: 255, padding: 20, paddingBottom: 0 }}
                                trigger={window.innerWidth <= 768 ? "click" : "hover"}
                                isOpen={opened}
                                toggle={toggle}
                            >
                                <p style={{ marginBottom: 8 }}>
                                    {message
                                        ? message
                                        : `Discounts are reflected in the ${name} Due:`}
                                </p>
                                {discounts.map((discount: any, index: number) => (
                                    <p
                                        key={`discount_${discount.name}_${discount.value}_${index}`}
                                        style={{
                                            fontWeight: "bold",
                                            color: "#4BAA8F",
                                            lineHeight: "19px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginBottom: 8,
                                        }}
                                    >
                                        <DiscountName>
                                            {discount.name
                                                .split("_")
                                                .join(" ")
                                                .toLowerCase()}
                                        </DiscountName>{" "}
                                        <span>-{discount.value}%</span>
                                    </p>
                                ))}
                            </RactTooltip>
                        )}
                    </TooltipContainer>
                    {value && <strong>{value}</strong>}
                </Value>
            ) : (
                <strong>{value}</strong>
            )}
        </BlockWithLoader>
    );
};

const SidebarMenu: React.FC<Props> = observer(({publicSecurityToken, appSecurityToken }) => {
    const { quote, bindSelectedCoverage, requestToBindFromBroker } = useAccountStore();
    assert(quote);
    const history = useHistory();
    const match = useRouteMatch();
    const sideMenuContainer = useRef<HTMLInputElement>(null);

    const [submitModal, setSubmitModal] = useState(false);
    const [requestToBindModal, setRequestToBindModal] = useState(false);
    const [priceChangeModal, setPriceChangeModal] = useState(false);
    const [showTopMenu, setShowTopMenu] = useState(false);

    const [selectedUrl, setSelectedUrl] = useState(
        window.location.href.substring(window.location.href.lastIndexOf("/") + 1),
    );

    const [showTopButton, setShowTopButton] = useState(false);
    const hasAuth = !!auth.getUserID();

    const allLinesDiscounts = quote.discountsApplied?.allLines;
    let allLinesDiscountSum = 1;
    quote.discountsApplied?.allLines.forEach((discount: any) => {
        if (discount.value === 1) {
            return;
        }
        allLinesDiscountSum = allLinesDiscountSum * discount.value;
    });
    history.listen((location) => {
        setSelectedUrl(location.pathname.substring(location.pathname.lastIndexOf("/") + 1));
    });

    async function bindCoverageCheck() {
        if (quote?.effectiveDate) {
            if (hasAuth) {
                if (quote.premiumOnIssueQuote && quote.premiumOnIssueQuote !== quote.totalPremiumCalc) {
                    setPriceChangeModal(true);
                } else {
                    setSubmitModal(true);
                }
            } else {
                setRequestToBindModal(true);
            }
        } else {
            alert("Please select an effective date to bind coverage");
        }
    }

    const handleScroll = () => {
        const pos = sideMenuContainer?.current?.getBoundingClientRect();
        if (pos && window.innerWidth <= 768) {
            const showButtonPos = pos?.y + pos?.height;
            if (showButtonPos < 0) {
                setShowTopButton(true);
            } else if (showButtonPos > 0) {
                setShowTopButton(false);
                setShowTopMenu(false);
            }
        }
    };

    const throttled = useCallback(throttle(handleScroll, 100), []);

    useEffect(() => {
        document.addEventListener("scroll", throttled);
        return () => document.removeEventListener("scroll", throttled);
    }, [throttled]);

    let premiumLoading = false;

    {
        let isTriggered = false;
        quote.components.forEach((component: any, index: number, arr: any) => {
            if (!isTriggered && !premiumLoading && component.loading) {
                premiumLoading = true;
                isTriggered = true;
            } else if (!isTriggered && premiumLoading && index === arr.length - 1) {
                premiumLoading = false;
            }
        });
    }

    const formatDate = (dateStr: string) => moment(dateStr).format("MM/DD/YY");

    const getDiscountAmount = (all: number, line: number) => {
        if (!line && !all) {
            return "";
        } else if (!line && all) {
            return all;
        } else if (!all && line) {
            return line;
        } else {
            return all + line;
        }
    };

    const getDiscountsArray = (all: any, line: any) => {
        if (!line && !all) {
            return [];
        } else if (!line && all) {
            return all;
        } else if (!all && line) {
            return line;
        } else {
            return all.concat(line);
        }
    };

    const getAllDiscounts = (discountsApplied: any) => {
        return (
            discountsApplied &&
            Object.values(discountsApplied)
                .filter((item: any) => !!item)
                .reduce((acc: any, i: any) => acc.concat(i))
        );
    };

    const allDiscounts = getAllDiscounts(quote.discountsApplied);

    interface Taxes {
        [key: string]: any;
    }

    const taxesSum = (quote?.taxesAndFees || 0) && Object.entries(quote?.taxesAndFees).reduce((acc: any, [, tax]: any) => {
        if (tax) {
            if (tax.amountType === "percentage") {
                return Math.round(acc + +quote.totalPremiumCalc * (tax?.amount / 100));
            } else {
                return Math.round(acc + tax?.amount);
            }
        } else {
            return acc;
        }
    }, 0);

    return (
        <>
            <StyledOnePaperBackground ref={sideMenuContainer}>
                {showTopButton && (
                    <StyledButton
                        onClick={() => setShowTopMenu((state) => !state)}
                        active={showTopMenu}
                    >
                        <span>Total Due: {formatCurrency(quote.totalPremiumCalc)}</span>

                        <svg width="16" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 1L7.837 7 1 1" stroke="#fff" strokeWidth="2" />
                        </svg>
                    </StyledButton>
                )}
                <SidemenuContainer>
                    <MobileSidebarTop>
                        <MobileTitleBlock>
                            <p>
                                <strong
                                    style={{ fontWeight: "bold" }}
                                >{`${quote.statusText}`}</strong>
                                <br />
                                <span>{quote.company.name}</span>
                            </p>
                        </MobileTitleBlock>
                        <MobileDetailsBlock>
                            <div>
                                <span>POLICY:</span>
                                <p>
                                    <strong>{quote.policyNumber}</strong>
                                </p>
                            </div>
                            <div>
                                <span>EXPIRES:</span>
                                <p>
                                    <strong>
                                        {quote?.expirationDate
                                            ? formatDate(quote.expirationDate)
                                            : "-"}
                                    </strong>
                                </p>
                            </div>
                        </MobileDetailsBlock>
                    </MobileSidebarTop>
                    <H4 style={{ fontWeight: 500, marginBottom: 20, fontSize: 22 }}>
                        <strong>Summary</strong>
                    </H4>
                    {quote.components.map((item: any, index: number) => {
                        const lineDiscountSum = quote.discountsApplied?.[
                            item.insuranceLine.value
                        ]?.reduce((acc: any, i: any) => acc + i.value, 0);

                        const componentDiscounts =
                            quote.discountsApplied?.[item.insuranceLine.value];

                        return (
                            !item.coverageDenied && (
                                <SidemenuRow key={`RowLabel__${index}`}>
                                    <div>
                                        <Link to={`${match.url}/overview?open=${index}`}>
                                            {item.insuranceLine.name}
                                        </Link>
                                    </div>
                                    <BlockWithLoader
                                        style={{ marginLeft: "auto" }}
                                        loading={item.loading}
                                    >
                                        <ValueComponent
                                            discountAmount={getDiscountAmount(
                                                allLinesDiscountSum,
                                                lineDiscountSum,
                                            )}
                                            value={item.calculateVisualPremium(quote)}
                                            name={item.insuranceLine.name}
                                            discounts={getDiscountsArray(
                                                allLinesDiscounts,
                                                componentDiscounts,
                                            )}
                                            uniqueKey={`rowLabel${index}`}
                                        />
                                    </BlockWithLoader>
                                </SidemenuRow>
                            )
                        );
                    })}

                    {quote.accountType !== "EXCESS" && quote?.taxesAndFees && (
                        <div
                            style={{
                                padding: "20px 0",
                                margin: "3px 0px 0px 0px",
                                borderTop: "1px solid #dbdbdb",
                            }}
                        >
                            {Object.entries(quote?.taxesAndFees).map(([name, tax]: any, index) => {
                                if (tax) {
                                    let lineTaxes = null;
                                    if (quote?.taxesAndFees[name]) {
                                        const {
                                            title: taxName,
                                            amount: value,
                                        } = quote?.taxesAndFees[name];

                                        lineTaxes = {
                                            name: taxName,
                                            value,
                                        };
                                    }
                                    return (
                                        <SidemenuRow key={`taxRow#_${index}`}>
                                            <DiscountName>
                                                {quote.company.info.state} {tax?.title}
                                            </DiscountName>
                                            <ValueComponent
                                                discountAmount={lineTaxes?.value}
                                                isSurplusLine={true}
                                                value={
                                                    tax?.amountType === "percentage"
                                                        ? formatCurrency(
                                                              +quote.totalPremiumCalc *
                                                                  (tax?.amount / 100),
                                                          )
                                                        : formatCurrency(tax?.amount)
                                                }
                                                hideTooltip={true}
                                                discounts={
                                                    tax?.amountType === "percentage" && [lineTaxes]
                                                }
                                                uniqueKey={`taxLabel${index}`}
                                                name={tax?.title}
                                                loading={premiumLoading}
                                            />
                                        </SidemenuRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    )}

                    <SidemenuRow
                        className="total-row"
                        style={{ paddingTop: 20, borderTop: "1px solid #dbdbdb", marginTop: 3 }}
                    >
                        <span style={{ fontWeight: "bold", fontSize: 18 }}>Total Due:</span>
                        <div style={{ display: "flex", fontWeight: "bold", fontSize: 18 }}>
                            <BlockWithLoader loading={premiumLoading} style={{ marginTop: 5 }}>
                                {formatCurrency(quote.totalPremiumCalc + taxesSum)}
                            </BlockWithLoader>
                            <ValueComponent
                                discountAmount={[
                                    <QuestionIcon key={"svg_0"}>
                                        <svg
                                            width="20"
                                            height="20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.29 13.29a1.577 1.577 0 00-.12.15.76.76 0 00-.09.18.64.64 0 00-.06.18 1.36 1.36 0 000 .2.84.84 0 00.08.38.9.9 0 00.54.54.94.94 0 00.76 0 .9.9 0 00.54-.54A1 1 0 0011 14a1 1 0 00-1.71-.71zM10 0a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16zm0-13a3 3 0 00-2.6 1.5 1 1 0 101.73 1A1 1 0 0110 7a1 1 0 110 2 1 1 0 00-1 1v1a1 1 0 102 0v-.18A3 3 0 0010 5z"
                                                fill="#BFCAD6"
                                            />
                                        </svg>
                                    </QuestionIcon>,
                                ]}
                                name={"Total"}
                                premiumRow={true}
                                discounts={allDiscounts}
                                uniqueKey={`rowLabel_TotalDue`}
                            />
                        </div>
                    </SidemenuRow>
                    {selectedUrl === "effective-date" && (
                        <SidemenuRow>
                            <Button
                                disabled={!canBindCoverages(quote)}
                                onClick={() => bindCoverageCheck()}
                                style={{ width: "100%" }}
                            >
                                Bind Coverage
                            </Button>
                        </SidemenuRow>
                    )}
                </SidemenuContainer>
                <Modal
                    isOpen={requestToBindModal}
                    closeable={true}
                    closeAction={() => setRequestToBindModal(false)}
                    title="Send Request to Bind"
                    buttonStyle="vertical"
                    buttons={[
                        <Button
                            key="modalButton1"
                            onClick={async () => {
                                if (publicSecurityToken == null || appSecurityToken == null) {
                                    toast.error('You are missing a security token. Please contact your broker to bind this account.')
                                    return
                                }

                                const res = await requestToBindFromBroker(publicSecurityToken, appSecurityToken);
                                if (res) {
                                    setRequestToBindModal(false);
                                    toast.success('Request to Bind Sent to Broker')
                                } else {
                                    toast.error('Something went wrong. Please contact your broker to bind this account.')
                                }

                            }}
                            style={{ minWidth: "250px" }}
                        >
                            Yes
                        </Button>,
                        <CancelButton
                            className="no-hover"
                            key="modalButton2"
                            onClick={() => setRequestToBindModal(false)}
                            style={{ minWidth: "250px" }}
                        >
                            No
                        </CancelButton>,
                    ]}
                >
                    <p style={{ fontStyle: "italic" }}>
                        {`In order to bind this account, the broker of record will receive an email asking them to confirm this action.`}
                    </p>
                    <p style={{ fontStyle: "italic" }}>
                        {`Would you like to send this request to bind?`}
                    </p>
                </Modal>
                <Modal
                    isOpen={submitModal}
                    closeable={true}
                    closeAction={() => setSubmitModal(false)}
                    title="Are you sure you want to bind?"
                    buttonStyle="vertical"
                    buttons={[
                        <Button
                            key="modalButton1"
                            onClick={async () => {
                                setSubmitModal(false);
                                if (publicSecurityToken !== undefined && appSecurityToken !== undefined) {
                                    setRequestToBindModal(true);
                                    return
                                }
                                // If we are logged in then do bind
                                // Else show the request to bind modal
                                if (hasAuth) {
                                    await bindSelectedCoverage();
                                } else {
                                    setRequestToBindModal(true);
                                }
                            }}
                            style={{ minWidth: "250px" }}
                        >
                            Bind Coverage
                        </Button>,
                        <CancelButton
                            className="no-hover"
                            key="modalButton2"
                            onClick={() => setSubmitModal(false)}
                            style={{ minWidth: "250px" }}
                        >
                            Return to Quote
                        </CancelButton>,
                    ]}
                >
                    <p style={{ fontStyle: "italic" }}>
                        {`Once you have bound an application the policy will be issued and your account will be charged the premium. Do you wish to bind this coverage?`}
                    </p>
                </Modal>
                <Modal
                    isOpen={priceChangeModal}
                    closeable={true}
                    closeAction={() => setPriceChangeModal(false)}
                    title=" "
                    buttonStyle="vertical"
                    buttons={[
                        <Button
                            key="modalButton3"
                            onClick={async () => {
                                setPriceChangeModal(false);
                                // If we have a public and app security token then we should request to bind
                                if (publicSecurityToken !== undefined && appSecurityToken !== undefined) {
                                    setRequestToBindModal(true);
                                    return
                                }

                                // If we are logged in show the bind confirmation
                                // Else show the request to bind modal
                                if (hasAuth) {
                                    setSubmitModal(true);
                                } else {
                                    setRequestToBindModal(true);
                                }
                            }}
                            style={{ width: "100%", maxWidth: 280 }}
                        >
                            Bind Coverage
                        </Button>,
                        <ModalBottomText key="modalText">
                            By selecting to bind coverage you are confirming that the retail agent
                            and insured have reviewed the change in premium.
                        </ModalBottomText>,
                    ]}
                >
                    <ModalContainer>
                        <H2>Change In Coverage</H2>
                        <StyledBody3>
                            Please note that the coverage and / or price has changed relative to the original quote
                            based on the provided subjectivities:
                        </StyledBody3>

                        <PriceChangeTable>
                            <PriceChangeRow>
                                <div>
                                    <span></span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 10, color: "#22232e7f" }}>
                                        PREMIUM
                                    </span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 10, color: "#22232e7f" }}>DATE</span>
                                </div>
                            </PriceChangeRow>
                            <PriceChangeRow>
                                <div>
                                    <span>Premium Quoted</span>
                                </div>
                                <div>
                                    <strong>{formatCurrency(quote.premiumOnIssueQuote)}</strong>
                                </div>
                                <div>
                                    <span>
                                        {quote.quotedDate ? formatDate(quote.quotedDate) : "-"}
                                    </span>
                                </div>
                            </PriceChangeRow>
                            <PriceChangeRow>
                                <div>
                                    <span>Premium Post Subjectivities</span>
                                </div>
                                <div>
                                    <strong>{formatCurrency(quote.totalPremiumCalc)}</strong>
                                </div>
                                <div>
                                    <span>
                                        {quote.supplementalAppSubmittedDate
                                            ? formatDate(quote.supplementalAppSubmittedDate)
                                            : "-"}
                                    </span>
                                </div>
                            </PriceChangeRow>
                        </PriceChangeTable>
                    </ModalContainer>
                </Modal>
            </StyledOnePaperBackground>
            {showTopMenu && (
                <>
                    <Overlay onClick={() => setShowTopMenu(false)} />
                    <StyledOnePaperBackground fixedPos={showTopButton}>
                        <SidemenuContainer>
                            <MobileSidebarTop>
                                <MobileTitleBlock>
                                    <p>
                                        <strong
                                            style={{ fontWeight: "bold" }}
                                        >{`${quote.statusText}`}</strong>
                                        <br />
                                        <span>{quote.company.name}</span>
                                    </p>
                                </MobileTitleBlock>
                                <MobileDetailsBlock>
                                    <div>
                                        <span>POLICY:</span>
                                        <p>
                                            <strong>{quote.policyNumber}</strong>
                                        </p>
                                    </div>
                                    <div>
                                        <span>EXPIRES:</span>
                                        <p>
                                            <strong>
                                                {quote?.expirationDate
                                                    ? formatDate(quote.expirationDate)
                                                    : "-"}
                                            </strong>
                                        </p>
                                    </div>
                                </MobileDetailsBlock>
                            </MobileSidebarTop>
                            <H4 style={{ fontWeight: 500, marginBottom: 20, fontSize: 22 }}>
                                <strong>Summary</strong>
                            </H4>
                            {quote.components.map((item: any, index: number) => {
                                const lineDiscountSum = quote.discountsApplied?.[
                                    item.insuranceLine.value
                                ]?.reduce((acc: any, i: any) => acc + i.value, 0);
                                const componentDiscounts =
                                    quote.discountsApplied?.[item.insuranceLine.value];

                                return (
                                    <SidemenuRow key={`RowLabelMobile__${index}`}>
                                        <div>
                                            <Link to={`${match.url}/overview?open=${index}`}>
                                                {item.insuranceLine.name}
                                            </Link>
                                        </div>
                                        <ValueComponent
                                            discountAmount={getDiscountAmount(
                                                allLinesDiscountSum,
                                                lineDiscountSum,
                                            )}
                                            value={item.calculateVisualPremium(quote)}
                                            name={item.insuranceLine.name}
                                            discounts={getDiscountsArray(
                                                allLinesDiscounts,
                                                componentDiscounts,
                                            )}
                                            uniqueKey={`rowLabelMobile${index}`}
                                        />
                                        {/* <Link to={`${match.url}/overview?open=${index}`}>
                                            {item.insuranceLine.name}
                                        </Link>
                                        <strong>{item.calculateVisualPremium(quote)}</strong> */}
                                    </SidemenuRow>
                                );
                            })}

                            {quote.accountType !== "EXCESS" && quote?.taxesAndFees && (
                                <div
                                    style={{
                                        padding: "20px 0",
                                        margin: "3px 0px 0px 0px",
                                        borderTop: "1px solid #EBF0F6",
                                    }}
                                >
                                    {Object.entries(quote?.taxesAndFees).map(
                                        ([name, tax]: any, index) => {
                                            if (tax) {
                                                let lineTaxes = null;
                                                if (quote?.taxesAndFees[name]) {
                                                    const {
                                                        title: taxName,
                                                        amount: value,
                                                    } = quote?.taxesAndFees[name];

                                                    lineTaxes = {
                                                        name: taxName,
                                                        value,
                                                    };
                                                }
                                                return (
                                                    <SidemenuRow key={`taxRow#_${index}`}>
                                                        <DiscountName>
                                                            {quote.company.info.state} {tax.title}
                                                        </DiscountName>
                                                        <ValueComponent
                                                            discountAmount={lineTaxes?.value}
                                                            isSurplusLine={true}
                                                            value={
                                                                tax.amountType === "percentage"
                                                                    ? formatCurrency(
                                                                          +quote.totalComponentPremium *
                                                                              (tax.amount / 100),
                                                                      )
                                                                    : formatCurrency(tax.amount)
                                                            }
                                                            hideTooltip={true}
                                                            discounts={
                                                                tax.amountType === "percentage" && [
                                                                    lineTaxes,
                                                                ]
                                                            }
                                                            uniqueKey={`taxLabel${index}`}
                                                            name={tax.title}
                                                        />
                                                    </SidemenuRow>
                                                );
                                            } else {
                                                return null;
                                            }
                                        },
                                    )}
                                </div>
                            )}

                            <SidemenuRow
                                style={{
                                    paddingTop: 20,
                                    borderTop: "1px solid #EBF0F6",
                                    marginTop: 3,
                                }}
                            >
                                <span style={{ fontWeight: "bold", fontSize: 18 }}>Total Due:</span>
                                <div style={{ display: "flex" }}>
                                    <span style={{ fontWeight: "bold", fontSize: 18 }}>
                                        {formatCurrency(quote.totalPremiumCalc)}
                                    </span>
                                    <ValueComponent
                                        discountAmount={[
                                            <QuestionIcon key={"svg_0"}>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9.29 13.29a1.577 1.577 0 00-.12.15.76.76 0 00-.09.18.64.64 0 00-.06.18 1.36 1.36 0 000 .2.84.84 0 00.08.38.9.9 0 00.54.54.94.94 0 00.76 0 .9.9 0 00.54-.54A1 1 0 0011 14a1 1 0 00-1.71-.71zM10 0a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16zm0-13a3 3 0 00-2.6 1.5 1 1 0 101.73 1A1 1 0 0110 7a1 1 0 110 2 1 1 0 00-1 1v1a1 1 0 102 0v-.18A3 3 0 0010 5z"
                                                        fill="#BFCAD6"
                                                    />
                                                </svg>
                                            </QuestionIcon>,
                                        ]}
                                        name={"Total"}
                                        premiumRow={true}
                                        discounts={allDiscounts}
                                        uniqueKey={`rowLabel_TotalDue`}
                                    />
                                </div>
                            </SidemenuRow>
                            {/* {quote.discountLines && quote.discountLines.length && (
                                <SidemenuRow
                                    style={{
                                        borderTop: "1px solid #00000026",
                                        paddingTop: 20,
                                        flexDirection: "column",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: 16,
                                            marginBottom: 10,
                                        }}
                                    >
                                        Applied Discounts:
                                    </p>
                                    {quote.discountLines.map((item: any, index: number) => (
                                        <SidemenuRow key={`DiscountRowLabel__${index}`}>
                                            <div>
                                                <span>{item.name} Discount</span>
                                                <span style={{ color: "#FF0000" }}>
                                                    {" "}
                                                    -{item.verbosePercentage}%
                                                </span>
                                            </div>
                                            <span>-{item.amount}</span>
                                        </SidemenuRow>
                                    ))}
                                </SidemenuRow>
                            )} */}
                        </SidemenuContainer>
                    </StyledOnePaperBackground>
                </>
            )}
        </>
    );
});

export default SidebarMenu;
