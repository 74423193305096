import React from "react";
import styled, { css } from "styled-components/macro";
import { OnePaperBackground } from "counterpart-theme";
import { SectionSelectorText } from "components/typography";
import { useHistory, useRouteMatch } from "react-router-dom";

import classNames from "classnames";
import { useAccountStore } from "../store";

import { subjectivitiesCompleted } from "../utils";

import { observer } from "mobx-react-lite";
import { assert } from "utils";
import TopMenuArrow from "../assets/topMenuArrow.svg";
import TopMenuMiddleArrow from "../assets/topMenuMiddleArrow.svg";
import TopMenuLastArrow from "../assets/topMenuLastArrow.svg";

interface MenuItemProps {
    label: any;
    completed: boolean;
    selected: boolean;
    enabled?: boolean;
    icon?: string;
    number: number;
    onClick?: () => void;
    tabSelected: number;
}

const Step = styled(SectionSelectorText as any)`
    position: absolute;
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 18px;
    left: 5px;
    font-weight: 500;
    top: 0;

    @media (max-width: 768px) {
        font-weight: 400;
    }

    @media (max-width: 480px) {
        font-size: 13px;
        left: 15px;
        top: -6px;
    }
`;

export const MarkMenu = styled.div<{ completed: boolean; selected: boolean }>`
    position: absolute;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    left: 0;

    @media (max-width: 768px) {
        z-index: 2;
    }

    & > span {
        position: relative;
    }

    ${({ selected, completed }) =>
        selected &&
        !completed &&
        css`
            border-color: #22232e;
            & > span {
                @media (min-width: 769px) {
                    color: #fff;
                }
            }
        `}

    ${({ selected, completed, theme }) =>
        completed &&
        css`
            width: 22px;
            height: 22px;
            & > span {
                display: none;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                left: 9px;
                top: 2px;
                width: 8px;
                height: 15px;
                border: solid;
                border-color: ${selected ? "#fff" : theme.colors.accentColor};
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);

                @media (max-width: 768px) {
                    border-color: ${theme.colors.accentColor};
                }

                @media (max-width: 480px) {
                    width: 5px;
                    height: 11px;
                    top: 2px;
                    left: 14px;
                }
            }
        `}
`;

export const SidemenuContainer = styled.div<{ numberOfColumns?: number }>`
    min-width: 340px;
    display: grid;
    grid-template-columns: repeat(${({numberOfColumns}) => numberOfColumns || '3'}, 1fr);

    @media (max-width: 768px) {
        width: 100vw;
    }

    @media (max-width: 480px) {
        min-width: 0;
        width: 100%;
    }
`;

export const CustomButton = styled.button<{ selected: boolean }>`
    text-align: left;
    border: none;
    outline: none;
    padding: 28px 35px 28px 35px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    background: #f8f8f8;
    border-radius: 10px;

    @media (min-width: 769px) {
        background-color: ${({ selected, theme }) => (selected ? theme.colors.primary : "#ffffff")};
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
        height: 100%;
        background-color: ${({ selected }) => selected && "#ffffff"};
        border-top: 3px solid transparent;
        border-top-color: ${({ selected, theme }) =>
            selected ? theme.colors.primary : "transparent"}; /* align-items: center; */
        border-bottom: 1px solid #dbe1e7;

        ${({ selected }) =>
            selected &&
            css`
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    height: calc(100% + 4px);
                    width: calc(100% + 2px);
                    left: -1px;
                    top: -3px;
                    background: #fff; //f8f8f8
                    pointer-events: none;
                    border-top: 3px solid ${({ theme }) => theme.colors.primary};
                }
            `}
    }

    @media (max-width: 480px) {
        padding: 10px 16px;
    }

    &:focus {
        @media (min-width: 769px) {
            border: none;
        }
        box-shadow: none;
        outline: none;
    }
`;

export const MenuItemContainer = styled.div<{ selected: boolean; tabSelected: number }>`
    position: relative;
    display: flex;
    align-items: center;
    border: none;

    @media (min-width: 769px) {
        clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    }

    &:not(:last-child) {
        @media (max-width: 768px) {
            border-right: 1px solid #dbe1e7;
        }
    }

    ${SidemenuContainer} &:first-of-type {
        @media (min-width: 769px) {
            clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0 50%, 0% 0%);
        }
    }

    ${SidemenuContainer} &:last-of-type {
        @media (min-width: 769px) {
            clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
        }
    }
`;

const ButtonContentContainer = styled.span`
    position: relative;
    padding-left: 30px;
    display: flex;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        position: initial;
        padding-left: 15px;
    }
`;

const ButtonText = styled(SectionSelectorText as any)`
    color: ${({ theme }) => theme.colors.accentColor};
    font-size: 18px;
    font-weight: 500;

    @media (min-width: 767px) {
        br {
            display: none;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        z-index: 2;
        line-heigth: 20px;
        word-spacing: 400px;
    }

    @media (max-width: 480px) {
        font-size: 13px;
        line-height: 14px;
    }

    &.selected {
        @media (min-width: 769px) {
            color: #ffffff;
        }
    }
`;

const StyledOnePaperBackground = styled(OnePaperBackground as any)`
    margin-bottom: 20px;
    background: none;

    @media (max-width: 768px) {
        margin-bottom: 0;
        position: sticky;
        top: 0;
        z-index: 100;
        width: 100%;
    }
`;

export const MenuItem: React.FC<MenuItemProps> = ({
    label,
    onClick,
    completed,
    selected,
    // icon,
    enabled = true,
    number,
    tabSelected,
}) => {
    if (enabled)
        return (
            <MenuItemContainer selected={selected} tabSelected={tabSelected}>
                <CustomButton onClick={onClick} selected={selected}>
                    {/* {icon && <img src={icon} alt="arrow background" />} */}
                    <ButtonContentContainer>
                        <MarkMenu selected={selected} completed={completed}>
                            {!completed && <Step>{number}.</Step>}
                        </MarkMenu>
                        <ButtonText className={classNames({ selected })}> {label}</ButtonText>
                    </ButtonContentContainer>
                </CustomButton>
            </MenuItemContainer>
        );
    else return <></>;
};

const SidebarMenu: React.FC<{}> = observer(() => {
    const match = useRouteMatch();
    const history = useHistory();

    const { policy, quote } = useAccountStore();
    assert(quote);

    const urlSelected = history.location.pathname.substring(
        history.location.pathname.lastIndexOf("/") + 1,
    );
    return (
        <StyledOnePaperBackground>
            <SidemenuContainer numberOfColumns={quote.hasEPLICoverage ? 3 : 2}>
                <MenuItem
                    completed={true}
                    selected={urlSelected === "overview"}
                    tabSelected={1}
                    label={["Review ", <br key="ReviewCoverageKey" />, "Coverage"]}
                    onClick={() => history.push(`${match.url}/quote-overview`)}
                    icon={TopMenuArrow}
                    number={1}
                />
                {quote.hasEPLICoverage && <MenuItem
                    completed={subjectivitiesCompleted(quote, true)}
                    selected={urlSelected === "advisory-services"}
                    tabSelected={2}
                    label="HR Counterpart"
                    onClick={() => history.push(`${match.url}/advisory-services`)}
                    icon={TopMenuMiddleArrow}
                    number={2}
                />}
                <MenuItem
                    completed={!!policy?.isAllInvoicesPaid}
                    selected={urlSelected === "payment"}
                    tabSelected={3}
                    label="Invoice"
                    onClick={() => history.push(`${match.url}/payment`)}
                    icon={TopMenuLastArrow}
                    number={quote.hasEPLICoverage ? 3 : 2}
                />
            </SidemenuContainer>
        </StyledOnePaperBackground>
    );
});

export default React.memo(SidebarMenu);
