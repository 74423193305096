import { types, flow, applySnapshot, Instance, TypeOfValue } from "mobx-state-tree";
import { logger } from "services";
import { toast } from "react-toastify";
import { listClaims } from "services/quotes";

export const ClaimRow = types.model("ClaimRow", {
    id: types.identifier,
    companyName: types.string,
    retailBroker: types.maybeNull(types.string),
    agency: types.maybeNull(types.string),
    noticeOfLoss: types.string,
    status: types.string,
    claimNumber: types.string
});

export const ClaimsTableStore = types
    .model({
        results: types.array(ClaimRow),
        count: types.number,
    })
    .volatile(() => ({
        loading: true,
        error: false,
        page: 1,
    }))
    .actions((self) => ({
        load: flow(function* load() {
            try {
                self.loading = true;
                const data = yield listClaims(self.page);
                applySnapshot(self, data);
                self.error = false;
            } catch (error) {
                logger.error(error);
                self.error = true;
                throw error;
            } finally {
                self.loading = false;
            }
        }),
    }))
    .actions((self) => {
        return {
            afterCreate() {
                self.load().catch((error) => {
                    logger.error(error);
                });
            },
            changePage: flow(function* changePage(page: number) {
                const oldPage = self.page;
                try {
                    self.page = page;
                    yield self.load();
                } catch (error) {
                    logger.error("DashboardTableStore.changePage", error);
                    toast.error("An error happing while trying to get the data");
                    self.page = oldPage;
                }
            }),
        };
    });

export type IClaimsTableStore = Instance<typeof ClaimsTableStore>;
export type IClaimsTableStoreSnapshot = TypeOfValue<IClaimsTableStore>["CreationType"];

export const createClaimsTableStoreStore = () => {
    const newStore = ClaimsTableStore.create({ results: [], count: 0 });
    return newStore;
};
