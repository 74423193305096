import automated from "./assets/login-page/automated-icon.svg";
import easy from "./assets/login-page/easy-icon.svg";
import greateCoverage from "./assets/login-page/greatcoverage-icon.svg";
import hrAdvisory from "./assets/login-page/hradvisory-icon.svg";
import limited from "./assets/login-page/limited-icon.svg";
import mobile from "./assets/login-page/mobile-icon.svg";
import modular from "./assets/login-page/modular-icon.svg";
import riskMitigation from "./assets/login-page/riskmitigation-icon.svg";

export const textBoxes = [
    {
        title: "Mobile",
        icon: mobile,
        text: "Leverage our fully-digital application optimized for all devices.",
    },
    {
        title: "Easy and efficient ",
        icon: easy,
        text: "Quote, bind, and issue coverage in minutes.",
    },
    {
        title: "Great Coverage",
        icon: greateCoverage,
        text: "State of the art policy containing proprietary coverages.",
    },
    {
        title: "Modular",
        icon: modular,
        text: "Quote multiple limits, retentions, and coverage options in real-time."
    },
    {
        title: "Risk Mitigation",
        icon: riskMitigation,
        text: "Risk assessments tailored to the unique risk exposures of your clients."
    },
    {
        title: "Limited Access",
        icon: limited,
        text: "Only select wholesale brokers have access to Counterpart."
    },
    {
        title: "HR Advisory Services",
        icon: hrAdvisory,
        text: "Access to HR legal experts, even before a claim is filed."
    },
    {
        title: "Automated",
        icon: automated,
        text: "Share the platform with your retail agents & let the platform work for you."
    },
];
