import { FullQuestion, useAnswersContext, getAnswersContext } from "services/application";
import { evaluateExpression } from "utils/dynamicOperators";

export const checkDependentQuestion = (
    question: FullQuestion,
    values: any,
    answersContext?: ReturnType<typeof useAnswersContext>,
) => {
    if (question.dependsOnExpression) {
        const context = answersContext ?? getAnswersContext(values);
        const currentValue = context[question.ratingEngineKey];
        return !!evaluateExpression(question.dependsOnExpression, { context, currentValue });
    }
    return true;
};

export const useDependentQuestion = (question: FullQuestion) => {
    const context = useAnswersContext();
    return checkDependentQuestion(question, undefined, context);
};
