import React from "react";
import moment from "moment";
import styled from "styled-components/macro";

import CommonContainer from "../CommonContainer";
import { Body2, H4, Body3, H2, ArialBaseCSS } from "components/typography";

import { useAccountStore } from "features/AccountPage/store";

import { formatCurrency } from "utils";

const ContentContainer = styled.div`
    ${ArialBaseCSS};
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 10px;

    @media (max-width: 768px) {
        padding: 10px 0 40px 0;
    }

    ${Body3} {
        ${ArialBaseCSS};
        &:nth-child(2) {
            margin-top: 40px;

            @media (max-width: 768px) {
                margin-top: 30px;
            }
        }
        &:nth-child(3) {
            margin-bottom: 40px;

            @media (max-width: 768px) {
                margin-bottom: 30px;
            }
        }
    }
`;

const RowLabel = styled.div`
    font-weight: 500;
    color: #22232e;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0.4;
    display: flex;
    align-items: center;
`;

const RowContent = styled.div`
    ${ArialBaseCSS};

    ${Body2} {
        margin-right: 10px;
    }

    @media (max-width: 767px) {
        margin-top: 0;
    }
`;

const Row = styled.div<{ isTitle?: boolean; noBorder?: boolean; pdfRow?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 0;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0;
    }

    ${({ noBorder }) =>
        !noBorder &&
        `
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #EBF0F6;

            @media (max-width: 768px) {
                display: none;
            }
        }
    `}

    ${({ isTitle }) =>
        isTitle &&
        `
        padding: 0;
        margin-bottom: -13px;
        line-height: 16px;

        @media (max-width: 768px) {
            display: none;
        }
    `}

    ${RowLabel} {
        @media (min-width: 769px) {
            display: none;
        }

        ${({ isTitle }) =>
            isTitle &&
            `
            @media (min-width: 769px) {
                display: initial;
            }

            @media (max-width: 768px) {
                display: none;
            }
        `};
    }

    ${({ pdfRow }) =>
        pdfRow &&
        `
        @media (max-width: 768px) {
            &:not(:last-child) {
                margin-bottom: 40px;
                border-bottom: 1px solid #EBF0F6;
                padding-bottom: 40px;
            }
        }
    `};
`;

const PaymentsRow = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr) fit-content(200px);
    position: relative;
    padding: 20px 0;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const StyledLink = styled.a`
    text-decoration: underline;
    color: #111da1;
`;

const PDFButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #12b3dc;
    transition: color 0.3s;

    ${Body2} {
        font-size: 16px;
    }

    svg path {
        transition: fill 0.3s;
    }

    &:hover {
        color: #0056b3;

        svg {
            path {
                fill: #0056b3;
            }
        }
    }
`;

const PaymentsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PaymentInfo: React.FC = () => {
    const { policy, quote } = useAccountStore();

    const formatDate = (dateStr: string) => moment(dateStr).format("MM/DD/YY");

    return (
        <CommonContainer isPolicy={true} borderBottom={false}>
            <ContentContainer>
                <H2 style={{ padding: 0, fontSize: "22px" }}>Invoice</H2>
                <Body3>
                    Here are your invoice details for{" "}
                    <strong style={{ fontWeight: "bold" }}>{quote.company.name}</strong> management
                    liability policy. We accept EFT/ACH payments and have a lockbox address. Please
                    do not mail payment to our physical office.
                </Body3>
                <Body3>
                    We appreciate your prompt payment! Please send remittance details (invoice and /
                    or policy number) to{" "}
                    <StyledLink href="mailto:accounting@yourcounterpart.com">
                        accounting@yourcounterpart.com
                    </StyledLink>{" "}
                    to ensure timely credit to your account.
                </Body3>
                {policy?.invoices && policy?.invoices.length ? (
                    <>
                        <H4 style={{ marginTop: 20, marginBottom: 0 }}>Payment Status</H4>
                        <PaymentsContainer>
                            <PaymentsRow>
                                <Row isTitle={true} noBorder={true}>
                                    <RowLabel>Premium</RowLabel>
                                </Row>
                                <Row isTitle={true} noBorder={true}>
                                    <RowLabel>Broker Comm</RowLabel>
                                </Row>
                                <Row isTitle={true} noBorder={true}>
                                    <RowLabel>Balance Due</RowLabel>
                                </Row>
                                {/* <Row isTitle={true} noBorder={true}>
                                    <RowLabel>Invoice Date</RowLabel>
                                </Row> */}
                                <Row isTitle={true} noBorder={true}>
                                    <RowLabel>Due Date</RowLabel>
                                </Row>
                                <Row isTitle={true} noBorder={true}>
                                    <RowLabel>Status:</RowLabel>
                                </Row>
                                <Row isTitle={true} noBorder={true}>
                                    <RowLabel>PDF</RowLabel>
                                </Row>
                                {policy.invoices.map((invoice: any, index: number, arr: any) => {
                                    const noBorder = index === arr.length - 1;
                                    return (
                                        <React.Fragment key={`invoice_${index}`}>
                                            <Row noBorder={noBorder}>
                                                <RowLabel>Premium</RowLabel>
                                                <RowContent style={{ fontWeight: "bold" }}>
                                                    {policy?.premium
                                                        ? formatCurrency(policy?.premium)
                                                        : "-"}
                                                </RowContent>
                                            </Row>
                                            <Row noBorder={noBorder}>
                                                <RowLabel>Broker Comm</RowLabel>
                                                <RowContent>
                                                    {quote?.commissionPercentage
                                                        ? quote?.commissionPercentage
                                                        : "-"}
                                                </RowContent>
                                            </Row>
                                            <Row noBorder={noBorder}>
                                                <RowLabel>Balance Due</RowLabel>
                                                <RowContent>
                                                    {invoice.amount
                                                        ? formatCurrency(invoice.amount)
                                                        : "-"}
                                                </RowContent>
                                            </Row>
                                            {/* <Row>
                                                <RowLabel>Invoice Date</RowLabel>
                                                <RowContent>06/01/2020</RowContent>
                                            </Row> */}
                                            <Row noBorder={noBorder}>
                                                <RowLabel>Due Date</RowLabel>
                                                <RowContent>
                                                    {invoice.dueDate
                                                        ? formatDate(invoice.dueDate)
                                                        : "-"}
                                                </RowContent>
                                            </Row>
                                            <Row noBorder={noBorder}>
                                                <RowLabel>Status</RowLabel>
                                                <RowContent style={{ textTransform: "capitalize" }}>
                                                    {invoice.status
                                                        ? invoice.status.toLowerCase()
                                                        : "-"}
                                                </RowContent>
                                            </Row>
                                            <Row noBorder={noBorder} pdfRow={true}>
                                                <RowLabel>PDF</RowLabel>
                                                <RowContent style={{ fontWeight: "bold" }}>
                                                    {invoice.invoicePdf ? (
                                                        <PDFButton
                                                            href={invoice.invoicePdf}
                                                            target="_blank"
                                                        >
                                                            <Body2 as="span">Invoice.pdf</Body2>
                                                            <svg
                                                                width="16"
                                                                height="16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M15.2 9.6a.8.8 0 0 0-.8.8v3.2a.8.8 0 0 1-.8.8H2.4a.8.8 0 0 1-.8-.8v-3.2a.8.8 0 1 0-1.6 0v3.2A2.4 2.4 0 0 0 2.4 16h11.2a2.4 2.4 0 0 0 2.4-2.4v-3.2a.8.8 0 0 0-.8-.8Zm-7.768 1.368a.8.8 0 0 0 .264.168.752.752 0 0 0 .608 0 .8.8 0 0 0 .264-.168l3.2-3.2a.803.803 0 0 0-1.136-1.136L8.8 8.472V.8a.8.8 0 0 0-1.6 0v7.672l-1.832-1.84a.803.803 0 1 0-1.136 1.136l3.2 3.2Z"
                                                                    fill="#12B3DC"
                                                                />
                                                            </svg>
                                                        </PDFButton>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </RowContent>
                                            </Row>
                                        </React.Fragment>
                                    );
                                })}
                            </PaymentsRow>
                        </PaymentsContainer>
                    </>
                ) : null}
            </ContentContainer>
        </CommonContainer>
    );
};

export default React.memo(PaymentInfo);
