import React, { useCallback } from "react";
import styled from "styled-components/macro";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";
import { RouteComponentProps } from "react-router";

import { H1, Body2, H4 } from "components/typography";
import {
    Button,
    Panel,
    FormikInputField as InputField,
    Row,
    FormErrorMessage,
    Header,
    Page,
} from "components/common";
import { auth } from "services";

import { textBoxes } from "./text";
import { TextBoxContainer, TextBoxItem, TextBoxIcon } from "./components/styled";
import { CounterpartBrokerAdminOverrideTokenObtainPair } from "CounterpartApiTypes";

const initialValues: CounterpartBrokerAdminOverrideTokenObtainPair = {
    email: "",
    password: "",
    brokerEmail: "",
};
type FormType = CounterpartBrokerAdminOverrideTokenObtainPair;

const ErrorMessage = () => {
    const { status } = useFormikContext<FormType>();
    if (status) {
        return <FormErrorMessage>{status}</FormErrorMessage>;
    } else {
        return null;
    }
};

const ContentContainer = styled.div<{ flexDirection?: string }>`
    display: flex;
    flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : "row")};

    &.login-box-container {
        flex: 0 0 33%;
        align-self: center;
    }

    @media (max-width: 1024px) {
        &.padding-mobile {
            padding: 10px;
        }
    }

    @media (max-width: 800px) {
        &.reverse-mobile {
            flex-direction: row-reverse;
            justify-content: flex-start;

            & > div.login-box-container {
                align-items: flex-start;
            }
        }
        &.content-box {
            padding-left: 20px;
        }

        &.login-box-container {
            flex: 0 0 50%;
            align-self: flex-start;
            & ${Row} {
                margin-bottom: 20px;
            }
        }
    }

    @media (max-width: 700px) {
        flex-direction: column-reverse !important;
        &.content-box {
            flex-direction: column !important;
            padding-top: 20px;
        }
        &.login-box-container {
            flex: 0 0 100%;
        }
    }
`;

type LoginPageProps = RouteComponentProps<{}>;

const submitForm = (history: any, location: any) => async (
    { email, password, brokerEmail }: FormType,
    actions: FormikHelpers<FormType>,
) => {
    try {
        await auth.brokerAdminOverrideLogin(email, brokerEmail, password);

        if (location.state && location.state.from) {
            history.push(location.state.from);
        } else {
            history.push("/");
        }
    } catch (error) {
        const errorData = error.response && error.response.data;
        if (errorData) {
            if (errorData.detail) {
                actions.setStatus(errorData.detail);
            } else if (errorData.nonFieldErrors) {
                if (typeof errorData.nonFieldErrors == "string") {
                    actions.setStatus(errorData.nonFieldErrors);
                } else if (Array.isArray(errorData.nonFieldErrors)) {
                    const msg = errorData.nonFieldErrors.join("\n");
                    actions.setStatus(msg);
                }
            } else {
                actions.setErrors(errorData);
            }
        } else {
            throw error;
        }
    }
};

const Container = styled.div`
    max-width: 1160px;
    margin: auto;
`;

const LoginPage: React.SFC<LoginPageProps> = ({ location, history }) => {
    const onSubmit = useCallback(submitForm(history, location), [location, history]);

    return (
        <Page>
            <Header showApplicationMenu={false} detached={false} />
            <Container>
                <ContentContainer className="reverse-mobile padding-mobile">
                    <ContentContainer
                        className="content-box"
                        flexDirection="column"
                        style={{ flexBasis: "66%" }}
                    >
                        <H1 as="h2" style={{ maxWidth: "440px" }}>
                            Management liability insurance for the 21st century workplace
                        </H1>
                        <ContentContainer>
                            <TextBoxContainer>
                                {textBoxes.map((item) => (
                                    <TextBoxItem key={item.title}>
                                        <H4>
                                            <TextBoxIcon src={item.icon} />
                                            {item.title}
                                        </H4>
                                        <Body2>{item.text}</Body2>
                                    </TextBoxItem>
                                ))}
                            </TextBoxContainer>
                        </ContentContainer>
                    </ContentContainer>
                    <ContentContainer className="login-box-container">
                        <Panel
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                maxWidth: 480,
                                padding: "50px 30px 50px 30px",
                            }}
                            big
                        >
                            <H1
                                style={{
                                    maxWidth: "320px",
                                    margin: "0 auto 40px",
                                    textAlign: "center",
                                }}
                            >
                                Welcome to Counterpart!
                            </H1>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                <Form>
                                    <Row>
                                        <InputField
                                            name="brokerEmail"
                                            label="Broker Email Address"
                                        />
                                    </Row>
                                    <Row>
                                        <InputField name="email" label="Admin Email Address" />
                                    </Row>
                                    <Row>
                                        <InputField
                                            name="password"
                                            label="Admin Password"
                                            type="password"
                                        />
                                    </Row>
                                    <ErrorMessage />
                                    <div style={{ textAlign: "center", marginTop: 40 }}>
                                        <Button
                                            type="submit"
                                            style={{ width: 160, height: 50, fontWeight: 900 }}
                                        >
                                            Sign In
                                        </Button>
                                    </div>
                                </Form>
                            </Formik>
                        </Panel>
                    </ContentContainer>
                </ContentContainer>
            </Container>
        </Page>
    );
};

export default LoginPage;
