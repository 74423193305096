import CounterpartTheme from "theme";
import { QuoteStatusEnum } from "CounterpartApiTypes";

export const STATUS_SHOW_SIDEBAR = ["APPROVED", "SIGNED"];

export const STATUS_COLORS: { [key in QuoteStatusEnum]: string } = {
    EXPIRED: CounterpartTheme.colors.systemError,
    DECLINED: CounterpartTheme.colors.systemError,
    APPROVED: CounterpartTheme.colors.textColor,
    PENDING: CounterpartTheme.colors.secondary,
};
