import * as React from "react";
import { observer } from "mobx-react-lite";
import { useRouteMatch } from "react-router-dom";

import styled from "styled-components/macro";

import { useAccountStore, StoreType } from "./../store";
import { OptionsContainer, SharedLimitOption, SharedOptionsContainer } from "./styled";
import { Tooltip, TooltipContainer } from "../../../counterpart-theme";
import { UncontrolledTooltip } from "reactstrap";
import { classNames as cx, formatCurrency } from "utils";

import { MuseoSansBaseCSS } from "components/typography";
import { Loading, SwitchButton } from "components/common";

type SharedLimitOptionContainerProps = {
    store: StoreType;
    value: number;
    publicSecurityToken?: string;
    appSecurityToken?: string;
};

const CustomSpan = styled.span`
    ${MuseoSansBaseCSS}
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.accentColor};
`;

const StyledSharedLimitsContainer = styled.div`
    display: flex;
    margin-top: 20px;
    margin-left: 3px;

    @media (max-width: 480px) {
        flex-direction: column;
    }
`;

const SharedLimitOptionContainer = observer(({ store, value, publicSecurityToken, appSecurityToken }: SharedLimitOptionContainerProps) => {
    const selected = store.quote?.sharedLimitAmount === value;
    const onCLick = () => {
        if (!selected) {
            store.changeSharedLimits(value, true, {params: {publicSecurityToken, appSecurityToken}});
        }
    };

    return (
        <SharedLimitOption className={cx({ selected })} onClick={onCLick}>
            {formatCurrency(value)}
        </SharedLimitOption>
    );
});

const QuoteRadioOptions = observer(() => {
    const store = useAccountStore();
    const match = useRouteMatch<{
        publicSecurityToken: string;
        appSecurityToken: string;
      }>();

    const {publicSecurityToken, appSecurityToken} = match.params;
    if (!store.quote || !store.quote?.showSidebar) return null;
    if (store.quote?.finalized) {
        return null;
    }
    if (store.isCalculatingShared) {
        return <Loading />;
    }

    if (store.quote.components.length === 1) {
        return <></>
    }

    return (
        <StyledSharedLimitsContainer>
            <OptionsContainer>
                <div style={{ display: "flex" }}>
                    {store.quote.accountType !== "EXCESS" ? (
                        <>
                            {store.quote.canShareLimits ? (
                                <>
                                    <SwitchButton
                                        onClick={() =>
                                            store.changeSharedLimits(null, !store?.quote?.sharedLimits, {params: {publicSecurityToken, appSecurityToken}})
                                        }
                                        checked={store.quote.sharedLimits}
                                    />
                                    <div style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        {store.quote.sharedLimits && (
                                            <CustomSpan>Shared Limits Enabled</CustomSpan>
                                        )}
                                        {!store.quote.sharedLimits && (
                                            <CustomSpan>Add shared limits?</CustomSpan>
                                        )}
                                    </div>
                                    <TooltipContainer>
                                        <Tooltip
                                            style={{ lineHeight: "16px", marginLeft: 5, marginTop: 5 }}
                                            id="uncontrolledForSharedLimits"
                                        />
                                        <UncontrolledTooltip
                                            placement="top"
                                            target="uncontrolledForSharedLimits"
                                        >
                                            Counterpart may reduce the shared limits offered to the Company
                                            based on the complexity or risk characteristics of the business.
                                            If you have any questions please get in touch with our
                                            underwriting team through the chat feature in the bottom right
                                            corner.
                                        </UncontrolledTooltip>
                                    </TooltipContainer>
                                </>
                            ) : (
                            <></>
                            )}
                        </>
                    ) : (
                        <div style={{ fontWeight: 300, paddingLeft: "10px" }}>
                            <CustomSpan>
                                Please email underwriting@yourcounterpart.com to submit the
                                underlying carrier(s)’ documentation and request to bind coverage.
                            </CustomSpan>
                        </div>
                    )}
                </div>
            </OptionsContainer>
            {store.quote.sharedLimits && (
                <SharedOptionsContainer>
                    <span style={{ fontWeight: 500, paddingLeft: 10 }}>Select a shared limit</span>
                    <div>
                        {store &&
                            (1000000 <= store?.quote?.maximumLimit ||
                                1000000 >= store?.quote?.maximumLimit) && (
                                <SharedLimitOptionContainer store={store} value={1_000_000} publicSecurityToken={publicSecurityToken} appSecurityToken={appSecurityToken} />
                            )}
                        {store && 2000000 <= store?.quote?.maximumLimit && (
                            <SharedLimitOptionContainer store={store} value={2_000_000} publicSecurityToken={publicSecurityToken} appSecurityToken={appSecurityToken} />
                        )}
                        {store && 3000000 <= store?.quote?.maximumLimit && (
                            <SharedLimitOptionContainer store={store} value={3_000_000} publicSecurityToken={publicSecurityToken} appSecurityToken={appSecurityToken} />
                        )}
                    </div>
                </SharedOptionsContainer>
            )}
        </StyledSharedLimitsContainer>
    );
});
export default QuoteRadioOptions;
