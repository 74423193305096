import React from "react";
import styled from "styled-components/macro";
import { useParams, Link } from "react-router-dom";
import { Redirect } from "react-router"

import { H1, Body2, Body3 } from "components/typography";
import {
    Container,
    Button,
    Page,
    FormikInputField,
} from "components/common";
import hr_counterpart_logo from "./assets/hr_counterpart_logo.svg";

import { toast } from "react-toastify";
import { validateHRContactToRegisterForZeroHR, optInToCounterpartHR } from "services/users"
import { Helmet } from "react-helmet";
import Loading from "components/common/Loading";
import { Form, Formik, FormikHelpers, Field } from "formik";


const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 999999;
`;

const StyledPage = styled(Page as any)`
    background-color: #fff;
    background-position: right -150px bottom -250px;
    background-repeat: no-repeat;
    background-size: 900px;
    min-height: 100vh;

    @media (max-width: 980px) {
        background-size: 650px;
        background-position: top 50% right -400px;
    }

    @media (max-width: 500px) {
        background-position: top 70% right -450px;
    }
`;

const StyledContainer = styled(Container as any)`
    width: 100%;
    max-width: 1420px;

    & {
        margin-top: 80px;

        @media (max-width: 700px) {
            margin-top: 0;
        }
    }
`;

const HRRegistration = (): any => {
    const { company_id, token } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [successfullyRegistered, setSuccessfullyRegistered] = React.useState(false);
    const [invalidToken, setInvalidToken] = React.useState(false);
    const [companyName, setCompanyName] = React.useState("");
    const [loginLink, setLoginLink] = React.useState("");

    const [formikRegister, setFormikRegister] = React.useState<any>({
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        phone: "",
        token: "",
        company_id: "",
        agreeToTerms: false
    })


    React.useEffect(() => {
        try {
            validateHRContactToRegisterForZeroHR({
                companyId: company_id,
                token: token
            }).then((results: any) => {
                if(results) {
                    // Only prefill the form if the firstName is not empty
                    if (results.firstName !== "") {
                        setCompanyName(results.companyName);
                        setFormikRegister({
                            first_name: results.firstName,
                            last_name: results.lastName,
                            title: results.title,
                            email: results.email,
                            phone: results.phone,
                            company_id: company_id,
                            token: token,
                            agreeToTerms: false
                        });
                    }
                    setLoading(false);
                } else {
                    setInvalidToken(true)
                }
            })
        } catch(e) {
            setInvalidToken(true)
        }
    }, [company_id, token])

    if(invalidToken) {
        toast.error("Invalid Token");
        return (
            <Redirect to="/" />
        )
    }

    if (loading) {
        return (
            <StyledLoaderContainer style={{ textAlign: "center" }}>
                    <Loading message="" />
            </StyledLoaderContainer>
        )
    }

    const MenuBarDiv = styled.nav<{
        detached?: boolean;
        position?: boolean;
        withBackground?: boolean;
        withBorderBottom?: boolean;
        headerNavStyles?: any;
    }>`
        top: ${(props) => (props.detached ? "20px" : "0")};
        margin-top: ${(props) => (props.detached ? "20px" : "0")};
        justify-content: center;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 80px;
        background-color: ${({ withBackground }) => (withBackground ? "#ffffff" : "none")};
        border-bottom: ${({ withBorderBottom }) => (withBorderBottom ? "1px solid #F2F4F7" : "none")};
        margin-bottom: 20px;

        ${({ headerNavStyles }) => headerNavStyles && headerNavStyles};
    `;

    const NavbarContainer = styled(Container as any)`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 767px) {
            margin: 0;
            margin-left: 0;
            margin-right: 0;
        }
    `;
    const NavBarBrand = styled(Link)`
    display: inline-block;
    padding: 20px 0;
    margin-right: 40px;
    background: none;
    border: none;
    & > img {
        &:not(.as-icon) {
            width: 200px;
            height: 38px;
        }
        &.as-icon {
            margin-right: 10px;
        }
    }

    @media (max-width: 480px) {
        &.hide-mobile {
            display: none;
        }
    }
`;

const NavBarBrandImg = styled.img`
    height: 30px;
    width: auto;

`;

const SubTitle = styled(Body3 as any)`
    font-weight: bold;
    font-size: 25px;
    color: #1C4451;
`
const SubTitleContent = styled(Body2 as any)`
    color: #1C4451;
    margin-bottom: 1rem;
`

const ContentContainer = styled.div`
    border-left: solid #000000 1px;
    padding: 10px;

    ::-webkit-scrollbar {
        display: none;
    }
`

const TwoColumnContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;

    @media (max-width: 980px) {
        grid-template-columns: auto;
        display: relative;
    }
`;



const _onRegisterUser = () => async (
    values: typeof formikRegister,
    actions: FormikHelpers<typeof formikRegister>,
) => {
    try {
        actions.setSubmitting(true);
        if(!values.agreeToTerms) {
            toast.error("Please agree to the terms and conditions to continue");
            actions.setSubmitting(false);
            return;
        }
        let response = await optInToCounterpartHR({
            firstName: values.first_name,
            lastName: values.last_name,
            title: values.title,
            email: values.email,
            phoneNumber: values.phone,
            token: values.token,
            companyId: values.company_id
        });

        if(response.loginLink) {
            setLoginLink(response.loginLink);
        } else {
            setLoginLink("");
        }

        actions.setValues(formikRegister);
        toast.success("Confirming Registration...");
        // Update the Right side content to say "Thank you for registering, your will receive an email to access your HR Portal shortly"
        setSuccessfullyRegistered(true);

    } catch (error) {
        actions.setSubmitting(false);
        toast.error("An error ocurred, please try again later.");
        if (error && error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

const CheckboxRegister = styled(Field)`
    margin-top: 5px;
    color: #FFFFFF;
    width: 25px;
    height: 25px;
    outline: white !important;
    appearance: auto !important;
`;

const TextRegister = styled(FormikInputField)`
    .input-label {
        color: #FFFFFF !important;
        opacity: 1 !important;
    }
`;

const JourneyTitleH1 = styled(H1 as any)`
    font-size: 40px;
    font-weight: bold;
    color: #1C4451;
    @media (max-width: 700px) {
        font-size: 40px;
    }
`;

const JourneyTitleH2 = styled(H1 as any)`
    font-size: 30px;
    font-weight: bold;
    color: #1C4451;
    @media (max-width: 700px) {
        font-size: 20px;
    }
`;

    return (
        <StyledPage style={{backgroundColor: "#1C4451"}}>
            <Helmet>
                <title>Counterpart | Register for Counterpart HR Services</title>
            </Helmet>
            <MenuBarDiv>
                <NavbarContainer>
                    <NavBarBrand to="/">
                        <NavBarBrandImg
                            className="as-icon"
                            src={hr_counterpart_logo}
                            alt="Counterpart"
                        />
                </NavBarBrand>
                </NavbarContainer>
            </MenuBarDiv>
            <StyledContainer style={{ marginTop: "0px"}}>
                <TwoColumnContainer>
                    <div style={{
                        position: "relative", backgroundColor: "#B0D3DD", padding: "2rem", margin:"1rem", borderRadius: "1000"
                    }}>
                        <JourneyTitleH1>Access ZeroHR Services</JourneyTitleH1>
                        <JourneyTitleH2>Counterpart customers get access to ZeroHR compliance and legal support, including all of the following:</JourneyTitleH2>
                        <ContentContainer>
                            <SubTitle>
                                Dedicated HR Expert
                            </SubTitle>
                            <Body2>
                                Access Certified HR Consultants dedicated to providing small businesses ongoing HR and compliance support.
                            </Body2>
                            <SubTitle>
                                HR Attorney
                            </SubTitle>
                            <SubTitleContent>
                                Access Licensed Employment Attorneys experienced with helping companies like yours address even the most complex employment issues.
                            </SubTitleContent>
                            <SubTitle>
                                Harassment & Discrimination Training
                            </SubTitle>
                            <SubTitleContent>
                                Simple, online training for your entire workforce to ensure compliance with state training mandates.
                            </SubTitleContent>
                            <SubTitle>
                                Model Policies & Procedures
                            </SubTitle>
                            <SubTitleContent>
                                Adopt best practices, policies, & procedures developed for small businesses by Licensed Employment Attorneys and Certified HR Consultants.
                            </SubTitleContent>
                            <SubTitle>
                                Employee Handbook Builder
                            </SubTitle>
                            <SubTitleContent>
                                Create an employee handbook customized to your business needs in minutes.
                            </SubTitleContent>
                        </ContentContainer>
                    </div>
                    <div id="content">
                        {(successfullyRegistered) ? (
                            <div style={{// align content in middle
                                display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", color: "#FFFFFF"
                            }}>
                                <h1 style={{ fontSize: "40px", fontWeight: "bold", color: "#FFFFFF" }}>THANK YOU FOR REGISTERING</h1>
                                <Body2 style={{ fontSize: "20px" }}>
                                    You will receive an email shortly confirming your registration.
                                </Body2>
                                {(loginLink !== "") ? (
                                    <Body2 style={{ fontSize: "20px" }}>
                                        <a href={loginLink} target="_blank" rel="noopener noreferrer" style={{ color: "#FFFFFF" }}>
                                            <Button>Set Your Password</Button>
                                        </a>
                                    </Body2>
                                ): (<></>)}
                            </div>
                        ) : (

                            <Formik
                                initialValues={formikRegister}
                                onSubmit={_onRegisterUser()}
                            >
                            {({ isSubmitting }) => (
                                <Form style={{color: "#FFFFFF", marginTop: "10px"}}>
                                    <JourneyTitleH2 style={{ color: "#FFFFFF" }}>Setup account for {(companyName)}</JourneyTitleH2>
                                        <div style={{ }}>
                                            <TextRegister
                                                name="first_name"
                                                label="First Name"
                                                type="text"
                                                placeholder="First Name"
                                                style={{ background: "#FFFFFF" }}
                                                required={true}
                                            />
                                            <TextRegister
                                                name="last_name"
                                                label="Last Name"
                                                type="Text"
                                                placeholder="Last Name"
                                                style={{ background: "#FFFFFF" }}
                                                required={true}
                                            />
                                            <TextRegister
                                                name="title"
                                                label="Title"
                                                type="text"
                                                placeholder="Title"
                                                style={{ background: "#FFFFFF" }}
                                                required={true}
                                            />
                                            <TextRegister
                                                name="email"
                                                label="Email"
                                                type="email"
                                                placeholder="Email"
                                                style={{ background: "#FFFFFF" }}
                                                required={true}
                                            />
                                            <TextRegister
                                                name="phone"
                                                label="Phone Number"
                                                type="phone"
                                                placeholder="Phone Number"
                                                required={true}
                                                style={{ background: "#FFFFFF" }}
                                            />
                                            <Field type="hidden" name="company_id" />
                                            <Field type="hidden" name="token" />
                                            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                                <CheckboxRegister type="checkbox" name="agreeToTerms" required={true} style={{
                                                    marginTop: "5px",
                                                    color: "#FFFFFF",
                                                    width: "25px",
                                                    height: "25px",
                                                    outline: "white !important",
                                                    appearance: "auto !important"
                                                    }} /> <span>I Agree to the ZeroHR <a href={"https://www.zerohr.com/terms"} target={"_blank"} rel="noopener noreferrer">Terms of Servcie</a> and <a href={"https://www.zerohr.com/privacy"} target={"_blank"} rel="noopener noreferrer">Privacy Policy</a></span>
                                            </div>
                                        </div>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            Register for ZeroHR
                                        </Button>
                                </Form>
                            )}
                        </Formik>
                        )}
                    </div>
                </TwoColumnContainer>
            </StyledContainer>
        </StyledPage>
    );
};

export default HRRegistration;
