import React, { useEffect } from 'react';
import { auth } from "services"
import { Redirect } from "react-router-dom";

const LogoutPage: React.SFC = () => {
    useEffect(() => {
        // Create an scoped async function in the hook
        async function logout() {
            await auth.logout()
        }
        // Execute the created function directly
        logout();
      }, []);

      return <Redirect to='/login' />;
  };

  export default LogoutPage;
