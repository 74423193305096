import React from "react";
import styled from "styled-components/macro";

const RedContainer = styled.div`
    background: #ff0000;
    color: #ffffff;
    text-align: center;
    user-select: none;
    padding: 10px;
    position: relative;
    z-index: 1000000000;
`;

declare global {
    interface Document {
        documentMode?: any;
    }
}

function IEIndicator() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (!isIE) return null;
    return (
        <RedContainer>
            Microsoft has announced the end of support for Internet Explorer. We recommend using{" "}
            <a href="microsoft-edge:https://yourcounterpart.com" style={{ color: "#FFFFFF" }}>
                <b>Microsoft Edge</b>
            </a>
            ,{" "}
            <a href="https://www.google.com/chrome/" style={{ color: "#FFFFFF" }}>
                <b>Google Chrome</b>
            </a>
            , or{" "}
            <a href="https://www.mozilla.org/en-US/firefox/new/" style={{ color: "#FFFFFF" }}>
                <b>Mozilla Firefox</b>
            </a>{" "}
            for a faster and more secure experience on this site.
        </RedContainer>
    );
}

export default IEIndicator;
