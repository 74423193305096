import * as React from "react";
import BaseInput from "./BaseInput";
import { CaptionText } from "../typography";
import { FormErrorMessage } from "./styled";

import { useField, ErrorMessage } from "formik";
import styled from "styled-components/macro";
import { Tooltip, TooltipContainer } from "../../counterpart-theme";

import { UncontrolledTooltip } from "reactstrap";
import { observer } from "mobx-react-lite";

import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";

const BaseSelect = styled(BaseInput.withComponent("select") as any)``;

type Props = {
    label?: string;
    errorMessage?: string;
    tooltipText?: string;
    addEmptyOption?: boolean;
    columnSize?: number;
    options: { [key: string]: string };
} & React.ComponentProps<typeof BaseSelect>;

const SelectContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

const SelectField = ({
    ref,
    label,
    error,
    errorMessage,
    addEmptyOption,
    options,
    className,
    columnSize = 1,
    tooltipText,
    ...rest
}: Props) => {
    const hasTooltip = tooltipText !== undefined;
    const randomId: number = Math.floor(Math.random() * Math.floor(999999));
    return (
        <SelectContainer className={className} style={{ flex: columnSize || 1 }}>
            {label && (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CaptionText as="label">{label}</CaptionText>
                    <TooltipContainer style={{ marginLeft: 8, padding: 0 }}>
                        <Tooltip
                            id={`uncontrolled${randomId}`}
                            className={hasTooltip ? "" : "hidden"}
                        />
                        <UncontrolledTooltip placement="top" target={`uncontrolled${randomId}`}>
                            {tooltipText}
                        </UncontrolledTooltip>
                    </TooltipContainer>
                </div>
            )}

            <BaseSelect {...rest} error={error} ref={ref}>
                {addEmptyOption && <option value="" disabled label="Click to see options" />}
                {Object.keys(options).map((key) => (
                    <option key={key} value={key} label={options[key]} />
                ))}
            </BaseSelect>
            {error && errorMessage && (
                <ErrorMessage name={rest.name as string} component={FormErrorMessage} />
            )}
        </SelectContainer>
    );
};

type FormikProps = Props & { name: string; tooltipText?: string };

export const FormikSelectField = observer((props: FormikProps) => {
    const [field, meta] = useField(props.name);
    return (
        <SelectField
            error={!!meta.touched && !!meta.error}
            errorMessage={meta.error}
            tooltipText={props.tooltipText}
            {...field}
            {...props}
            value={field.value}
            autocomplete="new-password"
        />
    );
});
FormikSelectField.displayName = "FormikInputField";

export const SelectTypeAhead = ({
    label,
    error,
    errorMessage,
    addEmptyOption,
    options,
    className,
    columnSize = 1,
    tooltipText,
    placeholder = "",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = () => {},
    ...rest
}: Props) => {
    const hasTooltip = tooltipText !== undefined;
    const randomId: number = Math.floor(Math.random() * Math.floor(999999));
    let myOptions: any = addEmptyOption
        ? { id: "", text: "Click to see options", ...options }
        : options;
    myOptions = Object.keys(options).map((key) => ({ id: key, text: options[key] }));
    return (
        <SelectContainer className={className} style={{ flex: columnSize || 1 }}>
            {label && (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CaptionText as="label">{label}</CaptionText>
                    <TooltipContainer style={{ marginLeft: 8, padding: 0 }}>
                        <Tooltip
                            id={`uncontrolled${randomId}`}
                            className={hasTooltip ? "" : "hidden"}
                        />
                        <UncontrolledTooltip placement="top" target={`uncontrolled${randomId}`}>
                            {tooltipText}
                        </UncontrolledTooltip>
                    </TooltipContainer>
                </div>
            )}

            <Typeahead
                id={randomId}
                placeholder={placeholder}
                clearButton={true}
                options={myOptions}
                labelKey={(option: any) => option?.text}
                maxResults={100000}
                paginate={false}
                inputProps={{
                    className: `custom-form-control temp-text-input is-typeahead ${className} `,
                    ...rest,
                    autoComplete: "new-password",
                    onPaste: (e: any) => {
                        e && e.preventDefault();
                        return false;
                    },
                }}
                onChange={onChange}
                renderMenu={(results, menuProps) => (
                    <Menu
                        itemCount={results.length}
                        {...menuProps}
                        className="typeahead-select-box"
                    >
                        {results.map((result: any, index: number) => (
                            <MenuItem
                                className="typeahead-select-item"
                                key={result?.id}
                                option={result}
                                position={index}
                                data-container="body"
                                data-toggle="tooltip-typeahead"
                                data-placement="right"
                                title={result?.text}
                            >
                                {result?.text}
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            />
            {error && errorMessage && (
                <ErrorMessage name={rest.name as string} component={FormErrorMessage} />
            )}
        </SelectContainer>
    );
};

export default SelectField;
