// frontend/src/services/generated/alerts.ts
// Auto Generated File, Do not edit.

import apiClient from "./../apiClient";
import * as apiTypes from "CounterpartApiTypes";
import * as logger from "./../logger";

type RemoveUndefined<T> = T extends undefined ? never : T;

type RemoveUndefinedKeys<T> = T extends {}
    ? {
          [P in keyof T]-?: RemoveUndefined<T[P]>;
      }
    : T;

type ApiReturnType<T> = T extends Array<any> ? RemoveUndefinedKeys<T[0]>[] : RemoveUndefinedKeys<T>;

/**
 * clearAlerts
 * ---
 *
 *
 * @api POST /api/alerts/clear/
 *
 * @param {apiTypes.AlertClear} data
 */
export async function clearAlerts(
    data: apiTypes.AlertClear,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Alert[]>> {
    try {
        const url = `/api/alerts/clear/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "clearAlerts"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * getAlertsList
 * ---
 *
 *
 * @api GET /api/alerts/
 *
 */
export async function getAlertsList(apiOptions = {}): Promise<ApiReturnType<apiTypes.Alert[]>> {
    try {
        const url = `/api/alerts/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getAlertsList"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}
