/* eslint-disable react/display-name */
import React from "react";
import styled from "styled-components/macro";
import { /* IDataTableColumn */ IDataTableConditionalRowStyles } from "react-data-table-component";

import { DashboardQuote } from "services/brokers";
import { formatCurrency, formatDate } from "utils";
import { ArialBaseCSS } from "components/typography";

import { ReactComponent as CautionIcon } from "assets/alert-blue-cautionmark.svg";
import { ReactComponent as CautionRedIcon } from "assets/alert-red-cautionmark.svg";
import { ReactComponent as CheckIcon } from "assets/alert-blue-checkmark.svg";
import { ReactComponent as EditIcon } from "assets/pencil-blue-icon.svg";
import { AlertEventtypeEnum } from "definitions/enums";
import { Button } from "reactstrap";

const StyledButton = styled.button`
    ${ArialBaseCSS}
    font-size: 14px;
    line-height: 24px;
    border: none;
    background: none;
    text-align: left;
    @media (max-width: 960px) {
        color: ${(props) => props.theme.colors.secondary};
        font-weight: bold;
    }
`;

const StyledAlertText = styled.span`
    ${ArialBaseCSS}
    font-style: normal;
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
    line-height: 130%;
    text-align: right;
    color: ${(props) => props.theme.colors.accentColor};
`;

export const getTypeAlert = (eventType: any) => {
    console.log(eventType);
    const cautionTypes: AlertEventtypeEnum[] = [
        AlertEventtypeEnum.APPLICATION_DECLINED,
        AlertEventtypeEnum.SUBJECTIVITIES_OUTSTANDING,
        AlertEventtypeEnum.POLICY_CANCELLED,
        AlertEventtypeEnum.NOTICE_OF_RENEWAL,
    ];
    const cautionRedTypes: AlertEventtypeEnum[] = [
        AlertEventtypeEnum.NOTICE_OF_NON_PAYMENT,
        AlertEventtypeEnum.NOTICE_OF_CANCELLATION,
        AlertEventtypeEnum.NOTICE_OF_NON_RENEWAL,
    ];
    const checkTypes: AlertEventtypeEnum[] = [
        AlertEventtypeEnum.QUOTE_AVAILABLE,
        AlertEventtypeEnum.POLICY_BOUND,
        AlertEventtypeEnum.POLICY_ISSUED,
    ];

    if (cautionTypes.includes(eventType)) return <CautionIcon />;
    if (cautionRedTypes.includes(eventType)) return <CautionRedIcon />;
    if (checkTypes.includes(eventType)) return <CheckIcon />;
    return <></>;
};

export const conditionalRowStyles: IDataTableConditionalRowStyles<DashboardQuote>[] = [];

export const clientTableColumns: any = (
    onRowClicked: any,
    setModalShareOpen: any,
    setAddEdit: any,
    setModalQuote: any,
) => [
    {
        name: "company",
        selector: "company__name",
        sortable: true,
        hide: "md",
        grow: 4,
        // eslint-disable-next-line react/display-name
        cell: (row: any) => (
            <StyledButton onClick={() => onRowClicked(row)}>{row.company.name}</StyledButton>
        ),
    },
    {
        name: "retail agent",
        selector: "broker",
        grow: 3,
        hide: "md",
        fontAlign: "left",
        allowOverflow: true,
        // eslint-disable-next-line @typescript-eslint/camelcase
        cell: (data: any) => {
            if (data.broker !== "-")
                return (
                    <div style={{ display: "flex" }}>
                        <span style={{ whiteSpace: "normal", wordBreak: "normal" }}>
                            {data.broker}
                        </span>
                        <EditIcon
                            style={{
                                alignSelf: "flex-end",
                                padding: "1px",
                                marginLeft: "5px",
                                marginBottom: "2px",
                            }}
                            onClick={() => {
                                setAddEdit("SELECT_AGENT");
                                setModalQuote(data.id);
                                setModalShareOpen(true);
                            }}
                        />
                    </div>
                );

            if (data.broker === "-")
                return (
                    <Button
                        style={{
                            background: "none",
                            border: "none",
                            color: "#5389B5",
                            fontSize: "12px",
                            padding: 0,
                        }}
                        onClick={() => {
                            setAddEdit("ADD_AGENT");
                            setModalQuote(data.id);
                            setModalShareOpen(true);
                        }}
                    >
                        Add Agent
                    </Button>
                );

            return <></>;
        },
    },
    {
        name: "agency",
        selector: "agency",
        hide: "md",
        grow: 3,
        allowOverflow: true,
        cell: ({ agency }: any) => {
            return <span style={{ whiteSpace: "normal" }}>{agency}</span>;
        },
    },
    {
        name: "stage",
        selector: "stage",
        hide: "md",
        grow: 2,
        style: { wordBreak: "normal" },
        allowOverflow: true,
        cell(row: any) {
            let type = "";
            if (row.status === "PENDING" || row.status === "DECLINED" || row.status === "EXPIRED") {
                type = "Application";
            } else if (row.status === "APPROVED" || row.account_lost) {
                type = "Quote";
            }
            if (row.finalized) {
                type = "Policy";
            }
            return <>{type || "-"}</>;
        },
    },
    {
        name: "status",
        selector: "status",
        sortable: true,
        hide: "md",
        grow: 2,
        cell(row: any) {
            let type = "";
            if (row.status === "PENDING" && row.application.status === "SUBMITTED") {
                type = "Under Review";
            }
            if (row.application.status !== "SUBMITTED" && row.status === "PENDING") {
                type = "In Progress";
            }
            if (row.status === "APPROVED") {
                type = "Issued";
            }
            if (row.status === "DECLINED") {
                type = "Declined";
            }
            if (row.status === "EXPIRED") {
                type = "Expired";
            }
            if (row.finalized) {
                type = "Bound";
            }
            return <>{type || "-"}</>;
        },
    },
    {
        name: "account type",
        selector: "account_type",
        hide: "md",
        grow: 3,
        allowOverflow: true,
        cell: ({ accountType }: any) => {
            return <span style={{ whiteSpace: "normal", fontSize: "12px" }}>{accountType}</span>;
        },
    },
    {
        name: "products",
        selector: "products",
        grow: 3,
        cell: ({ products }: any) =>
            (products ?? [])
                .map((p: string) => {
                    if (p === "FIDUCIARY") p = "FID";
                    if (p === "FI") p = "FID";
                    return p;
                })
                .join(", "),
        hide: "md",
    },
    {
        name: "expiration",
        selector: "expiration_date",
        grow: 2,
        // eslint-disable-next-line react/display-name
        cell: ({ expirationDate }: any) => {
            const expiration = formatDate(expirationDate, "MM/DD/YYYY") ?? "-";
            return <span style={{ whiteSpace: "nowrap" }}>{expiration}</span>;
        },
        hide: "md",
    },
    {
        name: "premium",
        selector: "components__insurance_line__value",
        grow: 2,
        cell: (row: any) => {
            console.log(row.status);
            if (
                (row.application.status === "SUBMITTED" && row.status === "PENDING") ||
                row.status === "DECLINED"
            ) {
                return "-";
            }
            return row.finalPremium ? formatCurrency(row.finalPremium, false) : "-";
        },
        hide: "md",
    },
    {
        name: "alerts",
        selector: "alerts",
        right: true,
        hide: "md",
        grow: 3,
        style: { display: "inline-grid" },
        cell: (row: any) =>
            row.alerts.map((a: any, index: number) => (
                <StyledAlertText key={`alert_${index}_${a?.id}`} onClick={() => onRowClicked(row)}>
                    <span>
                        {getTypeAlert(a.eventType)} {a.eventTypeDisplay}
                    </span>
                </StyledAlertText>
            )),
    },
];
