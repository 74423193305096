import React from "react";
import styled from "styled-components/macro";
import { Body1, H4, MuseoSansBaseCSS, H6 } from "components/typography";
import { logger } from "services";

export const Container = styled.div`
    max-width: 1160px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > :only-child {
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 800px) {
        margin: 0;
        & > :only-child {
            margin-left: 0;
            margin-right: 0;
        }
    }
`;

export const BlueDivider = styled.hr`
    margin: 40px auto 0;
    width: 100px;
    height: 3px;
    background: ${(props) => props.theme.colors.secondary};
`;

export const SidebarLine = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
`;

export const ThinDivider = styled.hr`
    margin: 8px auto;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.third};
`;

export const DiscountText = styled(Body1 as any)`
    background: ${(props) => props.theme.colors.secondary};
    border-radius: 6px;
    padding: 4px;
    font-weight: bolder;
    color: ${(props) => props.theme.colors.primaryFrontColor};
    margin-left: 5px;
`;

export const InsuranceLineNameColumn = styled(H4 as any)`
    text-align: left;
`;

export const SharedOptionsContainer = styled.div`
    @media (max-width: 480px) {
        margin-top: 10px;
    }
`;

export const OptionsContainer = styled.div`
    align-items: center;
    display: inline-grid;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
`;

export const RadioGroupContainer = styled.div`
    text-align: left;
`;

export const RadioGroup = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: column;
    text-align: left;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        align-items: center;
        justify-content: center;

        & > *:not(:last-child) {
            margin-right: 20px;
        }
    }
`;

export const ValuesColumn = styled(Body1 as any)`
    font-size: 18px;
`;

export const CoverageInfoIcon = styled.img`
    width: 28px;
    filter: brightness(0);

    @media (max-width: 700px) {
        display: none;
    }
`;

export const CoverageInfoShowMore = styled.div``;

export const CoverageInfoShowMoreIcon = styled.img`
    padding: 8px 6px;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
`;

export const CoverageShowMoreDiv = styled.div`
    ${MuseoSansBaseCSS}
    color: #FFFFFF;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 600;
    background: ${({ theme }) => theme.colors.secondary};
    padding: 10px;
    border-radius: 5px;

    &.open {
        background: ${({ theme }) => theme.colors.third};
        color: #000000;
    }

    @media (max-width: 480px) {
        margin-top: 15px;
    }
`;

export const CoverageShowMoreText = styled.span`
    ${MuseoSansBaseCSS}
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 600;
`;

export const RowDescription = styled.span`
    ${MuseoSansBaseCSS}
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #888888;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    @media (max-width: 767px) {
        display: none;
    }

    &.mobile {
        display: none;
        @media (max-width: 767px) {
            margin-top: 10px;
            display: block;
        }
    }

    @media (max-width: 480px) {
        &.left,
        &.right {
            text-align: center;
        }
    }
`;

export const CoverageInfoHeaderRow = styled.div`
    @media (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & span:last-child {
            text-align: right !important;
        }
    }
`;

export const CoverageInfoHeader = styled.div`
    padding-top: 10px;
    display: grid;

    &.expandable {
        display: grid;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: -ms-grid;
        -ms-grid-columns: 2fr 1fr 1fr 1fr 0.1fr 0.1fr;
        width: 100%;
        & > * {
            -ms-grid-row: 1;
        }
        & > *:nth-child(1) {
            -ms-grid-column: 1;
        }
        & > *:nth-child(2) {
            -ms-grid-column: 2;
            display: block;
        }
        & > *:nth-child(3) {
            -ms-grid-column: 3;
        }
        & > *:nth-child(4) {
            -ms-grid-column: 4;
        }
        & > *:nth-child(5) {
            -ms-grid-column: 5;
        }
        & > *:nth-child(6) {
            -ms-grid-column: 6;
        }
    }

    @media (min-width: 767px) {
        grid-template-columns: 2.1fr 1fr 1fr 1fr 0fr;
    }

    /* @media (max-width: 767px) {
        justify-content: center;
    } */

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        & span.header-title {
            margin-left: 0;
        }

        &.expandable {
            display: flex;
        }
    }
`;

export const CoverageInfoBody = styled.div`
    transition: all ease-in-out 0.3s;

    @media (max-width: 700px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }
`;

export const CoverageInfoItem = styled.div<{ isOpen?: boolean }>`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    overflow: hidden;

    &.hidden {
        display: none;
    }

    &.closed {
        box-shadow: none;
    }

    &.closed ${CoverageInfoBody} {
        max-height: 0;
    }

    &.closed ${CoverageInfoShowMoreIcon} {
        transform: rotate(180deg);
    }

    @media (max-width: 700px) {
        border: none;
        padding: 0;
        box-shadow: none;
    }
`;

CoverageInfoItem.defaultProps = { isOpen: false };

export const DeclineCoverageText = styled.div`
    cursor: pointer;
    color: #ff0000;
    font-weight: 500;
    &.denied {
        color: ${(props) => props.theme.colors.secondary};
    }
    &:hover {
        text-decoration: underline;
    }
    &:hidden {
        display: none;
    }
`;

export const LimitsTableTitle = styled(H6 as any)`
    text-align: left;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 20px;
`;

export const LimitsTableStyle = styled.table`
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0;

    & thead th {
        ${MuseoSansBaseCSS}
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        color: rgba(34, 35, 46, 0.4);
        text-transform: uppercase;
        vertical-align: center;

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
        }
    }

    & tbody, & tr, & td {
        padding: 0;
    }

    tbody td {
        border: 2px solid transparent;
        border-style: solid none;
        transition: 0.2s ease-in-out all;
        vertical-align: center;
    }
    tbody tr:not(.disabled):hover td,
    tbody tr td  {
        vertical-align: center;
        border-bottom: 1px solid ${(props) => props.theme.colors.third};

        &:first-child {
            left: -5px;
            margin-left: -5px;
            padding: 0;
        }
    }

    tbody tr {
        border-radius: 8px;
        border: 2px solid blue;
        cursor: pointer;
        transition: 0.2s ease-in-out all;

        &.disabled {
            opacity: 0.5;
        }
    }
    tbody tr:not(.disabled):hover {
        border-color: ${({ theme }) => theme.colors.secondary};
        /* background: ${(props) => props.theme.colors.third}; */
    }
    tbody > tr.disabled,
    tbody > tr.disabled > td > input[type="radio"]:disabled {
        cursor: not-allowed;
    }

    tbody tr td:last-child {
        text-align: center;
        border-right-style: solid;
    }
    tr td:nth-child(2) {
        text-align: left;
    }
    tr td:nth-child(4) {
        text-align: right;
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;

        & thead th {
            &.right {
                text-align: center;
            }
        }

        & thead {
            display: none;
        }

        & tbody {
            tr {
                display: flex;
                flex-direction: column;
                border: none;

                & td {
                    display: flex;
                    flex: 0 0 100%;
                    justify-content: space-between;
                    border: none;

                    &:first-child {
                        margin: 0;
                        padding: 0;
                    }

                    &:last-child {
                        border:none;
                        justify-content: center;
                    }
                }
            }
        }

    }
`;

export const TrashIcon = styled.img`
    cursor: pointer;
    width: 18px;
    height: 20px;
    margin-top: -5px;
    transition: 0.2s ease-in-out all;

    &:hover {
        filter: contrast(1.5);
    }
`;

export const StyledDownloadPdfButton = styled.a`
    cursor: pointer;
    background: #ffffff;
    border: 2px solid ${(props) => props.theme.colors.third};
    margin-top: 10px;
    cursor: pointer;
    text-transform: uppercase !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    width: 100%;
    max-width: 49%;
    min-width: 300px;
    color: ${(props) => props.theme.colors.textColor};

    & > span {
        display: inline-block;
        text-align: left;
        flex: auto;
        margin-left: 10px;
    }

    @media (max-width: 480px) {
        min-width: 100%;
    }
`;

export const StickyPanel = styled.div`
    position: sticky;
    top: 20px;
    height: fit-content !important;
    @media (min-device-height: 900px) {
        position: sticky;
        top: 10px;
        height: fit-content !important;
    }

    @media (max-width: 1024px) {
        padding-bottom: 180px;
    }
`;

export const ResponsiveContainer = styled.div`
    display: flex;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const SharedLimitOption = styled.div`
    ${MuseoSansBaseCSS}
    position: relative;
    display: inline-grid;
    align-items: center;
    min-width: 110px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border-style: solid;
    border-width: 2px;
    border-color: ${(props) => props.theme.colors.third};
    height: 40px;
    margin: 10px;

    &.selected {
        border-color: ${({ theme }) => theme.colors.secondary};
    }

    & input {
        cursor: pointer;
    }
    & label {
        margin: 0 0 0 10px;
        cursor: pointer;
    }
`;

type DownloadPdfButtonProps = {
    publicSecurityToken?: string | null;
    appSecurityToken?: string | null;
} & React.ComponentProps<typeof StyledDownloadPdfButton>;


export const DownloadPdfButton = ({
    href,
    children,
    publicSecurityToken=null,
    appSecurityToken=null,
    ...rest
}: DownloadPdfButtonProps) => {

    const onClick = async (ev: React.MouseEvent) => {
        ev.preventDefault();
        try {
            let url = href;

            // If we have security tokens, we use them to download the file
            if (publicSecurityToken && appSecurityToken) {
                url = `${href}?publicSecurityToken=${publicSecurityToken}&appSecurityToken=${appSecurityToken}`
            }
            window.open(url);
        } catch (error) {
            logger.error(error);
            alert("Was not possible to download this file");
        }
    }


    return (
        <StyledDownloadPdfButton {...rest} onClick={onClick}>
            {children}
        </StyledDownloadPdfButton>
    );
};
