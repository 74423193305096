import React from "react";
import { FullQuestion, useApplicationContext } from "services/application";
import QuestionRender from "../QuestionRender";
import { TableCategoryContainer, TableCategoryQuestions } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import AddAnother from "./AddAnother";

interface CategoryProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

const TableCategory: React.SFC<CategoryProps> = ({ question, extraProps = {} }) => {
    const { additionalQuestions, setAdditionalQuestions } = useApplicationContext();
    return (
        <TableCategoryContainer
            className="isTable"
            data-name="TableCategoryContainer"
            id={`q-${question.id}__0`}
        >
            <QuestionTitleRender question={question} extraProps={{ ...extraProps }} />
            <TableCategoryQuestions>
                {question.subQuestions?.map((q) => (
                    <QuestionRender
                        question={q}
                        key={q.id}
                        extraProps={{ textForTable: q.title, parentType: "category" }}
                    />
                ))}
            </TableCategoryQuestions>
            {additionalQuestions &&
                additionalQuestions
                    ?.filter((q: any) => q?.copyOf === question.id)
                    .map((additional: any, index: number) => (
                        <TableCategoryQuestions key={`additional_${additional?.id}_${index}`}>
                            {additional.subQuestions?.map((q: any) => (
                                <QuestionRender
                                    question={q}
                                    key={`additional_${additional?.id}_${q.id}_${index}`}
                                    extraProps={{ questionIndex: index + 1 }}
                                />
                            ))}
                        </TableCategoryQuestions>
                    ))}

            {question.allowMultipleAnswers && (
                <AddAnother
                    onClick={() =>
                        setAdditionalQuestions([
                            ...(additionalQuestions || []),
                            { ...question, copyOf: question.id },
                        ])
                    }
                />
            )}
        </TableCategoryContainer>
    );
};

export default React.memo(TableCategory);
