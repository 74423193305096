import React from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import styled from "styled-components/macro";
import { useLocation, useHistory } from "react-router-dom";

import { MuseoSansBaseCSS, BaseFont } from "components/typography";

import { formatCurrency } from "utils";
import { QuoteComponentStore, useAccountStore } from "../store";
import { BlockWithLoader } from "./QuoteComponentDetails";

interface Props {
    currentActiveIndex?: number;
    children: any;
    additionalHook?: any;
}

const CollapseContentWrapper = styled.div`
    padding: 40px 30px 20px 30px;
    position: relative;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

const CollapseContainer = styled.div`
    ${BaseFont};
    border: 1px solid #dbdbdb;
    margin-bottom: 20px;
    border-radius: 10px;

    @media (max-width: 768px) {
        border: none;
    }
`;

const TabMenuItem = styled.button`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    font-style: normal;
    display: flex;
    background: none;
    border: none;
    outline: none;
    color: #22232e;
    padding: 19px 0;

    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    width: 100%;
    text-align: left;

    padding-left: 10px;

    @media (max-width: 768px) {
        padding-left: 5px;
    }

    @media (max-width: 567px) {
        padding: 10px 5px !important;
        /* padding: 10px 0 !important; */
    }

    &:focus {
        outline: none;
    }

    &.disabled {
        cursor: not-allowed;
        color: #bfcad6;
    }
`;

const GridRowTitle = styled.div`
    ${BaseFont};
    display: grid;
    grid-template-columns: 300px 1fr 1fr 1fr;
    font-size: 14px;
    grid-column-gap: 10px;
    margin-bottom: 4px;
    padding: 0 30px;

    & > .grid-row-cell:not(:first-child) {
        text-align: center;
        @media (max-width: 768px) {
            text-align: left;
            padding-left: 10px;
        }
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const TabMenuItemContainer = styled.div`
    display: grid;
    grid-template-columns: 300px 3fr;
    font-size: 14px;
    grid-column-gap: 10px;
    padding: 0 30px;
    border-radius: 10px;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 0;
        &:not(.isActive) {
            border-bottom: 1px solid #bfcad6;
            padding-bottom: 20px;
        }
    }

    & .grid-row-cell {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 5px;
        }

        & > .mobile-title {
            color: #acacb0;
            font-size: 10px;
            text-transform: uppercase;

            @media (min-width: 767px) {
                display: none;
            }
        }

        @media (max-width: 768px) {
            display: flex;
            justify-content: flex-start;
        }
    }
`;

const DataBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
`;

const TabMenuIndicator = styled.div<{ isActive: boolean }>`
    background-color: ${({ theme }) => theme.colors.primary};
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 10px;

    &:before,
    &:after {
        content: "";
        background: #fff;
        position: relative;
    }

    &:before {
        height: 2px;
        width: 12px;
    }

    &:after {
        ${({ isActive }) => isActive && "display: none;"}
        position: absolute;
        width: 2px;
        height: 12px;
    }
`;

const TabContentContainer = styled.div`
    width: 100%;
    padding-top: 30px;
    max-width: 1300px;
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
`;

const FirstTitle = styled.span`
    align-self: flex-start;
`;

const GreyOverlay = styled.div`
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: #fff;
    opacity: 0.5;
`;

export const CollapseRow: React.FC<{
    label: string;
    tabIndex: number;
    disabled?: boolean;
    isActive?: boolean;
    onClick?: () => void;
    quoteComponent?: QuoteComponentStore;
    getPremiumText?: () => string;
    id: string;
}> = observer(
    ({ label, isActive, disabled = false, onClick, quoteComponent, getPremiumText, id }) => {
        const selectedRates = Object.entries(quoteComponent?.calculatedRates || {}).filter(
            ([, rate]) => rate?.isSelected,
        )[0][1];
        const { quote } = useAccountStore();
        const premiumText = getPremiumText ? getPremiumText() : "-";
        return (
            <TabMenuItemContainer id={id} className={classNames({ isActive, disabled })}>
                <TabMenuItem className={classNames({ isActive, disabled })} onClick={onClick}>
                    <TabMenuIndicator isActive={isActive || false} />
                    {!quoteComponent?.coverageDenied ? (
                        label
                    ) : (
                        <span style={{ opacity: 0.5 }}>{label}</span>
                    )}
                </TabMenuItem>
                <DataBlock>
                    <div className="grid-row-cell">
                        <FirstTitle className="mobile-title">limit:</FirstTitle>
                        {quoteComponent?.insuranceLine.canShareLimit && quote.sharedLimits ? (
                            "Shared"
                        ) : (
                            <span>
                                {!quoteComponent?.coverageDenied && selectedRates?.limit !== null
                                    ? formatCurrency(selectedRates?.limit || 0)
                                    : "-"}
                            </span>
                        )}
                    </div>
                    <div className="grid-row-cell">
                        <span className="mobile-title">retention:</span>
                        <span>
                            {!quoteComponent?.coverageDenied && selectedRates?.retention !== null
                                ? formatCurrency(selectedRates?.retention || 0)
                                : "-"}
                        </span>
                    </div>
                    <div className="grid-row-cell">
                        <span className="mobile-title">premium:</span>
                        <BlockWithLoader loading={quoteComponent?.loading || false}>
                            <strong>{!quoteComponent?.coverageDenied ? premiumText : "-"}</strong>
                        </BlockWithLoader>
                    </div>
                </DataBlock>
            </TabMenuItemContainer>
        );
    },
);
const Collapse: React.FC<Props> = ({ currentActiveIndex = 0, children, additionalHook }) => {
    const [activeIndex, setActiveIndex] = React.useState<any>([]);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryTabIndex = useQuery().get("open");
    const history = useHistory();

    React.useEffect(() => {
        const unlisten = history.listen((location) => {
            const tabIndex = location.search.split("=")[1];
            if (tabIndex) {
                setActiveIndex([+tabIndex]);
                const element = document.getElementById(`review-coverage-collapse-${tabIndex}`);
                setTimeout(() => {
                    element?.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 0);
            }
        });
        return () => unlisten();
    }, [history]);

    React.useEffect(() => {
        if (queryTabIndex) {
            setActiveIndex([+queryTabIndex]);
            const element = document.getElementById(`review-coverage-collapse-${queryTabIndex}`);
            setTimeout(() => {
                element?.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 0);
        } else {
            setActiveIndex([]);
        }

        if (additionalHook !== undefined) {
            additionalHook(currentActiveIndex);
        }
    }, [currentActiveIndex, additionalHook, queryTabIndex]);

    const onClickTabItem = (tabIndex: any, disabled = false): void => {
        if (disabled) {
            return;
        }
        if (activeIndex.includes(tabIndex)) {
            const newArr = [...activeIndex];
            newArr.splice(newArr.indexOf(tabIndex), 1);
            setActiveIndex(newArr);
        } else {
            setActiveIndex([...activeIndex, tabIndex]);
        }
        if (additionalHook !== undefined) {
            additionalHook(tabIndex);
        }
    };

    return (
        <TabsContainer>
            <TabContentContainer>
                <GridRowTitle>
                    <div className="grid-row-cell">
                        <span style={{ color: "#707070", fontSize: 10 }}>COVERAGE</span>
                    </div>
                    <div className="grid-row-cell">
                        <span style={{ color: "#707070", fontSize: 10 }}>LIMIT</span>
                    </div>
                    <div className="grid-row-cell">
                        <span style={{ color: "#707070", fontSize: 10 }}>RETENTION</span>
                    </div>
                    <div className="grid-row-cell">
                        <span style={{ color: "#707070", fontSize: 10 }}>PREMIUM</span>
                    </div>
                </GridRowTitle>
                {children?.map((child: any) => {
                    if (Array.isArray(child)) {
                        // eslint-disable-next-line
                        return child.map((child: any) => {
                            if (child && child.props) {
                                if (!activeIndex.includes(child.props.tabIndex)) return undefined;
                                return <div>{child.props.children}</div>;
                            }
                        });
                    }
                    if (child && child.props) {
                        const {
                            label,
                            tabIndex,
                            disabled,
                            quoteComponent,
                            getPremiumText,
                            id,
                        } = child?.props;
                        const isOpened = activeIndex.includes(child.props.tabIndex);
                        return (
                            <CollapseContainer key={`TabLabel__${tabIndex}`}>
                                <CollapseRow
                                    key={`TabLabel__${tabIndex}`}
                                    tabIndex={tabIndex}
                                    label={label}
                                    isActive={activeIndex.includes(tabIndex)}
                                    disabled={disabled}
                                    onClick={() => onClickTabItem(tabIndex, disabled)}
                                    quoteComponent={quoteComponent}
                                    getPremiumText={getPremiumText}
                                    id={id}
                                />
                                {isOpened && (
                                    <CollapseContentWrapper>
                                        {child.props.children}
                                        {quoteComponent.coverageDenied && <GreyOverlay />}
                                    </CollapseContentWrapper>
                                )}
                            </CollapseContainer>
                        );
                    }
                })}
            </TabContentContainer>
        </TabsContainer>
    );
};

export default Collapse;
