import React from "react";
import { Typeahead as BootstrapAutocomplete } from "react-bootstrap-typeahead";
import styled from "styled-components/macro";
import { CaptionText } from "components/typography";
import { useField } from "formik";
import { FormErrorMessage } from "./styled";
import { UncontrolledTooltip } from "reactstrap";
import { Tooltip, TooltipContainer } from "counterpart-theme";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    & .rbt-highlight-text {
        padding: 0;
        font-weight: bold;
        background: transparent;
    }
`;

const RedAsterisk = styled.b`
    margin-left: 2px;
    margin-top: -5px;
    align-items: center;
    font-size: 16px;
    line-height: 140%;
    color: #d50f1f;
`;

type AutocompleteFieldProps<
    T extends { id: string; text: string; subIndustry: string } = {
        match?: any; id: string; text: string; subIndustry: string
}
> = {
    name: string;
    options: T[];
    placeholder: string;
    onChange: (selected: T[]) => void;
    label: string;
    className?: string;
    errorMessage?: string | null;
    selected: T[];
    tooltipText?: string;
    renderMenuItemChildren?: any;
    onInputChange?: any;
    secondName: string;
    thirdName: string;
};

const AutocompleteField: React.SFC<AutocompleteFieldProps> = ({
    name,
    options,
    placeholder,
    onChange,
    selected,
    className,
    errorMessage,
    label,
    tooltipText,
    renderMenuItemChildren,
    onInputChange,
}) => {
    const _options = options.map(o => ({...o, match: renderMenuItemChildren(o, false, false, true)}));
    return (
        <Container>
            <div style={{ display: "flex" }}>
                <CaptionText as="label">{label}</CaptionText>
                <RedAsterisk>*</RedAsterisk>
                <TooltipContainer style={{ marginLeft: 8, padding: 0 }}>
                    <Tooltip
                        id={`uncontrolledTooltip`}
                        className={tooltipText && tooltipText?.length > 0 ? "" : "hidden"}
                        tabIndex={-1}
                    />
                    <UncontrolledTooltip placement="top" target={`uncontrolledTooltip`}>
                        {tooltipText}
                    </UncontrolledTooltip>
                </TooltipContainer>
            </div>
            <BootstrapAutocomplete
                id={name}
                clearButton={true}
                options={_options}
                labelKey="text"
                filterBy={["fullTextSearch", "text"]}
                placeholder={placeholder}
                selected={selected}
                maxResults={100}
                onChange={onChange}
                renderMenuItemChildren={renderMenuItemChildren}
                onInputChange={onInputChange}
                inputProps={{
                    className: className || "",
                    name,
                    autoComplete: "off",
                }}
            />
            {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </Container>
    );
};

const StyledAutocompleteField = styled(AutocompleteField)`
    font-family: Arial, Helvetica, sans-serif;
    transition: ease-in-out all 0.2s;
    width: 100%;
    height: 54px;
    line-height: 54px;
    border-width: 1px;
    border-radius: 0px;
    border-style: solid;
    outline: none !important;
    color: ${({ errorMessage, theme }) => {
        if (errorMessage) return theme.colors.systemError;
        return theme.colors.textColor;
    }};

    &:focus {
        box-shadow: none;
        border-color: ${({ theme }) => theme.colors.primary};
    }

    &::placeholder {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #22232e !important;
        opacity: 0.4;
        font-weight: normal;
    }
`;

type FormikAutoCompleteFieldProps = Omit<AutocompleteFieldProps, "selected" | "onChange"> &
    Partial<Pick<AutocompleteFieldProps, "onChange">>;

export const FormikAutoCompleteField = ({ onChange, ...props }: FormikAutoCompleteFieldProps) => {
    const [, meta, helper] = useField(props.name);
    const [,, secondHelper] = useField(props.secondName);
    const [,, thirdHelper] = useField(props.thirdName);
    const [inputValue, setInputValue] = React.useState("");
    const _onChange = (selected: typeof props.options) => {
        const value = selected && selected.length ? selected[0].text : "";
        const secondValue = selected && selected.length ? selected[0].subIndustry : "";
        const thirdValue = selected && selected.length ? selected[0].id : "";

        setInputValue("");
        // helper.setTouched(true);
        helper.setValue(value);
        secondHelper.setValue(secondValue);
        thirdHelper.setValue(thirdValue);
        if (onChange) onChange(selected);
    };

    const onInputChange = (text: string) => {
        setInputValue(text);
    };

    const RenderMenuItemComponent = (option: any, skip: any, skipThis: any, returnMatch = false) => {
        let matchedValue = "";

        let sic = null;
        const isNumber = inputValue.length && !isNaN(+inputValue);
        const isEmpty = !inputValue.length;

        if (option.fullTextSearch.toLowerCase().includes(inputValue.toLowerCase())) {
            matchedValue = option.subIndustry;
        }

        const concatStrings = (position: number, value: string, string: string) => {
            return [string.slice(0, position), value, string.slice(position)].join("");
        };

        const wrapInTag = (string: string, start: number, end: number) => {
            const str = concatStrings(end, "</strong>", string);

            return concatStrings(start, "<strong>", str);
        };

        const getDisplayedMatched = (start: number, end: number, matched: any, inputText: any) => {
            const wrapedstr = wrapInTag(matched, start, end);

            return `<span>${wrapedstr}</span>`;
        };

        const start = matchedValue.toLowerCase().indexOf(inputValue.toLowerCase());

        const end = Number(start) + Number(inputValue.length);

        const displayedMatched = getDisplayedMatched(start, end, matchedValue, inputValue);

        const match = `${matchedValue} in ${option.fullTextSearch}`;

        if (returnMatch) {
            return match;
        } else {
            return (
                <>
                    {isEmpty ? (
                        <span>{option.fullTextSearch}</span>
                    ) : (
                        <>
                            {isNumber && sic ? (
                                <>
                                    <strong>{sic}</strong>
                                    <span style={{ opacity: 0.4 }}> SIC code for </span>
                                    <span style={{ color: "#12B3DC" }}>{option.id}</span>
                                </>
                            ) : (
                                <>
                                    <span dangerouslySetInnerHTML={{ __html: displayedMatched }}></span>
                                    <span> in </span>
                                    <span style={{ color: "#12B3DC" }}>{option.id}</span>
                                </>
                            )}
                        </>
                    )}
                </>
            );
        }
    };

    const _selected = props.options.filter((v) => v.text === meta.value);

    return (
        <StyledAutocompleteField
            {...props}
            onChange={_onChange}
            onInputChange={onInputChange}
            errorMessage={meta.error}
            renderMenuItemChildren={RenderMenuItemComponent}
            selected={_selected}
        />
    );
};

export default StyledAutocompleteField;
