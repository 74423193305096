import React from "react";
import { useApiGet, assert } from "utils";
import { ThenArg } from "utils/types";
import { Loading, Page } from "components/common";
import { H1 } from "components/typography";
import { OnePaperBackground } from "counterpart-theme";

type LoaderChildProps<F extends (...a: any[]) => Promise<any>> = {
    data: ThenArg<ReturnType<F>>;
    reloadData: () => void;
};

interface LoaderProps<F extends (...a: any[]) => Promise<any>> {
    func: F;
    args: Parameters<F>;
    children: (props: LoaderChildProps<F>) => JSX.Element;
    message?: string;
}

function Loader<F extends (...a: any[]) => Promise<any>>({
    func,
    args,
    children,
    message,
}: LoaderProps<F>) {
    const [loading, data, error, reloadData] = useApiGet(func, ...args);
    if (loading) {
        return (
            <Page style={{ marginTop: "100px" }}>
                <OnePaperBackground className="text-center" style={{ width: "80%" }}>
                    <div className="py-5">
                        <Loading message={message} />
                    </div>
                </OnePaperBackground>
            </Page>
        );
    }

    if (error) {
        if (process.env.NODE_ENV !== "production") throw error;
        return <H1>Error while loading this screen</H1>;
    }

    assert(data);
    return children({ data, reloadData });
}
export default Loader;
