import React from "react";
import styled from "styled-components/macro";
import { H2, MuseoSansBaseCSS } from "components/typography";
import { VerticalDivider } from "components/common";

import responsive from "services/responsive";

interface Props {
    title?: string;
    children: React.ReactNode;
    borderBottom?: boolean;
    quoteInfo?: any;
    isPolicy?: boolean;
    style?: any;
    headerAdditionalComponent?: React.ReactNode;
    additionalInfo?: any;
}

const HeaderContainer = styled.div<{ borderBottom?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px;
    ${({ borderBottom, theme }) =>
        borderBottom ? `border-bottom: 2px solid ${theme.colors.third};` : ""}

    @media (max-width: 480px) {
        flex-direction: column;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        -ms-flex-direction: column;
        width: 100%;
        position: relative;
    }
`;

const MainContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 1250px;
    flex-direction: column;

    @media (max-width: 767px) {
        margin-bottom: 60px;
        padding: 0;
    }
`;

const QuoteInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    min-height: 30px;

    &:last-child {
        border-right: none;
        padding-right: 0;
    }

    @media (max-width: 480px) {
        justify-content: space-between;
        padding-right: 0;
        border-right: none;
        &:last-child {
            padding-left: 0;
        }
    }
`;

const SmallTitle = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 160%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    opacity: 0.4;
`;

const CommonText = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
`;

const QuoteInfoMainContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 480px) {
        justify-content: space-between;
        & ${QuoteInfoContainer}:first-child {
            text-align: left;
        }
        & ${QuoteInfoContainer}:last-child {
            text-align: right;
        }
        & > ${VerticalDivider} {
            display: none;
        }
    }
`;

const CommonContainer: React.FC<Props> = ({
    title,
    quoteInfo,
    borderBottom = false,
    children,
    isPolicy,
    style,
    headerAdditionalComponent = <></>,
    additionalInfo,
}) => {
    const { quoteDate, quoteNumber, commission, policyExpirationDate } = quoteInfo || {};
    return (
        <MainContainer style={style}>
            <HeaderContainer borderBottom={borderBottom}>
                {title && title.length > 0 && (
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <H2 style={{ padding: 0 }}>{title}</H2>
                    </div>
                )}

                {quoteInfo && (
                    <QuoteInfoMainContainer>
                        <QuoteInfoContainer>
                            <SmallTitle>{isPolicy ? `policy ` : `quote `} number</SmallTitle>
                            <CommonText>{quoteNumber}</CommonText>
                        </QuoteInfoContainer>
                        <VerticalDivider style={{ height: 30, margin: "20px" }}>
                            &nbsp;
                        </VerticalDivider>
                        <QuoteInfoContainer>
                            <SmallTitle>{isPolicy ? `policy ` : `quote `} expires</SmallTitle>
                            <CommonText>{isPolicy ? policyExpirationDate : quoteDate}</CommonText>
                        </QuoteInfoContainer>
                        <VerticalDivider style={{ height: 30, margin: "20px" }}>
                            &nbsp;
                        </VerticalDivider>
                        <QuoteInfoContainer>
                            <SmallTitle>Commission</SmallTitle>
                            <CommonText>{commission}</CommonText>
                        </QuoteInfoContainer>
                    </QuoteInfoMainContainer>
                )}
                {additionalInfo && (
                    <QuoteInfoContainer>
                        <SmallTitle>{additionalInfo.title}</SmallTitle>
                        <CommonText>{additionalInfo.text}</CommonText>
                    </QuoteInfoContainer>
                )}
                {headerAdditionalComponent}
            </HeaderContainer>
            <ContentContainer>{children}</ContentContainer>
        </MainContainer>
    );
};

export default React.memo(CommonContainer);
