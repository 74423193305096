import React from "react";
import { observer } from "mobx-react-lite";

import { Button } from "components/common";
import { SectionSelectorText } from "components/typography";

import { SubjectivitiesStoreType } from "../store";
import {
    SidebarContainer,
    SidebarMarkMenu,
    SidebarMenuItemContainer,
    SidebarPanel,
    SidebarTitle,
    SidebarCustomButton,
} from "./styled";

import ArrowSectionsPercentage from "assets/arrow-up-icon.svg";
import { ControlContainer } from "features/Application/components/styled";
import styled from "styled-components/macro";
import pdfIcon from "assets/pdf-nopaper-icon.svg";
import { StyledDownloadPdfButton } from "features/AccountPage/components/styled";
import { Body2 } from "components/typography";
import { getBaseURL } from "utils";
import { Loading } from "components/common";

const MobileContainer = styled.div`
    display: none;

    @media (max-width: 767px) {
        display: flex;
    }
`;

const SidebarItem: React.FC<{
    completed: boolean;
    label: string;
    href: string;
    itemSelected: string;
    itemIndex: string;
    onClick: () => void;
}> = ({ completed, label, href, itemSelected, itemIndex, onClick }) => {
    return (
        <SidebarMenuItemContainer
            className={itemSelected === itemIndex ? "is-selected" : ""}
            onClick={onClick}
        >
            <SidebarMarkMenu completed={completed} />
            <SidebarCustomButton href={href}>
                <SectionSelectorText style={{ fontSize: 16, lineHeight: "130%", fontWeight: 500 }}>
                    {label}
                </SectionSelectorText>
            </SidebarCustomButton>
        </SidebarMenuItemContainer>
    );
};

const Sidebar: React.FC<{ store: SubjectivitiesStoreType }> = ({ store }) => {
    const startClosed = (window as any).innerWidth <= 1024 ? true : false;
    const firstRequired = (store.requiredSubjectivities || [{}])[0] || {};
    const firstOptional = (store.optionalSubjectivities || [{}])[0] || {};
    const [closedRequired, toggleSectionRequired] = React.useState<boolean>(startClosed);
    const [closedOptional, toggleSectionOptional] = React.useState<boolean>(startClosed);
    const [requiredSelected, setRequiredSelected] = React.useState<string>(
        `required_${firstRequired.id}`,
    );
    const [optionalSelected, setOptionalSelected] = React.useState<string>(
        `optional_${firstOptional.id}`,
    );

    const getDownloadUrl = () => {
        const BASE_URL = getBaseURL();
        return `${BASE_URL}/api/quotes/${store.quoteId}/download`;
    };

    return (
        <SidebarContainer>
            {store.appSignedAndSubjectivitiesSubmitted || store.currentPage === 1 ? (
                <>
                    {store.applicationSignedLocally &&
                    !store.signedApplicationPdf &&
                    !store.hideSubjectivities ? (
                        <></>
                    ) : (
                        <SidebarPanel>
                            <SidebarTitle>Attachments:</SidebarTitle>
                            {store.signedApplicationPdf ? (
                                <StyledDownloadPdfButton
                                    style={{ maxWidth: "100%" }}
                                    href={store.signedApplicationPdf as string}
                                    target="_blank"
                                >
                                    <img src={pdfIcon} alt="Signed Application" />
                                    <Body2 style={{ textTransform: "capitalize" }} as="span">
                                        Signed Application
                                    </Body2>
                                    <span
                                        style={{
                                            color: "#4BAA8F",
                                            fontSize: "10px",
                                            textAlign: "right",
                                            fontWeight: 700,
                                        }}
                                    >
                                        DOWNLOAD
                                    </span>
                                </StyledDownloadPdfButton>
                            ) : (
                                <StyledDownloadPdfButton
                                    style={{ maxWidth: "100%" }}
                                    href={getDownloadUrl() + "/application"}
                                    target="_blank"
                                >
                                    <img src={pdfIcon} alt="Signed Application" />
                                    <Body2 style={{ textTransform: "capitalize" }} as="span">
                                        Application
                                    </Body2>
                                    <span
                                        style={{
                                            color: "#12B3DC",
                                            fontSize: "10px",
                                            textAlign: "right",
                                            fontWeight: 700,
                                        }}
                                    >
                                        DOWNLOAD
                                    </span>
                                </StyledDownloadPdfButton>
                            )}
                        </SidebarPanel>
                    )}
                    {!store.isSigned && !store.showApplicationUpload && (
                        <Button disabled={!(store.canSubmit && (store.appWasSubmitted || store.supplementalApplicationSubmitted))} block onClick={store.setHowSignOpen}>
                            Sign App
                        </Button>
                    )}
                    {(store.isSigned || store.showApplicationUpload) &&
                        store.hasSubjectivities &&
                        !store.appSignedAndSubjectivitiesSubmitted &&
                        !store.hideSubjectivities && (
                            <Button
                                block
                                disabled={!store.isSigned}
                                onClick={() => store.setCurrentPage(1)}
                            >
                                Continue to Subjectivities
                            </Button>
                        )}
                    <SidebarPanel
                        style={{
                            display: store.appIsSubmitting ? "grid" : "none",
                            justifyItems: "center",
                        }}
                    >
                        <Loading message={"Loading"} show={store.appIsSubmitting} />
                    </SidebarPanel>
                </>
            ) : (
                <>
                    {store.currentPage === 0 && (
                        <>
                            <SidebarPanel>
                                <SidebarTitle>Required Questions</SidebarTitle>
                                <SidebarPanel
                                    className={`internal ${closedRequired ? "closed" : ""}`}
                                >
                                    {store.requiredSubjectivities.map((item) => (
                                        <SidebarItem
                                            key={item.id}
                                            completed={item.isAnswered}
                                            label={item.name}
                                            href={`#${item.key}`}
                                            itemIndex={`required_${item.id}`}
                                            itemSelected={requiredSelected}
                                            onClick={() => {
                                                setRequiredSelected(`required_${item.id}`);
                                                toggleSectionRequired(true);
                                            }}
                                        />
                                    ))}
                                </SidebarPanel>
                                <MobileContainer>
                                    <ControlContainer
                                        className={closedRequired ? "closed" : ""}
                                        onClick={() => toggleSectionRequired(!closedRequired)}
                                    >
                                        <img src={ArrowSectionsPercentage} alt="close-menu" />
                                    </ControlContainer>
                                </MobileContainer>
                            </SidebarPanel>
                            {store.optionalQuestionSubjectivities.length > 0 && (
                                <SidebarPanel>
                                    <SidebarTitle>Optional Questions</SidebarTitle>
                                    <SidebarPanel
                                        className={`internal ${closedOptional ? "closed" : ""}`}
                                    >
                                        {store.optionalSubjectivities.map((item) => (
                                            <SidebarItem
                                                key={item.id}
                                                completed={item.isAnswered}
                                                label={item.name}
                                                href={`#${item.key}`}
                                                itemIndex={`optional_${item.id}`}
                                                itemSelected={optionalSelected}
                                                onClick={() => {
                                                    setOptionalSelected(`optional_${item.id}`);
                                                    toggleSectionOptional(true);
                                                }}
                                            />
                                        ))}
                                    </SidebarPanel>
                                    <MobileContainer>
                                        <ControlContainer
                                            className={closedOptional ? "closed" : ""}
                                            onClick={() => toggleSectionOptional(!closedOptional)}
                                        >
                                            <img src={ArrowSectionsPercentage} alt="close-menu" />
                                        </ControlContainer>
                                    </MobileContainer>
                                </SidebarPanel>
                            )}
                            <SidebarPanel
                                style={{
                                    display: store.appIsSubmitting ? "grid" : "none",
                                    justifyItems: "center",
                                }}
                            >
                                <Loading message={"Submitting"} show={store.appIsSubmitting} />
                            </SidebarPanel>

                            {!store.appWasSubmitted && !store.supplementalApplicationSubmitted && (
                                <Button
                                    disabled={!store.requiredSubjectivitiesFinished}
                                    block
                                    onClick={store.submitSubjectivities}
                                >
                                    Submit Subjectivities
                                </Button>
                            )}
                        </>
                    )}
                </>
            )}
        </SidebarContainer>
    );
};

export default observer(Sidebar);
