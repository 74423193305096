import React, { useState, useEffect } from "react";
import DashboardPage from "./DashboardPage";
// import PaymentsTable from "./PaymentsTable";
import ClaimsTable from "./ClaimsTable";
import RetailBrokerTable from "./RetailBrokerTable";
import RiskAssessmentPage from "./RiskAssessment";
import GetQuote from "./GetQuote";

import BrokerPage from "features/BrokerPage";
import { quotes } from "services";
import * as apiTypes from "CounterpartApiTypes";
import { Route, Switch } from "react-router-dom";

const Dashboard: React.SFC = () => {
    const [dashboard, setDashboard] = useState<apiTypes.BrokerDashboard>({
        totalPremium: 0,
        averagePremium: 0,
        applicationsCount: 0,
        quoteCount: 0,
        policyCount: 0,
        declineCount: 0,
        expireCount: 0,
    });

    useEffect(() => {
        quotes.getPolicyDashboard().then((dashboard) => {
            setDashboard(dashboard);
        });
    }, []);

    return (
        <BrokerPage dashboardDataObject={dashboard}>
            <Switch>
                <Route path="/dashboard">
                    <DashboardPage dashboardDataObject={dashboard} />
                </Route>
                {/* <Route exact path="/alerts">
                    <AlertsPage dashboardDataObject={dashboard} />
                </Route> */}
                <Route exact path="/retail-agents" component={RetailBrokerTable} />
                <Route exact path="/claims" component={ClaimsTable} />
                {/* <Route exact path="/payments" component={PaymentsTable} /> */}
                <Route exact path="/risk-assessment" component={RiskAssessmentPage} />
                <Route exact path="/get-quote" component={GetQuote} />
            </Switch>
        </BrokerPage>
    );
};

export default Dashboard;
