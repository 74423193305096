import React from "react";
import AccountPage from "./AccountPage";
import { RouteComponentProps } from "react-router-dom";
import Loader from "features/BrokerProfile/components/Loader";
import store, { AccountStoreContext } from "./store";

type Props = RouteComponentProps<{ quoteID: string, publicSecurityToken: string, appSecurityToken: string }>;

function AccountPageLoader({ match }: Props) {
    return (
        <Loader func={store.loadQuote} args={match.params.publicSecurityToken && match.params.appSecurityToken ? [match.params.quoteID, match.params.appSecurityToken, match.params.publicSecurityToken] : [match.params.quoteID]}>
            {({ data: store }) => (
                <AccountStoreContext.Provider value={store as any}>
                    <AccountPage />
                </AccountStoreContext.Provider>
            )}
        </Loader>
    );
}

export default AccountPageLoader;
