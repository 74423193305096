import React, { useState } from "react";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";

import { Footer, HeaderForApplication, Tab, Tabs, DragAndDrop } from "components/common";
import { AnswersContext } from "services/application";
import { SubjectivitiesApp } from "CounterpartApiTypes";
import { ReactComponent as PaperIcon } from "assets/paper-blue-icon.svg";

import {
    Container,
    PageTitle,
    PageTitleContainer,
    QuoteRefContainer,
    QuoteRefLabel,
    QuoteRefValue,
    SideBySideContainer,
    StackedPaperPanel,
} from "./components/styled";
import ShareModal from "./components/ShareModal";
import SignModal from "./components/SignModal";

import { getContext, SubjectivitiesStoreType } from "./store";
import Sidebar from "./components/Sidebar";
import { DEFAULT_PAGE_TEXT, PageTextType, SUPPLEMENTAL_PAGE_TEXT } from "./constants";
import SubmitModal from "./components/SubmitModal";
import { Observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import {
    StyledButtonText,
    StyledHeaderTitle,
    StyledShareIcon,
    StyledWhiteButton,
} from "features/Application/components/ApplicationEntry";
import styled from "styled-components/macro";

import { SupplementalTab } from "./SupplementalTab";
import { SignatureTab } from "./SignatureTab";
import { Body1 } from "components/typography";

const UploadSignedDragAndDropFile: React.FC<{ store: SubjectivitiesStoreType }> = ({ store }) => {
    const [loading, setLoading] = React.useState(false);

    const uploadNewFile = (data: any) => {
        // Run new file upload on backend
        store.uploadSignedManually(data);
    };

    const deleteSignedApplication = () => {
        // Run new file upload on backend
        store.removeSignedApplication();
    };

    return (
        <DragAndDrop
            id="new"
            icon={<PaperIcon />}
            name={store.signedApplicationPdf ? "Signed Application" : "Upload File"}
            description={"Upload File"}
            uploading={loading}
            isCleared={false}
            canOverride={true}
            filename={store.signedApplicationPdf ? "Signed Application" : null}
            fileURL={store.signedApplicationPdf}
            fileUpload={async (data: any) => {
                setLoading(true);
                await uploadNewFile(data);
                setLoading(false);
            }}
            deleteFunction={async () => {
                setLoading(true);
                await deleteSignedApplication();
                setLoading(false);
            }}
        />
    );
};

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        display: none;
    }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

const SupplementalApplication: React.FC<{
    store: SubjectivitiesStoreType;
    pageText: PageTextType;
    data: SubjectivitiesApp;
    isSupplemental: boolean;
}> = ({ store, pageText, data, isSupplemental }) => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const isFromSigningPage = query.get("sign")?.toLowerCase() === "true";
    const [debounce, setDebounce] = useState<any>(null);

    const onValidate = (values: any) => {
        if (debounce) clearTimeout(debounce);
        const id = setTimeout(() => {
            store.saveAnswers(values);
        }, 1000);
        setDebounce(id);
    }

    // Only check on load
    if (store.signedApplicationPdf && !isFromSigningPage) {
        store.setCurrentPage(1);
    }

    if (store.appSignedAndSubjectivitiesSubmitted) {
        store.setPageTitle("Subjectivities Completed");
    }

    if (isFromSigningPage) {
        store.setHideSubjectivities(true);
        store.setPageTitle("E-Signature");
    }

    if (store.hideSubjectivities && isFromSigningPage && store.isSigned) {
        store.setPageTitle("Signature Complete");
    }

    return (
        <Container>
            <Helmet>
                <title>
                    Counterpart | {pageText.title}{" "}
                    {store.companyName ? `for ${store.companyName}` : ""}
                </title>
            </Helmet>
            <HeaderForApplication
                style={{ maxWidth: 1330, padding: 0 }}
                optionalButtons={
                    <>
                        <StyledContainer>
                            Brokered with{" "}
                            <b style={{ marginLeft: 5, marginRight: 10 }}>{store.brokerName}</b>
                        </StyledContainer>
                        <StyledWhiteButton onClick={store.setModalShareOpen}>
                            <StyledShareIcon />
                            <StyledButtonText>Share</StyledButtonText>
                        </StyledWhiteButton>
                    </>
                }
            >
                <StyledHeaderTitle>
                    {pageText.title} for <b>{store.companyName}</b>
                </StyledHeaderTitle>
            </HeaderForApplication>
            <Formik
                initialValues={data.initialAnswers}
                validateOnChange
                validateOnMount={false}
                onSubmit={noop}
                validate={onValidate}
            >
                {({ values }) => (
                    <AnswersContext.Provider
                        value={{
                            context: getContext(
                                data.initialContext,
                                values as any,
                                store.questionMap,
                            ),
                        }}
                    >
                        <SideBySideContainer>
                            <Observer>
                                {() => {
                                    return <Sidebar store={store} />;
                                }}
                            </Observer>
                            <StackedPaperPanel>
                                <PageTitleContainer>
                                    <Observer>
                                        {() => <PageTitle>{store.pageTitle}</PageTitle>}
                                    </Observer>
                                    <QuoteRefContainer>
                                        <QuoteRefLabel>Application Number</QuoteRefLabel>
                                        <QuoteRefValue>{store.quoteNumber}</QuoteRefValue>
                                    </QuoteRefContainer>
                                </PageTitleContainer>
                                <Observer>
                                    {() => {
                                        // This will be the text shown if everything is done
                                        return <>
                                            {isFromSigningPage ? (
                                                <>
                                                    {store.isSigned ? (
                                                        <>
                                                            <Body1
                                                                style={{ lineHeight: "150%" }}
                                                            >
                                                                Thank you for signing your
                                                                application.
                                                            </Body1>
                                                            <Body1>
                                                                Your broker will be in touch
                                                                shortly to complete the bind
                                                                process.
                                                            </Body1>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {store.showApplicationUpload && (
                                                                <>
                                                                    Please download, sign, and
                                                                    upload your Counterpart
                                                                    application to proceed.
                                                                    <UploadSignedDragAndDropFile
                                                                        store={store}
                                                                    />
                                                                </>
                                                            )}
                                                            {!store.showApplicationUpload && (
                                                                <>
                                                                    <Body1
                                                                        style={{
                                                                            lineHeight: "150%",
                                                                            marginBottom: 50,
                                                                        }}
                                                                    >
                                                                        Thank you for submitting
                                                                        your application to
                                                                        Counterpart. Please
                                                                        complete the questions
                                                                        below and proceed to
                                                                        sign the application for
                                                                        us to support you with
                                                                        our proprietary
                                                                        coverage.
                                                                    </Body1>
                                                                    <SignatureTab
                                                                        store={store}
                                                                    />
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <Tabs
                                                    currentActiveIndex={store.currentPage}
                                                    additionalHook={(index: number) => {
                                                        const newTitle = [
                                                            store.isSigned
                                                                ? "Application Signed"
                                                                : "Sign Application",
                                                            store.isSigned &&
                                                            (
                                                                store.appWasSubmitted ||
                                                                store.supplementalApplicationSubmitted
                                                            )
                                                                ? "Subjectivities Complete"
                                                                : "Complete Subjectivities",
                                                        ][index];
                                                        store.setPageTitle(newTitle);
                                                        store.setCurrentPage(index);
                                                    }}
                                                >
                                                    {store.hasSubjectivities && ( // place here var which will come from url params
                                                        <Tab
                                                            tabIndex={0}
                                                            label="Subjectivities"
                                                        >
                                                            <SupplementalTab
                                                                store={store}
                                                                pageText={pageText}
                                                            />
                                                        </Tab>
                                                    )}
                                                    <Tab
                                                        tabIndex={1}
                                                        label={
                                                            store.isSigned
                                                                ? "Signed App"
                                                                : "Sign App"
                                                        }
                                                    >
                                                        {store.isSigned && (
                                                            <Body1
                                                                style={{ lineHeight: "150%" }}
                                                            >
                                                                Thank you for signing your
                                                                application.{" "}
                                                                {store.hasSubjectivities && !store.appSignedAndSubjectivitiesSubmitted ? (
                                                                    <>
                                                                        Please complete any
                                                                        outstanding
                                                                        subjectivities to
                                                                        proceed with the bind
                                                                        process.
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Your broker will be in
                                                                        touch to complete the
                                                                        bind process.
                                                                    </>
                                                                )}
                                                                {store.showApplicationUpload && (
                                                                    <UploadSignedDragAndDropFile
                                                                        store={store}
                                                                    />
                                                                )}
                                                            </Body1>
                                                        )}
                                                        {!store.isSigned && (
                                                            <>
                                                                {store.showApplicationUpload && (
                                                                    <>
                                                                        Please download, sign,
                                                                        and upload your
                                                                        Counterpart application
                                                                        to proceed.
                                                                        <UploadSignedDragAndDropFile
                                                                            store={store}
                                                                        />
                                                                    </>
                                                                )}
                                                                {!store.showApplicationUpload && (
                                                                    <SignatureTab
                                                                        store={store}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </Tab>
                                                </Tabs>
                                            )}
                                        </>
                                    }}
                                </Observer>
                            </StackedPaperPanel>
                        </SideBySideContainer>
                    </AnswersContext.Provider>
                )}
            </Formik>
            <Footer />
            <SubmitModal store={store} />
            <ShareModal store={store} isSupplemental={isSupplemental} />
            <SignModal store={store} />
        </Container>
    );
};

const OuterApplicationEntry: React.FC<{
    data: SubjectivitiesApp;
    store: SubjectivitiesStoreType;
    isSupplemental: boolean;
}> = ({ data, store, isSupplemental = false }) => {
    const pageText = { ...DEFAULT_PAGE_TEXT, ...(isSupplemental ? SUPPLEMENTAL_PAGE_TEXT : {}) };

    return (
        <Observer>
            {() => {
                return (
                    <SupplementalApplication
                        data={data}
                        store={store}
                        pageText={pageText}
                        isSupplemental={isSupplemental}
                    />
                );
            }}
        </Observer>
    );
};

export default OuterApplicationEntry;
