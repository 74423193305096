import * as apiTypes from "CounterpartApiTypes";
import apiClient from "./apiClient";
import * as logger from "./logger";
import { getQuoteRenewalContext as _getQuoteRenewalContext } from "./generated/quotes";
export * from "./generated/quotes";
interface PatchQuoteData {
    sharedLimits?: boolean;
    priorActsCoverage?: boolean;
    sharedLimitAmount?: number;
}

export async function patchQuote(
    quoteID: string,
    data: PatchQuoteData,
    apiOptions = {},
): Promise<apiTypes.Quote> {
    try {
        const url = `/api/quotes/${quoteID}/`;
        const resp = await apiClient.patch(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "patchQuote"';
        logger.error(msg, { quoteID, data });
        return Promise.reject(error);
    }
}

export async function patchQuoteComponent(
    quoteComponentID: string,
    data: any,
    apiOptions = {},
): Promise<any> {
    try {
        const url = `/api/quotes/quoteComponent/${quoteComponentID}/`;
        const resp = await apiClient.patch(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "patchQuoteComponent"';
        logger.error(msg, { quoteComponentID, data });
        return Promise.reject(error);
    }
}

export async function getAppSecurityTokenAndQuotePublicSecurityToken(
    quoteId: string,
    apiOptions = {},
): Promise<apiTypes.SendRequestToBrokerToBindAccount> {
    try {
        const url = `/api/quotes/${quoteId}/public_security_token/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getAppSecurityTokenAndQuotePublicSecurityToken"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

export async function getQuoteRenewalContext(
    brokerId: string,
    quickPageToken: string,
    quoteId: string,
    apiOptions = {},
): Promise<apiTypes.QuoteRenewalContext> {
    return _getQuoteRenewalContext(brokerId, quickPageToken, quoteId, apiOptions);
}

export async function getQuoteAnswerRenewalContext(
    brokerId: string,
    quickPageToken: string,
    quoteId: string,
    apiOptions = {},
): Promise<any> {
    try {
        const url = `/api/quotes/${quoteId}/renewal_answer_context`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ brokerId, quickPageToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteAnswerRenewalContext"';
        logger.error(msg, { brokerId, quickPageToken, quoteId });
        return Promise.reject(error);
    }
}
