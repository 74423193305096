import React from "react";
import { FullQuestion } from "services/application";
import QuestionRender from "../QuestionRender";
import { CategoryContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import AddAnother from "./AddAnother";
import { useFormikContext } from "formik";
import RemoveAnother from "./RemoveAnother";

interface CategoryProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

function getIndexesForQuestion(question: FullQuestion) {
    return Array.from(
        new Set(
            (question.subQuestions || []).reduce<number[]>((p, q) => [...p, ...(q.indexes || [])], [
                0,
            ]),
        ),
    );
}

const Category: React.SFC<CategoryProps> = ({ question, extraProps = {} }) => {
    const { setFieldValue } = useFormikContext<any>();
    const [indexes, setIndexes] = React.useState(getIndexesForQuestion(question));

    const deleteField = (index: number) => {
        question.subQuestions?.forEach((q) => {
            setFieldValue(`${q.id}__${index}`, null);
        });
        setIndexes(indexes.filter((idx) => idx !== index));
    };
    const extraChildProps = extraProps || {};

    return (
        <CategoryContainer
            className={question.type}
            data-name="CategoryContainer"
            id={`q-${question.id}__0`}
            style={{ padding: question.hideTitle ? "0px" : "20px 0px" }}
        >
            <QuestionTitleRender question={question} extraProps={extraProps} />

            {indexes.map((index: number) => (
                <CategoryContainer
                    className={question.type}
                    data-name="CategoryContainer"
                    id={`q-${question.id}__0`}
                    key={`category_${question.id}_${index}`}
                    style={{ padding: question.hideTitle ? "0px" : "20px 0px" }}
                >
                    {question.subQuestions?.map((q: any) => (
                        <QuestionRender
                            question={q}
                            key={`question_${q.id}_${index}`}
                            extraProps={{
                                ...extraChildProps,
                                questionIndex: index,
                                parentType: "category",
                            }}
                        />
                    ))}
                    {question.allowMultipleAnswers && (
                        <RemoveAnother indexOf={index} onClick={() => deleteField(index)} />
                    )}
                </CategoryContainer>
            ))}

            {question.allowMultipleAnswers && (
                <AddAnother
                    onClick={() => setIndexes((idx: any) => [...idx, Math.max(...idx) + 1])}
                />
            )}
        </CategoryContainer>
    );
};

export default React.memo(Category);
