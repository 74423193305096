import { DefaultTheme } from "styled-components/macro";

const CounterpartTheme: DefaultTheme = {
    loading: true,
    logoUrl: undefined,
    colors: {
        primary: "#29525E",
        primaryFrontColor: "#ffffff",
        secondary: "#5389B5",
        gray: "#c2c2c2",
        third: "#ebf0f6",
        textColor: "#22232e",
        backgroundLightBlue: "#e7f7fb",
        systemSuccess: "#56dda1",
        systemWarning: "#f1d43b",
        systemError: "#ed0000",
        // New Colors
        darkGray: "#707070",
        accentColor: "#5389B5",
        accentColorBG: "#edf2f9",
        black: "#22232E",
        dangerColor: "#FFE1D6",
        shipGrey: "#687D89",
    },
    defaultBorderRadius: "4px",
};

export default CounterpartTheme;
