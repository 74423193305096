import React from "react";
import styled from "styled-components/macro";

import { useParams } from "react-router-dom";
import {
    assert,
    copyToClipboard,
    formatCurrency,
    getBaseURL,
    parseQueryString,
    useApiGet,
} from "../../utils";
import { Button, CancelButton, Loading, Page, SectionContainer } from "../../components/common";
import { Helmet } from "react-helmet";
import { H2, H3, Body3 } from "components/typography";
import {
    Header,
    StackedPaperPaddedContainer,
    StackedPaper,
    BlueDivider,
    Footer,
} from "components/common";
import { getQuoteQuickDetails } from "../../services/generated/quotes";
import { QuoteComponentModelType } from "../AccountPage/store/QuoteComponentModel";
import { QuoteModel, QuoteStore } from "../AccountPage/store";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import { auth } from "services";

const PageHeading = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DataSection = styled.div`
    display: flex;
    margin-bottom: 40px;
    flex-direction: column;

    & ${H3} {
        font-weight: bold;
    }

    & ${Body3} {
        margin: 0;
    }
`;

const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #ebf0f6;

    ${H3} {
        font-size: 24px;
    }

    b {
        font-weight: bold;
    }
`;

/**
 * Return plain text summary of a quote
 *
 * @param quote
 */
function getSummary(quote: QuoteStore): string {
    let summary = `Counterpart Coverage Highlights\r\n${quote.statusText} ${quote.company.name}\r\n`;

    for (const component of quote.enabledComponents) {
        summary += `\r\n${component.insuranceLine.name}\r\n`;
        summary += `Premium: ${formatCurrency(component.premium)}\r\n`;
        summary += `Limit: ${formatCurrency(component.limits)}\r\n`;
        summary += `Retention: ${formatCurrency(component.retention)}\r\n`;

        summary += component.coverage.sublimits
            .map((sublimit: any) => `${sublimit.name}: ${formatCurrency(sublimit.value)}`)
            .join("\r\n");

        summary += "\r\n\r\n";
    }

    summary += `\r\nTotal Premium: ${formatCurrency(quote.finalPremium)}`;

    return summary;
}

const QuoteQuickPage = (): any => {
    const { quoteId } = useParams<{ quoteId: string }>();
    let { brokerId, token } = parseQueryString();

    brokerId = brokerId ?? "";
    token = token ?? "";
    const [accessToken, setAccessToken] = React.useState("");

    React.useEffect(() => {
        async function getAccessToken() {
            if (auth.getUserID()) {
                const _accessToken = await auth.getAccessTokenAsync();
                return _accessToken || "";
            }
            return "";
        }
        getAccessToken().then((value) => {
            setAccessToken(value);
        });
    }, []);

    const [loading, data, error] = useApiGet(getQuoteQuickDetails, brokerId, token, quoteId);

    if (loading) {
        return (
            <SectionContainer>
                <StackedPaper style={{ marginTop: 100 }}>
                    <div style={{ gridArea: "col3", textAlign: "center" }}>
                        <Loading />
                    </div>
                </StackedPaper>
            </SectionContainer>
        );
    }

    if (error) return <Redirect to="/" />;

    assert(data);

    const quote: QuoteStore = QuoteModel.create(data);

    const pdfUrl = () => {
        const tokenFields = new URLSearchParams();
        tokenFields.append("access_token", accessToken);
        tokenFields.append("brokerId", brokerId);
        tokenFields.append("quickPageToken", token);

        return `${getBaseURL()}/api/quotes/${quoteId}/download/summary?${tokenFields.toString()}`;
    };

    return (
        <Page>
            <Helmet>
                <title>Counterpart | Quote Summary</title>
            </Helmet>
            <Header>
                <Body3
                    style={{
                        marginTop: "20px",
                        marginLeft: 20,
                        paddingLeft: 20,
                        borderLeftColor: "#bfcad6",
                        borderLeftWidth: 1,
                        borderLeftStyle: "solid",
                        maxWidth: "400px",
                    }}
                >
                    <strong
                        style={{ fontWeight: "bold" }}
                    >{`${quote.statusText} ${quote.company.name}`}</strong>
                </Body3>
            </Header>
            <StackedPaperPaddedContainer style={{ maxWidth: 920 }}>
                <StackedPaper style={{ padding: 45 }}>
                    <PageHeading>
                        <H2>Coverage Highlights</H2>
                        <div>
                            <CancelButton
                                style={{
                                    minWidth: "180px",
                                    minHeight: "40px",
                                    padding: "10px 20px",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                }}
                                onClick={() => {
                                    copyToClipboard(getSummary(quote));
                                    toast.success("Content Copied");
                                }}
                            >
                                Copy Content
                            </CancelButton>
                            <Button
                                style={{
                                    minWidth: "180px",
                                    minHeight: "40px",
                                    padding: "10px 20px",
                                }}
                                onClick={() => window.open(pdfUrl(), "_blank")}
                            >
                                Download PDF
                            </Button>
                        </div>
                    </PageHeading>
                    <BlueDivider style={{ margin: "32px 0 40px 0" }} />
                    {quote.components.map(
                        (quoteComponent: QuoteComponentModelType) =>
                            !quoteComponent.coverageDenied && (
                                <DataSection key={`component${quoteComponent.id}`}>
                                    <H3>{quoteComponent.insuranceLine.name}</H3>
                                    <InfoRow>
                                        <Body3>Premium</Body3>
                                        <Body3>
                                            <b>{formatCurrency(quoteComponent.premium)}</b>
                                        </Body3>
                                    </InfoRow>
                                    <InfoRow>
                                        <Body3>Limit</Body3>
                                        <Body3>{formatCurrency(quoteComponent.limits)}</Body3>
                                    </InfoRow>
                                    <InfoRow>
                                        <Body3>Retention</Body3>
                                        <Body3>{formatCurrency(quoteComponent.retention)}</Body3>
                                    </InfoRow>
                                    {quoteComponent.coverage?.sublimits.map((sublimit) => (
                                        <InfoRow>
                                            <Body3>{sublimit.name}</Body3>
                                            <Body3>{formatCurrency(sublimit.value)}</Body3>
                                        </InfoRow>
                                    ))}
                                </DataSection>
                            ),
                    )}
                    <DataSection>
                        <InfoRow>
                            <H3>Total Premium:</H3>
                            <Body3 style={{ fontSize: 24 }}>
                                <b>{formatCurrency(quote.finalPremium)}</b>
                            </Body3>
                        </InfoRow>
                    </DataSection>
                </StackedPaper>
            </StackedPaperPaddedContainer>
            <Footer />
        </Page>
    );
};

export default QuoteQuickPage;
