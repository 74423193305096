//@ts-nocheck
import React, { useState } from "react";
import styled from "styled-components/macro";

import { Link } from "react-router-dom";

import { Container } from "./styled";
import { MuseoSansBaseCSS } from "components/typography";

import Logo from "assets/counterpart_logo.svg";
import icon from "assets/counterpart_icon.svg";
import shareApplicationIcon from "assets/share-application.svg";
import brokerDashboardIcon from "assets/dashboard-icon.svg";
import { ReactComponent as HomeIcon } from "assets/home-gray-icon.svg";

const HtmlToReactParser = require("html-to-react").Parser;

interface HeaderProps {
    children?: React.ReactChild;
    detached?: boolean;
    position?: string;
    showApplicationMenu?: boolean;
    style?: any;
    headerNavStyles?: any;
    onClickShare?: any;
    shareLink?: string;
    visibleButtons?: boolean;
    optionalButtons?: React.ReactChild;
    mobileHide?: boolean;
    withBackground?: boolean;
    withBorderBottom?: boolean;
}

interface UserStore {
    email: string;
    firstName: string;
    lastName: string;
}

type BrokerProps = React.HTMLAttributes & UserStore;

const ChildrenContainer = styled.div`
    flex: auto;
`;

const StyledHomeIcon = styled(HomeIcon)`
    display: none;
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: 767px) {
        display: block;
    }
`;

const NavBarBrand = styled(Link)`
    display: inline-block;
    padding: 20px 0;
    margin-right: 40px;
    background: none;
    border: none;
    & > img {
        &:not(.as-icon) {
            width: 200px;
            height: 38px;
        }
        &.as-icon {
            margin-right: 10px;
        }
    }

    @media (max-width: 480px) {
        &.hide-mobile {
            display: none;
        }
    }
`;

const DotDiv = styled.div`
    font-size: 25px;
    cursor: pointer;
    user-select: none;
`;

const DotMenu = styled.div`
    display: flex;
`;

const NavbarContainer = styled(Container)<{ detached?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 767px) {
        margin: 0;
        margin-left: 0;
        margin-right: 0;
    }
`;

const MenuSlider = styled.div`
    display: flex;
    width: 0px;
    height: 0px;
    overflow: hidden;
    left: 100%;
    position: relative;
    max-height: 35px;
    transition: height 0s, all 0.2s linear;
    &.opened {
        height: auto;
        width: 100%;
        left: 0;
        position: relative;
        @media (max-width: 700px) {
            align-items: right;
            text-align: right;
            display: contents;
        }
    }
`;

const MenuItem = styled(Link)`
    padding-right: 30px;
    margin-top: 7px;
    @media (max-width: 700px) {
        display: block;
        padding-top: 5px;
    }
`;

export const VerticalDivider = styled.div`
    width: 1px;
    background: #bfcad6;
    opacity: 0.4;

    &.mobile-styled {
        margin-left: 30px;
        margin-right: 30px;
    }

    @media (max-width: 480px) {
        &.mobile-styled {
            display: none;
        }
    }
`;

export const ImgProfileViewer = styled.img`
    width: 52px;
    height: 52px;
    border-radius: 26px;
`;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    padding-left: 20px;

    @media (max-width: 1024px) {
        padding-left: 0;
    }
`;

const ProfileName = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    text-transform: capitalize;
`;

const ProfileCompany = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    opacity: 0.4;
`;

const StyledButtonText = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #22232e;
    opacity: 0.6;

    @media (max-width: 767px) {
        display: none;
    }
`;

const StyledButton = styled(Link as any)`
    background: #ffffff;
    padding: 12px 15px 12px 15px;
    box-shadow: 0px 12px 18px rgba(228, 235, 244, 0.75);
    border-radius: 10px;
    padding-bottom: 15px;

    &:hover {
        text-decoration: none;
    }

    @media (max-width: 767px) {
        background: none;
        padding: 0;
    }
`;

const ButtonContainer = styled.div<{ isVisible?: boolean }>`
    display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
`;

const MenuContainer = styled.div`
    width: 100%;
    z-index: 100000;
    @media (max-width: 700px) {
        width: 94%;
        position: absolute;
        display: none;
        &.opened {
            background: #ffffff;
            margin-top: 70px;
            display: grid;
        }
        right: 3%;
        left: 3%;
        padding: 16px;
        align-items: center;
        z-index: 100000;
    }
`;

const MenuBarDiv = styled.nav<{
    detached?: boolean;
    position?: boolean;
    withBackground?: boolean;
    withBorderBottom?: boolean;
    headerNavStyles?: any;
}>`
    top: ${(props) => (props.detached ? "20px" : "0")};
    margin-top: ${(props) => (props.detached ? "20px" : "0")};
    justify-content: center;
    z-index: ${({ position }) => (position === "absolute" ? 9998 : "unset")};
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 80px;
    background-color: ${({ withBackground }) => (withBackground ? "#ffffff" : "none")};
    border-bottom: ${({ withBorderBottom }) => (withBorderBottom ? "1px solid #F2F4F7" : "none")};
    margin-bottom: 20px;

    @media (max-width: 768px) {
        /* position: ${({ position }) => (position ? position : "absolute")}; */
        top: 0;
        z-index: 100000;
        padding: 0;
    }

    ${({ headerNavStyles }) => headerNavStyles && headerNavStyles};
`;

export const BrandImg = styled.div`
    background: url(${Logo}) no-repeat;
    background-size: contain;
    background-position: center;
    width: 200px;
    height: 40px;
`;

const NavBarBrandImg = styled.img`
    height: 30px;
    width: auto;

`;

export const ProfileNameViewer: React.FC<BrokerProps> = ({ userInfo }) => (
    <ProfileContainer>
        <ProfileName>{`${userInfo.firstName} ${userInfo.lastName}`}</ProfileName>
        <ProfileCompany>{userInfo.email}</ProfileCompany>
    </ProfileContainer>
);

export const HeaderSingle: React.FC<HeaderProps> = ({ children, detached, position, style }) => (
    <MenuBarDiv detached={detached} position={position}>
        <NavbarContainer detached={detached} style={style}>
            <ChildrenContainer>{children}</ChildrenContainer>
        </NavbarContainer>
    </MenuBarDiv>
);

export const HeaderForApplication: React.SFC<HeaderProps> = ({
    children,
    showApplicationMenu = true,
    style,
    onClickShare,
    shareLink,
    optionalButtons,
    mobileHide = true,
    withBackground = false,
    headerNavStyles,
}) => {
    const [showMenuItems, setShowMenuItems] = useState(false);
    const updateMenuDisplay = () => {
        setShowMenuItems(!showMenuItems);
    };
    const htmlToReactParser = new HtmlToReactParser();
    const menuParse = htmlToReactParser.parse("&#8942;");
    const closeParse = htmlToReactParser.parse("&#10007;");
    const iconForMenu = showMenuItems ? closeParse : menuParse;
    return (
        <MenuBarDiv detached={false} withBackground={withBackground} headerNavStyles={headerNavStyles}>
            <NavbarContainer detached={false} style={style}>
                <NavBarBrand className={mobileHide ? "hide-mobile" : ""} to="/">
                    <NavBarBrandImg
                        className="as-icon"
                        src={icon}
                        alt="Counterpart"
                    />
                </NavBarBrand>
                <ChildrenContainer>{children}</ChildrenContainer>
                <DotMenu style={{ display: showApplicationMenu ? "none" : "none" }}>
                    <MenuContainer className={`${showMenuItems ? "opened" : ""}`}>
                        <MenuSlider className={`${showMenuItems ? "opened" : ""}`}>
                            <MenuItem to="/dashboard" target="_blank">
                                <img
                                    src={brokerDashboardIcon}
                                    style={{ marginRight: "10px" }}
                                    alt="go to dashboard"
                                />
                                Go To Dashboard
                            </MenuItem>
                            <MenuItem to={shareLink || "#"} onClick={onClickShare}>
                                <img
                                    src={shareApplicationIcon}
                                    style={{ marginRight: "10px" }}
                                    alt="share"
                                />
                                Share
                            </MenuItem>
                        </MenuSlider>
                    </MenuContainer>
                    <DotDiv className="text-dark" onClick={updateMenuDisplay} unselectable="on">
                        {iconForMenu}
                    </DotDiv>
                </DotMenu>
                {optionalButtons && (
                    <ButtonContainer isVisible={true}>{optionalButtons}</ButtonContainer>
                )}
            </NavbarContainer>
        </MenuBarDiv>
    );
};

const Header: React.SFC<HeaderProps> = ({
    children,
    showApplicationMenu = true,
    style,
    onClickShare,
    shareLink,
    visibleButtons = false,
    optionalButtons,
    withBackground = false,
    withBorderBottom = false,
    headerNavStyles,
}) => {
    const [showMenuItems, setShowMenuItems] = useState(false);
    const updateMenuDisplay = () => {
        setShowMenuItems(!showMenuItems);
    };
    const htmlToReactParser = new HtmlToReactParser();
    const menuParse = htmlToReactParser.parse("&#8942;");
    const closeParse = htmlToReactParser.parse("&#10007;");
    const iconForMenu = showMenuItems ? closeParse : menuParse;

    return (
        <MenuBarDiv
            detached={false}
            withBackground={withBackground}
            withBorderBottom={withBorderBottom}
            headerNavStyles={headerNavStyles}
        >
            <NavbarContainer detached={false} style={style}>
                <NavBarBrand to="/">
                    <BrandImg alt="Counterpart" />
                </NavBarBrand>
                <ChildrenContainer>{children}</ChildrenContainer>
                <DotMenu style={{ display: showApplicationMenu ? "none" : "none" }}>
                    <MenuContainer className={`${showMenuItems ? "opened" : ""}`}>
                        <MenuSlider className={`${showMenuItems ? "opened" : ""}`}>
                            <MenuItem to="/dashboard">
                                <img
                                    src={brokerDashboardIcon}
                                    style={{ marginRight: "10px" }}
                                    alt="go to dashboard"
                                />
                                Go To Dashboard
                            </MenuItem>
                            <MenuItem to={shareLink || "#"} onClick={onClickShare}>
                                <img
                                    src={shareApplicationIcon}
                                    style={{ marginRight: "10px" }}
                                    alt="share"
                                />
                                Share
                            </MenuItem>
                        </MenuSlider>
                    </MenuContainer>
                    <DotDiv className="text-dark" onClick={updateMenuDisplay} unselectable="on">
                        {iconForMenu}
                    </DotDiv>
                </DotMenu>
                {optionalButtons && (
                    <ButtonContainer isVisible={true}>{optionalButtons}</ButtonContainer>
                )}
                <ButtonContainer isVisible={visibleButtons}>
                    <StyledButton to="/">
                        <StyledHomeIcon />
                        <StyledButtonText>go to dashboard</StyledButtonText>
                    </StyledButton>
                </ButtonContainer>
            </NavbarContainer>
        </MenuBarDiv>
    );
};

export default Header;
