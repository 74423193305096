// frontend/src/services/generated/application.ts
// Auto Generated File, Do not edit.
import Cookies from "js-cookie";

import apiClient from "./../apiClient";
import * as apiTypes from "CounterpartApiTypes";
import * as logger from "./../logger";

type RemoveUndefined<T> = T extends undefined ? never : T;

type RemoveUndefinedKeys<T> = T extends {}
    ? {
          [P in keyof T]-?: RemoveUndefined<T[P]>;
      }
    : T;

type ApiReturnType<T> = T extends Array<any> ? RemoveUndefinedKeys<T[0]>[] : RemoveUndefinedKeys<T>;

/**
 * createApplication
 * ---
 *
 *
 * @api POST /api/applications/
 *
 * @param {apiTypes.Application} data
 */
export async function createApplication(
    data: apiTypes.Application,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Application>> {
    try {
        const url = `/api/applications/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "createApplication"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * deleteAnswers
 * ---
 * Deletes the list of answers, always returns 204
 *
 *
 * @api DELETE /api/applications/{id}/answers/
 *
 * @param {apiTypes.Answer[]} data
 * @param {string} id A UUID string identifying this Application.
 */
export async function deleteAnswers(
    data: apiTypes.Answer[],
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/applications/${id}/answers/`;
        const resp = await apiClient.delete(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "deleteAnswers"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}

/**
 * downloadApplicationPDF
 * ---
 * Returns the application as a downloadable PDF files
 *
 *
 * @api GET /api/applications/{id}/application_pdf/
 *
 * @param {string} appSecurityToken
 * @param {string} id A UUID string identifying this Application.
 */
export async function downloadApplicationPDF(
    appSecurityToken: string,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/applications/${id}/application_pdf/`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ appSecurityToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "downloadApplicationPDF"';
        logger.error(msg, { appSecurityToken, id });
        return Promise.reject(error);
    }
}

/**
 * getAdditionalStartInfo
 * ---
 *
 *
 * @api GET /api/additional_start_info
 *
 */
export async function getAdditionalStartInfo(apiOptions = {}): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/additional_start_info`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getAdditionalStartInfo"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * getAnswers
 * ---
 * Returns a list of answers for a application
 *
 *
 * @api GET /api/applications/{id}/answers/
 *
 * @param {string} appSecurityToken
 * @param {string} id A UUID string identifying this Application.
 */
export async function getAnswers(
    appSecurityToken: string,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Answer[]>> {
    try {
        const url = `/api/applications/${id}/answers/`;
        const params = { appSecurityToken, ...(apiOptions as any).params };

        let ignoreStatusFilter = Cookies.get('ignoreStatusFilter');
        if (ignoreStatusFilter) {
            params.ignoreStatusFilter = true;
        }

        apiOptions = {
            ...apiOptions,
            params,
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getAnswers"';
        logger.error(msg, { appSecurityToken, id });
        return Promise.reject(error);
    }
}

/**
 * getAnswersForSection
 * ---
 *
 *
 * @api GET /api/applications/{id}/answers/{section_pk}/
 *
 * @param {string} id A UUID string identifying this Application.
 * @param {string} sectionPk
 */
export async function getAnswersForSection(
    id: string,
    sectionPk: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Answer[]>> {
    try {
        const url = `/api/applications/${id}/answers/${sectionPk}/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getAnswersForSection"';
        logger.error(msg, { id, sectionPk });
        return Promise.reject(error);
    }
}

/**
 * getApplication
 * ---
 *
 *
 * @api GET /api/applications/{id}/
 *
 * @param {string} appSecurityToken
 * @param {string} id A UUID string identifying this Application.
 */
export async function getApplication(
    appSecurityToken: string,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Application>> {
    try {
        const url = `/api/applications/${id}/`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ appSecurityToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getApplication"';
        logger.error(msg, { appSecurityToken, id });
        return Promise.reject(error);
    }
}

/**
 * getApplicationSections
 * ---
 * This endpoint returns all sections with the related questions for this
application.
 *
 *
 * @api GET /api/applications/{id}/sections/
 *
 * @param {string} appSecurityToken
 * @param {string} id A UUID string identifying this Application.
 */
export async function getApplicationSections(
    appSecurityToken: string,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.ApplicationSection[]>> {
    try {
        const url = `/api/applications/${id}/sections/`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ appSecurityToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getApplicationSections"';
        logger.error(msg, { appSecurityToken, id });
        return Promise.reject(error);
    }
}

/**
 * getBrokerageNameFromId
 * ---
 *
 *
 * @api GET /api/get_brokerage_name
 *
 * @param {string} b
 * @param {string} r
 * @param {string} bEmail
 * @param {string} bName
 * @param {string} rEmail
 * @param {string} rName
 */
export async function getBrokerageNameFromId(
    b: string,
    r: string,
    bEmail: string,
    bName: string,
    rEmail: string,
    rName: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.BrokerageName>> {
    try {
        const url = `/api/get_brokerage_name`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ b, r, bEmail, bName, rEmail, rName }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getBrokerageNameFromId"';
        logger.error(msg, { b, r, bEmail, bName, rEmail, rName });
        return Promise.reject(error);
    }
}

/**
 * getIndustryQuestionData
 * ---
 *
 *
 * @api GET /api/start_industry_question
 *
 */
export async function getIndustryQuestionData(
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.IndustryQuestion>> {
    try {
        const url = `/api/start_industry_question`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getIndustryQuestionData"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * getInitialApplicationData
 * ---
 * This endpoint returns the company and broker names with all sections with the related questions for this
application.
 *
 *
 * @api GET /api/applications/{id}/initialApplicationStructure/
 *
 * @param {string} appSecurityToken
 * @param {string} id A UUID string identifying this Application.
 */
export async function getInitialApplicationData(
    appSecurityToken: string,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.ApplicationInitialize>> {
    try {
        const url = `/api/applications/${id}/initialApplicationStructure/`;
        const params = { appSecurityToken, ...(apiOptions as any).params };

        let ignoreStatusFilter = Cookies.get('ignoreStatusFilter');
        if (ignoreStatusFilter) {
            params.ignoreStatusFilter = true;
        }

        apiOptions = {
            ...apiOptions,
            params,
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getInitialApplicationData"';
        logger.error(msg, { appSecurityToken, id });
        return Promise.reject(error);
    }
}

/**
 * getInsuranceLine
 * ---
 *
 *
 * @api GET /api/insurance_lines/{id}/
 *
 * @param {string} id A UUID string identifying this Insurance Line.
 */
export async function getInsuranceLine(
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.InsuranceLine>> {
    try {
        const url = `/api/insurance_lines/${id}/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getInsuranceLine"';
        logger.error(msg, { id });
        return Promise.reject(error);
    }
}

/**
 * getInsuranceLinesList
 * ---
 *
 *
 * @api GET /api/insurance_lines/
 *
 */
export async function getInsuranceLinesList(
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.InsuranceLine[]>> {
    try {
        const url = `/api/insurance_lines/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getInsuranceLinesList"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * getUnderwritingGuidelines
 * ---
 * Returns a list of answers for a application
 *
 *
 * @api GET /api/applications/{id}/underwriting_guidelines/
 *
 * @param {string} appSecurityToken
 * @param {string} id A UUID string identifying this Application.
 */
export async function getUnderwritingGuidelines(
    appSecurityToken: string,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.UnderwritingGuidelines[]>> {
    try {
        const url = `/api/applications/${id}/underwriting_guidelines/`;
        const params = { appSecurityToken, ...(apiOptions as any).params };

        let ignoreStatusFilter = Cookies.get('ignoreStatusFilter');
        if (ignoreStatusFilter) {
            params.ignoreStatusFilter = true;
        }

        apiOptions = {
            ...apiOptions,
            params,
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getUnderwritingGuidelines"';
        logger.error(msg, { appSecurityToken, id });
        return Promise.reject(error);
    }
}

/**
 * saveAnswer
 * ---
 * Endpoint used to answer application questions
 *
 *
 * @api POST /api/applications/{id}/answers/
 *
 * @param {apiTypes.Answer[]} data
 * @param {string} id A UUID string identifying this Application.
 */
export async function saveAnswer(
    data: apiTypes.Answer[],
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/applications/${id}/answers/`;
        const params = { ...(apiOptions as any).params };

        let ignoreStatusFilter = Cookies.get('ignoreStatusFilter');
        let userId = Cookies.get('userId');

        if (ignoreStatusFilter && userId) {
            params.ignoreStatusFilter = true;
            params.userId = userId;
        }

        apiOptions = {
            ...apiOptions,
            params,
        };

        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "saveAnswer"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}

/**
 * saveAnswers
 * ---
 *
 *
 * @api POST /api/applications/{id}/save_answers/
 *
 * @param {apiTypes.Answer[]} data
 * @param {string} id A UUID string identifying this Application.
 */
export async function saveAnswers(
    data: apiTypes.Answer[],
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/applications/${id}/save_answers/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "saveAnswers"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}

/**
 * sendRetailBrokerInvite
 * ---
 *
 *
 * @api POST /api/brokers/send_share_application_email
 *
 * @param {apiTypes.SendRetailBrokerInvite} data
 */
export async function sendRetailBrokerInvite(
    data: apiTypes.SendRetailBrokerInvite,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Broker>> {
    try {
        const url = `/api/brokers/send_share_application_email`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "sendRetailBrokerInvite"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * shareApplication
 * ---
 * Returns the data needed to show the industry question on start application page
 *
 *
 * @api POST /api/applications/{id}/share_application/
 *
 * @param {apiTypes.ShareApplication} data
 * @param {string} id A UUID string identifying this Application.
 */
export async function shareApplication(
    data: apiTypes.ShareApplication,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/applications/${id}/share_application/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "shareApplication"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}

/**
 * submitApplication
 * ---
 *
 *
 * @api POST /api/applications/{id}/submit/
 *
 * @param {apiTypes.SubmitApplication} data
 * @param {string} id A UUID string identifying this Application.
 */
export async function submitApplication(
    data: apiTypes.SubmitApplication,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Application>> {
    try {
        const url = `/api/applications/${id}/submit/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "submitApplication"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}

/**
 * toogleInsuranceLine
 * ---
 *
 *
 * @api POST /api/applications/{id}/toggle_insurance_line/
 *
 * @param {apiTypes.ToggleInsuranceLine} data
 * @param {string} id A UUID string identifying this Application.
 */
export async function toogleInsuranceLine(
    data: apiTypes.ToggleInsuranceLine,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/applications/${id}/toggle_insurance_line/`;
        const params = { ...(apiOptions as any).params };

        let ignoreStatusFilter = Cookies.get('ignoreStatusFilter');
        if (ignoreStatusFilter) {
            params.ignoreStatusFilter = true;
        }
        apiOptions = {
            ...apiOptions,
            params,
        };

        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "toogleInsuranceLine"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}
