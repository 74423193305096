import * as React from "react";
import styled from "styled-components/macro";

import responsive from "services/responsive";

import BaseInput, { BaseTextArea } from "./BaseInput";
import { CaptionText } from "../typography";
import { FormErrorMessage } from "./styled";

import { useField, ErrorMessage } from "formik";
import { TooltipContainer, Tooltip } from "../../counterpart-theme";

import { UncontrolledTooltip } from "reactstrap";
import { observer } from "mobx-react-lite";

type Props = {
    label?: string;
    errorMessage?: string;
    labelHelper?: string;
    visibleHelper?: boolean;
    helperPosition?: string;
    tooltipText?: string;
    columnSize?: number;
} & React.ComponentProps<typeof BaseInput>;

const InputContainer = styled.div<{
    visibleHelper?: boolean;
    helperLength?: number;
    helperPosition?: string;
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    & label.input-helper {
        position: absolute;
        margin-top: 18px;
        ${({ helperPosition }) => (helperPosition === "suffix" ? `right: 10px;` : `left: 15px;`)}
    }
    & input.with-helper {
        ${({ helperPosition, visibleHelper, helperLength }) =>
            helperPosition === "suffix"
                ? `
            padding-right: ${
                visibleHelper && (helperLength || 0) > 0
                    ? `${(helperLength || 0) * 0.47}rem`
                    : "10px"
            };
        `
                : `
            padding-left: ${
                visibleHelper && (helperLength || 0) > 0
                    ? `${(helperLength || 0) * 1.875}rem`
                    : "15px"
            };
            padding-right: -${
                visibleHelper && (helperLength || 0) > 0
                    ? `${(helperLength || 0) * 0.47}rem`
                    : "10px"
            };
        `}
    }

    & > p {
        position: absolute;
        top: 80px;
    }

    ${responsive.InternetExplorer} & {
        & input.with-helper {
            position: absolute;
            margin-top: -1px;
            ${({ helperPosition }) => (helperPosition === "suffix" ? `right: 0;` : `left: 0;`)}
        }
    }
`;

const RedAsterisk = styled.b`
    margin-left: 2px;
    margin-top: -5px;
    align-items: center;
    font-size: 16px;
    line-height: 140%;
    color: #d50f1f;
`;

const InputType = ({ ...props }: Props) => {
    if (props.type === "textarea") return <BaseTextArea {...props} />;
    return <BaseInput {...props} />;
};

const InputField = ({
    ref,
    label,
    error,
    errorMessage,
    className,
    labelHelper,
    columnSize = 1,
    visibleHelper,
    helperPosition = "prefix",
    tooltipText,
    ...rest
}: Props) => {
    const hasTooltip = tooltipText !== undefined;
    const randomId: number = Math.floor(Math.random() * Math.floor(999999));
    return (
        <InputContainer
            className={className}
            helperLength={labelHelper?.length}
            visibleHelper={visibleHelper}
            helperPosition={helperPosition}
            style={{ flex: columnSize || 1 }}
        >
            {label && label?.length > 0 && (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CaptionText className="input-label" as="label">
                        {label}
                    </CaptionText>
                    {rest.required && <RedAsterisk>*</RedAsterisk>}
                    <TooltipContainer style={{ marginLeft: 8, padding: 0 }}>
                        <Tooltip
                            id={`uncontrolled${randomId}`}
                            className={hasTooltip ? "" : "hidden"}
                            tabIndex={-1}
                        />
                        <UncontrolledTooltip placement="top" target={`uncontrolled${randomId}`}>
                            {tooltipText}
                            {rest.required && (
                                <>
                                    <br />
                                    <RedAsterisk>*</RedAsterisk> - this field is required to submit
                                    an application.
                                </>
                            )}
                        </UncontrolledTooltip>
                    </TooltipContainer>
                </div>
            )}

            <InputType
                {...rest}
                className={`with-helper custom-form-control temp-text-input ${
                    error ? "on-error" : ""
                } ${className}`}
                error={error}
                ref={ref}
                autoComplete="off"
            />

            {<label className="input-helper">{visibleHelper ? labelHelper : ""}</label>}
            {error && errorMessage && (
                <ErrorMessage name={rest.name as string} component={FormErrorMessage} />
            )}
        </InputContainer>
    );
};

type FormikProps = Props & { name: string; tooltipText?: string };

export const FormikInputField = observer((props: FormikProps) => {
    const [field, meta] = useField(props.name);
    return (
        <InputField
            error={!!meta.touched && !!meta.error}
            errorMessage={meta.error}
            tooltipText={props.tooltipText}
            autoComplete="off"
            {...field}
            {...props}
            // value={field.value}
        />
    );
});
FormikInputField.displayName = "FormikInputField";

export default InputField;
