import { getBrokerProfile as _getBrokerProfile } from "./generated/users";
import rootStore from "./store";
export * from "./generated/users";

export async function getBrokerProfile(apiOptions?: {}) {
    const result = await _getBrokerProfile(apiOptions);
    rootStore.userInfo?.updateInfo(result);
    return result;
}

export async function getCustomTheme() {
    return {
        loading: false,
        logoUrl:
            "https://www.google.com/logos/doodles/2020/december-holidays-days-2-30-6753651837108830.3-law.gif",
        colors: {
            textColor: "black",
            primary: "darkcyan",
            primaryFrontColor: "white",
            secondary: "cyan",
            third: "grey",
            backgroundLightBlue: "",
            systemSuccess: "green",
            systemWarning: "yellow",
            systemError: "red",
        },
        defaultBorderRadius: "4px",
    };
}
