import { QuoteSubjectivity } from "features/AccountPage/store";
import {
    QuestionRowContainer,
    CategoryContainer,
} from "features/Application/components/QuestionComponents/styled";
import QuestionRender from "features/Application/components/QuestionRender";
import { getParent, Instance } from "mobx-state-tree";
import React from "react";
import { FullQuestion } from "services/application";
import styled from "styled-components/macro";
import { ClearedLabel, SubjectivityTitle } from "./styled";
import { classNames as cx } from "utils";
import { SubjectivitiesStoreType } from "../store";
import { QuestionTitle } from "components/typography";
import { Container } from "features/Application/components/QuestionComponents/AddAnother";

const QuestionSubjectivityContainer = styled.div`
    margin-top: 30px;
    position: relative;

    &.cleared {
        pointer-events: none;
        ${SubjectivityTitle} {
            opacity: 0.4;
        }
        ::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    & ${QuestionRowContainer} {
        padding: 0;
    }

    & ${CategoryContainer} ${QuestionRowContainer}:not(:first-child) {
        margin-top: 16px;
    }

    & ${Container} {
        margin-top: 10px;
    }

    & ${ClearedLabel} {
        position: absolute;
        top: 0;
        right: 0;
    }

    & .main-title ${QuestionTitle} {
        font-weight: normal !important;
        padding: 10px 0;
    }

    & ${QuestionTitle} div[id^="tooltip_"] {
        display: none;
    }
`;

const InnerQuestionContainer = styled.div`
    .cleared > & {
        opacity: 0.4;
    }
`;

const QuestionComponent: React.FC<{
    item: Instance<typeof QuoteSubjectivity>;
}> = ({ item }) => {
    if (item.type !== "question" || !item.question) return null;

    const store = getParent(item, 2) as SubjectivitiesStoreType;
    const cleared = item.cleared || store.supplementalApplicationSubmitted;
    //const questionsOnError = cleared ? [] : store.requiredErrors;

    return (
        <QuestionSubjectivityContainer id={item.key} className={cx({ cleared })}>
            <SubjectivityTitle>{item.name}</SubjectivityTitle>

            <InnerQuestionContainer>
                <QuestionRender
                    question={item.question as FullQuestion}
                    extraProps={{
                        overrideTitle: true,
                    }}
                />
            </InnerQuestionContainer>
        </QuestionSubjectivityContainer>
    );
};

export default QuestionComponent;
