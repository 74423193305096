import * as React from "react";
import spinner from "assets/spinner.svg";
import counterpartSpinning from "assets/counterpart_spinning.svg";
import { H2 } from "../typography";

const Loading: React.SFC<{ message?: string; show?: boolean; type?: string }> = ({
    message,
    show,
    type,
}) => {
    const spinnerIcon: string = `${(type === "spinner") ? spinner : counterpartSpinning}`;
    if (show) {
        return (
            <>
                <H2>{message}</H2>
                <div>
                    <img src={spinnerIcon} alt="Loading Indicator" />
                </div>
            </>
        );
    } else {
        return <></>;
    }
};

Loading.defaultProps = {
    message: "Loading...",
    show: true,
};

export default React.memo(Loading);
