/// <reference types="googlemaps" />

import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useFormikContext, ErrorMessage } from "formik";
import { Suggestion, ReconfigurableOptions } from "places.js";

import { CaptionText } from "./../typography";
import BaseInput from "./BaseInput";
import { FormErrorMessage } from "./styled";
import { UncontrolledTooltip } from "reactstrap";
import { Tooltip, TooltipContainer } from "counterpart-theme";

interface Props {
    name: string;
    placeholder: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    style?: any;
    onSuggestionChosen?: any;
    templates?: {
        value: (suggestion: Suggestion) => string;
        suggestion: (suggestion: Suggestion) => string;
    };
    placesConfig?: ReconfigurableOptions;
    id?: any;
    required?: boolean;
    postcodeSearch?: boolean;
    tooltipText?: string;
}
const RedAsterisk = styled.b`
    margin-left: 2px;
    margin-top: -5px;
    align-items: center;
    font-size: 16px;
    line-height: 140%;
    color: #d50f1f;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & ${BaseInput} {
        padding-right: 32px;
    }
`;

const emptyAddress = {
    name: "",
    city: "",
    administrative: "",
    postcode: "",
};

function fillInAddress(place?: google.maps.places.PlaceResult, postcodeSearch?: any) {
    if (!place) {
        return emptyAddress;
    }

    const addressInfoMap = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        // eslint-disable-next-line @typescript-eslint/camelcase
        administrative_area_level_1: "short_name",
        // eslint-disable-next-line @typescript-eslint/camelcase
        postal_code: "short_name",
    } as const;

    const addressInfo: Partial<Record<keyof typeof addressInfoMap, string | undefined>> = {};

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0] as keyof typeof addressInfoMap;
        if (addressInfoMap[addressType]) {
            const val = component[addressInfoMap[addressType]];
            addressInfo[addressType] = val;
        }
    }

    if(postcodeSearch) {
        return {
            name: (addressInfo.locality ?? "") + ", " + (addressInfo.administrative_area_level_1 ?? "") + " " + (addressInfo.postal_code ?? ""),
            city: addressInfo.locality ?? "",
            administrative: addressInfo.administrative_area_level_1 ?? "",
            postcode: addressInfo.postal_code ?? ""
        }
    }

    return {
        name: [addressInfo.street_number, addressInfo.route].filter(Boolean).join(" "),
        city: addressInfo.locality ?? "",
        administrative: addressInfo.administrative_area_level_1 ?? "",
        postcode: addressInfo.postal_code ?? "",
    };
}

const AddressField = ({
    name,
    label,
    className,
    style,
    onSuggestionChosen,
    id,
    disabled = false,
    tooltipText,
    postcodeSearch,
    ...rest
}: Props) => {
    const containerRef = useRef<HTMLInputElement>(null);
    const { getFieldMeta, setFieldValue, setFieldTouched } = useFormikContext<any>();
    const { value: formikValue, error } = getFieldMeta<any>(name);

    useEffect(() => {
        const autoComplete = new window.google.maps.places.Autocomplete(
            (containerRef.current as unknown) as HTMLInputElement,
            {
                componentRestrictions: { country: "us" },
            },
        );
        autoComplete.setFields(["address_components"]);
        autoComplete.addListener("place_changed", () => {
            const place = autoComplete.getPlace();
            const address = fillInAddress(place, postcodeSearch);
            if (containerRef.current) {
                containerRef.current.value =
                    address.name.length > 0
                        ? address.name
                        : `${address.postcode} ${address.city}, ${address.administrative}`;
            }
            setFieldTouched(name, true);
            address.name.length
                ? setFieldValue(name, address.name)
                : setFieldValue(
                      name,
                      `${address.postcode} ${address.city}, ${address.administrative}`,
                  );
            if (onSuggestionChosen) {
                onSuggestionChosen(address);
            }
        });

        if (formikValue && containerRef.current) {
            if (containerRef.current.value !== formikValue) {
                containerRef.current.value = formikValue;
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <InputContainer className={className} style={style}>
            {label && label.length && (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CaptionText as="label">{label}</CaptionText>{" "}
                    {rest.required && <RedAsterisk>*</RedAsterisk>}
                    <TooltipContainer style={{ marginLeft: 8, padding: 0 }}>
                        <Tooltip
                            id={`uncontrolledTooltipAddress`}
                            className={tooltipText && tooltipText?.length > 0 ? "" : "hidden"}
                            tabIndex={-1}
                        />
                        <UncontrolledTooltip placement="top" target={`uncontrolledTooltipAddress`}>
                            {tooltipText}
                        </UncontrolledTooltip>
                    </TooltipContainer>
                </div>
            )}
            <BaseInput
                id={id}
                name={name}
                error={!!error}
                onBlur={(e) => {
                    if (!formikValue) {
                        e.target.value = "";
                    }
                }}
                type="text"
                autoComplete="off"
                ref={containerRef}
                onChange={(ev) => {
                    if (!ev.target.value && !postcodeSearch) onSuggestionChosen(emptyAddress);
                    setFieldValue(name, ev.target.value);
                }}
                onFocus={(event) => {
                    event.target.setAttribute("autocomplete", "off");
                }}
                defaultValue={formikValue}
                disabled={disabled}
                {...rest}
            />
            {error && <ErrorMessage name={name} component={FormErrorMessage} />}
        </InputContainer>
    );
};

export default AddressField;
