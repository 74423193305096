import * as React from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useRouteMatch } from "react-router-dom";

import { MuseoSansBaseCSS } from "components/typography";
import { formatCurrency, classNames as cx } from "utils";

import { QuoteComponentStore, QuoteStore } from "./../store";
import { CalculatedRateType } from "../store/QuoteComponentModel";
import { RadioCheckButton, Tab, Tabs } from "components/common";
import styled from "styled-components/macro";
import { Tooltip, TooltipContainer } from "counterpart-theme";
import { UncontrolledTooltip } from "reactstrap";

import { GridRow, BlockWithLoader } from "./QuoteComponentDetails";
import SelectField, { Value } from "./SelectField";

interface Props {
    quoteComponent: QuoteComponentStore;
    quote: QuoteStore;
}

const tabStyle = `
    // width: 34%;
    margin-top: 30px;

    &:first-child {
        button {
            border-right: 1px solid #EEF1F7;
        }
    }

    &:nth-child(2) {
        button {
            border-right: 1px solid #EEF1F7;
            border-left: 1px solid #EEF1F7;
        }
    }

    &:last-child {
        button {
            border-left: 1px solid #EEF1F7;
        }
    }

    button {
        background: #F5F7FA;
        width: 100%;
        border-bottom: 1px solid #EEF1F7;
        color: #111da1;
        padding-bottom: 24px !important;

        &.isActive {
            background: #fff;
            border-bottom-color: #fff;
            font-weight: bold;
        }
    }
`;

const InQuoteTitleRow = styled.div`
    display: grid;
    grid-template-columns: 300px 1fr 1fr 1fr;
    font-size: 14px;
    grid-column-gap: 10px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const StyledRateName = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    display: flex;
    text-align: left;
    color: ${(props) => props.theme.colors.textColor};
    margin-left: 10px;
`;

const ShowOnMobile = styled.div`
    border-top: 1px solid #dbdbdb;
    margin-top: 20px;

    @media (min-width: 767px) {
        display: none;
    }
`;

const Indicator = styled.span<{ notEmpty: boolean }>`
    position: absolute;
    margin-left: ${({ notEmpty }) => (notEmpty ? "-21px" : "0")};
    color: #22232e4c;
    font-size: 8px;
`;

const StyledTooltip = styled(Tooltip as any)`
    height: 12px;
    width: 12px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;

    &:hover {
        svg {
            path {
                fill: #12b3dc;
            }
        }
    }
`;

const TabsWrapper = styled.div`
    & > div > div:first-child {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        z-index: 0;
    }
`;

type LimitsTableRowProps = {
    rate: CalculatedRateType | null;
    quoteComponent: QuoteComponentStore;
    optionsNumber: number;
    // quote: QuoteStore;
};

const LimitsTableRow = observer(({ rate, quoteComponent, optionsNumber }: LimitsTableRowProps) => {
    if (!rate) return null;

    const match = useRouteMatch<{
        publicSecurityToken: string;
        appSecurityToken: string;
      }>();

    const {publicSecurityToken, appSecurityToken} = match.params;

    const disabled = !rate.canClick;

    const onSelect = React.useCallback(
        (ev?: any) => {
            ev && ev.stopPropagation();
            if (rate.canClick && !rate.editing) {
                quoteComponent.setSelectedIndex(parseInt(rate.index), publicSecurityToken, appSecurityToken);
            }
        },
        [rate, quoteComponent, publicSecurityToken, appSecurityToken],
    );

    const onDesktopSelect = (e: any) => {
        if (window.innerWidth >= 769 && !rate.isSelected) {
            onSelect(e);
        }
    };

    const onCheckBoxClick = () => {
        if (
            !quoteComponent.hiddenOptions.includes(rate.index) &&
            quoteComponent.hiddenOptions.length + 1 > optionsNumber
        ) {
            toast.error("You must have one option in the Quote");
        } else {
            quoteComponent.setHiddenOptions(rate.index);
        }
    };

    React.useEffect(() => {
        if (window.innerWidth <= 768 && !rate.isSelected) {
            onSelect();
        }
    }, [onSelect, rate.isSelected]);

    return (
        <GridRow
            selected={rate.isSelected || false}
            key={rate.index}
            className={cx({ disabled }, "no-border-bottom")}
        >
            <div className="grid-row-cell option-cell" onClick={onDesktopSelect}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <RadioCheckButton
                        className={"no-border left-align auto-height top-align"}
                        type="radio"
                        name={quoteComponent.id}
                        disabled={!rate.canClick}
                        checked={!quoteComponent.coverageDenied && rate.isSelected}
                        onClick={onDesktopSelect}
                        label=""
                        value={quoteComponent.id}
                    />
                    <StyledRateName>{rate.name}</StyledRateName>
                    <div
                        style={{ marginLeft: "auto", width: 60, textAlign: "center" }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <RadioCheckButton
                            className="single-check"
                            type="checkbox"
                            name={quoteComponent.id}
                            disabled={false}
                            checked={
                                !quoteComponent.coverageDenied &&
                                !quoteComponent.hiddenOptions.includes(rate.index)
                            }
                            onClick={onCheckBoxClick}
                            label=""
                            value={quoteComponent.id}
                            checkboxStyle={{ marginLeft: 0 }}
                        />
                    </div>
                </div>
            </div>
            <div className="grid-row-cell" onClick={onDesktopSelect}>
                <>
                    <span className="mobile-title">limit</span>
                    <SelectField
                        editing={!quoteComponent.coverageDenied && rate.isSelected}
                        value={rate.limit}
                        options={quoteComponent.allowedLimits}
                        onChange={rate.changeLimit}
                        isAsync={true}
                        afterChange={rate.save}
                        disabled={quoteComponent.coverageDenied}
                    />
                </>
            </div>
            <div className="grid-row-cell" onClick={onDesktopSelect}>
                <>
                    <span className="mobile-title">retention</span>
                    <SelectField
                        editing={!quoteComponent.coverageDenied && rate.isSelected}
                        value={rate.retention}
                        options={quoteComponent.allowedRetention}
                        onChange={rate.changeRetention}
                        isAsync={true}
                        afterChange={rate.save}
                        disabled={quoteComponent.coverageDenied}
                    />
                    {rate.editing && (
                        <TooltipContainer style={{ marginLeft: 8, padding: 0, marginTop: 15 }}>
                            <Tooltip id={`retentionTooltip`} />
                            <UncontrolledTooltip placement="top" target={`retentionTooltip`}>
                                Counterpart may increase the coverage retention offered to the
                                Company based on the complexity or risk characteristics of the
                                business. If you have any questions please get in touch with our
                                underwriting team through the chat feature in the bottom right
                                corner.
                            </UncontrolledTooltip>
                        </TooltipContainer>
                    )}
                </>
            </div>
            <div className="grid-row-cell no-borders" onClick={onDesktopSelect}>
                <span className="mobile-title">premium</span>
                <Value>
                    <BlockWithLoader
                        style={{ height: 19 }}
                        loading={quoteComponent.loading && rate.isSelected}
                    >
                        <strong>{formatCurrency(rate.premium)}</strong>
                    </BlockWithLoader>
                </Value>
            </div>
            {/* <td align="left">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        height: "100%",
                        maxHeight: 55,
                        paddingTop: 12,
                    }}
                >
                    {(() => {
                        if (quote.sharedLimits) {
                            return null;
                        }
                        if (rate.editing) {
                            if (rate.loading) {
                                return <TrashIcon src={iconSpinner} alt="loading" />;
                            }
                            return (
                                <CustomButtonLabel
                                    style={{
                                        marginLeft: 15,
                                    }}
                                    className="edit-button"
                                    onClick={rate.save}
                                >
                                    calculate
                                </CustomButtonLabel>
                            );
                        }
                        return (
                            <>
                                {rate.isRemovable && (
                                    <>
                                        <CustomButtonLabel
                                            className="edit-button"
                                            onClick={rate.edit}
                                            style={{
                                                marginLeft: 15,
                                            }}
                                        >
                                            EDIT
                                        </CustomButtonLabel>
                                        <CustomButtonLabel
                                            className="delete-button"
                                            style={{
                                                marginLeft: 15,
                                            }}
                                            onClick={rate.remove}
                                        >
                                            DELETE
                                        </CustomButtonLabel>
                                    </>
                                )}
                                {!rate.isRemovable && (
                                    <CustomButtonLabel
                                        className="edit-button"
                                        onClick={rate.edit}
                                        style={{
                                            marginLeft: 15,
                                        }}
                                    >
                                        EDIT
                                    </CustomButtonLabel>
                                )}
                            </>
                        );
                    })()}
                </div>
            </td> */}
            <ShowOnMobile>
                <RadioCheckButton
                    className="left-align no-border"
                    type="checkbox"
                    name={quoteComponent.id}
                    disabled={false}
                    checked={
                        !quoteComponent.coverageDenied &&
                        !quoteComponent.hiddenOptions.includes(rate.index)
                    }
                    onClick={onCheckBoxClick}
                    label="Add info onto the Quote Bundle document"
                    value={quoteComponent.id}
                    checkboxStyle={{ marginLeft: 0 }}
                />
            </ShowOnMobile>
        </GridRow>
    );
});

const LimitsTable: React.FC<Props> = observer(({ quoteComponent }) => {
    const calculatedRatesEntries = Object.entries(quoteComponent.calculatedRates);

    const calculatedRatesEntriesLength = calculatedRatesEntries.filter(([, rate]) => rate).length;

    const [selectedTab, setSelectedTab] = React.useState(0);
    return (
        <>
            <>
                <section>
                    <InQuoteTitleRow>
                        <div className="grid-row-cell option-cell">
                            <div></div>
                            <div
                                style={{
                                    textAlign: "right",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    lineHeight: "16px",
                                }}
                            >
                                <span style={{ color: "#acacb0", fontSize: 10 }}>IN QUOTE</span>
                                <TooltipContainer style={{ marginLeft: 8, zIndex: 1 }}>
                                    <StyledTooltip id={`limitTooltip`}>
                                        <svg
                                            width="12"
                                            height="12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.574 7.974a.948.948 0 00-.072.09.456.456 0 00-.054.108.384.384 0 00-.036.108.817.817 0 000 .12.504.504 0 00.048.228.54.54 0 00.324.324.564.564 0 00.456 0 .54.54 0 00.324-.324A.6.6 0 006.6 8.4a.6.6 0 00-1.026-.426zM6 0a6 6 0 100 12A6 6 0 006 0zm0 10.8a4.8 4.8 0 110-9.6 4.8 4.8 0 010 9.6zM6 3a1.8 1.8 0 00-1.56.9.6.6 0 101.038.6.6.6 0 11.522.9.6.6 0 00-.6.6v.6a.6.6 0 001.2 0v-.108A1.8 1.8 0 006 3z"
                                                fill="#BFCAD6"
                                            />
                                        </svg>
                                    </StyledTooltip>
                                    <UncontrolledTooltip placement="top" target={`limitTooltip`}>
                                        The quote options will be shown in the Quote Bundle document
                                        unless toggled off here
                                    </UncontrolledTooltip>
                                </TooltipContainer>
                            </div>
                        </div>
                        <div className="grid-row-cell"></div>
                        <div className="grid-row-cell"></div>
                        <div className="grid-row-cell"></div>
                    </InQuoteTitleRow>
                </section>
                {window.innerWidth >= 768 ? (
                    <>
                        {calculatedRatesEntries.map(([index, rate]) => {
                            const r: any = rate;
                            return (
                                <LimitsTableRow
                                    key={index}
                                    rate={r}
                                    quoteComponent={quoteComponent}
                                    optionsNumber={calculatedRatesEntriesLength}
                                />
                            );
                        })}
                    </>
                ) : (
                    <>
                        {calculatedRatesEntriesLength > 1 ? (
                            <TabsWrapper>
                                <Tabs currentActiveIndex={selectedTab}>
                                    {calculatedRatesEntries.map(([index, rate]) => {
                                        const r: any = rate;
                                        if (r && r.isSelected && +index !== selectedTab) {
                                            setSelectedTab(+index);
                                        }

                                        if (r) {
                                            return (
                                                <Tab
                                                    containerStyle={tabStyle}
                                                    tabIndex={+index}
                                                    label={[
                                                        r.name,
                                                        <br key={`BrLabel__${index}`} />,
                                                        <Indicator
                                                            key={`IndicatorLabel__${index}`}
                                                            notEmpty={
                                                                !quoteComponent.hiddenOptions.includes(
                                                                    index,
                                                                )
                                                            }
                                                        >{`${
                                                            !quoteComponent.hiddenOptions.includes(
                                                                index,
                                                            )
                                                                ? "IN QUOTE"
                                                                : "-"
                                                        }`}</Indicator>,
                                                    ]}
                                                    key={`TabLabel__${index}`}
                                                >
                                                    {" "}
                                                    <LimitsTableRow
                                                        key={index}
                                                        rate={r}
                                                        quoteComponent={quoteComponent}
                                                        optionsNumber={calculatedRatesEntriesLength}
                                                    />
                                                </Tab>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Tabs>
                            </TabsWrapper>
                        ) : (
                            <div style={{ paddingTop: 30 }}>
                                {calculatedRatesEntries.map(([index, rate]) => {
                                    const r: any = rate;
                                    if (r && r.isSelected && +index !== selectedTab) {
                                        setSelectedTab(+index);
                                    }

                                    if (r) {
                                        return (
                                            <LimitsTableRow
                                                key={index}
                                                rate={r}
                                                quoteComponent={quoteComponent}
                                                optionsNumber={calculatedRatesEntriesLength}
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        )}
                    </>
                )}
            </>
            {/* <div
            style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "160%",
                color: "rgba(34, 35, 46, 0.4)",
            }}
        >
            {!quote.shouldUseManualDiscounts && (
                <>
                    NOTE: Discounts are included in the Premium on this page and may be different
                    from the Overview page, which have discounts itemized.
                </>
            )}
        </div> */}
        </>
    );
});

export default LimitsTable;
