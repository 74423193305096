import React from "react";
import styled, { keyframes } from "styled-components/macro";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useRouteMatch } from "react-router-dom";

import { H4, Body3 } from "components/typography";
import { formatCurrency } from "utils";

import { QuoteComponentStore, useAccountStore } from "./../store";

import QuoteOtherLimitsAndRetentionsForm from "./QuoteOtherLimitsAndRetentionsForm";
import { CoverageInfoItem, CoverageInfoBody, DeclineCoverageText } from "./styled";
import SelectField from "./SelectField";

interface QuoteComponentDetailsProps {
    quoteComponent: QuoteComponentStore;
    expandable?: boolean;
}

// function getTitleTexts(store: StoreType, comp: QuoteComponentModelType): [string, string, string] {
//     const { limits, retention } = comp;
//     const visualPremium: string = comp.calculateVisualPremium(store.quote);
//     if (comp.coverageDenied) {
//         return ["--", "--", "--"];
//     } else if (store.quote?.sharedLimits) {
//         return ["Shared", formatCurrency(retention), visualPremium];
//     }
//     return [formatCurrency(limits), formatCurrency(retention), visualPremium];
// }

const DeclineCoverageBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px solid #dbdbdb;
    position: relative;
    z-index: 1;

    ${DeclineCoverageText} {
        text-transform: uppercase;
        color: #707070;
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        transition: color 0.3s;

        @media (max-width: 768px) {
            width: 100%;
            padding: 15px 0;
            border: 1px solid #bfcad6;
            border-radius: 10px;
            justify-content: center;
            font-size: 10px;
        }

        &.denied {
            &:hover {
                text-decoration: none;
                color: #111da1;

                svg path {
                    stroke: #111da1;
                }
            }
        }

        &:hover {
            text-decoration: none;
            color: #f36f7a;
            svg path {
                stroke: #f36f7a;
            }
        }

        svg {
            margin-right: 8px;
            min-width: 20px;

            path {
                transition: stroke 0.3s;
            }
        }
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

export const GridRow = styled.div<{ selected?: boolean }>`
    display: grid;
    grid-template-columns: 300px 1fr 1fr 1fr;
    font-size: 14px;
    grid-column-gap: 10px;
    /* grid-column-gap: 30px;
    grid-row-gap: 20px; */

    @media (min-width: 767px) {
        ${({ selected }) =>
            selected === false &&
            `
            background: #F5F7FA;
        `}

        ${({ selected }) =>
            selected &&
            `
            background: #fff;
        `}
    }

    @media (max-width: 768px) {
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    &.no-border-bottom {
        @media (max-width: 768px) {
            border-bottom: none;
        }
    }

    &.hide-on-mobile {
        @media (max-width: 768px) {
            display: none;
        }
    }

    &.show-on-mobile {
        @media (min-width: 767px) {
            display: none;
        }

        &.title-row {
            border: none;
            padding: 0;
        }
    }

    &.no-bottom-border > .grid-row-cell {
        &:after {
            display: none;
        }
    }

    & > .grid-row-cell {
        padding: 10px;
        line-height: 20px;
        position: relative;

        @media (max-width: 768px) {
            display: flex;
            justify-content: space-between;
            padding: 5px;
        }

        &.name-cell {
            display: flex;
            align-items: center;
            /* padding-left: 0; */

            &:after {
                width: calc(100% - 10px);
                left: 0;
            }

            @media (max-width: 768px) {
                display: block;
                margin-bottom: 20px;
            }
        }

        .mobile-title {
            color: #acacb0;
            font-size: 10px;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            @media (min-width: 767px) {
                display: none;
            }
        }

        &.option-cell {
            padding: 8px 10px;

            @media (max-width: 768px) {
                display: none;
            }
        }

        &.no-borders {
            border: none !important;

            &:after {
                display: none;
            }
        }

        &:not(:first-child) {
            @media (min-width: 767px) {
                border-left: 1px solid #dbdbdb;
                border-right: 1px solid #dbdbdb;
            }
        }

        &:not(:first-child) {
            text-align: center;
            @media (min-width: 769px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:after {
            @media (min-width: 767px) {
                content: "";
                position: absolute;
                width: calc(100% - 20px);
                left: 10px;
                bottom: 0;
                height: 1px;
                background-color: #dbdbdb;
            }
        }
    }

    &:last-of-type {
        & > .grid-row-cell:after {
            display: none;
        }

        & > .grid-row-cell:not(:first-child) {
            @media (min-width: 767px) {
                border-bottom: 1px solid #dbdbdb;
                border-radius: 0;
            }
        }
    }

    &:first-of-type {
        & > .grid-row-cell:not(:first-child) {
            @media (min-width: 767px) {
                border-top: 1px solid #dbdbdb;
                border-radius: 0;
            }
        }
    }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.span`
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${rotate} 2s linear infinite;
`;

const Value = styled.span`
    padding: 0;
`;

export const BlockWithLoader: React.FC<{ children: any; loading: boolean; style?: any }> = ({
    children,
    loading,
    style,
}) => {
    if (loading) {
        return (
            <Loader style={style}>
                <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.536 10a.666.666 0 00-.911-.244l-1.155.666a.667.667 0 00.667 1.155l1.155-.666A.667.667 0 004.536 10zM2.47 5.577l1.155.667a.667.667 0 10.667-1.155l-1.155-.667a.667.667 0 00-.667 1.155zM4 8a.667.667 0 00-.667-.667H2a.667.667 0 000 1.333h1.333A.667.667 0 004 8zm7.463-2a.667.667 0 00.911.244l1.155-.667a.667.667 0 10-.666-1.155l-1.155.667a.667.667 0 00-.245.91zM10 4.536a.667.667 0 00.91-.244l.667-1.155a.666.666 0 10-1.155-.667l-.666 1.155a.667.667 0 00.244.91zm3.53 5.886l-1.156-.666a.666.666 0 10-.666 1.155l1.155.666a.667.667 0 00.666-1.155zm-2.62 1.286a.668.668 0 10-1.154.666l.666 1.155a.666.666 0 101.155-.666l-.666-1.155zM14 7.333h-1.334a.667.667 0 000 1.333H14a.667.667 0 100-1.333zM8 12a.667.667 0 00-.667.666V14a.667.667 0 101.333 0v-1.334A.667.667 0 008 12zm-2-.537a.668.668 0 00-.911.245l-.667 1.155a.667.667 0 001.155.666l.667-1.155a.667.667 0 00-.244-.91zm2-10.13A.667.667 0 007.333 2v1.333a.667.667 0 001.333 0V2A.667.667 0 008 1.333z"
                        fill="#C0C4CC"
                    />
                </svg>
            </Loader>
        );
    } else {
        return children;
    }
};

const QuoteComponentDetails = observer(
    ({ quoteComponent, expandable = true }: QuoteComponentDetailsProps) => {
        const store = useAccountStore();
        const match = useRouteMatch<{ quoteID: string }>();
        if (store.quote?.finalized && quoteComponent.coverageDenied) {
            return null;
        }
        // const [limitText, retentionText, premiumText] = getTitleTexts(store, quoteComponent);
        const isPolicy = store.quote?.finalized;
        const isOpen = true;

        // const toggleOpen = (ev: any) => {
        //     ev.stopPropagation();
        //     if (expandable) {
        //         quoteComponent.toggleOpen();
        //     }
        // };

        const modifiableSubcoverages = store.quote.brokerModifiableSubcoverages;

        const onSubcoverageChange = (key: string, e: any) => {
            const quoteId = match.params.quoteID;
            const res = quoteComponent.setNewSubcoverage(key, e, quoteId);
            return res;
        };

        return (
            <CoverageInfoItem
                className={`${expandable && isOpen ? "open" : "closed"} ${
                    expandable ? "expansible" : ""
                }`}
                style={{ userSelect: "none" }}
            >
                <CoverageInfoBody>
                    <div style={{ width: "100%", padding: 0 }}>
                        <QuoteOtherLimitsAndRetentionsForm
                            quoteComponent={quoteComponent}
                            quote={store.quote as NonNullable<typeof store.quote>}
                        />
                        {quoteComponent.coverage && quoteComponent.coverage?.sublimits.length > 0 && (
                            <>
                                {!isPolicy && (
                                    <GridRow className="show-on-mobile title-row">
                                        <H4 style={{ textAlign: "left", margin: 0 }}>
                                            Directors & Officers
                                        </H4>
                                        <Body3
                                            style={{ fontSize: 14, lineHeight: "20px", margin: 0 }}
                                        >
                                            Click on the Coverage Highlights document for more
                                            details on the coverages below
                                        </Body3>
                                    </GridRow>
                                )}
                                <GridRow className="no-bottom-border hide-on-mobile">
                                    <div
                                        className="grid-row-cell name-cell"
                                        style={{
                                            paddingTop: !isPolicy ? 90 : "0",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <H4 style={{ textAlign: "left", margin: 0 }}>
                                            Limits & Retentions
                                        </H4>
                                    </div>
                                    <div
                                        className="grid-row-cell hide-on-mobile"
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <span style={{ color: "#707070", fontSize: 10 }}>
                                            LIMIT
                                        </span>
                                    </div>
                                    <div
                                        className="grid-row-cell"
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <span style={{ color: "#707070", fontSize: 10 }}>
                                            RETENTION
                                        </span>
                                    </div>
                                    <div
                                        className="grid-row-cell"
                                        style={{
                                            border: "none",
                                            padding: 0,
                                            position: "relative",
                                        }}
                                    >
                                        {/* <div
                                            style={{
                                                position: "absolute",
                                                height: 20,
                                                width: "100%",
                                                border: "1px solid #ebf0f6",
                                                borderRadius: "0 0 8px 8px",
                                                borderTopColor: "transparent",
                                                top: 0,
                                            }}
                                        ></div> */}
                                        {/* <span style={{ color: "#acacb0", fontSize: 10 }}>
                                            PREMIUM
                                        </span> */}
                                    </div>
                                </GridRow>
                                {quoteComponent?.calculatedRates[
                                    quoteComponent.selectedIndex
                                ]?.coverages?.map((item: any, index: number) => {
                                    return (
                                        <GridRow
                                            key={`trCoverageSublimits_${index}`}
                                            // className={classNames({
                                            //     "no-bottom-border":
                                            //         index ===
                                            //         quoteComponent?.calculatedRates && quoteComponent?.calculatedRates[quoteComponent.selectedIndex]?.coverages,
                                            // })}
                                        >
                                            <div className="grid-row-cell name-cell">
                                                {item?.verboseName}
                                            </div>
                                            <div className="grid-row-cell">
                                                <span className="mobile-title">Limit</span>
                                                {item && item.verboseName === "ERISA" && item.limit === "0" && item.retention === "0" ? (
                                                    <>Not Purchased</>
                                                ) : (
                                                    <SelectField
                                                        editing={
                                                            !quoteComponent.coverageDenied &&
                                                            !isPolicy &&
                                                            modifiableSubcoverages.includes(
                                                                item.limitKey,
                                                            )
                                                        }
                                                        value={item.limit}
                                                        options={item.getLimitChoices}
                                                        onChange={(e) =>
                                                            onSubcoverageChange(item.limitKey, e)
                                                        }
                                                        isAsync={true}
                                                        disabled={quoteComponent.coverageDenied}
                                                    />
                                                )}
                                            </div>
                                            <div className="grid-row-cell">
                                                <span className="mobile-title">Retention</span>
                                                <Value>
                                                    {item && item.verboseName === "ERISA" && item.limit === "0" && item.retention === "0" ? (
                                                        <>Not Purchased</>
                                                    ) : (
                                                        <>
                                                            {item?.retention !== null
                                                                ? formatCurrency(item?.retention)
                                                                : "-"}
                                                        </>
                                                    )}
                                                </Value>
                                            </div>
                                            {/* <div className="grid-row-cell">
                                                    <span className="mobile-title">premium</span>
                                                    <Value>-</Value>
                                                </div> */}
                                        </GridRow>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    {quoteComponent.endorsements.length > 0 && (
                        <div style={{ width: "100%", padding: 0, marginTop: 40 }}>
                            <div style={{ display: "flex" }}>
                                <H4 style={{ textAlign: "left", marginBottom: 20 }}>
                                    Endorsements
                                </H4>
                            </div>

                            <Grid>
                                {quoteComponent.endorsements.map((item: any) => (
                                    <div
                                        key={item?.endorsementId || "1"}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>
                                            {item?.name}
                                        </p>
                                         {/* <p style={{ textAlign: "left" }}>{item?.description}</p> */}
                                    </div>
                                ))}
                            </Grid>
                        </div>
                    )}
                    {!store.quote?.finalized && (
                        <DeclineCoverageBlock>
                            <DeclineCoverageText
                                onClick={quoteComponent.toggleCoverage}
                                className={classNames({ denied: quoteComponent.coverageDenied })}
                            >
                                {!quoteComponent.coverageDenied && (
                                    <svg
                                        width="20"
                                        height="20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            stroke="#7a7b82"
                                            strokeWidth="1.711"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M10 18.334a8.333 8.333 0 100-16.667 8.333 8.333 0 000 16.667zM12.5 7.5l-5 5M7.5 7.5l5 5" />
                                        </g>
                                    </svg>
                                )}
                                {quoteComponent.coverageDenied ? "Enable" : "Decline"}{" "}
                                {quoteComponent.insuranceLine.name} Coverage
                            </DeclineCoverageText>
                        </DeclineCoverageBlock>
                    )}
                </CoverageInfoBody>
            </CoverageInfoItem>
        );
    },
);

export default QuoteComponentDetails;
