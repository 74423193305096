import React from "react";
import styled from "styled-components/macro";
import rootStore from "services/store";
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect } from "react-router"
import { FullPage, StackedPaperPaddedContainer } from "components/common";
import { getAccountsNeedingFeedback, requestAccountExtending } from "services/quotes"
import { AccountNeedsFeedbackQuote } from "CounterpartApiTypes";
import { parseQueryString } from "utils"
import { toast } from "react-toastify";

import Loading from "components/common/Loading";

import {
    BlueDivider,
    LightBlueButton,
    Button,
    CancelButton,
    Modal
} from "components/common";

import { H2, Body3 } from "components/typography";

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 999999;
`;

const DataColumn = styled.span`
    text-align: left;
    padding-right: 5px;

    &:first-of-type {
        font-weight: bold;
    }

    @media (max-width: 576px) {
        font-size: 14px;
    }

    .mobileTitle {
        display: none;
        font-size: 11px;

        @media (max-width: 576px) {
            display: initial;
        }
    }
`;

const QuoteRow = styled.div`
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 0.7fr 0.7fr;
    padding: 18px 0;

    &:not(:first-of-type) {
        border-top: 1px solid rgba(191, 202, 214, 0.6);
    }

    ${LightBlueButton} {
        max-height: 30px;

        margin: auto 0;

        @media (max-width: 576px) {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
        }
    }

    ${DataColumn}:not (:first-of-type) {
        @media (max-width: 576px) {
            display: flex;
            justify-content: space-between;
            grid-column: 1 / 3;
            align-items: center;
            padding: 5px 0;
        }
    }

    @media (max-width: 576px) {
        grid-template-columns: 1.5fr 1fr;
        grid-row-gap: 5px;
    }
`;

const TitleRow = styled.div`
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 0.7fr 0.7fr;
    padding-bottom: 4px;

    @media (max-width: 576px) {
        display: none;
    }

    span {
        text-align: left;
        font-size: 12px;
        color: #22232e;
        text-transform: uppercase;
    }
`;

export const LinkButton = Button.withComponent(Link);

const ThanksBlock = styled.div`
    padding: 30px;
    text-align: center;
    left: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 8px;

    ${BlueDivider} {
        margin: 14px auto 30px auto;
        width: 60px;
    }

    ${LinkButton} {
        justify-self: center;
        border-radius: 8px;

        @media (max-width: 576px) {
            justify-self: stretch;
        }
    }
`;

const QuoteGrid = styled.div`
    border-bottom: 1px solid #bfcad6;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: 800px) {
        width: 100%;
    }
`;

const QuoteRowComponent: React.FC<{
    name: string | null | undefined;
    appDate: string | null | undefined;
    expDate: string | null | undefined;
    id: string | null | undefined;
    brokerId: string;
    token: string;
    setShowExtension: any;
    setExtendError: any;
    setExtendLoading: any;
}> = ({ name, appDate, expDate, id, brokerId, token, setShowExtension, setExtendError, setExtendLoading }) => {

    async function requestAccountExtension(quoteId: string, brokerId: string, token: string) {
        try {
            await requestAccountExtending({ 'brokerId': brokerId, 'quickPageToken': token }, quoteId)
            toast.success("Account Extension Requested")
            return true
        } catch (error) {
            return false
        }
    }

    const getDateString = (dateStr: any) => {
        if (dateStr) {
            return moment(dateStr).format("YYYY-MM-DD")
        } else {
            return "-";
        }
    };

    const handleClick = (id: string, brokerId: string, token: string) => {
        let close_account_redirect = "/close_account/" + id + (brokerId !== "" && token !== "" ? "?brokerId=" + brokerId + "&token=" + token : "")
        window.location.href = close_account_redirect
    };

    const extendQuoteEmail = (id: string, brokerId: string, token: string, setError: any, setLoading: any) => {
        // Send Notification
        requestAccountExtension(id, brokerId, token).then((success: boolean) => {
            setError(!success)
            setLoading(false)
        })
    };

    return (
        <QuoteRow>
            <DataColumn>{name}</DataColumn>
            <DataColumn>
                <span className="mobileTitle">APPLICATION DATE</span>
                {getDateString(appDate)}
            </DataColumn>
            <DataColumn>
                <span className="mobileTitle">EXPIRATION DATE</span>
                {getDateString(expDate)}
            </DataColumn>
            <CancelButton
                style={{
                    maxWidth: "230px",
                    padding: "5px 0",
                    fontSize: "10px",
                    minHeight: "unset",
                    marginRight: "10px"
                }}
                onClick={() => {
                        setExtendError(false);
                        setExtendLoading(true);
                        setShowExtension(true);
                        extendQuoteEmail(id || "", brokerId, token, setExtendError, setExtendLoading)
                    }
                }
            >
                Extend
            </CancelButton>
            <LightBlueButton
                style={{
                    maxWidth: "230px",
                    // marginLeft: "10px",
                    padding: "7px 0",
                    fontSize: "10px",
                    minHeight: "unset",
                }}
                onClick={() => handleClick(id || "", brokerId, token)}
            >
                Close
            </LightBlueButton>
        </QuoteRow>
    );
};


async function loadAccountsNeedingFeedback(setError: any, brokerId: string = "", token: string = "") {
    try {
        let quotesData: any = await getAccountsNeedingFeedback(brokerId, token)

        let data: AccountNeedsFeedbackQuote[] = quotesData;

        return data;

    } catch (error) {
        setError(true)
        return null
    }
}

const AccountLost: React.FC<{}> = () => {
    let { brokerId, token, thanks } = parseQueryString()
    brokerId = brokerId ?? "";
    token = token ?? "";
    let showThanks = thanks ?? false;

    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState<[AccountNeedsFeedbackQuote] | null>(null)
    const [error, setError] = React.useState(false)
    const [showExtension, setShowExtension] = React.useState(false);
    const [extendError, setExtendError] = React.useState(false);
    const [extendLoading, setExtendLoading] = React.useState(true);
    const title = showThanks ? "Thank You For The Feedback!" : "Expiring / Expired Quotes"

    React.useEffect(() => {
        setTimeout(() => {
            loadAccountsNeedingFeedback(setError, brokerId, token).then((qs: any) => {
                console.log(qs)
                if(qs) {
                    setLoading(false)
                    setData(qs)
                    return
                }
                // window.location.href = "/";
            })
        }, 2000)
    }, [brokerId, token])

    if((rootStore === null || rootStore.userInfo === null) && (brokerId === "" || token === "")) {
        setError(true)
    }

    if (error) return <Redirect to="/" />;

    if(loading || data === null) {
        return (
            <StyledLoaderContainer style={{ textAlign: "center" }}>
                    <Loading message="" />
            </StyledLoaderContainer>
        )
    }

    return (
        <>
            <FullPage>
                <StackedPaperPaddedContainer>
                    <ThanksBlock>
                        <H2>{title}</H2>
                        <Body3 style={{ lineHeight: "150%", opacity: 0.8 }}>
                            We are grateful for your support in improving Counterpart for you and your
                            clients.
                        </Body3>
                        <BlueDivider />
                        {data && !data.length ? (
                            <Body3>
                                No quotes needing feedback at this time.
                            </Body3>
                        ) : (
                            <>
                                <Body3 style={{ textAlign: "left" }}>
                                    See all of the expiring / expired quotes:
                                </Body3>
                                <TitleRow>
                                    <span>NAME</span>
                                    <span>Application date</span>
                                    <span>Quote Expiration Date</span>
                                    <span></span>
                                </TitleRow>
                                <QuoteGrid>
                                    {data.map((item: AccountNeedsFeedbackQuote) => (
                                        <QuoteRowComponent
                                            key={item.accountId}
                                            name={item.accountName}
                                            appDate={item.accountSubmissionDate}
                                            expDate={item.accountExpirationDate}
                                            id={item.accountId}
                                            brokerId={brokerId}
                                            token={token}
                                            setShowExtension={setShowExtension}
                                            setExtendError={setExtendError}
                                            setExtendLoading={setExtendLoading}
                                        />
                                    ))}
                                </QuoteGrid>
                            </>
                        )}
                        <LinkButton style={{ marginBottom: "30px" }} to="/">Return to Dashboard</LinkButton>
                    </ThanksBlock>
                </StackedPaperPaddedContainer>
            </FullPage>
            <Modal
                isOpen={showExtension}
                closeable={true}
                closeAction={() => setShowExtension(false)}
                title="Extend Account"
                buttonStyle="vertical"
                buttons={[
                    <CancelButton
                        className="no-hover"
                        key="modalButton2"
                        onClick={() => setShowExtension(false)}
                        style={{ minWidth: "250px" }}
                    >
                        Return to Accounts
                    </CancelButton>,
                ]}
            >
                {extendLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <Loading message="" />
                    </div>
                ) : (
                    <>
                        {extendError ? (
                            <p>
                                Sorry, we could not request an extension for this account.
                                If you would like to talk directly to an underwriter email out team at <a href="mailto:underwriting@yourcounterpart.com">underwriting@yourcounterpart.com.</a>
                            </p>
                        ) : (
                            <p>
                                Thank you for notifying us that this quote is still open!
                                Our underwriting team will be in touch to extend the quote 30 days.
                                Please get in touch with our underwriting team if you would like to discuss the account or terms at <a href="mailto:underwriting@yourcounterpart.com">underwriting@yourcounterpart.com.</a>
                            </p>
                        )}
                    </>
                )}
            </Modal>
        </>
    );
};

export default AccountLost;
