import moment from "moment";
import { QuoteStore } from "./store";
import isEmpty from "lodash/isEmpty";

export const effectiveCompleted = (quote: QuoteStore): boolean =>
    !!quote.effectiveDate && moment(quote.effectiveDate).isValid();

export const signedApplicationCompleted = (quote: any): boolean => !!quote.signedPdfDocument;
export const surplusLicenseFilled = (quote: any): boolean => !quote.quoteHasLicenseeRequirement || quote.brokerSurplusLicense;

export const subjectivitiesCompleted = (quote: QuoteStore, onlyRequired: boolean = false): boolean => {
    let subjectivity = false;
    if(onlyRequired) {
        subjectivity = isEmpty(quote.quoteSubjectivities) || isEmpty(quote.quoteSubjectivities.filter((subjectivity:any) => !subjectivity?.cleared && !subjectivity?.isOptional));
    } else {
        subjectivity = isEmpty(quote.quoteSubjectivities) || isEmpty(quote.quoteSubjectivities.filter((subjectivity:any) => !subjectivity?.cleared));
    }
    return subjectivity && signedApplicationCompleted(quote)
};

export const canBindCoverages = (quote?: any): boolean => {
    return (
        surplusLicenseFilled(quote) &&
        effectiveCompleted(quote) &&
        subjectivitiesCompleted(quote, true)
    );
};
