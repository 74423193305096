import { ValueType } from "./types";

export function toNumber(n: ValueType): number {
    if (typeof n === "number" && !isNaN(n) && isFinite(n)) {
        return n;
    }

    // null, undefined and empty string will be 0;
    if (!n || !String(n).replace(/[^\d]/g, "")) return 0;

    const number = parseFloat(String(n));

    // Return 0 when the number is invalid
    if (isNaN(number) || !isFinite(number)) return 0;

    return number;
}

export function toNumberArray(args: ValueType, argsNum?: number): number[] {
    if (!Array.isArray(args)) throw new Error("Array Expected");
    if (argsNum !== undefined && argsNum !== Infinity && args.length !== argsNum) {
        throw new Error("Invalid number of arguments");
    }
    const flatArgs = args.reduce((acc, val) => (acc as any[]).concat(val), []);
    return (flatArgs as any[]).map((s: any) => toNumber(s));
}
