import React, { useRef, useCallback } from "react";
import styled from "styled-components/macro";

import { MuseoSansBaseCSS } from "components/typography";

type Props = Omit<React.InputHTMLAttributes<any>, "onChange"> & {
    label: string;
    onChange: (e: boolean) => any;
    value: any;
};

const InputRadio: React.SFC<Props> = ({ className, onChange, value, label, ...props }) => {
    const inputRef = useRef(null);
    const onClick = useCallback(() => {
        if (onChange) {
            onChange(value);
        } else {
            return;
        }
    }, [onChange, value]);
    return (
        <div className={className} onClick={onClick}>
            <input ref={inputRef} {...props} />
            <label>{label}</label>
            <div className="checkmark" />
        </div>
    );
};

InputRadio.defaultProps = {
    type: "radio",
};

export const RadioButton = styled(InputRadio)`
           ${MuseoSansBaseCSS}
           position: relative;
           display: inline-flex;
           align-items: center;
           min-width: 150px;
           padding: 15px 0 15px 20px;
           cursor: pointer;
           user-select: none;
           border-style: solid;
           border-width: 2px;
           border-color: ${({ checked, theme }) =>
               checked ? theme.colors.secondary : theme.colors.third};
           height: 50px;

           & input {
               cursor: pointer;
           }
           & label {
               margin: 0 0 0 10px;
               cursor: pointer;
           }
       `;

export default RadioButton;
