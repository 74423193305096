// eslint-disable-next-line @typescript-eslint/camelcase

import React from "react";
import { FullQuestion } from "services/application";
import { QuestionTypeEnum } from "CounterpartApiTypes";

import Category from "./Category";
import HorizontalCategory from "./HorizontalCategory";
import TableCategory from "./TableCategory";
import Text from "./Text";
import Date from "./Date";
import Address from "./Address";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Currency from "./Currency";
import NumberField from "./Number";
import Boolean from "./Boolean";

export const TYPE_TO_COMPONENT_MAP: {
    [P in QuestionTypeEnum]: React.SFC<{
        question: FullQuestion;
        extraProps?: { [key: string]: any };
    }>;
} = {
    category: Category,
    checkbox: Checkbox,
    date: Date,
    email: Text,
    file: Text,
    // eslint-disable-next-line @typescript-eslint/camelcase
    horizontal_category: HorizontalCategory,
    number: NumberField,
    radio: Checkbox,
    select: Select,
    // eslint-disable-next-line @typescript-eslint/camelcase
    table_category: TableCategory,
    text: Text,
    textarea: Text,
    year: Text,
    address: Address,
    currency: Currency,
    percentage: Text,
    boolean: Boolean,
};

export { Category, HorizontalCategory, TableCategory, Text };
