import { Modal, Button, CancelButton } from "components/common";
import { Body1 } from "components/typography";
import React from "react";
import { SubjectivitiesStoreType } from "../store";
import { observer } from "mobx-react-lite";

const SignModal: React.FC<{ store: SubjectivitiesStoreType; }> = ({
    store,
}) => {
    return (
        <Modal
            isOpen={store.showHowSign}
            closeable={true}
            title={`How would you like to sign?`}
            titlePosition="center"
            buttonStyle="horizontal"
            closeAction={store.setHowSignClosed}
            buttons={[
                <CancelButton
                    key="modalButton1"
                    onClick={async () => { store.setHowSignClosed(); store.startSign(); }}
                    style={{ minWidth: "250px", minHeight: "50px"}}
                >
                    Electronically Sign
                </CancelButton>,
                <Button
                    className="no-hover"
                    key="modalButton2"
                    onClick={() => { store.setHowSignClosed(); store.createHRContact(); store.setShowApplicationUpload(); }}
                    style={{ minWidth: "250px" }}
                >
                    Upload Signed App
                </Button>
            ]}
        >
            <div>
                <Body1 style={{ textAlign: "center" }}>
                    {`You can electronically sign your application in 30 seconds or you can download, sign, and upload your application.`}
                </Body1>
            </div>
        </Modal>
    );
};

export default observer(SignModal);
