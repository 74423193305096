import styled, { css } from "styled-components/macro";

const BaseFont = css`
    font-family: "Roobert", "sans-serif";
`;

const TimesTitleFont = css`
    font-family: "Reckless", "Times";
`;

const TimesTitleBaseCSS = css`
    ${TimesTitleFont}
    color: ${(props) => props.theme.colors.textColor};
    line-height: 160%;
`;

const MuseoSansBaseCSS = css`
    ${BaseFont}
    color: ${(props) => props.theme.colors.textColor};
    line-height: 160%;
`;

const ArialBaseCSS = css`
    ${BaseFont}
    font-weight: normal;

    line-height: 160%;

    &.highlight-text {
        color: ${(props) => props.theme.colors.secondary};
    }

    &.line-through {
        text-decoration: line-through;
    }
`;

export { MuseoSansBaseCSS, BaseFont, ArialBaseCSS, TimesTitleBaseCSS };

export const H1 = styled.h1`
    ${MuseoSansBaseCSS}
    font-size: 28px;
    font-weight: 700;
`;

export const H2 = styled.h2`
    ${TimesTitleBaseCSS}
    font-size: 24px;
    font-weight: 700;
`;

export const H3 = styled.h3`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    font-weight: 500;
`;

export const H4 = styled.h4`
    ${MuseoSansBaseCSS}
    font-size: 18px;
    font-weight: 700;
`;

export const H5 = styled.h5`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    font-weight: 500;
    opacity: 0.6;
`;

export const H6 = styled.h6`
    ${MuseoSansBaseCSS}
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
`;

export const Body1 = styled.p`
    ${ArialBaseCSS}
    font-size: 16px;
    line-height: 180%;
`;

export const Body2 = styled.p`
    ${ArialBaseCSS}
    font-size: 14px;
`;

export const Body3 = styled.p`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    font-weight: 300;
`;

export const Body1Span = styled.span`
    ${ArialBaseCSS}
    font-size: 16px;
    line-height: 180%;
`;

export const TableText = styled.span`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    font-weight: 500;
`;

export const LinkText = styled.a`
    ${ArialBaseCSS}
    font-size: 16px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.primary};
`;

export const SmallLinkText = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 130%;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.primary};
`;

export const CaptionText = styled.span`
           ${MuseoSansBaseCSS}
           font-style: normal;
           font-weight: 500;
           font-size: 11px;
           line-height: 160%;
           letter-spacing: normal;
           text-transform: uppercase;
           color: ${(props) => props.theme.colors.darkGray};
           opacity: 0.4;
       `;

export const ChartText = styled.span`
    ${MuseoSansBaseCSS}
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
`;

export const BadgeText = styled.span`
    ${MuseoSansBaseCSS}
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700;
`;

export const MenuText = styled.span`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    color: ${(props) => props.theme.colors.textColor};
`;

export const SectionSelectorText = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    text-align: left;

    &.section-required.on-error {
        color: #eb0f21;
    }
`;

export const QuestionTitle = styled.span`
    ${ArialBaseCSS}
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => props.theme.colors.textColor};
    display: flex;
    align-items: center;
`;

export const ChartTitle = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.textColor};

    & > span.chart-title-highlight {
        background: ${(props) => props.theme.colors.third};
        border-radius: 10px;
        font-size: 12px;
        line-height: 130%;
        padding: 5px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #6e73ac;
        margin-left: 10px;
    }
`;

export const InsuranceLineBodyText = styled.p`
    ${ArialBaseCSS}
    font-size: 14px;
    color: rgba(34, 35, 46, 0.9);
`;

export const TextNotes = styled.p`
    ${ArialBaseCSS}
    font-size: 14px;
    color: rgba(34, 35, 46, 0.4);
`;

export const TableTitle = styled.span`
    ${MuseoSansBaseCSS}
    font-size: 12px;
    font-weight: bold;
    color: rgba(34, 35, 46, 0.4);
`;
