import React from "react";
import styled from "styled-components/macro";

import { H4, Body3 } from "components/typography";
import { Broker } from "CounterpartApiTypes";

const SectionContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & input,
    & select {
        border: none;
        padding: 0 !important;
        height: auto;
    }
`;

const SectionTitle = styled(H4 as any)`
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    margin: 0;
`;

const Row = styled.div`
    display: flex;
    grid-gap: 50px;

    @media (max-width: 900px) {
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Detail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const DetailTitle = styled.p`
    color: #22232e7f;
    font-size: 11px;
    margin-bottom: 2px;
    line-height: 18px;
`;

const Value = styled(Body3 as any)`
    color: #242221;
    font-weight: 500;
`;

type RenderContentProps = { data: Broker; };

const ProfileForm = ({ data }: RenderContentProps) => {
    //const { isSubmitting } = useFormikContext<Broker>();
    return (
        <>
            <SectionContainer style={{ alignItems: "start" }}>
                <SectionTitle style={{marginBottom: 20}}>{data?.brokerageName}</SectionTitle>
                <Row>
                    <Detail>
                        <DetailTitle>BROKERAGE NAME:</DetailTitle>
                        <Value>{data?.brokerage.name}</Value>
                    </Detail>
                </Row>
                {data && data.brokerageOffice ? (
                    <>
                        <Row>
                            <Detail>
                                <DetailTitle>BROKER ADDRESS:</DetailTitle>
                                <Value>{data?.brokerageOffice.address1}</Value>
                            </Detail>
                            <Detail>
                                <DetailTitle>SECONDARY ADDRESS:</DetailTitle>
                                <Value>{data?.brokerageOffice.address2 || "-"}</Value>
                            </Detail>
                        </Row>
                        <Row>
                            <Detail>
                                <DetailTitle>CITY:</DetailTitle>
                                <Value>{data?.brokerageOffice.city || "-"}</Value>
                            </Detail>
                            <Detail>
                                <DetailTitle>STATE:</DetailTitle>
                                <Value>{data?.brokerageOffice.state || "-"}</Value>
                            </Detail>
                            <Detail>
                                <DetailTitle>ZIPCODE:</DetailTitle>
                                <Value>{data?.brokerageOffice.zipcode || "-"}</Value>
                            </Detail>
                        </Row>
                    </>
                ) : (
                        <>
                            <Row>
                                <Detail>
                                    <DetailTitle>BROKER ADDRESS:</DetailTitle>
                                    <Value>{data?.brokerage.address1 || "-"}</Value>
                                </Detail>
                                <Detail>
                                    <DetailTitle>SECONDARY ADDRESS:</DetailTitle>
                                    <Value>{data?.brokerage.address2 || "-"}</Value>
                                </Detail>
                            </Row>
                            <Row>
                                <Detail>
                                    <DetailTitle>CITY:</DetailTitle>
                                    <Value>{data?.brokerage.city || "-"}</Value>
                                </Detail>
                                <Detail>
                                    <DetailTitle>STATE:</DetailTitle>
                                    <Value>{data?.brokerage.state || "-"}</Value>
                                </Detail>
                                <Detail>
                                    <DetailTitle>ZIPCODE:</DetailTitle>
                                    <Value>{data?.brokerage.zipcode || "-"}</Value>
                                </Detail>
                            </Row>
                        </>
                )}
            </SectionContainer>
        </>
    );
};

export default React.memo(ProfileForm);
