import React from "react";
import styled from "styled-components/macro";
import addIcon from "assets/add-icon.svg";
import { H6, MuseoSansBaseCSS } from "components/typography";

type AddAnotherProps = { buttonEnabled?: boolean; onClick: () => void };

const AddIcon = styled.img`
    margin-right: 10px;
`;

const AddAnotherText = styled(H6 as any)`
    ${MuseoSansBaseCSS}
    font-size: 12px;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.secondary};
    line-height: 20px;
`;

export const Container = styled.button`
    background: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    cursor: pointer;
    background-color: none;
    border: none;
    box-shadow: none;
    max-width: 160px;

    &:hover {
        opacity: 0.4;
    }
`;

const AddAnother: React.SFC<AddAnotherProps> = ({ buttonEnabled = true, onClick }) => {
    return (
        <Container
            style={{ paddingLeft: "0px" }}
            type="button"
            disabled={!buttonEnabled}
            onClick={onClick}
        >
            <AddIcon src={addIcon} alt="Add another" />
            <AddAnotherText as="span">Add Another</AddAnotherText>
        </Container>
    );
};

export default AddAnother;
