import React from "react";
import styled from "styled-components/macro";

const SwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bfcad6;
    transition: 0.4s;

    &.round {
        border-radius: 24px;
        &:before {
            border-radius: 50%;
        }
    }

    &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
`;

const SwitchInput = styled.input`
    &:checked + ${SwitchSlider} {
        background: ${(props) => props.theme.colors.secondary};
    }

    &:focus + ${SwitchSlider} {
        box-shadow: 0 0 1px ${(props) => props.theme.colors.secondary};
    }

    &:checked + ${SwitchSlider}:before {
        transform: translateX(12px);
    }
`;

const SwitchContainer = styled.label`
    position: relative;
    display: inline-block;
    width: 36px;
    height: 24px;

    & > input {
        display: none;
    }
`;

const SwitchButton: React.FC<{ checked: boolean; onClick: () => void }> = ({
    checked,
    onClick,
}) => {
    return (
        <SwitchContainer>
            <SwitchInput type="checkbox" checked={checked} onClick={onClick} readOnly />
            <SwitchSlider className="round" />
        </SwitchContainer>
    );
};

export default React.memo(SwitchButton);
