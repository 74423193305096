import React from "react";
import styled from "styled-components/macro";
import { H6, MuseoSansBaseCSS } from "components/typography";
import removeIcon from "assets/remove-icon.svg";

type RemoveAnotherProps = { indexOf: number; onClick: () => void };

const RemoveIcon = styled.img`
    margin-right: 10px;
`;
const AddAnotherText = styled(H6 as any)`
    ${MuseoSansBaseCSS}
    font-size: 12px;
    font-weight: 900;
    color: #d50f1f;
    line-height: 20px;

    @media (min-width: 801px) {
        display: none;
    }
`;

const Container = styled.button`
    ${MuseoSansBaseCSS}
    display: flex;
    position: absolute;
    right: -45px;
    flex-direction: column;
    justify-content: center;
    height: 54px;
    align-self: center;
    padding-left: 10px;
    background-color: none;
    border: none;
    box-shadow: none;
    background: none;
    border: none;
    color: #d50f1f;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 160%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    z-index: 999;
    bottom: 0;
    margin-bottom: 20px;

    @media (max-width: 800px) {
        flex-direction: row;
        right: 0;
        margin-bottom: 0;
        bottom: -54px;
    }
`;

const RemoveAnother: React.SFC<RemoveAnotherProps> = ({ indexOf, onClick }) => (
    <Container disabled={indexOf === 0} type="button" onClick={onClick}>
        <RemoveIcon src={removeIcon} alt="Add another" />
        <AddAnotherText as="span">remove</AddAnotherText>
    </Container>
);

export default RemoveAnother;
