import * as Sentry from "@sentry/browser";

const DEFAULT_SENTRY_DSN =
    "https://07341e53d4814f8a957bb1403f1935a3@o103428.ingest.sentry.io/5288705";

if (process.env.NODE_ENV === "production") {
    const SENTRY_DSN = process.env.REACT_SENTRY_DSN || DEFAULT_SENTRY_DSN;
    Sentry.init({
        dsn: SENTRY_DSN,
    });
}
