import { ValueType } from "./types";
import { assert } from "utils";

export enum LogicalOperatorEnum {
    // Joins query clauses with a logical AND returns true when both clauses return truthy values.
    $and = "$and",
    // Inverts the effect of the child expression
    $not = "$not",
    // Performs a logical NOR on both child's returns true, when both are true or both are false
    $nor = "$nor",
    // Joins query clauses with a logical OR returns true at least one clause if true
    $or = "$or",
    // Returns either the non-null result of the first expression or the result of the second expression
    // if the first expression results in a null result.
    $ifNull = "$ifNull",
}

type LogicalHandlerType = (args: ValueType) => ValueType;

export const LOGICAL_OPERATORS_HANDLERS: {
    [K in LogicalOperatorEnum]: LogicalHandlerType;
} = {
    $and: (args: ValueType) => {
        assert(Array.isArray(args));
        return args.every((exp) => !!exp);
    },
    $or: (args: ValueType) => {
        assert(Array.isArray(args));
        return args.some((exp) => !!exp);
    },
    $nor: (args: ValueType) => {
        assert(Array.isArray(args));
        return args.every((exp) => !!exp) || args.every((exp) => !exp);
    },
    $not: (args: ValueType) => {
        assert(!Array.isArray(args));
        return !args;
    },
    $ifNull: (args: ValueType) => {
        assert(Array.isArray(args) && args.length === 2);
        const [condition, val] = args;
        return condition === null ? val : condition;
    },
};
