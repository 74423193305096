import React from "react";
import { Redirect } from "react-router"
import { useLocation } from "react-router-dom";
import { verifyMagicLink } from "services/brokers"
import Loading from "components/common/Loading";
import styled from "styled-components/macro";

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 999999;
`;

async function verify(token?: string | undefined | null, next?: string | undefined | null) {
    if(!token) {
        return "/"
    }
    try {
        let result = await verifyMagicLink(token)
        if(result) {
            return next != null ? next : "/dashboard";
        } else{
            return "/"
        }
    } catch (error) {
        return "/"
    }
}

const MagicLinkToken: React.SFC<{}> = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const token = query.get('token');
    const next = query.get('next');

    const [location, setLocation] = React.useState("")

    React.useEffect(() => {
        setTimeout(() => {
            verify(token, next).then((redirectToUrl: string) => {
                setLocation(redirectToUrl)
            })
        }, 2000)
    }, [token, next])

    if (!token) return <Redirect to="/" />;

    if(location !== "") {
        return (
            <Redirect to={location} />
        )
    }

    return (
        <StyledLoaderContainer style={{ textAlign: "center" }}>
                <Loading message="" />
        </StyledLoaderContainer>
    )

}

export default MagicLinkToken;
