import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import styled from 'styled-components';

import {
    FormikInputField,
    Modal,
    Row,
    Button,
    SelectTypeAhead,
    Loading,
} from "../../../components/common";
import { Body1 } from "../../../components/typography";
import { sendRetailBrokerInvite } from "../../../services/application";
import { setRetailBroker, listAllRetailBrokers } from "../../../services/quotes";
import { toast } from "react-toastify";
import { assert, useApiGet } from "utils";

const StyledRow = styled(Row as any)`
    @media (max-width: 767px) {
        margin-bottom: 20px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;

        & > div {
            margin-right: 0 !important;
        }

        label, button {
            margin-top: 20px;
        }
    }
`;

const brokerInviteInitialValues: any = {
    email: "",
    firstName: "",
    brokerageName: "",
    lastName: "",
    sendEmail: false,
};

const quotesRetailBrokerInitialValues: any = {
    retailBrokerId: null,
};

const _addRetailBroker = (
    setModalShareOpen: any,
    reloadDataFunction: any,
    callback: any,
    quoteId: any,
) => async (
    values: typeof brokerInviteInitialValues,
    actions: FormikHelpers<typeof brokerInviteInitialValues>,
) => {
    try {
        actions.setSubmitting(true);
        const data = await sendRetailBrokerInvite({ ...values, quoteId: quoteId });
        actions.setValues(brokerInviteInitialValues);
        setModalShareOpen(false);
        toast.success("Retail Agent Added!");
        if (reloadDataFunction) {
            reloadDataFunction();
        }
        if (callback) {
            callback(data);
        }
    } catch (error) {
        console.log(error);
        actions.setSubmitting(false);
        toast.error("An error ocurred, agent not added!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

const _setRetailBroker = (
    setModalShareOpen: any,
    reloadDataFunction: any,
    callback: any,
    quoteId: any,
) => async (
    values: typeof quotesRetailBrokerInitialValues,
    actions: FormikHelpers<typeof quotesRetailBrokerInitialValues>,
) => {
    try {
        actions.setSubmitting(true);
        console.log(values);
        if (
            quoteId === null ||
            quoteId === "" ||
            values.retail_broker_id === null ||
            values.retail_broker_id === ""
        ) {
            toast.error("Please select a retailer for the Quote");
            actions.setSubmitting(false);
            return;
        }
        const data = await setRetailBroker({ ...values, quoteId: quoteId });
        const reset = quotesRetailBrokerInitialValues;
        actions.setValues(reset);
        setModalShareOpen(false);
        toast.success("Retail Agent Set!");
        if (reloadDataFunction) {
            reloadDataFunction();
        }
        if (callback) {
            callback(data);
        }
    } catch (error) {
        console.log(error);
        actions.setSubmitting(false);
        toast.error("An error ocurred, broker not sent!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

const AddEditModal: React.FC<{
    openSelectAgentPage?: boolean;
    openStatus?: boolean;
    openCloseFunction?: any;
    reloadDataFunction?: any;
    callback?: any;
    quote?: any;
}> = ({
    openSelectAgentPage = false,
    openStatus = false,
    openCloseFunction,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reloadDataFunction = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback = () => {},
    quote = null,
}) => {
    const _PAGE_SELECT_AGENT = "SELECT_AGENT";
    const _PAGE_ADD_AGENT = "ADD_AGENT";

    const [loading, data] = useApiGet(listAllRetailBrokers);
    const [agentID, setAgentID] = React.useState("");

    React.useEffect(() => {
        if (openStatus === false) {
            setCurrentPage(_PAGE_SELECT_AGENT);
        }
    }, [openStatus]);

    const [currentPage, setCurrentPage] = React.useState(
        openSelectAgentPage === true ? _PAGE_SELECT_AGENT : _PAGE_ADD_AGENT,
    );

    if (loading) {
        return (
            <Modal isOpen={true} closeable={true} title={""}>
                <div className="text-center">
                    <Loading />
                </div>
            </Modal>
        );
    }

    assert(data);

    const agentOptions = Object.fromEntries(data.map((c) => [c.id, c.fullName]));

    return (
        <Modal
            isOpen={openStatus}
            closeable={true}
            title={
                currentPage === _PAGE_SELECT_AGENT
                    ? "Assign Account to Retail Agent"
                    : "Add Retail Agent"
            }
            titlePosition="left"
            buttons={[]}
            buttonStyle="vertical"
            closeAction={() => {
                openCloseFunction(false);
            }}
            style={{ overflowY: "auto" }}
        >
            <div>
                {currentPage === _PAGE_ADD_AGENT && (
                    <div>
                        <Body1>
                            {`Better manage your book of business with Counterpart by adding retail
                            agents to your account. Once added, you can automatically track a retail
                            agent's submissions in your dashboard, along with seeing useful metrics.`}
                        </Body1>
                        <Formik
                            initialValues={brokerInviteInitialValues}
                            onSubmit={_addRetailBroker(
                                openCloseFunction,
                                reloadDataFunction,
                                callback,
                                quote,
                            )}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <StyledRow>
                                        <FormikInputField
                                            name="firstName"
                                            label="First Name"
                                            placeholder="e.g. John"
                                        />
                                        <FormikInputField
                                            name="lastName"
                                            label="Last Name"
                                            placeholder="e.g. Smith"
                                        />
                                    </StyledRow>
                                    <StyledRow>
                                        <FormikInputField
                                            name="email"
                                            label="Retail Agent Email"
                                            placeholder="e.g. john@company.com"
                                            type="email"
                                        />
                                        <FormikInputField
                                            name="brokerageName"
                                            label="Brokerage Name"
                                            placeholder="e.g. John Insurance Brokers LLC"
                                        />
                                    </StyledRow>
                                    <StyledRow>
                                        <Button
                                            disabled={isSubmitting}
                                            style={{
                                                height: 54,
                                            }}
                                            type="submit"
                                        >
                                            ADD AGENT
                                        </Button>
                                    </StyledRow>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
                {currentPage === _PAGE_SELECT_AGENT && quote && (
                    <div>
                        <Body1>
                            {`Select the Retail Broker that produced this account from the dropdown or click on the Add New Agent button in order to ensure that you can track submissions accurately. `}
                        </Body1>
                        <Formik
                            initialValues={quotesRetailBrokerInitialValues}
                            onSubmit={_setRetailBroker(
                                openCloseFunction,
                                reloadDataFunction,
                                callback,
                                quote,
                            )}
                        >
                            {({ isSubmitting, setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <SelectTypeAhead
                                            name="retailBrokerId"
                                            placeholder="Type to search retail agents"
                                            value={agentID}
                                            addEmptyOption
                                            options={agentOptions}
                                            required
                                            onChange={(ev: any) => {
                                                if (ev.length >= 1) {
                                                    setAgentID(ev[0]["id"]);
                                                    setFieldValue("retailBrokerId", ev[0]["id"]);
                                                } else {
                                                    setAgentID("");
                                                    setFieldValue("retailBrokerId", "");
                                                }
                                            }}
                                            label="Retail Agent"
                                        />
                                    </Row>
                                    <Row>
                                        <Button
                                            disabled={isSubmitting}
                                            style={{
                                                height: 54,
                                            }}
                                            type="submit"
                                        >
                                            ASSIGN TO THIS AGENT
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Button
                                            disabled={isSubmitting}
                                            style={{
                                                minHeight: 20,
                                                color: "#111da1",
                                                background: "none",
                                                border: "none",
                                            }}
                                            type="button"
                                            onClick={() => setCurrentPage(_PAGE_ADD_AGENT)}
                                        >
                                            ADD NEW AGENT
                                        </Button>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
            </div>
        </Modal>
    );
};

const AddEditRetailBrokerModal: React.FC<{
    openSelectAgentPage?: boolean;
    openStatus?: boolean;
    openCloseFunction?: any;
    reloadDataFunction?: any;
    callback?: any;
    quote?: any;
}> = ({
    openSelectAgentPage = false,
    openStatus = false,
    openCloseFunction,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reloadDataFunction = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback = () => {},
    quote = null,
}) => {
    if (openStatus) {
        return (
            <AddEditModal
                openSelectAgentPage={openSelectAgentPage}
                openStatus={openStatus}
                openCloseFunction={openCloseFunction}
                reloadDataFunction={reloadDataFunction}
                callback={callback}
                quote={quote}
            />
        );
    }
    return null;
};

export default React.memo(AddEditRetailBrokerModal);
