import React from "react";
import styled from "styled-components/macro";

import { Button } from "components/common";
import { BaseFont } from "components/typography";

import logo from "assets/counterpart_icon.svg";
import Mike from "assets/Mikes_avatar_in_colour.jpg";
import responsive from "services/responsive";
import { auth } from "services";

const StyledLogo = styled.img`
    width: 120px;
`;

const NoDataContainer = styled.div`
    ${BaseFont};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 30px;

    &.column {
        flex-direction: column;
    }

    &.padding {
        padding: 180px 0;
    }

    ${responsive.Mobile} &,
    ${responsive.Tablet} & {
        flex-direction: column;
        padding: 20px;
    }

    b {
        font-weight: bold;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 550px;
    padding: 0 40px;

    &.with-border {
        border-right: 1px solid #ecf1f7;
    }

    ${responsive.Mobile} &,
    ${responsive.Tablet} & {
        padding: 20px;
        border-right: none;
        text-align: center;

        @media (max-width: 480px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

const MikeImg = styled.img`
    height: 60px;
    width: auto;
    border-radius: 50%;
`;

const GetStartedButton = styled(Button as any)`
    & {
        padding-top: 13px;
        padding-bottom: 13px;
        width: 215px;
        max-width: 100%;
    }
`;

export const getShareApplicationUrl = () => {
    return `${window.location.protocol}//${
        window.location.host
    }/startapplication?b=${auth.getUserID()}`;
};

const NoDataComponent: React.FC<{
    simpleView?: boolean;
    buttonText?: string;
    message?:string;
    filterNoResults?: boolean;
    action?: (props?: any) => void;
}> = ({ simpleView, buttonText, message, filterNoResults, action }) => {
    if (filterNoResults && buttonText) {
        return (
            <NoDataContainer className="column padding">
                <StyledLogo src={logo} alt="Counterpart" />
                <span
                    style={{
                        fontSize: 28,
                        lineHeight: "120%",
                        fontWeight: 500,
                        marginTop: 50,
                        textAlign: "center",
                        maxWidth: 440,
                    }}
                >
                    {message}
                </span>
                <GetStartedButton
                    style={{ marginTop: 30 }}
                    onClick={(e:any) => {
                        if (action) {
                            action(e)
                        } else {
                            window.open(getShareApplicationUrl(), "_blank")
                        }
                    }}
                >
                    {buttonText}
                </GetStartedButton>
            </NoDataContainer>
        );
    }

    if (simpleView) {
        return (
            <NoDataContainer className="column padding">
                <span
                    style={{
                        fontSize: 18,
                        lineHeight: "160%",
                        fontWeight: "bold",
                        color: "#a5b2c0",
                        textAlign: "center",
                    }}
                >
                    No Data to Display
                </span>
                <span
                    style={{
                        fontSize: 14,
                        lineHeight: "160%",
                        fontWeight: "normal",
                        marginTop: 20,
                        color: "#a5b2c0",
                        maxWidth: 360,
                        textAlign: "center",
                    }}
                >
                    {message}
                </span>
            </NoDataContainer>
        );
    }

    return (
        <NoDataContainer>
            <ContentContainer className="with-border">
                <StyledLogo src={logo} alt="Counterpart" />
                <span
                    style={{
                        fontSize: 28,
                        lineHeight: "120%",
                        fontWeight: 500,
                        marginTop: 50,
                        textAlign: "center",
                    }}
                >
                    Welcome to Counterpart
                </span>
                <span
                    style={{
                        fontWeight: 300,
                        fontSize: 16,
                        lineHeight: "150%",
                        marginTop: 20,
                        textAlign: "center",
                    }}
                >
                    We are thrilled to help you and your clients get the <b>coverage</b> and{" "}
                    <b>support</b> that they need as a 21st century business.
                </span>
                <GetStartedButton
                    style={{ marginTop: 40 }}
                    onClick={() => window.open(getShareApplicationUrl(), "_blank")}
                >{`let's get started`}</GetStartedButton>
            </ContentContainer>
            <ContentContainer>
                <span style={{ fontWeight: 300, fontSize: 18, lineHeight: "150%" }}>
                    Click <b>Start App</b> in the top right hand corner to start sharing the
                    application with your agents after which you will be able to view their
                    submissions and account details in this dashboard.
                </span>
                <ContentContainer
                    style={{
                        padding: 30,
                        backgroundColor: "rgba(235, 240, 246, 0.5)",
                        marginTop: 40,
                    }}
                >
                    <span style={{ fontWeight: 300, fontSize: 18, lineHeight: "150%" }}>
                        {`Don't hesitate to contact us through the chat feature below if you have any
                        questions along the way. Cheers.`}
                    </span>
                    <div
                        style={{
                            display: "flex",
                            marginTop: 30,
                            justifyContent: "flex-start",
                            width: "100%",
                        }}
                    >
                        <MikeImg src={Mike} alt="Mike Levins" />
                        <div style={{ display: "flex", flexDirection: "column", marginLeft: 16 }}>
                            <span style={{ fontSize: 16, fontWeight: 500, lineHeight: "160%" }}>
                                Mike Levins
                            </span>
                            <span style={{ fontSize: 16, fontWeight: 300, lineHeight: "160%" }}>
                                Head of Insurance
                            </span>
                        </div>
                    </div>
                </ContentContainer>
            </ContentContainer>
        </NoDataContainer>
    );
};

export default NoDataComponent;
