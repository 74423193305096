import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components/macro";
import {
    FullPage,
    StackedPaper,
    StackedPaperPaddedContainer,
    Button,
    LinkButton,
    Modal,
} from "components/common";
import { CancelButton } from "components/common/Button";
import { ResponsiveContainer, SideApplicationContainer } from "components/common/styled";

import SidebarMenu from "./components/SidebarMenu";
import SidebarDocumentsMenu from "./components/SidebarDocumentsMenu";
// import PolicySideMenu from "./components/PolicySideMenu";
import TopMenu from "./components/TopMenu";
import PolicyTopMenu from "./components/PolicyTopMenu";
import AccountPageDetail from "./components/AccountPageDetail";
import QuoteFeedback from "./components/QuoteFeedback";
import HeaderDetails from "./components/HeaderDetails";
import PaymentsInfo from "./pages/PaymentsInfo";
import { customNavStyles } from "./components/HeaderDetails";
import { useAccountStore } from "./store";
import { Body2, Body3 } from "components/typography";
import { assert, getBaseURL } from "utils";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import { EffectiveDate, Subjectivities, LossPrevention } from "./pages";
// import { SubmitCTAContainer } from "features/Application/components/styled";
import { Page } from "components/common/styled";
import { Loading, Header } from "components/common";
import { OnePaperBackground } from "counterpart-theme";
// import { canBindCoverages } from "./utils";
import { BlueCheckMarkIcon, RedXIcon } from "./components/Icons";
import { QuoteStatusEnum } from "definitions/enums";

import { DownloadPdfButton } from "./components/styled";
import pdfIcon from "assets/pdf-nopaper-icon.svg";
import CommonContainer from "./pages/CommonContainer";
import { Helmet } from "react-helmet";
// import { auth } from "services";

// const StyledBody3 = styled(Body3 as any)`
//     @media (max-width: 480px) {
//         display: none;
//     }
// `;

const StyledStackedPaper = styled(StackedPaper as any)`
    width: 100%;
    padding: 40px 20px;
    box-shadow: none;

    @media (max-width: 1024px) {
        border-radius: 4px 4px 0 0;
    }

    @media (max-width: 768px) {
        border-radius: 0;
    }
`;

const StyledStackedPaperPaddedContainer = styled(StackedPaperPaddedContainer as any)`
    padding: 0 20px;

    @media (max-width: 767px) {
        padding: 20px 0;
    }
`;

const HideOnMobile = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;

const ShowOnMobile = styled.div`
    @media (min-width: 767px) {
        display: none;
    }
`;

const StyledSideApplicationContainer = styled(SideApplicationContainer as any)`
    @media (max-width: 1024px) {
        padding: 0 20px 20px 20px;
    }

    @media (max-width: 768px) {
        padding: 0;
    }
`;

const AccountPage: React.FC<{}> = observer(() => {
    const [submitModal, setSubmitModal] = useState(false);
    const match = useRouteMatch<{ applicationID: string, publicSecurityToken: string, appSecurityToken: string, bindQuoteToken: string, }>();
    const { quote, loading, bindSelectedCoverage, appSecurityToken, publicSecurityToken } = useAccountStore();
    assert(quote);

    const getDownloadUrl = () => {
        const BASE_URL = getBaseURL();
        return `${BASE_URL}/api/quotes/${quote.id}/download`;
    };

    const pageWidth = 1350;

    if (loading) {
        return (
            <Page>
                <Header />
                <OnePaperBackground className="text-center" style={{ width: "80%" }}>
                    <div className="py-5">
                        <Loading />
                    </div>
                </OnePaperBackground>
            </Page>
        );
    }

    return (
        <FullPage
            headerStyle={{ maxWidth: pageWidth, paddingRight: quote.showSidebar && 10 }}
            withSection={true}
            stretchContainer={true}
            headerChild={<HeaderDetails />}
            headerProps={{ visibleButtons: false }}
            headerNavStyles={customNavStyles}
        >
            <>
                <Helmet>
                    <title>
                        Counterpart | {quote.statusText} {quote.company.name}
                    </title>
                </Helmet>
                <ResponsiveContainer style={{ maxWidth: pageWidth, width: "100%" }}>
                    <Modal
                        isOpen={submitModal}
                        closeable={true}
                        closeAction={() => setSubmitModal(false)}
                        title="Are you sure you want to bind?"
                        buttonStyle="vertical"
                        buttons={[
                            <Button
                                key="modalButton1"
                                onClick={async () => {
                                    setSubmitModal(false);
                                    await bindSelectedCoverage();
                                }}
                                style={{ minWidth: "250px" }}
                            >
                                Bind Coverage
                            </Button>,
                            <CancelButton
                                className="no-hover"
                                key="modalButton2"
                                onClick={() => setSubmitModal(false)}
                                style={{ minWidth: "250px" }}
                            >
                                Return to Quote
                            </CancelButton>,
                        ]}
                    >
                        <p style={{ fontStyle: "italic" }}>
                            {`Once you have bound an application the policy will be issued and your account will be charged the premium. Do you wish to bind this coverage?`}
                        </p>
                    </Modal>

                    <StyledStackedPaperPaddedContainer>
                        {quote.status !== QuoteStatusEnum.PENDING && (
                            <>
                                {quote.finalized ? (
                                    <PolicyTopMenu />
                                ) : (
                                    quote.status !== QuoteStatusEnum.EXPIRED &&
                                    quote.status !== QuoteStatusEnum.DECLINED && <TopMenu />
                                )}
                                {window.innerWidth <= 767 && (
                                    <ShowOnMobile>
                                        <SidebarMenu />
                                    </ShowOnMobile>
                                )}
                            </>
                        )}
                        {quote.status === QuoteStatusEnum.EXPIRED &&
                        (quote.feedback === null || quote.feedback.length === 0) ? (
                            <QuoteFeedback quote={quote} />
                        ) : (
                            <StyledStackedPaper>
                                {quote.showSidebar ? (
                                    <>
                                        {quote.finalized && (
                                            <Switch>
                                                <Route
                                                    exact
                                                    path={`${match.path}/overview`}
                                                    component={AccountPageDetail}
                                                />
                                                {quote.hasEPLICoverage && (
                                                    <Route
                                                        exact
                                                        path={`${match.path}/advisory-services`}
                                                        component={LossPrevention}
                                                    />
                                                )}
                                                <Route
                                                    exact
                                                    path={`${match.path}/payment`}
                                                    component={PaymentsInfo}
                                                />
                                                <Redirect to={`${match.url}/overview`} />
                                            </Switch>
                                        )}
                                        {!quote.finalized && (
                                            <Switch>
                                                <Route
                                                    exact
                                                    path={`${match.path}/overview`}
                                                    component={AccountPageDetail}
                                                />
                                                <Route
                                                    exact
                                                    path={`${match.path}/effective-date`}
                                                    component={EffectiveDate}
                                                />
                                                <Route
                                                    exact
                                                    path={`${match.path}/sign-application`}
                                                    render={() => (
                                                        <Redirect
                                                            to={`${match.url}/subjectivities`}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={`${match.path}/subjectivities`}
                                                    render={() => <Subjectivities quote={quote} />}
                                                />
                                                <Route
                                                    exact
                                                    path={`${match.path}/policy-overview`}
                                                    component={AccountPageDetail}
                                                />
                                                <Redirect to={`${match.url}/overview`} />
                                            </Switch>
                                        )}
                                    </>
                                ) : (
                                    <CommonContainer
                                        title={quote.statusText}
                                        additionalInfo={{
                                            title: "Application number",
                                            text: quote.policyNumber,
                                        }}
                                        borderBottom={true}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: 30,
                                            }}
                                        >
                                            {quote.status === QuoteStatusEnum.PENDING && (
                                                <BlueCheckMarkIcon />
                                            )}
                                            {quote.status === QuoteStatusEnum.DECLINED && (
                                                <RedXIcon />
                                            )}
                                            {quote.status === QuoteStatusEnum.EXPIRED && (
                                                <RedXIcon />
                                            )}
                                            <Body3 style={{ textAlign: "center", marginTop: 30 }}>
                                                {quote.descriptionText}
                                            </Body3>
                                            {quote.status === QuoteStatusEnum.PENDING && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "space-between",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    { appSecurityToken && publicSecurityToken && (
                                                    <DownloadPdfButton
                                                        style={{ minWidth: 280 }}
                                                        href={getDownloadUrl() + "/application"}
                                                        target="_blank"
                                                        appSecurityToken={appSecurityToken}
                                                        publicSecurityToken={publicSecurityToken}
                                                    >
                                                        <img src={pdfIcon} alt="Application" />
                                                        <Body2 as="span">Application</Body2>
                                                        <span
                                                            style={{
                                                                color: "#BFCAD6",
                                                                fontSize: "10px",
                                                                textAlign: "right",
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            DOWNLOAD
                                                        </span>
                                                    </DownloadPdfButton>
                                                    )}
                                                </div>
                                            )}
                                            {quote.status === QuoteStatusEnum.DECLINED && (
                                                <LinkButton to="/">Back to Dashboard</LinkButton>
                                            )}
                                            {quote.status === QuoteStatusEnum.EXPIRED && (
                                                <LinkButton to="/">Back to Dashboard</LinkButton>
                                            )}
                                        </div>
                                    </CommonContainer>
                                )}
                            </StyledStackedPaper>
                        )}
                    </StyledStackedPaperPaddedContainer>
                    {quote.showSidebar && (
                        <StyledSideApplicationContainer>
                            {/* {!quote.finalized && ( */}
                            <>
                                {window.innerWidth >= 768 && (
                                    <HideOnMobile>
                                        <SidebarMenu publicSecurityToken={publicSecurityToken} appSecurityToken={appSecurityToken} bindQuoteToken={match.params.bindQuoteToken} />
                                    </HideOnMobile>
                                )}
                                <SidebarDocumentsMenu publicSecurityToken={publicSecurityToken} appSecurityToken={appSecurityToken} />
                            </>
                            {/* )} */}
                        </StyledSideApplicationContainer>
                    )}
                </ResponsiveContainer>
            </>
        </FullPage>
    );
});

export default AccountPage;
