import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

import ArrowRight from "assets/arrow-right-blue.svg";
import { BaseFont } from "components/typography";

const baseButton = css`
    ${BaseFont}
    min-width: 120px;
    min-height: 40px;
    padding: 10px 40px;
    letter-spacing: 0.05em;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    transition: ease-in-out all 0.2s;
    color: #fff;
    border: none;
    margin: none;
    width: auto;
    @media (max-width: 800px) {
        padding: 16px 10px;
        min-width: 100px;
        min-height: 20px;
    }
`;

export const Button = styled.button<{ block?: boolean }>`
    -webkit-button,
    & {
        ${baseButton}
        outline: none !important;
        border: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius: 100px;
        background: ${({ theme }) => theme.colors.primary};
        display: ${(props) => (props.block ? "block" : "inline-block")};
        width: ${(props) => (props.block ? "100%" : "auto")};
        text-align: center;
        color: ${(props) => props.theme.colors.primaryFrontColor};

        &:hover:not(:disabled) {
            background-color: ${({ theme }) => theme.colors.darkGray};
            border: 1px solid ${({ theme }) => theme.colors.darkGray};
            color: #fff;
            text-decoration: none;
            background-color: ${({ theme }) => theme.colors.darkGray};
        }

        &:disabled {
            background-color: #fff;
            color: ${({ theme }) => theme.colors.gray};
            border: 1px solid ${({ theme }) => theme.colors.gray};
            cursor: not-allowed;
        }

        &.light-blue {
            background: ${(props) => props.theme.colors.secondary};
            text-align: center;
            &:hover {
                opacity: 0.4;
                text-decoration: none;
            }
            padding: 17px 31px;
        }

        &.link-arrow {
            background: none;
            color: ${(props) => props.theme.colors.primary};
            padding: 0;
            margin: 0;
            text-align: left;
            min-width: 200px;
            &:after {
                position: absolute;
                content: url("${ArrowRight}");
                margin-left: 40px;
                margin-top: -5px;
            }
        }
    }
`;

export const EnableDisableCoverageButton = styled(Button as any)`
    z-index: 998;
    color: ${(props) => props.theme.colors.primary};
    background-color: #ffffff;
    border: 1px solid ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    & {
        font-size: 12px;
    }

    &:hover {
        opacity: 0.4;
        color: #fff;
    }

    &.disabled {
        color: ${(props) => props.theme.colors.primaryFrontColor};
        background: ${(props) => props.theme.colors.primary};

        &:hover {
            background: ${(props) => props.theme.colors.primary};
        }
    }
`;

export const CancelButton = styled(Button as any)`
    & {
        border: 1px solid ${({ theme }) => theme.colors.gray};
        background: #ffffff;
        color: ${(props) => props.theme.colors.primary};
        text-align: center;

        &:hover,
        &.no-hover {
            color: ${(props) => props.theme.colors.primary} !important;
            text-decoration: none;
            background: #ffffff !important;
            border: 1px solid ${(props) => props.theme.colors.primary};
        }

        &:focus {
            color: ${(props) => props.theme.colors.primaryFrontColor};
            background: ${({ theme }) => theme.colors.primary};
            border: 1px solid ${(props) => props.theme.colors.primary};
        }

        &.with-border {
            border: 1px solid ${(props) => props.theme.colors.primary};
        }
    }
`;

export const LightBlueButton = styled(Button as any)``;

export const WhiteButton = styled.button`
    ${baseButton}
    background: #ffffff;
    padding: 12px 15px 12px 15px;
    box-shadow: 0px 12px 18px rgba(228, 235, 244, 0.75);
    padding-bottom: 15px;

    &:hover {
        text-decoration: none;
    }
`;

export const LinkButton = Button.withComponent(Link);

export const SmallButton = styled(Button as any)`
    padding: 12px 20px;
`;

export default Button;
