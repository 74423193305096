import React from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { getQuoteAnswerRenewalContext } from "services/quotes";
import { toast } from "react-toastify";

import {
    copyToClipboard,
    formatCurrency,
    formatDate,
    parseQueryString,
    useApiGet,
} from "../../utils";

// import { downloadPDF } from "../../services/generated/quotes";

import { H2, Body3 } from "components/typography";

import {
    Button,
    CancelButton,
    Loading,
    Page,
    SectionContainer,
    Header,
    StackedPaperPaddedContainer,
    StackedPaper,
    BlueDivider,
    Footer,
} from "components/common";

interface RenewalQuestions {
    rating_engine_key: string;
    title: string;
    number?: number | null;
    answerType: "number" | "currency" | "string" | "date" | "radio" | "text" | "select";
    originalAnswer: string | null;
    renewalAnswer: string | null;
    indented: boolean;
}

interface RenewalSection {
    name: string;
    questions: RenewalQuestions[];
}

interface RenewalData {
    original_quote_id: string;
    renewal_quote_id: string;
    sections: RenewalSection[];
}

const ShowOnMobile = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 767px) {
        display: none;
    }
`;

const Cell = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 30px;
    flex-direction: column;

    p {
        margin-bottom: 0;
    }

    .blue-values {
        color: #111da1;
        font-weight: bold;
    }

    &.column-cell {
        @media (max-width: 480px) {
            flex-direction: column !important;
            align-items: flex-start !important;

            &:last-child {
                margin-top: 15px;
            }
        }
    }

    @media (max-width: 768px) {
        grid-column: 1 / 3;

        ${ShowOnMobile} {
            align-self: flex-start;
            margin-top: 3px;
        }

        span:not(.number) {
            text-align: right;
        }
    }

    @media (max-width: 480px) {
        padding: 0;
    }

    @media (max-width: 768px) {
        background: none;
    }

    &:first-child {
        @media (min-width: 769px) {
            padding: 12px 30px 12px 20px;
        }

        @media (max-width: 768px) {
            padding-bottom: 16px;
        }
        &:before {
            left: 20px !important;
        }
    }

    &:not(:first-child) {
        border-left: 1px solid #bfcad6;
        border-right: 1px solid #bfcad6;
        align-items: center;
        text-align: center;

        @media (max-width: 768px) {
            border: none;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &:not(:last-child) {
        @media (max-width: 768px) {
            &:before {
                display: none;
            }
        }
    }

    &:last-child {
        @media (min-width: 769px) {
            padding: 12px 20px;
        }

        @media (max-width: 768px) {
            &:not(:empty) {
                padding-bottom: 28px;
            }
        }
    }
    &:not(.no-border) {
        &:before {
            content: "";
            height: 1px;
            width: calc(100% - 20px);
            left: 10px;
            bottom: 0;
            position: absolute;
            background-color: #ebf0f6;

            @media (max-width: 768px) {
                width: 100%;
                left: 0;
            }
        }
    }
`;

const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 0;
    }

    ${H2} {
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
`;

const StyledBlueDivider = styled(BlueDivider as any)`
    margin: 36px auto 32px 20px !important;

    @media (max-width: 768px) {
        margin: 36px auto 32px 0 !important;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;

    @media (max-width: 360px) {
        width: 100%;
    }

    ${Button} {
        text-transform: uppercase;

        min-width: 180px;
        min-height: 40px;
        padding: 10px 20px;

        @media (max-width: 768px) {
            margin-bottom: 30px;
            min-width: 150px;
        }

        @media (max-width: 480px) {
            min-width: 110px;
            padding: 10px;
        }

        &:first-child {
            margin-right: 10px;
        }
    }
`;

const DateSpan = styled.span`
    font-size: 12px;
    color: #22232e66;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
`;

const InfoTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding-right: 18px;

    @media (max-width: 768px) {
        padding-right: 0;
    }
`;

const Row = styled.div<{ indented?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 270px 270px;
    grid-column-gap: 20px;

    ${({ indented }) =>
        indented &&
        `
        margin-left: 50px;

        @media (max-width: 992px) {
            margin-left: 30px;
        }

        @media (max-width: 768px) {
            margin-left: 0;
        }
    `}

    @media (max-width: 992px) {
        grid-template-columns: 1fr 200px 200px;
    }

    @media (max-width: 768px) {
        margin-bottom: 24px;
    }

    &.section-title {
        background: #f5f7fa;
        width: calc(100% + 18px);

        @media (max-width: 768px) {
            width: auto;
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 10px !important;
            grid-template-columns: auto;
        }

        @media (max-width: 480px) {
            padding-left: 0;
        }

        ${Cell} {
            padding-top: 16px;
            margin-top: -16px;
            padding: 26px 10px 13px 10px;

            &:not(:first-child) {
                left: -18px;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            &:first-child {
                color: #111da1;
                padding-left: 20px;
                font-size: 18px;

                @media (max-width: 480px) {
                    padding-left: 10px;
                }

                &:before {
                    display: none;
                }
            }

            &:not(:first-child) {
                &:after {
                    content: "";
                    position: absolute;
                    top: 16px;
                    left: 0;
                    height: calc(100% - 16px);
                    width: calc(100% + 2px);
                    margin-left: -1px;
                    background-color: #f5f7fa;
                }
            }

            ${DateSpan} {
                position: relative;
                z-index: 1;
            }
        }
    }

    &:not(.section-title) {
        @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(3, auto);
        }
    }

    &.no-border-bottom {
        ${Cell} {
            &:before {
                display: none;
            }
        }
    }

    &.first-row {
        ${Cell} {
            @media (min-width: 769px) {
                padding-top: 40px;
            }
        }
    }

    &.last-row {
        @media (max-width: 768px) {
            margin-bottom: 50px;
        }

        ${Cell} {
            padding-bottom: 60px;

            @media (max-width: 768px) {
                padding-bottom: 12px;
            }
        }
    }

    &:first-child {
        ${Cell} {
            &:not(:first-child) {
                border-top: 1px solid #bfcad6;
                border-radius: 8px;
            }
        }
    }

    &:last-child {
        ${Cell} {
            &:before {
                display: none;
            }

            &:not(:first-child) {
                @media (min-width: 768px) {
                    border-bottom: 1px solid #bfcad6;
                    border-radius: 0 0 8px 8px;
                }
            }
        }
    }
`;

const StyledStackedPaper = styled(StackedPaper as any)`
    padding: 45px 20px;
`;

const getCurrency = (price: number) => (price ? formatCurrency(price) : "-");

const getFormattedValue = (value: string | null, type: string): string => {
    if (value === null) {
        return " ";
    } else if (!value) {
        return "-";
    }

    switch (type) {
        case "number":
            return value;
        case "currency":
            return getCurrency(+value);
        case "string":
            return value;
        case "select":
            return value;
        case "radio":
            return value;
        case "text":
            return value;
        case "date":
            return formatDate(value) || "";
    }

    return "";
};

const getRows = (questions: RenewalQuestions[]) => {
    return questions
        .map((question: RenewalQuestions) => {
            return `${question.title}:
      expiring policy: ${getFormattedValue(question.originalAnswer, question.answerType)}
      renewal app: ${getFormattedValue(question.renewalAnswer, question.answerType)}

      `;
        })
        .join("");
};

function getSummary(data: RenewalData): string {
    // return ""
    // const parentRange = data.components[0].dateRanges.parent;
    // const renewalRange = data.components[0].dateRanges.renewal;
    let summary = `Renewal Application Highlights
  `;
    data.sections.forEach((section: RenewalSection) => {
        summary += `\r\n${section.name}

    ${
        Object.keys(section.questions).length
            ? `Limit:
      ${getRows(section.questions)}
    `
            : ""
    }


`;
    });
    return summary;
}

const RenewalApplicationHighlights = () => {
    const { quoteId } = useParams<{ quoteId: string }>();
    const { brokerId, token } = parseQueryString();

    const [loading, data, error] = useApiGet(
        getQuoteAnswerRenewalContext,
        brokerId,
        token,
        quoteId,
    );

    // const getPDF = async () => {
    //     // Another way to download pdf. It'll open it in browser;
    //     // const res = await downloadPDF(quoteId, 'renewal');
    //     // let blob = new Blob([res], {type: "application/pdf"});
    //     // let file = window.URL.createObjectURL(blob);
    //     // window.location.assign(file);

    //     const res = await downloadPDF(quoteId, "renewal");
    //     const blob = new Blob([res], { type: "application/pdf" });
    //     const blobUrl = URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = blobUrl;
    //     link.download = "renewal.pdf";
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    // };

    if (loading) {
        return (
            <Page>
                <StackedPaperPaddedContainer style={{ maxWidth: 1160 }}>
                    <SectionContainer>
                        <StackedPaper style={{ marginTop: 100 }}>
                            <div style={{ gridArea: "col3", textAlign: "center" }}>
                                <Loading />
                            </div>
                        </StackedPaper>
                    </SectionContainer>
                </StackedPaperPaddedContainer>
            </Page>
        );
    }

    if (error) return <Redirect to="/" />;

    return (
        <Page>
            <Helmet>
                <title>Counterpart | Renewal Application HIghlights</title>
            </Helmet>
            <Header>
                <Body3
                    style={{
                        marginTop: "20px",
                        marginLeft: 20,
                        paddingLeft: 20,
                        borderLeftColor: "#bfcad6",
                        borderLeftWidth: 1,
                        borderLeftStyle: "solid",
                        maxWidth: "400px",
                    }}
                >
                    {/* <strong
                    style={{ fontWeight: "bold" }}
                >Some company name</strong> {`${quote.statusText} ${quote.company.name}`} */}
                </Body3>
            </Header>
            <StackedPaperPaddedContainer style={{ maxWidth: 1160 }}>
                <StyledStackedPaper>
                    <Heading>
                        <H2>Renewal Application Highlights</H2>
                        <ButtonsContainer>
                            <CancelButton
                                onClick={() => {
                                    copyToClipboard(getSummary(data));
                                    toast.success("Content Copied");
                                }}
                            >
                                Copy Content
                            </CancelButton>
                            {/* <Button onClick={getPDF}>Download PDF</Button> */}
                        </ButtonsContainer>
                    </Heading>
                    <StyledBlueDivider />
                    <InfoTable>
                        {data?.sections.map((component: RenewalSection, componentIndex: number) => {
                            return (
                                <React.Fragment key={`component_#${componentIndex}`}>
                                    <Row className="section-title">
                                        <Cell>
                                            <strong>{component.name}</strong>
                                        </Cell>
                                        <Cell>
                                            <DateSpan>expiring policy</DateSpan>
                                        </Cell>
                                        <Cell>
                                            <DateSpan>renewal app</DateSpan>
                                        </Cell>
                                    </Row>

                                    {component.questions.length && (
                                        <React.Fragment key={`limits_data_row_#${componentIndex}`}>
                                            {component.questions.map(
                                                (question: RenewalQuestions, index, arr: any) => {
                                                    const areValuesChanged =
                                                        question.originalAnswer !==
                                                        question.renewalAnswer;
                                                    let isNewLine = false;

                                                    if (
                                                        (question.originalAnswer ||
                                                            question.renewalAnswer) &&
                                                        question.answerType !== "currency"
                                                    ) {
                                                        isNewLine =
                                                            question?.originalAnswer?.length! >
                                                                15 ||
                                                            question?.renewalAnswer?.length! > 15;
                                                    }
                                                    return (
                                                        <Row
                                                            className={`${index ===
                                                                arr.length - 1 &&
                                                                "no-border-bottom last-row"} ${index ===
                                                                0 && " first-row"}`}
                                                            key={`limits_row_#${index}`}
                                                            indented={question.indented}
                                                        >
                                                            <Cell>
                                                                <p>
                                                                    {question.number && (
                                                                        <span
                                                                            className="number"
                                                                            style={{
                                                                                color: "#BFCAD6",
                                                                            }}
                                                                        >
                                                                            {question.number}.{" "}
                                                                        </span>
                                                                    )}
                                                                    {question.title}
                                                                </p>
                                                            </Cell>
                                                            <Cell
                                                                className={`${isNewLine &&
                                                                    "column-cell"}`}
                                                            >
                                                                {question.originalAnswer !==
                                                                    null && (
                                                                    <>
                                                                        <ShowOnMobile>
                                                                            <DateSpan>
                                                                                Expiring Policy
                                                                            </DateSpan>
                                                                        </ShowOnMobile>
                                                                        <span
                                                                            className={`${areValuesChanged &&
                                                                                "blue-values"}`}
                                                                        >
                                                                            {getFormattedValue(
                                                                                question.originalAnswer,
                                                                                question.answerType,
                                                                            )}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Cell>
                                                            <Cell
                                                                className={`${isNewLine &&
                                                                    "column-cell"}`}
                                                            >
                                                                {question.renewalAnswer !==
                                                                    null && (
                                                                    <>
                                                                        <ShowOnMobile>
                                                                            <DateSpan>
                                                                                Renewal App
                                                                            </DateSpan>
                                                                        </ShowOnMobile>
                                                                        <span
                                                                            className={`${areValuesChanged &&
                                                                                "blue-values"}`}
                                                                        >
                                                                            {getFormattedValue(
                                                                                question.renewalAnswer,
                                                                                question.answerType,
                                                                            )}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Cell>
                                                        </Row>
                                                    );
                                                },
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </InfoTable>
                </StyledStackedPaper>
            </StackedPaperPaddedContainer>
            <Footer />
        </Page>
    );
};

export default RenewalApplicationHighlights;
