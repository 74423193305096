import React, { KeyboardEvent, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { CustomDataTable, RadioCheckButton } from "components/common";
import { MuseoSansBaseCSS, ArialBaseCSS } from "components/typography";

import { formatCurrency, formatDate, onTextChange } from "utils";

import Arrow from "assets/arrow-down.svg";
import Exit from "assets/exit-icon.svg";
import { useHistory } from "react-router";

import * as apiTypes from "CounterpartApiTypes";
import { useLocalStore, observer } from "mobx-react-lite";
import { createDashboardTableStore, IDashboardTableStore } from "./store";
import { getSnapshot } from "mobx-state-tree";
import { clientTableColumns, conditionalRowStyles, getTypeAlert } from "./DashboardTable/columns";
import { DashboardQuote } from "services/brokers";
import rootStore from "services/store";
import {
    ClearButton,
    FilterContainer,
    FilterDropdown,
    RowContent,
    RowDetail,
    RowDetailContainer,
    RowLabel,
    SearchButton,
    SectionContainer,
    SectionHeader,
    SectionInput,
    SectionInputContainer,
    SectionTitle,
    HeadingRowDetail,
} from "./styled";

import NoDataComponent from "./NoDataComponent";
import styled from "styled-components/macro";
import responsive from "services/responsive";
import AddEditRetailBrokerModal from "./helpers/AddEditRetailBrokerModal";

import { Helmet } from "react-helmet";

interface Props {
    dashboardDataObject: apiTypes.BrokerDashboard;
}

type FilterKeyType = "application" | "quote" | "policy" | "decline" | "expire" | "renewal";

const FILTERS: Record<FilterKeyType, string> = {
    application: "Open Applications",
    quote: "Open Quotes",
    policy: "Bound Policies",
    decline: "Declined Applications",
    expire: "Expired Quotes",
    renewal: "Renewal",
} as const;

const FILTER_KEYS = ["policy", "quote", "application", "decline", "expire", "renewal"] as const;

type HeaderProps = {
    store: IDashboardTableStore;
    data: DashboardQuote[];
    dashboardDataObject: apiTypes.BrokerDashboard;
};

const SectionSearchContainer = styled.div`
    display: flex;
    position: relative;

    ${responsive.Tablet} & {
        width: 50%;
    }

    ${SectionInput} {
        @media (max-width: 700px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
`;

const StyledSectionHeader = styled(SectionHeader as any)`
    @media (max-width: 700px) {
        max-width: 700px;
        ${SectionInputContainer} {
            max-width: 700px;
        }
    }
`;

const TableWrapper = styled.div`
    border-radius: 10px;
    overflow-x: auto;

    & > div > nav {
        justify-content: flex-end;
        position: sticky;
        left: 0;
    }
    & > div > div > div[role="table"] {
        min-height: 40vh;
    }
`;

const ClearFilterButton = styled.button`
    padding: 2px 19px 2px 8px;
    border-radius: 10px;
    background-color: #ebf0f6;
    position: relative;
    border: none;
    font-size: 12px;
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
    span {
        ${MuseoSansBaseCSS};
        line-height: 15px;
        color: #22232e99;
    }
    &:after {
        content: "+";
        position: absolute;
        right: 4px;
        top: -3px;
        color: #6e73ac;
        transform: rotate(45deg);
        font-size: 18px;
        font-weight: bold;
    }
`;

const StyledButton = styled.button`
    ${ArialBaseCSS}
    font-size: 14px;
    line-height: 24px;
    border: none;
    background: none;
    text-align: left;
    padding-left: 0;
    @media (max-width: 960px) {
        color: ${(props) => props.theme.colors.secondary};
        font-weight: bold;
    }
`;

const StyledAlertText = styled.span`
    ${ArialBaseCSS}
    font-style: normal;
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
    line-height: 130%;
    text-align: right;
    color: ${(props) => props.theme.colors.accentColor};
`;

const SectionHeaderComponent: React.FC<HeaderProps> = observer(({ store }) => {
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (store.showFilters) {
                store.toggleShowFilters();
            }
        };

        const handleBodyClick = (e: any) => {
            if (
                store.showFilters &&
                !dropdownContainerRef.current?.contains(e.target) &&
                !dropdownRef.current?.contains(e.target)
            ) {
                store.toggleShowFilters();
            }
        };

        window.addEventListener("scroll", handleScroll);
        document.body.addEventListener("click", handleBodyClick);
        return () => {
            document.body.removeEventListener("click", handleBodyClick);
            window.removeEventListener("scroll", handleScroll);
        };
    }, [store]);

    const handleClearFilters = (e: any) => {
        e.stopPropagation();
        store.clearFilters();
    };

    return (
        <StyledSectionHeader>
            <Helmet>
                <title>Counterpart | Dashboard</title>
            </Helmet>
            <SectionTitle>Accounts</SectionTitle>
            <SectionInputContainer>
                <SectionSearchContainer>
                    <SearchButton onClick={() => store.search()} />
                    <SectionInput
                        name="search_client"
                        placeholder="Search by Company"
                        value={store.query}
                        onKeyDown={(e: KeyboardEvent) => {
                            if (e.key === "Enter") store.search();
                        }}
                        onChange={onTextChange(store.changeQuery)}
                    />
                    {store.query?.length > 0 && (
                        <ClearButton onClick={() => store.changeQuery("")} />
                    )}
                </SectionSearchContainer>
                <FilterDropdown ref={dropdownRef} onClick={store.toggleShowFilters}>
                    <div style={{ marginLeft: "5px", marginRight: "10px" }}>
                        {store.types.length > 0 && store.types.split(",").length > 0 ? (
                            <>
                                Filtered By:
                                <ClearFilterButton onClick={handleClearFilters}>
                                    <span>{store.types.split(",").length}</span>
                                </ClearFilterButton>
                            </>
                        ) : (
                            <>Filter by:</>
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#ebf0f6",
                            borderRadius: 4,
                            width: 22,
                            height: 22,
                            marginRight: 15,
                        }}
                    >
                        <img src={store.showFilters ? Exit : Arrow} width="10px" alt="expand" />
                    </div>
                </FilterDropdown>
                <FilterContainer
                    ref={dropdownContainerRef}
                    className={store.showFilters ? "expanded" : ""}
                >
                    {FILTER_KEYS.map((key: FilterKeyType) => (
                        <div key={key as string} className="button-container">
                            <RadioCheckButton
                                checkboxStyle={{ marginLeft: "0px" }}
                                className="single-check"
                                type="checkbox"
                                name={`check_${key}`}
                                label={`${FILTERS[key]}`}
                                checked={store.types.split(",").includes(key)}
                                onClick={() => {
                                    store.toggleType(key);
                                }}
                                value=""
                            />
                        </div>
                    ))}
                </FilterContainer>
            </SectionInputContainer>
        </StyledSectionHeader>
    );
});

const RowDetailComponent = ({ data, onRowClicked }: any) => {
    let stage = "";
    let status = "";
    // Stage Logic only
    if (data.status === "PENDING") {
        stage = "Application";
    }
    if (data.status === "APPROVED") {
        stage = "Quote";
    }
    if (data.finalized) {
        stage = "Policy";
    }

    // Status Logic only
    if (data.status === "PENDING" && data.application.status === "SUBMITTED") {
        status = "Under Review";
    }
    if (data.application.status !== "SUBMITTED" && data.status === "PENDING") {
        status = "In Progress";
    }
    if (data.status === "APPROVED") {
        status = "Issued";
    }
    if (data.status === "DECLINED") {
        status = "Declined";
    }
    if (data.status === "EXPIRED") {
        status = "Expired";
    }
    if (data.finalized) {
        status = "Bound";
    }

    const products =
        data.products !== undefined
            ? data.products.map((product: string) => {
                  if (product === "FIDUCIARY" || product === "FI") {
                      return "FID";
                  } else {
                      return product;
                  }
              })
            : [];
    return (
        <RowDetailContainer>
            <HeadingRowDetail>
                <StyledButton onClick={() => onRowClicked(data)}>{data.company.name}</StyledButton>
                <RowContent>
                    {data.alerts.map((a: any, index: number) => (
                        <StyledAlertText
                            key={`alert_${index}_${a?.id}`}
                            onClick={() => onRowClicked(data)}
                        >
                            <span>
                                {getTypeAlert(a.eventType)} {a.eventTypeDisplay}
                            </span>
                        </StyledAlertText>
                    ))}
                </RowContent>
            </HeadingRowDetail>

            <RowDetail>
                <RowLabel>Account Type</RowLabel>
                <RowContent>{data.accountType}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Retail Agent</RowLabel>
                <RowContent>{data.broker}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>stage</RowLabel>
                <RowContent>{stage}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>status</RowLabel>
                <RowContent>{status}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Products</RowLabel>
                <RowContent>{products.join(", ")}</RowContent>
            </RowDetail>
            {data.expirationDate && (
                <RowDetail>
                    <RowLabel>Expiration</RowLabel>
                    <RowContent>{formatDate(data.expirationDate)}</RowContent>
                </RowDetail>
            )}
            {data.finalPremium && (
                <RowDetail>
                    <RowLabel>Premium</RowLabel>
                    <RowContent>{formatCurrency(data.finalPremium)}</RowContent>
                </RowDetail>
            )}
        </RowDetailContainer>
    );
};

const rowClickedCallback = (history: ReturnType<typeof useHistory>) => (quote: DashboardQuote) => {
    if (quote.alerts?.length) {
        // Clearing alerts for clicked row
        const alertsSet = new Set(quote.alerts.map((a) => `${a.model}__${a.uuid}`));
        for (const modelUUID of Array.from(alertsSet)) {
            const [model, uuid] = modelUUID.split("__");
            rootStore.alerts.clear(model, uuid);
        }
    }

    if (quote.status === "PENDING" && quote.application.status === "OPEN") {
        Cookies.set("appSecurityToken", quote.application.appSecurityToken);
        window.open(`/application/${quote.application.id}`, "_blank");
        return;
    }
    return history.push(`/account/${quote.id}`);
};

const DashboardPage: React.FC<Props> = ({ dashboardDataObject }) => {
    const store = useLocalStore(createDashboardTableStore);
    const history = useHistory();

    const data = getSnapshot(store.results) as DashboardQuote[];
    const onRowClicked = useCallback(rowClickedCallback(history), [history]);
    const { pathname } = useLocation();

    const [modalShareOpen, setModalShareOpen] = React.useState(false);
    const [modalQuote, setModalQuote] = React.useState(null);

    useEffect(() => {
        store.setLoadingScreen();
        if (pathname.includes("dashboard/alerts")) {
            sessionStorage.setItem("dashboardPage", '1');
            store.setTypes('application,decline,expire,policy,quote,renewal');
            store.showFiltersOnly();
        } else {
            store.reloadAllItems();
        }
        // eslint-disable-next-line
    }, [pathname]);

    return (
        <React.Fragment>
            <SectionContainer>
                <SectionHeaderComponent
                    store={store}
                    data={data}
                    dashboardDataObject={dashboardDataObject}
                />
                <TableWrapper>
                    <CustomDataTable<DashboardQuote>
                        NoDataComponentAlternative={NoDataComponent}
                        columns={clientTableColumns(
                            onRowClicked,
                            setModalShareOpen,
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            () => {},
                            setModalQuote,
                        )}
                        data={data}
                        store={store}
                        conditionalRowStyles={conditionalRowStyles}
                        paginationTotalRows={store.count}
                        onChangePage={store.changePage}
                        paginationDefaultPage={store.page}
                        expandableRows={window.innerWidth < 959}
                        expandableRowExpanded={() => {
                            if (window.innerWidth < 959) return true;
                            return false;
                        }}
                        expandableRowsComponent={<RowDetailComponent onRowClicked={onRowClicked} />}
                        progressPending={store.loading}
                        onSort={store.changeSortColumn}
                        onRowClicked={onRowClicked}
                    />
                </TableWrapper>
            </SectionContainer>
            {modalShareOpen && (
                <AddEditRetailBrokerModal
                    openSelectAgentPage={true}
                    openStatus={modalShareOpen}
                    quote={modalQuote}
                    openCloseFunction={setModalShareOpen}
                    reloadDataFunction={() => store.load()}
                />
            )}
        </React.Fragment>
    );
};

export default observer(DashboardPage);
