import React from "react";
import { UncontrolledTooltip } from "reactstrap";

import styled from "styled-components/macro";

import { FullQuestion } from "services/application";
import { QuestionTitle } from "components/typography";
import { Tooltip } from "counterpart-theme";

import { QuestionTitleContainer, TitleForTable } from "./styled";

interface QuestionTitleRenderProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

const RedAsterisk = styled.b`
    align-self: flex-start;
    margin-left: 2px;
    font-size: 16px;
    line-height: 140%;
    color: #d50f1f;
`;

const QuestionTitleRender: React.SFC<QuestionTitleRenderProps> = ({
    question,
    extraProps = {},
}) => {
    if (question.hideTitle) return null;
    let titleCss = true
    if("overrideTitle" in extraProps) {
        titleCss = false
    }
    return (
        <QuestionTitleContainer style={{ paddingRight: 30 }} className={question.type}>
            <div
                style={{ display: "flex", flex: 1, alignSelf: "center" }}
                className={`main-title ${
                    extraProps.parentType && extraProps.parentType === "category" && titleCss
                        ? "subcategory-question-title"
                        : ""
                }`}
            >
                <QuestionTitle>
                    {question.title}
                    {question.required && <RedAsterisk>*</RedAsterisk>}
                    {(question.helpText || question.required) && (
                        <React.Fragment>
                            <Tooltip style={{ marginLeft: 5 }} id={`tooltip_${question.id}`}/>
                            <UncontrolledTooltip placement="top" target={`tooltip_${question.id}`}>
                                {question.helpText}
                                {question.required && (
                                    <>
                                        <RedAsterisk>*</RedAsterisk> - this field is required to submit an application.
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </React.Fragment>
                    )}
                </QuestionTitle>
            </div>
            {extraProps && extraProps.textForTable?.length > 0 && (
                <TitleForTable className="text-for-table">{extraProps.textForTable}</TitleForTable>
            )}
        </QuestionTitleContainer>
    );
};

export default QuestionTitleRender;
