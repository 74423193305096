import React, { useState } from "react";
import styled from "styled-components/macro";

import { Formik, Form } from "formik";
import { RouteComponentProps } from "react-router";

import { H1, Body1 } from "components/typography";
import {
    Button,
    Panel,
    FormikInputField as InputField,
    Row,
    Header,
    Page,
} from "components/common";
import { forgotPassword } from "services/users";

const initialValues = { email: "" };
type FormType = typeof initialValues;

type LoginPageProps = RouteComponentProps<{}>;

const Container = styled.div`
    max-width: 1290px;
    padding: 0px 20px;
    margin: auto;
`;

const ForgotPasswordPanel = styled(Panel as any)`
    display: flex;
    flex-direction: column;
    width: 480px;
    padding: 50px 30px;
    justify-content: space-evenly;
    align-content: center;
    @media (max-width: 900px) {
        padding: 30px;
    }
`;

const WelcomeH1 = styled(H1 as any)`
    max-width: 320px;
    margin: 0 auto 40px;
    text-align: center;
`;

const LoginPage: React.SFC<LoginPageProps> = () => {
    const [emailSent, setEmailSent] = useState(false);

    return (
        <Page>
            <Header showApplicationMenu={false} detached={false} />
            <Container>
                <ForgotPasswordPanel big>
                    <WelcomeH1>Forgot Password</WelcomeH1>
                    {!emailSent && <Body1>Don’t worry! Happens to the best of us.</Body1>}
                    {emailSent && <Body1>A recovery email was sent to your address</Body1>}
                    {!emailSent && (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={async (data) => {
                                try {
                                    await forgotPassword(data);
                                    setEmailSent(true);
                                } catch (error) {
                                    alert("Was not possible send you a email");
                                    console.error(error);
                                }
                            }}
                        >
                            <Form>
                                <Row>
                                    <InputField name="email" label="Email Address" />
                                </Row>
                                <div style={{ textAlign: "center", marginTop: 40 }}>
                                    <Button type="submit" style={{ height: 50, fontWeight: 900 }}>
                                        Send me a recovery link
                                    </Button>
                                </div>
                            </Form>
                        </Formik>
                    )}
                </ForgotPasswordPanel>
            </Container>
        </Page>
    );
};

export default LoginPage;
