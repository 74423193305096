import React, { useState } from "react";
import { FullQuestion } from "services/application";
import { useFormikContext } from "formik";
import QuestionRender from "../QuestionRender";
import { HorizontalCategoryContainer, HorizontalCategoryQuestions } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import AddAnother from "./AddAnother";
import RemoveAnother from "./RemoveAnother";
interface CategoryProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

function getIndexesForQuestion(question: FullQuestion) {
    return Array.from(
        new Set(
            (question.subQuestions || []).reduce<number[]>((p, q) => [...p, ...(q.indexes || [])], [
                0,
            ]),
        ),
    );
}

const HorizontalCategory: React.SFC<CategoryProps> = ({ question, extraProps }) => {
    const { setFieldValue } = useFormikContext<any>();
    const [indexes, setIndexes] = useState(getIndexesForQuestion(question));

    const deleteField = (index: number) => {
        question.subQuestions?.forEach((q) => {
            setFieldValue(`${q.id}__${index}`, null);
        });
        setIndexes(indexes.filter((idx) => idx !== index));
    };
    const extraChildProps = extraProps || {};

    return (
        <HorizontalCategoryContainer
            className={question.type}
            data-name="HorizontalCategoryContainer"
            id={`q-${question.id}__0`}
        >
            <QuestionTitleRender question={question} />
            {indexes.map((index: number) => (
                <HorizontalCategoryQuestions
                    className={`horizontal_category_container ${
                        question.allowMultipleAnswers ? "allow-multiple" : ""
                    }`}
                    key={`horizontal_category_${question.id}_${index}`}
                >
                    {question.subQuestions?.map((q: any) => (
                        <QuestionRender
                            question={q}
                            key={`question_${q.id}_${index}`}
                            extraProps={{
                                ...extraChildProps,
                                questionIndex: index,
                                parentType: "category",
                            }}
                        />
                    ))}
                    {question.allowMultipleAnswers && index > 0 && (
                        <RemoveAnother indexOf={index} onClick={() => deleteField(index)} />
                    )}
                </HorizontalCategoryQuestions>
            ))}

            {question.allowMultipleAnswers && (
                <AddAnother onClick={() => setIndexes((idx) => [...idx, Math.max(...idx) + 1])} />
            )}
        </HorizontalCategoryContainer>
    );
};

export default React.memo(HorizontalCategory);
