import * as React from "react";
import styled, { css } from "styled-components/macro";
import { observer } from "mobx-react-lite";

// import { formatDate } from "utils";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";

import {
    // Body2,
    // H4,
    H2,
} from "components/typography";

import {
    Modal,
    Button,
    CancelButton,
    // Tab,
    // Tabs
} from "components/common";

import QuoteRadioOptions from "./QuoteRadioOptions";

import { formatCurrency } from "utils";
import { useAccountStore, QuoteComponentStore } from "../store";
import QuoteComponentDetails from "./QuoteComponentDetails";
// import PolicyComponentDetails from "./PolicyComponentDetails";
// import { DownloadPdfButton, RowDescription } from "./styled";


import CommonContainer from "../pages/CommonContainer";

import { BlueCheckMarkIcon } from "./Icons";

// import pdfIcon from "assets/pdf-nopaper-icon.svg";
// import counterpartIcon from "assets/counterpart_icon.svg";
// import { getBaseURL } from "../../../utils";

// import ManualDiscountDetails from "./ManualDiscountDetails";
// import CoverageSummaryDetails from "./CoverageSummaryDetails";
// import { QuoteComponentModelType } from "../store/QuoteComponentModel";
import { default as Collapse, CollapseRow } from "../components/Collapse";

const RowWaitingSignature = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.secondary};
    padding-bottom: 40px;
`;

const CustomDetailBody = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 0.55fr 0.2fr 0.3fr 0.4fr 0.3fr;

    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #EBF0F6;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
`;

const HeadRow = styled(Row as any)`
    color: #6E6E6E;
    text-transform: uppercase;
    font-size: 10px;

    @media (max-width: 576px) {
        display: none;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 577px) {
        &:not(:last-of-type) {
            border-bottom: 1px solid #EBF0F6;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    @media (max-width: 576px) {
        &:last-child {
            ${Row}:last-child {
                border-bottom: none;
            }
        }
    }
`;

const Cell = styled.div<{isTitle?: boolean, hideTitle?: boolean, right?: boolean}>`
    padding: 5px;

    ${({isTitle}) => isTitle && css`
        span.title {
            font-weight: bold;
        }
    `};

    ${({hideTitle}) => hideTitle && css`
        @media (min-width: 577px) {
            span.title {
                display: none;
            }
        }
    `}

    ${({right}) => right && css`
        text-align: right;
    `}

    @media (max-width: 576px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const MobileTitle = styled.div`
    color: #6E6E6E;
    text-transform: uppercase;
    font-size: 10px;

    @media (min-width: 577px) {
        display: none;
    }
`;

const ExcessCoverageTable: React.FC<{
    quote: any;
    title: string;
}> = ({ quote, title }) => {
    return (
        <div style={{ width: '100%' }}>
            <Table>
                <HeadRow>
                    <Cell>type</Cell>
                    <Cell>insurer</Cell>
                    <Cell right>limit</Cell>
                    <Cell right>attachment point</Cell>
                    <Cell right>premium</Cell>
                </HeadRow>
                {[...quote.excessOptions, ...quote.excessOptions].map((option: any, index: number) => (
                    <Row key={index}>
                        <Cell isTitle hideTitle={index !== 0}>
                            <MobileTitle>type</MobileTitle>
                            <span className="title">{title}</span>
                        </Cell>
                        <Cell>
                            <MobileTitle>insurer</MobileTitle>
                            <span>{option.externalCarrierName}</span>
                        </Cell>
                        <Cell right>
                            <MobileTitle>limit</MobileTitle>
                            <span>{option.limit ? formatCurrency(option.limit) : undefined}</span>
                        </Cell>
                        <Cell right>
                            <MobileTitle>attachment point</MobileTitle>
                            <span>
                                {option.orderInTower === 1
                                    ? formatCurrency(option.retention)
                                    : formatCurrency(option.attachmentPoint)}
                            </span>
                        </Cell>
                        <Cell right>
                            <MobileTitle>premium</MobileTitle>
                            <span>{option.premium ? formatCurrency(option.premium) : undefined}</span>
                        </Cell>
                    </Row>
                ))}
            </Table>
            <Table>
                {quote.components.map((component: any, index: number) => (
                        <Row key={index}>
                            <Cell isTitle hideTitle={index !== 0}>
                                <MobileTitle>type</MobileTitle>
                                <span className="title">Counterpart Excess</span>
                            </Cell>
                            <Cell>
                                <MobileTitle>insurance line</MobileTitle>
                                {component.insuranceLine.shortName}
                            </Cell>
                            <Cell right>
                                <MobileTitle>limit</MobileTitle>
                                <span>
                                    {component.limits
                                        ? formatCurrency(component.limits)
                                        : undefined}
                                </span>
                            </Cell>
                            <Cell right>
                                <MobileTitle>attachment point</MobileTitle>
                                <span>
                                    {component.attachmentPoint
                                        ? formatCurrency(component.attachmentPoint)
                                        : undefined}
                                </span>
                            </Cell>
                            <Cell right>
                                <MobileTitle>premium</MobileTitle>
                                <span>
                                    {component.premium
                                        ? formatCurrency(component.premium)
                                        : undefined}
                                </span>
                            </Cell>
                        </Row>
                    )
                )}
            </Table>
        </div>
    );
};

const AccountPageDetail = observer(() => {
    // const windowWidth = window.innerWidth;
    const { quote, changeSharedLimits } = useAccountStore();
    const match = useRouteMatch<{ applicationID: string }>();
    const useQuery = () => new URLSearchParams(useLocation().search);
    if (!quote) return null;
    const query = useQuery();
    const history = useHistory();
    const [policyBoundModal, setPolicyBoundModal] = React.useState(
        query.get("bound")?.toLowerCase() === "true",
    );

    // const getDownloadUrl = () => {
    //     const BASE_URL = getBaseURL();
    //     return `${BASE_URL}/api/quotes/${quote.id}/download`;
    // };

    React.useEffect(() => {
        if (quote.sharedLimits && quote.enabledComponents.length <= 1) {
            changeSharedLimits(null, false);
        }
        // eslint-disable-next-line
    }, [quote.enabledComponents]);

    const showMoreInfo = quote.showSidebar;

    const isPolicy = quote.finalized;

    const boundFeedbackUrl = () => {
        const typeFormUrl = "https://yourcounterpart.typeform.com/to/WNGD6I5T";

        const hiddenFields = new URLSearchParams();
        hiddenFields.append("company_name", quote.company.name);
        hiddenFields.append("account_id", quote.id);

        return `${typeFormUrl}#${hiddenFields.toString()}`;
    };

    return (
        <CommonContainer title="Review Coverage" isPolicy={isPolicy}>
            {quote.accountType !== "EXCESS" ? (
                <CustomDetailBody style={{ width: "100%" }}>
                    <div>
                        {showMoreInfo && (
                            <>
                                {!quote.signedApplicationPdf && quote.finalized && (
                                    <RowWaitingSignature>
                                        AWAITING SIGNATURE ON APPLICATION
                                    </RowWaitingSignature>
                                )}
                                <Collapse currentActiveIndex={0}>
                                    {quote.components.map(
                                        (item: QuoteComponentStore, index: number) => {
                                            return (
                                                <CollapseRow
                                                    key={`tabs_${item.id}`}
                                                    id={`review-coverage-collapse-${index}`}
                                                    label={item.insuranceLine.name}
                                                    tabIndex={index}
                                                    disabled={false}
                                                    quoteComponent={item}
                                                    getPremiumText={() =>
                                                        item.calculateVisualPremium(quote)
                                                    }
                                                >
                                                    <QuoteComponentDetails
                                                        key={item.id}
                                                        quoteComponent={item}
                                                        expandable={true}
                                                    />
                                                </CollapseRow>
                                            );
                                        },
                                    )}
                                </Collapse>
                                {/* )} */}
                            </>
                        )}
                    </div>
                </CustomDetailBody>
            ) : (
                <>
                    {quote.excessOptions ? <ExcessCoverageTable title="Tower" quote={quote} /> : undefined}
                </>
            )}
            <QuoteRadioOptions />
            <Modal
                isOpen={policyBoundModal}
                style={{ minWidth: 750 }}
                closeable={true}
                closeAction={() => {
                    history.replace(match.url);
                    setPolicyBoundModal(false);
                }}
                title={""}
                buttonStyle="vertical"
                confetti
                buttons={[
                    <Button
                        key="dashboardButton"
                        style={{ minWidth: "250px" }}
                        onClick={() => setPolicyBoundModal(false)}
                    >
                        Review Policy
                    </Button>,
                    <div
                        key="feedbackButton"
                        style={{
                            borderRadius: "4px",
                            border: "2px solid #EBF0F6",
                            padding: "20px",
                            display: "flex",
                            marginTop: "30px",
                        }}
                    >
                        <p style={{ margin: "auto" }}>
                            Take 1 minute to share your feedback on this account so we can help grow
                            your book.
                        </p>
                        <CancelButton
                            style={{ width: "250px" }}
                            onClick={() => window.open(boundFeedbackUrl(), "_blank")}
                        >
                            Share Feedback
                        </CancelButton>
                    </div>,
                ]}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 15px",
                        marginTop: "-30px",
                    }}
                >
                    <BlueCheckMarkIcon />
                    <H2 style={{ marginTop: 30 }}>The Policy is Bound!</H2>
                    <p style={{ textAlign: "center", marginTop: 12, marginBottom: 40 }}>
                        Check your email (and spam filters) for the policy bundle and invoice. If
                        you have any questions please reach out to{" "}
                        <a href="mailto:support@yourcounterpart.com">support@yourcounterpart.com</a>
                        .
                        <span
                            style={{
                                display: "block",
                                width: "60px",
                                height: "3px",
                                background: "#4BAA8F",
                                margin: "auto",
                                marginTop: "20px",
                            }}
                        />
                    </p>
                </div>
            </Modal>
        </CommonContainer>
    );
});

export default AccountPageDetail;
