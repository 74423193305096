import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components/macro";

import { formatCurrency } from "utils";

type SelectProps = {
    options: number[];
    editing: boolean;
    value: number;
    onChange: (e: any) => any;
    afterChange?: () => any;
    disabled?: boolean;
    isAsync?: boolean;
};

const SelectBody = styled.div<{ opened: boolean }>`
    position: relative;
    border: 1px solid #bfcad6;
    border-top: none;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
    padding: 4px 10px 14px 10px;
    border-radius: 0;

    @media (max-width: 992px) {
        padding: 4px 4px 14px 4px;
    }

    &:after {
        content: "";
        position: absolute;
        width: calc(100% - 20px);
        height: 1px;
        top: 0;
        left: 10px;
        background-color: #bfcad6;
    }
`;

const SelectHead = styled.div`
    position: relative;
    padding: 8px 16px;
    border: 1px solid #bfcad6;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: #fff;

    @media (max-width: 992px) {
        padding: 8px 10px;
    }
`;

const SelectContainer = styled.div<{ opened: boolean; disabled: boolean }>`
    position: relative;
    min-width: 103px;

    ${SelectHead} {
        svg {
            transform: rotate(180deg);
        }
    }

    ${({ opened }) =>
        opened &&
        css`
            &:hover {
                ${SelectHead} {
                    border-bottom-color: transparent;
                }
            }

            ${SelectHead} {
                border-bottom-color: transparent !important;
                border-radius: 0;

                svg {
                    transform: rotate(0);
                }
            }
            ${SelectBody} {
                display: flex;
                ${SelectOptions} {
                    display: flex;
                }
            }
        `}

    ${({ disabled }) =>
        disabled
            ? css`
                  opacity: 0.5;
              `
            : css`
                  &:hover {
                      ${SelectHead}, ${SelectBody} {
                          border-color: ${({ theme }) => theme.colors.accentColor};
                      }
                  }
              `};
`;

const SelectOptions = styled.ul`
    list-style-type: none;
    flex-direction: column;
    padding: 0;
    margin: 0;
    text-align: left;
`;

const SelectOption = styled.li`
    transition: background-color 0.3s;
    padding: 4px 7px;
    border-radius: 10px;

    &:hover {
        background-color: #ebf0f6;
        cursor: pointer;
    }
`;

export const Value = styled.span`
    padding: 8px 0;
    border: 1px solid transparent;
    display: block;
    font-size: 14px;
`;

function useOutsideEventHandler(ref: any, callback: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

const SelectField: React.FC<SelectProps> = ({
    value,
    editing,
    options,
    onChange,
    afterChange,
    disabled,
    isAsync,
}) => {
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(value);

    const wrapperRef = useRef(null);
    useOutsideEventHandler(wrapperRef, () => setOpened(false));

    // useEffect(() => {
    //     if (selected !== value) {
    //         const toSubmit = {
    //             target: {
    //                 value: selected,
    //             },
    //         };
    //         onChange(toSubmit);
    //         afterChange();
    //         console.log(`${value} -> ${selected}`);
    //     }
    // }, [selected, afterChange, onChange, value]);

    const submit = async function(optionValue: number) {
        if (selected !== optionValue) {
            const toSubmit = {
                target: {
                    value: optionValue,
                },
            };
            if (isAsync) {
                if (afterChange) {
                    onChange(toSubmit);
                    const res = await afterChange();
                    if (res) {
                        setSelected(optionValue);
                    }
                } else {
                    const res = await onChange(toSubmit);
                    if (res) {
                        setSelected(optionValue);
                    }
                }
            } else {
                setSelected(optionValue);
                onChange(toSubmit);
                afterChange && afterChange();
            }
            console.log(`${value} -> ${optionValue}`);
        }
    };

    const optionClick = (value: number) => {
        // setSelected(value);
        submit(value);
        setOpened(false);
    };

    const onHeadClick = () => {
        if (!disabled) {
            setOpened((opened) => !opened);
        }
    };

    if (editing) {
        return (
            <SelectContainer opened={opened} ref={wrapperRef} disabled={disabled || false}>
                <SelectHead onClick={onHeadClick}>
                    {formatCurrency(selected)}
                    <svg
                        style={{ marginLeft: 5 }}
                        width="11"
                        height="6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M.309 5.72c.195.18.46.28.735.28.276 0 .54-.1.736-.28l3.746-3.4 3.694 3.4c.196.18.46.28.736.28.275 0 .54-.1.735-.28a.96.96 0 00.229-.312.894.894 0 000-.739.96.96 0 00-.229-.312L6.267.284a1.053 1.053 0 00-.34-.21 1.122 1.122 0 00-.802 0c-.127.049-.243.12-.34.21L.31 4.357a.96.96 0 00-.229.312.895.895 0 000 .74.96.96 0 00.229.312z"
                            fill="#22232E"
                        />
                    </svg>
                </SelectHead>
                <SelectBody opened={opened}>
                    <SelectOptions>
                        {options.map((option: number, index: number) => (
                            <SelectOption
                                onClick={() => optionClick(option)}
                                key={`${index}_${option}`}
                                value={option}
                            >
                                {formatCurrency(option)}
                            </SelectOption>
                        ))}
                    </SelectOptions>
                </SelectBody>
            </SelectContainer>
        );
    }
    return <Value>{value !== null ? formatCurrency(value) : "-"}</Value>;
};

export default SelectField;
