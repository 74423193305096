import { ArithmeticExpressionOperatorsEnum, ARITHMETIC_OPERATORS_HANDLERS } from "./arithmetic";
import { LogicalOperatorEnum, LOGICAL_OPERATORS_HANDLERS } from "./logical";
import { ComparisonOperatorEnum, COMPARISON_OPERATORS_HANDLERS } from "./comparison";
import { Expression, ContextType, ValueType } from "./types";

export * from "./types";
export * from "./utils";

export const OperatorsTypes = {
    ...ComparisonOperatorEnum,
    ...LogicalOperatorEnum,
    ...ArithmeticExpressionOperatorsEnum,
};

export type HandlerType = (args: ValueType) => ValueType;

export const OPERATORS_HANDLERS: { [K in keyof typeof OperatorsTypes]?: HandlerType } = {
    ...ARITHMETIC_OPERATORS_HANDLERS,
    ...LOGICAL_OPERATORS_HANDLERS,
    ...COMPARISON_OPERATORS_HANDLERS,
};
export type ValidOperators = keyof typeof OperatorsTypes;

export function evaluateExpression(
    expression: Expression,
    context: ContextType,
): ValueType | ValueType[] {
    if (Array.isArray(expression)) {
        return expression.map((e) => evaluateExpression(e, context)) as ValueType[];
    } else if (typeof expression === "object" && expression !== null) {
        const keys = Object.keys(expression) as ValidOperators[];
        if (!keys.length || keys.length > 1) {
            throw new Error("Expression requires one key for each object");
        }
        const [operator] = keys;
        const handler = OPERATORS_HANDLERS[operator];
        if (!handler) {
            throw new Error(`Handler "${operator}" not found`);
        }
        const subExpression = expression[operator];
        if (subExpression === undefined) {
            throw new Error("Undefined subExpression");
        }
        const args = evaluateExpression(subExpression, context);
        return handler(args);
    } else if (typeof expression === "string" && expression.startsWith("$")) {
        if (expression === "$currentValue") {
            return context.currentValue ?? null;
        }
        const key = expression.slice(1);
        const value = context.context[key];
        console.log(expression);
        if (expression.endsWith("__is_enabled")) {
            const line = key.replace("__is_enabled", "");
            const lines = context.context.lines;
            if (lines !== null && Array.isArray(lines) && lines.indexOf(line) >= 0) {
                return true;
            }
            return false;
        }
        if (value === undefined) {
            throw new Error(`${key} not found in context`);
        }
        return value;
    } else if (typeof expression === "string" && expression.startsWith("/$")) {
        return expression.slice(1) as ValueType;
    }
    return expression as ValueType;
}
