import * as React from "react";
import { observer } from "mobx-react-lite";

import LimitsTable from "./LimitsTable";
// import AddLimitForm from "./AddLimitForm";

import { QuoteComponentStore, QuoteStore } from "../store";
// import styled from "styled-components/macro";
// import { H2, Body1 } from "components/typography";

interface Props {
    quoteComponent: QuoteComponentStore;
    quote: QuoteStore;
}

// const SharedLimitsOverlayText = styled.div``;

// const Container = styled.div`
//     & ${SharedLimitsOverlayText} {
//         display: none;
//     }

//     &.shared-limits {
//         position: relative;
//         padding: 10px 40px 30px 40px;
//         margin-bottom: 60px;
//         margin-top: 30px;
//     }

//     &.shared-limits .shared-limits-overlay {
//         filter: blur(1.5px) grayscale(1);
//         background-color: #fff;
//         opacity: 0.4;
//     }

//     &.shared-limits ${H2}, &.shared-limits ${Body1} {
//         text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
//     }
//     &.shared-limits ${H2} {
//         margin-bottom: 30px;
//     }

//     &.shared-limits ${Body1} {
//         font-size: 17px;
//     }

//     &.shared-limits ${SharedLimitsOverlayText} {
//         display: flex;
//         position: absolute;
//         top: 0;
//         bottom: 0;
//         left: 0;
//         right: 0;
//         content: "";
//         cursor: not-allowed;
//         border-radius: 8px;
//         flex-direction: column;
//         justify-content: center;
//         padding: 40px 100px;
//         background-color: rgba(193, 202, 213, 0.2);
//     }

//     @media (max-width: 480px) {
//         &.shared-limits ${SharedLimitsOverlayText} {
//             padding: 10px;
//         }
//     }
// `;

const QuoteOtherLimitsAndRetentionsForm: React.SFC<Props> = observer(
    ({ quoteComponent, quote }) => {
        // const containerClass = quote.sharedLimits ? "shared-limits" : "";
        if (!quote.finalized) {
            return (
                <>
                    {/* <div className="shared-limits-overlay"> */}
                    <LimitsTable quoteComponent={quoteComponent} quote={quote} />
                    {/* <AddLimitForm quoteComponent={quoteComponent} quote={quote} /> */}
                    {/* </div> */}
                    {/* <SharedLimitsOverlayText className="text-center">
                        <H2>You have selected shared limits</H2>
                        <Body1>
                            In order to edit the limits and retentions for this coverage section
                            first return to the Overview section and decline shared limits.
                        </Body1>
                    </SharedLimitsOverlayText> */}
                </>
            );
        } else {
            return <></>;
        }
    },
);

export default QuoteOtherLimitsAndRetentionsForm;
