import React from "react";
import styled from "styled-components/macro";

import { CustomDataTable } from "components/common";

import { BaseFont, TimesTitleBaseCSS } from "components/typography";

import { useLocalStore, observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";

import { createClaimsTableStoreStore } from "./store";
import { ClaimsTable } from "CounterpartApiTypes";
import { formatDate } from "utils";
import {
    RowContent,
    RowDetail,
    RowDetailContainer,
    RowLabel,
    HeadingRowDetail,
    RowBold,
} from "../styled";
import NoDataComponent from "../NoDataComponent";
import { Helmet } from "react-helmet";

const SectionTitle = styled.h2`
    ${TimesTitleBaseCSS}
    font-size: 24px;
    font-weight: 700;
`;

const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const SectionContainer = styled.div`
    ${BaseFont};
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 0;
    border-radius: 10px;
`;

export const COLUMNS: any = [
    {
        name: "Company",
        selector: "companyName",
        sortable: true,
        hide: "md",
        grow: 4,
    },
    {
        name: "Claims ID",
        selector: "claimNumber",
        sortable: true,
        hide: "md",
        grow: 3,
    },
    {
        name: "Retail Agent",
        selector: "retailBroker",
        hide: "md",
        grow: 3,
        fontAlign: "left",
    },
    {
        name: "Agency",
        selector: "agency",
        hide: "md",
        grow: 3,
        // eslint-disable-next-line react/display-name
        cell: (data: any) => {
            return (
                <span style={{ whiteSpace: "normal", wordBreak: "normal" }}>
                    {data?.agency || "-"}
                </span>
            );
        },
        // allowOverflow: true,
    },
    {
        name: "Notice of Claim",
        selector: "noticeOfClaim",
        hide: "md",
        grow: 3,
        // eslint-disable-next-line react/display-name
        cell: ({ noticeOfClaim }: any) => {
            const effective = formatDate(noticeOfClaim, "MM/DD/YYYY") ?? "-";
            return <span style={{ whiteSpace: "nowrap" }}>{effective}</span>;
        },
    },
    {
        name: "status",
        selector: "status",
        sortable: true,
        hide: "md",
        grow: 2,
    },
];

const RowDetailComponent = ({ data }: any) => {
    return (
        <RowDetailContainer>
            <HeadingRowDetail>
                <RowBold>{data.companyName}</RowBold>
            </HeadingRowDetail>
            <RowDetail>
                <RowLabel>Claims ID</RowLabel>
                <RowContent>{data.claimNumber}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Retail Agent</RowLabel>
                <RowContent>{data.retailBroker}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Agency</RowLabel>
                <RowContent>{data.agency}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Notice of Claim</RowLabel>
                <RowContent>{formatDate(data.noticeOfClaim, "MM/DD/YYYY") ?? "-"}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Status</RowLabel>
                <RowContent>{data.status}</RowContent>
            </RowDetail>
        </RowDetailContainer>
    );
};

const PaymentsTablePage: React.FC = () => {
    const store = useLocalStore(createClaimsTableStoreStore);
    const data = getSnapshot(store.results) as ClaimsTable[];

    return (
        <React.Fragment>
            <Helmet>
                <title>Counterpart | Claims</title>
            </Helmet>
            <SectionContainer>
                <SectionHeader>
                    <SectionTitle>Claims</SectionTitle>
                </SectionHeader>
                <React.Fragment>
                    <CustomDataTable<ClaimsTable>
                        columns={COLUMNS}
                        noDataComponent={
                            <NoDataComponent simpleView={true} message={`Nice Job! :)`} />
                        }
                        data={data}
                        paginationTotalRows={store.count}
                        onChangePage={store.changePage}
                        expandableRows={window.innerWidth < 959}
                        expandableRowExpanded={() => {
                            if (window.innerWidth < 959) return true;
                            return false;
                        }}
                        expandableRowsComponent={<RowDetailComponent />}
                        progressPending={store.loading}
                        noDataText="No Claims, Hurray!"
                    />
                </React.Fragment>
            </SectionContainer>
        </React.Fragment>
    );
};

export default observer(PaymentsTablePage);
