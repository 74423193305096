// frontend/src/services/generated/quotes.ts
// Auto Generated File, Do not edit.

import apiClient from "./../apiClient";
import * as apiTypes from "CounterpartApiTypes";
import * as logger from "./../logger";

type RemoveUndefined<T> = T extends undefined ? never : T;

type RemoveUndefinedKeys<T> = T extends {}
    ? {
          [P in keyof T]-?: RemoveUndefined<T[P]>;
      }
    : T;

type ApiReturnType<T> = T extends Array<any> ? RemoveUndefinedKeys<T[0]>[] : RemoveUndefinedKeys<T>;

/**
 * bindAccountWithBindToken
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/bind-account-with-token
 *
 * @param {apiTypes.RequestToBindAccountViaToken} data
 * @param {string} quoteId
 */
export async function bindAccountWithBindToken(
    data: apiTypes.RequestToBindAccountViaToken,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/bind-account-with-token`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "bindAccountWithBindToken"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * bindCoverage
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/bind_coverage/
 *
 * @param {any} data
 * @param {string} quoteId
 */
export async function bindCoverage(
    data: any,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/bind_coverage/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "bindCoverage"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * changeSubcoverages
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/change_subcoverages/
 *
 * @param {apiTypes.ChangeSubcoverages} data
 * @param {string} quoteId
 */
export async function changeSubcoverages(
    data: apiTypes.ChangeSubcoverages,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.ChangeSubcoverages>> {
    try {
        const url = `/api/quotes/${quoteId}/change_subcoverages/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "changeSubcoverages"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * checkIfRetailBrokerExists
 * ---
 *
 *
 * @api GET /api/brokers/check_if_retail_broker_exists/
 *
 * @param {string} email
 */
export async function checkIfRetailBrokerExists(
    email: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Broker>> {
    try {
        const url = `/api/brokers/check_if_retail_broker_exists/`;
        apiOptions = { ...apiOptions, params: { ...{ email }, ...(apiOptions as any).params } };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "checkIfRetailBrokerExists"';
        logger.error(msg, { email });
        return Promise.reject(error);
    }
}

/**
 * deleteApplicationFile
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/delete_application_file/
 *
 * @param {any} data
 * @param {string} quoteId
 */
export async function deleteApplicationFile(
    data: any,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/delete_application_file/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "deleteApplicationFile"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * deleteSignedApplication
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/delete_signed_application/
 *
 * @param {any} data
 * @param {string} quoteId
 */
export async function deleteSignedApplication(
    data: any,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/delete_signed_application/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "deleteSignedApplication"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * deleteSubjectivityFile
 * ---
 * Delete a subjectivity file from a subjectivity
 *
 *
 * @api DELETE /api/quotes/delete_subjectivity_file/{id}
 *
 * @param {string} id
 */
export async function deleteSubjectivityFile(
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.QuoteSubjectivityFile[]>> {
    try {
        const url = `/api/quotes/delete_subjectivity_file/${id}`;
        const resp = await apiClient.delete(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "deleteSubjectivityFile"';
        logger.error(msg, { id });
        return Promise.reject(error);
    }
}

/**
 * downloadPDF
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/download/{type}
 *
 * @param {string} quoteId
 * @param {string} type
 */
export async function downloadPDF(
    quoteId: string,
    type: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/download/${type}`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "downloadPDF"';
        logger.error(msg, { quoteId, type });
        return Promise.reject(error);
    }
}

/**
 * generatePolicyCertificate
 * ---
 *
 *
 * @api GET /api/quotes/{policy_id}/generate_pdf
 *
 * @param {string} policyId
 */
export async function generatePolicyCertificate(
    policyId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Policy>> {
    try {
        const url = `/api/quotes/${policyId}/generate_pdf`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "generatePolicyCertificate"';
        logger.error(msg, { policyId });
        return Promise.reject(error);
    }
}

/**
 * generateQuotePDF
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/generate_pdf/
 *
 * @param {string} quoteId
 */
export async function generateQuotePDF(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/quotes/${quoteId}/generate_pdf/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "generateQuotePDF"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * getAccountsNeedingFeedback
 * ---
 *
 *
 * @api GET /api/quotes/accounts-needing-feedback
 *
 * @param {string} brokerId
 * @param {string} quickPageToken
 */
export async function getAccountsNeedingFeedback(
    brokerId: string,
    quickPageToken: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.AccountNeedsFeedbackQuote>> {
    try {
        const url = `/api/quotes/accounts-needing-feedback`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ brokerId, quickPageToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getAccountsNeedingFeedback"';
        logger.error(msg, { brokerId, quickPageToken });
        return Promise.reject(error);
    }
}

/**
 * getBrokerPolicies
 * ---
 *
 *
 * @api GET /api/brokers/{broker_id}/policies/
 *
 * @param {string} brokerId
 */
export async function getBrokerPolicies(
    brokerId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Policy>> {
    try {
        const url = `/api/brokers/${brokerId}/policies/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getBrokerPolicies"';
        logger.error(msg, { brokerId });
        return Promise.reject(error);
    }
}

/**
 * getPolicyDashboard
 * ---
 *
 *
 * @api GET /api/brokers/dashboard_data/
 *
 */
export async function getPolicyDashboard(
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.BrokerDashboard>> {
    try {
        const url = `/api/brokers/dashboard_data/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getPolicyDashboard"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * getPolicyFromQuote
 * ---
 *
 *
 * @api GET /api/policies/from_quote_id/{quote_id}/
 *
 * @param {string} quoteId
 */
export async function getPolicyFromQuote(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Policy>> {
    try {
        const url = `/api/policies/from_quote_id/${quoteId}/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getPolicyFromQuote"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuote
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/
 *
 * @param {string} quoteId
 */
export async function getQuote(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/quotes/${quoteId}/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuote"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuoteAnswerRenewalContext
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/renewal_answer_context
 *
 * @param {string} brokerId
 * @param {string} quickPageToken
 * @param {string} quoteId
 */
export async function getQuoteAnswerRenewalContext(
    brokerId: string,
    quickPageToken: string,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/renewal_answer_context`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ brokerId, quickPageToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteAnswerRenewalContext"';
        logger.error(msg, { brokerId, quickPageToken, quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuoteFeedbackData
 * ---
 *
 *
 * @api GET /api/quotes/feedback/{quote_id}/
 *
 * @param {string} quoteId
 */
export async function getQuoteFeedbackData(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/feedback/${quoteId}/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteFeedbackData"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuoteFeedbackDiffHistory
 * ---
 *
 *
 * @api GET /api/quotes/feedback/{quote_id}/history
 *
 * @param {string} quoteId
 */
export async function getQuoteFeedbackDiffHistory(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/feedback/${quoteId}/history`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteFeedbackDiffHistory"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuoteFeedbackDropdownData
 * ---
 *
 *
 * @api GET /api/quotes/feedback/dropdown
 *
 */
export async function getQuoteFeedbackDropdownData(apiOptions = {}): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/feedback/dropdown`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteFeedbackDropdownData"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * getQuoteFeedbackSaveData
 * ---
 *
 *
 * @api POST /api/quotes/feedback/{quote_id}/save
 *
 * @param {string} quoteId
 */
export async function getQuoteFeedbackSaveData(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/feedback/${quoteId}/save`;
        const resp = await apiClient.post(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteFeedbackSaveData"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuotePerformAction
 * ---
 *
 *
 * @api POST /api/quotes/action/{quote_id}/{action}
 *
 * @param {string} action
 * @param {string} quoteId
 */
export async function getQuotePerformAction(
    action: string,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/action/${quoteId}/${action}`;
        const resp = await apiClient.post(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuotePerformAction"';
        logger.error(msg, { action, quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuoteQuickDetails
 * ---
 * This endpoint is open and access is enforced via the required brokerId and quickPageToken query parameters. Quote
details is only returned when the Quote ID, Broker ID and Quick Page Token match.
@param request:
@param quote_id: The quote ID
@return: Quote Details or 404 if access is not allowed
 *
 *
 * @api GET /api/quotes/{quote_id}/quick
 *
 * @param {string} brokerId
 * @param {string} quickPageToken
 * @param {string} quoteId
 */
export async function getQuoteQuickDetails(
    brokerId: string,
    quickPageToken: string,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/quotes/${quoteId}/quick`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ brokerId, quickPageToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteQuickDetails"';
        logger.error(msg, { brokerId, quickPageToken, quoteId });
        return Promise.reject(error);
    }
}

/**
 * getQuoteRenewalContext
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/renewal_context
 *
 * @param {string} brokerId
 * @param {string} quickPageToken
 * @param {string} quoteId
 */
export async function getQuoteRenewalContext(
    brokerId: string,
    quickPageToken: string,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/renewal_context`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ brokerId, quickPageToken }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getQuoteRenewalContext"';
        logger.error(msg, { brokerId, quickPageToken, quoteId });
        return Promise.reject(error);
    }
}

/**
 * getSecurityTokenAndPublicToken
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/public_security_token/
 *
 * @param {string} quoteId
 */
export async function getSecurityTokenAndPublicToken(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.AppSecurityTokenAndQuotePublicSecurityToken>> {
    try {
        const url = `/api/quotes/${quoteId}/public_security_token/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getSecurityTokenAndPublicToken"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * getSubjectivityApp
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/subjectivities/{token}/
 *
 * @param {string} quoteId
 * @param {string} token
 */
export async function getSubjectivityApp(
    quoteId: string,
    token: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.SubjectivitiesApp>> {
    try {
        const url = `/api/quotes/${quoteId}/subjectivities/${token}/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "getSubjectivityApp"';
        logger.error(msg, { quoteId, token });
        return Promise.reject(error);
    }
}

/**
 * listAllRetailBrokers
 * ---
 *
 *
 * @api GET /api/brokers/list_all_retail_brokers/
 *
 */
export async function listAllRetailBrokers(
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.RetailBrokerTable[]>> {
    try {
        const url = `/api/brokers/list_all_retail_brokers/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listAllRetailBrokers"';
        logger.error(msg, {});
        return Promise.reject(error);
    }
}

/**
 * listBrokerDashboardQuotes
 * ---
 *
 *
 * @api GET /api/brokers/dashboard_quotes/
 *
 * @param {string} ordering Which field to use when ordering the results.
 * @param {number} page A page number within the paginated result set.
 */
export async function listBrokerDashboardQuotes(
    ordering: string,
    page: number,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/brokers/dashboard_quotes/`;
        apiOptions = {
            ...apiOptions,
            params: { ...{ ordering, page }, ...(apiOptions as any).params },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listBrokerDashboardQuotes"';
        logger.error(msg, { ordering, page });
        return Promise.reject(error);
    }
}

/**
 * listBrokerQuotes
 * ---
 *
 *
 * @api GET /api/brokers/{broker_id}/quotes/
 *
 * @param {string} brokerId
 */
export async function listBrokerQuotes(
    brokerId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/brokers/${brokerId}/quotes/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listBrokerQuotes"';
        logger.error(msg, { brokerId });
        return Promise.reject(error);
    }
}

/**
 * listClaims
 * ---
 *
 *
 * @api GET /api/policies/claims
 *
 * @param {number} page A page number within the paginated result set.
 */
export async function listClaims(page: number, apiOptions = {}): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/policies/claims`;
        apiOptions = { ...apiOptions, params: { ...{ page }, ...(apiOptions as any).params } };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listClaims"';
        logger.error(msg, { page });
        return Promise.reject(error);
    }
}

/**
 * listCompanyPolicies
 * ---
 *
 *
 * @api GET /api/companies/{company_id}/policies/
 *
 * @param {string} companyId
 */
export async function listCompanyPolicies(
    companyId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Policy>> {
    try {
        const url = `/api/companies/${companyId}/policies/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listCompanyPolicies"';
        logger.error(msg, { companyId });
        return Promise.reject(error);
    }
}

/**
 * listCompanyQuotes
 * ---
 *
 *
 * @api GET /api/companies/{company_id}/quotes/
 *
 * @param {string} companyId
 */
export async function listCompanyQuotes(
    companyId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/companies/${companyId}/quotes/`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listCompanyQuotes"';
        logger.error(msg, { companyId });
        return Promise.reject(error);
    }
}

/**
 * listPayments
 * ---
 *
 *
 * @api GET /api/policies/payments
 *
 * @param {number} page A page number within the paginated result set.
 */
export async function listPayments(page: number, apiOptions = {}): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/policies/payments`;
        apiOptions = { ...apiOptions, params: { ...{ page }, ...(apiOptions as any).params } };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listPayments"';
        logger.error(msg, { page });
        return Promise.reject(error);
    }
}

/**
 * listRetailBrokers
 * ---
 *
 *
 * @api GET /api/brokers/retail_brokers_table/
 *
 * @param {number} page A page number within the paginated result set.
 */
export async function listRetailBrokers(
    page: number,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/brokers/retail_brokers_table/`;
        apiOptions = { ...apiOptions, params: { ...{ page }, ...(apiOptions as any).params } };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listRetailBrokers"';
        logger.error(msg, { page });
        return Promise.reject(error);
    }
}

/**
 * loadMinimalQuoteData
 * ---
 *
 *
 * @api GET /api/quotes/{quote_id}/load_minimal_quote_data
 *
 * @param {string} quoteId
 */
export async function loadMinimalQuoteData(
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.MinimalQuoteData>> {
    try {
        const url = `/api/quotes/${quoteId}/load_minimal_quote_data`;
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "loadMinimalQuoteData"';
        logger.error(msg, { quoteId });
        return Promise.reject(error);
    }
}

/**
 * markAccountLost
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/mark_as_lost
 *
 * @param {apiTypes.NeedsFeedbackRequest} data
 * @param {string} quoteId
 */
export async function markAccountLost(
    data: apiTypes.NeedsFeedbackRequest,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.AccountLostQuote>> {
    try {
        const url = `/api/quotes/${quoteId}/mark_as_lost`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "markAccountLost"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * removePermutation
 * ---
 *
 *
 * @api DELETE /api/quotes/{quote_component_id}/{index}/remove_permutation
 *
 * @param {string} index
 * @param {string} quoteComponentId
 */
export async function removePermutation(
    index: string,
    quoteComponentId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/quotes/${quoteComponentId}/${index}/remove_permutation`;
        const resp = await apiClient.delete(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "removePermutation"';
        logger.error(msg, { index, quoteComponentId });
        return Promise.reject(error);
    }
}

/**
 * requestAccountExtending
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/request-quote-extension
 *
 * @param {apiTypes.NeedsFeedbackRequest} data
 * @param {string} quoteId
 */
export async function requestAccountExtending(
    data: apiTypes.NeedsFeedbackRequest,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/request-quote-extension`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "requestAccountExtending"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * requestSignature
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/hellosign/
 *
 * @param {apiTypes.RequestESignature} data
 * @param {string} quoteId
 */
export async function requestSignature(
    data: apiTypes.RequestESignature,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.HelloSignSignatureRequest>> {
    try {
        const url = `/api/quotes/${quoteId}/hellosign/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "requestSignature"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * runEngineForQuote
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/run_engine
 *
 * @param {apiTypes.RatingEngine} data
 * @param {string} quoteId
 */
export async function runEngineForQuote(
    data: apiTypes.RatingEngine,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/quotes/${quoteId}/run_engine`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "runEngineForQuote"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * saveApplicationFile
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/save_application_file/
 *
 * @param {apiTypes.SignedApplicationFile} data
 * @param {string} quoteId
 */
export async function saveApplicationFile(
    data: apiTypes.SignedApplicationFile,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.QuoteFile>> {
    try {
        const url = `/api/quotes/${quoteId}/save_application_file/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "saveApplicationFile"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * saveBrokerFeedback
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/save-broker-feedback
 *
 * @param {apiTypes.SaveBrokerFeedback} data
 * @param {string} quoteId
 */
export async function saveBrokerFeedback(
    data: apiTypes.SaveBrokerFeedback,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/save-broker-feedback`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "saveBrokerFeedback"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * saveBrokerSurplusLicense
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/subjectivities/surplus_license/
 *
 * @param {apiTypes.SurplusLicense} data
 * @param {string} quoteId
 */
export async function saveBrokerSurplusLicense(
    data: apiTypes.SurplusLicense,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/subjectivities/surplus_license/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "saveBrokerSurplusLicense"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * saveHRContact
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/save_hr_contact/
 *
 * @param {apiTypes.CompanyHRContact} data
 * @param {string} quoteId
 */
export async function saveHRContact(
    data: apiTypes.CompanyHRContact,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/save_hr_contact/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "saveHRContact"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * saveSubjectivityAnswer
 * ---
 * Return a list of quote subjectivities
 *
 *
 * @api POST /api/quotes/{quote_id}/subjectivities/{token}/answers/
 *
 * @param {apiTypes.Answer[]} data
 * @param {string} quoteId
 * @param {string} token
 */
export async function saveSubjectivityAnswer(
    data: apiTypes.Answer[],
    quoteId: string,
    token: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/subjectivities/${token}/answers/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "saveSubjectivityAnswer"';
        logger.error(msg, { data, quoteId, token });
        return Promise.reject(error);
    }
}

/**
 * sendRequestToBrokerToBindAccount
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/send-request-to-broker-to-bind-account
 *
 * @param {apiTypes.SendRequestToBrokerToBindAccount} data
 * @param {string} quoteId
 */
export async function sendRequestToBrokerToBindAccount(
    data: apiTypes.SendRequestToBrokerToBindAccount,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/send-request-to-broker-to-bind-account`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "sendRequestToBrokerToBindAccount"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * sendStartApplicationLinkEmail
 * ---
 *
 *
 * @api POST /api/brokers/send_start_application_link_email/
 *
 * @param {apiTypes.SendEmail} data
 */
export async function sendStartApplicationLinkEmail(
    data: apiTypes.SendEmail,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.SendEmail>> {
    try {
        const url = `/api/brokers/send_start_application_link_email/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "sendStartApplicationLinkEmail"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * sendStartApplicationLinkEmailQuickPage
 * ---
 *
 *
 * @api POST /api/brokers/send_start_application_link_email_quick_page/
 *
 * @param {apiTypes.SendEmailQuickPage} data
 */
export async function sendStartApplicationLinkEmailQuickPage(
    data: apiTypes.SendEmailQuickPage,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.SendEmailQuickPage>> {
    try {
        const url = `/api/brokers/send_start_application_link_email_quick_page/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "sendStartApplicationLinkEmailQuickPage"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * setEffectiveDate
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/set_effective_date/
 *
 * @param {apiTypes.QuoteEffectiveDate} data
 * @param {string} quoteId
 */
export async function setEffectiveDate(
    data: apiTypes.QuoteEffectiveDate,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.QuoteEffectiveDate>> {
    try {
        const url = `/api/quotes/${quoteId}/set_effective_date/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "setEffectiveDate"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * setRetailBroker
 * ---
 *
 *
 * @api POST /api/quotes/set_retail_broker/
 *
 * @param {apiTypes.SetRetailBroker} data
 */
export async function setRetailBroker(
    data: apiTypes.SetRetailBroker,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/quotes/set_retail_broker/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "setRetailBroker"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}

/**
 * shareSubjectivitiesApplication
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/share_subjectivities/{token}/
 *
 * @param {apiTypes.ShareApplication} data
 * @param {string} quoteId
 * @param {string} token
 */
export async function shareSubjectivitiesApplication(
    data: apiTypes.ShareApplication,
    quoteId: string,
    token: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/share_subjectivities/${token}/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "shareSubjectivitiesApplication"';
        logger.error(msg, { data, quoteId, token });
        return Promise.reject(error);
    }
}

/**
 * submitSupplementalApplication
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/submit_supplemental_application/
 *
 * @param {apiTypes.SubmitSupplemental} data
 * @param {string} quoteId
 */
export async function submitSupplementalApplication(
    data: apiTypes.SubmitSupplemental,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/submit_supplemental_application/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "submitSupplementalApplication"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * updateShared
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/update_shared
 *
 * @param {apiTypes.SharedLimit} data
 * @param {string} quoteId
 */
export async function updateShared(
    data: apiTypes.SharedLimit,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.Quote>> {
    try {
        const url = `/api/quotes/${quoteId}/update_shared`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "updateShared"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * uploadSignedApplicationRequest
 * ---
 *
 *
 * @api POST /api/quotes/{quote_id}/upload_signed_application/
 *
 * @param {apiTypes.SignedApplicationFile} data
 * @param {string} quoteId
 */
export async function uploadSignedApplicationRequest(
    data: apiTypes.SignedApplicationFile,
    quoteId: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/quotes/${quoteId}/upload_signed_application/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "uploadSignedApplicationRequest"';
        logger.error(msg, { data, quoteId });
        return Promise.reject(error);
    }
}

/**
 * uploadSubjectivityFile
 * ---
 * Upload a subjectivity file to a subjectivity
 *
 *
 * @api POST /api/quotes/upload_subjectivity_file/{id}
 *
 * @param {apiTypes.QuoteSubjectivityFile} data
 * @param {string} id
 */
export async function uploadSubjectivityFile(
    data: apiTypes.QuoteSubjectivityFile,
    id: string,
    apiOptions = {},
): Promise<ApiReturnType<apiTypes.QuoteSubjectivityFile[]>> {
    try {
        const url = `/api/quotes/upload_subjectivity_file/${id}`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "uploadSubjectivityFile"';
        logger.error(msg, { data, id });
        return Promise.reject(error);
    }
}
