import React from "react";
import { FullQuestion, useApplicationContext } from "services/application";
import { InputContainer, QuestionRowContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import { FormikInputField } from "components/common";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-date-picker";

import { clearFieldVerificationRequired } from "../../sectionUtils";
import moment from "moment";

interface TextProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

const Date: React.SFC<TextProps> = ({ question, extraProps = {} }) => {
    const fieldName = `${question.id}__${extraProps?.questionIndex || 0}`;
    let type = question.type.toLowerCase();
    const hasHelper = false;
    const helperText = "";
    const helperPosition = "prefix";
    let min: any = Number.MIN_SAFE_INTEGER.toString();
    let max: any = Number.MAX_SAFE_INTEGER.toString();
    if (type === "year") {
        type = "number";
        min = "1500";
        max = "2999";
    }
    const [meta, field] = useField(fieldName);
    const { setFieldValue } = useFormikContext<any>();
    const { sections: _sec, setSections } = useApplicationContext();
    const { questionsOnError, setQuestionsOnError } = extraProps;
    const sections = _sec as NonNullable<typeof _sec>;

    const getDate = (value: string) => {
        if (value === "" || value === null) {
            return null;
        }
        return moment(value).toDate();
    };
    let errorClassName = "";
    if (field.touched && questionsOnError) {
        errorClassName = questionsOnError.includes(question.id) ? "on-error" : "";
    }

    return (
        <QuestionRowContainer className={question.type} data-name="QuestionRowContainer">
            <QuestionTitleRender question={question} extraProps={extraProps} />
            <InputContainer className="safari-ie">
                <DatePicker
                    className={`question-${question.id} ${errorClassName} ${
                        question.required ? "field-required" : ""
                    }`}
                    name={fieldName}
                    onChange={(e: any) => {
                        clearFieldVerificationRequired(
                            question.id,
                            sections,
                            setSections,
                            setQuestionsOnError,
                            questionsOnError,
                        );
                        if (moment(e).isValid()) {
                            setFieldValue(fieldName, moment(e).format("YYYY-MM-DD"));
                        } else {
                            setFieldValue(fieldName, "");
                        }
                    }}
                    value={getDate(field.value)}
                    format={"MM/dd/yyyy"}
                />
            </InputContainer>
            <InputContainer className="no-safari-ie">
                <FormikInputField
                    className={`question-${question.id} ${errorClassName} ${
                        question.required ? "field-required" : ""
                    }`}
                    name={fieldName}
                    type="date"
                    id={"q-" + fieldName}
                    placeholder={question.placeholder || "mm/dd/yyyy"}
                    visibleHelper={hasHelper}
                    labelHelper={helperText}
                    min={min}
                    max={max}
                    max-length="10"
                    helperPosition={helperPosition}
                    value={field.value ?? ""}
                    onChange={(e: any) => {
                        clearFieldVerificationRequired(
                            question.id,
                            sections,
                            setSections,
                            setQuestionsOnError,
                            questionsOnError,
                        );
                        meta.onChange(e);
                    }}
                    {...extraProps}
                />
            </InputContainer>
        </QuestionRowContainer>
    );
};

export default React.memo(Date);
