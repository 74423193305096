import { AxiosError } from "axios";
import { useState, useEffect, ChangeEvent } from "react";
import { ThenArg } from "./types";
import baseJWTDecode from "jwt-decode";
import moment from "moment";
import currency from "currency.js";

import * as mobxCustomTypes from "./mobxCustomTypes";

export { classNames } from "./classNames";
export * from "./UsStates";
export { mobxCustomTypes };

/**
 * Decodes a token a returns the payload object or null when there's an error
 * @param token string
 */
export function jwtDecode<T>(token: string): T | null {
    try {
        return baseJWTDecode(token);
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Returns the baseURL used for the API calls, based on the branch name.
 *
 * When the branch starts with 'feature_deploy/' or is the 'qa' branch, we
 * return a test URL that is generated from the branch name.
 * Otherwise we just return the REACT_APP_DEFAULT_API_URL environment variable
 *
 * Always return the url without a leading slash
 */
export function getBaseURL(): string {
    let baseURL = "https://qa-counterpart.test-counterpart.com/";
    const BRANCH = process.env.REACT_APP_BRANCH || "";
    if (process.env.REACT_APP_DEFAULT_API_URL) {
        baseURL = process.env.REACT_APP_DEFAULT_API_URL;
    } else if (BRANCH.startsWith("feature_deploy/")) {
        const cleanedName = BRANCH.replace(/[^A-Za-z0-9]/g, "-");
        const protocol = "https";
        const dnsName = `${cleanedName}`.slice(0, 64);
        baseURL = `${protocol}://${dnsName}.test-counterpart.com`;
    }
    return baseURL.replace(/\/$/, "");
}

/**
 * Return true when error is a instance of Error emitted by axios
 *
 * @param error any
 */
export function isAxiosError<T>(error: any): error is AxiosError<T> {
    return !!(error instanceof Error && (error as any).isAxiosError);
}

/**
 * Returns a UUID string
 */
export function generateUUID(): string {
    let dt = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (char) => {
        const rand = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (char === "x" ? rand : (rand & 0x3) | 0x8).toString(16);
    });
}

export function useApiGet<F extends (...a: any[]) => Promise<any>>(
    func: F,
    ...args: Parameters<F>
): [boolean, ThenArg<ReturnType<F>> | undefined, Error | undefined, () => void] {
    const [reloadRef, setReload] = useState(generateUUID());
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        func(...args)
            .then((d) => {
                setData(d);
                setLoading(false);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, [func, reloadRef, ...args]);

    const reload = () => setReload(() => generateUUID());
    return [loading, data, error, reload];
}

export const formatCurrency = (n: string | number | null, round = true) => {
    const number = String(n);
    const value = parseFloat(number);
    // if (!value) return "--";
    const formatted = currency(value, { separator: ",", symbol: "$", precision: 0 });
    if (round) {
        return currency(Math.round(value), { separator: ",", symbol: "$", precision: 0 })
            .format()
            .toString();
    }
    return formatted.format().toString();
};

export const formatDate = (dateToFormat: string | null, format = `MMM DD, YYYY`) => {
    if (dateToFormat !== null) {
        return moment(dateToFormat).format(format);
    }

    return null;
};

export function parseQueryString(queryString?: string): { [key: string]: string } {
    return Object.fromEntries(
        (queryString || window.location.search)
            .replace(/^\?/, "")
            .split("&")
            .filter(Boolean)
            .map((p) => p.split("=").map(decodeURIComponent)),
    );
}

export function copyToClipboard(str: string) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}

export function memoizeAsync<F extends (...a: any[]) => Promise<any>>(
    func: F,
): (...a: Parameters<F>) => Promise<ThenArg<ReturnType<F>>> {
    const cache: { [key: string]: ThenArg<ReturnType<F>> } = {};
    return async function(...args: Parameters<F>) {
        const key = JSON.stringify(args);
        if (cache[key]) {
            return JSON.parse(JSON.stringify(cache[key]));
        }
        const result = await func(...args);
        // eslint-disable-next-line require-atomic-updates
        cache[key] = result;
        return result;
    };
}

class AssertionError extends Error {}

/**
 * Raises an exception when condition is false
 *
 * @param condition Condition to be checked
 * @param message
 */
export function assert(condition: any, message?: string): asserts condition {
    if (!condition && process.env.NODE_ENV !== "production") {
        throw new AssertionError(message || "AssertionError");
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

type PromiseFunction = (...a: any[]) => Promise<any>;

export const cancelable = <F extends PromiseFunction>(func: F) => (
    ...args: Parameters<F>
): {
    result: Promise<ThenArg<ReturnType<F>>>;
    cancel: () => void;
} => {
    let hasCanceled = false;
    return {
        result: func(...args).then((r) => {
            if (hasCanceled) {
                // eslint-disable-next-line
                throw { isCancelled: true };
            }
            return r;
        }),
        cancel() {
            hasCanceled = true;
        },
    };
};

export const onTextChange = (handler: (text: string) => void, thisArg?: any) => (
    ev: ChangeEvent<HTMLInputElement>,
) => {
    if (thisArg) {
        handler.call(thisArg, ev.target.value);
    } else {
        handler(ev.target.value);
    }
};

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        );
    },
};

export const maskForDate = (value: string) => {
    const v = value.replace(/\D/g, "").slice(0, 10);
    if (v.length >= 5) {
        return `${v.slice(4)}-${v.slice(2, 4)}-${v.slice(0, 2)}`;
    } else if (v.length >= 3) {
        return `${v.slice(2)}-${v.slice(0, 2)}`;
    }
    return v;
};
