import React from "react";
import SideMenu from "features/BrokerProfile/components/SideMenu";
import styled from "styled-components/macro";
import { Surface } from "components/common";
import { BaseFont, H3, H4, Body3 } from "components/typography";
import { auth } from "services";
import rootStore from "services/store";
import { Formik, FormikHelpers } from "formik";
import { getBrokerProfile, uploadAvatar, uploadLogo, brokerUpdateProfile } from "services/users";
import { Broker } from "CounterpartApiTypes";
import AvatarChanger from "./components/AvatarChanger";
import PasswordChanger from "./components/PasswordChanger";
import ProfileForm from "./components/ProfileForm";
import Header from "./components/Header";
import Loader from "./components/Loader";
import { toast } from "react-toastify";

import responsive from "services/responsive";
import { Helmet } from "react-helmet";

const PageContainer = styled.div`
    display: flex;
    display: -ms-flex;
    @media (max-width: 767px) {
        padding-top: 40px;
    }
`;

const ContentContainer = styled.div`
    padding: 20px;
    width: 100%;
`;

const RenderContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: auto;
    position: relative;

    @media (max-width: 1300px) {
        & {
            display: block;
        }
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        flex: 1;
        -ms-flex: 1;
    }
`;

const onSubmit = (reloadData: () => void) => async (
    values: Broker,
    actions: FormikHelpers<Broker>,
) => {
    try {
        actions.setSubmitting(true);
        await brokerUpdateProfile(values, auth.getUserID() as string).then(() => {
            toast.success("Your information was updated!");
        });
        reloadData();
    } catch (error) {
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    } finally {
        actions.setSubmitting(false);
    }
};

const BottomContainer = styled.div`
    display: grid;
    grid-template-columns: 287px auto;
    background-color: rgba(194, 194, 194, 0.06);
    padding-top: 40px;
    padding-bottom: 70px;
    margin: 0 -20px -20px -20px;
    border-top: 1px solid #E4E7ED;

    @media (max-width: 900px) {
        grid-template-columns: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const TopContainer = styled.div`
    display: grid;
    grid-template-columns: 287px auto;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: -20px -20px 0 -20px;

    @media (max-width: 900px) {
        grid-template-columns: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TopTitleContainer = styled.div`
    margin-bottom: 17px;
    display: flex;
    align-items: center;

    @media (max-width: 400px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

const SectionTitle = styled(H4 as any)`
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    margin: 0;
`;

const VerifiedBadge = styled.div`
    ${BaseFont}
    background: #5CDCA3;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    padding: 5px 15px;
    font-weight: 700;
    margin-left: 11px;
`;

const BrokerageAvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: none;
    align-items: center;

    @media (max-width: 900px) {
        align-items: flex-start;
        margin-bottom: 40px;
    }
`;

const Detail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const DetailTitle = styled.p`
    color: #22232e7f;
    font-size: 11px;
    margin-bottom: 2px;
    line-height: 18px;
`;

const Value = styled(Body3 as any)`
    color: #242221;
    font-weight: 500;
`;

const Row = styled.div`
    display: flex;
`;

const AvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 900px) {
        align-items: flex-start;
        margin-bottom: 40px;
    }
`;

const AvatarTitle = styled(Body3 as any)`
    color: #22232e66;
    margin: 26px 0 0 0;
`;

const BrokerageAvatarTitle = styled(H3 as any)`
    margin: 26px 0 0 0;
    max-width: 80%;
    text-align: center;
    color: #22232e66;

    @media (max-width: 900px) {
        text-align: left;
    }
`;

type RenderContentProps = { data: Broker; reloadData: () => void };

const RenderContent = ({ data, reloadData }: RenderContentProps) => {
    const quickPageUrl = "/quickpage/" + data?.id + "/" + data?.quickPageToken;
    return (
        <RenderContentContainer>
            <TopContainer>
                <AvatarContainer>
                    <AvatarChanger
                        afterSave={reloadData}
                        saveImage={uploadAvatar}
                        src={data?.photo}
                        title="Change User Profile Image"
                        size={130}
                    />
                   <AvatarTitle>Your Image</AvatarTitle>
                </AvatarContainer>
                <SectionContainer style={{ alignItems: "start" }}>
                    <TopTitleContainer>
                        <SectionTitle>{data?.firstName} {data?.lastName}</SectionTitle>
                        <VerifiedBadge>Verified</VerifiedBadge>
                    </TopTitleContainer>
                        <Row>
                            {data?.email && <Detail>
                                <DetailTitle>EMAIL:</DetailTitle>
                                <Value style={{ wordBreak: 'break-all' }}>{data?.email || "-"}</Value>
                            </Detail>}
                        </Row>
                        <Row>
                            <Detail>
                                <DetailTitle>PHONE:</DetailTitle>
                                <Value>{data?.phoneNumber || "-"}</Value>
                            </Detail>
                        </Row>
                        <Row style={{ justifyContent: "flex-start" }}>
                            <PasswordChanger />
                        </Row>
                </SectionContainer>
                {rootStore.userInfo.adminUser && (
                    <div>
                        <a href={quickPageUrl} target="_blank" rel="noopener noreferrer">
                            View Quick Page
                        </a>
                    </div>
                )}
            </TopContainer>
            <BottomContainer>
                <BrokerageAvatarContainer>
                    <AvatarChanger
                        afterSave={reloadData}
                        saveImage={uploadLogo}
                        src={data?.brokerage.logo}
                        title="Change Brokerage Logo"
                        size={130}
                    />
                    <BrokerageAvatarTitle>Brokerage Image</BrokerageAvatarTitle>
                </BrokerageAvatarContainer>
                <Formik initialValues={data} onSubmit={onSubmit(reloadData)}>
                    <ProfileForm data={data} />
                </Formik>
            </BottomContainer>
        </RenderContentContainer>
    );
};

const BrokerProfile: React.FC<{}> = () => {
    const [menuVisible, setMenuVisible] = React.useState<boolean>(false);
    return (
        <PageContainer>
            <Helmet>
                <title>Counterpart | Profile</title>
            </Helmet>
            <SideMenu showAppetiteGuideBanner={false} visible={menuVisible} openCloseAction={setMenuVisible} />
            <ContentContainer>
                <Header />
                <Surface>
                    <Loader func={getBrokerProfile} args={[]}>
                        {RenderContent}
                    </Loader>
                </Surface>
            </ContentContainer>
        </PageContainer>
    );
};

export default React.memo(BrokerProfile);
