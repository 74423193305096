import React from "react";

import { FullQuestion, useApplicationContext, CATEGORY_QUESTION } from "services/application";

import { TYPE_TO_COMPONENT_MAP } from "./QuestionComponents";
import { useDependentQuestion } from "features/Application/dependentQuestions";
import { Tooltip } from "reactstrap";
import { isMobile } from "utils";

function getTooltipPlacement(question: FullQuestion) {
    if (CATEGORY_QUESTION.has(question.type) && !isMobile.any()) return "right-end";
    return "auto-end";
}

const QuestionRender: React.SFC<{
    question: FullQuestion;
    extraProps?: { [key: string]: any };
    disabled?: boolean;
}> = ({ question, extraProps = {} }) => {
    const { guidelines } = useApplicationContext();
    const isVisible = useDependentQuestion(question);

    if (!isVisible) return null;
    if (!question.displayOnApplication) return null;

    const Component = TYPE_TO_COMPONENT_MAP[question.type];
    if (!Component) return null;

    if (guidelines) {
        const ids = new Set(Object.keys(guidelines).map((k) => k.split("__")[0]));
        if (ids.has(question.id)) {
            const questionErrors = Object.entries(guidelines).filter(([k, _]) =>
                k.startsWith(question.id),
            );

            return (
                <>
                    <Component
                        question={question}
                        extraProps={{ ...extraProps, extraClass: "guideline-question" }}
                    />
                    {questionErrors.map(([key, err]) => (
                        <Tooltip
                            className="tooltip-guideline"
                            key={key}
                            isOpen={true}
                            autohide={false}
                            placement={getTooltipPlacement(question)}
                            target={"q-" + key}
                            dangerouslySetInnerHTML={{ __html: err }}
                        />
                    ))}
                </>
            );
        }
    }

    return <Component question={question} extraProps={extraProps} />;
};

export default QuestionRender;
