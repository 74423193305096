import React, { useRef, useCallback } from "react";
import styled from "styled-components/macro";
import { ArialBaseCSS, MuseoSansBaseCSS, CaptionText, BaseFont } from "../typography";

import ArrowUp from "assets/arrow-down-icon.svg";
import DeleteIcon from "assets/delete-gray-icon.svg";
import { ReactComponent as Spinner } from "assets/spinner.svg";
import classNames from "classnames";
const DRAG_CLASS_NAME = "on-drag";

interface Props {
    id?: string;
    icon?: any;
    name?: string;
    description?: string;
    options?: any;
    fileURL?: string | null;
    filename?: string | null;
    uploading?: boolean;
    fileUpload: (data: File | null) => void;
    deleteFunction: () => void;
    clearAfterUpload?: boolean;
    isCleared?: boolean;
    canOverride?: boolean;
    label?: string;
}

const DescriptionText = styled.span`
    ${ArialBaseCSS}
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.textColor};
    margin-left: 15px;
`;

const Name = styled(DescriptionText as any)`
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkGray};
`;

const DragAndDropContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: rgba(194, 194, 194, 0.12);
    border: 1px dashed ${(props) => props.theme.colors.third};
    padding: 25px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.darkGray};

    & svg * {
        fill: currentColor;
    }

    &.${DRAG_CLASS_NAME} {
        border-color: ${({ theme }) => theme.colors.secondary};
    }

    &.unchangeable {
        border-style: solid;
    }

    &.unchangeable svg * {
        fill: ${({ theme }) => theme.colors.systemSuccess};
    }

    &:not(.hasFile):not(.uploading) svg * {
        fill: ${({ theme }) => theme.colors.darkGray};
    }

    & svg.spinner {
        width: 16px;
        height: 16px;
    }

    &.on-error {
        border: 2px dashed #eb0f21;
    }
`;

const FileName = styled(DescriptionText as any)`
    font-weight: normal;
    margin-left: 10px;
    font-size: 13px;
    color: ${(props) => props.theme.colors.accentColor};
`.withComponent("a");

const UploadDeleteContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    transition: all ease-in-out 0.2s;

    .uploading & {
        opacity: 0;
    }
`;

const UploadDeleteButton = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.accentColor};
    cursor: pointer;
    text-decoration: underline;

    & img {
        transform: rotate(-180deg);
    }

    &.with-file {
        color: #bfcad6;
        text-decoration: none;
    }
`;

const ClearedLabel = styled.div`
    ${BaseFont}
    background: ${(props) => props.theme.colors.systemSuccess};
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    padding: 5px 15px;
    font-weight: 700;
`;

const preventDefault = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    console.log(ev.type);
    switch (ev.type) {
        case "dragenter":
        case "dragover":
            ev.currentTarget.classList.add(DRAG_CLASS_NAME);
            break;
        case "dragleave":
            ev.currentTarget.classList.remove(DRAG_CLASS_NAME);
            break;
    }
};

const DragAndDrop: React.SFC<Props> = ({
    id,
    icon,
    uploading,
    name,
    fileUpload,
    deleteFunction,
    isCleared,
    canOverride,
    fileURL,
    filename,
    label,
}) => {
    if (isCleared) {
        canOverride = false;
    }

    const inputRef = useRef<HTMLInputElement>(null);

    const hasFile = !!fileURL;

    const openOrDeleteFile = useCallback(() => {
        if (!isCleared) {
            if (hasFile) {
                const confirmDelete = window.confirm("Are you sure you want to delete this file?");
                if (confirmDelete) {
                    deleteFunction();
                }
            } else {
                inputRef.current?.click();
            }
        }
    }, [isCleared, hasFile, deleteFunction]);

    const handleDrop = useCallback(
        (ev: React.DragEvent<HTMLDivElement>) => {
            ev.preventDefault();
            if (canOverride) {
                const newFile = ev.dataTransfer.items ? ev.dataTransfer.items[0].getAsFile() : null;
                fileUpload(newFile);
            }
        },
        [canOverride, fileUpload],
    );

    const onInputChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            ev.preventDefault();
            if (canOverride && !isCleared) fileUpload(ev.target.files && ev.target.files[0]);
            if (inputRef.current) {
                inputRef.current.value = "";
            }
        },
        [fileUpload, canOverride, isCleared],
    );

    const buttonContent = hasFile ? (
        <>
            delete <img src={DeleteIcon} alt="delete" />
        </>
    ) : (
        <div style={{ textAlign: "right" }}>
            Browse file or drag and drop to upload <img src={ArrowUp} alt="upload" />
        </div>
    );

    const dragCallback = canOverride ? preventDefault : undefined;

    const containerClassNames = classNames({ uploading, hasFile, unchangeable: !isCleared });
    const iconContent = uploading ? <Spinner className={"spinner"} viewBox="25 25 50 50" /> : icon;

    return (
        <>
            <div style={{ marginBottom: 20 }} id={id} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {label && (
                    <CaptionText className="input-label" as="label">
                        {label}
                    </CaptionText>
                )}
            </div>
            <DragAndDropContainer
                onDrop={canOverride ? handleDrop : undefined}
                onDragEnter={dragCallback}
                onDragLeave={dragCallback}
                onDragOver={dragCallback}
                className={containerClassNames}
            >
                {iconContent}
                <Name>{name}</Name>
                {filename && (
                    <FileName href={fileURL} target={"_blank"}>
                        (Download)
                    </FileName>
                )}
                <UploadDeleteContainer>
                    {!isCleared ? (
                        <UploadDeleteButton
                            className={hasFile ? "with-file" : ""}
                            onClick={openOrDeleteFile}
                        >
                            {buttonContent}
                        </UploadDeleteButton>
                    ) : (
                        <ClearedLabel>cleared</ClearedLabel>
                    )}
                </UploadDeleteContainer>
            </DragAndDropContainer>
            <input
                ref={inputRef}
                style={{ display: "none" }}
                type="file"
                onChange={onInputChange}
            />
        </>
    );
};

export default React.memo(DragAndDrop);
