import styled, { css } from "styled-components/macro";

import responsive from "services/responsive";

import { TimesTitleBaseCSS, ArialBaseCSS } from "components/typography";

import { ReactComponent as SearchIcon } from "assets/search-icon.svg";
import { ReactComponent as ClearIcon } from "assets/x-close-black.svg";

export const SearchButton = styled(SearchIcon)`
    position: absolute;
    top: 12.5px;
    left: 17px;
    cursor: pointer;
    border-radius: 10px;
`;

export const ClearButton = styled(ClearIcon)`
    position: absolute;
    top: 12.5px;
    right: 17px;
    cursor: pointer;
    border-radius: 10px;
`;

export const SectionTitle = styled.h2`
    ${TimesTitleBaseCSS}
    font-size: 24px;
    font-weight: 700;
`;

export const SectionInput = styled.input`
    min-width: 360px;
    width: 100%;
    border: none;
    background: #ffffff;
    height: 40px;
    padding: 7px 45px;
    border-radius: 10px;

    &::placeholder {
        ${ArialBaseCSS}
        color: ${(props) => props.theme.colors.textColor} !important;
        opacity: 0.4;
        font-size: 14px;
        font-weight: normal;
    }

    @media (max-width: 960px) {
        min-width: 0px;
    }

    @media (max-width: 480px) {
        width: 100%;
        border: none;
        margin-bottom: 10px;
    }
`;

export const SectionInputContainer = styled.div`
    display: flex;
    max-width: 600px;
    position: relative;
    flex-flow: wrap;

    ${responsive.Mobile} & {
        flex-direction: column;
    }

    ${responsive.Tablet} & {
        max-width: unset;
        width: 100%;
    }
`;

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        margin-top: 20px;
    }
`;

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 0;
`;

//components for detail row

export const RowDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    padding: 20px;
`;

export const RowDetail = styled.div<{ column?: boolean }>`
    display: flex;
    flex-direction: ${({ column }) => (column ? "column" : "row")};
    justify-content: space-between;
    flex-grow: 1;
    align-items: baseline;

    ${({ column }) =>
        column &&
        css`
            row-gap: 4px;

            div:nth-child(2) {
                text-align: left;
                word-break: break-all;
            }
        `}
`;

export const RowLabel = styled.div`
    display: flex;
    font-size: 12px;
    line-height: 150%;
    opacity: 0.3;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    min-width: 120px;
`;

export const RowBold = styled(RowLabel as any)`
    font-size: 16px;
    font-weight: bold;
    opacity: 1;
    text-transform: none;
    min-width: 120px;
`;

export const RowContent = styled.div`
    font-size: 16px;
    line-height: 16px;
    text-align: right;
`;

export const HeadingRowDetail = styled(RowDetail as any)`
    margin-bottom: 5px;
`;

export const FilterContainer = styled.div`
    display: none;
    background: #ffffff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: left;
    width: 100%;
    margin-top: 10px;

    &.expanded {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 999;
        top: 29px;
        width: 240px;
        align-self: flex-end;
        right: -20px;
        padding-top: 20px;
        box-shadow: 0px 10px 30px rgba(38, 47, 147, 0.1);
        box-shadow: 0px 10px 10px rgba(38, 47, 147, 0.1);
        padding-bottom: 10px;
    }

    & > div.button-container {
        padding: 5px 15px;
        display: flex;
        align-items: left;
        justify-content: left;
        height: 44px;
        margin: 5px 5px 0 5px;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
    }

    & > div.button-container:hover {
        background-color: ${({ theme }) => theme.colors.backgroundLightBlue};
    }

    ${responsive.Mobile} & {
        top: 80px;
        left: 0;
        width: 100%;
    }

    ${responsive.Tablet} & {
        right: 0;
        width: calc(50% - 20px);
    }
`;

export const FilterDropdown = styled.div`
    position: relative;
    display: flex;
    background: #ffffff;
    right: 0px;
    left: 20px;
    height: 40px;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    border-radius: 10px;
    min-width: 240px;
    padding-left: 10px;

    ${responsive.Mobile} & {
        left: 0;
        width: 100%;
    }

    ${responsive.Tablet} & {
        min-width: unset;
        width: calc(50% - 20px);
    }
`;
