import React from "react";
import styled from "styled-components/macro";
import { useRouteMatch } from "react-router-dom";
import moment from "moment";
import CommonContainer from "../CommonContainer";
import { Page } from "components/common";
import { MuseoSansBaseCSS } from "components/typography";

import { DayPickerSingleDateController } from "react-dates";

// import DayPicker from "react-day-picker";

import { useAccountStore } from "features/AccountPage/store";

const Text = styled.span`
    ${MuseoSansBaseCSS}
    line-height: 150%;
    margin-top: 10px;
`;

const StyledPage = styled(Page as any)`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;

    @media (max-width: 480px) {
        align-items: center;
        flex-direction: column;
    }
`;

const EffectiveDate: React.FC = () => {
    const [effectiveDate, setEffectiveDate] = React.useState<any>(null);
    const { quote } = useAccountStore();
    const match = useRouteMatch<{
        publicSecurityToken: string;
        appSecurityToken: string;
      }>();

    const {publicSecurityToken, appSecurityToken} = match.params;

    React.useEffect(() => {
        if (effectiveDate !== null && moment(effectiveDate).isValid())
            quote?.saveEffectiveDate({
                effectiveDate: moment(effectiveDate)
                    .format("YYYY-MM-DD")
                    .toString(),
            }, {params: {
                appSecurityToken,
                publicSecurityToken
            }});
        //eslint-disable-next-line
    }, [effectiveDate]);

    React.useEffect(() => {
        if (quote?.effectiveDate !== null) setEffectiveDate(moment(quote?.effectiveDate));
        //eslint-disable-next-line
    }, []);

    return (
        <CommonContainer
            title="Select Effective Date"
            // quoteInfo={{
            //     quoteDate: quote ? formatDate(quote?.expirationDate) : "-",
            //     quoteNumber: (quote && quote?.policyNumber) || "-",
            //     commission: (quote && quote?.commissionPercentage) || "-",
            // }}
            borderBottom={true}
        >
            <StyledPage>
                <Text style={{ alignSelf: "flex-start" }}>
                    Select the date which you would like coverage to commence in the calendar below.
                </Text>
                <div>
                    <div style={{ marginTop: 30 }}>
                        <DayPickerSingleDateController
                            navNext={<></>}
                            navPrev={<></>}
                            date={effectiveDate}
                            noBorder={true}
                            daySize={
                                window.innerWidth <= 576 ? 30 : window.innerWidth <= 767 ? 40 : 45
                            }
                            numberOfMonths={2}
                            focused={true}
                            orientation={window.innerWidth <= 767 ? "vertical" : "horizontal"}
                            onDateChange={(date: any) => {
                                setEffectiveDate(date);
                            }}
                            onFocusChange={() => {
                                return;
                            }}
                            initialVisibleMonth={() => moment()}
                            isDayBlocked={(day) => {
                                if (day < moment().subtract(1, "days")) {
                                    return true;
                                }
                                return false;
                            }}
                        />
                    </div>
                </div>
            </StyledPage>
        </CommonContainer>
    );
};

export default React.memo(EffectiveDate);
