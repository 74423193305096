import React from "react";
import styled from "styled-components/macro";

import { useFormikContext } from "formik";

const Container = styled.div`
    margin: 3rem 0;
    border-radius: 10px;
    text-align: left;
    background: #f6f8fa;
`;

const ContainerHeader = styled.div`
    text-transform: uppercase;
    font-size: 11;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 500;
    background: #555;
    padding: 0.5rem;
    color: #fff;
    letter-spacing: 1px;
`;

const Pre = styled.pre`
    font-size: 0.65rem;
    padding: 0.25rem;
    overflow-x: scroll;
`;

const FormikDebug = () => {
    const {
        values,
        errors,
        touched,
        isSubmitting,
        isValidating,
        submitCount,
        initialValues,
        initialErrors,
        isValid,
        dirty,
    } = useFormikContext<any>();

    const jsonValue = JSON.stringify(
        {
            values,
            errors,
            touched,
            isSubmitting,
            isValidating,
            submitCount,
            initialValues,
            initialErrors,
            isValid,
            dirty,
        },
        null,
        4,
    );

    return (
        <Container>
            <ContainerHeader>Formik State</ContainerHeader>
            <Pre>{jsonValue}</Pre>
        </Container>
    );
};

export default FormikDebug;
