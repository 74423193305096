import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import * as auth from "./auth";
import * as logger from "./logger";
import { getBaseURL } from "../utils";

const HTTP_CLIENT = axios.create({
    baseURL: getBaseURL(),
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

const TOKEN_NOT_VALID_CODE = "token_not_valid";

/**
 * Takes a AxiosResponse, and returns true when response was an error caused by
 * invalid token.
 *
 * @param response AxiosResponse
 */
function isTokenInvalidResponse(response: AxiosResponse<any>) {
    return response.status === 401 && response.data.code === TOKEN_NOT_VALID_CODE;
}

async function RequestIntercept(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const token = await auth.getAccessTokenAsync();
    if (!token) {
        logger.debug("Token not found, sending request without it");
        return config;
    }
    config.headers.authorization = `Bearer ${token}`;
    return config;
}

async function ResponseErrorInterceptor(err: AxiosError): Promise<AxiosError | AxiosRequestConfig> {
    // If we do not got a response back just reject the request and let the
    // calling code handle it. This happens when there's a network problem.
    if (!err.response) return Promise.reject(err);

    // Force logging of 500 errors
    if (err.response.status === 500) {
        logger.error("Received a 500 error from backend");
        logger.error(err);
    }

    // This interceptor on cares about errors that have occur due to a invalid
    // token. If we receive any other error, let the calling code deal with it.
    if (!isTokenInvalidResponse(err.response)) return Promise.reject(err);

    return (async () => {
        try {
            await auth.getAccessTokenAsync();
            return HTTP_CLIENT(err.config);
        } catch {
            return Promise.reject(err);
        }
    })();
}

HTTP_CLIENT.interceptors.request.use(RequestIntercept, undefined);
HTTP_CLIENT.interceptors.response.use(undefined, ResponseErrorInterceptor);

export default HTTP_CLIENT;
