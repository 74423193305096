import React from "react";
import styled from "styled-components/macro";
import responsive from "services/responsive";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router"
import { sendStartApplicationLinkEmailQuickPage } from "services/quotes";
import { requestMagicLink } from "services/auth";

import { H1, Body3, MuseoSansBaseCSS, Body1 } from "components/typography";
import {
    HeaderForApplication,
    Container,
    Button,
    Page,
    StackedPaperPaddedContainer,
    Footer,
    Modal,
    LightBlueButton,
    Row,
    FormikInputField
} from "components/common";

import { toast } from "react-toastify";
import { checkQuickToken } from "services/users"
import Card from "./components/PageCard";
import { StyledShareIcon } from "features/Application/components/ApplicationEntry";
import { Helmet } from "react-helmet";
import logoBG from "./assets/logo-background.png";
import Loading from "components/common/Loading";
import { Form, Formik, FormikHelpers } from "formik";
import rootStore from "services/store";
import magicLink from "assets/magic-link.svg";


const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 999999;
`;

const StyledPage = styled(Page as any)`
    background-color: #fff;
    background-image: url(${logoBG});
    background-position: right -150px bottom -250px;
    background-repeat: no-repeat;
    background-size: 900px;
    min-height: 100vh;

    @media (max-width: 980px) {
        background-size: 650px;
        background-position: top 50% right -400px;
    }

    @media (max-width: 500px) {
        background-position: top 70% right -450px;
    }
`;

const StyledH1 = styled(H1 as any)`
    font-size: 38px;

    @media (max-width: 700px) {
        font-size: 28px;
    }
`;

const StyledContainer = styled(Container as any)`
    width: 100%;
    max-width: 1420px;

    & {
        margin-top: 80px;

        @media (max-width: 700px) {
            margin-top: 0;
        }
    }
`;

const PageCredits = styled.strong`
    margin-bottom: 30px;
    @media (min-width: 701px) {
        display: none;
    }
`;

const StyledPagesContainer = styled(Container as any)`
    width: 100%;
    max-width: 1420px;
`;

const CardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    width: 100%;

    @media (max-width: 700px) {
        margin-top: 60px;
    }
`;

const VerticalContainer = styled.div`
    display: flex;
    max-width: 780px;
    flex-direction: column;


    ${responsive.Tablet} & > ${StackedPaperPaddedContainer},
    ${responsive.Mobile} & > ${StackedPaperPaddedContainer} {
        min-width: unset;
    }
`;

const StyledHeaderTitle = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media (max-width: 700px) {
        display: none;
    }
`;

const TitleWrapper = styled.div`
    ${MuseoSansBaseCSS};
    display: flex;
    font-size: 18px;
`;

const PagesCardsContainer = styled.div`
    grid-gap: 30px;
    grid-auto-flow: column;
    display: flex;
    width: 100%;

    @media (max-width: 980px) {
        flex-direction: column;
        grid-gap: 20px;
    }
`;

const StyledButton = styled(Button as any)`
    padding: 20px 12px;

    @media (max-width: 400px) {
        padding: 16px 8px;
    }
`;

const BlueButton = styled(Button as any)`
    max-width: 180px;
    white-space: nowrap;
    padding: 16px 24px;
    margin-top: 40px;

    @media (max-width: 700px) {
        margin-top: 14px;
    }
`;

const DesktopButtonText = styled.span`
    @media (max-width: 700px) {
        display: none;
    }
`;

const MobileButtonText = styled.span`
    @media (min-width: 701px) {
        display: none;
    }
`;

const formikEmail: any = {
    email: "",
};

const _onSendStartPage = (setModalShareOpen: any, brokerId: string, token: string) => async (
    values: typeof formikEmail,
    actions: FormikHelpers<typeof formikEmail>,
) => {
    try {
        actions.setSubmitting(true);
        await sendStartApplicationLinkEmailQuickPage({'email': values.email, 'brokerId': brokerId, 'quickPageToken': token})
        actions.setValues(formikEmail);
        setModalShareOpen(false);
        toast.success("Invite Sent!");
    } catch (error) {
        actions.setSubmitting(false);
        toast.error("An error ocurred, email not sent!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

function openQuotesPage(setMagicModelOpen: any, setRedirect: any) {
    if(rootStore.userInfo && rootStore.userInfo.email) {
        window.open('/dashboard', "_blank")
        return;
    }
    setRedirect('/dashboard');
    setMagicModelOpen(true);
}

// function openRetailPage(setMagicModelOpen: any, setRedirect: any) {
//     if(rootStore.userInfo && rootStore.userInfo.email) {
//         window.open('/retail-agents', "_blank")
//         return;
//     }
//     setRedirect('/retail-agents');
//     setMagicModelOpen(true);
// }

// function openRiskPage(setMagicModelOpen: any, setRedirect: any) {
//     if(rootStore.userInfo && rootStore.userInfo.email) {
//         window.open('/risk-assessment', "_blank")
//         return;
//     }
//     setRedirect('/risk-assessment');
//     setMagicModelOpen(true);
// }

async function sendMagicLink(modalState: any, setLoading: any, brokerId: string, token: string, location: string = '/') {
    setLoading(true);
    try {
        await requestMagicLink({'brokerId': brokerId, 'quickPageToken': token, 'redirectUrl': location})
        modalState(false);
        toast.success("Magic Link Sent! Check your Email to Login.");
    } catch (error) {
        toast.error("An error ocurred, email not sent!");
    }
    setLoading(false);
}


const BrokerQuickPage = (): any => {
    const { brokerId, token } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [invalidToken, setInvalidToken] = React.useState(false);
    const [modalShareOpen, setModalShareOpen] = React.useState(false);
    const [magicModelOpen, setMagicModelOpen] = React.useState(false);
    const [redirect, setRedirect] = React.useState("/");
    const [broker, setBroker] = React.useState({
        "brokerId": "",
        "brokerName": "",
        "brokerageName": ""
    });
    const isLoggedIn = (rootStore.userInfo && rootStore.userInfo.email)

    React.useEffect(() => {
        try {
            checkQuickToken({ 'brokerId': brokerId, 'quickPageToken': token }).then((results: any) => {
                if(results) {
                    setBroker(results);
                    setLoading(false);
                } else {
                    setInvalidToken(true)
                }
            })
        } catch(e) {
            setInvalidToken(true)
        }
    }, [brokerId, token])

    if(invalidToken) {
        return (
            <Redirect to="/" />
        )
    }

    if (loading) {
        return (
            <StyledLoaderContainer style={{ textAlign: "center" }}>
                    <Loading message="" />
            </StyledLoaderContainer>
        )
    }

    return (
        <StyledPage>
            <Helmet>
                <title>Counterpart | Broker Quick Page</title>
            </Helmet>
            <HeaderForApplication
                style={{ maxWidth: 1420, paddingLeft: "10px", paddingRight: "10px" }}
                mobileHide={false}
                withBorderBottom
                optionalButtons={
                    <StyledButton onClick={() => (window as any).Intercom("show")}>
                        <DesktopButtonText>Ask An Underwriter</DesktopButtonText>
                        <MobileButtonText>Contact us</MobileButtonText>
                    </StyledButton>
                }
            >
                <TitleWrapper style={{ display: "flex" }}>
                    <span style={{ fontWeight: 700 }}>Counterpart</span>
                    <StyledHeaderTitle>
                        <strong>
                            {broker.brokerName}, {broker.brokerageName}
                        </strong>
                    </StyledHeaderTitle>
                </TitleWrapper>
            </HeaderForApplication>
            <StyledContainer>
                <VerticalContainer>
                    <PageCredits>
                        <strong>
                            {broker.brokerName}, {broker.brokerageName}
                        </strong>
                    </PageCredits>
                    <StyledH1>Welcome Back {broker.brokerName}</StyledH1>
                    <Body3>
                        Counterpart combines the efficiency and ease of a fully-digital application,
                        modular coverage and proprietary risk management services that will help you
                        cultivate stronger relationships with your agents.
                    </Body3>
                </VerticalContainer>
            </StyledContainer>
            <CardsWrapper>
                <StyledPagesContainer>
                    <PagesCardsContainer>
                        <Card
                            key={`item 0`}
                            item={{
                                title: "Start An Application",
                                requiresLogin: false,
                                text:
                                    "Use our digital application to receive a same-day underwriting decision.",
                                linkText: "Start An Application",
                                icon: <StyledShareIcon />,
                                onclick: () => {
                                    window.open("/startapplication?b=" + broker.brokerId, "_blank");
                                },
                            }}
                        />
                        <Card
                            key={`item 1`}
                            item={{
                                title: "Share My Application",
                                requiresLogin: false,
                                text:
                                    "Invite a retail agent or colleague to submit our digital application under your account.",
                                linkText: "Share My Application",
                                icon: <StyledShareIcon />,
                                onclick: () => {
                                    setModalShareOpen(true);
                                },
                            }}
                        />
                        <Card
                            key={`item 2`}
                            item={{
                                title: "My Policies & Quotes",
                                requiresLogin: !isLoggedIn,
                                text:
                                    "View and manage your entire book of business with Counterpart.",
                                linkText: "My Policies & Quotes",
                                icon: <StyledShareIcon />,
                                onclick: () => {
                                    openQuotesPage(setMagicModelOpen, setRedirect);
                                },
                            }}
                        />
                        {/* <Card
                            key={`item 3`}
                            item={{
                                title: "Risk Assessment",
                                requiresLogin: !isLoggedIn,
                                text: "Request a risk assessment for an account that you have submitted.",
                                onclick: () => { openRiskPage(setMagicModelOpen, setRedirect) },
                                linkText: "Risk Assessment",
                                icon: <StyledShareIcon />,
                            }}
                        /> */}
                        {/* <Card
                            key={`item 4`}
                            item={{
                                title: "My Retail Agents",
                                requiresLogin: !isLoggedIn,
                                text: "Better manage your book of business with Counterpart by adding retail agents to your account.",
                                onclick: () => { openRetailPage(setMagicModelOpen, setRedirect) },
                                linkText: "My Retail Agents",
                                icon: <StyledShareIcon />,
                            }}
                        /> */}
                    </PagesCardsContainer>
                </StyledPagesContainer>
            </CardsWrapper>
            <Footer style={{ padding: "50px 0 60px 0", marginTop: "auto" }} />
            <Modal
                isOpen={modalShareOpen}
                closeable={true}
                title="Share Application"
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalShareOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <Body1>
                        {`Invite your retail agent or colleague to start an application under your account. We will email a link to the designated email address on your behalf.`}
                    </Body1>
                    <Formik
                        initialValues={formikEmail}
                        onSubmit={_onSendStartPage(setModalShareOpen, brokerId, token)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Row>
                                    <FormikInputField
                                        name="email"
                                        label="Email address"
                                        type="email"
                                        placeholder="e.g. john@company.com"
                                    />
                                    <LightBlueButton
                                        disabled={isSubmitting}
                                        type="submit"
                                        style={{ marginLeft: 10, marginTop: 24, height: 54 }}
                                    >
                                        Send Email
                                    </LightBlueButton>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Modal
                isOpen={magicModelOpen}
                closeable={true}
                title=""
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setMagicModelOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <StyledH1>Sign into Counterpart</StyledH1>
                    <Body3>
                        Click below and we’ll email you a magic link for a password-free sign-in
                        experience.
                        <br />
                        <BlueButton
                            style={{ maxWidth: "100%" }}
                            type="button"
                            onClick={() => {
                                sendMagicLink(
                                    setMagicModelOpen,
                                    setLoading,
                                    brokerId,
                                    token,
                                    redirect,
                                );
                            }}
                        >
                            SEND MAGIC LINK
                        </BlueButton>
                    </Body3>
                    <Body3
                        style={{
                            background: "#FCFCFF",
                            borderRadius: "4px",
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <img
                                src={magicLink}
                                style={{ paddingRight: "10px" }}
                                alt="Magic Link Option"
                            />
                        </div>
                        <div>
                            Once signed in, you will be able to view and manage your accounts,
                            retail agents, and risk assessments. Or you can{" "}
                            <a href="/login?manual=true" target="_blank">
                                click here to sign-in manually
                            </a>{" "}
                            instead.
                        </div>
                    </Body3>
                </div>
            </Modal>
        </StyledPage>
    );
};

export default BrokerQuickPage;
