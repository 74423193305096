import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Page, Container, SectionContainer } from "./styled";

interface Props {
    children: React.ReactChild;
    headerChild?: React.ReactChild;
    headerDetached?: boolean;
    withSection?: boolean;
    headerStyle?: any;
    headerProps?: any;
    headerNavStyles?: any;
    stretchContainer?: boolean;
}

interface HeaderProps {
    children?: React.ReactChild;
    detached?: boolean;
}

const PageContainer: React.SFC<{ withSection: boolean; stretchContainer?: boolean }> = ({
    withSection,
    children,
    stretchContainer,
}) => {
    if (withSection) {
        return <SectionContainer stretchContainer={stretchContainer}>{children}</SectionContainer>;
    } else {
        return <Container>{children}</Container>;
    }
};

const FullPage: React.SFC<Props> = ({
    headerChild,
    children,
    withSection = false,
    headerStyle = {},
    headerProps,
    headerNavStyles,
    stretchContainer = false,
}) => {
    return (
        <Page>
            <Header headerNavStyles={headerNavStyles} style={headerStyle} {...headerProps}>
                {headerChild}
            </Header>
            <PageContainer withSection={withSection} stretchContainer={stretchContainer}>
                {children}
            </PageContainer>
            <Footer />
        </Page>
    );
};

FullPage.defaultProps = {
    headerDetached: true,
};

export default FullPage;
