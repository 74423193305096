import React, { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { useHistory, Redirect } from "react-router";

import { auth } from "services";
import Cookies from "js-cookie";
import { Application, IndustryQuestion } from "CounterpartApiTypes";
import { useApiGet, parseQueryString, noop, assert } from "utils";
import {
    getInsuranceLinesList,
    createApplication,
    getIndustryQuestionData,
    getBrokerageNameFromId,
} from "services/application";

import { H2, Body3, TextNotes, MuseoSansBaseCSS, Body1, CaptionText } from "components/typography";
import {
    HeaderForApplication,
    AddressField,
    StackedPaperLoading,
    Container,
    Row,
    StackedPaper,
    FormButton,
    Button,
    FormikInputField,
    Page,
    // Loading,
    StackedPaperPaddedContainer,
    Footer,
    Modal,
    FormikRadioCheckButton as RadioCheckButton,
} from "components/common";

import { sendStartApplicationLinkEmail } from "services/quotes";
import "./StartApplicationHelperStyles.css";
import { Suggestion } from "places.js";
import { FormikProps } from "formik";
import { FormikAutoCompleteField } from "components/common/AutoCompleteField";
import { evaluateExpression } from "utils/dynamicOperators";
import { H1MuseoSans } from "counterpart-theme";

import DarkCheck from "assets/dark_check.svg";
import { Alert } from "reactstrap";
import responsive from "services/responsive";
import rootStore from "services/store";
import { observer } from "mobx-react-lite";
import {
    StyledButtonText,
    StyledShareIcon,
} from "features/Application/components/ApplicationEntry";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const FooterWrapper = styled.div`
    z-index: 1;
    width: 100%;

    @media (max-with: 767px) {
        margin-top: -72px;
    }

    & > div {
        max-width: 1440px;
        padding: 0px 40px;
        margin: 0 auto;

        @media (max-width: 767px) {
            padding: 0px 40px;
        }

        @media (max-width: 480px) {
            padding: 0 20px;
        }
    }
`;

const StyledButton = styled(Button as any)`
    @media (max-width: 767px) {
        background-color: #fff;
        border: none;
        min-height: unset;
        min-width: unset;
        padding-right: 0;
    }

    ${StyledShareIcon} {
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }

    ${StyledButtonText} {
        color: #fff;
        opacity: 1;
    }
`;

const StartApplicationRow = styled(Row as any)`
    grid-gap: 10px;
    padding: 0px;
`;

const TwoColumnsRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-column: 1 / 5;

    ${responsive.Mobile} & {
        grid-template-columns: 1fr;
    }
`;

const RowWithCheckbox = styled(Row as any)`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr ${(props) => props.twoInputs && "1fr"};
    grid-auto-flow: unset;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    & > div {
        margin: 0;
    }

    & > div:last-child {
        @media (max-width: 768px) {
            grid-column: 1 / 2;
        }
    }

    & > div:nth-child(${(props) => (props.twoInputs ? "2" : "1")}) {
        margin-right: 0;
    }
`;

const ProductContainer = styled.div`
    & > label {
        height: 100%;
    }
    @media (max-width: 1100px) {
        font-size: 14px;
    }
`;

const CheckBoxContainer = styled.div`
    grid-column: 1 / 3;
    padding-top: 5px;

    @media (max-width: 768px) {
        grid-column: 1 / 2;
    }

    & > label.single-check {
        font-size: 14px;
        white-space: unset;
    }

    & > label > input {
        position: absolute;
    }

    & > label > span {
        margin-left: 0;
    }

    & > label > input[type="checkbox"]:not(:checked) ~ span {
        color: #22232e66;
    }
`;

const RowWithHint = styled(Row as any)`
    flex-direction: column;
    & > div:not(:last-child) {
        margin-right: 0;
    }
`;

const HintButton = styled.button`
    font-size: 14px;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0;
    color: ${({ theme }) => theme.colors.textColor};
    text-decoration: underline;
`;

const ExcludedIndustriesBody = styled.ul`
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    grid-template-columns: 1.4fr 1fr;
    grid-gap: 10px;
    padding: 0px;
    list-style-type: none;
    font-weight: bold;
    font-size: 10px;

    @media (max-width: 580px) {
        grid-template-columns: 1fr;
    }
`;

const ProductsRow = styled(Row as any)`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto;
    justify-content: space-between;

    @media (max-width: 950px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        grid-column-gap: 20px;
        grid-row-gap: 10px;
    }

    @media (max-width: 768px) {
        margin-top: 20px;
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-template-rows: unset;
        grid-auto-flow: dense;
        margin-bottom: 20px;
    }

    & > span:nth-child(1) {
        grid-column: 1 / 5;
        font-size: 12px;
        color: #22232e;
        margin-bottom: 0.5rem;

        @media (max-width: 950px) {
            grid-column: 1 / 3;
            margin-bottom: 0;
        }

        @media (max-width: 480px) {
            grid-area: auto;
        }
    }

    & > div:nth-child(2) {
        @media (max-width: 950px) {
            grid-column: 1 / 3;
        }

        @media (max-width: 480px) {
            grid-area: auto;
        }
    }

    & > div > label {
        @media (max-width: 950px) {
            width: 100%;
        }
    }

    & > div > label > input {
        position: absolute;
    }

    & > div:not(:last-child) {
        margin-right: 10px;

        @media (max-width: 950px) {
            margin: 0;
        }
    }
`;

const StyledUl = styled.ul`
    padding: 0px;
    margin-top: 40px;
    list-style-type: none;

    & li {
        max-width: 500px;
    }
`;

const StyledListItem = styled.li`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    padding-bottom: 30px;
    background-image: url(${DarkCheck});
    color: #707070;
    background-repeat: no-repeat;
    background-position: -7px 1px;
    line-height: 27px;
    padding-left: 44px;
`;

const StyledH1MuseoSans = styled(H1MuseoSans as any)`
    font-size: 32px;
    line-height: 130%;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
    max-width: 500px;
    color: #242221;
`;

const HorizontalContainer = styled.div`
    display: grid;
    grid-template-columns: 0.544fr 1fr;

    ${responsive.Tablet} &, ${responsive.Mobile} & {
        display: flex;
        width: 100%;
    }


    ${responsive.Tablet} & > ${StackedPaperPaddedContainer},
    ${responsive.Mobile} & > ${StackedPaperPaddedContainer} {
        min-width: unset;
    }
`;

const MarketingPanelItem = styled.div`
    padding: 40px 20px 40px 40px;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
        display: none;
    }
`;

const StyledStackedPaper = styled(StackedPaper as any)`
    text-align: center;
    padding: 70px 160px 100px 160px;
    box-shadow: none;

    @media (max-width: 1200px) {
        padding: 70px 80px 80px 80px;
    }

    @media (max-width: 900px) {
        padding: 20px 20px 120px 20px;
    }

    .input-label {
        @media (max-width: 800px) {
            white-space: nowrap;
        }

        @media (max-width: 600px) {
            white-space: unset;
        }
    }
`;

const Credentials = styled.span`
    font-weight: 700;
    background-color: purple;
    color: #ffffff;
    word-break: break-word;
`;

const StyledRow = styled(Row as any)`
    & > button {
        margin-left: 10px;
        margin-top: 25px;
        height: 54px;

        @media (max-width: 480px) {
            margin-left: 0;
        }
    }

    @media (max-width: 480px) {
        & > div {
            margin-right: 0 !important;
        }
    }
`;

const suggestionChosen = ({ setFieldValue }: FormikProps<ApplicationFormik>) => ({
    name,
    city,
    administrative,
    postcode,
}: Suggestion) => {
    setFieldValue("company.info.address1", name ?? "");
    setFieldValue("company.info.city", city ?? "");
    setFieldValue("company.info.state", administrative ?? "");
    setFieldValue("company.info.zipcode", postcode ?? "");
};

const TEMPLATES = {
    suggestion({ highlight }: Suggestion) {
        const { name, city, administrative, postcode } = highlight;
        const highlightedPart = [city, administrative, postcode].filter(Boolean).join(", ");

        const out = `
            <span class="ap-name">${name}</span>
            <span class="ap-address">
                ${highlightedPart}
            </span>
        `.replace(/\s*\n\s*/g, " ");
        return out;
    },
    value(suggestion: Suggestion) {
        const { name } = suggestion;
        return [name]
            .filter(Boolean)
            .join(", ")
            .trim();
    },
};

const _onSubmit = (history: any) => async (
    values: ApplicationFormik,
    actions: FormikHelpers<any>,
) => {
    const insuranceLines = Object.entries(values.insuranceLines)
        .filter(([_, v]) => v)
        .map(([k, _]) => k);

    if (insuranceLines.length === 0) {
        let statusText = "Please select at least one insurance line"
        actions.setErrors({ "values.insuranceLines": statusText });
        actions.setStatus(statusText);
        window.scrollTo(0, 0);
        return;
    }

    let newValues = { ...values, insuranceLines };

    try {
        actions.setSubmitting(true);
        newValues = Object.assign(newValues, {"industry": newValues.industrySelected});
        const data = await createApplication(newValues);
        Cookies.set("appSecurityToken", data.appSecurityToken);
        const appSecurityToken = Cookies.get("appSecurityToken");
        const loggedIn = Cookies.get("$auth_accessToken");
        const applicationPath = loggedIn
            ? `/application/${data.id}/general`
            : `/application/${data.id}/${appSecurityToken}/general`;
        history.push(`${applicationPath}`);
    } catch (error) {
        actions.setSubmitting(false);
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
            if (error.response.data.error) {
                const statusText = error.response.data.error;
                actions.setStatus(statusText);
            }
        }
        window.scrollTo(0, 0);
    }
};

type ApplicationFormik = Omit<Application, "insuranceLines"> & {
    insuranceLines: { [key: string]: boolean };
};

const initialValues: ApplicationFormik = {
    company: {
        broker: "",
        retailBroker: "",
        name: "",
        dbaName: "",
        useLegalNameAsDba: false,
        info: {
            url: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            companyWithoutWebsite: false,
        },
    },
    retailApplicationSubmitterName: '',
    retailApplicationSubmitterEmail: '',
    industry: "",
    subIndustry: "",
    industrySelected: "",
    insuranceLines: {},
};

const formikEmail: any = {
    email: "",
};

const excludedIndustries = [
    "Adult Entertainment",
    "Banking & Lending",
    "Cannabis",
    "Colleges & Universities",
    "Cryptocurrency",
    "Firearms",
    "Hospital",
    "Insurance",
    "Investing, Funds, Trusts, & Estates",
    "Nonprofit & Member Organizations",
    "Public Administration",
    "Tobacco"
];

const StyledHeaderTitle = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    @media (max-width: 767px) {
        line-height: 130%;
    }

    & > b {
        font-weight: 700;
    }
`;

const _onSendStartApplication = (setModalShareOpen: any) => async (
    values: typeof formikEmail,
    actions: FormikHelpers<typeof formikEmail>,
) => {
    try {
        actions.setSubmitting(true);
        await sendStartApplicationLinkEmail(values);
        actions.setValues(formikEmail);
        setModalShareOpen(false);
        toast.success("Invite Sent!");
    } catch (error) {
        actions.setSubmitting(false);
        toast.error("An error ocurred, email not sent!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

const loadData = async (b: any, r: any = null) => {
    const insuranceLines = getInsuranceLinesList({params: {b,r}});
    const industryQuestionData = getIndustryQuestionData({params: {b,r}});
    const brokerAdditionalInfo = getBrokerageNameFromId(b, r, "", "", "", "");
    return Promise.all([insuranceLines, industryQuestionData, brokerAdditionalInfo]);
};

const validateIndustryQuestion = (expression: any, value: string | null, errorText: string) => {
    return evaluateExpression(expression, {
        currentValue: value,
        context: { industry: value },
    })
        ? errorText
        : null;
};

const validateState = (values: ApplicationFormik) => {
    const invalidStates = rootStore.userInfo.isVPN
        ? []
        : ["LA", "LOUISIANA", "PUERTO RICO", "PR", "WV", "WEST VIRGINIA"];
    const selectedState = values.company.info.state;
    if (selectedState !== null && invalidStates.includes(selectedState.toUpperCase())) {
        return { company: { info: { state: "We can't underwrite applications for this state" } } };
    }
    return {};
};

const StartApplicationPage = (): any => {
    const history = useHistory();
    const brokerID = parseQueryString()["b"] || auth.getUserID();
    const retailBrokerID = parseQueryString()["r"] || null;
    const [loading, data] = useApiGet(loadData, brokerID, retailBrokerID);
    // eslint-disable-next-line
    const [websiteHelperVisible, setWebsiteHelperVisible] = useState<boolean>(false);
    const [modalShareOpen, setModalShareOpen] = React.useState<boolean>(false);
    const [modalExcludedOpen, setModalExcludedOpen] = useState<boolean>(false);
    const [modalStandaloneMPLOpen, setModalStandaloneMPLOpen] = useState<boolean>(false);
    const [modalUnselectOthersOpen, setModalUnselectOthersOpen] = useState<boolean>(false);
    const [modalCoverageUnavailableOpen, setModalCoverageUnavailableOpen] = useState<boolean>(
        false,
    );
    const [selectedIndustry, setSelectedIndustry] = useState<string>("");
    const [crimeInsuranceLine, setCrimeInsuranceLine] = useState<string>("");
    const onSubmit = useCallback(_onSubmit(history), [
        history
    ]);

    const handleInsuranceLines = (e:any, item: any, insuranceLinesState: any, insuranceLines: any) => {
        const MPLShort = 'mpl';
        const MPLId = insuranceLines.filter((i: any) => i.value === MPLShort)[0]?.id;
        if (item.value === MPLShort) {
            const isOtherSelected = Object.entries(insuranceLinesState).filter(e => e[0] !== MPLId).map(e => e[1]).includes(true);

            if (isOtherSelected) {
                e.preventDefault();
                e.stopPropagation();
                setModalUnselectOthersOpen(true);
            }

        } else {
            const isMPLSelected = insuranceLinesState[MPLId];

            if (isMPLSelected) {
                e.preventDefault();
                e.stopPropagation();
                setModalStandaloneMPLOpen(true);
            }
        }
    }

    const validateIndustry = (
        values: ApplicationFormik,
        industryQuestionData: IndustryQuestion,
    ) => {
        if (!values.industry) return {};
        const [option] = industryQuestionData.options.filter((i) => i.text === values.industry);
        if (!industryQuestionData.guideline) return {};
        const errorText = validateIndustryQuestion(
            industryQuestionData.guideline.jsonExpression,
            option.text,
            industryQuestionData.guideline.errorText,
        );
        if (errorText) {
            setSelectedIndustry(option.text);
            setModalCoverageUnavailableOpen(true);
            return {
                industry: errorText,
            };
        }
        return {};
    };

    React.useEffect(() => {
        const address1ElementData: HTMLInputElement = document.getElementById(
            "address1",
        ) as HTMLInputElement;
        if (address1ElementData) {
            address1ElementData.addEventListener("change", noop);
        }
    }, []);

    if (loading) {
        return <StackedPaperLoading />;
    }

    assert(data);
    const [insuranceLines, industryQuestionData, brokerage] = data;
    if (crimeInsuranceLine === "") {
        // Set a base variable for this in case it fails to keep from getting infinite loop
        setCrimeInsuranceLine("Unknown");
        insuranceLines.forEach((obj) => {
            if ((obj.value as string) === "crm") {
                setCrimeInsuranceLine(obj.id as string);
            }
        });
    }

    const { options } = industryQuestionData;

    initialValues.insuranceLines = insuranceLines.reduce((obj, item) => {
        if (['crm', 'mpl'].includes(item.value)) { // TODO: Change to MPL later
            obj[item.id as string] = false;
        } else {
            obj[item.id as string] = false;
        }
        return obj;
    }, {} as Record<string, boolean>);

    // If the user is not logged in, and we don't have a broker UUID on the URL,
    // Redirect to root
    if (!brokerID) {
        return <Redirect to="/" />;
    }

    initialValues.company.broker = brokerID || "";
    initialValues.company.retailBroker = retailBrokerID;

    return (
        <Page>
            <Helmet>
                <title>Counterpart | Start Application</title>
            </Helmet>
            <HeaderForApplication
                style={{ maxWidth: 1340, padding: "25px 10px" }}
                withBackground={true}
                headerNavStyles={{
                    height: "auto",
                }}
                mobileHide={false}
                optionalButtons={
                    <StyledButton onClick={() => setModalShareOpen(true)}>
                        <StyledShareIcon />
                        <StyledButtonText>Share Application</StyledButtonText>
                    </StyledButton>
                }
            >
                <StyledHeaderTitle>
                    {brokerage["brokerageName"] && (
                        <span>
                            <span style={{ fontWeight: 700 }}>{brokerage["brokerageName"]}</span>
                            {` | `}
                        </span>
                    )}
                    {brokerage["brokerName"] && brokerage["brokerEmail"] && (
                        <span>
                            <Credentials>
                                FOR {brokerage["brokerName"]} {brokerage["brokerEmail"]}
                            </Credentials>
                        </span>
                    )}{" "}
                    Digital Application
                </StyledHeaderTitle>
            </HeaderForApplication>
            <Container style={{ maxWidth: 1440, width: "100%", padding: 0 }}>
                <HorizontalContainer>
                    <MarketingPanelItem>
                        <StyledH1MuseoSans>
                            Management & Professional Liability insurance for the 21st century workplace
                        </StyledH1MuseoSans>
                        <StyledUl>
                            <StyledListItem>{`Coverage that is tailored to protect your business and your key managers.`}</StyledListItem>
                            <StyledListItem>{`Insights into the risk exposures of your business and guidance on how to best manage them. `}</StyledListItem>
                            <StyledListItem>{`Access to HR experts with the purchase of employment practices coverage.`}</StyledListItem>
                            <StyledListItem>{`Claims experts with decades of experience getting the best outcome for all stakeholders. `}</StyledListItem>
                        </StyledUl>
                    </MarketingPanelItem>
                    <StackedPaperPaddedContainer style={{ minWidth: 0, padding: 0 }}>
                        <StyledStackedPaper>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        maxWidth: 500,
                                    }}
                                >
                                    <H2>Let&apos;s get your application started</H2>
                                    <Body3 style={{ lineHeight: "150%", marginBottom: 35 }}>
                                        Share some basic info with us about the company so we can
                                        see if you are eligible for our proprietary coverages and
                                        services.
                                    </Body3>
                                </div>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                autoComplete="off"
                                validateOnChange
                                validate={(values: ApplicationFormik) => {
                                    return {
                                        ...(rootStore.userInfo.adminUser ||
                                        rootStore.userInfo.brokerAdminOverride
                                            ? {}
                                            : validateState(values)),
                                        ...(rootStore.userInfo.adminUser ||
                                        rootStore.userInfo.brokerAdminOverride
                                            ? {}
                                            : validateIndustry(values, industryQuestionData)),
                                    };
                                }}
                            >
                                {(props) => (
                                    <Form>
                                        {!!props.status && (
                                            <StartApplicationRow>
                                                <Alert color="danger">{props.status}</Alert>
                                            </StartApplicationRow>
                                        )}
                                        <RowWithCheckbox twoInputs>
                                            <FormikInputField
                                                className="row-field"
                                                name="company.name"
                                                placeholder="e.g. Acme, Inc."
                                                label="Legal Company Name"
                                                tooltipText="Legal Name (name of the Company used on government and legal forms)"
                                                required
                                                autoComplete="off"
                                            />
                                            <FormikInputField
                                                style={{
                                                    opacity: `${
                                                        props.values.company.useLegalNameAsDba
                                                            ? "0.4"
                                                            : "1"
                                                    }`,
                                                }}
                                                className="row-field"
                                                name={
                                                    props.values.company.useLegalNameAsDba
                                                        ? "company.name"
                                                        : "company.dbaName"
                                                }
                                                placeholder="e.g. Acme Supplies"
                                                label="Doing Business As"
                                                tooltipText="Doing Business As (DBA) Name (operating name of the Company)"
                                                autoComplete="off"
                                                disabled={props.values.company.useLegalNameAsDba}
                                            />
                                            <CheckBoxContainer>
                                                <RadioCheckButton
                                                    checkboxStyle={{ marginLeft: "5px" }}
                                                    className="single-check"
                                                    name="company.useLegalNameAsDba"
                                                    type="checkbox"
                                                    label="Company is doing business as the legal name"
                                                />
                                            </CheckBoxContainer>
                                        </RowWithCheckbox>
                                        <RowWithHint>
                                            <FormikAutoCompleteField
                                                name="industry"
                                                secondName='subIndustry'
                                                thirdName='industrySelected'
                                                placeholder="Type to search industries"
                                                label="Industry"
                                                tooltipText="If two or more industries describe the Company's operations, please use your best judgement in selecting an industry"
                                                options={options}
                                            />
                                            <HintButton
                                                style={{ marginTop: 10 }}
                                                type="button"
                                                onClick={() => setModalExcludedOpen(true)}
                                            >
                                                Click to review the current list of excluded
                                                industries
                                            </HintButton>
                                        </RowWithHint>

                                        <ProductsRow>
                                            <CaptionText>PRODUCTS</CaptionText>
                                            <TwoColumnsRow>
                                                {insuranceLines?.map((item) => (
                                                    <ProductContainer key={item.id as string}>
                                                        <RadioCheckButton
                                                            style={{ display: "flex" }}
                                                            key={item.id as string}
                                                            type="checkbox"
                                                            label={item.name}
                                                            name={`insuranceLines.${item.id}`}
                                                            tooltipText="Counterpart is unable to offer this coverage based on the industry of the applicant"
                                                            onClick={(e: any) => handleInsuranceLines(e, item, props.values.insuranceLines, insuranceLines)}
                                                        />
                                                    </ProductContainer>
                                                ))}
                                            </TwoColumnsRow>
                                        </ProductsRow>

                                        <RowWithCheckbox>
                                            <FormikInputField
                                                style={{
                                                    opacity: `${
                                                        props.values.company.info
                                                            .companyWithoutWebsite
                                                            ? "0.4"
                                                            : "1"
                                                    }`,
                                                }}
                                                className="row-field"
                                                name="company.info.url"
                                                placeholder="e.g. http://example.com"
                                                label="Website"
                                                id="website"
                                                labelHelper="http://"
                                                autoComplete="off"
                                                disabled={
                                                    props.values.company.info.companyWithoutWebsite
                                                }
                                                onChange={(e: any) => {
                                                    if (
                                                        props?.values.company.info.url &&
                                                        (props?.values.company.info.url.startsWith(
                                                            "http://",
                                                        ) ||
                                                            props?.values.company.info.url.startsWith(
                                                                "https://",
                                                            ))
                                                    ) {
                                                        setWebsiteHelperVisible(false);
                                                    } else {
                                                        setWebsiteHelperVisible(true);
                                                    }
                                                    props?.setFieldValue(
                                                        "company.info.url",
                                                        e.target.value,
                                                    );
                                                }}
                                                onFocus={() =>
                                                    props?.values.company.info.url &&
                                                    !props?.values.company.info.url.startsWith(
                                                        "http://",
                                                    ) &&
                                                    !props?.values.company.info.url.startsWith(
                                                        "https://",
                                                    ) &&
                                                    setWebsiteHelperVisible(true)
                                                }
                                                onBlur={() => {
                                                    if (
                                                        props?.values.company.info.url &&
                                                        !props?.values.company.info.url.startsWith(
                                                            "http://",
                                                        ) &&
                                                        !props?.values.company.info.url.startsWith(
                                                            "https://",
                                                        ) &&
                                                        props?.values.company.info.url.length > 0
                                                    )
                                                        props?.setFieldValue(
                                                            "company.info.url",
                                                            `http://${props?.values.company.info.url}`,
                                                        );
                                                    setWebsiteHelperVisible(false);
                                                }}
                                            />
                                            <CheckBoxContainer>
                                                <RadioCheckButton
                                                    checkboxStyle={{ marginLeft: "5px" }}
                                                    className="single-check"
                                                    type="checkbox"
                                                    label="Company does not have a website"
                                                    name="company.info.companyWithoutWebsite"
                                                    onChange={() => {
                                                        if (props?.values.company.info.url) {
                                                            props?.setFieldValue(
                                                                "company.info.url",
                                                                "",
                                                            );
                                                        }
                                                    }}
                                                />
                                            </CheckBoxContainer>
                                        </RowWithCheckbox>
                                        <StartApplicationRow>
                                            <AddressField
                                                className="row-field"
                                                label="Company Address"
                                                name="company.info.address1"
                                                placeholder="Street address, city, state zip"
                                                tooltipText="Please provide the address of the Company's headquarters"
                                                onSuggestionChosen={suggestionChosen(props)}
                                                id="address1"
                                                required
                                                templates={TEMPLATES}
                                            />
                                        </StartApplicationRow>
                                        <StartApplicationRow>
                                            <FormikInputField
                                                className="row-field"
                                                name="company.info.address2"
                                                label="Suite"
                                                placeholder="Suite, floor, building, unit"
                                                id="address2"
                                                autoComplete="off"
                                            />
                                        </StartApplicationRow>
                                        <StartApplicationRow>
                                            <FormikInputField
                                                className="row-field"
                                                name="company.info.city"
                                                placeholder="e.g. Los Angeles"
                                                label="City"
                                                id="city"
                                                required
                                                autoComplete="off"
                                            />
                                            <FormikInputField
                                                className="row-field"
                                                name="company.info.state"
                                                placeholder="e.g. California"
                                                label="State"
                                                id="state"
                                                required
                                                autoComplete="off"
                                            />
                                            <FormikInputField
                                                className="row-field"
                                                name="company.info.zipcode"
                                                placeholder="e.g. 90013"
                                                label="Zipcode"
                                                id="zipcode"
                                                required
                                                autoComplete="off"
                                            />
                                        </StartApplicationRow>
                                        {retailBrokerID && (
                                            <>
                                                <Body3 style={{lineHeight: "150%", textAlign: 'left'}}>Please provide the email contact information to whom our broker will send the quote once completed:</Body3>
                                                <StartApplicationRow>
                                                    <FormikInputField
                                                        className="row-field"
                                                        name="retailApplicationSubmitterName"
                                                        placeholder="Your name"
                                                        label="Name"
                                                        required
                                                        autoComplete="off"
                                                    />
                                                    <FormikInputField
                                                        className="row-field"
                                                        name="retailApplicationSubmitterEmail"
                                                        placeholder="Email"
                                                        type="email"
                                                        label="Your email"
                                                        required
                                                        autoComplete="off"
                                                    />
                                                </StartApplicationRow>

                                            </>
                                        )}
                                        <FormButton
                                            loading={props.isSubmitting}
                                            disabled={props.isSubmitting ? true : false}
                                            type="submit"
                                            style={{ marginTop: 25 }}
                                        >
                                            Start Application
                                        </FormButton>
                                    </Form>
                                )}
                            </Formik>
                            <TextNotes style={{ marginTop: 30 }}>
                                Note: All fields are mandatory to start an Application
                            </TextNotes>
                        </StyledStackedPaper>
                    </StackedPaperPaddedContainer>
                </HorizontalContainer>
            </Container>
            {/* <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 120,
                    width: "100%",
                }}
            >
                <H1MuseoSans
                    style={{
                        fontSize: 26,
                        lineHeight: "140%",
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    Insurance Products for Leaders
                </H1MuseoSans>
                <Container style={{ alignSelf: "center" }}>
                    <div id="insuranceLines">
                        <InsuranceLineContainer>
                            {insuranceLines?.map((item) => (
                                <InsuranceLineCheckBox
                                    key={item.id as string}
                                    item={item as InsuranceLineSaved}
                                />
                            ))}
                        </InsuranceLineContainer>
                    </div>
                </Container>
            </div> */}
            <FooterWrapper>
                <div style={{
                    maxWidth: 1350
                }}>
                    <TextNotes style={{ marginTop: 30 }}>
                        Please be advised that the HRCounterpart services provided are subject to change without prior notice. The service provider reserves the right to modify, alter, or discontinue any aspect or feature of the services, including but not limited to its availability, functionality, content, or pricing, at any time and at its sole discretion. By utilizing these services, you acknowledge and agree that the service provider shall not be liable to you or any third party for any modification, suspension, or termination of the services.
                    </TextNotes>
                </div>
                <Footer />
            </FooterWrapper>
            <Modal
                isOpen={modalUnselectOthersOpen}
                closeable={true}
                title="Only Stand Alone MPL Available"
                titlePosition="center"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalUnselectOthersOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <Body1>
                        We're sorry, we can only offer stand alone MPL coverage at this time. If you prefer to submit for MPL please unselect all other coverages and try again.
                    </Body1>
                </div>
            </Modal>
            <Modal
                isOpen={modalStandaloneMPLOpen}
                closeable={true}
                title="Only Stand Alone MPL Available"
                titlePosition="center"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalStandaloneMPLOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <Body1>
                        We're sorry, we can only offer stand alone MPL coverage at this time.
                    </Body1>
                </div>
            </Modal>
            <Modal
                isOpen={modalShareOpen}
                closeable={true}
                title="Email Application"
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalShareOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <Body1>
                        {`Invite a retail agent or client to start Counterpart's digital application. We will email a link to the designated email address on your behalf. All submissions from that link will appear in your broker dashboard.`}
                    </Body1>
                    <Formik
                        initialValues={formikEmail}
                        onSubmit={_onSendStartApplication(setModalShareOpen)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <StyledRow>
                                    <FormikInputField
                                        name="email"
                                        label="Broker Email"
                                        type="email"
                                        placeholder="e.g. john@company.com"
                                    />
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Send Email
                                    </Button>
                                </StyledRow>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Modal
                isOpen={modalExcludedOpen}
                closeable={true}
                title="Excluded industries"
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalExcludedOpen(false);
                }}
            >
                <div>
                    <Body1 style={{ marginTop: "-25px" }}>
                        {`Counterpart is unable to offer coverage for the following industries:`}
                    </Body1>
                    <ExcludedIndustriesBody>
                        {excludedIndustries.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ExcludedIndustriesBody>
                </div>
            </Modal>
            <Modal
                isOpen={modalCoverageUnavailableOpen}
                closeable={true}
                title=""
                titlePosition="center"
                buttons={[
                    <Button
                        key="btnAppetite"
                        type="button"
                        style={{ marginBottom: "5px" }}
                        onClick={() =>
                            window.open(
                                "https://counterpart-public.s3.us-west-2.amazonaws.com/Counterpart%2BAppetite%2BGuide.pdf",
                                "_blank",
                            )
                        }
                    >
                        See our Management Liability Appetite
                    </Button>,
                    <Button
                    key="btnAppetite"
                    type="button"
                    style={{ marginBottom: "5px" }}
                    onClick={() =>
                        window.open(
                            "https://counterpart-public.s3.us-west-2.amazonaws.com/MPL_Appetite_Guide_09_2023.pdf",
                            "_blank",
                        )
                    }
                >
                    See our Management Professional Liability Appetite
                </Button>,
                ]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalCoverageUnavailableOpen(false);
                }}
            >
                <div
                    style={{ textAlign: "center", padding: "0 25px 0 25px", marginBottom: "25px" }}
                >
                    <h4 style={{ marginTop: "-30px", marginBottom: "25px" }}>
                        Coverage Currently Unavailable
                    </h4>
                    <p>
                        Thanks for your interest in Counterpart! Unfortunately, at this moment we
                        are unable to provide coverage for business in the <b>{selectedIndustry}</b>{" "}
                        industry.
                    </p>
                    <p>
                        We are continuously reviewing the exposures of these industries and are
                        excited to support you with future businesses.
                    </p>
                </div>
            </Modal>
        </Page>
    );
};

export default observer(StartApplicationPage);
