import { applySnapshot, types, getSnapshot, flow, Instance, getParent } from "mobx-state-tree";
import debounce from "lodash/debounce";
import HelloSign from "hellosign-embedded";
import * as Yup from "yup";
import { AnswerFormat } from "services/application";
import { Question } from "CounterpartApiTypes";
import {
    requestSignature,
    saveSubjectivityAnswer as originalSaveSubjectivityAnswer,
    submitSupplementalApplication,
    uploadSignedApplicationRequest,
    deleteSignedApplication,
    saveHRContact,
} from "services/quotes";

import { QuoteSubjectivity } from "features/AccountPage/store";
import { checkDependentQuestion } from "features/Application/dependentQuestions";
import { isAxiosError } from "utils";
import { toast } from "react-toastify";
import { logger } from "services";

type AnswerValue = number | string | null | boolean;

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore No constructable signature for HelloSign module
const helloSignClient = new HelloSign();

const HELLOSIGN_CLIENT_ID = process.env.REACT_APP_HELLOSIGN_CLIENT_ID;

const E_SIGNATURE_ERROR_MESSAGE =
    "Error with the E-Signature request. Please contact Counterpart support by clicking the chat menu on the screen.";

export type SignatureValues = {
    hrName?: string;
    hrTitle?: string;
    hrEmail?: string;
    hrPhone?: string;
    title: string;
    name: string;
    email: string;
    phone: string;
    sameAsSignatory: boolean;
};

export const getValidationSchema = (hrRequired = false) => {
    let hrSchema = Yup.string();
    if (hrRequired) {
        hrSchema = hrSchema.required("This is Required");
    }
    return Yup.object().shape({
        title: Yup.string().required("Title is required"),
        name: Yup.string().required("Name is required"),
        email: Yup.string()
            .email()
            .required("Email is required"),
        phone: Yup.string().required("Phone is required"),
        hrName: hrSchema,
        hrTitle: hrSchema,
        hrEmail: hrSchema.email(),
        hrPhone: hrSchema,
    });
};

export const CATEGORY_QUESTION = new Set(["category", "horizontal_category", "table_category"]);

function isEmptyValue(value: any): boolean {
    if (Array.isArray(value)) return !!value.length && value.every(isEmptyValue);

    return (
        value === undefined ||
        value === null ||
        value === "" ||
        (Array.isArray(value) && !value.length)
    );
}

export const saveSubjectivityAnswer = debounce(originalSaveSubjectivityAnswer, 1500, {
    leading: false,
});

export const saveSubjectivityAnswerNow = (...args: Parameters<typeof saveSubjectivityAnswer>) => {
    saveSubjectivityAnswer.cancel();
    saveSubjectivityAnswer(...args);
    saveSubjectivityAnswer.flush();
};

const SignatureStore = types
    .model("SignatureStore", {
        title: "",
        name: "",
        email: "",
        phone: "",
        hrName: "",
        hrTitle: "",
        hrEmail: "",
        hrPhone: "",
    })
    .views((self) => ({
        get isValid() {
            const parent = getParent(self) as any;
            return getValidationSchema(parent.hasEpliCoverage).isValidSync({
                hrName: self.hrName,
                hrTitle: self.hrTitle,
                hrEmail: self.hrEmail,
                hrPhone: self.hrPhone,
                title: self.title,
                name: self.name,
                email: self.email,
                phone: self.phone,
            });
        },
    }));

export const SubjectivitiesStore = types
    .model("SubjectivitiesStore", {
        quoteId: types.string,
        quoteNumber: types.string,
        quoteExpires: types.maybeNull(types.string),
        companyName: types.string,
        brokerName: types.string,
        initialAnswers: types.map(
            types.union(types.null, types.number, types.string, types.boolean),
        ),
        initialContext: types.map(
            types.union(
                types.null,
                types.number,
                types.string,
                types.boolean,
                types.array(types.union(types.number, types.string, types.boolean)),
            ),
        ),
        answerValues: types.optional(
            types.map(
                types.array(types.union(types.null, types.number, types.string, types.boolean)),
            ),
            {},
        ),
        lastAnswersValues: types.maybeNull(types.optional(
            types.map(
                types.union(types.null, types.number, types.string, types.boolean),
            ),
            {},
        )),
        isSavingAnswers: false,
        subjectivities: types.array(QuoteSubjectivity),
        supplementalApplicationSubmitted: types.boolean,
        signature: types.optional(SignatureStore, {}),
        signedApplicationPdf: types.maybeNull(types.string),
        hasEpliCoverage: types.boolean,
        publicSecurityToken: types.string,
    })
    .volatile(() => ({
        appShowSubmitModal: false,
        appIsSubmitting: false,
        appWasSubmitted: false,
        modalShareOpen: false,
        showHowSign: false,
        showApplicationUpload: false,
        applicationSignedLocally: false,
        pageTitle: "Complete Application",
        currentPage: 0,
        hideSubjectivities: false,
    }))
    .actions((self) => ({
        setApplicationSignedLocally() {
            self.applicationSignedLocally = true;
        },
        setPageTitle(newTitle: string) {
            self.pageTitle = newTitle;
        },
        setHideSubjectivities(value: boolean) {
            self.hideSubjectivities = value;
        },
        setAnswerValues(values: Record<string, AnswerValue>) {
            const obj: Record<string, AnswerValue[]> = {};
            for (const [key, value] of Object.entries(values)) {
                const [questionID, index] = key.split("__");
                obj[questionID] = obj[questionID] || [];
                obj[questionID][parseInt(index)] = value;
            }
            // for (const [key, value] of Object.entries(obj)) {
            //     obj[key] = value.filter((val) => val !== undefined);
            // }
            applySnapshot(self.answerValues, obj);
        },
        setSubmitModalOpen() {
            self.appShowSubmitModal = true;
        },
        setSubmitModalClosed() {
            self.appShowSubmitModal = false;
        },
        setModalShareOpen() {
            self.modalShareOpen = true;
        },
        setModalShareClosed() {
            self.modalShareOpen = false;
        },
        setHowSignOpen() {
            self.showHowSign = true;
        },
        setHowSignClosed() {
            self.showHowSign = false;
        },
        setSignatureValues(values: SignatureValues) {
            applySnapshot(self.signature, values);
        },
        setCurrentPage(page: number) {
            self.currentPage = page;
        },
        setShowApplicationUpload() {
            self.showApplicationUpload = true;
        },
        hideApplicationUpload() {
            self.showApplicationUpload = false;
        },
        setIsSavingAnswer(value: boolean) {
            self.isSavingAnswers = value;
        }
    }))
    .actions((self) => ({
        _saveAnswers: flow(function* saveAnswers(values: any) {
            self.setIsSavingAnswer(true)
            const data = yield originalSaveSubjectivityAnswer(
                values,
                self.quoteId,
                self.publicSecurityToken,
            ).finally(() => {
                self.setIsSavingAnswer(false);
            });
            applySnapshot(self, data);
        }),
    }))
    .actions((self) => ({
        saveAnswers: flow(function* saveAnswers(values: any) {
            if (self.isSavingAnswers) {
                self.lastAnswersValues = values;
                self.setAnswerValues(values);
                return;
            }
            try {
                yield self._saveAnswers(values);
            } catch (error) {
                logger.error(error as Error);
                toast.error("Error while saving answers");
            } finally {
                if (self.lastAnswersValues) {
                    self._saveAnswers(self.lastAnswersValues);
                    self.lastAnswersValues = null;
                }
            }
        }),
        uploadSignedManually: flow(function* uploadSignedManually(file: File | null) {
            try {
                if (file) {
                    const data = new FormData();

                    data.append("filename", file.name);
                    data.append("file", file);
                    const response = yield uploadSignedApplicationRequest(
                        data as any,
                        self.quoteId,
                        {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        },
                    );

                    if (response && response.file) {
                        self.setPageTitle("Signature Complete");
                        const mobxUpdate = self;
                        mobxUpdate.signedApplicationPdf = response.file;
                        applySnapshot(self, mobxUpdate as any);
                    } else {
                        toast.error(
                            "Could not save your signed application, please contact counterpart to send us your file for the server.",
                        );
                    }
                }
            } catch (error) {
                alert(error);
                console.log(error);
                let message = "Was not possible to upload your file";
                if (isAxiosError(error)) {
                    if (error.response && error.response.status === 400) {
                        if (Array.isArray(error.response.data)) {
                            message = error.response.data[0];
                        }
                    }
                } else {
                    logger.error(error);
                }
                toast.error(message);
            }
        }),
        removeSignedApplication: flow(function* removeSignedApplication() {
            try {
                const response = yield deleteSignedApplication({}, self.quoteId, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                });

                if (response) {
                    const mobxUpdate = self;
                    mobxUpdate.signedApplicationPdf = response.file;
                    applySnapshot(self, mobxUpdate as any);
                } else {
                    toast.error(
                        "Could not delete your signed application, please contact counterpart to send us your file for the server.",
                    );
                }
            } catch (error) {
                alert(error);
                console.log(error);
                let message = "Was not possible to upload your file";
                if (isAxiosError(error)) {
                    if (error.response && error.response.status === 400) {
                        if (Array.isArray(error.response.data)) {
                            message = error.response.data[0];
                        }
                    }
                } else {
                    logger.error(error);
                }
                toast.error(message);
            }
        }),
    }))
    .views((self) => ({
        get uploadSubjectivities() {
            return self.subjectivities.filter((s) => s.type === "upload");
        },
        get questionSubjectivities() {
            return self.subjectivities.filter((s) => s.type === "question");
        },
    }))
    .views((self) => ({
        get questionMap() {
            const reducerFunc = (prev: Record<string, Question>, current: Question | null) => {
                if (!current) return prev;
                prev = current.subQuestions.reduce(reducerFunc, prev);
                prev[current.id] = current;
                return prev;
            };
            const questions = self.questionSubjectivities.map(
                (s) => s.question,
            ) as (Question | null)[];
            return questions.reduce<Record<string, Question>>(reducerFunc, {});
        },
        get optionalQuestionSubjectivities() {
            return self.questionSubjectivities.filter((s) => s.isOptional);
        },
        get requiredQuestionSubjectivities() {
            return self.questionSubjectivities.filter((s) => !s.isOptional);
        },
        get optionalUploadSubjectivities() {
            return self.uploadSubjectivities.filter((s) => s.isOptional);
        },
        get requiredUploadSubjectivities() {
            return self.uploadSubjectivities.filter((s) => !s.isOptional);
        },
    }))
    .views((self) => ({
        get requiredSubjectivities() {
            return self.requiredUploadSubjectivities.concat(self.requiredQuestionSubjectivities);
        },
        get optionalSubjectivities() {
            return self.optionalUploadSubjectivities.concat(self.optionalQuestionSubjectivities);
        },
    }))
    .views((self) => ({
        get answerContext(): Record<string, any> {
            const context = { ...getSnapshot(self.initialContext) };
            console.log(context);
            for (const [questionID, values] of Array.from(self.answerValues.entries())) {
                const question = self.questionMap[questionID];
                if (!question) continue;
                const oldValue = self.initialContext.get(question.ratingEngineKey);
                if (Array.isArray(oldValue)) {
                    context[question.ratingEngineKey] = values.filter(
                        (v) => v !== undefined,
                    ) as any[];
                } else {
                    context[question.ratingEngineKey] = values[0];
                }
            }
            console.log(context);
            return context;
        },
    }))
    .views((self) => ({
        get requiredErrors() {
            const context = self.answerContext;
            const excludeQuestions: Set<string> = new Set();
            const errors: string[] = [];
            for (const question of Object.values(self.questionMap)) {
                const isVisible = checkDependentQuestion(question as any, undefined, context);
                if (!isVisible) {
                    question.subQuestions.forEach((q) => {
                        excludeQuestions.add(q.id);
                    });
                }
            }
            for (const question of Object.values(self.questionMap)) {
                if (!question.required || excludeQuestions.has(question.id)) continue;
                if (CATEGORY_QUESTION.has(question.type)) continue;
                const value = self.answerContext[question.ratingEngineKey];
                if (isEmptyValue(value)) {
                    errors.push(question.id);
                }
            }
            console.log(errors);
            return errors;
        },
        get requiredSubjectivitiesFinished() {
            const subjectivityValid = self.requiredSubjectivities.every(
                (item) => item.isAnswered || item.cleared,
            );
            return subjectivityValid;
        },
        get canSubmit() {
            return self.signature.isValid;
        },
        get isSigned() {
            return self.signedApplicationPdf || self.applicationSignedLocally;
        },
        get hasSubjectivities() {
            return Boolean(self.subjectivities.length);
        },
    }))
    .views((self) => ({
        get appSignedAndSubjectivitiesSubmitted() {
            const subjectivitiesComplete =
                !self.hasSubjectivities ||
                (self.hasSubjectivities &&
                    (self.appWasSubmitted || self.supplementalApplicationSubmitted))
                    ? true
                    : false;

            return !!(self.isSigned && subjectivitiesComplete);
        },
    }))
    .actions((self) => ({
        afterCreate() {
            helloSignClient.on("sign", () => {
                self.setApplicationSignedLocally();
                helloSignClient.off("sign");
                if (self.hideSubjectivities) {
                    self.setCurrentPage(0);
                    self.setPageTitle("Signature Complete");
                } else {
                    self.setCurrentPage(1);
                }
            });
        },
        submitSubjectivities: flow(function* submitSubjectivities() {
            self.appIsSubmitting = true;
            if (self.requiredSubjectivitiesFinished) {
                try {
                    yield submitSupplementalApplication({}, self.quoteId);
                    self.appWasSubmitted = true;
                    toast.success("Subjectivities Complete!");
                    self.supplementalApplicationSubmitted = true;
                    if (!self.signedApplicationPdf) {
                        self.setCurrentPage(1);
                    }
                } catch (error) {
                    toast.error("Error while submitting");
                }
            } else {
                toast.error(
                    "Please make sure you answer and provide all required subjectivities before submitting the application",
                );
            }
            self.appIsSubmitting = false;
        }),
        startSign: flow(function* startSign() {
            if (self.canSubmit) {
                try {
                    self.appIsSubmitting = true;
                    const signatureValues = getSnapshot(self.signature);
                    const response = yield requestSignature(signatureValues, self.quoteId);
                    if (!response.signUrl) return void toast.error(E_SIGNATURE_ERROR_MESSAGE);
                    helloSignClient.open(response.signUrl, {
                        clientId: HELLOSIGN_CLIENT_ID,
                        skipDomainVerification: true,
                    });
                    self.appIsSubmitting = false;
                } catch (error) {
                    toast.error("Error while submitting");
                }
            } else {
                toast.error(
                    "Please make sure you answer all the information to proceed to sign your application",
                );
            }
        }),
        createHRContact: flow(function* createHRContact() {
            try {
                const { hrName, hrTitle, hrEmail, hrPhone } = self.signature;
                if (hrName) {
                    yield saveHRContact({ hrName, hrTitle, hrEmail, hrPhone }, self.quoteId);
                }
            } catch (error) {
                console.log(error);
            }
        }),
    }));

export const getContext = (
    initial: any,
    values: AnswerFormat,
    questionMap: Record<string, Question>,
) => {
    const newContext = Object.entries(values).reduce<Record<string, any>>((prev, current) => {
        const [key, value] = current;
        const [questionID, questionIndex] = key.split("__");
        const question = questionMap[questionID];
        if (!question) return prev;
        const currentValue = prev[question.ratingEngineKey];
        const oldValue = initial[question.ratingEngineKey];
        if (Array.isArray(currentValue) || Array.isArray(oldValue)) {
            prev[question.ratingEngineKey] = prev[question.ratingEngineKey] || [];
            prev[question.ratingEngineKey][parseInt(questionIndex)] = value;
        } else {
            prev[question.ratingEngineKey] = value;
        }
        return prev;
    }, {});

    return { ...initial, ...newContext };
};

export type SubjectivitiesStoreType = Instance<typeof SubjectivitiesStore>;
