import React, { useEffect } from "react";
import styled from "styled-components/macro";
import {
    toogleInsuranceLine,
    FullApplicationSection,
    setSectionsAndAnswers,
} from "services/application";

import QuestionRender from "./QuestionRender";
import { UnderlineBlueDiv, StyledTimesTitleFont } from "counterpart-theme";
import {
    SectionNameContainer,
    SectionQuestionsContainer,
    SectionQuestionsMainContainer,
} from "./styled";
import { useApplicationContext, AnswerFormat } from "services/application";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { useFormikContext } from "formik";
import Cookies from "js-cookie";
import { Button, EnableDisableCoverageButton } from "components/common/Button";
import { Loading } from "components/common";
import { assert } from "utils";
import { toast } from "react-toastify";
import { logger } from "services";
import { isEqual } from "lodash";
import { Body1 } from "components/typography";

interface AdditionalProps {
    SectionRatingEngineKey?: string;
    showOnlyQuestions?: boolean;
}
type SectionProps = RouteComponentProps<{
    applicationID: string;
    appSecurityToken?: string;
    sectionKey: string;
}> & AdditionalProps;;

const StyledBlueUnderline = styled(UnderlineBlueDiv as any)`
    @media (max-width: 767px) {
        display: none !important;
    }
`;

// eslint-disable-next-line
const toggleCallback = (
    applicationID: string,
    section: FullApplicationSection,
    sections: FullApplicationSection[],
    setSections: (sec: FullApplicationSection[]) => void,
    appSecurityToken?: string,
) => async () => {
    if (section.insuranceLine && applicationID) {
        const { insuranceLine, isActive: active } = section;
        try {
            const res = await toogleInsuranceLine(
                {
                    insuranceLine,
                    active: !active,
                },
                applicationID,
            );

            if (res && res.success) {
                section.isActive = !section.isActive;
                setSections([...sections]);
                // TEMPORARY FIX
                // If we have enabled coverage, refresh the page to see new questions
                // TODO remove once we enable the ability to refresh the questions when sections are updated
                const ignoreStatusFilter = Cookies.get('ignoreStatusFilter');
                if (section.isActive && !ignoreStatusFilter) {
                    const location = window.location;
                    const loggedIn = Cookies.get("$auth_accessToken");
                    const currentLocation = loggedIn
                        ? `${location.origin}/application/${applicationID}/${section.ratingEngineKey}`
                        : `${location.origin}/application/${applicationID}/${appSecurityToken}/${section.ratingEngineKey}`;
                    window.location.assign(currentLocation);
                    }
            }
        } catch (error) {
            toast.error("An Error happened when trying to change this section");
            logger.error(error);
        }
    }
};

const SectionQuestions: React.SFC<SectionProps> = ({ SectionRatingEngineKey, showOnlyQuestions }) => {
    const match = useRouteMatch<{
        applicationID: string;
        appSecurityToken: string;
        sectionKey?: string;
    }>();
    const {
        sections: _sec,
        setSections,
        checkNewGuidelines,
        setGuidelines,
        guidelines,
        questionsOnError,
        setQuestionsOnError,
    } = useApplicationContext();
    const { values, isSubmitting } = useFormikContext<AnswerFormat>();
    const sections = _sec as NonNullable<typeof _sec>;
    const SectionREK = SectionRatingEngineKey ? SectionRatingEngineKey : match.params.sectionKey;
    const sectionIndex = sections.findIndex((s) => s.ratingEngineKey === SectionREK);
    const section = sections[sectionIndex];
    const appToken = Cookies.get("appSecurityToken");
    const appSecurityToken = match.params.appSecurityToken
        ? match.params.appSecurityToken
        : appToken;
    const showOnlyQuestionsValue = showOnlyQuestions ? true : false;

    assert(section);
    assert(setSections);
    assert(checkNewGuidelines);
    assert(setGuidelines);

    useEffect(() => {
        if (section.isActive) {
            checkNewGuidelines(section, values as AnswerFormat, null);
        } else {
            setGuidelines(null);
        }
        if (showOnlyQuestionsValue && window.Intercom) {
                window.Intercom('shutdown');
        }
    });

    assert(section);

    useEffect(() => {
        if (!isSubmitting) {
            const newSections = setSectionsAndAnswers(sections, values);
            if (!isEqual(sections, newSections)) setSections(newSections);
        }
        //eslint-disable-next-line
    }, [section, isSubmitting]);

    return (
        <SectionQuestionsMainContainer>
            <div className="disabled-orverlay">&nbsp;</div>
            <SectionNameContainer>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <StyledTimesTitleFont>{section.sectionName}</StyledTimesTitleFont>
                    <StyledBlueUnderline style={{ width: 100, margin: 0, marginTop: 20 }} />
                </div>
                {section?.insuranceLine && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                        }}
                    >
                        {/* NOTE: Update the x__is_enabled logic to happen on the fly before re-enabling */}

                        <EnableDisableCoverageButton
                            type="button"
                            className={`switch-button ${section.isActive ? "enabled" : "disabled"}`}
                            onClick={toggleCallback(
                                match.params.applicationID,
                                section,
                                sections,
                                setSections,
                                appSecurityToken,
                            )}
                        >
                            {section.isActive ? "Decline coverage" : "Apply for coverage"}
                        </EnableDisableCoverageButton>
                    </div>
                )}
            </SectionNameContainer>
            <Body1
                style={{ marginTop: 30, fontSize: 16, fontWeight: 700 }}
            >{`While completing the application, please include information on any owned subsidiaries.`}</Body1>
            <SectionQuestionsContainer data-section-name="questions-container">
                {section.questions.map((q) => (
                    <QuestionRender
                        question={q}
                        key={q.id}
                        extraProps={{ questionsOnError, setQuestionsOnError }}
                    />
                ))}
            </SectionQuestionsContainer>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                }}
            >
                {isSubmitting ? (
                    <Loading />
                ) : !showOnlyQuestionsValue ? (
                    <Button style={{ zIndex: 998 }} disabled={!!guidelines} type="submit">
                        Save and Proceed
                    </Button>
                ) : null
                }
            </div>
        </SectionQuestionsMainContainer>
    );
};

export default SectionQuestions;
