import styled from "styled-components/macro";
import tooltip from "assets/blue-question-icon.svg";
import { BaseFont, TimesTitleBaseCSS } from "components/typography";

export const B1Arial = styled.div`
    color: #22232e;
    font-size: 16px;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
`;

export const NoteTextArial = styled.div`
    font-family: Arial, sans-serif;
    font-size: 11px;
`;

export const H1MuseoSans = styled.h1`
    ${BaseFont}
    color: #22232e;
    font-size: 24px;
    letter-spacing: 0.02em;
    line-height: 120%;
    text-align: center;
`;

export const StyledTimesTitleFont = styled.h1`
    ${TimesTitleBaseCSS}
    font-size: 24px;
    letter-spacing: 0.02em;
    line-height: 120%;
    text-align: center;
`;

export const MenuBarDiv = styled.div`
    color: #97989c;
    font-size: 24px;
    top: 0;
`;

export const OnePaperBackground = styled.div`
    background: #fff;
    &.application-paper {
        min-width: 300px;
        height: fit-content;
        padding: 20px;
        margin-left: 10px;

        &.section-selector {
            max-height: 100%;
        }

        @media (max-width: 1024px) {
            position: relative;
            width: 100%;
            top: 0;
            margin-bottom: 20px;
        }
    }
    border-radius: 10px;
`;

export const UnderlineBlueDiv = styled.div`
    border-bottom: 3px solid ${(props) => props.theme.colors.darkGray};
    margin: 25px auto auto;
    max-width: 100px;
`;

export const StackedPaperBackground = styled.div`
    background: #fff;
    border-radius: 10px;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.15), 15px 15px 0 -5px white,
        15px 15px 1px -4px rgba(0, 0, 0, 0.15);
    padding: 1px;
`;

const Tooltip = styled.div`
    align-self: flex-start;
    background: url(${tooltip});
    background-repeat: no-repeat;
    display: inline-table;
    height: 18px;
    text-align: center;
    width: 18px;
    min-width: 18px;
    transition: all 0.3s ease-in-out;

    &:hover {
        filter: contrast(1.1);
        transform: scale(1.1);
    }
`;
export { Tooltip };

export const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    align-self: stretch;
`;

export const ParentDiv = styled.div`
    max-width: 95%;
`;
