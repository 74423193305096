import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { auth } from "services";

/**
 * When the user hits a route marked as private, we check if he is authenticated
 * and if not redirect the user to the login page.
 */

const AuthenticatedComponent: React.SFC<RouteProps> = (props: RouteProps) => {
    const { component, ...rest } = props;
    const Component = component as React.ComponentType<RouteProps>;
    const hasAuth = !!auth.getUserID();
    const render = (props: RouteProps): React.ReactNode =>
        hasAuth ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        );

    return <Route {...rest} render={render} />;
};

export default AuthenticatedComponent;
