import React from "react";
import styled from "styled-components/macro";
import DataTable, { IDataTableProps } from "react-data-table-component";

import { H2, Body1 } from "components/typography";

import { ReactComponent as SortIcon } from "assets/sort-table-icon.svg";
import { Loading } from "components/common";

interface TableProps<T extends any> extends IDataTableProps<T> {
    noDataText?: string;
    NoDataComponentAlternative?: React.FC<{
        simpleView?: boolean;
        filterNoResults?: boolean;
        buttonText?: string;
        message?: string;
        action?: any;
    }> | null;
    store?: any;
}

interface NoDataProps {
    text?: string;
}

const customStyles: any = {
    tableWrapper: {
        display: "flex",
        margin: 0,
        padding: 0,
    },
    table: {
        style: {
            backgroundColor: "#ffffff",
            margin: 0,
            padding: 0,
            borderRadius: 4,
            height: "auto",
        },
    },
    headCells: {
        style: {
            color: "#22232e",
            fontSize: 10,
            textTransform: "uppercase",
            opacity: "0.3",
            fontWeight: 700,
            lineHeight: 150,
            fontStyle: "normal",
            paddingLeft: 10,
        },
    },
    headRow: {
        style: {
            alignItems: "flex-end",
            marginBottom: 16,
            border: "none",
            paddingLeft: 10,
        },
    },
    rows: {
        style: {
            margin: 0,
            minHeight: 83,
            borderBottomWidth: 1,
            borderBottomColor: "rgba(191, 202, 214, 0.4)",
            borderBottomStyle: "solid",
            color: "#22232e",
            fontSize: 14,
            fontFamily: "Roobert",
            lineHeight: "160%",
            fontWeight: 400,
            "@media (max-width: 958px)": {
                minHeight: "unset",
            },
        },
    },
};

const NoDataTitle = styled(H2 as any)`
    line-height: 130px;
`;

const NoDataText = styled.p`
    ${Body1}
`;

const NoDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-align: center;
    max-width: 320px;

    & ${NoDataText} {
        padding: 20px 0;
    }
`;

const StyledSortIcon = styled(SortIcon)`
    cursor: pointer;
    margin-left: 5px;
`;

const LoadingWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
`;

const CustomLoading = () => (
    <LoadingWrapper>
        <Loading />
    </LoadingWrapper>
);

const NoDataComponent: React.FC<NoDataProps> = ({ text = "Nothing to show" }) => {
    return (
        <NoDataContainer>
            <NoDataTitle>{text}</NoDataTitle>
        </NoDataContainer>
    );
};

function CustomDataTable<T>({
    noDataText = "Nothing to show",
    NoDataComponentAlternative = null,
    store,
    ...rest
}: TableProps<T>): React.ReactElement {
    const filterNoResults = (store?.types.split(",").length < 6 && store.types !== "") && !rest.data.length;
    return (
        <DataTable
            responsive={true}
            noHeader={true}
            sortServer={true}
            sortIcon={<StyledSortIcon />}
            customStyles={customStyles}
            noDataComponent={
                NoDataComponentAlternative ? (
                    <NoDataComponentAlternative
                        filterNoResults={(!!store.query && !rest.data.length) || filterNoResults}
                        action={filterNoResults ? () => { store.clearFilters(); store.changeQuery("") } : false}
                        buttonText={filterNoResults ? "Clear Filter" : "Get Quote"}
                        message={filterNoResults ? "No data to display, press “Clear\xa0Filter” button to try again" : "Click on “Get Quote” to get started"}
                    />
                ) : (
                    <NoDataComponent text={noDataText} />
                )
            }
            pagination={true}
            paginationServer={true}
            paginationPerPage={10}
            paginationComponentOptions={{ noRowsPerPage: true }}
            progressComponent={<CustomLoading />}
            {...rest}
        />
    );
}

export default CustomDataTable;
