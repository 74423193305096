import React from "react";
import styled from "styled-components/macro";

import { MuseoSansBaseCSS } from "../typography";
import classNames from "classnames";

interface Props {
    currentActiveIndex?: number;
    children: any;
    additionalHook?: any;
}

const TabMenuItem = styled.button`
    ${MuseoSansBaseCSS}
    padding: 10px 30px !important;
    font-size: 12px;
    line-height: 150%;
    font-weight: 500;
    font-style: normal;
    display: block;
    background: none;
    border: none;
    outline: none;
    color: ${(props) => props.theme.colors.secondary};
    text-transform: uppercase;
    padding: 10px 0;

    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 2px;

    @media (max-width: 567px) {
        padding: 10px !important;
    }

    &:focus {
        outline: none;
    }

    &.disabled {
        cursor: not-allowed;
        color: #bfcad6;
    }

    &.isActive {
        color: ${(props) => props.theme.colors.primary};
        border-bottom-color: ${(props) => props.theme.colors.primary};
    }
`;

const TabMenuItemContainer = styled.div<{ containerStyle?: any }>`
    ${({ containerStyle }) => containerStyle && containerStyle};
`;

const TabMenuContainer = styled.div`
    width: 100%;
    display: inline-flex;
    box-shadow: inset 0 -2px 0 ${(props) => props.theme.colors.third};
    z-index: 1;
`;

const TabContentContainer = styled.div`
    width: 100%;
    padding-top: 30px;
    max-width: 1300px;
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
`;

export const Tab: React.FC<{
    label: any;
    tabIndex: any;
    disabled?: boolean;
    isActive?: boolean;
    onClick?: any;
    containerStyle?: any;
}> = ({ label, isActive, disabled = false, onClick, containerStyle }) => {
    return (
        <TabMenuItemContainer
            className={classNames({ isActive, disabled })}
            containerStyle={containerStyle}
        >
            <TabMenuItem className={classNames({ isActive, disabled })} onClick={onClick}>
                {label}
            </TabMenuItem>
        </TabMenuItemContainer>
    );
};
const Tabs: React.FC<Props> = ({ currentActiveIndex = 0, children, additionalHook }) => {
    const [activeIndex, setActiveIndex] = React.useState<any>(currentActiveIndex);

    React.useEffect(() => {
        setActiveIndex(currentActiveIndex);
        if (additionalHook !== undefined) {
            additionalHook(currentActiveIndex);
        }
    }, [currentActiveIndex, additionalHook]);

    const onClickTabItem = (tabIndex: any, disabled = false): void => {
        if (disabled) {
            return;
        }
        setActiveIndex(tabIndex);
        if (additionalHook !== undefined) {
            additionalHook(tabIndex);
        }
    };

    return (
        <TabsContainer>
            <TabMenuContainer>
                {children?.map((child: any) => {
                    if (Array.isArray(child)) {
                        // eslint-disable-next-line
                            return child.map((child: any) => {
                            if (child && child.props) {
                                const { label, tabIndex, disabled, containerStyle } = child?.props;
                                return (
                                    <Tab
                                        key={`TabLabel__${tabIndex}`}
                                        tabIndex={tabIndex}
                                        label={label}
                                        isActive={activeIndex === tabIndex}
                                        disabled={disabled}
                                        onClick={() => onClickTabItem(tabIndex, disabled)}
                                        containerStyle={containerStyle}
                                    />
                                );
                            }
                        });
                    }
                    if (child && child.props) {
                        const { label, tabIndex, disabled, containerStyle } = child?.props;
                        return (
                            <Tab
                                key={`TabLabel__${tabIndex}`}
                                tabIndex={tabIndex}
                                label={label}
                                isActive={activeIndex === tabIndex}
                                disabled={disabled}
                                onClick={() => onClickTabItem(tabIndex, disabled)}
                                containerStyle={containerStyle}
                            />
                        );
                    }
                })}
            </TabMenuContainer>
            <TabContentContainer>
                {children?.map((child: any) => {
                    if (Array.isArray(child)) {
                        // eslint-disable-next-line
                        return child.map((child: any) => {
                            if (child && child.props) {
                                if (child.props.tabIndex !== activeIndex) return undefined;
                                return child.props.children;
                            }
                        });
                    }
                    if (child && child.props) {
                        if (child.props.tabIndex !== activeIndex) return undefined;
                        return child.props.children;
                    }
                })}
            </TabContentContainer>
        </TabsContainer>
    );
};

export default React.memo(Tabs);
