import { FormikInputField, LightBlueButton, Modal, Row } from "components/common";
import { Body1 } from "components/typography";
import React from "react";
import { toast } from "react-toastify";
import { SubjectivitiesStoreType } from "../store";
import { observer } from "mobx-react-lite";
import { Form, Formik, FormikHelpers } from "formik";
import { shareSubjectivitiesApplication } from "services/quotes";

const formikModalValues: any = {
    email: "",
};

const _onSendFunction = (setModalShareOpen: any, quoteId: any, token: any) => async (
    values: typeof formikModalValues,
    actions: FormikHelpers<typeof formikModalValues>,
) => {
    try {
        actions.setSubmitting(true);
        await shareSubjectivitiesApplication(values, quoteId, token);
        actions.setValues(formikModalValues);
        setModalShareOpen(false);
        toast.success("Invite Sent!");
    } catch (error) {
        actions.setSubmitting(false);
        toast.error("An error ocurred, email not sent!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

const ShareModal: React.FC<{ store: SubjectivitiesStoreType; isSupplemental: boolean }> = ({
    store,
    isSupplemental,
}) => {
    return (
        <Modal
            isOpen={store.modalShareOpen}
            closeable={true}
            title={`Share ${
                isSupplemental ? "Supplemental Application" : "Subjectivities"
            } & E-Signature`}
            titlePosition="left"
            buttons={[]}
            buttonStyle="vertical"
            closeAction={store.setModalShareClosed}
        >
            <div>
                <Body1>
                    {`Invite your retail agent or a colleague to complete the subjectivities and sign the application. We will email a link to the designated email address on your behalf.`}
                </Body1>
                <Formik
                    initialValues={formikModalValues}
                    onSubmit={_onSendFunction(
                        store.setModalShareClosed,
                        store.quoteId,
                        store.publicSecurityToken,
                    )}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Row>
                                <FormikInputField
                                    name="email"
                                    label="Email address"
                                    type="email"
                                    placeholder="e.g. john@company.com"
                                />
                                <LightBlueButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    style={{ marginLeft: 10, marginTop: 24, height: 54 }}
                                >
                                    Send Email
                                </LightBlueButton>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

export default observer(ShareModal);
