import React from "react";
import styled from "styled-components/macro";
import rootStore from "services/store";
import { Redirect } from "react-router"
import { useParams } from "react-router-dom";
import { Button, FullPage, StackedPaperPaddedContainer, StackedPaper } from "components/common";
import { parseQueryString } from "utils"
import Loading from "components/common/Loading";
import { requestAccountExtending } from "services/quotes"
import { toast } from "react-toastify";
import { H2, Body3 } from "components/typography";
import { CancelButton } from "components/common/Button";

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 999999;
`;

async function requestAccountExtension(quoteId: string, brokerId: string, token: string) {
    try {
        await requestAccountExtending({ 'brokerId': brokerId, 'quickPageToken': token }, quoteId)
        toast.success("Account Extension Requested")
        return true
    } catch (error) {
        return false
    }
}

const ExtendAccount: React.FC<{}> = () => {
    const { quoteId } = useParams();

    let { brokerId, token } = parseQueryString()
    brokerId = brokerId ?? "";
    token = token ?? "";

    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        setTimeout(() => {
            requestAccountExtension(quoteId, brokerId, token).then((success: boolean) => {
                setError(!success)
                setLoading(false)
            })
        }, 2000)
    }, [quoteId, brokerId, token])

    if ((rootStore === null || rootStore.userInfo === null) && (brokerId === "" || token === "")) {
        setError(true)
    }

    if (!quoteId || error) return <Redirect to="/" />;

    let nextAccountComponent = "/accounts-needing-feedback/?thanks=true"

    if (brokerId !== "" && token !== "") {
        nextAccountComponent += "&brokerId=" + brokerId + "&token=" + token
    }

    const viewExpiringDiv = (nextAccountComponent: string) => {
        window.location.href = nextAccountComponent
    };


    if (loading) {
        return (
            <StyledLoaderContainer style={{ textAlign: "center" }}>
                <Loading message="Requesting Extension" />
            </StyledLoaderContainer>
        )
    }

    return (
        <FullPage>
            <StackedPaperPaddedContainer style={{ maxWidth: "500px" }}>
                <StackedPaper style={{ textAlign: "center", position: "relative" }}>
                    <H2>Thanks for the heads up</H2>
                    <Body3 style={{ lineHeight: "150%", opacity: 0.8 }}>
                        The underwriting team has been notified and will adjust the quote accordingly or otherwise reach out to you directly.
                    </Body3>
                    <div style={{ display: "inline-grid", alignItems: "center" }}>
                        <Button
                            key="modalButton1"
                            onClick={async () => {
                                viewExpiringDiv(nextAccountComponent)
                            }}
                            style={{ minWidth: "250px", maxWidth: "300px", marginTop: "10px" }}
                        >
                            See Other Quotes
                        </Button>
                        <CancelButton
                            className="no-hover"
                            key="modalButton2"
                            onClick={() => window.location.href = "/"}
                            style={{ minWidth: "250px", maxWidth: "300px", marginTop: "10px" }}
                        >
                            Open Dashboard
                        </CancelButton>
                    </div>
                </StackedPaper>
            </StackedPaperPaddedContainer>
        </FullPage>
    );
};

export default ExtendAccount;
