import React from "react";
import { observer } from "mobx-react-lite";
import { Instance } from "mobx-state-tree";
import styled from "styled-components/macro";

import { DragAndDrop } from "components/common";
import { QuoteSubjectivity } from "features/AccountPage/store";

import { ReactComponent as PaperIcon } from "assets/paper-blue-icon.svg";
import { SubjectivityTitle, Text } from "./styled";
import { SubjectivitiesStoreType } from "../store";
import { ArialBaseCSS } from "components/typography";
import AddAnother from "features/Application/components/QuestionComponents/AddAnother"

const UploadSubjectivityContainer = styled.div`
    margin-top: 30px;

    &.cleared {
        pointer-events: none;
        opacity: 0.4;
    }
`;

const AllowedTypesUploading = styled.div`
    ${ArialBaseCSS};
    font-size: 14px;
    color: rgb(34, 35, 46, 0.3);
`

const NewUploadComponent: React.FC<{ subjectivity: Instance<typeof QuoteSubjectivity>, showAddAnother: boolean, setShowAddAnother: any }> = ({ subjectivity, showAddAnother=true, setShowAddAnother }) => {

    const [loading, setLoading] = React.useState(false)

    const uploadNewFile = ((data: any) => {
        // Run new file upload on backend
        subjectivity.saveNewFile(data);
        setShowAddAnother(true);
    });

    if(showAddAnother) {
        return <AddAnother onClick={() => setShowAddAnother(false)} />
    }

    return (
            <DragAndDrop
                id="new"
                icon={<PaperIcon />}
                name={"Upload File"}
                description={"Upload File"}
                uploading={loading}
                isCleared={subjectivity.cleared}
                canOverride={true}
                filename={null} // TODO: Refactor This
                fileUpload={async (data: any) => {
                    setLoading(true);
                    await uploadNewFile(data);
                    setLoading(false);
                }}
                deleteFunction={() => {return}}
            />
    );
};

const MultipleUploadComponent: React.FC<{ subjectivity: Instance<typeof QuoteSubjectivity>, store: SubjectivitiesStoreType }> = ({ subjectivity, store }) => {

    const cleared = subjectivity.cleared || store.supplementalApplicationSubmitted;

    let defaultAnotherDefault = (subjectivity.files && subjectivity.files.length !== 0)

    const [showAddAnother, setShowAddAnother] = React.useState(defaultAnotherDefault);

    return (
        <>
            <UploadSubjectivityContainer id={subjectivity.key} className={`${cleared ? "cleared" : ""}`}>
                <SubjectivityTitle>{subjectivity.name}</SubjectivityTitle>
                <Text>{subjectivity.language}</Text>
                {subjectivity.files.map((file: any) => (
                    <DragAndDrop
                        id={file.id}
                        icon={<PaperIcon />}
                        name={file.filename}
                        description={""}
                        fileURL={file.file} // TODO: Refactor this
                        uploading={file.loading}
                        isCleared={subjectivity.cleared}
                        canOverride={false}
                        filename={file.filename} // TODO: Refactor This
                        fileUpload={(data: any) => {
                        }}
                        deleteFunction={() => {
                            file.deleteFileForSubjectivity(subjectivity)
                        }}
                    />
                ))}
                <NewUploadComponent subjectivity={subjectivity} showAddAnother={showAddAnother} setShowAddAnother={setShowAddAnother} />
            </UploadSubjectivityContainer>
            <AllowedTypesUploading>
                The following file types are supported up to 10MB: Acrobat (.pdf), Excel (.xls, .xlsx), Word (.doc, .docx), PowerPoint (.ppt, .pptx, .pps)
            </AllowedTypesUploading>
        </>
    )
};

export default observer(MultipleUploadComponent);
