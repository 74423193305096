import React from "react";
import moment from "moment";
import { useAccountStore } from "../store";
import { BaseFont, Body3 } from "components/typography";
import styled from "styled-components/macro";

export const customNavStyles = `
  @media (max-width: 768px) {
    & > div {
      & > div {
        display: none;
      }

      & > a {
        margin: auto !important;
      }
    }
  }

`;

const StyledBody3 = styled(Body3 as any)`
    ${BaseFont}
    margin-top: 20px;
    margin-left: 20px;
    padding-left: 20px;
    border-left-color: #bfcad6;
    border-left-width: 1px;
    border-left-style: solid;
    max-width: 400px;

    @media (max-width: 768px) {
        display: none;
    }

    span {
        &.mobile {
            @media (min-width: 767px) {
                display: none;
            }
        }
    }
`;

const HeaderDetailsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        justify-content: flex-end;
        flex-direction: column-reverse;
    }

    @media (max-width: 480px) {
        display: none;
    }
`;

const HeaderDetailsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 10px;
        align-items: flex-start;
    }
`;

const HeaderDetailsInnerWrapper = styled.div`
    @media (max-width: 768px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
`;

const QuoteNumberContainer = styled.div`
    border-right: 1px solid #bfcad6;
    padding-right: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        border-right: none;
        padding: 0;
        margin: 0;
        flex-direction: row;
        align-items: center;
    }

    span {
        color: rgba(34, 35, 46, 0.5);
        font-size: 10px;

        &.mobile {
            margin-right: 2px;
            @media (min-width: 767px) {
                display: none;
            }
        }

        &.desktop {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    p {
        margin: 0;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
`;

const QuoteExpiresContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 0;
        margin: 0;
        flex-direction: row;
        align-items: center;
    }

    span {
        color: rgba(34, 35, 46, 0.5);
        font-size: 10px;

        &.mobile {
            margin-right: 2px;

            @media (min-width: 767px) {
                display: none;
            }
        }

        &.desktop {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    p {
        margin: 0;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
`;

const HeaderDetails = () => {
    const { quote } = useAccountStore();

    const formatDate = (dateStr: string) => moment(dateStr).format("MM/DD/YY");

    const isPolicy = quote.finalized;

    return (
        <HeaderDetailsWrapper>
            <StyledBody3>
                <strong style={{ fontWeight: "bold" }}>
                    {quote.accountType !== "EXCESS" ? "Surplus Line" : "Excess"}
                    {isPolicy ? " Policy " : " Quote "}
                    For{" "}
                </strong>
                <span className="mobile">
                    <br />
                </span>
                {quote.company.name}
            </StyledBody3>

            <HeaderDetailsInnerWrapper>
                <HeaderDetailsContainer>
                    <QuoteNumberContainer>
                        <span className="desktop">QUOTE NUMBER</span>
                        <span className="mobile">#:</span>
                        <p>
                            <strong>{quote.policyNumber}</strong>
                        </p>
                    </QuoteNumberContainer>
                    <QuoteExpiresContainer>
                        {isPolicy ? (
                            <span className="desktop">POLICY EXPIRES</span>
                        ) : (
                            <span className="desktop">QUOTE EXPIRES</span>
                        )}

                        <span className="mobile">EXPIRES:</span>
                        {quote.expirationDate ? (
                            <p>
                                <strong>{formatDate(quote.expirationDate)}</strong>
                            </p>
                        ) : (
                            <>-</>
                        )}
                    </QuoteExpiresContainer>
                </HeaderDetailsContainer>
            </HeaderDetailsInnerWrapper>
        </HeaderDetailsWrapper>
    );
};

export default HeaderDetails;
