import React, { useState, useCallback } from "react";
import { Redirect, useHistory, useLocation, useParams, Link } from "react-router-dom";

import { H1MuseoSans, OnePaperBackground, UnderlineBlueDiv } from "../../../counterpart-theme";

import { TimesTitleBaseCSS } from "components/typography";

import { submitApplication } from "../../../services/generated/application";
import { getApplicationFiles, getIsRenewal } from "services/application/helpers";
import {
    H1,
    H2,
    Body1,
    Body3,
    TableTitle,
    MuseoSansBaseCSS,
    ArialBaseCSS,
} from "components/typography";
import styled from "styled-components/macro";

import {
    Page,
    Loading,
    LightBlueButton,
    HeaderSingle,
    HeaderForApplication,
    ResponsiveContainer,
    StackedPaper,
    StackedPaperPaddedContainer,
    Button,
    CancelButton,
    Modal,
    RadioCheckButton,
    InputField,
    DragAndDrop,
} from "../../../components/common";
import { FullApplicationSection, useReviewInfo, useApplicationInfo } from "services/application";
import { saveApplicationFile, deleteApplicationFile } from "services/quotes";
import { auth } from "services";
import { BlueCheckMarkIcon } from "features/AccountPage/components/Icons";
import CommonContainer from "features/AccountPage/pages/CommonContainer";

import { ReactComponent as CheckIcon } from "../../../assets/check-circle-blue.svg";
import { ReactComponent as PaperIcon } from "../../../assets/paper-blue-icon.svg";

// import { Tooltip } from "../../../counterpart-theme";
// import { UncontrolledTooltip } from "reactstrap";

import AddAnother from "features/Application/components/QuestionComponents/AddAnother";
import { toast } from "react-toastify";
import { SignedApplicationFileLabelEnum } from "definitions/enums";

type QuestionLinkCountProps = {
    section: FullApplicationSection;
    count: number;
};

const filesToUpload = [
    {
        title: "Financial Statements",
        key: SignedApplicationFileLabelEnum.FINANCIAL_STATEMENTS,
    },
    {
        title: "Loss Runs",
        key: SignedApplicationFileLabelEnum.LOSS_RUNS,
    },
    {
        title: "Expiring Declarations Page",
        key: SignedApplicationFileLabelEnum.EXPIRING_DEC_PAGES,
    },
    {
        title: "Third Party Application",
        key: SignedApplicationFileLabelEnum.THIRD_PARTY_APP,
    },
    {
        title: "Other",
        key: SignedApplicationFileLabelEnum.OTHER,
    },
];

const StyledHeaderTitle = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    & > b {
        font-weight: 700;
    }
`;

export const ExclusionContainerHtml = styled.div`
    border-radius: 10px;
    margin-top: 50px;
    margin: auto;
    text-align: left;
`;

export const Exclusion = styled.div`
    background: #ffffff;
    border-bottom: 1px solid ${(props) => props.theme.colors.third};
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
`;

export const ArrowBoxImg = styled.img`
    margin-right: 10px;
    position: relative;
    float: right;
`;

export const ExclusionStyledTitle = styled.span`
    ${MuseoSansBaseCSS}
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: ${(props) => props.theme.colors.textColor};
`;

const StyledBody3 = styled(Body3 as any)`
    ${MuseoSansBaseCSS}
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: ${(props) => props.theme.colors.textColor};
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    width: 100%;
    max-width: 1200px;

    @media (max-width: 480px) {
        padding: 10px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        margin: 10px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
    }
`;

const AllowedTypesUploading = styled.div`
    ${ArialBaseCSS};
    font-size: 14px;
    color: rgb(34, 35, 46, 0.6);
    margin-top: 30px;
`;

const StyledTimesTitleFont = styled.h1`
    ${TimesTitleBaseCSS}
    font-size: 24px;
    letter-spacing: 0.02em;
    line-height: 120%;
    text-align: center;
    @media (min-width: 767px) {
        font-size: 30px;
    }
`;

const QuestionCountLink: React.FC<QuestionLinkCountProps & {
    applicationID: string;
    appSecurityToken: string
}> = ({ section, count, applicationID, appSecurityToken }) => {
    let back_to_app = appSecurityToken ? appSecurityToken + "/" + section.ratingEngineKey : section.ratingEngineKey
    return (
        <Link to={`/application/${applicationID}/${back_to_app}`}>
            <Exclusion>
                <div style={{ minWidth: "220px" }}>
                    <ExclusionStyledTitle style={{ fontWeight: "bold" }}>
                        {section.sectionName}
                    </ExclusionStyledTitle>
                </div>
                <div style={{ minWidth: "220px" }}>
                    <StyledBody3 as="span">{count} Unanswered Questions </StyledBody3>
                </div>
                <div>
                    <LightBlueButton style={{ fontSize: 10, padding: "0 12px", minHeight: 30 }}>
                        Return to section
                    </LightBlueButton>
                </div>
            </Exclusion>
        </Link>
    );
};

type MissingQuestionContainerProps = {
    sections: { section: FullApplicationSection; count: number }[];
    applicationID: string;
    appSecurityToken: string;
};

const MissingQuestionContainer: React.FC<MissingQuestionContainerProps> = ({
    sections,
    applicationID,
    appSecurityToken
}) => {
    if (!sections.length) {
        return (
            <>
                <H2 style={{ fontSize: 18 }}>Your application is almost complete!</H2>
                <Body1>
                    Please review and agree to the terms below in order to submit the application.
                </Body1>
            </>
        );
    }

    return (
        <ExclusionContainerHtml>
            <H2>Notice of Exclusion and Price Change</H2>
            <Body1>
                {`We've noticed that you have not answered every question within the application. Please note that this will adversely impact the quote and coverage that we're able to extend to your company.`}
            </Body1>
            <Exclusion style={{ borderBottom: "0px", marginBottom: "0px" }}>
                <div style={{ minWidth: "220px" }}>
                    <TableTitle
                        style={{
                            textTransform: "uppercase",
                            color: "#22232E",
                            fontWeight: "normal",
                        }}
                    >
                        Application Section
                    </TableTitle>
                </div>
                <div style={{ minWidth: "220px" }}>
                    <TableTitle
                        style={{
                            textTransform: "uppercase",
                            color: "#22232E",
                            fontWeight: "normal",
                        }}
                    >
                        Remaining Questions
                    </TableTitle>
                </div>
                <div style={{ minWidth: "140px" }}></div>
            </Exclusion>
            {sections.map((d: any, idx: number) => {
                if (d?.section?.isActive) {
                    return (
                        <QuestionCountLink
                            key={`${d.section.id}_${idx}_countLink`}
                            {...d}
                            applicationID={applicationID}
                            appSecurityToken={appSecurityToken}
                        />
                    );
                }
                return <React.Fragment key={`empty_${d?.section?.id}_${idx}`}></React.Fragment>;
            })}
        </ExclusionContainerHtml>
    );
};

const UploadNewFile: React.FC<{
    formData: any;
    setFormData: any;
    applicationID: string;
    label: string;
    isFileUploaded?: boolean;
}> = ({
    isFileUploaded,
    setFormData,
    applicationID,
    label,
}) => {
    const [uploadingFile, setUploadingFile] = useState(false);
    const [showAddAnother, setShowAddAnother] = useState(true);

    const uploadNewFile = async (data: any, applicationID: string) => {
        console.log(data);
        const dataUploading = new FormData();
        dataUploading.append("filename", data.name);
        dataUploading.append("label", label);
        dataUploading.append("file", data);
        const success: any = await saveApplicationFile(dataUploading as any, applicationID, {
            headers: {
                "content-type": "multipart/form-data",
            },
        }).catch(() => {
            return false;
        });

        if (success) {
            toast.success("Uploaded!");
            data.filename = data.name;
            data.file = success["file"];
            data.id = success["id"];
            data.label = success["label"];
            setFormData((state: any) => {
                return {
                    ...state,
                    files: [...state.files, data],
                };
            });
            setShowAddAnother(true);
        } else {
            toast.error("Could not upload your file at this time");
        }
    };

    if (isFileUploaded && showAddAnother) {
        return <AddAnother onClick={() => setShowAddAnother(false)} />
    }

    return (
        <DragAndDrop
            icon={<PaperIcon />}
            name={"Upload File"}
            description={"Upload Document"}
            fileURL=""
            uploading={uploadingFile}
            isCleared={false}
            canOverride={true}
            fileUpload={async (data: any) => {
                setUploadingFile(true);
                await uploadNewFile(data, applicationID);
                setUploadingFile(false);
            }}
            filename={null}
            deleteFunction={() => {
                return;
            }}
        />
    );
};

const initialFormData = {
    additionalDetails: "",
    files: [],
};

const SubmitReview = () => {
    const history = useHistory();
    const location = useLocation();
    const { applicationID, appSecurityToken } = useParams();
    const { loading, sectionsMissing, error } = useReviewInfo(
        applicationID as string,
        appSecurityToken as string,
        undefined,
    );
    const [submittingApplication, setSubmittingApplication] = useState(false);
    const [applicationSubmittedSuccessfully, setApplicationSubmittedSuccessfully] = useState(false);
    const [quoteRedirect, setQuoteRedirect] = useState("");
    const [agreedOne, setAgreedOne] = useState(false);
    const [agreedTwo, setAgreedTwo] = useState(false);
    const [submitModal, setSubmitModal] = useState(false);
    const [submitedModal, setSubmitedModal] = useState(false);
    const [SubmitAndSign, SetSubmitAndSign] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const { uploadedFiles } = getApplicationFiles();
    //const [agreedThree, setAgreedThree] = useState(false);
    const [applicationWasDenied, setApplicationWasDenied] = useState(false);
    const _ApplicationDeniedInital =
        'We were unable to process your application due to technical issues. Please press "Go Back" and resubmit the application or contact us directly with any questions at support@yourcounterpart.com.';
    const [applicationDeniedError, setApplicationDeniedError] = useState(_ApplicationDeniedInital);
    const hasAuth = !!auth.getUserID();

    const isRenewal = getIsRenewal();

    const cancelApplication = () => {
        const url = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
        if (url) history.push(`${url}/general`);
    };
    //this allows to user know the information even on logged out
    const { companyName, brokerageName } = useApplicationInfo(applicationID, appSecurityToken);

    // const defaultAnotherDefault = formData.files && formData.files.length !== 0;
    // const [showAddAnother, setShowAddAnother] = useState(defaultAnotherDefault);


    React.useEffect(() => {
        if (uploadedFiles && uploadedFiles.length) {
            setFormData((state: any) => {
                if (!state.files.length) {
                    return {
                        ...state,
                        files: uploadedFiles,
                    };
                } else {
                    return {
                        ...state,
                        files: [...state.files],
                    };
                }
            });
        }
    }, [uploadedFiles]);

    const submit = useCallback(
        (formData: any) => {
            console.log("submit");
            setSubmitModal(false);
            setSubmitedModal(false);
            setSubmittingApplication(true);
            const submitBackendHelpers = submitApplication(
                {
                    additionalDetails: formData["additionalDetails"],
                    appSecurityToken: appSecurityToken,
                },
                applicationID || "",
            );

            submitBackendHelpers
                .then((result) => {
                    const quoteID = result.quote;
                    const token = result.publicSecurityToken;
                    if (!quoteID) {
                        alert("No quote id returned " + JSON.stringify(result));
                        setSubmittingApplication(false);
                        setApplicationSubmittedSuccessfully(false);
                        return;
                    }
                    if (SubmitAndSign) {
                        setQuoteRedirect(`/subjectivities/${quoteID}/${token}?sign=TRUE`);
                    } else {
                        setQuoteRedirect(`/account/${quoteID}`);
                    }
                    setSubmittingApplication(false);
                    setApplicationSubmittedSuccessfully(true);
                    setSubmitedModal(true);
                })
                .catch((err) => {
                    setApplicationWasDenied(true);
                    if (err?.response?.data !== undefined) {
                        let message = err.response.data["Message"];
                        if (message === undefined || message === null) {
                            message =
                                'We were unable to process your application due to technical issues. Please press "Go Back" and resubmit the application or contact us directly with any questions at support@yourcounterpart.com.';
                        } else {
                            message = message.replace(
                                "ApplicationDeclinedError:",
                                "Application Declined:",
                            );
                        }
                        setApplicationDeniedError(message);
                    } else {
                        setApplicationDeniedError(_ApplicationDeniedInital);
                    }
                    setSubmittingApplication(false);
                });
        },
        [SubmitAndSign, applicationID, appSecurityToken],
    );

    React.useEffect(() => {
        if (SubmitAndSign) {
            submit(formData);
        }
    }, [SubmitAndSign, submit, formData]);

    const deleteFile = async (item: any) => {
        console.log(item.id);
        const success = await deleteApplicationFile(
            { id: item["id"] } as any,
            applicationID,
            {},
        ).catch(() => {
            return false;
        });

        if (success) {
            toast.success("File Delete");
            const files = formData.files.filter((i: any) => {
                if (i.id === item.id) {
                    return false;
                } else {
                    return true;
                }
            });
            // if (files.length === 0) {
            //     setShowAddAnother(false);
            // }
            setFormData((state) => {
                return {
                    ...state,
                    files,
                };
            });
        } else {
            toast.error("Could not delete your file at this time");
        }
    };

    if (loading) {
        return (
            <Page>
                <HeaderSingle />
                <OnePaperBackground className="text-center" style={{ width: "80%" }}>
                    <div className="py-5">
                        <Loading message="Loading..." />
                    </div>
                </OnePaperBackground>
            </Page>
        );
    }
    if (error) {
        return (
            <Page>
                <HeaderSingle />
                <OnePaperBackground className="text-center">
                    <ContentContainer>
                        <H1>Error Loading this page</H1>
                    </ContentContainer>
                </OnePaperBackground>
            </Page>
        );
    }

    if (applicationSubmittedSuccessfully) {
        if (hasAuth) {
            return (
                <OnePaperBackground className="text-center mb-5">
                    <ContentContainer>
                        <H1MuseoSans className="font-weight-bold my-5">
                            Your application has been successfully submitted!!
                        </H1MuseoSans>
                        <h3 className="mt-4 text-center">
                            Application ID: <code>{applicationID}</code>
                        </h3>
                    </ContentContainer>
                    <Redirect to={quoteRedirect} />
                </OnePaperBackground>
            );
        } else {
            return (
                <Page>
                    <HeaderForApplication style={{ maxWidth: 1200 }}>
                        <StyledHeaderTitle>
                            <b>{brokerageName}</b>
                            {` | `}
                            Application for <b>{companyName}</b>
                        </StyledHeaderTitle>
                    </HeaderForApplication>
                    <ResponsiveContainer style={{ maxWidth: 1200 }}>
                        <StackedPaperPaddedContainer>
                            <StackedPaper style={{ width: "100%" }}>
                                <CommonContainer
                                    style={{ maxWidth: 800 }}
                                    title={"Application Submitted"}
                                    additionalInfo={{
                                        title: "Application Reference Number: ",
                                        text: applicationID,
                                    }}
                                    borderBottom={true}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: 30,
                                        }}
                                    >
                                        <BlueCheckMarkIcon />
                                        <Body3 style={{ textAlign: "center", marginTop: 30 }}>
                                            {`Thank you for submitting your application! Our underwriting team needs to take a look at the application and may contact you for additional information. We'll be in touch with a decision within the next 24 hours. If you have any questions please get in touch with our underwriting team.`}
                                        </Body3>
                                    </div>
                                </CommonContainer>
                            </StackedPaper>
                        </StackedPaperPaddedContainer>
                    </ResponsiveContainer>
                </Page>
            );
        }
    } else {
        return (
            <Page>
                <HeaderForApplication style={{ maxWidth: 1200 }}>
                    <StyledHeaderTitle>
                        <b>{brokerageName}</b>
                        {` | `}
                        Application for <b>{companyName}</b>
                    </StyledHeaderTitle>
                </HeaderForApplication>
                <OnePaperBackground style={{ maxWidth: 900 }} className="application-paper">
                    <ContentContainer>
                        <StyledTimesTitleFont
                            className="font-weight-bold"
                            style={{ textAlign: "left" }}
                        >
                            Application Review
                        </StyledTimesTitleFont>
                        <UnderlineBlueDiv style={{ margin: "25px 0px" }} />
                        <div
                            style={{
                                alignItems: "center",
                            }}
                        >
                            <MissingQuestionContainer
                                sections={sectionsMissing as NonNullable<typeof sectionsMissing>}
                                applicationID={applicationID as string}
                                appSecurityToken={appSecurityToken as string}
                            />
                        </div>
                        <p
                            style={{
                                color: "#FF0000",
                                fontWeight: "bold",
                                textDecoration: "underline",
                                marginTop: "20px",
                                fontFamily: "Arial",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                            }}
                            className={`${!applicationWasDenied ? "hidden" : ""}`}
                        >
                            {applicationDeniedError}
                        </p>
                        <div style={{ marginTop: 60 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <H2>Additional Details</H2>
                                <Body3 style={{ opacity: 0.6, marginBottom: 0 }}>Optional</Body3>
                            </div>
                            <InputField
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    setFormData((state) => {
                                        return {
                                            ...state,
                                            additionalDetails: value,
                                        };
                                    });
                                }}
                                optional
                                type="textarea"
                                placeholder="Please provide any additional details on the Company's financials, M&A activity, layoffs, litigation, or related events."
                                style={{ height: "72px !important" }}
                            />
                        </div>

                        <div
                            style={{
                                marginTop: 60,
                                marginBottom: 20,
                                // paddingBottom: 60,
                                // borderBottom: "1px solid #BFCAD6",
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <H2>Additional Documentation</H2>
                                <Body3 style={{ opacity: 0.6, marginBottom: 0 }}>Optional</Body3>
                            </div>
                            <Body1 style={{ display: "inline" }}>
                                Please upload any common subjectivities (loss runs, financials,
                                expiring dec pages) to streamline the quote and bind process.
                            </Body1>
                            {filesToUpload.map((file: { key: string; title: string }, index) => {
                                // console.log(file)
                                // const fileKey: string = file[key];
                                const uploadedFiles: any = formData.files.filter(
                                    (f: { label: string }) => f.label === file.key,
                                );
                                // const file2 = formData.files[file[key]];
                                // const file3 = formData.files[file[key]];
                                if (uploadedFiles.length) {
                                    return (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginBottom: -18,
                                                    marginTop: index === 0 ? 32 : 24,
                                                }}
                                            >
                                                <H2 style={{ fontSize: 16 }}>{file.title}</H2>
                                            </div>
                                            {uploadedFiles.map((uploadedFile: any) => (
                                                <DragAndDrop
                                                    id={uploadedFile.id}
                                                    icon={<PaperIcon />}
                                                    name={uploadedFile.filename}
                                                    description={"Upload Document"}
                                                    fileURL={uploadedFile.file} // indicates if file is uploaded
                                                    uploading={false}
                                                    isCleared={false}
                                                    canOverride={true}
                                                    fileUpload={() => {
                                                        return;
                                                    }}
                                                    // label="upload document"
                                                    filename={uploadedFile.filename}
                                                    deleteFunction={() => {
                                                        deleteFile(uploadedFile);
                                                    }}
                                                />
                                            ))}
                                            <UploadNewFile
                                                isFileUploaded={!!uploadedFiles}
                                                formData={formData}
                                                setFormData={setFormData}
                                                applicationID={applicationID}
                                                label={file.key}
                                            />
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginBottom: -18,
                                                    marginTop: index === 0 ? 32 : 24,
                                                }}
                                            >
                                                <H2 style={{ fontSize: 16 }}>{file.title}</H2>
                                            </div>
                                            <UploadNewFile
                                                formData={formData}
                                                setFormData={setFormData}
                                                applicationID={applicationID}
                                                label={file.key}
                                            />
                                        </>
                                    );
                                }
                            })}
                        </div>
                        <AllowedTypesUploading>
                            We accept all major document file formats. DOC, DOCX, HTML, PDF, ODT,
                            TXT, and many more.
                        </AllowedTypesUploading>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            <RadioCheckButton
                                className={"no-border left-align top-align"}
                                type="checkbox"
                                label="By checking this box you certify that all information provided within the application is accurate."
                                checked={agreedOne}
                                value={`${agreedOne}`}
                                onClick={() => setAgreedOne(!agreedOne)}
                            />
                            <RadioCheckButton
                                className={"no-border left-align top-align"}
                                type="checkbox"
                                label="By checking this box you agree to the subjectivity clause requiring the applicant to sign the application prior to binding."
                                checked={agreedTwo}
                                value={`${agreedTwo}`}
                                onClick={() => setAgreedTwo(!agreedTwo)}
                            />
                        </div>

                        {/* <CheckboxWithLabel
                            label="By checking this box you agree to the Warranty and Fraud Statement and consent to our Agreement to conduct Electronic Signature, Terms and Conditions, and Privacy policy."
                            value={false}
                            onChange={setAgreedThree}
                        /> */}
                        <div className="text-center" style={{ marginBottom: 30 }}>
                            <Loading
                                show={submittingApplication}
                                message="Submitting Application"
                            />
                        </div>
                        <div style={{ alignItems: "center", display: "inline-block" }}></div>
                        <ButtonContainer>
                            <div>
                                <CancelButton
                                    className="no-hover with-border"
                                    style={{ padding: "14px", fontSize: "14px", minWidth: "200px" }}
                                    onClick={cancelApplication}
                                >
                                    Go Back
                                </CancelButton>
                            </div>
                            <div>
                                <Button
                                    style={{ padding: "15px", fontSize: "14px", minWidth: "200px" }}
                                    onClick={() => setSubmitModal(true)}
                                    disabled={!(agreedOne && agreedTwo) || submittingApplication}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ButtonContainer>
                    </ContentContainer>
                </OnePaperBackground>
                <Modal
                    isOpen={!isRenewal && submitModal}
                    closeable={true}
                    closeAction={() => setSubmitModal(false)}
                    title="Are you sure you want to submit?"
                    buttonStyle="vertical"
                    buttons={[
                        <Button
                            style={{ width: 250, height: 48 }}
                            key="modalButton1"
                            onClick={() => {
                                submit(formData);
                            }}
                        >
                            Submit Application
                        </Button>,
                        <CancelButton
                            style={{ width: 250, height: 48 }}
                            className="no-hover"
                            key="modalButton2"
                            onClick={cancelApplication}
                        >
                            Return to Application
                        </CancelButton>,
                    ]}
                >
                    <p style={{ fontStyle: "italic" }}>
                        {`Please make sure that your application is complete and accurate prior to submitting. Once submitted you will no longer be able to make changes.`}
                    </p>
                </Modal>
                <Modal
                    isOpen={submitedModal}
                    closeable={true}
                    closeAction={() => setSubmitedModal(false)}
                    title="Application Received!"
                    iconTitle={<CheckIcon />}
                    buttonStyle="vertical"
                    buttons={[
                        <Button key="modalButton1" onClick={() => setSubmitedModal(false)}>
                            Risk Mitigation
                        </Button>,
                    ]}
                >
                    <div
                        style={{
                            padding: "20px",
                            marginBottom: "30px",
                            textAlign: "center",
                            fontSize: "16px",
                            lineHeight: "150%",
                            fontFamily: "Arial,sans-serif",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <p>
                            <b>Application Reference Number: {applicationID}</b>
                        </p>
                        <p>
                            Thank you for completing your application. Your broker will be in
                            contact with you shortly details about which coverages and services that
                            we can offer based on the information provided in the application.
                        </p>
                        <p>
                            In the meantime check out more details about our loss prevention and
                            risk assessment capabilities.
                        </p>
                    </div>
                </Modal>
                <Modal
                    isOpen={isRenewal && submitModal}
                    closeable={true}
                    closeAction={() => setSubmitModal(false)}
                    title="Sign Application"
                    buttonStyle="horizontal"
                    buttons={[
                        <Button
                            key="modalButton1"
                            onClick={() => submit(formData)}
                            style={{ maxWidth: "300px" }}
                        >
                            Submit without signing
                        </Button>,
                        <Button
                            style={{ maxWidth: "300px" }}
                            key="modalButton2"
                            onClick={() => {
                                SetSubmitAndSign(true);
                            }}
                        >
                            Submit and Sign App
                        </Button>,
                    ]}
                >
                    <div
                        style={{
                            padding: "10px 20px 20px 20px",
                            textAlign: "center",
                            fontSize: "16px",
                            lineHeight: "150%",
                            fontFamily: "Arial,sans-serif",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <p style={{ marginBottom: 40 }}>
                            If you are an executive of the company please proceed to submit and sign
                            the application now to streamline the quote to bind flow.
                        </p>
                        <p>
                            If you are a broker submitting the application on behalf of the company
                            please submit without signing. The company will have the opportunity to
                            sign the application prior to binding.
                        </p>
                    </div>
                </Modal>
            </Page>
        );
    }
};

export default SubmitReview;
