import React, { useRef } from "react";
import styled from "styled-components/macro";

import { MuseoSansBaseCSS } from "components/typography";
import { B1Arial } from "../../counterpart-theme";

type Props = Omit<React.InputHTMLAttributes<any>, "onChange"> & {
    label: string;
    onChange?: (e: any) => any;
    value: any;
};

const CheckboxLabel: React.SFC<Props> = ({
    className,
    value,
    label,
    width,
    onChange,
    ...props
}) => {
    const inputRef = useRef(null);

    const onClick = (e: any) => {
        if (onChange) {
            onChange(e.target.checked);
        }
        return;
    };

    return (
        <div className={className}>
            <B1Arial className="mx-auto text-center" style={{ maxWidth: width, display: "flex" }}>
                <div>
                    <input
                        type="checkbox"
                        ref={inputRef}
                        {...props}
                        onChange={(e: any) => {
                            onClick(e);
                        }}
                        defaultChecked={value}
                    />
                </div>
                <div className="checkbox-label-right-side-div" style={{ margin: "5px" }}>
                    {label}
                </div>
            </B1Arial>
        </div>
    );
};

export const CheckboxWithLabel = styled(CheckboxLabel)`
    ${MuseoSansBaseCSS}
    align-items: center;
    min-width: 150px;
    padding: 50px 0px 0px 0px;
    user-select: none;
    & input {
        cursor: pointer;
        zoom: 1.2;
        margin: 10px;
    }
    & label {
        margin: 0 0 0 10px;
        cursor: pointer;
    }
    .checkbox-label-right-side-div {
        text-align: left;
        padding-left: 10px;
        font-size: 17px;
    }
`;

export default CheckboxWithLabel;
