import { ValueType } from "./types";
import { assert } from "utils";
import { toNumberArray } from "./utils";

export enum ComparisonOperatorEnum {
    // Matches values that are equal to a specified value.
    $eq = "$eq",
    // Matches all values that are not equal to a specified value.
    $ne = "$ne",
    // Matches values that are greater than a specified value.
    $gt = "$gt",
    // Matches values that are greater than or equal to a specified value.
    $gte = "$gte",
    // Matches values that are less than a specified value.
    $lt = "$lt",
    // Matches values that are less than or equal to a specified value.
    $lte = "$lte",
    // Matches any of the values specified in an array.
    $in = "$in",
    // Matches none of the values specified in an array.
    $nin = "$nin",
    // Returns true when any element of a is present on b
    $intersect = "$intersect",
}

type ComparisonHandlerType = (args: ValueType) => boolean;

export const COMPARISON_OPERATORS_HANDLERS: {
    [K in ComparisonOperatorEnum]: ComparisonHandlerType;
} = {
    $eq: (args: ValueType) => {
        assert(Array.isArray(args) && args.length === 2);
        const [a, b] = args;
        //eslint-disable-next-line
        return a == b;
    },
    $ne: (args: ValueType) => {
        assert(Array.isArray(args) && args.length === 2);
        const [a, b] = args;
        //eslint-disable-next-line
        return a != b;
    },
    $gt: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a > b;
    },
    $gte: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a >= b;
    },
    $lt: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a < b;
    },
    $lte: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a <= b;
    },
    $in: (args: ValueType) => {
        assert(Array.isArray(args) && args.length === 2);
        const [a, b] = args;
        if (!Array.isArray(b)) {
            return [b].includes(a as any);
        }

        return b.includes(a);
    },
    $nin: (args: ValueType) => {
        assert(Array.isArray(args) && args.length === 2);
        const [a, b] = args;
        assert(Array.isArray(b));
        return !b.includes(a);
    },
    $intersect: (args: ValueType) => {
        if (args === null || args === undefined) {
            return false;
        }
        assert(Array.isArray(args) && args.length === 2);
        let [arg1, arg2] = args;
        if (!Array.isArray(arg1)) {
            arg1 = [arg1];
        }
        if (!Array.isArray(arg2)) {
            arg2 = [arg2];
        }
        assert(Array.isArray(arg1));
        assert(Array.isArray(arg2));
        for (const a of arg1) {
            for (const b of arg2) {
                // eslint-disable-next-line eqeqeq
                if (a == b) return true;
            }
        }
        return false;
    },
};
