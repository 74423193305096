import styled from "styled-components/macro";
import { StackedPaper } from "components/common";
import { BaseFont, SectionSelectorText } from "components/typography";
import { B1Arial } from "counterpart-theme";

export const SectionMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    cursor: pointer;

    &.disabled {
        opacity: 0.7;
        filter: grayscale(1);
        cursor: pointer;
    }

    &.disabled * {
        cursor: pointer !important;
    }

    &.disabled.notClickable * {
        cursor: no-drop !important;
    }
`;

export const QuestionsStackedPaper = styled(StackedPaper as any)`
    ${BaseFont}
    font-size: 1rem;
    line-height: 120%;
    font-weight: 700;

    /* Removing annoying drop-pin icon from address fields on application */
    & button.ap-input-icon.ap-icon-pin {
        display: none;
    }
    @media (max-width: 800px) {
        padding: 16px;
    }
`;

export const SectionRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    & > ${SectionSelectorText} {
        color: ${(props) => props.theme.colors.textColor};
    }

    & > ${SectionSelectorText}.arrow {
        display: none;
    }

    & > svg {
        transform: rotate(-90deg);
        align-self: center;
        & > path {
            fill: ${(props) => props.theme.colors.textColor};
        }
    }

    ${SectionMenuContainer}.isSelected & ${SectionSelectorText} {
        color: ${(props) => props.theme.colors.primary};

        line-height: 140%;
        font-weight: 700;
        text-align: left;
        &.arrow {
            display: inline-flex;
            text-align: right;
            color: ${(props) => props.theme.colors.primary};
        }

        &.section-required.on-error {
            color: #eb0f21 !important;
            &.arrow {
                color: #eb0f21 !important;
            }
        }
    }
`;

export const CustomButton = styled.button`
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;

    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }
`;

export const ControlContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

    &.closed {
        transform: rotate(180deg);
    }
`;

export const SectionMenu = styled.div`
    display: flex;
    flex-direction: column;
    & > ${ControlContainer} {
        display: none;
    }
    @media (max-width: 1024px) {
        & ${ControlContainer} {
            display: flex;
        }
        &.closed {
            position: relative;
            max-height: 210px;

            & > ${SectionMenuContainer}:not(.isSelected) {
                display: none;
            }

            & > ${ControlContainer} {
                & > img {
                    transform: rotate(180deg);
                }
            }
        }
    }
`;

export const SectionNameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h1 {
        font-weight: bold;
    }

    @media print {
        & {
            page-break-before: always;
            display: block;
        }
    }

    @media (max-width: 800px) {
        & h1 {
            text-align: left !important;
        }
    }
`;

export const SectionDescription = styled(B1Arial as any)`
    max-width: 558px;
    text-align: center;
`;

export const SubmitCTAContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 0;
    width: 100%;

    & > button {
        width: 100%;
    }

    @media (max-width: 1024px) {
        &:not(.no-hidden-mobile) {
            display: none;
        }
        background: #ffffff;
        z-index: 999;
        border-radius: 0 0 10px 10px;
        /* margin-bottom: 20px; */

        & > button {
            max-width: 320px;
        }
    }

    /* @media (max-width: 767px) {
        margin-bottom: 0;
    } */
`;

export const SectionQuestionsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: 20px;
`;

export const SectionQuestionsMainContainer = styled.div`
    &.application-section-questions {
        position: relative;

        & > div.disabled-orverlay {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 997;
            cursor: not-allowed;
        }

        &.is-insurance {
            &.disabled {
                & > div.disabled-orverlay {
                    display: block;
                }

                & > ${StackedPaper} {
                    background: ${(props) => props.theme.colors.third};

                    & input {
                        background: ${(props) => props.theme.colors.third};
                    }
                }
            }
        }
    }
`;
