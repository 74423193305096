enum Responsive {
    Android = "x-responsive-android",
    Chrome = "x-responsive-chrome",
    Desktop = "x-responsive-desktop",
    Edge = "x-responsive-ms-edge",
    Firefox = "x-responsive-firefox",
    InternetExplorer = "x-responsive-ie11",
    iOS = "x-responsive-ios",
    iPad = "x-responsive-ipad",
    Mobile = "x-responsive-mobile",
    Safari = "x-responsive-safari",
    Tablet = "x-responsive-tablet",
    TouchScreen = "x-responsive-touch-screen",
}

type ResponsiveEnumKeys = keyof typeof Responsive;

export default (Object.fromEntries(
    Object.entries(Responsive).map(([key, value]) => [key, `.${value}`]),
) as unknown) as { [key in ResponsiveEnumKeys]: string };

const MOBILE_MEDIA_QUERY = window.matchMedia("(max-width: 700px)");
const TABLET_MEDIA_QUERY = window.matchMedia("(min-width: 701px) and (max-width: 1200px)");
const DESKTOP_MEDIA_QUERY = window.matchMedia("(min-width: 1200px)");

const FEATURE_DETECTION: { [key in Responsive]: () => boolean } = {
    [Responsive.Android]: () => /Android/.test(navigator.userAgent),
    [Responsive.Chrome]: () => /Google Inc/.test(navigator.vendor),
    [Responsive.Desktop]: () => DESKTOP_MEDIA_QUERY.matches,
    [Responsive.Edge]: () => /Edge/.test(navigator.userAgent),
    [Responsive.Firefox]: () => /Firefox/.test(navigator.userAgent),
    [Responsive.InternetExplorer]: () => /Trident/.test(navigator.userAgent),
    [Responsive.iOS]: () => /(iPhone|iPad|iPod)/.test(navigator.platform),
    [Responsive.iPad]: () => /(iPad)/.test(navigator.platform),
    [Responsive.Mobile]: () => MOBILE_MEDIA_QUERY.matches,
    [Responsive.Safari]: () =>
        /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
    [Responsive.Tablet]: () => TABLET_MEDIA_QUERY.matches,
    [Responsive.TouchScreen]: () => "ontouchstart" in window,
};

const addClassToDocument = (className: string) => {
    document.body.classList.add(className);
};
const removeClassFromDocument = (className: string) => {
    document.body.classList.remove(className);
};

(function(window) {
    const allFeatures = new Set(Object.keys(FEATURE_DETECTION));
    function detectFeatures() {
        const featuresDetected = new Set();
        Object.entries(FEATURE_DETECTION).forEach(
            ([feature, func]) => func() && featuresDetected.add(feature),
        );

        for (const feature of Array.from(allFeatures)) {
            if (featuresDetected.has(feature)) {
                addClassToDocument(feature);
            } else {
                removeClassFromDocument(feature);
            }
        }
    }

    detectFeatures();

    // Adding listeners
    [MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY, DESKTOP_MEDIA_QUERY].forEach((mQuery) => {
        mQuery.addListener(() => detectFeatures());
    });
    window.addEventListener("resize", detectFeatures);
})(window);
