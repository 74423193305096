import styled from "styled-components/macro";

export const TextBoxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 60px;
    margin-top: 60px;

    @media (max-width: 576px) {
        margin-right: 0;
    }
`;

export const TextBoxItem = styled.div`
    width: 370px;
    text-align: left;
    margin-bottom: 30px;
    max-width: 50%;

    &:nth-child(odd) {
        padding-right: 20px;

        @media (max-width: 576px) {
            padding-right: 0;
        }
    }

    @media (max-width: 576px) {
        width: auto;
        max-width: unset;
    }

    & > h4 {
        margin-bottom: 14px;
    }
`;

export const TextBoxIcon = styled.img`
    margin-right: 16px;
    position: relative;
`;
