import React from "react";

import SideMenu from "../../BrokerPage/SideMenu";
import { MenuContainer, MobileMenuContainer, MobileTitle } from "../../BrokerPage";

import MenuIcon from "assets/mobile-menu-icon.svg";

interface MenuProps {
    visible: boolean;
    openCloseAction: any;
    showAppetiteGuideBanner: boolean;
}

const SideMenuProfile: React.FC<MenuProps> = ({ visible, openCloseAction, showAppetiteGuideBanner }) => {
    return (
        <MenuContainer visible={visible}>
            <MobileMenuContainer hideTransition={false} bannerHidden={true}>
                <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => openCloseAction(true)}
                >
                    <img src={MenuIcon} alt="" />
                </button>
                <MobileTitle>profile</MobileTitle>
                <div></div>
            </MobileMenuContainer>
            <SideMenu showAppetiteGuideBanner={showAppetiteGuideBanner} visible={visible} closeAction={() => openCloseAction(false)} />
        </MenuContainer>
    );
};

export default React.memo(SideMenuProfile);
