import React, { useRef } from "react";
import styled from "styled-components/macro";
import { observer } from "mobx-react-lite";
import { H2 } from "components/typography";
import CloseButton from "assets/x-close-black.svg";
import Confetti from "react-confetti";
import { Helmet } from "react-helmet";

import responsive from "services/responsive";

interface ModalProps {
    isOpen: boolean;
    closeable: boolean;
    title: string;
    buttons?: Array<any>;
    buttonStyle?: string;
    titlePosition?: string;
    closeAction?: () => any;
    style?: any;
    iconTitle?: React.ReactElement;
    containerClasses?: string;
    confetti?: boolean;
}

const ModalPageContainer = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(34, 35, 46, 0.67);
    z-index: 999999;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px);

    &.opened {
        display: flex;
    }

    &.closed {
        display: none;
    }

    ${responsive.InternetExplorer} & {
        width: 100%;
        height: 100%;
        position: fixed;
        margin: 0;

        &.opened {
            display: -ms-flexbox;
        }

        &.closed {
            display: none;
        }
    }
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    @media (max-width: 700px) {
        width: 98%;
        left: 0;
        margin-left: 1%;
    }
    width: 600px;
    background: #ffffff;
    border: 2px solid #ecf1f7;
    box-shadow: 0px 12px 18px rgba(20, 21, 23, 0.15);
    border-radius: 10px;
    padding: 25px;
    max-height: calc(100vh - 110px);
    overflow-y: auto;

    &.no-padding-bottom {
        padding-bottom: 0 !important;
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        position: absolute;
        margin-top: -300px;
        margin-left: -300px;
        width: 660px;
    }
`;

const HeaderSection = styled.div`
    display: flex;
`;

const HeaderTitleContainer = styled.div<{ titlePosition?: string }>`
    flex-grow: 1;
    text-align: ${({ titlePosition }) => titlePosition || "center"};
`;

const HeaderCloseButtonContainer = styled.div<{ closeable: boolean }>`
    display: ${({ closeable }) => (closeable ? "block" : "none")};
    cursor: pointer;
    z-index: 1;
`;

const BodySection = styled.div`
    position: relative;
    padding-top: 25px;
`;

const ButtonContainer = styled.div<{ buttonStyle: string }>`
    display: flex;
    flex-direction: ${({ buttonStyle }) => (buttonStyle === "horizontal" ? "row" : "column")};
    justify-content: center;
    align-items: center;

    & button {
        margin: 5px;
    }
`;

const Modal: React.FC<ModalProps> = observer(
    ({
        children,
        title,
        isOpen,
        closeable,
        buttonStyle = "horizontal",
        buttons,
        closeAction,
        style,
        titlePosition,
        iconTitle = <></>,
        containerClasses = "",
        confetti = false,
    }) => {
        const ref = useRef<HTMLDivElement>(null);

        const getStyleObj = (condition: boolean) => {
            if (condition) {
                return {
                    backgroundColor: "#ebf0f6",
                };
            }
        };

        return (
            <ModalPageContainer
                className={`${isOpen ? "opened" : "closed"}`}
                isOpen={isOpen}
                style={getStyleObj(confetti)}
            >
                <Helmet>
                    <style>
                        {isOpen &&
                            `
                            body {
                                overflow: hidden;
                            }
                        `}
                    </style>
                </Helmet>
                {confetti && <Confetti style={{ zIndex: 0 }} />}
                <ModalContainer className={containerClasses} style={style} ref={ref}>
                    <HeaderSection>
                        <HeaderTitleContainer titlePosition={titlePosition}>
                            {iconTitle}
                            <H2>{title}</H2>
                        </HeaderTitleContainer>
                        <HeaderCloseButtonContainer closeable={closeable} onClick={closeAction}>
                            <img src={CloseButton} alt="Close" />
                        </HeaderCloseButtonContainer>
                    </HeaderSection>
                    <BodySection>{children}</BodySection>
                    <ButtonContainer buttonStyle={buttonStyle}>{buttons}</ButtonContainer>
                </ModalContainer>
            </ModalPageContainer>
        );
    },
);

/* Example Modal */

/*<Modal
    isOpen={modalOpen}
    closeable={true}
    title="Modal test"
    buttons={[
        <Button key="button1" type="submit" disabled={isSubmitting ? true : false}>
            Start Aplication
        </Button>,
        <Button key="button2" disabled={isSubmitting ? true : false}>
            Cancel
        </Button>,
    ]}
    buttonStyle="horizontal"
    closeAction={() => setModalOpen(false)}
    >
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <>
            <Form>
                <Row>
                    <FormikInputField
                        name="company.name"
                        placeholder="eg. Aliro Incorporated"
                        label="Legal Company Name"
                        tooltipText="Under what name was the company registered."
                    />
                    <FormikInputField
                        name="company.info.url"
                        placeholder="http://example.com"
                        label="Website"
                        onFocus={checkInput}
                        id="website"
                        tooltipText="Url of the website starting with http://"
                    />
                </Row>
            </Form>
            <Body1 style={{ color: "#000" }}>
                Note: All fields are mandatory to start an Application
            </Body1>
        </>
    </Formik>
</Modal>
*/

export default Modal;
