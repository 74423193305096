import React from "react";
import styled from "styled-components/macro";
import moment from "moment";
import CommonContainer from "../CommonContainer";
import {
    CancelButton,
    FormikInputField,
    LightBlueButton,
    LinkButton,
    Modal,
    Row,
} from "components/common";
import { Body1, MuseoSansBaseCSS } from "components/typography";
import store, { QuoteStore, QuoteSubjectivityModel } from "../../store";

import { observer } from "mobx-react-lite";
import { Form, Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { shareSubjectivitiesApplication } from "services/quotes";

import { MarkMenu } from "../../components/SidebarMenu";
import { formatCurrency } from "utils";
import { signedApplicationCompleted, surplusLicenseFilled } from "features/AccountPage/utils";

const Text = styled.p`
    ${MuseoSansBaseCSS}
    line-height: 150%;
    margin-top: 20px;
`;

const formikModalValues: any = {
    email: "",
};

let formikSurplusLicenseModalValues: any = {
    licensee: "",
    slaNumber: null,
    licenseNumber: "",
    expirationDate: "",
};

const SubjectivityTable = styled.table`
    width: 100%;

    & tbody tr {
        cursor: pointer;
    }

    & tbody tr td {
        padding: 5px;
    }

    & tbody tr td:not(:first-child),
    & thead tr th:not(:first-child) {
        text-align: center;
    }

    & tbody tr:hover {
        background-color: #f3f3f3;
    }
`;

const PriceChangeRow = styled.div`
    display: grid;
    grid-template-columns: 177px 1fr 63px;

    @media (max-width: 676px) {
        grid-template-columns: 80px 1fr 63px;
    }

    @media (max-width: 576px) {
        grid-template-columns: 66px 1fr 63px;
    }

    & > div {
        display: flex;
        align-items: center;

        @media (max-width: 678px) {
            font-size: 14px;
        }

        &:first-child {
            @media (max-width: 678px) {
                padding-right: 10px;
            }
        }
    }

    &:not(:first-child) {
        padding: 12px 0;
    }

    &:nth-child(2) {
        position: relative;

        &:after {
            content: "";
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 0;
            position: absolute;
            background: #c4c4c433;
        }
    }

    &:last-child {
        padding-bottom: 0;
    }
`;

const PriceChangeTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    width: 100%;
    background-color: #c4c4c419;
    padding: 40px;
    border-radius: 8px;
    margin-top: 24px;

    @media (max-width: 678px) {
        font-size: 14px;
        margin-bottom: 20px;
    }

    @media (max-width: 576px) {
        padding: 10px;
    }
`;

const ButtonsContainer = styled.div`
    margin-top: 30px;

    ${CancelButton} {
        margin-left: 20px;

        @media (max-width: 480px) {
            margin-left: 0;
            margin-top: 10px;
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;
        width: 100%;
    }
`;

const _onSendFunction = (setModalShareOpen: any, quoteId: any, token: any) => async (
    values: typeof formikModalValues,
    actions: FormikHelpers<typeof formikModalValues>,
) => {
    try {
        actions.setSubmitting(true);
        await shareSubjectivitiesApplication(values, quoteId, token);
        actions.setValues(formikModalValues);
        setModalShareOpen(false);
        toast.success("Invite Sent!");
    } catch (error) {
        actions.setSubmitting(false);
        toast.error("An error ocurred, email not sent!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

const _onSaveLicenseFunction = (quote: any) => async (
    values: typeof formikSurplusLicenseModalValues,
    actions: FormikHelpers<typeof formikSurplusLicenseModalValues>,
) => {
    try {
        actions.setSubmitting(true);
        const surplusSet = await quote.setBrokerSurplusLicense(values);
        if (surplusSet) {
            actions.setValues(formikSurplusLicenseModalValues);
            toast.success("License info saved!");
        }
    } catch (error) {
        actions.setSubmitting(false);
        toast.error("License info not saved!");
        if (error.response && error.response.data) {
            actions.setErrors(error.response.data);
        }
    }
};

const formatDate = (dateStr: string) => moment(dateStr).format("MM/DD/YY");

// const openSubjectivityPage = (quoteID: string, key?: string) => () => {
//     let url = `/subjectivities/${quoteID}/`;
//     if (key) {
//         url = `${url}#${key}`;
//     }
//     window.open(url, "_blank");
// };

const PAGE_TEXT = {
    description1:
        "Thank you for submitting an application to Counterpart. Please complete any outstanding questions and digitally sign the application in order to proceed. Once the application is complete and signed you will receive an email to bind coverage.",
    description2:
        "Our underwriting team has approved all subjectivities. You can now proceed to bind coverage.",
};

const Subjectivities: React.FC<{ quote: QuoteStore }> = observer(({ quote }) => {
    const [modalShareOpen, setModalShareOpen] = React.useState(false);
    const requiredSubjectivities = quote.quoteSubjectivities.filter((e: any) => !e.isOptional);

    const isPolicy = quote.finalized;

    const isAllSubjectivitiesCleared =
        requiredSubjectivities.filter((s: QuoteSubjectivityModel) => s.cleared).length ===
        requiredSubjectivities.length;

    const shouldShowTable =
        requiredSubjectivities &&
        requiredSubjectivities.length !== 0 &&
        quote.premiumOnIssueQuote &&
        quote.premiumOnIssueQuote !== quote.totalPremiumCalc;

    const areSubjectivitiesApproved =
        requiredSubjectivities && requiredSubjectivities.length !== 0 && isAllSubjectivitiesCleared;

    if (store.quote.brokerSurplusLicense !== null) {
        formikSurplusLicenseModalValues = store.quote.brokerSurplusLicense
    }

    return (
        <>
            <CommonContainer
                isPolicy={isPolicy}
                // quoteInfo={{
                //     quoteDate: formatDate(quote.expirationDate) || "-",
                //     quoteNumber: quote.policyNumber || "-",
                //     commission: quote.commissionPercentage || "-",
                // }}
                title={
                    areSubjectivitiesApproved
                        ? "Subjectivities Approved"
                        : "Complete Subjectivities"
                }
                borderBottom={true}
            >
                <Text>
                    {areSubjectivitiesApproved ? PAGE_TEXT.description2 : PAGE_TEXT.description1}
                </Text>

                {quote.quoteHasLicenseeRequirement && (
                    <Formik
                        initialValues={formikSurplusLicenseModalValues}
                        onSubmit={_onSaveLicenseFunction(quote)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Row>
                                    <FormikInputField
                                        name="licensee"
                                        label="Licensee"
                                        type="text"
                                        placeholder="e.g. ABC123"
                                    />
                                    {quote.requiringSlaNumber && (
                                        <FormikInputField
                                            name="slaLicense"
                                            label="SLA License"
                                            type="text"
                                            placeholder="e.g. 321CDS"
                                        />
                                    )}

                                    {quote.requiringLicenseNumber && (
                                        <FormikInputField
                                            name="licenseNumber"
                                            label="License Number"
                                            type="text"
                                            placeholder="e.g. 321CDS"
                                        />
                                    )}

                                    <FormikInputField
                                        name="expirationDate"
                                        label="Expiration Date"
                                        type="date"
                                        placeholder=""
                                    />

                                    <LightBlueButton
                                        disabled={isSubmitting}
                                        type="submit"
                                        style={{ marginLeft: 10, marginTop: 24, height: 54 }}
                                    >
                                        Save
                                    </LightBlueButton>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                )}

                {shouldShowTable && (
                    <PriceChangeTable>
                        {" "}
                        {/*quote.premiumOnIssueQuote && quote.premiumOnIssueQuote !== quote.totalPremiumCalc*/}
                        <PriceChangeRow>
                            <div>
                                <span style={{ fontSize: 10, color: "#22232e7f" }}>DATE</span>
                            </div>
                            <div>
                                <span style={{ fontSize: 10, color: "#22232e7f" }}>TYPE</span>
                            </div>
                            <div>
                                <span style={{ fontSize: 10, color: "#22232e7f" }}>PREMIUM</span>
                            </div>
                        </PriceChangeRow>
                        <PriceChangeRow>
                            <div>
                                <span>{quote.quotedDate ? formatDate(quote.quotedDate) : "-"}</span>
                            </div>
                            <div>
                                <span>
                                    <strong style={{ paddingRight: 10 }}>Premium Quoted</strong>
                                </span>
                            </div>
                            <div>
                                <strong>{formatCurrency(quote.premiumOnIssueQuote)}</strong>
                            </div>
                        </PriceChangeRow>
                        <PriceChangeRow>
                            <div>
                                <span>
                                    {quote.supplementalAppSubmittedDate
                                        ? formatDate(quote.supplementalAppSubmittedDate)
                                        : "-"}
                                </span>
                            </div>
                            <div>
                                <span>
                                    <strong style={{ paddingRight: 10 }}>
                                        Premium Post Subjectivities
                                    </strong>
                                </span>
                            </div>
                            <div>
                                <strong>{formatCurrency(quote.totalPremiumCalc)}</strong>
                            </div>
                        </PriceChangeRow>
                    </PriceChangeTable>
                )}
                <ButtonsContainer>
                    <LinkButton
                        to={"/subjectivities/" + quote.id + "/" + quote.publicSecurityToken}
                        target="_blank"
                    >
                        View Subjectivities
                    </LinkButton>
                    <CancelButton onClick={() => setModalShareOpen(true)}>
                        Share With Agent
                    </CancelButton>
                </ButtonsContainer>
            </CommonContainer>
            <CommonContainer style={{ marginTop: 30 }} borderBottom={false}>
                <SubjectivityTable style={{ fontSize: "16px" }}>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th style={{ textAlign: "right", paddingRight: 16 }}>Status</th>
                        </tr>
                    </thead>
                    <tbody style={{ paddingTop: 20 }}>
                        {quote.quoteHasLicenseeRequirement && (
                            <tr style={{ borderBottom: "1px solid #ebf0f6", padding: "10px 0" }}>
                                <td style={{ fontSize: 16, fontWeight: 500 }}>
                                    Surplus License Filled
                                </td>
                                <td
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        fontWeight: 500,
                                        position: "relative",
                                        height: 40,
                                    }}
                                >
                                    <MarkMenu
                                        style={{ position: "relative", top: 3 }}
                                        completed={surplusLicenseFilled(quote)}
                                        selected={false}
                                    />
                                </td>
                            </tr>
                        )}
                        <tr style={{ borderBottom: "1px solid #ebf0f6", padding: "10px 0" }}>
                            <td style={{ fontSize: 16, fontWeight: 500 }}>Application Signed</td>
                            <td
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    fontWeight: 500,
                                    position: "relative",
                                    height: 40,
                                }}
                            >
                                <MarkMenu
                                    style={{ position: "relative", top: 3 }}
                                    completed={signedApplicationCompleted(quote)}
                                    selected={false}
                                />
                            </td>
                        </tr>
                        {requiredSubjectivities && requiredSubjectivities.length !== 0 && (
                            <tr style={{ borderBottom: "1px solid #ebf0f6", padding: "10px 0" }}>
                                <td style={{ fontSize: 16, fontWeight: 500 }}>
                                    Subjectivities Submitted
                                </td>
                                <td
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        fontWeight: 500,
                                        position: "relative",
                                        height: 40,
                                    }}
                                >
                                    <MarkMenu
                                        style={{ position: "relative", top: 3 }}
                                        completed={
                                            quote.supplementalAppSubmitted ||
                                            requiredSubjectivities.filter(
                                                (s: QuoteSubjectivityModel) => s.cleared,
                                            ).length === requiredSubjectivities.length
                                        }
                                        selected={false}
                                    />
                                </td>
                            </tr>
                        )}
                        {requiredSubjectivities && requiredSubjectivities.length !== 0 && (
                            <tr style={{ borderBottom: "1px solid #ebf0f6", padding: "10px 0" }}>
                                <td style={{ fontSize: 16, fontWeight: 500 }}>
                                    Subjectivities Approved
                                </td>
                                <td
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        fontWeight: 500,
                                        position: "relative",
                                        height: 40,
                                    }}
                                >
                                    <MarkMenu
                                        style={{ position: "relative", top: 3 }}
                                        completed={
                                            requiredSubjectivities.filter(
                                                (s: QuoteSubjectivityModel) => s.cleared,
                                            ).length === requiredSubjectivities.length
                                        }
                                        selected={false}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </SubjectivityTable>
            </CommonContainer>
            <Modal
                isOpen={modalShareOpen}
                closeable={true}
                title="Share Subjectivities & E-Signature"
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalShareOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <Body1>
                        {`Invite your retail agent or colleague to complete the subjectivities and sign the application. We will email a link to the designated email address on your behalf.`}
                    </Body1>
                    <Formik
                        initialValues={formikModalValues}
                        onSubmit={_onSendFunction(
                            setModalShareOpen,
                            quote.id,
                            quote.publicSecurityToken,
                        )}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Row>
                                    <FormikInputField
                                        name="email"
                                        label="Email address"
                                        type="email"
                                        placeholder="e.g. john@company.com"
                                    />
                                    <LightBlueButton
                                        disabled={isSubmitting}
                                        type="submit"
                                        style={{ marginLeft: 10, marginTop: 24, height: 54 }}
                                    >
                                        Send Email
                                    </LightBlueButton>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    );
});

export default React.memo(Subjectivities);
