/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { auth } from "services";
import rootStore from "services/store";
import Cookies from "js-cookie";
import PrivateRoute from "components/AuthenticatedComponent";
import VPNPrivateRoute from "components/VPNAuthenticatedRoute";
import PageNotFound from "components/PageNotFound";
import { BrokerAdminOverrideIndicator, IEIndicator } from "components/common";

// Features Pages
import LoginPage, { BrokerAdminOverridePage } from "features/Login";
import MagicLinkToken from "features/Login/MagicLinkToken";
import ForgotPassword from "features/Login/ForgotPassword";
import LogoutPage from "features/Logout";
import StartApplication from "features/StartApplication";
import BrokerQuickPage from "features/BrokerQuickPage";
import AccountPage from "features/AccountPage";
import ExtendAccount from "features/AccountPage/components/ExtendAccount";
import AccountLost from "features/AccountPage/components/AccountLost";
import AccountsNeedingFeedback from "features/AccountPage/components/AccountsNeedingFeedback";
import Dashboard from "features/Dashboard";
import Application from "features/Application";
import SubmitReview from "features/Application/components/SubmitReview";
import BrokerProfile from "features/BrokerProfile";
import Supplemental from "features/Supplemental";
import QuoteQuickPage from "features/QuoteQuickPage";
import RenewalCoverageHighlights from "features/RenewalCoverageHighlights";
import RenewalApplicationHighlights from "features/RenewalApplicationHighlights";
import CallToBindRequest from 'features/CallToBindRequest';
import HRRegistration from "features/HRRegistration";

import hmacSHA256 from "crypto-js/hmac-sha256";

import TagManager from "react-gtm-module";

import { Loader } from "@googlemaps/js-api-loader";
import { ThemeProvider } from "styled-components/macro";

import CounterpartTheme from "./theme";
import { Helmet } from "react-helmet";

// import { getCustomTheme } from "./services/users";

const App: React.SFC = () => {
    // const [CustomTheme, setCustomTheme] = React.useState<any>(undefined);

    const [authInitialized, setAuthInitialized] = useState(false);
    const INTERCOM_HASH = "7D4BtP7iqyfIV07xcrMGwBn8ovOvvYioJ8GYA-3k";
    const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "mz65crft";
    const REACT_APP_TAG_MANAGER_ID = process.env.REACT_APP_TAG_MANAGER_ID || "GTM-KJ7BHJ3";
    const REACT_APP_GOOGLE_MAPS_KEY =
        process.env.REACT_APP_GOOGLE_MAPS_KEY || "AIzaSyBFb5atj4gldqrq7sXHcrgpxvPgHAQ7zDA";

    useEffect(() => {
        auth.initialState();
        setAuthInitialized(true);
        const userData = auth.getUserInfo();
        let intercomObject: any = { app_id: INTERCOM_APP_ID };

        if (userData !== null) {
            const { user_id } = userData;
            let { email }: any = userData;

            const is_admin =
                rootStore && rootStore.userInfo && rootStore.userInfo.brokerAdminOverride;
            if (is_admin) email = rootStore.userInfo.adminUser;

            const user_hash = hmacSHA256(user_id, INTERCOM_HASH).toString();
            intercomObject = { ...intercomObject, email, user_id, user_hash };
        }

        (window as any).Intercom("boot", intercomObject);

        new Loader({
            apiKey: REACT_APP_GOOGLE_MAPS_KEY,
            libraries: ["places"],
        }).load();

        // getCustomTheme().then((res) => {
        //     setTimeout(() => {
        //         setCustomTheme(res);
        //     }, 3000);
        // });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const userData = auth.getUserInfo();
        let intercomObject: any = { app_id: INTERCOM_APP_ID };
        let tagManagerArgs: any = { gtmId: REACT_APP_TAG_MANAGER_ID };

        if (userData !== null) {
            const { user_id } = userData;
            let { email }: any = userData;
            const user_hash = hmacSHA256(user_id, INTERCOM_HASH).toString();
            intercomObject = { ...intercomObject, email, user_id, user_hash };
            const is_admin =
                rootStore && rootStore.userInfo && rootStore.userInfo.brokerAdminOverride;
            if (is_admin) email = rootStore.userInfo.adminUser;

            tagManagerArgs = {
                ...tagManagerArgs,
                dataLayer: { userId: user_id, user_id, email, is_admin },
            };
        }

        TagManager.initialize(tagManagerArgs);
        (window as any).Intercom("update", intercomObject);
    });

    if (!authInitialized) {
        return null;
    }
    const loggedIn = Cookies.get("$auth_accessToken");
    const applicationSectionPath = loggedIn
        ? `/application/:applicationID/:sectionKey?`
        : `/application/:applicationID/:appSecurityToken/:sectionKey?`;
    const applicationReviewPath = loggedIn
        ? "/application/:applicationID/review"
        : "/application/:applicationID/:appSecurityToken/review";

    return (
        <ThemeProvider theme={CounterpartTheme}>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <ToastContainer />
            <BrokerAdminOverrideIndicator />
            <IEIndicator />
            <BrowserRouter>
                <Switch>
                    <Route component={MagicLinkToken} path="/auth/magic-link-token" />
                    <Route component={LoginPage} path="/login/" />
                    <Route component={ForgotPassword} path="/auth/forgot-password" />
                    <Route component={LoginPage} path="/set_password" />
                    <Route component={BrokerAdminOverridePage} path="/admin-login/" />
                    <Route component={LogoutPage} path="/logout/" />
                    {/* Deprecated route, remove when we build renewals */}
                    <Redirect
                        from="/close_account/:quoteId/:brokerId/:token"
                        to="/close_account/:quoteId\?brokerId=:brokerId&token=:token"
                    />
                    <Route path="/close_account/:quoteId/" component={AccountLost} />
                    <Route path="/extend-account/:quoteId/" component={ExtendAccount} />
                    <Route path="/quote/:quoteId/summary" component={QuoteQuickPage} />
                    <Route path="/renewal/:quoteId/summary" component={RenewalCoverageHighlights} />
                    <Route
                        path="/renewal-application/:quoteId/summary"
                        component={RenewalApplicationHighlights}
                    />
                    <Route path="/accounts-needing-feedback/" component={AccountsNeedingFeedback} />
                    {/* Temporary path for emails that went to brokers to go to login page */}
                    <PrivateRoute path="/confirm_email" component={LoginPage} />
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    {/* <PrivateRoute path="/alerts" component={Dashboard} /> */}
                    <PrivateRoute path="/claims" component={Dashboard} />
                    <PrivateRoute path="/payments" component={Dashboard} />
                    <PrivateRoute path="/retail-agents" component={Dashboard} />
                    <PrivateRoute path="/risk-assessment" component={Dashboard} />
                    <PrivateRoute path="/get-quote" component={Dashboard} />
                    <Route path="/startapplication" component={StartApplication} />
                    <Route path="/quickpage/:brokerId/:token" component={BrokerQuickPage} />
                    <Route path={applicationReviewPath} component={SubmitReview} />
                    <Route path={applicationSectionPath} component={Application} />
                    {/* Route Deprecated, Redirect to the new Signature Page */}
                    <Redirect from="/sign-application/:quoteID" to="/supplemental/:quoteID/" />
                    <Route path="/account/:quoteID/:publicSecurityToken/:appSecurityToken" component={AccountPage} />
                    <VPNPrivateRoute path="/account/:quoteID" component={AccountPage} />
                    <PrivateRoute path="/broker-profile/" component={BrokerProfile} />
                    <Route
                        path="/supplemental/:quoteID"
                        component={() => <Supplemental isSupplemental={true} />}
                    />
                    <Route
                        path="/subjectivities/:quoteID/:publicSecurityToken"
                        component={Supplemental}
                    />
                    <Route path="/bind-account-with-token/:quoteID/:publicSecurityToken/:appSecurityToken/:bindQuoteToken" component={CallToBindRequest}/>
                    <Route path="/register-for-hr-services/:company_id/:token" component={HRRegistration}/>
                    <PrivateRoute exact path="/">
                        <Redirect to="/dashboard" />
                    </PrivateRoute>
                    <Route component={PageNotFound} />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
