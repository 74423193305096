import React from "react";

export const BlueCheckMarkIcon = () => (
           <svg
               width="60"
               height="60"
               viewBox="0 0 60 60"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
           >
               <path
                   fillRule="evenodd"
                   clipRule="evenodd"
                   d="M25.29 33.27L38.16 20.37C38.7221 19.8112 39.4824 19.4976 40.275 19.4976C41.0676 19.4976 41.8279 19.8112 42.39 20.37C42.6712 20.6489 42.8944 20.9807 43.0467 21.3463C43.199 21.7118 43.2774 22.104 43.2774 22.5C43.2774 22.896 43.199 23.2881 43.0467 23.6537C42.8944 24.0193 42.6712 24.3511 42.39 24.63L27.39 39.63C26.8312 40.1842 26.077 40.4967 25.29 40.5C24.8952 40.5023 24.5038 40.4266 24.1383 40.2773C23.7728 40.128 23.4403 39.908 23.16 39.63L16.11 32.55C15.796 32.281 15.5409 31.9501 15.3608 31.5779C15.1808 31.2057 15.0796 30.8003 15.0636 30.3871C15.0477 29.974 15.1173 29.562 15.2681 29.177C15.4189 28.792 15.6477 28.4424 15.9401 28.15C16.2324 27.8577 16.5821 27.6289 16.967 27.4781C17.352 27.3273 17.764 27.2576 18.1772 27.2736C18.5903 27.2896 18.9957 27.3907 19.3679 27.5708C19.7401 27.7509 20.0711 28.0059 20.34 28.32L25.29 33.27ZM13.3329 5.05591C18.2664 1.75947 24.0666 0 30 0C33.9397 0 37.8407 0.775973 41.4805 2.28361C45.1203 3.79125 48.4274 6.00104 51.2132 8.78679C53.9989 11.5726 56.2087 14.8797 57.7164 18.5195C59.224 22.1593 60 26.0603 60 30C60 35.9334 58.2405 41.7336 54.9441 46.6671C51.6476 51.6006 46.9623 55.4457 41.4805 57.7164C35.9987 59.987 29.9667 60.5811 24.1473 59.4235C18.3279 58.266 12.9824 55.4088 8.78681 51.2132C4.59123 47.0176 1.73401 41.6721 0.576455 35.8527C-0.581101 30.0333 0.0129985 24.0013 2.28363 18.5195C4.55426 13.0377 8.39943 8.35235 13.3329 5.05591Z"
                   fill="#4BAA8F"
               />
           </svg>
       );

export const RedXIcon = () => (
    <svg width="60" height="60" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
            <path
                d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334Z"
                stroke="#EB0F21"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 7.5L7.5 12.5"
                stroke="#EB0F21"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 7.5L12.5 12.5"
                stroke="#EB0F21"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
