import React from "react";
import styled from "styled-components/macro";
import QuoteFeedback from "./QuoteFeedback";
import rootStore from "services/store";
import { Redirect } from "react-router"
import { useParams } from "react-router-dom";
import { QuoteModel, Company } from "../store";
import { FullPage, StackedPaperPaddedContainer } from "components/common";
import { markAccountLost } from "services/quotes"
import { AccountLostQuote } from "CounterpartApiTypes";
import { parseQueryString } from "utils"

import Loading from "components/common/Loading";

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 999999;
`;

async function markAsAccountWasLostByBroker(quoteId: string, brokerId: string, token: string, setError: any) {
    try {
        let quoteData: any = await markAccountLost({ 'brokerId': brokerId, 'quickPageToken': token }, quoteId)

        if(!quoteData) {
            setError(true)
        }

        let data: AccountLostQuote = quoteData;

        if(data.feedback && data.feedback.length) {
            setError(true)
        }

        let quoteStore: any = QuoteModel.create({
            'id': data.id,
            'carrierOptions': data.carrierOptions,
            'feedback': data.feedback,
            'statusText': "",
            'descriptionText': "",
            'components': [],
            'status': "",
            'sharedLimits': false,
            'priorActsCoverage': false,
            'finalized': false,
            'insuranceLinesRequiringPnp': [""],
            'supplementalAppSubmitted': false,
            'shouldUseManualDiscounts': false,
            'company': Company.create({
                'name': data.companyName,
                'info': {
                    'address1': null,
                    'address2': null,
                    'city': null,
                    'state': null,
                    'zipcode': null,
                    'url': null
                }
            })
        })

        return quoteStore

    } catch (error) {
        setError(true)
    }
    return null
}

const AccountLost: React.FC<{}> = () => {
    const { quoteId } = useParams();

    let { brokerId, token } = parseQueryString()
    brokerId = brokerId ?? "";
    token = token ?? "";

    const [loading, setLoading] = React.useState(true)
    const [quote, setQuote] = React.useState<AccountLostQuote | null>(null)
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        setTimeout(() => {
            markAsAccountWasLostByBroker(quoteId, brokerId, token, setError).then((q: any) => {
                console.log(q)
                if(q) {
                    setLoading(false)
                    setQuote(q)
                    return
                }
                window.location.href = "/";
            })
        }, 2000)
    }, [quoteId, brokerId, token])

    if((rootStore === null || rootStore.userInfo === null) && (brokerId === "" || token === "")) {
        setError(true)
    }

    if (!quoteId || error) return <Redirect to="/" />;

    if(quote !== null && quote?.feedback && quote?.feedback.length) return <Redirect to="/" />;

    if(loading || quote === null) {
        return (
            <StyledLoaderContainer style={{ textAlign: "center" }}>
                    <Loading message="" />
            </StyledLoaderContainer>
        )
    }

    return (
        <FullPage>
            <StackedPaperPaddedContainer style={{ maxWidth: "1200px" }}>
                <QuoteFeedback quote={quote} />
            </StackedPaperPaddedContainer>
        </FullPage>
    );
};

export default AccountLost;
