import React from "react";

import styled from "styled-components/macro";

import CommonContainer from "../CommonContainer";
import { H2, ArialBaseCSS } from "components/typography";

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 28px;
    margin-top: 60px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-row-gap: 68px;
    }
`;

const GridColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
`;

const ListCell = styled.div`
    display: flex;
    align-items: center;

    svg {
        height: 50px;
        min-width: 50px;
        margin-right: 16px;
    }

    div {
        font-size: 14px;
        line-height: 20px;

        p:first-child {
            font-weight: bold;
            margin-bottom: 6px;
        }
        p:nth-child(2) {
            margin-bottom: 0;
        }
    }
`;

const ContentWrapper = styled.div`
    ${ArialBaseCSS};
    padding: 0 10px 40px 10px;

    @media (max-width: 768px) {
        padding: 10px 0 40px 0;
    }
`;

const HRAdvisoryServices = () => {
    return (
        <CommonContainer>
            <ContentWrapper>
                <H2 style={{ fontSize: 22 }}>HR Counterpart</H2>
                <div style={{ marginTop: 40 }}>
                    {`Counterpart has partnered with a leading HR service provider to help policyholders with their toughest HR questions. Whether policyholders need help dealing with complex employee circumstances or navigating state compliance, our certified HR experts can help them get to the right answer.`}
                </div>
                <Grid>
                    <GridColumn>
                        <div>
                            <p style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>
                                HR Resource Center
                            </p>
                            <p>
                                Policyholders have access to HR training, templates, & alerts to
                                keep your business on track
                            </p>
                        </div>
                        <ListCell>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.1896 6.49003L10.0391 0.389651C9.92676 0.314778 9.78057 0.314778 9.66827 0.389651L0.517691 6.49003C0.424768 6.55198 0.368835 6.65649 0.368835 6.76813C0.368835 6.87978 0.424545 6.98429 0.517691 7.04624L1.90752 7.97279V12.8558C1.90752 12.9697 1.96546 13.0758 2.06128 13.1373L9.72665 18.0515C9.78169 18.0867 9.84431 18.1043 9.90715 18.1043C9.97288 18.1043 10.0388 18.0849 10.0954 18.0461L14.0947 15.3177V17.2203L12.6048 20.2001C12.5531 20.3037 12.5587 20.4269 12.6193 20.5254C12.6801 20.6237 12.788 20.6839 12.9036 20.6839H15.954C16.0699 20.6839 16.1776 20.6237 16.2384 20.5254C16.299 20.4269 16.3048 20.3037 16.2529 20.2001L14.763 17.2203V14.8616L17.2982 13.1319C17.3893 13.0697 17.4439 12.9663 17.4439 12.8558V8.20989L19.1894 7.04624C19.2823 6.98429 19.3383 6.87978 19.3383 6.76813C19.3383 6.65649 19.2826 6.55198 19.1896 6.49003ZM13.4447 20.0153L14.429 18.0468L15.4132 20.0153H13.4447ZM9.90135 17.3694L2.57603 12.6731V8.41847L9.66827 13.1468C9.72442 13.1843 9.78904 13.203 9.85367 13.203C9.91829 13.203 9.98291 13.1843 10.0391 13.1468L14.0947 10.4429V14.5084L9.90135 17.3694ZM16.7756 12.6793L14.7632 14.0525V9.99726L16.7756 8.65556V12.6793ZM16.9938 7.70672C16.9446 7.725 16.9011 7.75419 16.8659 7.79207L14.429 9.41677L10.0391 6.49003C9.88509 6.38753 9.67785 6.4292 9.57557 6.58273C9.47328 6.73627 9.51473 6.94395 9.66827 7.04624L13.8264 9.81833L9.85367 12.467L1.30564 6.76813L9.85367 1.06953L18.4017 6.76813L16.9938 7.70672Z" fill="#64656D" />
                            </svg>

                            <div>
                                <p>Online Employee Training</p>
                                <p>
                                    Never pay for online harassment & discrimination training again
                                </p>
                            </div>
                        </ListCell>
                        <ListCell>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.7055 11.8507L18.0573 10.7371V7.39608L19.7055 6.30469C19.7946 6.23787 19.8614 6.14878 19.8614 6.03741C19.8614 5.92605 19.7946 5.81468 19.7055 5.77014L11.2194 0.0681812C11.108 0.00136146 10.9521 0.00136146 10.8407 0.0681812L0.372287 7.06199C0.32774 7.08426 0.305467 7.10653 0.283194 7.15108C0.283194 7.15108 0.283194 7.15108 0.283194 7.17335C0.238647 7.24017 0.238647 7.28472 0.238647 7.35154V13.4767C0.238647 13.588 0.305467 13.6994 0.39456 13.744L8.88067 19.4014C8.94749 19.4459 8.99204 19.4682 9.05886 19.4682C9.12568 19.4682 9.1925 19.4459 9.23704 19.4014L19.7277 12.4076C19.8168 12.3407 19.8837 12.2517 19.8837 12.1403C19.8614 12.0066 19.7946 11.8953 19.7055 11.8507ZM11.0412 0.758652L18.9259 6.01514L17.6341 6.86153C17.5672 6.8838 17.5004 6.92835 17.4559 6.97289L9.03658 12.5858L2.71098 8.37611C2.68871 8.35383 2.66643 8.35383 2.66643 8.33156L1.15185 7.32926L11.0412 0.758652ZM2.82235 9.26704L8.83613 13.2762C8.94749 13.343 9.1034 13.343 9.21477 13.2762L17.3891 7.81927V10.5589L9.08113 16.0381L2.82235 12.0289V9.26704ZM9.03658 18.7109L0.884572 13.2762V7.97519L2.17642 8.82157V12.2071C2.17642 12.3185 2.24324 12.4298 2.33233 12.4967L8.92522 16.7286C8.96976 16.7731 9.03658 16.7731 9.1034 16.7731C9.17022 16.7731 9.23704 16.7509 9.28159 16.7286L17.6118 11.2494L18.9259 12.1403L9.03658 18.7109Z" fill="#64656D" />
                            </svg>

                            <div>
                                <p>Employee Handbook Builder</p>
                                <p>
                                    Build a new handbook or update an existing one with a few simple clicks
                                </p>
                            </div>
                        </ListCell>
                        <ListCell>
                            <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.6319 2.64653H12.0904V1.13195C12.0904 0.953764 11.9345 0.797852 11.7563 0.797852H6.47757C6.29939 0.797852 6.14348 0.953764 6.14348 1.13195V2.64653H2.40157C1.622 2.64653 0.998352 3.27018 0.998352 4.04975V20.8438C0.998352 21.6233 1.622 22.247 2.40157 22.247H15.6542C16.4337 22.247 17.0574 21.6233 17.0574 20.8438V4.04975C17.0351 3.27018 16.4114 2.64653 15.6319 2.64653ZM6.47757 6.96754H11.7563C11.9345 6.96754 12.0904 6.81163 12.0904 6.63345V5.18568H14.4291V19.5965H3.60432V5.20796H6.14348V6.63345C6.14348 6.8339 6.29939 6.96754 6.47757 6.96754ZM11.4222 1.46605V4.85158V6.29935H6.81167V4.85158V2.95836V1.46605H11.4222ZM16.3669 20.8215C16.3669 21.2224 16.0328 21.5565 15.6319 21.5565H2.40157C2.00065 21.5565 1.66655 21.2224 1.66655 20.8215V4.04975C1.66655 3.64883 2.00065 3.31473 2.40157 3.31473H6.14348V4.53976H3.27022C3.09204 4.53976 2.93613 4.69567 2.93613 4.87386V19.9529C2.93613 20.131 3.09204 20.287 3.27022 20.287H14.7632C14.9414 20.287 15.0973 20.131 15.0973 19.9529V4.87386C15.0973 4.69567 14.9414 4.53976 14.7632 4.53976H12.0904V3.31473H15.6319C16.0328 3.31473 16.3669 3.64883 16.3669 4.04975V20.8215Z" fill="#64656D" />
                            </svg>


                            <div>
                                <p>Policy & Procedure Templates</p>
                                <p>
                                    Access hundreds of professional HR templates & guides
                                </p>
                            </div>
                        </ListCell>
                        <ListCell>
                            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.64622 2.32935C10.3321 2.32837 12.0179 2.32837 13.7042 2.32935C13.7042 2.66573 13.7047 3.0026 13.7037 3.33947C13.0295 3.33947 12.3553 3.33898 11.681 3.33947C11.6805 3.68524 11.6815 4.0305 11.681 4.37627C13.4029 4.47456 15.0902 5.10533 16.4486 6.1688C17.8341 7.24313 18.8793 8.75016 19.3925 10.4271C19.9008 12.07 19.8978 13.866 19.3866 15.5073C18.7548 17.5735 17.3051 19.373 15.4232 20.4345C14.0781 21.2075 12.5178 21.5937 10.9678 21.5537C9.53187 21.5226 8.10584 21.1205 6.86456 20.3989C5.48645 19.6007 4.33753 18.4127 3.58871 17.0075C2.79444 15.5296 2.45708 13.8151 2.61563 12.146C2.79049 10.2226 3.64601 8.37328 4.99053 6.98875C6.46644 5.44467 8.53805 4.4958 10.6694 4.37627C10.6689 4.0305 10.6699 3.68524 10.6694 3.33997C9.99519 3.33898 9.32095 3.33997 8.64672 3.33947C8.64523 3.0026 8.64672 2.66573 8.64622 2.32935ZM10.741 5.37552C8.57658 5.48518 6.49361 6.58766 5.17576 8.3061C4.13551 9.63926 3.56648 11.33 3.59019 13.0208C3.59809 14.6864 4.18144 16.3416 5.21774 17.6451C6.19674 18.8904 7.57535 19.8165 9.10016 20.2463C10.3681 20.6073 11.7304 20.6296 13.0092 20.3095C14.5029 19.941 15.8741 19.0988 16.8838 17.9385C17.7818 16.9161 18.3962 15.6476 18.6378 14.308C18.9183 12.7778 18.7158 11.1621 18.0564 9.75188C17.3925 8.32043 16.2698 7.10779 14.8941 6.33526C13.6405 5.62299 12.1789 5.29204 10.741 5.37552Z" fill="#64656D" stroke="white" stroke-width="0.232941" />
                                <path d="M1.87701 7.22361C3.07088 6.03369 4.2608 4.84081 5.45269 3.64941C5.69126 3.8875 5.92984 4.12607 6.16792 4.36465C4.97554 5.55605 3.78414 6.74892 2.59175 7.94032C2.35367 7.70125 2.11312 7.46465 1.87701 7.22361Z" fill="#64656D" stroke="white" stroke-width="0.232941" />
                                <path d="M16.1826 4.36415C16.4221 4.12706 16.6602 3.88848 16.8983 3.64941C18.0902 4.8418 19.2831 6.0327 20.474 7.22657C20.2364 7.46465 19.9983 7.70273 19.7602 7.94032C18.5673 6.74892 17.3764 5.55506 16.1826 4.36415Z" fill="#64656D" stroke="white" stroke-width="0.232941" />
                                <path d="M10.6693 8.39881C11.0066 8.39831 11.3435 8.39831 11.6809 8.39881C11.6833 9.84805 11.6764 11.2978 11.6838 12.747C12.6495 13.7028 13.6072 14.6665 14.568 15.6267C14.3304 15.8658 14.0913 16.1039 13.8527 16.3424C12.7912 15.2805 11.7288 14.22 10.6688 13.1565C10.6702 11.5704 10.6688 9.98487 10.6693 8.39881Z" fill="#64656D" stroke="white" stroke-width="0.232941" />
                            </svg>


                            <div>
                                <p>Regulatory & Compliance Alerts</p>
                                <p>
                                    Stay ahead of changing regulations that impact your business
                                </p>
                            </div>
                        </ListCell>
                    </GridColumn>
                    <GridColumn>
                        <div>
                            <p style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>
                                HR Experts On Call
                            </p>
                            <p>
                                Policyholders can get their toughest HR questions answered by
                                certified HR professionals
                            </p>
                        </div>
                        <ListCell>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.02005 0.532451C8.50522 0.532451 10.9904 0.531947 13.4751 0.532451C13.4755 1.39274 13.4746 2.25252 13.4755 3.11281C15.3386 3.11382 17.2011 3.11281 19.0641 3.11331C19.0661 7.84313 19.0646 12.5734 19.0651 17.3038C12.8534 17.3043 6.64171 17.3038 0.430019 17.3038C0.430989 12.5734 0.428563 7.84313 0.430989 3.11281C2.29401 3.11281 4.15655 3.11382 6.01908 3.1123C6.02103 2.25252 6.01908 1.39223 6.02005 0.532451ZM7.26385 1.82213C7.26385 2.25252 7.26288 2.68292 7.26385 3.11281C8.91965 3.11281 10.5755 3.11432 12.2313 3.1118C12.2313 2.68191 12.2317 2.25202 12.2313 1.82162C10.5755 1.82162 8.91965 1.82011 7.26385 1.82213ZM1.67284 4.40248C1.67236 6.12255 1.67284 7.84313 1.67236 9.5637C3.53635 9.56421 5.40034 9.5637 7.26385 9.56421C7.26191 8.92012 7.26579 8.27604 7.26191 7.63196C8.91868 7.6244 10.5759 7.62491 12.2327 7.63196C12.2303 8.27554 12.2317 8.91962 12.2317 9.5637C14.0953 9.5637 15.9588 9.56421 17.8223 9.5637C17.8228 7.84313 17.8223 6.12255 17.8228 4.40198C12.4394 4.40198 7.05615 4.40198 1.67284 4.40248ZM8.50473 8.92113C8.50813 9.78041 8.49939 10.6402 8.5091 11.4995C9.33506 11.4959 10.161 11.4975 10.9875 11.4985C10.9947 10.6397 10.9865 9.78092 10.9913 8.92214C10.163 8.91458 9.3336 8.91458 8.50473 8.92113ZM1.67284 10.8529C1.67236 12.5734 1.67284 14.294 1.67236 16.0146C7.05566 16.0151 12.439 16.0151 17.8223 16.0146C17.8232 14.294 17.8223 12.5734 17.8228 10.8529C15.9583 10.8549 14.0933 10.8493 12.2293 10.8554C12.2347 11.5 12.2293 12.1446 12.2317 12.7886C10.5759 12.7897 8.92014 12.7897 7.26433 12.7886C7.26288 12.1436 7.26385 11.498 7.26385 10.8529C5.39986 10.8534 3.53635 10.8524 1.67284 10.8529Z" fill="#64656D" stroke="white" stroke-width="0.559059" />
                            </svg>


                            <div>
                                <p>Terminations & Disciplinary Actions</p>
                                <p>
                                    Get guidance & best practices when disciplinary action or termination is necessary
                                </p>
                            </div>
                        </ListCell>
                        <ListCell>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.65837 0.694363C3.65266 0.528357 3.82673 0.39226 3.98635 0.435273C4.11842 0.462157 4.20579 0.595902 4.1947 0.727295C4.19504 1.434 4.19504 2.14103 4.19436 2.84773C7.24127 2.84706 10.2882 2.8474 13.3351 2.8474C13.3361 2.12994 13.3331 1.41182 13.3364 0.694363C13.3327 0.551208 13.4614 0.42452 13.6039 0.428552C13.7467 0.424856 13.8744 0.551544 13.8714 0.694363C13.8751 1.41182 13.8714 2.12961 13.8728 2.8474C14.938 2.84773 16.0029 2.84706 17.0682 2.8474C17.164 2.84236 17.2641 2.87999 17.3182 2.96232C17.3858 3.05574 17.3639 3.17672 17.368 3.28459C17.3669 7.9677 17.368 12.6508 17.3673 17.3339C17.3861 17.5009 17.2352 17.6518 17.0682 17.6333C11.5326 17.6333 5.99724 17.6333 0.461588 17.633C0.294574 17.6515 0.143354 17.5009 0.162173 17.3336C0.162173 12.6048 0.162173 7.87596 0.162509 3.14681C0.146043 2.99425 0.272059 2.84437 0.428656 2.84874C1.50467 2.84572 2.58102 2.84807 3.65737 2.84773C3.6577 2.12994 3.65501 1.41215 3.65837 0.694363ZM0.699507 3.38473C0.700179 7.95526 0.700179 12.5255 0.699507 17.096C6.07655 17.0953 11.4532 17.0953 16.8303 17.096C16.8296 12.5255 16.8296 7.95526 16.8303 3.38473C15.8443 3.3854 14.8584 3.38507 13.8728 3.38507C13.8728 4.18115 13.8731 4.97758 13.8724 5.774C13.8839 5.9054 13.7958 6.03847 13.6644 6.06569C13.5048 6.1087 13.3307 5.97294 13.3364 5.80727C13.3327 4.99976 13.3361 4.19258 13.3351 3.38507C10.2882 3.38473 7.24127 3.38574 4.19436 3.38473C4.19403 4.19224 4.19672 4.99976 4.19302 5.80693C4.19537 5.93833 4.0875 6.0583 3.95678 6.07073C3.80455 6.09291 3.654 5.96118 3.65837 5.80693C3.65501 4.99976 3.6577 4.19224 3.65737 3.38473C2.67141 3.3854 1.68546 3.3854 0.699507 3.38473Z" fill="#64656D" stroke="#64656D" stroke-width="0.139765" />
                            </svg>


                            <div>
                                <p>Workplace Incidents & Complaints</p>
                                <p>
                                    Get feedback on how to document, respond, & investigate an HR incident or complaint
                                </p>
                            </div>
                        </ListCell>
                        <ListCell>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.87203 0.449662C10.2529 0.335914 11.6615 0.53821 12.948 1.05445C14.5158 1.67708 15.8978 2.75471 16.8879 4.11793C17.669 5.1847 18.205 6.42893 18.4473 7.72775C18.7202 9.19106 18.6255 10.7226 18.1646 12.139C17.7669 13.3721 17.0906 14.5123 16.2099 15.4629C15.2889 16.4548 14.143 17.2398 12.8789 17.7295C11.5819 18.2373 10.1642 18.4281 8.77733 18.2989C7.38838 18.1712 6.03241 17.7113 4.85181 16.9707C3.7168 16.2564 2.74068 15.2897 2.02587 14.1561C1.2153 12.8866 0.742149 11.4069 0.657269 9.9047C0.565024 8.35913 0.879641 6.79047 1.57166 5.4038C2.45658 3.61779 3.95531 2.14467 5.75813 1.28824C6.73355 0.822403 7.79489 0.53891 8.87203 0.449662ZM8.93867 1.00615C7.16671 1.14615 5.44772 1.87028 4.10963 3.0375C2.61687 4.31987 1.60182 6.14123 1.30299 8.08439C1.00801 9.94985 1.36752 11.9116 2.31664 13.546C3.11177 14.9351 4.31412 16.0877 5.73568 16.8261C7.01168 17.4939 8.46271 17.825 9.90321 17.7729C11.4216 17.7267 12.9231 17.2535 14.1953 16.425C15.3366 15.6873 16.2941 14.6684 16.9598 13.4851C17.7181 12.1471 18.0938 10.597 18.032 9.06087C17.9791 7.5587 17.5059 6.07508 16.6834 4.81651C15.6768 3.2608 14.1419 2.05193 12.3875 1.44644C11.2858 1.06005 10.1024 0.912701 8.93867 1.00615Z" fill="#64656D" stroke="#64656D" stroke-width="0.139765" />
                                <path d="M9.9004 2.94297C9.9018 2.61468 9.90285 2.28603 9.90005 1.95774L9.34167 1.95459C9.33851 2.28358 9.34026 2.61258 9.34096 2.94157L9.9004 2.94297Z" fill="#64656D" stroke="#64656D" stroke-width="0.139765" />
                                <path d="M5.25771 4.6308C5.03113 4.40156 4.80174 4.17511 4.57341 3.94727L4.15918 4.32281C4.39348 4.5594 4.62918 4.7953 4.86593 5.02944L5.25771 4.6308Z" fill="#64656D" />
                                <path d="M14.3598 5.04282C14.598 4.81322 14.8291 4.57628 15.0641 4.34319L14.6499 3.96484C14.4335 4.18149 14.2167 4.39778 13.9996 4.61373L14.3598 5.04282Z" fill="#64656D" />
                                <path d="M9.42374 5.54092C9.55807 5.39708 9.82744 5.45798 9.88461 5.64697C9.91864 5.78837 9.89479 5.93641 9.90145 6.08061C10.7839 6.14326 11.5924 6.78969 11.8488 7.63353C11.8989 7.76722 11.8288 7.92962 11.6966 7.98422C11.5549 8.05037 11.3672 7.97022 11.322 7.81937C11.1357 7.19604 10.5524 6.7064 9.90145 6.64375C9.9018 7.51768 9.90075 8.39196 9.9018 9.26589C10.3195 9.39014 10.7471 9.52908 11.0915 9.80523C11.4872 10.1171 11.7225 10.6253 11.6916 11.1285C11.6639 11.5562 11.431 11.9517 11.1052 12.2237C10.772 12.51 10.3353 12.6528 9.90145 12.6853C9.88461 12.8663 9.95932 13.0959 9.80184 13.2323C9.65523 13.3678 9.38866 13.282 9.34938 13.0871C9.32974 12.9447 9.34552 12.8001 9.33816 12.6566C8.47638 12.5268 7.67809 11.9255 7.39504 11.0907C7.32629 10.9298 7.4459 10.7313 7.61881 10.7135C7.75981 10.6893 7.89379 10.7912 7.93168 10.9242C8.14633 11.5303 8.71909 11.9622 9.33921 12.091C9.33956 11.2871 9.34307 10.4832 9.33745 9.67923C8.92498 9.55323 8.50795 9.39259 8.18176 9.10244C7.76156 8.73355 7.54831 8.15291 7.61075 7.60028C7.65389 7.18134 7.8703 6.7855 8.19824 6.5209C8.51952 6.25631 8.93024 6.12436 9.33991 6.08236C9.35885 5.90351 9.28449 5.68512 9.42374 5.54092ZM8.29855 7.25378C8.07478 7.64753 8.13476 8.16761 8.40588 8.52391C8.63527 8.8221 8.99408 8.97574 9.34026 9.09474C9.33991 8.27716 9.34026 7.45958 9.34026 6.642C8.93024 6.6903 8.509 6.88349 8.29855 7.25378ZM9.9011 9.84968C9.9025 10.6085 9.9004 11.3676 9.90215 12.1264C10.3599 12.0865 10.8299 11.8544 11.0407 11.4302C11.2231 11.0781 11.1213 10.6312 10.8558 10.3495C10.6043 10.0803 10.2424 9.96132 9.9011 9.84968Z" fill="#64656D" stroke="#64656D" stroke-width="0.139765" />
                                <path d="M2.61413 9.09834C2.8078 9.09824 3.00547 9.09804 3.16962 9.09921V9.65745C2.84063 9.65885 2.51163 9.66025 2.18298 9.6571V9.09766L2.61413 9.09834Z" fill="#64656D" />
                                <path d="M17.0596 9.10028C16.7099 9.10098 16.3595 9.09118 16.0101 9.10553V9.65781C16.3398 9.65921 16.7306 9.66061 17.0606 9.65746L17.0596 9.10028Z" fill="#64656D" />
                                <path d="M4.55662 14.8266C4.78741 14.6012 5.01364 14.3709 5.24267 14.1441L4.8081 13.7847C4.5938 14.002 4.37528 14.2148 4.16168 14.4325L4.55662 14.8266Z" fill="#64656D" />
                                <path d="M15.0795 14.4323C14.854 14.202 14.6229 13.9766 14.3956 13.748L13.967 14.1089C14.2044 14.3504 14.4447 14.5894 14.686 14.8278L15.0795 14.4323Z" fill="#64656D" />
                                <path d="M9.90147 16.7632C9.89972 16.4349 9.90603 16.1063 9.89797 15.7783H9.34081C9.33835 16.0818 9.34142 16.4594 9.34002 16.7632H9.90147Z" fill="#64656D" />
                            </svg>

                            <div>
                                <p>Wages & Compensation</p>
                                <p>
                                    Receive guidance on avoiding fines & complying with the latest wage & hour laws
                                </p>
                            </div>
                        </ListCell>
                    </GridColumn>
                </Grid>
            </ContentWrapper>
        </CommonContainer>
    );
};

export default React.memo(HRAdvisoryServices);
