import React from 'react';
import { useRouteMatch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { bindAccountWithBindToken } from 'services/generated/quotes';

import Loading from "components/common/Loading";
import styled from "styled-components/macro";

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 999999;
`;

const CallToBindRequest = () => {
  const match = useRouteMatch<{
    quoteID: string;
    publicSecurityToken: string;
    appSecurityToken: string;
    bindQuoteToken: string;
  }>();

  const {publicSecurityToken, quoteID, appSecurityToken, bindQuoteToken} = match.params;

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

    React.useEffect(() => {
        setTimeout(() => {
            bindAccountWithBindToken({publicSecurityToken, appSecurityToken, bindQuoteToken}, quoteID, {params: {appSecurityToken, publicSecurityToken}}).then((data: any) => {
                setError(false);
                setLoading(false);
            }).catch((err: any) => {
                setError(true);
                setLoading(false);
            })
        }, 2000)
    }, [publicSecurityToken, appSecurityToken, bindQuoteToken, quoteID])

    if (error) return <Redirect to="/" />;

    if(loading) {
        return (
            <StyledLoaderContainer style={{ textAlign: "center" }}>
                    <Loading message="" />
            </StyledLoaderContainer>
        )
    }

  return (
    <Redirect to={{ pathname: `/account/${quoteID}/${publicSecurityToken}/${appSecurityToken}/overview?bound=true` }} />
  )
}

export default CallToBindRequest;
