import {
    getApplicationSections as _getApplicationSections,
    getAnswers,
    getUnderwritingGuidelines as _getUnderwritingGuidelines,
    getInitialApplicationData as _getInitialApplicationData,
} from "../generated/application";
import { saveAnswer as _saveAnswer } from "../generated/application";

import groupBy from "lodash/groupBy";
import debounce from "lodash/debounce";

import {
    FullApplicationSection,
    FullQuestion,
    saveQuestionsCache,
    saveQuestionsKeyCache,
    addAnswerToQuestions,
    addGuidelinesToQuestion,
    savedAnswersHasDiff,
    setSavedAnswers,
    getAnswersDiff,
    AnswerFormat,
    saveApplicationData,
} from "./helpers";
import { memoizeAsync } from "utils";

export * from "./helpers";
export * from "../generated/application";
export * from "./hooks";

export const getInitialApplicationData = _getInitialApplicationData;
export const getApplicationSections = _getApplicationSections;
export const getUnderwritingGuidelines = memoizeAsync(_getUnderwritingGuidelines);

async function baseSaveAnswer(id: string, data: AnswerFormat): Promise<any> {
    // Nothing changed skip saving to backend
    if (!savedAnswersHasDiff(data)) return true;
    // Get only what have changed, to avoid making a request with everything
    const diff = getAnswersDiff(data);
    await _saveAnswer(diff as any, id);
    // If we got here saved answers return 200, so update the cached value;
    setSavedAnswers(diff);
}

// Make sure we don't call saveAnswer more than 1 time each 10 seconds
export const saveAnswer = debounce(baseSaveAnswer, 1000, {
    leading: true,
    trailing: false,
});

// Call save answers immediately, canceling any pending invocations
export const saveAnswerNow = (...args: Parameters<typeof baseSaveAnswer>) => {
    saveAnswer.cancel();
    const result = saveAnswer(...args);
    saveAnswer.flush();
    return result;
};

export async function sectionsWithAnswers(
    id: string,
    appSecurityToken: any,
): Promise<FullApplicationSection[]> {
    const [initialData, answers, guidelines] = await Promise.all([
        getInitialApplicationData(appSecurityToken, id),
        getAnswers(appSecurityToken, id),
        getUnderwritingGuidelines(appSecurityToken, id),
    ]);
    const answersForQuestion = groupBy(answers, "question");
    const guidelinesForQuestion = groupBy(guidelines, "question");
    saveApplicationData(initialData);
    for (const section of initialData.sections) {
        saveQuestionsKeyCache(section.questions as FullQuestion[]);
        saveQuestionsCache(section.questions as FullQuestion[]);
        addAnswerToQuestions(section.questions as FullQuestion[], answersForQuestion);
        addGuidelinesToQuestion(section.questions as FullQuestion[], guidelinesForQuestion);
    }
    return initialData.sections as FullApplicationSection[];
}
