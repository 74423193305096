import styled from "styled-components/macro";
import { QuestionTitle, MuseoSansBaseCSS } from "components/typography";

import responsive from "services/responsive";

export const TitleForTable = styled.span`
    ${QuestionTitle}
    font-weight: normal;
`;

export const QuestionTitleContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    & > ${TitleForTable} {
        display: none;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        & > ${TitleForTable} {
            display: flex;
        }
    }
`;

export const InputContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    & > label.label-container {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
        &:nth-child(even) {
            margin-left: 20px;
        }
    }

    &.safari-ie {
        display: none;
    }

    ${responsive.InternetExplorer}, ${responsive.Safari} & {
        &.safari-ie {
            display: flex;
        }
        &.no-safari-ie {
            display: none;
        }
    }
`;

export const QuestionsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    &.horizontal_category {
        flex-direction: row;
        justify-content: space-between;
    }
    & > .question-row-container {
        display: flex;
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
`;

export const QuestionRowContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 0;

    & > div.main-title > ${QuestionTitle} {
        font-weight: normal;
    }

    &:not(.category) > ${QuestionTitleContainer} {
        flex: 0 0 67%;
        &.horizontal_category {
            ${responsive.Edge} & {
                flex-basis: 0;
            }
        }
    }

    & > ${QuestionTitleContainer} {
        padding-right: 140px;

        &
            > div.main-title.subcategory-question-title
            > ${QuestionTitle},
            &.category
            > div.main-title.subcategory-question-title
            > ${QuestionTitle} {
            ${MuseoSansBaseCSS}
            font-size: 12px;
            font-weight: 300;
            line-height: 160%;
            text-transform: uppercase;
        }
    }

    &.textarea {
        & > ${QuestionTitleContainer} {
            flex: 0 0 100%;
            padding-right: 0;
            max-width: 100%;
        }
        flex-direction: column;
        width: 100%;
        & div.textarea > div.main-title > ${QuestionTitle} {
            font-weight: 300;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 160%;
            margin-bottom: 10px;
            margin-top: -25px;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:not(.category) > ${QuestionTitleContainer} {
            flex: none !important;
        }
    }

    @media (max-width: 700px) {
        flex-direction: column;
        &:not(.category) > ${QuestionTitleContainer} {
            flex: 0 0 100%;
        }

        & > ${QuestionTitleContainer} {
            padding-right: 0;
            & > div.main-title > ${QuestionTitle} {
                padding-bottom: 16px;
            }
        }
    }
    @media print {
        & {
            page-break-inside: avoid;
        }
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        width: 100%;

        &:not(.category) > ${QuestionTitleContainer} {
            width: 67%;
        }
    }
`;

export const CategoryContainer = styled(QuestionRowContainer as any)`
    position: relative;
    display: flex;
    flex-direction: column;

    & > ${QuestionTitleContainer} {
        padding-right: 0;

        & > div.main-title > ${QuestionTitle} {
            font-size: 1rem;
            font-weight: 700;
            line-height: 120%;
            padding-bottom: 10px;
        }

        &.category > div.main-title > ${QuestionTitle} {
            font-size: 1rem;
            line-height: 160%;
            font-weight: bold;
        }
    }
    @media print {
        & {
            page-break-inside: avoid;
            break-inside: avoid;
        }
    }
`;

export const HorizontalCategoryContainer = styled(CategoryContainer as any)`
    position: relative;
    max-width: 100%;
    & > div.horizontal_category > ${QuestionTitle} {
        font-size: 1rem;
        font-weight: normal;
        line-height: 160%;
        padding-bottom: 10px;
    }

    & > .remove-option {
        position: absolute;
        display: inline-flex;
    }

    /* Add more space to the Address field */
    & .address ~ .number {
        max-width: 180px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block !important;
    }

    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const HorizontalCategoryQuestions = styled.div`
    position: relative;
    display: flex;
    flex: 0 0 100%;
    ${responsive.Edge} & {
        flex: 1;
    }

    & > ${QuestionRowContainer} {
        display: flex;
        flex-direction: column;
        &:not(.category) > ${QuestionTitleContainer} {
            display: flex;
            flex: 1;
            padding-right: 0;

            & > div.main-title {
                max-width: 875px;
                & > ${QuestionTitle} {
                    font-weight: normal !important;
                    padding-bottom: 10px;
                }
            }
        }

        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @media (max-width: 700px) {
        flex-direction: column;

        & > ${QuestionRowContainer} {
            &:not(:first-child) {
                margin-left: 0;
            }
        }
    }
`;

export const TableCategoryQuestions = styled.div`
    display: flex;

    & > ${CategoryContainer} {
        & > ${QuestionTitleContainer} {
            & > div.main-title {
                padding-left: 20px;
            }
        }

        /** styles for table borders */

        &:first-child {
            & > ${QuestionTitleContainer} {
                & > div.main-title {
                    padding-left: 200px;
                }
            }

            & > div[data-name="QuestionRowContainer"] {
                & > div:first-child {
                    flex: 0 0 200px;
                    max-width: 200px;
                    padding-left: 20px;
                    padding-right: 0;
                    align-items: center;

                    & div.main-title.subcategory-question-title > ${QuestionTitle} {
                        ${MuseoSansBaseCSS}
                        font-size: 16px;
                        line-height: 140%;
                        font-weight: normal;
                        text-transform: none;
                    }
                }

                /** styles for table row questions title end*/
            }
        }

        /** styles for table row questions*/
        &:not(:first-child) {
            max-width: calc(25% + 140px);

            @media (min-width: 1450px) {
                max-width: calc(25% + 150px);
            }
            @media (max-width: 1200px) {
                max-width: calc(25% + 110px);
            }
            & > div[data-name="QuestionRowContainer"] {
                padding: 20px;
                & > div:first-child {
                    display: none;
                }
            }
        }

        /** styles for table row questions end*/

        & > div[data-name="QuestionRowContainer"] {
            display: flex;
            justify-content: space-between;
            & > div:not(:first-child) {
                max-width: 300px;
            }
        }

        @media (max-width: 800px) {
            &:first-child {
                & > div[data-name="QuestionRowContainer"] {
                    padding-right: 0;
                }

                & > ${QuestionTitleContainer} {
                    & > div.main-title {
                        justify-content: flex-end;
                        padding-right: 80px;
                    }
                }
            }
            &:not(:first-child) {
                max-width: calc(33% + 40px);
                & > div[data-name="QuestionRowContainer"] {
                    padding: 20px;
                }
            }
        }

        @media (max-width: 700px) {
            flex-direction: column;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            padding: 0 !important;

            & > div[data-name="QuestionRowContainer"] {
                padding: 20px 0 !important;
                border: none !important;
                flex-direction: column;

                & > div:first-child {
                    display: inline-flex !important;
                    flex: 1 !important;
                    padding-left: 0 !important;
                    align-items: flex-start !important;

                    & > div.main-title {
                        flex-direction: row;
                        align-items: flex-start;
                    }

                    & > ${TitleForTable} {
                        font-weight: normal;
                        padding: 10px 0 10px 0;
                    }
                }
            }

            & > ${QuestionTitleContainer} {
                display: none !important;
            }
        }
    }

    @media (max-width: 700px) {
        flex-direction: column;
    }

    @media print {
        & {
            page-break-inside: avoid !important;
        }
    }
`;

export const TableCategoryContainer = styled(CategoryContainer as any)`
    @media print {
        & {
            page-break-inside: avoid !important;
            display: block;
        }
    }
`;
