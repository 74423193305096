import React from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { getQuoteRenewalContext } from "services/quotes";
import { toast } from "react-toastify";

import { copyToClipboard, formatCurrency, parseQueryString, useApiGet } from "../../utils";

import { downloadPDF } from "../../services/generated/quotes";

import { H2, Body3, Body2 } from "components/typography";

import {
    Button,
    CancelButton,
    Loading,
    Page,
    SectionContainer,
    Header,
    StackedPaperPaddedContainer,
    StackedPaper,
    BlueDivider,
    Footer,
} from "components/common";

import topArrow from "./assets/topArrow.svg";
import bottomArrow from "./assets/bottomArrow.svg";

const Cell = styled.div<{ arrow?: string }>`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 12px 30px;
    flex-direction: column;

    .currency-span {
        font-family: Arial;

        @media (min-width: 769px) {
            &.center {
                margin: auto;
            }
        }

        &.premium {
            font-size: 20px;

            @media (max-width: 768px) {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 768px) {
        grid-column: 1 / 3;

        span {
            font-weight: bold;
        }
    }

    @media (max-width: 480px) {
        padding: 0;
    }

    ${({ arrow }) =>
        arrow === "top" &&
        `
    background: url(${topArrow});
    background-size: 10px 15px;
    background-position: 13px 16px;
    background-repeat: no-repeat;
  `}

    ${({ arrow }) =>
        arrow === "bottom" &&
        `
    background: url(${bottomArrow});
    background-size: 10px 15px;
    background-position: 13px 16px;
    background-repeat: no-repeat;
  `}

  @media (max-width: 768px) {
        background: none;
    }

    &:first-child {
        @media (min-width: 769px) {
            padding: 12px 30px 12px 20px;
        }

        @media (max-width: 768px) {
            padding-bottom: 16px;
        }
        &:before {
            left: 20px !important;
        }
    }

    &:not(:first-child) {
        border-left: 1px solid #bfcad6;
        border-right: 1px solid #bfcad6;
        align-items: center;

        @media (max-width: 768px) {
            border: none;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &:not(:last-child) {
        @media (max-width: 768px) {
            &:before {
                display: none;
            }
        }
    }

    &:last-child {
        @media (min-width: 769px) {
            padding: 12px 20px;
        }

        @media (max-width: 768px) {
            padding-bottom: 28px;
        }
    }
    &:not(.no-border) {
        &:before {
            content: "";
            height: 1px;
            width: calc(100% - 20px);
            left: 10px;
            bottom: 0;
            position: absolute;
            background-color: #ebf0f6;

            @media (max-width: 768px) {
                width: 100%;
                left: 0;
            }
        }
    }

    ${Body3} {
        margin-bottom: 10px;
        font-family: sans-serif;
    }

    ${Body2} {
        line-height: 21px;
        margin-bottom: 0;
    }
`;

const ShowOnMobile = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 767px) {
        display: none;
    }
`;

const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 0;
    }

    ${H2} {
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
`;

const StyledBlueDivider = styled(BlueDivider as any)`
    margin: 36px auto 32px 20px !important;

    @media (max-width: 768px) {
        margin: 36px auto 32px 0 !important;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;

    @media (max-width: 360px) {
        width: 100%;
    }

    ${Button} {
        text-transform: uppercase;

        min-width: 180px;
        min-height: 40px;
        padding: 10px 20px;

        @media (max-width: 768px) {
            margin-bottom: 30px;
            min-width: 150px;
        }

        @media (max-width: 480px) {
            min-width: 110px;
            padding: 10px;
        }

        &:first-child {
            margin-right: 10px;
        }
    }
`;

const DateSpan = styled.span`
    font-size: 12px;
    color: #22232e66;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
`;

const InfoTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding-right: 18px;

    @media (max-width: 768px) {
        padding-right: 0;
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 140px 140px;
    grid-column-gap: 20px;

    @media (max-width: 768px) {
        margin-bottom: 24px;
    }

    &.premium-row {
        ${Cell}:not (:first-child) {
            border-radius: 8px 8px 0 0 !important;
        }
    }

    &.section-title {
        background: #f5f7fa;
        width: calc(100% + 18px);

        @media (max-width: 768px) {
            width: auto;
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 10px !important;
            grid-template-columns: auto;
        }

        @media (max-width: 480px) {
            padding-left: 0;
        }

        ${Cell} {
            padding-top: 16px;
            margin-top: -16px;
            padding: 22px 10px 9px 10px;

            &:not(:first-child) {
                left: -18px;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            &:first-child {
                color: #111da1;
                padding-left: 20px;
                font-size: 18px;

                @media (max-width: 480px) {
                    padding-left: 10px;
                }

                &:before {
                    display: none;
                }
            }

            &:not(:first-child) {
                &:after {
                    content: "";
                    position: absolute;
                    top: 16px;
                    left: 0;
                    height: calc(100% - 16px);
                    width: calc(100% + 2px);
                    margin-left: -1px;
                    background-color: #f5f7fa;
                }
            }

            ${DateSpan} {
                position: relative;
                z-index: 1;
            }
        }
    }

    &:not(.section-title) {
        @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(3, auto);
        }
    }

    &.no-border-bottom {
        ${Cell} {
            &:before {
                display: none;
            }
        }
    }

    &.last-row {
        @media (max-width: 768px) {
            margin-bottom: 50px;
        }

        ${Cell} {
            padding-bottom: 50px;

            @media (max-width: 768px) {
                padding-bottom: 12px;
            }
        }
    }

    &:first-child {
        ${Cell} {
            &:not(:first-child) {
                border-top: 1px solid #bfcad6;
                border-radius: 8px;
            }
        }
    }

    &:last-child {
        ${Cell} {
            &:before {
                display: none;
            }

            &:not(:first-child) {
                @media (min-width: 768px) {
                    border-bottom: 1px solid #bfcad6;
                    border-radius: 0 0 8px 8px;
                }
            }
        }
    }
`;

const StyledStackedPaper = styled(StackedPaper as any)`
    padding: 45px 20px;

    /* @media (max-width: 768px) {
        padding: 45px 20px;
    } */

    ${BlueDivider} {
        margin: 36px auto 32px 0;
        width: 80px;

        @media (max-width: 768px) {
            margin: 16px auto 32px 0;
        }
    }
`;

const Arrow = styled.div<{ direction: string }>`
    height: 12px;
    width: 8px;

    ${({ direction }) =>
        direction === "top"
            ? `
      background: url(${topArrow});
    `
            : `
      background: url(${bottomArrow});
    `}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    @media (min-width: 769px) {
        display: none;
    }

    ${({ direction }) => direction === "no-arrow" && "display: none;"}
`;

const CurrencyContainer = styled.div`
    display: flex;
    align-items: center;
    font-family: Arial;

    @media (min-width: 769px) {
        width: 100%;
        justify-content: center;
    }

    ${Arrow} {
        margin-right: 10px;
    }
`;

const getSummaryTotalPremiums = (components: any) => {
    const res = {
        parent: "-",
        renewal: "-",
    };

    if (components) {
        const parent = components
            .map(
                (item: any) =>
                    +item.premiums.parent
                        .substring(1)
                        .split(",")
                        .join(""),
            )
            .reduce((acc: number, item: number) => acc + item);
        const renewal = components
            .map(
                (item: any) =>
                    +item.premiums.renewal
                        .substring(1)
                        .split(",")
                        .join(""),
            )
            .reduce((acc: number, item: number) => acc + item);
        if (parent) res.parent = formatCurrency(parent);
        if (renewal) res.renewal = formatCurrency(renewal);
    }
    return res;
};

const getRows = (limits: any, parent: any, renewal: any) => {
    return Object.values(limits)
        .map((limit: any) => {
            return `${limit.name}:
      ${parent}: ${limit.parent ? formatCurrency(limit.parent) : "-"}
      ${renewal}: ${limit.renewal ? formatCurrency(limit.renewal) : "-"}

      `;
        })
        .join("");
};

const getEndorsements = (endorsements: any, parent: any, renewal: any) => {
    return Object.values(endorsements)
        .map((endorsement: any) => {
            return `${endorsement.name};
      ${parent}: ${endorsement.parent ? "+" : "-"}
      ${renewal}: ${endorsement.renewal ? "+" : "-"}

      `;
        })
        .join("");
};

function getSummary(data: any): string {
    const parentRange = data.components[0].dateRanges.parent;
    const renewalRange = data.components[0].dateRanges.renewal;
    let summary = `Renewal Coverage Highlights

Total Premium:
    ${parentRange}: ${getSummaryTotalPremiums(data.components).parent}
    ${renewalRange}: ${getSummaryTotalPremiums(data.components).renewal}
  `;
    data.components.forEach((component: any) => {
        summary += `\r\n${component.name}
    Premium:
      ${parentRange}: ${component.premiums.parent || "-"}
      ${renewalRange}: ${component.premiums.renewal || "-"}

    ${
        Object.keys.length
            ? `Limit:
      ${getRows(component.limits, parentRange, renewalRange)}
    `
            : ""
    }
    ${
        Object.keys(component.retentions).length
            ? `Retention:
      ${getRows(component.retentions, parentRange, renewalRange)}
    `
            : ""
    }
    ${
        Object.keys(component.endorsements).length
            ? `Endorsements:
      ${getEndorsements(component.endorsements, parentRange, renewalRange)}
    `
            : ""
    }


`;
    });
    return summary;
}

const CheckMark = () => {
    return (
        <svg
            style={{ alignSelf: "center", marginTop: 4, marginBottom: "auto" }}
            width="12"
            height="10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.726.29a.857.857 0 00-.278-.215.76.76 0 00-.657 0 .857.857 0 00-.278.214L4.152 7.588 1.479 4.516a.87.87 0 00-.286-.211.775.775 0 00-.668.013.881.881 0 00-.28.222c-.08.095-.142.206-.184.328a1.13 1.13 0 00.012.765c.045.12.111.23.194.32l3.279 3.758c.079.091.173.164.278.214a.76.76 0 00.656 0 .857.857 0 00.278-.214l6.968-7.983a.989.989 0 00.203-.327 1.102 1.102 0 000-.785.989.989 0 00-.203-.327z"
                fill="#12B3DC"
            />
        </svg>
    );
};

const RenewalCoverageHighlights = () => {
    const { quoteId } = useParams<{ quoteId: string }>();
    const { brokerId, token } = parseQueryString();

    const [loading, data, error] = useApiGet(getQuoteRenewalContext, brokerId, token, quoteId);

    const getTotalPremiums = (components: any) => {
        const res = {
            parent: "-",
            renewal: "-",
        };

        if (components) {
            const parent = components
                .map(
                    (item: any) =>
                        +item.premiums.parent
                            .substring(1)
                            .split(",")
                            .join(""),
                )
                .reduce((acc: number, item: number) => acc + item);
            const renewal = components
                .map(
                    (item: any) =>
                        +item.premiums.renewal
                            .substring(1)
                            .split(",")
                            .join(""),
                )
                .reduce((acc: number, item: number) => acc + item);
            if (parent) res.parent = formatCurrency(parent);
            if (renewal) res.renewal = formatCurrency(renewal);
        }
        return res;
    };

    const getCurrency = (price: number) => (price ? formatCurrency(price) : "-");

    const getCheckMark = (flag: boolean) =>
        flag ? (
            <CheckMark />
        ) : (
            <span
                style={{
                    alignSelf: "center",
                    marginTop: 2,
                    marginBottom: "auto",
                    lineHeight: "14px",
                }}
            >
                -
            </span>
        );

    const getArrowDirection = (parent: string | number, renewal: string | number): string => {
        let parentVal, renewalVal;
        if (typeof parent === "string" && typeof renewal === "string") {
            parentVal = Number(
                parent
                    .substring(1)
                    .split(",")
                    .join(""),
            );
            renewalVal = Number(
                renewal
                    .substring(1)
                    .split(",")
                    .join(""),
            );
        } else {
            parentVal = parent;
            renewalVal = renewal;
        }

        if (!renewalVal) {
            return "no-arrow";
        } else if (parentVal < renewalVal) {
            return "top";
        } else if (parentVal > renewalVal) {
            return "bottom";
        }
        return "no-arrow";
    };

    const getPDF = async () => {
        // Another way to download pdf. It'll open it in browser;
        // const res = await downloadPDF(quoteId, 'renewal');
        // let blob = new Blob([res], {type: "application/pdf"});
        // let file = window.URL.createObjectURL(blob);
        // window.location.assign(file);

        const res = await downloadPDF(quoteId, "renewal");
        const blob = new Blob([res], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "renewal.pdf";
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    if (loading) {
        return (
            <Page>
                <StackedPaperPaddedContainer style={{ maxWidth: 920 }}>
                    <SectionContainer>
                        <StackedPaper style={{ marginTop: 100 }}>
                            <div style={{ gridArea: "col3", textAlign: "center" }}>
                                <Loading />
                            </div>
                        </StackedPaper>
                    </SectionContainer>
                </StackedPaperPaddedContainer>
            </Page>
        );
    }

    if (error) return <Redirect to="/" />;

    return (
        <Page>
            <Helmet>
                <title>Counterpart | Quote Summary</title>
            </Helmet>
            <Header>
                <Body3
                    style={{
                        marginTop: "20px",
                        marginLeft: 20,
                        paddingLeft: 20,
                        borderLeftColor: "#bfcad6",
                        borderLeftWidth: 1,
                        borderLeftStyle: "solid",
                        maxWidth: "400px",
                    }}
                >
                    {/* <strong
                    style={{ fontWeight: "bold" }}
                >Some company name</strong> {`${quote.statusText} ${quote.company.name}`} */}
                </Body3>
            </Header>
            <StackedPaperPaddedContainer style={{ maxWidth: 920 }}>
                <StyledStackedPaper>
                    <Heading>
                        <H2>Renewal Coverage Highlights</H2>
                        <ButtonsContainer>
                            <CancelButton
                                onClick={() => {
                                    copyToClipboard(getSummary(data));
                                    toast.success("Content Copied");
                                }}
                            >
                                Copy Content
                            </CancelButton>
                            <Button onClick={getPDF}>Download PDF</Button>
                        </ButtonsContainer>
                    </Heading>
                    <StyledBlueDivider />
                    <InfoTable>
                        <Row className="section-title">
                            <Cell>
                                <strong>Total</strong>
                            </Cell>
                            <Cell>
                                <DateSpan>Expiring Policy</DateSpan>
                            </Cell>
                            <Cell>
                                <DateSpan>Renewal Quote</DateSpan>
                            </Cell>
                        </Row>

                        <Row className="no-border-bottom last-row">
                            <Cell>
                                <strong>Premium</strong>
                            </Cell>
                            <Cell>
                                <ShowOnMobile>
                                    <DateSpan>Expiring Policy</DateSpan>
                                </ShowOnMobile>
                                <strong className="currency-span premium">
                                    {getTotalPremiums(data?.components).parent
                                        ? getTotalPremiums(data?.components).parent
                                        : "-"}
                                </strong>
                            </Cell>
                            <Cell
                                style={{ backgroundPosition: "13px 19px" }}
                                arrow={getArrowDirection(
                                    getTotalPremiums(data?.components).parent,
                                    getTotalPremiums(data?.components).renewal,
                                )}
                            >
                                <ShowOnMobile>
                                    <DateSpan>Renewal Quote</DateSpan>
                                </ShowOnMobile>
                                <CurrencyContainer>
                                    {
                                        <Arrow
                                            direction={getArrowDirection(
                                                getTotalPremiums(data?.components).parent,
                                                getTotalPremiums(data?.components).renewal,
                                            )}
                                        />
                                    }
                                    <strong className="currency-span premium">
                                        {getTotalPremiums(data?.components).renewal
                                            ? getTotalPremiums(data?.components).renewal
                                            : "-"}
                                    </strong>
                                </CurrencyContainer>
                            </Cell>
                        </Row>
                        {data?.components.map((component: any, componentIndex: number) => {
                            return (
                                <React.Fragment key={`component_#${componentIndex}`}>
                                    <Row className="section-title">
                                        <Cell>
                                            <strong>{component.name}</strong>
                                        </Cell>
                                        <Cell></Cell>
                                        <Cell></Cell>
                                    </Row>
                                    <Row className="no-border-bottom last-row">
                                        <Cell>
                                            <strong>Premium</strong>
                                        </Cell>
                                        <Cell>
                                            <ShowOnMobile>
                                                <DateSpan>Expiring Policy</DateSpan>
                                            </ShowOnMobile>
                                            <strong
                                                className={`currency-span${
                                                    !component.premiums.parent ? " center" : ""
                                                }`}
                                            >
                                                {component.premiums.parent
                                                    ? component.premiums.parent
                                                    : "-"}
                                            </strong>
                                        </Cell>
                                        <Cell
                                            arrow={getArrowDirection(
                                                component.premiums.parent,
                                                component.premiums.renewal,
                                            )}
                                        >
                                            <ShowOnMobile>
                                                <DateSpan>Renewal Quote</DateSpan>
                                            </ShowOnMobile>
                                            <CurrencyContainer>
                                                {
                                                    <Arrow
                                                        direction={getArrowDirection(
                                                            component.premiums.parent,
                                                            component.premiums.renewal,
                                                        )}
                                                    />
                                                }
                                                <strong
                                                    className={`currency-span${
                                                        !component.premiums.renewal ? " center" : ""
                                                    }`}
                                                >
                                                    {component.premiums.renewal
                                                        ? component.premiums.renewal
                                                        : "-"}
                                                </strong>
                                            </CurrencyContainer>
                                        </Cell>
                                    </Row>
                                    {Object.keys(component.limits).length && (
                                        <React.Fragment key={`limits_data_row_#${componentIndex}`}>
                                            <Row className="no-border-bottom">
                                                <Cell>
                                                    <strong>Limits</strong>
                                                </Cell>
                                                <Cell></Cell>
                                                <Cell style={{ padding: 0 }}></Cell>
                                            </Row>
                                            {Object.values(component.limits).map(
                                                (limit: any, index, arr: any) => (
                                                    <Row
                                                        className={`${index === arr.length - 1 &&
                                                            "no-border-bottom last-row"}`}
                                                        key={`limits_row_#${index}`}
                                                    >
                                                        <Cell>{limit.name}</Cell>
                                                        <Cell>
                                                            <ShowOnMobile>
                                                                <DateSpan>Expiring Policy</DateSpan>
                                                            </ShowOnMobile>
                                                            <span
                                                                className={`currency-span${
                                                                    !limit.parent ? " center" : ""
                                                                }`}
                                                            >
                                                                {getCurrency(limit.parent)}
                                                            </span>
                                                        </Cell>
                                                        <Cell
                                                            arrow={getArrowDirection(
                                                                limit.parent,
                                                                limit.renewal,
                                                            )}
                                                        >
                                                            <ShowOnMobile>
                                                                <DateSpan>Renewal Quote</DateSpan>
                                                            </ShowOnMobile>
                                                            <CurrencyContainer>
                                                                {
                                                                    <Arrow
                                                                        direction={getArrowDirection(
                                                                            limit.parent,
                                                                            limit.renewal,
                                                                        )}
                                                                    />
                                                                }
                                                                <span
                                                                    className={`currency-span${
                                                                        !limit.renewal
                                                                            ? " center"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {getCurrency(limit.renewal)}
                                                                </span>
                                                            </CurrencyContainer>
                                                        </Cell>
                                                    </Row>
                                                ),
                                            )}
                                        </React.Fragment>
                                    )}

                                    {Object.keys(component.retentions).length && (
                                        <React.Fragment
                                            key={`retentions_data_row_#${componentIndex}`}
                                        >
                                            <Row className="no-border-bottom">
                                                <Cell>
                                                    <strong>Retention</strong>
                                                </Cell>
                                                <Cell></Cell>
                                                <Cell style={{ padding: 0 }}></Cell>
                                            </Row>
                                            {Object.values(component.retentions).map(
                                                (retention: any, index, arr: any) => (
                                                    <Row
                                                        className={`${index === arr.length - 1 &&
                                                            "no-border-bottom last-row"}`}
                                                        key={`retentions_row_#${index}`}
                                                    >
                                                        <Cell>{retention.name}</Cell>
                                                        <Cell>
                                                            <ShowOnMobile>
                                                                <DateSpan>Expiring Policy</DateSpan>
                                                            </ShowOnMobile>
                                                            <span className="currency-span">
                                                                {retention.parent === 0
                                                                    ? `$${retention.parent}`
                                                                    : getCurrency(retention.parent)}
                                                            </span>
                                                        </Cell>
                                                        <Cell
                                                            arrow={getArrowDirection(
                                                                retention.parent,
                                                                retention.renewal,
                                                            )}
                                                        >
                                                            <ShowOnMobile>
                                                                <DateSpan>Renewal Quote</DateSpan>
                                                            </ShowOnMobile>
                                                            <CurrencyContainer>
                                                                {
                                                                    <Arrow
                                                                        direction={getArrowDirection(
                                                                            retention.parent,
                                                                            retention.renewal,
                                                                        )}
                                                                    />
                                                                }
                                                                <span className="currency-span">
                                                                    {retention.renewal === 0
                                                                        ? `$${retention.renewal}`
                                                                        : getCurrency(
                                                                              retention.renewal,
                                                                          )}
                                                                </span>
                                                            </CurrencyContainer>
                                                        </Cell>
                                                    </Row>
                                                ),
                                            )}
                                        </React.Fragment>
                                    )}

                                    {!!Object.keys(component.endorsements).length && (
                                        <React.Fragment
                                            key={`endorsements_data_row_#${componentIndex}`}
                                        >
                                            <Row className="no-border-bottom">
                                                <Cell>
                                                    <strong>Endorsements</strong>
                                                </Cell>
                                                <Cell></Cell>
                                                <Cell style={{ padding: 0 }}></Cell>
                                            </Row>
                                            {Object.values(component.endorsements).map(
                                                (endorsement: any, index, arr: any) => (
                                                    <Row
                                                        className={`${index === arr.length - 1 &&
                                                            "no-border-bottom last-row"}`}
                                                        key={`endorsements_row_#${index}`}
                                                    >
                                                        <Cell>
                                                            <Body3>{endorsement.name}</Body3>
                                                            {/* <Body2>{endorsement.description}</Body2> */}
                                                        </Cell>
                                                        <Cell>
                                                            <ShowOnMobile>
                                                                <DateSpan>Expiring Policy</DateSpan>
                                                            </ShowOnMobile>
                                                            {getCheckMark(endorsement.parent)}
                                                        </Cell>
                                                        <Cell
                                                            arrow={getArrowDirection(
                                                                endorsement.parent,
                                                                endorsement.renewal,
                                                            )}
                                                        >
                                                            <ShowOnMobile>
                                                                <DateSpan>Renewal Quote</DateSpan>
                                                            </ShowOnMobile>
                                                            {getCheckMark(endorsement.renewal)}
                                                        </Cell>
                                                    </Row>
                                                ),
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </InfoTable>
                </StyledStackedPaper>
            </StackedPaperPaddedContainer>
            <Footer />
        </Page>
    );
};

export default RenewalCoverageHighlights;
