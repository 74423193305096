import apiClient from "./apiClient";
import * as logger from "./logger";
import { AlertID } from "./store/alerts";
import { extractTimeSkew, saveToken } from "./auth";

type RemoveUndefined<T> = T extends undefined ? never : T;
type RemoveUndefinedKeys<T> = T extends {}
    ? {
          [P in keyof T]-?: RemoveUndefined<T[P]>;
      }
    : T;
type ApiReturnType<T> = T extends Array<any> ? RemoveUndefinedKeys<T[0]>[] : RemoveUndefinedKeys<T>;

export interface DashboardQuote {
    id: string;
    statusText: string;
    company: {
        name: string;
        info: {
            url: string;
            address1: string;
            address2?: string;
            city: string;
            state: string;
            zipcode: string;
        };
        broker: string;
        id: string;
    };
    accountType?: any;
    finalPremium?: any;
    totalComponentPremium?: any;
    status: string;
    commission?: number | null;
    policyEffectiveDate?: string;
    products: string[];
    application: {
        id: string;
        status: string;
        appSecurityToken: string;
    };
    finalized: boolean;
    pdfSigned: boolean;
    alerts: AlertID[];
    createdAt: string;
    expirationDate: string;
    broker: string;
    agency: string;
    submissionDate: string;
    accountLost: boolean;
}

export interface PaginatedDashboardQuote {
    count: number;
    next: string;
    previous?: any;
    results: DashboardQuote[];
}

export async function getDashboardQuotes(
    page = 1,
    query?: string,
    sortColumn?: string,
    sortDirection = "asc",
    types = "application,quote,policy",
    alertsOnly?: boolean,
): Promise<PaginatedDashboardQuote> {
    try {
        const url = "/api/brokers/dashboard_quotes/";
        const direction = sortDirection === "desc" ? "-" : "";
        const resp = await apiClient.get(url, {
            params: {
                page: page || 1,
                query: query || undefined,
                types: types,
                ordering: sortColumn ? `${direction}${sortColumn}` : undefined,
                // eslint-disable-next-line @typescript-eslint/camelcase
                alerts_only: alertsOnly || false,
            },
        });

        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "dashboard quotes"';
        logger.error(msg, { page, query });
        return Promise.reject(error);
    }
}

export async function getDashboardGraphs() {
    try {
        const url = "/api/brokers/dashboard_data/";
        const resp = await apiClient.get(url);

        return resp.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getRetailBrokers(
    page = 1,
    query?: string,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/brokers/retail_brokers_table/`;
        apiOptions = {
            ...apiOptions,
            params: {
                ...{
                    page: page || 1,
                    query: query || undefined,
                },
                ...(apiOptions as any).params,
            },
        };
        const resp = await apiClient.get(url, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "listRetailBrokers"';
        logger.error(msg, { page });
        return Promise.reject(error);
    }
}

export async function verifyMagicLink(token: string): Promise<ApiReturnType<boolean>> {
    let response = null;
    try {
        // eslint-disable-next-line no-var
        response = await apiClient.post(
            `/api/users/exchange-magic-link-token/`,
            { token: token },
            {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            },
        );
        extractTimeSkew(response);
        const { refresh, access } = response?.data;
        saveToken(access, refresh);
        return true;
    } catch (error) {
        logger.error(error);
        return Promise.reject(error);
    }
}
