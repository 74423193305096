import React from "react";

import { Loading, Button, Modal, CancelButton } from "components/common";

import { SubjectivitiesStoreType } from "../store";
import { observer } from "mobx-react-lite";

const SubmitModal: React.FC<{ store: SubjectivitiesStoreType }> = ({ store }) => {
    return (
        <Modal
            isOpen={store.appShowSubmitModal}
            closeable={true}
            closeAction={store.setSubmitModalClosed}
            title="Are you sure you want to submit?"
            buttonStyle="vertical"
            buttons={[
                <Button
                    style={{ width: 250, height: 48 }}
                    key="modalButton1"
                    onClick={store.submitSubjectivities}
                    disabled={store.appIsSubmitting}
                >
                    Submit Application
                </Button>,
                <CancelButton
                    style={{ width: 250, height: 48 }}
                    className="no-hover"
                    key="modalButton2"
                    disabled={store.appIsSubmitting}
                    onClick={store.setSubmitModalClosed}
                >
                    Return to Application
                </CancelButton>,
            ]}
        >
            <p style={{ fontStyle: "italic" }}>
                {store.appIsSubmitting ? (
                    <div
                        className="text-center"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Loading />
                    </div>
                ) : (
                    `Please make sure that your application is complete and accurate prior to submitting. Once submitted you will no longer be able to make changes.`
                )}
            </p>
        </Modal>
    );
};

export default observer(SubmitModal);
