import React from "react";

import { Container, StackedPaper } from "./styled";
import Loading from "./Loading";

interface Props {
    style?: React.CSSProperties;
    message?: string
}

const StackedPaperLoading: React.SFC<Props> = ({ style = {}, message }) => (
    <Container
        style={{
            ...style,
            textAlign: "center",
            display: "flex",
            height: "100%",
            alignItems: "center",
        }}
    >
        <StackedPaper>
            <Loading message={message} />
        </StackedPaper>
    </Container>
);

StackedPaper.defaultProps = {
    style: {},
};

export default StackedPaperLoading;
