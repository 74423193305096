import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import ApplicationEntry from "./components/ApplicationEntry";
import { useApplicationInfo, ApplicationContext } from "services/application";

const Application: React.SFC = observer(() => {
    const { applicationID, appSecurityToken } = useParams();
    const appInfo = useApplicationInfo(applicationID as string, appSecurityToken as string);
    const [additionalQuestions, setAdditionalQuestions] = React.useState([]);
    const value = { ...appInfo, additionalQuestions, setAdditionalQuestions };

    return (
        <ApplicationContext.Provider value={value}>
            <ApplicationEntry />
        </ApplicationContext.Provider>
    );
});

export default Application;
