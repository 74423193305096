import React from "react";
import { AnswerOption } from "CounterpartApiTypes";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { useFormikContext } from "formik";

import { FullQuestion, useApplicationContext } from "services/application";
import { InputContainer, QuestionRowContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import { clearFieldVerificationRequired } from "features/Application/sectionUtils";

import { BaseInput } from "components/common";
import { useField } from "formik";

interface SelectProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

interface TypeAheadProps {
    name: string;
    options: AnswerOption[];
    placeholder: string;
    className?: string;
    extraProps?: any;
}

const SelectComp = BaseInput.withComponent("select");

const TypeAhead: React.SFC<TypeAheadProps> = ({
    name,
    options,
    placeholder,
    className = "",
    extraProps = {},
}) => {
    const { getFieldMeta, setFieldValue, getFieldProps } = useFormikContext<string>();
    const { value } = getFieldMeta<string>(name);
    const { onBlur } = getFieldProps(name);
    const { sections: _sec, setSections } = useApplicationContext();
    const sections = _sec as NonNullable<typeof _sec>;

    const { questionsOnError, setQuestionsOnError } = extraProps;

    const onChange = (values: AnswerOption[]) => {
        clearFieldVerificationRequired(
            "",
            sections,
            setSections,
            questionsOnError,
            setQuestionsOnError,
        );
        if (!values || !values.length) {
            setFieldValue(name, null);
        } else {
            setFieldValue(name, values[0].id);
        }
    };
    const selected = options.filter((o) => o.id === value);

    return (
        <Typeahead
            id={"qq-" + name}
            clearButton={true}
            options={options}
            labelKey="text"
            filterBy={["fullTextSearch", "text"]}
            placeholder={placeholder}
            selected={selected}
            maxResults={100000}
            paginate={false}
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
                className: `custom-form-control temp-text-input is-typeahead ${className} `,
                name,
                autoComplete: "new-password",
                onPaste: (e: any) => {
                    e && e.preventDefault();
                    return false;
                },
            }}
            renderMenu={(results, menuProps) => (
                <Menu itemCount={results.length} {...menuProps} className="typeahead-select-box">
                    {results
                        .sort((a, b) => {
                            const textA = a.text.toUpperCase();
                            const textB = b.text.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                        })
                        .map((result, index) => (
                            <MenuItem
                                className="typeahead-select-item"
                                key={result.id}
                                option={result}
                                position={index}
                                data-container="body"
                                data-toggle="tooltip-typeahead"
                                data-placement="right"
                                title={result.text}
                            >
                                {result.text}
                            </MenuItem>
                        ))}
                </Menu>
            )}
        />
    );
};

const Select: React.SFC<SelectProps> = ({ question, extraProps = {} }) => {
    const { sections: _sec, setSections } = useApplicationContext();

    const { questionsOnError, setQuestionsOnError } = extraProps;
    const sections = _sec as NonNullable<typeof _sec>;
    const fieldName = `${question.id}__${extraProps?.questionIndex || 0}`;
    const [meta] = useField(fieldName);
    const { getFieldMeta, setFieldValue } = useFormikContext<string>();

    const containerClass = extraProps["extraClass"] ?? "";

    if (question.allowTypeaheadOnSelects) {
        return (
            <QuestionRowContainer data-name="QuestionRowContainer" className={containerClass}>
                <QuestionTitleRender question={question} extraProps={extraProps} />
                <InputContainer id={`q-${fieldName}`}>
                    <TypeAhead
                        className={`question-${question.id} ${
                            questionsOnError?.includes(question.id) ? "on-error" : ""
                        } ${question.required ? "field-required" : ""}`}
                        name={fieldName}
                        options={question.options || []}
                        placeholder={question.placeholder || ""}
                        extraProps={extraProps}
                    />
                </InputContainer>
            </QuestionRowContainer>
        );
    } else {
        const { value } = getFieldMeta<string>(fieldName);
        const selectedOption = question?.options?.filter((o) => o.id === value);
        const selected =
            selectedOption != null && selectedOption.length > 0 ? selectedOption[0].id : "";
        return (
            <QuestionRowContainer
                className={`${question.type} ${containerClass}`}
                data-name="QuestionRowContainer"
            >
                <QuestionTitleRender question={question} extraProps={extraProps} />
                <InputContainer>
                    <SelectComp
                        className={`question-${question.id} custom-form-control ${
                            questionsOnError?.includes(question.id) ? "on-error" : ""
                        } ${question.required ? "field-required" : ""}`}
                        name={fieldName}
                        id={"q-" + fieldName}
                        onChange={(e: any) => {
                            clearFieldVerificationRequired(
                                question.id,
                                sections,
                                setSections,
                                setQuestionsOnError,
                                questionsOnError,
                            );
                            if (!e.target.value || e.target.value === "") {
                                setFieldValue(fieldName, null);
                            } else {
                                setFieldValue(fieldName, e.target.value);
                                meta.onChange(e);
                                e.target.className = e.target.className.replace(
                                    /\bon-error\b/g,
                                    "",
                                );
                            }
                        }}
                        value={selected}
                        {...extraProps}
                    >
                        <option value="">{question.placeholder || ""}</option>
                        {question?.options?.map((option) => (
                            <option
                                className="typeahead-select-item"
                                key={option.id}
                                data-container="body"
                                data-toggle="tooltip-typeahead"
                                data-placement="right"
                                value={option.id}
                            >
                                {option.text}
                            </option>
                        ))}
                    </SelectComp>
                </InputContainer>
            </QuestionRowContainer>
        );
    }
};

export default React.memo(Select);
