import React from "react";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import styled from "styled-components/macro";

import { classNames, assert } from "utils";
import { ProgressBar } from "components/common";
import { H3, SectionSelectorText } from "components/typography";
import { useApplicationContext, AnswerFormat, FullApplicationSection } from "services/application";
import { OnePaperBackground } from "counterpart-theme";
import ArrowSectionsPercentage from "assets/arrow-up-icon.svg";
import { ReactComponent as ArrowDownIcon } from "assets/arrow-down.svg";

import { getSectionUrl, calculatePercentage } from "../sectionUtils";

import {
    SectionMenuContainer,
    CustomButton,
    SectionRow,
    ControlContainer,
    SectionMenu,
} from "./styled";

const StyledH3 = styled(H3 as any)`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    padding-left: 5px;
    padding-bottom: 20px;
`;

const StyledOnePaperBackground = styled(OnePaperBackground as any)`
    &.application-paper {
        @media (max-width: 1024px) {
            margin-left: 0;
        }

        @media (max-width: 800px) {
            padding: 10px;
        }
    }
`;

type SectionItemProps = {
    sectionName: string;
    percentage: number;
    url: string;
    section: FullApplicationSection;
    disabled?: boolean;
    clickable: boolean;
    onError?: boolean;
};

const StyledArrowDownIcon = styled(ArrowDownIcon as any)`
    @media (max-width: 1024px) {
        display: none;
    }
`;

const SectionItem: React.SFC<SectionItemProps> = ({
    percentage,
    url,
    sectionName,
    section,
    disabled = false,
    onError = false,
    clickable,
}) => {
    const history = useHistory();
    const callback = () => {
        if (!clickable) return;
        window.scrollTo(0, 0);
        history.push(url);
    };

    const isSelected = window.location.pathname === url;
    const notClickable = !clickable;

    return (
        <SectionMenuContainer className={classNames({ isSelected, disabled, notClickable })}>
            <CustomButton onClick={callback}>
                <SectionRow>
                    <SectionSelectorText
                        className={`section-required ${section} ${onError ? "on-error" : ""}`}
                    >
                        {sectionName}
                    </SectionSelectorText>
                    {!isSelected && <StyledArrowDownIcon />}
                    {/* <SectionSelectorText className={`section-required ${section} arrow`}>&#8594;</SectionSelectorText> */}
                </SectionRow>
                <SectionRow>
                    <ProgressBar
                        className={`section-required ${section} ${onError ? "on-error" : ""}`}
                        percentage={percentage}
                        completeColor="#4BAA8F"
                    />
                </SectionRow>
            </CustomButton>
        </SectionMenuContainer>
    );
};

const SectionSelector = () => {
    const startClosed = (window as any).innerWidth <= 1024 ? true : false;
    const { sections, guidelines } = useApplicationContext();
    const { values } = useFormikContext<AnswerFormat>();
    const [closed, toggleSection] = React.useState(startClosed);

    assert(sections);
    const disabled = !guidelines;

    return (
        <StyledOnePaperBackground className="application-paper section-selector">
            <StyledH3>Go to section:</StyledH3>
            <SectionMenu className={classNames({ closed, disabled })}>
                {sections.map((sec) => (
                    <SectionItem
                        disabled={!sec.isActive || !!guidelines}
                        clickable={!guidelines}
                        sectionName={sec.sectionName}
                        url={getSectionUrl(sec)}
                        percentage={calculatePercentage(sec, values)}
                        key={sec.ratingEngineKey}
                        section={sec}
                        onError={sec.onError}
                    />
                ))}
                <ControlContainer onClick={() => toggleSection(!closed)}>
                    <img src={ArrowSectionsPercentage} alt="close-menu" />
                </ControlContainer>
            </SectionMenu>
        </StyledOnePaperBackground>
    );
};

export default SectionSelector;
