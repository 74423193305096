import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";

import { Formik, Form } from "formik";
import { RouteComponentProps } from "react-router";

import { H1, Body2, Body1, Body3,  } from "components/typography";
import { Row, Alert, FormButton } from "components/common";
import { setPassword } from "services/users";
import { parseQueryString } from "utils";
import { LinkButton } from 'components/common'
import { Input } from "components/common";
import responsive from "services/responsive";
import SuccessIcon from "assets/SuccessIcon.svg";
import AttentionIcon from "assets/AttentionIcon.svg";
import * as Yup from "yup";

type LoginPageProps = RouteComponentProps<{}>;

const DangerIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: 2px solid #000;
    transform: rotate(45deg);
    margin-right: 15px;

    &:before {
        content: "";
        height: 15px;
        width: 2px;
        background: #000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &:after {
        content: "";
        width: 15px;
        height: 2px;
        background: #000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`;

const StyledRow = styled(Row as any)`
    @media (max-width: 767px) {
        margin: 0 0 20px 0;
    }
`;

const ErrorMessage: React.FC<{ formStatus: { type: string; message: string } }> = ({
    formStatus,
}) => {
    if (!formStatus.message) return null;

    if (formStatus.type === "error") {
        return (
            <Alert color="danger">
                <DangerIcon />
                {formStatus.message}
            </Alert>
        );
    } else if (formStatus.type === "success") {
        return (
            <Alert color="success">
                <img src={SuccessIcon} alt="Success icon" />
                {formStatus.message}
            </Alert>
        );
    } else {
        return (
            <Alert color="neutral">
                <img src={AttentionIcon} alt="attention icon" />
                {formStatus.message}
            </Alert>
        );
    }
};

const WelcomeH1 = styled(H1 as any)`
    font-size: 50px;
    font-weight: normal;

    ${responsive.Mobile} & {
        font-size: 36px;
        line-height: 43px;
    }
`;

export const MarkMenu = styled.span`
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 6px;
    width: 6px;
    min-height: 6px;
    min-width: 6px;
    border: none;
    background-color: #6e6e6e;
    margin-top: 8px;
    margin-right: 10px;
    box-sizing: border-box;
    border-radius: 50%;
`;

const Requirements = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-bottom: 10px;
    grid-column-gap: 10px;
`;

const Requirement = styled(Body2 as any)<{ completed: boolean }>`
    display: flex;
    margin-bottom: 10px;

    ${({ completed }) =>
        completed &&
        css`
            opacity: 0.2;
        `}

    @media (max-width: 920px) {
        & {
            line-height: 120%;
        }
    }
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: #c2c2c2;
    margin: 60px 0;

    @media (max-width: 415px) {
        margin: 30px 0;
    }
`;

const SetPasswordPage: React.SFC<LoginPageProps> = () => {
    const initialValues = {
        ...parseQueryString(),
        password: "",
        repeatPassword: "",
    };

    const [loading, setLoading] = useState(false);
    const [formStatus, setFormStatus] = useState({
        type: "neutral",
        message: "",
        sent: false,
    });
    const [requirementsNotFilled, setRequirementsNotFilled] = useState(false);

    const [isMinLength, setIsMinLength] = useState(false);
    const [isUseSpecialSymbol, setIsUseSpecialSymbol] = useState(false);
    const [isUpperLetter, setIsUpperLetter] = useState(false);
    const [isWithNumber, setIsWithNumber] = useState(false);
    const [isLowerLetter, setIsLowerLetter] = useState(false);

    function includesChar(string: string, chars: string) {
        let stringIncludesALetter = false;
        for (let i = 0; i < chars.length; i++) {
            const char = chars[i];
            if (string.includes(char)) {
                stringIncludesALetter = true;
            }
        }
        return stringIncludesALetter;
    }

    function validate(event: any) {
        const password = event?.target?.value;

        if (password && password.length >= 10) {
            setIsMinLength(true);
        } else {
            setIsMinLength(false);
        }

        if (includesChar(password, "!@#$%&*().")) {
            setIsUseSpecialSymbol(true);
        } else {
            setIsUseSpecialSymbol(false);
        }

        if (/\d/.test(password)) {
            setIsWithNumber(true);
        } else {
            setIsWithNumber(false);
        }

        if (/[A-Z]/.test(password)) {
            setIsUpperLetter(true);
        } else {
            setIsUpperLetter(false);
        }

        if (/[a-z]/.test(password)) {
            setIsLowerLetter(true);
        } else {
            setIsLowerLetter(false);
        }
    }

    useEffect(() => {
        if (
            !isMinLength ||
            !isUseSpecialSymbol ||
            !isUpperLetter ||
            !isWithNumber ||
            !isLowerLetter
        ) {
            setRequirementsNotFilled(true);
        } else {
            setRequirementsNotFilled(false);
        }
    }, [isMinLength, isUseSpecialSymbol, isUpperLetter, isWithNumber, isLowerLetter]);

    return (
        <>
            <WelcomeH1>Reset Password</WelcomeH1>
            <Body1>Almost done. Enter your new password, and you&apos;re good to go.</Body1>
            <br />
            <Formik
                initialValues={initialValues}
                onSubmit={async (data, actions) => {
                    const { password, repeatPassword } = data;

                    if (password === "" || repeatPassword === "") {
                        setFormStatus((s: any) => ({
                            ...s,
                            type: "error",
                            message: "Repeat password!",
                        }));
                        return;
                    }

                    if (password !== repeatPassword) {
                        setFormStatus((s: any) => ({
                            ...s,
                            type: "error",
                            message: "Passwords doesn’t match!",
                        }));
                        return;
                    }

                    if (requirementsNotFilled) {
                        setFormStatus((s: any) => ({
                            ...s,
                            type: "error",
                            message: "Password is too weak",
                        }));
                        return;
                    }

                    setLoading(true);
                    try {
                        await setPassword(data as any);
                        setFormStatus((s: any) => ({
                            ...s,
                            type: "success",
                            message: "Your password was changed!",
                            sent: true,
                        }));
                    } catch (error) {
                        if (error.response && error.response.data) {
                            actions.setErrors(error.response.data);
                            if (error.response.data.nonFieldErrors) {
                                setFormStatus((s: any) => ({
                                    ...s,
                                    type: "error",
                                    message: error.response.data.nonFieldErrors.join("\n"),
                                }));
                            }
                        } else if (error) {
                            setFormStatus((s: any) => ({
                                ...s,
                                type: "error",
                                message: "Something went wrong, please try again later",
                            }));
                        }
                    }
                    setLoading(false);
                }}
                validationSchema={Yup.object({
                    password: Yup.string().required("Required"),
                    repeatPassword: Yup.string().required("Required"),
                })}
            >
                <Form
                    onChange={() =>
                        setFormStatus((s: any) => ({ ...s, type: "neutral", message: "" }))
                    }
                >
                    <ErrorMessage formStatus={formStatus} />
                    {!formStatus.sent && (
                        <>
                            <StyledRow>
                                <Input
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    required
                                    onKeyUp={(e: any) => {
                                        validate(e);
                                    }}
                                />
                            </StyledRow>
                            <Requirements>
                                <Requirement completed={isMinLength}>
                                    <MarkMenu />10 characters minimum
                                </Requirement>
                                <Requirement completed={isUpperLetter}>
                                    <MarkMenu />
                                    One uppercase character
                                </Requirement>
                                <Requirement completed={isUseSpecialSymbol}>
                                    <MarkMenu />
                                    One special character
                                </Requirement>
                                <Requirement completed={isLowerLetter}>
                                    <MarkMenu />
                                    One lowercase character
                                </Requirement>
                                <Requirement completed={isWithNumber}>
                                    <MarkMenu />
                                    One number
                                </Requirement>
                            </Requirements>
                            <StyledRow>
                                <Input
                                    name="repeatPassword"
                                    type="password"
                                    placeholder=" Repeat Password"
                                    required
                                />
                            </StyledRow>
                        </>
                    )}
                    <div>
                        {formStatus.sent ? (
                            <LinkButton className="asButton" as ="a" href="/login/?manual=true">Return to Login</LinkButton>
                        ) : (
                            <FormButton
                                loading={loading}
                                disabled={loading || formStatus.sent}
                                type="submit"
                            >
                                Update
                            </FormButton>
                        )}
                    </div>
                    {formStatus.sent && (
                        <>
                            <Divider />
                            <Body3>
                                Prefer a password-free sign in experience?
                                <br />
                                <a href="/login">
                                    Click here
                                </a>{" "}
                                to get a magic login link.
                            </Body3>
                        </>
                    )}
                </Form>
            </Formik>
        </>
    );
};

export default SetPasswordPage;
