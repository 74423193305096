import React from "react";
import styled, { css } from "styled-components/macro";
import { OnePaperBackground } from "counterpart-theme";
import { SectionSelectorText } from "components/typography";
import { useHistory, useRouteMatch } from "react-router-dom";

import classNames from "classnames";
import { useAccountStore } from "../store";

import { effectiveCompleted, subjectivitiesCompleted } from "../utils";
import { observer } from "mobx-react-lite";
import { assert } from "utils";
import { ReactComponent as TopMenuArrow } from "../assets/topMenuArrow.svg";
import { ReactComponent as TopMenuMiddleArrow } from "../assets/topMenuMiddleArrow.svg";
import { ReactComponent as TopMenuLastArrow } from "../assets/topMenuLastArrow.svg";

interface MenuItemProps {
    label: any;
    completed: boolean;
    selected: boolean;
    enabled?: boolean;
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    number: number;
    onClick?: () => void;
    tabSelected: number;
}

const Step = styled(SectionSelectorText as any)`
    position: absolute;
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 18px;
    left: 5px;
    font-weight: 500;
    top: -1px;

    @media (max-width: 768px) {
        font-weight: 400;
    }

    @media (max-width: 480px) {
        font-size: 13px;
        left: 9px;
        top: -7px;
    }
`;

export const MarkMenu = styled.div<{ selected: boolean }>`
    position: absolute;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    left: 0;

    @media (max-width: 768px) {
        z-index: 2;
    }

    & > span {
        position: relative;
    }

    ${({ selected }) =>
        selected &&
        css`
            border-color: #22232e;
            & > span {
                @media (min-width: 769px) {
                    color: #fff;
                }
            }
        `}
`;

export const SidemenuContainer = styled.div<{ columns?: number }>`
    border-radius: 10px;
    min-width: 340px;
    display: grid;
    grid-template-columns: ${({ columns }) => columns ? `repeat(${columns}, 1fr)` : `repeat(3, 1fr)`};

    @media (max-width: 768px) {
        width: 100vw;
    }

    @media (max-width: 480px) {
        min-width: 0;
        width: 100%;
    }
`;

export const CustomButton = styled.button<{ selected: boolean }>`
    border-radius: 10px;
    background-color: ${({ selected, theme }) => (selected ? theme.colors.primary : "#ffffff")};
    text-align: left;
    border: none;
    outline: none;
    padding: 28px 20px 28px 20px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    background: #f8f8f8;

    @media (max-width: 920px) {
        padding: 28px 30px 28px 30px;
    }

    @media (min-width: 769px) {
        background-color: ${({ selected, theme }) => (selected ? theme.colors.primary : "#ffffff")};
    }

    @media (max-width: 900px) {
        padding: 28px 0 28px 15px;
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
        height: 100%;
        align-items: center;
        border-bottom: 1px solid #dbe1e7;
        border-top: 3px solid transparent;

        ${({ selected }) =>
            selected &&
            css`
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    height: calc(100% + 4px);
                    width: calc(100% + 2px);
                    left: -1px;
                    top: -3px;
                    background: #fff;
                    pointer-events: none;
                    border-top: 3px solid ${({ theme }) => theme.colors.primary};
                }
            `}
    }

    @media (max-width: 480px) {
        padding: 10px 12px;
    }

    &:focus {
        @media (min-width: 769px) {
            border: none;
        }

        box-shadow: none;
        outline: none;
    }
`;

export const MenuItemContainer = styled.div<{ selected: boolean; tabSelected: number }>`
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    @media (min-width: 769px) {
        clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    }

    &:not(:last-child) {
        @media (max-width: 768px) {
            border-right: 1px solid #dbe1e7;
        }
    }

    ${SidemenuContainer} &:first-of-type {
        @media (min-width: 769px) {
            clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0 50%, 0% 0%);
        }
    }

    ${SidemenuContainer} &:last-of-type {
        @media (min-width: 769px) {
            clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
        }
    }
`;

const ButtonContentContainer = styled.span`
    position: relative;
    padding-left: 30px;
    display: flex;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        position: initial;
        padding-left: 15px;
    }
`;

const ButtonText = styled(SectionSelectorText as any)`
    color: ${({ theme }) => theme.colors.accentColor};
    font-size: 18px;
    font-weight: 500;

    @media (min-width: 767px) {
        br {
            display: none;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        z-index: 2;
    }

    @media (max-width: 480px) {
        font-size: 13px;
        line-height: 14px;
    }

    &.selected {
        @media (min-width: 769px) {
            color: #ffffff;
        }
    }
`;

const StyledOnePaperBackground = styled(OnePaperBackground as any)`
    margin-bottom: 20px;
    background: none;
    @media (max-width: 1024px) {
        border-radius: 10px 10px 0 0;
    }

    @media (max-width: 768px) {
        margin-bottom: 0;
        position: sticky;
        top: 0;
        z-index: 100;
        width: 100%;
    }
`;

export const MenuItem: React.FC<MenuItemProps> = ({
    label,
    onClick,
    selected,
    // icon: Icon,
    enabled = true,
    number,
    tabSelected,
}) => {
    if (enabled)
        return (
            <MenuItemContainer selected={selected} tabSelected={tabSelected}>
                <CustomButton onClick={onClick} selected={selected}>
                    {/* {Icon && <Icon fill={selected ? "#242221" : "#ffffff"} />} */}
                    <ButtonContentContainer>
                        <MarkMenu selected={selected}>
                            <Step>{number}.</Step>
                        </MarkMenu>
                        <ButtonText className={classNames({ selected })}> {label}</ButtonText>
                    </ButtonContentContainer>
                </CustomButton>
            </MenuItemContainer>
        );
    else return <></>;
};

const SidebarMenu: React.FC<{}> = observer(() => {
    const match = useRouteMatch();
    const history = useHistory();

    const { quote } = useAccountStore();
    assert(quote);

    const urlSelected = history.location.pathname.substring(
        history.location.pathname.lastIndexOf("/") + 1,
    );
    return (
        <StyledOnePaperBackground>
            <SidemenuContainer columns={quote.accountType === "EXCESS" ? 2 : 3}>
                <>
                    <MenuItem
                        completed={true}
                        selected={urlSelected === "overview"}
                        tabSelected={1}
                        label={["Review ", <br key="ReviewCoverageKey" />, "Coverage"]}
                        onClick={() => history.push(`${match.url}/overview`)}
                        icon={TopMenuArrow}
                        number={1}
                    />
                    <MenuItem
                        completed={subjectivitiesCompleted(quote, true)}
                        selected={urlSelected === "subjectivities"}
                        tabSelected={2}
                        label={[
                            "Complete ",
                            <br key="CompleteSubjectivitiesKey" />,
                            "Subjectivities",
                        ]}
                        onClick={() => history.push(`${match.url}/subjectivities`)}
                        icon={TopMenuMiddleArrow}
                        number={2}
                    />
                    {quote.accountType !== "EXCESS" && (
                        <MenuItem
                            completed={effectiveCompleted(quote)}
                            selected={urlSelected === "effective-date"}
                            tabSelected={3}
                            label={["Bind ", <br key="BindCoverageKey" />, "Coverage"]}
                            onClick={() => history.push(`${match.url}/effective-date`)}
                            icon={TopMenuLastArrow}
                            number={3}
                        />
                    )}
                </>
            </SidemenuContainer>
        </StyledOnePaperBackground>
    );
});

export default React.memo(SidebarMenu);
