import React, { useCallback } from "react";
import { FullQuestion, useApplicationContext } from "services/application";
import { InputContainer, QuestionRowContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";

import styled from "styled-components/macro";
import { Body2 } from "components/typography";
import { useField } from "formik";

import { clearFieldVerificationRequired } from "../../sectionUtils";

interface BooleanProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

type Props = {
    label: string;
    type: string;
    value: string;
    className?: string;
    parentQuestion?: FullQuestion;
    checked: boolean;
    testID: string;
    onChange: (v: any) => void;
};

const RadioCheckMark = ({ className }: any) => (
    <svg className={className} height="24" width="24" viewBox="0 0 24 24">
        <circle className="radioOutline" cx="12" cy="12" r="10" fill="#e5eaef" />
        <circle className="radioDot" cx="12" cy="12" r="5" fill="white" />
    </svg>
);

const Checkmark = styled.span`
    margin-left: 20px;
    height: 20px;
    width: 20px;
    border: 2px solid #e5eaef;
    align-self: center;
    flex: 0 0 20px;

    &:after {
        content: "";
        display: none;
        margin-left: 6px;
        margin-top: 2px;
        border: solid white;
    }
    @media (max-width: 700px) {
        margin-left: 10px;
    }
`;

const RadioMark = styled(RadioCheckMark)`
    align-self: center;
    margin-left: 15px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute;
        top: 28%;
    }
`;

const Label = styled.span`
    ${Body2}
    display: block;
    margin-left: 10px;
    align-self: center;
    margin-right: 10px;
    font-weight: 500;
    line-height: 160%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute;
        left: 35px;
        top: 28%;
    }
`;

const DivContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #e5eaef;
    box-sizing: border-box;
`;

const Input = styled.input`
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &[type="checkbox"] ~ ${Checkmark} {
        &:after {
            width: 5px;
            height: 10px;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    &:checked ~ ${Checkmark} {
        background: ${(props) => props.theme.colors.accentColor};
        border-color: ${(props) => props.theme.colors.accentColor};
        &:after {
            display: block;
        }
    }

    &:checked ~ ${RadioMark} {
        & > .radioOutline {
            fill: ${(props) => props.theme.colors.primary};
        }
    }

    &:checked ~ ${DivContainer} {
        border-color: ${(props) => props.theme.colors.primary};
    }
`;

const Container = styled.label`
    position: relative;
    display: inline-flex;
    min-height: 54px;
    height: fit-content;
    cursor: pointer;
    user-select: none;

    &.on-error > ${DivContainer} {
        border: 2px solid #eb0f21 !important;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
        height: 54px;
        width: 100%;
    }
`;

const RadioButton: React.FC<Props> = ({ label, testID, value, checked, className, onChange }) => {
    const onClick = useCallback(() => {
        onChange(value);
        //eslint-disable-next-line
    }, [onChange]);
    return (
        <Container className={`label-container ${className}`}>
            <Input
                data-testid={testID}
                type="radio"
                readOnly
                value={value}
                checked={checked}
                onClick={onClick}
            />
            <RadioMark />
            <Label>{label}</Label>
            <DivContainer />
        </Container>
    );
};

const Boolean: React.SFC<BooleanProps> = ({ question, extraProps = {} }) => {
    const index = extraProps?.questionIndex || 0;
    const fieldName = `${question.id}__${index}`;
    const [, props, helpers] = useField(fieldName);
    const { sections, setSections } = useApplicationContext();

    const formikValue = props.value;

    const { questionsOnError, setQuestionsOnError } = extraProps;

    const onChange = useCallback(
        (newValue: string) => {
            clearFieldVerificationRequired(
                question.id,
                sections,
                setSections,
                setQuestionsOnError,
                questionsOnError,
            );
            helpers.setValue(formikValue === newValue ? null : newValue);
            setTimeout(() => {
                helpers.setTouched(true);
            }, 100);
        },
        //eslint-disable-next-line
        [formikValue, helpers],
    );

    return (
        <QuestionRowContainer data-name="QuestionRowContainer">
            <QuestionTitleRender question={question} />
            <InputContainer id={"q-" + fieldName}>
                {["Yes", "No"].map((v) => (
                    <RadioButton
                        key={v}
                        value={v}
                        type="radio"
                        testID={`${fieldName}__${v.toLowerCase()}`}
                        checked={formikValue === v}
                        onChange={onChange}
                        label={v}
                        parentQuestion={question}
                        className={`question-${question.id} ${
                            question.required ? "field-required" : ""
                        }${questionsOnError?.includes(question.id) ? " on-error" : ""}`}
                    />
                ))}
            </InputContainer>
        </QuestionRowContainer>
    );
};

export default React.memo(Boolean);
