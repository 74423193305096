import { ValueType } from "./types";
import { toNumberArray } from "./utils";

export enum ArithmeticExpressionOperatorsEnum {
    //Returns the absolute value of a number.
    $abs = "$abs",
    //Adds numbers to return the sum
    $add = "$add",
    //Returns the smallest integer greater than or equal to the specified number.
    $ceil = "$ceil",
    //Returns the result of dividing the first number by the second.
    $divide = "$divide",
    //Returns the largest integer less than or equal to the specified number.
    $floor = "$floor",
    //Returns the remainder of the first number divided by the second
    $mod = "$mod",
    //Multiplies numbers to return the product. Accepts any number of argument expressions.
    $multiply = "$multiply",
    //Raises a number to the specified exponent.
    $pow = "$pow",
    //Rounds a number to to a whole integer or to a specified decimal place.
    $round = "$round",
    //Calculates the square root.
    $sqrt = "$sqrt",
    //Returns the result of subtracting the second value from the first.
    $subtract = "$subtract",
    //Returns the sum of all child expressions
    $sum = "$sum",
    // Returns the max between a list of numbers
    $max = "$max",
    // Counts the number in arrays and strings
    $len = "$len",
}

function MathOperator<F extends (...a: number[]) => number>(func: F, argsNum?: number) {
    return (value: ValueType): ReturnType<F> => {
        const args = toNumberArray(Array.isArray(value) ? value : [value], argsNum ?? func.length);
        return func(...args) as ReturnType<F>;
    };
}

type ArithmeticHandlerType = (args: ValueType) => number;

export const ARITHMETIC_OPERATORS_HANDLERS: {
    [K in ArithmeticExpressionOperatorsEnum]: ArithmeticHandlerType;
} = {
    $abs: MathOperator(Math.abs),
    $ceil: MathOperator(Math.ceil),
    $floor: MathOperator(Math.floor),
    $pow: MathOperator(Math.pow),
    $round: MathOperator(Math.round),
    $sqrt: MathOperator(Math.sqrt),
    $max: MathOperator(Math.max, Infinity),
    $sum: (args: ValueType) => {
        return toNumberArray(args).reduce((acc, val) => acc + val, 0);
    },
    $add: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a + b;
    },
    $subtract: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a - b;
    },
    $multiply: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a * b;
    },
    $divide: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a / b;
    },
    $mod: (args: ValueType) => {
        const [a, b] = toNumberArray(args, 2);
        return a % b;
    },
    $len: (args: ValueType) => {
        if (Array.isArray(args)) {
            return args.filter((e) => e !== null).length;
        } else if (typeof args === "string" || typeof args === "number") {
            return 1;
        }
        return 0;
    },
};
