import { types } from "mobx-state-tree";

enum AbstractEnum {}

export const Enumerable = <T extends typeof AbstractEnum>(name: string, enumType: T) =>
    types.custom<string, T[keyof T]>({
        name,
        fromSnapshot(value) {
            return value as T[keyof T];
        },
        toSnapshot(value) {
            return value as string;
        },
        isTargetType(value): boolean {
            return Object.values(enumType).includes(value as string);
        },
        getValidationMessage(value): string {
            const possibleValues = Object.values(enumType)
                .map((s) => `"${s}"`)
                .join(", ");
            return `'${value}' doesn't match any of the possible values [${possibleValues}]`;
        },
    });

export const currencyType = types.custom<string, number>({
    name: "Currency",
    fromSnapshot(value: string) {
        return parseFloat(value);
    },
    toSnapshot(value: number) {
        if (value === null || value === undefined) return "0.00";
        return value.toFixed(2);
    },
    isTargetType(value: string | number): boolean {
        return typeof value !== "string";
    },
    getValidationMessage(value: string): string {
        if (/^-?\d+\.?\d*$/.test(value)) return ""; // OK
        return `'${value}' doesn't look like a valid money value`;
    },
});
