/* eslint-disable @typescript-eslint/camelcase */
import { createContext, useContext } from "react";
import { types, flow, Instance, applySnapshot } from "mobx-state-tree";
import { toast } from "react-toastify";

import { mobxCustomTypes, isAxiosError, formatCurrency } from "utils";
import { quotes, logger } from "services";
import rootStore from "services/store";

import QuoteComponentModel from "./QuoteComponentModel";
import { STATUS_COLORS, STATUS_SHOW_SIDEBAR } from "./constants";

import { QuoteEffectiveDate, Question as IQuestion } from "CounterpartApiTypes";
import { uploadSubjectivityFile, deleteSubjectivityFile } from "services/quotes";
import { ApiResponseType } from "utils/types";

export * from "./constants";

const _ALL_LINES = "allLines";

export const Company = types.model("Company", {
    name: types.string,
    info: types.model({
        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        state: types.maybeNull(types.string),
        zipcode: types.maybeNull(types.string),
        url: types.maybeNull(types.string),
    }),
});

const QuoteSubjectivityFile = types
    .model("QuoteSubjectivityFile", {
        id: types.identifier,
        file: types.string,
        filename: types.string,
        size: types.number,
    })
    .volatile(() => ({
        loading: false,
    }))
    .actions((self) => ({
        deleteFileForSubjectivity: flow(function* deleteFileForSubjectivity(subjectivity: any) {
            self.loading = true;
            if (!subjectivity) {
                toast.error("Cannot delete this file from the subjectivity");
                return;
            }
            try {
                // Make Delete endpoint
                const data = new FormData();
                data.append("quote_subjectivity", subjectivity.id);
                const response: ApiResponseType<typeof deleteSubjectivityFile> = yield deleteSubjectivityFile(
                    self.id,
                );
                applySnapshot(subjectivity.files, response as any);
                if (response === [] || response.length === 0) {
                    subjectivity.setAnsweredTo(false);
                }
            } catch (error) {
                console.log(error);
                let message = "Could not delete file from the subjectivity";
                if (isAxiosError(error)) {
                    if (error.response && error.response.status === 400) {
                        if (Array.isArray(error.response.data)) {
                            message = error.response.data[0];
                        }
                    }
                } else {
                    logger.error(error);
                }
                toast.error(message);
            } finally {
                self.loading = false;
            }
        }),
    }));

export const Discount = types.model("Discount", {
    name: types.string,
    value: types.number,
});

export const DiscountsApplied = types.model("DiscountsApplied", {
    allLines: types.maybeNull(types.array(Discount)),
    do: types.maybeNull(types.array(Discount)),
    epli: types.maybeNull(types.array(Discount)),
    fiduciary: types.maybeNull(types.array(Discount)),
});

const TaxesAndFees = types.model("TaxesAndFees", {
    slTax: types.maybeNull(
        types.model({
            title: types.string,
            amount: types.number,
            amountType: types.string,
        }),
    ),
    stampingFee: types.maybeNull(
        types.model({
            title: types.string,
            amount: types.number,
            amountType: types.string,
        }),
    ),
    municipalFee: types.maybeNull(
        types.model({
            title: types.string,
            amount: types.number,
            amountType: types.string,
        }),
    ),
    slServiceCharge: types.maybeNull(
        types.model({
            title: types.string,
            amount: types.number,
            amountType: types.string,
        }),
    ),
});

export const QuoteSubjectivity = types
    .model("QuoteSubjectivity", {
        id: types.identifier,
        name: types.string,
        type: types.string,
        question: types.frozen<IQuestion | null>(),
        language: types.string,
        key: types.string,
        cleared: types.boolean,
        isOptional: types.boolean,
        isAnswered: types.boolean,
        files: types.array(QuoteSubjectivityFile),
    })
    .volatile(() => ({
        loading: false,
    }))
    .actions((self) => ({
        setAnsweredTo: flow(function* setAnsweredTo(to) {
            yield (self.isAnswered = to);
        }),
        saveNewFile: flow(function* saveFile(file: File | null) {
            try {
                self.loading = true;
                let response: ApiResponseType<typeof uploadSubjectivityFile>;

                if (file) {
                    const data = new FormData();

                    data.append("filename", file.name);
                    data.append("file", file);
                    data.append("quote_subjectivity", self.id);
                    response = yield uploadSubjectivityFile(data as any, self.id, {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    });
                    self.files = response as any;
                    self.isAnswered = true;
                    applySnapshot(self, self);
                }
            } catch (error) {
                let message = "Was not possible to upload your file";
                if (isAxiosError(error)) {
                    if (error.response && error.response.status === 400) {
                        if (Array.isArray(error.response.data)) {
                            message = error.response.data[0];
                        }
                    }
                } else {
                    logger.error(error);
                }
                toast.error(message);
            } finally {
                self.loading = false;
            }
        }),
    }));

export const ExcessOptionModel = types.model("ExcessOption", {
    externalCarrierName: types.string,
    orderInTower: types.maybeNull(types.integer),
    policyStartDate: types.maybeNull(types.string),
    policyEndDate: types.maybeNull(types.string),
    limit: types.maybeNull(types.integer),
    premium: types.maybeNull(types.string),
    retention: types.maybeNull(types.integer),
    attachmentPoint: types.maybeNull(types.integer),
    towerCoverage: types.maybeNull(types.integer),
    hasExternalApplicationPdf: types.boolean,
    hasPolicyPdf: types.boolean,
    hasBinderPdf: types.boolean,
});

export const QuoteModel: any = types
    .model("Quote", {
        id: types.identifier,
        statusText: types.string,
        descriptionText: types.string,
        components: types.array(types.late(() => QuoteComponentModel)),
        company: types.maybeNull(Company),
        totalComponentPremium: types.maybeNull(mobxCustomTypes.currencyType),
        status: types.string,
        fees: types.maybeNull(mobxCustomTypes.currencyType),
        taxes: types.maybeNull(mobxCustomTypes.currencyType),
        commission: types.maybeNull(types.number),
        sharedLimits: types.boolean,
        priorActsCoverage: types.boolean,
        signedApplicationPdf: types.maybeNull(types.string),
        signedPdfDocument: types.maybeNull(types.string),
        finalized: types.boolean,
        policyPeriod: types.maybeNull(types.string),
        policyNumber: types.maybeNull(types.string),
        insuredPaymentStatus: types.maybeNull(types.string),
        sharedLimitDiscounts: types.map(types.number),
        sharedLimitAmount: types.maybeNull(mobxCustomTypes.currencyType),
        expirationDate: types.maybeNull(types.string),
        effectiveDate: types.maybeNull(types.string),
        insuranceLinesRequiringPnp: types.array(types.string),
        quoteSubjectivities: types.array(QuoteSubjectivity),
        finalPremium: types.maybeNull(mobxCustomTypes.currencyType),
        supplementalAppSubmitted: types.boolean,
        discountsApplied: types.maybeNull(DiscountsApplied),
        shouldUseManualDiscounts: types.boolean,
        feedback: types.maybeNull(types.array(types.frozen({}))),
        carrierOptions: types.maybeNull(types.array(types.string)),
        brokerModifiableSubcoverages: types.array(types.string),
        premiumOnIssueQuote: types.maybeNull(types.number),
        supplementalAppSubmittedDate: types.maybeNull(types.string),
        quotedDate: types.maybeNull(types.string),
        taxesAndFees: types.maybeNull(TaxesAndFees),
        publicSecurityToken: types.maybeNull(types.string),
        accountType: types.maybeNull(types.string),
        excessOptions: types.maybeNull(types.array(ExcessOptionModel)),
        hasPolicy: types.boolean,
        requiringSlaNumber: types.boolean,
        requiringLicenseNumber: types.boolean,
        quoteHasLicenseeRequirement: types.maybeNull(types.boolean),
        brokerSurplusLicense: types.maybeNull(
            types.model({
                licensee: types.maybeNull(types.string),
                slaNumber: types.maybeNull(types.string),
                licenseNumber: types.maybeNull(types.string),
                expirationDate: types.maybeNull(types.string),
            }),
        ),
        isInBinderStatus: types.boolean,
        isAdmitted: types.boolean,
    })
    .views((self) => ({
        get enabledComponents() {
            return self.components.filter((item) => !item.coverageDenied);
        },
        get statusColor() {
            return (STATUS_COLORS as any)[self.status] || STATUS_COLORS.PENDING;
        },
        get hasEndorsementData() {
            if (self.components.reduce((sum, item) => sum + item.endorsements.length, 0)) {
                return true;
            }
            return false;
        },
        get hasBundledPolicyDiscount() {
            if (self.components.filter((item) => !item.coverageDenied).length > 1) {
                return true;
            }
            return false;
        },
    }))
    .views((self) => ({
        get canShareLimits() {
            return (
                self.enabledComponents.filter((item) => !item.insuranceLine.canShareLimit)
                    .length === 0
            );
        },
        get discountLines() {
            const discounts: any = [];
            if (self && self.discountsApplied !== null) {
                const adjustedPremiums: any = [];
                self.components.forEach((item) => {
                    adjustedPremiums[item.insuranceLine.value.toLowerCase()] = parseFloat(
                        String(item.calculateVisualPremium(self))
                            .replace("$", "")
                            .replace(",", ""),
                    );
                });

                // Need to loop through and find discounts to the individual insurance lines
                Object.entries(self.discountsApplied).forEach(([key, value_discount]) => {
                    const appliedDiscounts: any = value_discount;
                    if (appliedDiscounts) {
                        // First round through for each individual insurance line
                        appliedDiscounts.forEach((item: DiscountModel) => {
                            if (item.value !== 1) {
                                const percentage = 1 - item.value;
                                const verbosePercentage = String(+(percentage * 100).toFixed(2));
                                let amount = 0;
                                let appliedTo = null;
                                const name = item.name
                                    .toLowerCase()
                                    .replace("_", " ")
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                        letter.toUpperCase(),
                                    );

                                const shortname =
                                    key.toLowerCase() === "fiduciary" ? "fi" : key.toLowerCase();
                                const component = self.components.filter(
                                    (item) => item.insuranceLine.value.toLowerCase() === shortname,
                                );
                                if (component && component.length === 1) {
                                    appliedTo = shortname.toUpperCase();
                                    amount = adjustedPremiums[shortname] * percentage;
                                    adjustedPremiums[shortname] =
                                        adjustedPremiums[shortname] - amount;
                                    discounts.push({
                                        key: item.name,
                                        amount: formatCurrency(amount),
                                        name: name,
                                        percentage: percentage,
                                        verbosePercentage: verbosePercentage,
                                        appliedTo: appliedTo,
                                    });
                                }
                            }
                        });
                    }
                });

                // Next calculate the total adjusted premium for the allLines discounts
                // Well be calculating other totals here
                let total_premium = 0;
                for (const key in adjustedPremiums) {
                    total_premium += adjustedPremiums[key];
                }

                Object.entries(self.discountsApplied).forEach(([key, value_discount]) => {
                    const appliedDiscounts: any = value_discount;
                    if (appliedDiscounts) {
                        // First round through for each individual insurance line
                        appliedDiscounts.forEach((item: DiscountModel) => {
                            if (item.value !== 1) {
                                const percentage = 1 - item.value;
                                const verbosePercentage = String(+(percentage * 100).toFixed(2));
                                let amount = 0;
                                const appliedTo = "";
                                const name = item.name
                                    .toLowerCase()
                                    .replace("_", " ")
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                        letter.toUpperCase(),
                                    );

                                if (key === _ALL_LINES) {
                                    // Calculate from total premium
                                    amount = total_premium * percentage;
                                    total_premium = total_premium - amount;

                                    discounts.push({
                                        key: item.name,
                                        amount: formatCurrency(amount),
                                        name: name,
                                        percentage: percentage,
                                        verbosePercentage: verbosePercentage,
                                        appliedTo: appliedTo,
                                    });
                                }
                            }
                        });
                    }
                });
            }

            return discounts;
        },
        get hasEPLICoverage() {
            return !!self.enabledComponents.filter((c) => c.insuranceLine.value === "epli").length;
        },
        get showSidebar() {
            return (
                STATUS_SHOW_SIDEBAR.includes(self.status) ||
                (self.status === "PENDING" && rootStore.userInfo.isVPN)
            );
        },
        get bundledPolicyDiscountPercentage() {
            const activeInsuranceLength = self.enabledComponents.length;
            if (!self.sharedLimits) {
                if (activeInsuranceLength === 2) {
                    return 0.03;
                } else if (activeInsuranceLength === 3) {
                    return 0.05;
                }
            }
            return 1;
        },
    }))
    .views((self) => ({
        get commissionPercentage() {
            return self.commission != null ? 100 * self.commission + "%" : null;
        },
        get maximumLimit() {
            const maximumLimits = self.components?.map((d: any) => d.maximumLimit) || [];
            return Math.min(...maximumLimits);
        },
    }))
    .actions((self) => ({
        changePriorActsCoverage: flow(function* changePriorActsCoverage(newValue: boolean) {
            const oldValue = self.priorActsCoverage;
            try {
                self.priorActsCoverage = newValue;
                yield quotes.patchQuote(self.id, { priorActsCoverage: newValue });
            } catch (error) {
                //eslint-disable-next-line require-atomic-updates
                self.priorActsCoverage = oldValue;
            }
        }),
        updateForReact: flow(function* updateForReact() {
            const data = yield quotes.getQuote(self.id);
            self = data;
            return data;
        }),
        saveEffectiveDate: flow(function* saveEffectiveDate(
            newValue: QuoteEffectiveDate,
            apiOptions,
        ) {
            const { effectiveDate } = newValue;
            yield quotes.setEffectiveDate(newValue, self.id, apiOptions);
            self.effectiveDate = effectiveDate;
        }),
        setSubjectivities(subjectivites: any) {
            self.quoteSubjectivities = subjectivites;
        },
        setQuoteFeedback: flow(function* setQuoteFeedback(data: any) {
            try {
                yield quotes.saveBrokerFeedback(data, self.id);
                const snapshotUpdateFound: any[] = [];

                for (const key in data) {
                    const reasonForUncompetative: any = {};
                    reasonForUncompetative[key] = data[key];
                    snapshotUpdateFound.push(reasonForUncompetative);
                }
                const feedback = snapshotUpdateFound;
                applySnapshot(self as any, { ...self, feedback });
                toast.success("Feedback saved! Thank you for taking the time to submit the data");
                return true;
            } catch (error) {
                toast.error("Could not save feedback to the account");
            }
            return false;
        }),
        setBrokerSurplusLicense: flow(function* setBrokerSurplusLicense(data: any) {
            try {
                yield quotes.saveBrokerSurplusLicense(data, self.id);
                const brokerSurplusLicense = data;
                applySnapshot(self as any, { ...self, brokerSurplusLicense });
                return true;
            } catch (error) {
                toast.error("Could not save license to the account");
            }
            return false;
        }),
    }))
    .views((self) => ({
        // DEPRECATED
        // Used for Manual Discounts pre V8
        get totalPremiumCalc() {
            return self.enabledComponents.reduce((sum, item) => sum + Math.round(item.premium), 0);
        },
        // DEPRECATED
        // Used for Manual Discounts pre V8
        get sharedLimitsPercentage() {
            if (self.sharedLimits) {
                const limitDiscountList = self.sharedLimitDiscounts.toJSON();
                const activeInsuranceLength = self.enabledComponents.length;
                if (limitDiscountList[activeInsuranceLength] !== undefined) {
                    return 1 - limitDiscountList[activeInsuranceLength];
                }
            }
            return 0;
        },
    }))
    .views((self) => ({
        // DEPRECATED
        // Used for Manual Discounts pre V8
        get sharedLimitsPercentageString() {
            if (self.sharedLimits) {
                return String(+(100 * self.sharedLimitsPercentage).toFixed(2)) + "%";
            }
            return "0";
        },
        // DEPRECATED
        // Used for Manual Discounts pre V8
        get bundledPolicyDiscountString() {
            if (!self.sharedLimits) {
                return String(+(100 * self.bundledPolicyDiscountPercentage).toFixed(2)) + "%";
            }
            return "0";
        },
        // DEPRECATED
        // Used for Manual Discounts pre V8
        get sharedLimitsDiscount() {
            if (self.sharedLimits) {
                return Math.ceil(self.totalPremiumCalc * self.sharedLimitsPercentage);
            }
            return 0;
        },
        // DEPRECATED
        // Used for Manual Discounts pre V8
        get bundledPolicyDiscount() {
            if (!self.sharedLimits) {
                return Math.ceil(self.totalPremiumCalc * self.bundledPolicyDiscountPercentage);
            }
            return 0;
        },
    }))
    .views((self) => ({
        // DEPRECATED
        // Used for Manual Discounts pre V8
        get subTotalUsingManualDiscounts() {
            let discount = 0;
            if (self.sharedLimits) {
                discount = self.sharedLimitsDiscount;
            } else {
                discount = self.bundledPolicyDiscount;
            }
            return Math.ceil(self.totalPremiumCalc - discount);
        },
    }))
    .views((self) => ({
        // ALL DEPRECATED
        // Used for Manual Discounts pre V8
        get totalUsingManualDiscounts() {
            return self.subTotalUsingManualDiscounts;
        },
    }));

export const QuoteMinimalDataModel = types
    .model("QuoteMinimalDataModel", {
        id: types.identifier,
        components: types.array(types.late(() => QuoteComponentModel)),
        status: types.string,
        signedApplicationPdf: types.maybeNull(types.string),
        finalized: types.boolean,
    })
    .views((self) => ({
        get enabledComponents() {
            return self.components.filter((item) => !item.coverageDenied);
        },
    }))
    .views((self) => ({
        get hasEPLICoverage() {
            return !!self.enabledComponents.filter((c) => c.insuranceLine.value === "epli").length;
        },
    }));

export const Invoice = types.model("Invoice", {
    id: types.identifier,
    dueDate: types.maybeNull(types.string),
    datePaid: types.maybeNull(types.string),
    amount: types.maybeNull(types.string),
    status: types.string,
    invoicePdf: types.maybeNull(types.string),
});

export const PolicyModel = types
    .model("Policy", {
        id: types.identifier,
        insuranceReferenceId: types.string,
        premium: types.maybeNull(mobxCustomTypes.currencyType),
        status: types.string,
        startDate: types.maybeNull(types.string),
        expirationDate: types.maybeNull(types.string),
        invoices: types.array(Invoice),
    })
    .views((self) => ({
        get isAllInvoicesPaid() {
            return self.invoices.every((invoice) => invoice.status === "PAID");
        },
    }));

const AccountPageStore = types
    .model("AccountPageStore", {
        quote: types.maybe(types.late(() => QuoteModel)),
        policy: types.maybe(types.late(() => types.maybeNull(PolicyModel))),
        quoteMinimalData: types.maybe(types.late(() => types.maybeNull(QuoteMinimalDataModel))),
        loading: false,
        error: false,
        runningEngine: false,
        runningEngineError: "",
        isCalculatingShared: false,
        appSecurityToken: "",
        publicSecurityToken: "",
    })
    .actions((self) => ({
        loadQuote: flow(function* loadQuote(
            quoteID: string,
            _appSecurityToken: string = "",
            _publicSecurityToken: string = "",
        ) {
            try {
                self.loading = true;
                // Examples of using optional Auth
                let appSecurityToken = _appSecurityToken;
                let publicSecurityToken = _publicSecurityToken;

                if (!appSecurityToken || !publicSecurityToken || appSecurityToken === "" || publicSecurityToken === "" || appSecurityToken === undefined || publicSecurityToken === undefined) {
                    let data = yield quotes.getAppSecurityTokenAndQuotePublicSecurityToken(quoteID);
                    appSecurityToken = data['appSecurityToken'];
                    publicSecurityToken = data['publicSecurityToken'];
                }

                const quote = yield quotes.getQuote(quoteID, {
                    params: { appSecurityToken, publicSecurityToken },
                });


                let policy = null;
                if (quote.finalized) {
                    policy = yield quotes.getPolicyFromQuote(quote.id, {
                        params: { appSecurityToken, publicSecurityToken },
                    });
                }
                applySnapshot(self as any, {
                    quote,
                    policy,
                    appSecurityToken,
                    publicSecurityToken,
                });

                // applySnapshot(self.quote, { publicSecurityToken, appSecurityToken });
            } catch (error) {
                self.error = true;
                self.loading = false;
                // window.location.href = "/";
                throw error;
            }
            return self;
        }),
        loadMinimalQuoteData: flow(function* loadMinimalQuoteData(quoteID: string) {
            try {
                self.loading = true;
                const quoteMinimalData = yield quotes.loadMinimalQuoteData(quoteID);
                applySnapshot(self as any, { quoteMinimalData });
                self.loading = false;
            } catch (error) {
                self.error = true;
                self.loading = false;
                // window.location.href = "/";
                throw error;
            }
            return self;
        }),
        changeSharedLimits: flow(function* changeSharedLimits(
            _limit = 0,
            sharedLimit = true,
            apiOptions = undefined,
        ) {
            if (!self.quote) return;
            try {
                self.runningEngine = true;
                self.isCalculatingShared = true;
                const limit = _limit ? _limit : self.quote?.sharedLimitAmount ?? 1000000;
                const data = yield quotes.updateShared(
                    { limit, sharedLimit },
                    self.quote?.id,
                    apiOptions,
                );
                applySnapshot(self.quote, data);
            } catch (error) {
                logger.error(error);
                toast.error("Was not possible to change this setting");
            } finally {
                self.runningEngine = false;
                self.isCalculatingShared = false;
            }
        }),
        bindSelectedCoverage: flow(function* bindSelectedCoverage() {
            self.loading = true;
            try {
                if (self.quote) {
                    const data = yield quotes.bindCoverage({}, self.quote.id);
                    self.quote.finalized = data.finalized;
                }
                self.loading = false;
                window.location.href = "/account/" + self.quote?.id + "/overview?bound=true";
            } catch (error) {
                self.loading = false;
            }
        }),
        requestToBindFromBroker: flow(function* requestToBindFromBroker(
            publicSecurityToken,
            appSecurityToken,
        ) {
            self.loading = true;
            try {
                if (self.quote) {
                    yield quotes.sendRequestToBrokerToBindAccount(
                        {
                            publicSecurityToken,
                            appSecurityToken,
                        },
                        self.quote.id,
                        { params: { appSecurityToken, publicSecurityToken } },
                    );
                }
                self.loading = false;
                return true;
            } catch (error) {
                self.loading = false;
                return false;
            }
        }),
    }));

const store = AccountPageStore.create({});
(window as any).store = store;

export type StoreType = typeof store;
export type QuoteStore = Instance<typeof QuoteModel>;
export type QuoteMinimalDataStore = Instance<typeof QuoteMinimalDataModel>;
export type PolicyStore = Instance<typeof PolicyModel>;
export type QuoteComponentStore = Instance<typeof QuoteComponentModel>;
export type QuoteSubjectivityModel = Instance<typeof QuoteSubjectivity>;
export type DiscountAppliedModel = Instance<typeof DiscountsApplied>;
export type DiscountModel = Instance<typeof Discount>;
export type ExcessOptionStore = Instance<typeof ExcessOptionModel>;

export const AccountStoreContext = createContext(store);

export function useAccountStore(): StoreType {
    return useContext(AccountStoreContext);
}

export default store;
