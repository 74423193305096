import React from "react";
import styled from "styled-components/macro";

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 4px;
    background-color: ${(props) => props.theme.colors.third};
    border-radius: 1px;
`;

const BarOfProgress = styled.span<{ width: number; completeColor?: string }>`
    width: ${({ width }) => width}%;
    max-width: 100%;
    display: block;
    height: 4px;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 1px;
    transition: width 500ms ease-in-out;

    &.completed {
        background-color: ${({ completeColor }) => (completeColor ? completeColor : "#111da1")};
    }

    &.section-required.on-error {
        background: #eb0f21;
    }
`;

const ProgressBar: React.SFC<{
    percentage?: number;
    className?: string;
    completeColor?: string;
}> = ({ percentage = 0, className, completeColor }) => {
    return (
        <ProgressBarContainer>
            <BarOfProgress
                className={`${className} ${percentage === 100 ? "completed" : ""}`}
                width={percentage}
                completeColor={completeColor}
            />
        </ProgressBarContainer>
    );
};

export default React.memo(ProgressBar);
