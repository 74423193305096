import React from "react";
import rootStore from "services/store";
import styled from "styled-components/macro";
import { observer } from "mobx-react-lite";

const RedContainer = styled.div`
    background: #ff0000;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    user-select: none;
    z-index: 1000000;
`;

const PurpleContainer = styled(RedContainer as any)`
    background-color: #800080;
`;

function BrokerAdminOverrideIndicator() {
    if (rootStore.userInfo.isVPN && !rootStore.userInfo.brokerAdminOverride) {
        return <PurpleContainer>Application Admin Mode</PurpleContainer>;
    }

    if (!rootStore.userInfo || !rootStore.userInfo.brokerAdminOverride) return null;

    return (
        <RedContainer>
            {rootStore.userInfo.adminUser} Currently logged in as{" "}
            <strong>{rootStore.userInfo.email}</strong>
        </RedContainer>
    );
}

export default observer(BrokerAdminOverrideIndicator);
