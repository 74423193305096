import {
    Answer,
    Question,
    ApplicationSection,
    QuestionTypeEnum,
    UnderwritingGuidelines,
    ApplicationInitialize,
} from "CounterpartApiTypes";

import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import { groupBy, isString } from "lodash";
import { evaluateExpression, ContextType } from "utils/dynamicOperators";
import { NonUndefined } from "utils/types";

export type AnswerToQuestionMap = { [key: string]: Answer[] };
export type GuidelineToQuestionMap = { [key: string]: UnderwritingGuidelines[] };

export interface FullQuestion extends Question {
    id: string;
    answers?: Answer[];
    indexes?: number[];
    guidelines?: UnderwritingGuidelines[];
    subQuestions: FullQuestion[];
    validate: (value: any) => string | undefined;
    ratingEngineKey: string;
}

export interface FullApplicationSection extends ApplicationSection {
    questions: FullQuestion[];
    ratingEngineKey: string;
    onError?: boolean;
}

export type AnswerFormat = { [key: string]: string | null | { [key: string]: boolean } };

export const CATEGORY_QUESTION = new Set(["category", "horizontal_category", "table_category"]);
export const QUESTION_WITH_OPTIONS = new Set(["select", "check_box", "radio"]);

export const ANSWER_KEY: { [P in QuestionTypeEnum]: "text" | "optionSelected" | "fileSent" } = {
    select: "optionSelected",
    checkbox: "optionSelected",
    radio: "optionSelected",
    category: "text",
    date: "text",
    year: "text",
    email: "text",
    horizontal_category: "text", // eslint-disable-line @typescript-eslint/camelcase
    number: "text",
    table_category: "text", // eslint-disable-line @typescript-eslint/camelcase
    text: "text",
    textarea: "text",
    address: "text",
    file: "fileSent",
    currency: "text",
    percentage: "text",
    boolean: "text",
};

export function isQuestionAnswered(question: FullApplicationSection, values: AnswerFormat) {
    let answer = values[`${question?.id}__0`];
    if (answer === null && answer !== 0) {
        return true;
    }

    if ("" + answer === "0") {
        answer = "" + answer;
    }

    return (
        !answer ||
        (typeof answer === "object" &&
            answer !== null &&
            !!Object.values(answer).filter(Boolean).length) ||
        answer === null
    );
}

/**
 * Returns a flat array with only question that are supposed to have answers.
 * basically just flats the questions and remove category questions
 * @param questions
 */
export function flatQuestions(questions: FullQuestion[], excludeCategories = true) {
    return questions.reduce<FullQuestion[]>((result: any[], question: FullQuestion) => {
        if (excludeCategories) {
            if (!CATEGORY_QUESTION.has(question.type)) {
                result.push(question);
            }
        } else {
            result.push(question);
        }

        if (Array.isArray(question.subQuestions)) {
            result.push(...flatQuestions(question.subQuestions));
        }
        return result;
    }, []);
}

export function flatQuestionsAll(questions: FullQuestion[]) {
    return questions.reduce<FullQuestion[]>((result: FullQuestion[], question: FullQuestion) => {
        result.push(question);
        if (Array.isArray(question.subQuestions)) {
            result.push(...flatQuestions(question.subQuestions));
        }
        return result;
    }, []);
}

/**
 * Returns a flat array with only question that are supposed to have answers.
 * basically just flats the questions and remove category questions
 * @param questions
 */
export function flatQuestionsWithFilter(
    questions: FullQuestion[],
    values: AnswerFormat,
    filter: (q: FullQuestion, v: AnswerFormat) => boolean,
) {
    return questions.reduce<FullQuestion[]>((result, question) => {
        const isVisible = filter(question, values) && question.displayOnApplication;
        if (!CATEGORY_QUESTION.has(question.type) && isVisible) {
            result.push(question);
        }
        if (Array.isArray(question.subQuestions) && isVisible) {
            result.push(...flatQuestionsWithFilter(question.subQuestions, values, filter));
        }
        return result;
    }, []);
}

/**
 * Adds answers together with questions
 * @param questions
 * @param answersMap
 */
export function addAnswerToQuestions(questions: FullQuestion[], answersMap: AnswerToQuestionMap) {
    for (const question of questions) {
        const answer = answersMap[question.id];
        if (answer) {
            question.answers = answer;
            question.indexes = Array.from(new Set(answer.map((a) => a.questionSetIndex || 0)));
        }
        if (question.subQuestions && question.subQuestions.length) {
            addAnswerToQuestions(question.subQuestions as FullQuestion[], answersMap);
        }
    }
}

export function addGuidelinesToQuestion(
    questions: FullQuestion[],
    guidelinesMap: GuidelineToQuestionMap,
) {
    for (const question of questions) {
        const guideline = guidelinesMap[question.id];
        if (guideline) {
            question.guidelines = guideline;
        }
        if (question.subQuestions && question.subQuestions.length) {
            addGuidelinesToQuestion(question.subQuestions as FullQuestion[], guidelinesMap);
        }
    }
}
/**
 * Question Type Cache
 * -----------------------------------------------------------------------------
 */

type QuestionTypeCacheType = Pick<Question, "id" | "type" | "options">;
const QUESTION_TYPE_CACHE: { [key: string]: QuestionTypeCacheType } = {};
const QUESTION_OPTIONS_CACHE: { [key: string]: string } = {};
let BROKERAGE_NAME = "";
let COMPANY_NAME = "";
let COMPANY_ADDRESS = "";
let COMPANY_STATE = "";
let COMPANY_ZIPCODE = "";
let APPLICATION_FILES: any[] = [];
let IS_RENEWAL = false;
let LINES: string[];

export function getApplicationFiles() {
    const uploadedFiles = APPLICATION_FILES;
    return { uploadedFiles };
}

export function getIsRenewal() {
    const isRenewal = IS_RENEWAL;
    return isRenewal;
}

export function saveApplicationData(data: NonUndefined<ApplicationInitialize>) {
    BROKERAGE_NAME = data.brokerageName;
    COMPANY_NAME = data.companyName;
    COMPANY_STATE = data.state;
    COMPANY_ZIPCODE = data.zipcode;
    COMPANY_ADDRESS = data.address;
    APPLICATION_FILES = data.uploadedFiles;
    IS_RENEWAL = data.isRenewal;
    LINES = data.lines;
}

export function getBrokerageCompanyName() {
    return {
        // eslint-disable-next-line @typescript-eslint/camelcase
        brokerage_name: BROKERAGE_NAME,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_name: COMPANY_NAME,
    };
}

export function getExtraContextVars() {
    return {
        // eslint-disable-next-line @typescript-eslint/camelcase
        start__address: COMPANY_ADDRESS,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start__state: COMPANY_STATE,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start__zipcode: COMPANY_ZIPCODE,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_renewal: IS_RENEWAL,
        lines: LINES,
    };
}

export function saveQuestionsCache(questions?: FullQuestion[]) {
    const flat = flatQuestions(questions || []);
    flat.forEach((question) => {
        QUESTION_TYPE_CACHE[question.id as string] = pick(question, "id", "type", "options");
        question.options?.forEach((option) => {
            QUESTION_OPTIONS_CACHE[option.id as string] = option.text;
        });
    });
}

export function getQuestionType(questionID: string): QuestionTypeCacheType | undefined {
    return QUESTION_TYPE_CACHE[questionID];
}

/**
 * Question Key Cache
 * -----------------------------------------------------------------------------
 */

const QUESTION_KEY_CACHE: { [key: string]: string } = {};

export function saveQuestionsKeyCache(questions?: FullQuestion[]) {
    const flat = flatQuestions(questions || []);
    flat.forEach((question) => {
        QUESTION_KEY_CACHE[question.id as string] = question.ratingEngineKey as string;
        QUESTION_KEY_CACHE[question.ratingEngineKey as string] = question.id as string;
    });
}

export function getQuestionID(ratingEngineKey: string): string | undefined {
    return QUESTION_KEY_CACHE[ratingEngineKey];
}

/**
 * Save answers cache
 * -----------------------------------------------------------------------------
 */
let SAVED_ANSWERS: AnswerFormat = {};
export const setSavedAnswers = (newAnswers: AnswerFormat) => {
    SAVED_ANSWERS = Object.assign({}, SAVED_ANSWERS, newAnswers);
};

export const savedAnswersHasDiff = (newAnswers: AnswerFormat) => {
    return !isEqual(SAVED_ANSWERS, newAnswers);
};

export const getAnswersDiff = (newAnswers: AnswerFormat) => {
    const result: AnswerFormat = {};
    for (const [key, newValue] of Object.entries(newAnswers)) {
        const oldValue = SAVED_ANSWERS[key];
        if (!isEqual(oldValue, newValue)) {
            result[key] = newValue;
        }
    }
    return result;
};

function safeJSONParse(value: any): any {
    if (value !== null && +value === 0) {
        return value;
    }

    try {
        return JSON.parse(value);
    } catch (error) {
        return value;
    }
}

/**
 * Return the initial values for application questions form
 * @param sections
 */
export function getInitialValues(sections: FullApplicationSection[]) {
    const initialValues = sections.reduce<AnswerFormat>((result, sec) => {
        flatQuestions(sec.questions)
            .filter(
                (q) => ["category", "horizontal_category", "table_category"].indexOf(q.type) === -1,
            )
            .forEach((question) => {
                const indexes = question.indexes || [0];
                indexes.forEach((index) => {
                    const key = `${question.id}__${index}`;
                    const values = (question.answers || [])
                        .filter((a) => a.questionSetIndex === index)
                        .map((a) => safeJSONParse(a.text))
                        .filter(Boolean);
                    if (question.type === "checkbox") {
                        result[key] =
                            question.options?.reduce<{ [key: string]: boolean }>((obj, item) => {
                                obj[item.id as string] = values.includes(item.id as string);
                                return obj;
                            }, {}) || {};
                    } else {
                        result[key] = values[0] || null;
                    }
                });
            });
        return result;
    }, {});
    if (process.env.NODE_ENV !== "production") {
        (window as any).initialAnswers = initialValues;
    }
    // setSavedAnswers(initialValues);
    return initialValues;
}

/**
 * Underwriting guidelines
 * -----------------------------------------------------------------------------
 */
type AnswersContextValue = boolean | string | string[] | null | AnswersContextValue[];
type AnswersContextType = Record<string, AnswersContextValue>;

function setAnswerContextKey(context: AnswersContextType, key: string, value: AnswersContextValue) {
    if (context[key] !== undefined) {
        if (Array.isArray(context[key])) {
            (context[key] as any[]).push(value);
        } else {
            const previousValue = context[key];
            context[key] = [previousValue, value];
        }
    } else {
        context[key] = value;
    }
}

export function getAnswersContext(answers: AnswerFormat) {
    const returnValue: AnswersContextType = {
        ...getExtraContextVars(),
    };
    const entries = Object.entries(answers);
    for (const [key, value] of entries) {
        const [questionID] = key.split("__");
        const questionObj = getQuestionType(questionID);
        const ratingEngineKey = getQuestionID(questionID) || "";
        const answerValue =
            value === null || isString(value)
                ? value
                : Object.entries(value)
                      .filter(([, value]) => value)
                      .map(([id]) => id);

        if (questionObj?.options && questionObj.options.length) {
            if (Array.isArray(answerValue)) {
                setAnswerContextKey(
                    returnValue,
                    ratingEngineKey,
                    answerValue.map((o) => QUESTION_OPTIONS_CACHE[o] ?? o),
                );
            } else if (answerValue === null) {
                setAnswerContextKey(returnValue, ratingEngineKey, null);
            } else {
                setAnswerContextKey(
                    returnValue,
                    ratingEngineKey,
                    QUESTION_OPTIONS_CACHE[answerValue] ?? answerValue,
                );
            }
        } else {
            setAnswerContextKey(returnValue, ratingEngineKey, answerValue);
        }
    }
    return returnValue;
}

export function checkJSONExpression(question: FullQuestion, context: ContextType) {
    if (!question.guidelines?.length) return null;
    for (const guideline of question.guidelines) {
        if (!guideline.jsonExpression || !guideline.errorText) continue;
        const result = evaluateExpression(guideline.jsonExpression, context);
        if (result) return guideline.errorText;
    }
    return null;
}

function answerToMap(answers: any) {
    const obj = Object.keys(answers).map((key: string) => {
        const pattern = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/;
        const questionIdx = key.split("__");
        if (pattern.test(answers[key])) {
            return {
                fileSent: null,
                optionSelected: answers[key],
                question: questionIdx[0],
                questionSetIndex: Number(questionIdx[1]),
                text: null,
            };
        } else {
            return {
                fileSent: null,
                optionSelected: null,
                question: questionIdx[0],
                questionSetIndex: Number(questionIdx[1]),
                text: answers[key],
            };
        }
    });
    return Object.keys(obj).map((key: any) => obj[key]);
}

export function setSectionsAndAnswers(sections: FullApplicationSection[], answers: AnswerFormat) {
    const answersForQuestion = groupBy(answerToMap(answers), "question");
    for (const section of sections) {
        saveQuestionsKeyCache(section.questions as FullQuestion[]);
        saveQuestionsCache(section.questions as FullQuestion[]);
        addAnswerToQuestions(section.questions as FullQuestion[], answersForQuestion as any);
    }

    return sections as FullApplicationSection[];
}
