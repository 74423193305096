import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components/macro";

import responsive from "services/responsive";

import { MuseoSansBaseCSS } from "components/typography";
import { Surface } from "components/common";
import rootStore from "services/store";

import Logo from "assets/counterpart_logo.svg";

export const SideMenuContainer = styled(Surface as any)<{ visible: boolean }>`
    position: fixed;
    top: 0px;
    bottom: 100;
    display: flex;
    width: 230px;
    flex: 0;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    padding: 10px 0;
    z-index: 100999;
    padding-top: ${() =>
        !rootStore.userInfo || !rootStore.userInfo.brokerAdminOverride ? "50px" : "10px"};

    @media (max-width: 767px) {
        padding-top: 50px;
        position: fixed;
        transition: all 0.2s linear;
        transform: ${(props) => (props.visible ? "translateX(0)" : "translateX(-230px)")};
        opacity: ${(props) => (props.visible ? "1" : "0")};
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        min-width: 230px;
        min-height: 150vh;
    }
`;

export const CloseMenuContainer = styled.button`
    background: none;
    border: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 767px) {
        display: none;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 10px;
`;

export const NavBrand = styled(Link as any)`
    display: flex;
    align-self: center;
`;

export const BrandImg = styled.div`
    background: url(${Logo}) no-repeat;
    background-size: contain;
    background-position: center;
    width: 167px;
    height: 40px;
`;

export const NavContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 60px;
    width: 100%;
    padding: 0;
`;

export const NavItemImg = styled.img`
    width: 20px;
    margin-right: 15px;
`;

export const NavItemText = styled.span`
    ${MuseoSansBaseCSS}
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    text-align: left;
    color: ${(props) => props.theme.colors.textColor};
    white-space: nowrap;
    text-overflow: ellipsis;

    .selected & {
        color: ${(props) => props.theme.colors.accentColor};
        font-weight: 500;
    }
`;

export const NavItemBadge = styled(Link as any)`
    ${MuseoSansBaseCSS}
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    min-width: 24px;
    width: auto;
    background: ${(props) => props.theme.colors.third};
`;

export const CustomNavItemBadge = styled.span`
    ${MuseoSansBaseCSS}
    color: ${(props) => props.theme.colors.primaryFrontColor};
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    min-width: 32px;
    width: auto;
    background: ${(props) => props.theme.colors.secondary};
    align-self: center;
    border-radius: 10px;

    &:hover {
        text-decoration: underline;
    }
`;

export const NavItemContainer = styled(Link as any)`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-decoration: none;
    padding: 13px 22px;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;

    & svg {
        transition: all 0.2s ease-in-out;
    }

    &.selected,
    &:hover {
        background: ${({ theme }) => theme.colors.accentColorBG};
        text-decoration: none;
        & svg {
            fill: ${({ theme }) => theme.colors.accentColor};
            path {
                fill: ${({ theme }) => theme.colors.accentColor};
            }
        }
    }
    &:hover svg {
        transform: scale(1.1);
    }
`;

export const LinkWrapper = styled.div`
    position: relative;
    margin-bottom: 18px;
    align-self: stretch;
    margin: 20px 11px 0 11px;
`;

export const HideHint = styled.button`
    border: none;
    outline: none;
    background: none;
    height: 13px;
    width: 13px;
    position: absolute;
    right: 10px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transform: rotate(45deg);

    &:before {
        content: "";
        width: 13px;
        height: 1px;
        background: #fff;
        position: absolute;
    }

    &:after {
        content: "";
        width: 1px;
        height: 13px;
        background: #fff;
        position: absolute;
    }
`;

const HintAnimation = keyframes`
    0% { box-shadow: 0 0 14px rgba(0,0,0,0.1); }
    50% { box-shadow: 0 0 14px rgba(0,0,0,0.7); }
    100% { box-shadow: 0 0 14px rgba(0,0,0,0.1); }
`;

export const LinkHint = styled.div<{show: boolean}>`
    background-color: #6E6E6E;
    box-shadow: 0px 2px 30px  rgba(38, 47, 147, 0.2);
    position: absolute;
    max-width: 100%;
    right: calc(-100% - 10px);
    padding: 25px 15px;
    color: #fff;
    font-size: 14px;
    bottom: -17px;
    animation: ${HintAnimation} 3s infinite ease-in-out;

    ${({show}) => show ? css`
        display: block;
    ` : css`
        display: none;
    `}

    @media (max-width: 768px) {
        display: none;
    }

    p {
        margin: 0;
    }

    &:before {
        content: "";
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 5px 0;
        border-color: transparent #6e6e6e transparent transparent;
    }
`;

export const AppetiteGuideLink = styled.a`
    display: flex;
    align-items: center;
    padding: 15px 12px;
    font-size: 16px;
    border: 1px solid #E4E7ED;
    color: ${({theme}) => theme.colors.black};
    transition: .3s;

    &:hover {
        text-decoration: none;
        color: ${({theme}) => theme.colors.black};
        border-color: ${({theme}) => theme.colors.black};
    }

    svg.logo {
        margin-top: -2px;
        margin-right: 9px;
    }

    svg:not(.logo ) {
        margin-left: auto;
    }
`;
