import React, { useState } from "react";
import styled from "styled-components/macro";

import { TimesTitleBaseCSS, Body1, H4 } from "components/typography";

import { observer } from "mobx-react-lite";
import { listBrokerCompanies, requestRiskAssessment } from "services/users";
import { useApiGet, assert, isAxiosError } from "utils";
import { Loading, Button, SelectTypeAhead } from "components/common";
import { Link } from "react-router-dom";
import { logger } from "services";
import { toast } from "react-toastify";
import responsive from "services/responsive";
import { auth } from "services";
import { Helmet } from "react-helmet";

const SectionTitle = styled.h2`
    ${TimesTitleBaseCSS}
    font-size: 24px;
    font-weight: 700;
`;

const SectionContainer = styled.div`
    display: grid;
    margin: 20px 0;
    padding: 40px;
    border-radius: 10px;
    background: #ffffff;
    border: 2px solid #ecf1f7;
    grid-template-areas: "col1 col2" "col3 col3";
    grid-column-gap: 60px;
    grid-row-gap: 20px;

    ${responsive.Mobile} &,
    ${responsive.Tablet} & {
        grid-template-areas: "col1" "col3" "col2";
        grid-row-gap: 40px 60px;
    }
`;

const InnerContainer = styled.div`
    flex: 1;
`;

const HelperContainer = styled.div`
    padding: 30px;
    background: #c2c2c226;
    border-radius: 10px;

    ${responsive.Mobile} &,
    ${responsive.Tablet} & {
        padding: 20px;
    }
`;

const ButtonWrapper = styled.div`
    grid-area: col3 / col3 / col3 / col3;
    margin-bottom: 20px;
`;

export const StyledDownloadPdfButton = styled.a`
    cursor: pointer;
    background: #ffffff;
    border: 2px solid ${(props) => props.theme.colors.third};
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 12px;
    color: ${(props) => props.theme.colors.textColor};

    margin-bottom: 30px;

    &:hover {
        text-decoration: none;
    }

    & > span {
        display: inline-block;
        text-align: left;
        flex: auto;
        margin-left: 10px;
    }

    & .download-text {
        color: #bfcad6;
        font-size: 10px;
        text-align: right;
        font-weight: 700;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }

    &:hover .download-text {
        opacity: 1;
    }
`;

const StartApplicationLink = styled(Link)`
    color: ${(props) => props.theme.colors.secondary};
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transition: 0.2s all ease-in-out;

    &:hover {
        text-decoration: none;
        color: ${(props) => props.theme.colors.secondary};
        opacity: 0.7;
    }
`;

const submit = async (company: string) => {
    if (!company) return;
    try {
        await requestRiskAssessment({ company });
        toast.success("Risk Assessment was requested");
    } catch (error) {
        if (isAxiosError(error) && error.response?.data && error.response.status === 400) {
            toast.error((error.response.data as any).detail as string);
        } else {
            toast.error("There was a problem trying to request the Risk Assessment");
            logger.error(error);
        }
    }
};

const RiskAssessmentPage: React.FC = () => {
    const [loading, companies] = useApiGet(listBrokerCompanies);
    const [companyID, setCompanyID] = useState("");

    if (loading) {
        return (
            <SectionContainer>
                <div style={{ gridArea: "col3", textAlign: "center" }}>
                    <Loading />
                </div>
            </SectionContainer>
        );
    }

    assert(companies);

    const quotedCompanyForBroker = companies.filter((c) => c.quoted === true);

    const companyOptions = Object.fromEntries(quotedCompanyForBroker.map((c) => [c.id, c.name]));

    return (
        <SectionContainer>
            <Helmet>
                <title>Counterpart | Risk Assessment</title>
            </Helmet>
            <InnerContainer>
                <SectionTitle>Risk Assessment</SectionTitle>
                <Body1>
                    {`Generate a risk assessment for your client by analyzing your business’s profile
                    relative to more than 1,000 data points from governmental resources and
                    prominent experts in each domain. The report, which is co-branded with your name
                    and agency, offers an objective perspective on your client's exposure to a
                    multitude of critical small business risks based on the attributes of the
                    company.`}
                </Body1>
                <SelectTypeAhead
                    placeholder="Type to search companies"
                    value={companyID}
                    addEmptyOption
                    options={companyOptions}
                    onChange={(ev: any) => {
                        if (ev.length >= 1) {
                            setCompanyID(ev[0]["id"]);
                        } else {
                            setCompanyID("");
                        }
                    }}
                    label="Company Name"
                />
            </InnerContainer>
            <InnerContainer>
                <HelperContainer>
                    <H4>{`Can't find the Company in the dropdown?`}</H4>
                    <Body1>
                        {`You must first start an application for a company before you can request a
                        risk assessment. Once an application is started, you'll be able to find and
                        select the company from the dropdown list.`}
                    </Body1>
                    <StartApplicationLink to={`/startapplication?b=${auth.getUserID()}`}>
                        Start Application
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29001C12.6168 6.19677 12.5061 6.12281 12.3843 6.07235C12.2624 6.02189 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29001C11.1968 6.38324 11.1228 6.49394 11.0723 6.61576C11.0219 6.73758 10.9959 6.86815 10.9959 7.00001C10.9959 7.26631 11.1017 7.5217 11.29 7.71001L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z"
                                fill="currentColor"
                            />
                        </svg>
                    </StartApplicationLink>
                </HelperContainer>
            </InnerContainer>
            <ButtonWrapper>
                <Button
                    disabled={!companyID}
                    onClick={async () => {
                        await submit(companyID);
                        setCompanyID("");
                    }}
                >
                    Request Risk Assessment
                </Button>
            </ButtonWrapper>
        </SectionContainer>
    );
};

export default observer(RiskAssessmentPage);
