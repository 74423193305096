import { types, flow, applySnapshot, Instance, TypeOfValue } from "mobx-state-tree";
import { logger } from "services";
import { toast } from "react-toastify";
import { getRetailBrokers } from "services/brokers";
import debounce from "lodash/debounce";

export const RetailBrokerRow = types.model("RetailBrokerRow", {
    id: types.identifier,
    fullName: types.string,
    brokerageName: types.string,
    invitationDate: types.string,
    submissions: types.number,
    policies: types.number,
    totalPremium: types.number,
    email: types.string,
    firstName: types.string,
    lastName: types.string,
});

export const RetailBrokerTableStore = types
    .model({
        results: types.array(RetailBrokerRow),
        count: types.number,
    })
    .volatile(() => ({
        loading: true,
        error: false,
        page: 1,
        query: ""
    }))
    .actions((self) => ({
        load: flow(function* load() {
            try {
                self.loading = true;
                const data = yield getRetailBrokers(
                    self.page,
                    self.query
                );
                applySnapshot(self, data);
                self.error = false;
            } catch (error) {
                logger.error(error);
                self.error = true;
                throw error;
            } finally {
                self.loading = false;
            }
        }),
    }))
    .actions((self) => ({
        search() {
            self.page = 1;
            self.load();
        },
    }))
    .actions((self) => {
        const searchDebounce = debounce(() => {
            self.search();
        }, 750);

        return {
            setLoadingScreen() {
                self.loading = true;
            },
            reloadAllItems() {
                searchDebounce();
            },
            changeQuery(query: string) {
                self.query = query;
                searchDebounce();
            }
        };
    })
    .actions((self) => {
        return {
            afterCreate() {
                self.load().catch((error) => {
                    logger.error(error);
                });
            },
            changePage: flow(function* changePage(page: number) {
                const oldPage = self.page;
                try {
                    self.page = page;
                    yield self.load();
                } catch (error) {
                    logger.error("DashboardTableStore.changePage", error);
                    toast.error("An error happing while trying to get the data");
                    self.page = oldPage;
                }
            }),
        };
    });

export type IRetailBrokerTableStore = Instance<typeof RetailBrokerTableStore>;
export type IRetailBrokerTableStoreSnapshot = TypeOfValue<IRetailBrokerTableStore>["CreationType"];

export const createRetailBrokerTableStore = () => {
    const newStore = RetailBrokerTableStore.create({ results: [], count: 0 });
    return newStore;
};
