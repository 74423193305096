import { ArialBaseCSS, BaseFont, H2, MuseoSansBaseCSS } from "components/typography";
import responsive from "services/responsive";
import styled, { css } from "styled-components/macro";

import CheckMark from "assets/round-check-green.svg";

export const SubjectivityTitle = styled.h3`
    ${ArialBaseCSS}
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => props.theme.colors.textColor};
    display: flex;
    font-weight: 700;
`;

export const ClearedLabel = styled.div`
    ${BaseFont}
    margin-right: 15px;
    background: ${(props) => props.theme.colors.systemSuccess};
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    padding: 5px 15px;
    font-weight: 700;
`;

export const Text = styled.p`
    ${ArialBaseCSS}
    line-height: 150%;
    font-weight: 300;
`;

export const Container = styled.div`
    max-width: 1370px;
    margin: auto;
    padding: 0 20px;

    ${responsive.Tablet} &,
    ${responsive.Mobile} & {
        padding: 0 12px;
    }
`;

export const SideBySideContainer = styled.div`
    display: flex;
    & > *:not(:first-child) {
        margin-left: 20px;
    }

    ${responsive.Mobile} & {
        flex-wrap: wrap;
        > * {
            margin-left: 0;
        }
        > *:not(:first-child) {
            margin-top: 20px;
        }
    }
`;

export const PanelCSS = css`
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(38, 47, 147, 0.05);
    padding: 40px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
    align-items: center;
`;

export const HeaderTitle = styled.div`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    & > b {
        font-weight: 700;
    }
`;

export const SidebarContainer = styled.div`
    flex: 0 0 340px;
    height: 100%;
    min-height: 0;
    position: sticky;
    top: 10px;

    & > *:not(:first-child) {
        margin-top: 20px;
    }

    ${responsive.Tablet} & {
        flex: 0 0 240px;
    }

    ${responsive.Mobile} & {
        flex: 0 0 100%;
    }
`;

export const SidebarTitle = styled(SubjectivityTitle as any)`
    margin-bottom: 20px;
`;

export const SidebarLink = styled.a`
    ${MuseoSansBaseCSS}
    font-size: 16px;
    display: block;
    padding: 1px 0;

    &:hover {
        text-decoration: none;
    }
`;

export const StackedPaperPanel = styled.div`
    ${PanelCSS}
    position: relative;
    flex: 1;
    margin-bottom: 24px;

    ${responsive.Mobile} & {
        width: 100%;
        padding: 24px;
    }

    ${responsive.Tablet} & {
        padding: 32px;
    }

    &::after {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 10px;
        width: 100%;
        z-index: -1;
        content: "";
        height: 100%;
        background: #f6f6f6;
        box-shadow: 0px 10px 30px rgba(38, 47, 147, 0.05);
        top: 10px;
        width: calc(100% - 28px);
    }
`;

export const PageTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
`;

export const PageTitle = styled(H2.withComponent("h1") as any)``;
export const SectionTitle = styled(H2 as any)`
    margin-top: 80px;
`;

export const QuoteRefContainer = styled.div`
    text-align: right;
`;

export const QuoteRefLabel = styled.div`
    ${MuseoSansBaseCSS}
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 4px;
    opacity: 0.4;
    letter-spacing: 0.05em;
    white-space: nowrap;
`;

export const QuoteRefValue = styled(QuoteRefLabel as any)`
    font-size: 14px;
    font-weight: 700;
    opacity: 1;
    margin-bottom: 0;
`;

export const SidebarMarkMenu = styled.div<{ completed: boolean }>`
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background-color: #ffffff;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.textColor};
    border-radius: 50%;
    margin-left: -11px;
    margin-right: 16px;
    margin-top: -30px;

    ${({ completed }) => {
        if (completed) {
            return {
                backgroundImage: `url(${CheckMark})`,
                width: "22px",
                height: "22px",
                border: "none",
            };
        }
    }}
`;

export const SidebarMenuItemContainer = styled.div`
    position: relative;
    display: flex;
    margin-left: 11px;
    border-left: 2px solid ${(props) => props.theme.colors.third};
    padding: 30px 0;
    align-items: center;

    &.menu-item:last-of-type {
        border-left: none;
        border: none;
    }

    @media (max-width: 767px) {
        border: none;
    }
`;

export const SidebarCustomButton = styled.a`
    background-color: transparent;
    text-align: left;
    border: none;
    box-shadow: none;
    outline: none;
    margin-top: -30px;

    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }
`;

export const SidebarPanel = styled.div`
    ${PanelCSS};
    padding: 20px;
    position: relative;

    &.internal {
        padding: 0;
        background: none;
        border: none;
        box-shadow: none;
    }

    & ${SidebarMenuItemContainer}:last-of-type {
        border-left: 2px solid transparent;
    }

    @media (max-width: 767px) {
        &.closed {
            max-height: 40px;
            & ${SidebarMenuItemContainer} {
                &:not(.is-selected) {
                    display: none;
                }
            }
        }
    }
`;
