import React, { ReactNode } from "react";
import styled, { css } from "styled-components/macro";

import responsive from "services/responsive";

const MainContainer = styled.div<{ noPaddingTop: boolean, hideTransition: boolean }>`
    width: 100%;
    height: fit-content;
    display: flex;
    margin: 0;
    padding: 0;
    bottom: 0;
    padding-bottom: 60px;
    padding-top: 40px;

    @media (min-width: 769px) {
        ${({noPaddingTop}) => noPaddingTop && css`
            padding-top: 0;
        `}
    }

    @media (max-width: 768px) {
        ${({noPaddingTop, hideTransition}) => noPaddingTop && !hideTransition && css`
            padding-top: 0;
        `}
    }

    @media (max-width: 496px) {
        ${({hideTransition}) => hideTransition && css`
            padding-top: 62px;
        `}
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
    }
`;

const PageContainer: React.FC<{ children: ReactNode, noPaddingTop: boolean, hideTransition: boolean }> = ({ children, noPaddingTop, hideTransition }) => {
    return <MainContainer hideTransition={hideTransition} noPaddingTop={noPaddingTop}>{children}</MainContainer>;
};

export default PageContainer;
