import React, { KeyboardEvent } from "react";
import styled from "styled-components/macro";

import { Button, CustomDataTable, FormikInputField, Modal, Row } from "components/common";

import { Body1, BaseFont, TimesTitleBaseCSS } from "components/typography";

import { useLocalStore, observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";

import { createRetailBrokerTableStore } from "./store";
import { RetailBrokerTable } from "CounterpartApiTypes";
import moment from "moment";
import { formatCurrency, onTextChange } from "utils";
import { sendRetailBrokerInvite } from "services/application";
import { toast } from "react-toastify";
import {
    RowContent,
    RowDetail,
    RowDetailContainer,
    RowLabel,
    SearchButton,
    SectionInput,
    SectionInputContainer,
    HeadingRowDetail,
    RowBold,
} from "../styled";
import AddEditRetailBrokerModal from "../helpers/AddEditRetailBrokerModal";
import { ReactComponent as ClearIcon } from "assets/x-close-black.svg";
// import { auth } from "services";

import NoDataComponent from "../NoDataComponent";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";

async function resendInvite(retailBroker: RetailBrokerTable) {
    if (retailBroker) {
        try {
            await sendRetailBrokerInvite({
                email: retailBroker.email,
                firstName: retailBroker.firstName,
                brokerageName: retailBroker.brokerageName,
                lastName: retailBroker.lastName,
            });
            toast.success("Application Link Sent!");
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error("Could not resend the application invite to this broker");
            }
        }
    }
}

// function copyLink(retailBroker: RetailBrokerTable) {
//     const host = `${window.location.protocol}//${window.location.host}/`;
//     const url = `${host}startapplication?b=${auth.getUserID()}&r=${retailBroker.id}`;
//     navigator.clipboard.writeText(url).then(() => {
//         toast.success("Application link copied to clipboard!");
//     });
// }

const SectionTitle = styled.h2`
    ${TimesTitleBaseCSS}
    font-size: 24px;
    font-weight: 700;
`;

const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const SectionContainer = styled.div`
    ${BaseFont};
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 0;
`;

const ClearButton = styled(ClearIcon)`
    margin-left: 25px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 17px;
`;

const SearchContainer = styled.div`
    display: flex;
    position: relative;
`;

const StyledButton = styled(Button as any)`
    & {
        max-width: 230px;
        margin-left: 10px;
        padding: 8px 15px 8px 15px;
        font-size: 12px;

        @media (max-width: 700px) {
            margin-left: 0;
            margin-top: 10px;
        }

        @media (max-width: 480px) {
            margin-top: 0;
        }
    }
`;

const EmailButton = styled(Button as any)`
    & {
        padding: 3px;
        margin: 5px;
        min-width: 90px;
        width: 140px;
        min-height: 20px;
        border: none;
        background: none;
        color: #111da1;
        font-size: 12px;

        @media (max-width: 380px) {
            margin-right: 0;
            padding-right: 0;
            margin-left: 0;
            text-align: right;
            width: 130px;
        }
    }
`;

const StyledRow = styled(Row as any)`
    & > button {
        margin-left: 10px;
        margin-top: 25px;
        height: 54px;

        @media (max-width: 480px) {
            margin-left: 0;
        }
    }

    @media (max-width: 480px) {
        & > div {
            margin-right: 0 !important;
        }
    }
`;

const COLUMNS: any = (setModalShareOpen: any, setAgentData: any) => [
    {
        name: "Agent",
        selector: "fullName",
        hide: "md",
        grow: 1,
    },
    {
        name: "Agency",
        selector: "brokerageName",
        hide: "md",
        grow: 1,
        fontAlign: "left",
    },
    {
        name: "Email",
        selector: "email",
        hide: "md",
        grow: 2,
    },
    {
        name: "Invitation Date",
        selector: "invitationDate",
        hide: "md",
        grow: 1,
        format: (row: RetailBrokerTable) => moment(row.invitationDate).format("MM/DD/YY"),
    },
    {
        name: "Submissions",
        selector: "submissions",
        hide: "md",
        grow: 1,
        format: (row: RetailBrokerTable) => row.submissions || "--",
    },
    {
        name: "Policies",
        selector: "policies",
        hide: "md",
        grow: 1,
        format: (row: RetailBrokerTable) => row.policies || "--",
    },
    {
        name: "Total Premium",
        selector: "totalPremium",
        hide: "md",
        grow: 1,
        format: (row: RetailBrokerTable) =>
            row.totalPremium ? formatCurrency(row.totalPremium) : "--",
    },
    {
        name: "",
        selector: "",
        right: true,
        hide: "md",
        grow: 2,
        style: { display: "inline-grid" },
        wordBreak: "normal",
        allowOverflow: true,
        // eslint-disable-next-line react/display-name
        cell: (row: RetailBrokerTable) => (
            <>
                <Button
                    style={{
                        padding: 3,
                        margin: 5,
                        minWidth: 90,
                        width: 140,
                        minHeight: 20,
                        border: "none",
                        background: "none",
                        color: " #111da1",
                        fontSize: 12,
                    }}
                    onClick={() => {
                        setAgentData({
                            email: row.email,
                            firstName: row.firstName,
                            brokerageName: row.brokerageName,
                            lastName: row.lastName,
                        });
                        setModalShareOpen(true);
                    }}
                >
                    EMAIL APPLICATION
                </Button>
            </>
        ),
    },
];

const RowDetailComponent = ({ data, setModalShareOpen, setAgentData }: any) => {
    return (
        <RowDetailContainer>
            <HeadingRowDetail>
                <RowBold>{data.fullName}</RowBold>
                <RowContent>
                    <EmailButton
                        onClick={() => {
                            setAgentData({
                                email: data.email,
                                firstName: data.firstName,
                                brokerageName: data.brokerageName,
                                lastName: data.lastName,
                            });
                            setModalShareOpen(true);
                        }}
                    >
                        EMAIL APPLICATION
                    </EmailButton>
                </RowContent>
            </HeadingRowDetail>
            <RowDetail column>
                <RowLabel>Agency</RowLabel>
                <RowContent>{data.brokerageName}</RowContent>
            </RowDetail>
            <RowDetail column>
                <RowLabel>Email</RowLabel>
                <RowContent>{data.email}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Invitation Date</RowLabel>
                <RowContent>{moment(data.invitationDate).format("MM/DD/YY")}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Submissions</RowLabel>
                <RowContent>{data.submissions || "--"}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Policies</RowLabel>
                <RowContent>{data.policies || "--"}</RowContent>
            </RowDetail>
            <RowDetail>
                <RowLabel>Total Premium</RowLabel>
                <RowContent>
                    {data.totalPremium ? formatCurrency(data.totalPremium) : "--"}
                </RowContent>
            </RowDetail>
        </RowDetailContainer>
    );
};

const RetailBrokerPage: React.FC = () => {
    const store = useLocalStore(createRetailBrokerTableStore);
    const data = getSnapshot(store.results) as RetailBrokerTable[];

    const [modalShareOpen, setModalShareOpen] = React.useState(false);
    const [showAddAgent, setShowAddAgent] = React.useState(false);
    const [agentData, setAgentData] = React.useState<any>({
        email: "",
        firstName: "",
        brokerageName: "",
        lastName: "",
    });

    return (
        <React.Fragment>
            <Helmet>
                <title>Counterpart | Retail Agents</title>
            </Helmet>
            <SectionContainer>
                <SectionHeader>
                    <SectionTitle>Retail Agents</SectionTitle>
                    <SectionInputContainer
                        style={{ maxWidth: "100%", justifyContent: "space-between" }}
                    >
                        <SearchButton style={{ zIndex: 1 }} onClick={store.search} />
                        <SearchContainer>
                            <SectionInput
                                name="search_client"
                                placeholder="Search by Agent"
                                value={store.query}
                                onKeyDown={(e: KeyboardEvent) => {
                                    if (e.key === "Enter") store.search();
                                }}
                                onChange={onTextChange(store.changeQuery)}
                            />
                            {store.query?.length > 0 && (
                                <ClearButton onClick={() => store.changeQuery("")} />
                            )}
                        </SearchContainer>
                        <StyledButton onClick={() => setShowAddAgent(true)}>
                            Add Retail Agent
                        </StyledButton>
                    </SectionInputContainer>
                </SectionHeader>
                <React.Fragment>
                    <CustomDataTable<RetailBrokerTable>
                        columns={COLUMNS(setModalShareOpen, setAgentData)}
                        noDataComponent={
                            <NoDataComponent
                                filterNoResults
                                buttonText='Add Retail Agent'
                                message="Click on “Add Retail Agent” to get started"
                                action={() => setShowAddAgent(true)}
                            />
                        }
                        data={data}
                        paginationTotalRows={store.count}
                        onChangePage={store.changePage}
                        expandableRows={window.innerWidth < 959}
                        expandableRowExpanded={() => {
                            if (window.innerWidth < 959) return true;
                            return false;
                        }}
                        expandableRowsComponent={
                            <RowDetailComponent
                                setModalShareOpen={setModalShareOpen}
                                setAgentData={setAgentData}
                            />
                        }
                        progressPending={store.loading}
                    />
                </React.Fragment>
            </SectionContainer>

            <AddEditRetailBrokerModal
                quote={null}
                openSelectAgentPage={false}
                openStatus={showAddAgent}
                openCloseFunction={setShowAddAgent}
                reloadDataFunction={() => store.load()}
                callback={(data: any) => {
                    setAgentData({
                        email: data?.email,
                        firstName: data?.firstName,
                        brokerageName: data?.brokerage?.name,
                        lastName: data?.lastName,
                    });
                    setModalShareOpen(true);
                }}
            />

            <Modal
                isOpen={modalShareOpen}
                closeable={true}
                title="Email Application"
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalShareOpen(false);
                }}
            >
                <div style={{ paddingBottom: 50 }}>
                    <Body1>
                        {`We will email an application link to ${agentData.firstName} ${agentData.lastName} on your behalf. All submissions from that retail agent will appear in your broker dashboard.`}
                    </Body1>
                    <Formik
                        initialValues={agentData}
                        onSubmit={() => {
                            resendInvite(agentData);
                            setAgentData({
                                email: "",
                                firstName: "",
                                brokerageName: "",
                                lastName: "",
                            });
                            setModalShareOpen(false);
                        }}
                    >
                        {() => (
                            <Form>
                                <StyledRow>
                                    <FormikInputField
                                        name="email"
                                        label="Email Address"
                                        type="email"
                                        placeholder="e.g. john@company.com"
                                        value={agentData.email}
                                        onChange={(e: any) =>
                                            setAgentData({ ...agentData, email: e.target.value })
                                        }
                                    />
                                    <Button
                                        type="submit"
                                    >
                                        Send Email
                                    </Button>
                                </StyledRow>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default observer(RetailBrokerPage);
