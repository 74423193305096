import styled from "styled-components/macro";
import { MuseoSansBaseCSS } from "./../typography";

interface InputProps {
    error?: boolean;
}

const commonCss = `
    ${MuseoSansBaseCSS}
    transition: ease-in-out all 0.2s;
    border-radius: 0px !important;
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 0 15px;
    border-width: 1px !important;
    border-style: solid;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    background: #fff;
`;

const BaseInput = styled.input<InputProps>`
    ${commonCss}

    border-color: ${({ error, theme }) =>
        (error && theme.colors.systemError) || "#C2C2C2;"} !important;
    color: ${({ error, theme }) => {
        if (error) return theme.colors.systemError;
        return theme.colors.textColor;
    }};

    &:focus, &:hover {
        border-color: ${({ error, theme }) => {
            if (error) return theme.colors.systemError;
            return theme.colors.primary;
        }} !important;
    }

    &::placeholder {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #22232e !important;
        opacity: 0.4;
        font-weight: normal;
    }
`;

export const BaseTextArea = styled.textarea<InputProps>`
    ${commonCss}
    height: 100px;

    border-color: ${({ error, theme }) => (error && theme.colors.systemError) || "#E5EAEF"};
    color: ${({ error, theme }) => (error ? theme.colors.systemError : theme.colors.gray)};
    background-color: none;

    &:focus,
    &:hover {
        border-color: ${({ error, theme }) =>
            error ? theme.colors.systemError : theme.colors.gray};
    }

    &::placeholder {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #22232e !important;
        opacity: 0.4;
        font-weight: normal;
    }
`;

export default BaseInput;
