import React from "react";
import ApplicationEntry from "./ApplicationEntry";
import { Footer, HeaderSingle, Loading, Page } from "components/common";
import { OnePaperBackground } from "counterpart-theme";
import { useRouteMatch } from "react-router-dom";
import { getSubjectivityApp } from "services/quotes";
import { assert, useApiGet } from "utils";
import { ApplicationContext } from "services/application";
import { SubjectivitiesStore, SubjectivitiesStoreType } from "./store";

const Supplemental: React.FC<{ isSupplemental: boolean }> = ({ isSupplemental = false }) => {
    const match = useRouteMatch<{
        quoteID: string;
        publicSecurityToken: string;
    }>();
    const [loading, data, error] = useApiGet(
        getSubjectivityApp,
        match.params.quoteID,
        match.params.publicSecurityToken,
    );
    const [, reload] = React.useState<any>(undefined);

    const storeRef = React.useRef<SubjectivitiesStoreType | null>(null);

    React.useEffect(() => {
        if (!loading && error === undefined) {
            storeRef.current = SubjectivitiesStore.create(data as any);
            reload(true);
        }
    }, [data, loading, error]);

    if (error) throw error;

    if (loading || storeRef.current === null) {
        return (
            <Page>
                <HeaderSingle />
                <OnePaperBackground
                    className="text-center"
                    style={{
                        width: "80%",
                    }}
                >
                    <div className="py-5">
                        <Loading message="Fetching Your Application Information..." />
                    </div>
                </OnePaperBackground>
                <Footer />
            </Page>
        );
    }
    assert(data);

    if (storeRef.current.supplementalApplicationSubmitted) {
        storeRef.current.setCurrentPage(1)
    }

    return (
        <ApplicationContext.Provider
            value={{
                loading: false,
                brokerageName: data.brokerName,
                companyName: data.companyName,
                initialValues: data.initialAnswers,
                guidelines: null,
                setGuidelines: () => undefined,
                setSections: () => undefined,
                checkNewGuidelines: () => undefined,
            }}
        >
            <ApplicationEntry
                data={data}
                store={storeRef.current as any}
                isSupplemental={isSupplemental}
            />
        </ApplicationContext.Provider>
    );
};

export default Supplemental;
