import React from "react";
import { FullQuestion } from "services/application";
import { InputContainer, QuestionRowContainer } from "./styled";
import QuestionTitleRender from "./QuestionTitle";
import { AddressField } from "components/common";
import { Suggestion } from "places.js";

interface AddressProps {
    question: FullQuestion;
    extraProps?: { [key: string]: any };
}

const TEMPLATES = {
    suggestion({ highlight }: Suggestion) {
        const { administrative, postcode, name } = highlight;
        const highlightedPart = [administrative, postcode].filter(Boolean).join(", ");

        const out = `
            <span class="ap-name">${name}</span>
            <span class="ap-address">
                ${highlightedPart}
            </span>
        `.replace(/\s*\n\s*/g, " ");

        return out;
    },
    value(suggestion: Suggestion) {
        const { name, postcode, administrative } = suggestion;
        return [name, administrative, postcode]
            .filter(Boolean)
            .join(", ")
            .trim();
    },
};

const STYLES = { paddigRight: "32px" };

const Text: React.SFC<AddressProps> = ({ question, extraProps = {} }) => {
    const fieldName = `${question.id}__${extraProps?.questionIndex || 0}`;

    return (
        <QuestionRowContainer data-name="QuestionRowContainer" className="address">
            <QuestionTitleRender question={question} extraProps={extraProps} />
            <InputContainer id={"q-" + fieldName}>
                <AddressField
                    className={`question-${question.id} ${
                        question.required ? "field-required" : ""
                    }`}
                    name={fieldName}
                    placeholder={question.placeholder as string}
                    templates={TEMPLATES}
                    label=""
                    style={STYLES}
                    data-lpignore="true"
                    postcodeSearch={true}
                />
            </InputContainer>
        </QuestionRowContainer>
    );
};

export default React.memo(Text);
