import styled, { css, createGlobalStyle } from "styled-components/macro";
import { Body2 } from "./../typography";

import responsive from "services/responsive";

interface PanelProps {
    big?: boolean;
}

export const Row = styled.div`
    /* grid-gap: 40px; */
    margin: 0 0 20px 0;
    grid-auto-flow: column;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > *:not(:last-child) {
        margin-right: 20px;
    }

    & > div.row-field.half-input {
        width: 51%;
        flex: 0 0 51%;
    }

    @media (max-width: 767px) {
        margin: 0;
        & > div.row-field,
        & > div.row-field:not(:last-child) {
            margin: 20px 0;
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;
        padding: 0;
        margin: 0;
        & > div.row-field.half-input {
            width: 100%;
            flex: 0 0 100%;
        }

        & div.row-field,
        & div.row-field:not(:last-child) {
            width: 100%;
            flex: 0 0 100%;
            padding: 0 0 15px 0;
            margin: 0;
        }
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        padding: 30px 0;

        & > div.row-field:not(:last-child) {
            padding-right: 20px;
        }
    }
`;
Row.displayName = "Row";

export const BlueDivider = styled.hr`
    margin: 40px auto 70px;
    width: 100px;
    height: 3px;
    border: none;
    background: ${(props) => props.theme.colors.secondary};

    @media (max-width: 767px) {
        margin: 15px auto 30px;
    }
`;
BlueDivider.displayName = "BlueDivider";

export const GreyDivider = styled.hr`
    margin: 40px auto 70px;
    width: 100px;
    height: 1px;
    background-color: ${(props) => props.theme.colors.third};
    &.thin-margin {
        margin: 20px auto;
    }
`;
GreyDivider.displayName = "GreyDivider";

export const SectionContainer = styled.div<{ stretchContainer?: boolean }>`
    max-width: 1560px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    ${({ stretchContainer }) =>
        stretchContainer &&
        `
        width: 100%;
    `}

    & > :only-child {
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 800px) {
        margin: 0;
        & > :only-child {
            margin-left: 0;
            margin-right: 0;
        }
    }
`;
SectionContainer.displayName = "SectionContainer";

export const basePanelCss = css`
    background: #fff;
    box-shadow: 0px 10px 30px rgba(38, 47, 147, 0.05);
    min-height: 50px;
    padding: 60px;
    position: relative;
    width: 100%;

    @media (max-width: 800px) {
        width: 100%;
        min-width: 100%;
        padding: 20px;
        margin: 0px;
    }
`;

export const Panel = styled.div<PanelProps>`
    ${basePanelCss}
    padding: ${(props) => (props.big ? "60px" : "30px")};

    &.side-panel {
        width: 340px;
    }

    &.responsive-cta {
        & div.responsive-cta-control {
            display: none;
            cursor: pointer;
        }
    }

    @media (min-width: 1040px) {
        &.side-panel {
            // position: sticky;
            // top: 10px;
        }
    }

    @media (max-width: 1200px) {
        &.side-panel {
            width: 100%;
        }
    }

    @media (max-width: 1024px) {

        &.responsive-cta {
            position: fixed;
            bottom: 0;
            z-index: 100010;
            margin: 0;
            box-shadow: 0px 0px 1px;

            & div.responsive-cta-content {
                &.closed {
                    display: none;
                    height: 0;
                }
                &.opened {
                    display: block;
                    height: auto;
                    overflow-y: auto;
                }
            }

            & div.responsive-cta-control {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    @media (max-width: 700px) {
        margin: 0;
    }
`;

Panel.defaultProps = {
    big: false,
};
Panel.displayName = "Panel";

export const Container = styled.div`
    max-width: 1290px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 20px;

    & > :only-child {
        margin-left: auto;
        margin-right: auto;
    }

    & > :not(${Panel}) {
        margin: 0;
    }

    @media (max-width: 800px) {
        margin: 0;
        & > :only-child {
            margin-left: 0;
            margin-right: 0;
        }
    }

    ${responsive.InternetExplorer} {
        width: 100%;
    }
`;
Container.displayName = "Container";

export const StackedPaper = styled.div`
    ${basePanelCss}
    border-radius: 10px;
`;

export const StackedPaperPaddedContainer = styled.div`
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    ${responsive.Desktop} & {
        padding-left: 20px;
    }

    &.application-section-questions {
        position: relative;

        & div.disabled-orverlay {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 997;
            cursor: not-allowed;
        }

        &.is-insurance {
            &.disabled {
                & div.disabled-orverlay {
                    display: block;
                }

                & ${StackedPaper} {
                    background: rgb(242, 244, 246);

                    & input {
                        background: ${(props) => props.theme.colors.third};
                    }
                    & select {
                        background: ${(props) => props.theme.colors.third};
                    }
                }
            }
        }
    }
    @media (min-width: 900px) {
        min-width: 900px;
    }

    @media (max-width: 767px) {
        padding: 20px 0;
    }
`;

export const FormErrorMessage = styled(Body2 as any)`
    font-size: 12px;
    color: ${(props) => props.theme.colors.systemError};
`;

export const BodyStyle = createGlobalStyle`
    background: ${(props) => props.theme.colors.backgroundLightBlue};
`;

export const ResponsiveContainer = styled.div`
    display: flex;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
        &.ie-styled {
            min-width: 1350px;
        }
    }
`;

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
    position: relative;
    border-radius: 10px;

    & ${ResponsiveContainer} {
        padding: 0;
        max-width: 1350px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 1024px) {
            padding: 0;
            margin: 0;
        }

        @media (max-width: 700px) {
            padding: 0;
        }
    }

    ${responsive.InternetExplorer} & {
        position: relative;
        display: -ms-flexbox;
        -ms-flex-direction: column;
        width: 100%;
    }
`;

export const Surface = styled.div`
    background: #ffffff;
    padding: 20px;
    box-shadow: 0px 12px 18px rgba(228, 235, 244, 0.75);
    display: flex;
    flex: auto;
    border-radius: 10px;
`;

export const SideApplicationContainer = styled.div`
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    height: fit-content;

    @media (max-width: 1024px) {
        position: relative;
        top: 0;
        padding: 0 10px 20px 10px;
    }
    @media (max-width: 767px) {
        padding: 0;
    }
`;

export const Alert = styled.div<{ color: "danger" | "success" | "neutral" }>`
    display: flex;
    padding: 15px 20px;
    align-items: center;
    background: #f3f3f3;
    color: #000;
    margin-bottom: 20px;

    ${({ color, theme }) =>
        color === "danger" &&
        css`
            background-color: ${theme.colors.dangerColor};
        `};

    ${({ color }) =>
        color === "success" &&
        css`
            background-color: #72dd9d33;
        `};

    ${({ color }) =>
        color === "neutral" &&
        css`
            background-color: #f3f3f3;
        `};

    img {
        display: block;
        height: 30px;
        width: 30px;
        margin-right: 10px;
    }
`;
