import axios, { AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/browser";

import { jwtDecode, getBaseURL, isAxiosError } from "../utils";
import * as logger from "./logger";
import { ChangePassword, RequestMagicLink } from "CounterpartApiTypes";
import Cookies from "js-cookie";
import rootStore from "services/store";
import apiClient from "./apiClient";

type TokenUpdateCallback = (state: AuthState) => void;
const TOKEN_UPDATE_CALLBACKS: TokenUpdateCallback[] = [];

const ACCESS_TOKEN_KEY = "$auth_accessToken";
const REFRESH_TOKEN_KEY = "$auth_refreshToken";
const BASE_URL = getBaseURL();
let TIME_SKEW = 0;
let requestingRefresh: Promise<AuthState> | void;

let ACCESS_TOKEN = "";
let STATE: AuthState | undefined = undefined;

export type DecodeToken = {
    user_id: string;
    token_type: "access" | "refresh";
    exp: number;
    jti: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    brokerageName?: string;
    avatar?: string | null;
    brokerAdminOverride?: boolean;
    adminUser?: string;
};

export type AuthState = {
    accessToken?: string;
    refreshToken?: string;
    userID?: string;
    userInfo?: DecodeToken;
};

export type AuthenticationFailedError = {
    detail: string;
};

export type BadRequestError = {
    email?: string[];
    password?: string[];
};

export type AuthErrorDetails = AuthenticationFailedError | BadRequestError;

export type AuthError = AxiosError<AuthErrorDetails>;
type AuthStateCallback = (a: DecodeToken | null) => void;

const STATE_CHANGE_LISTENERS: AuthStateCallback[] = [];

/**
 * Returns a promise that resolve on next tick
 *
 * @param value (optional) Value that will be returned, can be anything
 */
export function nextTick<T extends any>(value?: T): Promise<T> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(value);
        }, 0);
    });
}

function getCookieValue(key: string): string | null {
    return Cookies.get(key) ?? null;
}

function setCookieValue(key: string, value: string, expires = 7) {
    Cookies.set(key, value, { expires });
}

function removeCookieValue(key: string) {
    Cookies.remove(key);
}

function getRefreshToken() {
    return getCookieValue(REFRESH_TOKEN_KEY) ?? undefined;
}

/**
 * Given a access and refresh token, returns the state used on the auth reducer
 *
 * @param access Access token string
 * @param refresh Refresh token string
 */
function getStateFromToken(access: string, refresh: string): AuthState {
    const decodedAccess: DecodeToken | null = jwtDecode(access);
    if (!decodedAccess) return {};
    const userID = decodedAccess.user_id;
    const userInfo = decodedAccess;

    Sentry.setUser({
        id: userID,
        email: userInfo.email,
    });

    STATE_CHANGE_LISTENERS.forEach((func) => func(userInfo));
    return {
        accessToken: access,
        refreshToken: refresh,
        userID,
        userInfo,
    };
}

export class ChangePasswordRequiredError extends Error {
    accessToken: string;
    refreshToken: string;

    constructor(message: string, accessToken: string, refreshToken: string) {
        super(message);
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }
}

/** Make a login using the email and password informed by the user.
 *
 * This will save the tokens in memory and in local storage
 */
export async function login(email: string, password: string): Promise<AuthState> {
    const url = `${BASE_URL}/api/users/sign-in/`;
    const data = { email, password };
    try {
        // eslint-disable-next-line no-var
        var response = await axios.post(url, data, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
        });
        extractTimeSkew(response);
    } catch (error) {
        logger.error(error);
        return Promise.reject(error);
    }
    const { refresh, access, requirePasswordChange } = response.data;
    if (requirePasswordChange) {
        return Promise.reject(
            new ChangePasswordRequiredError(
                "For security reasons you need to change your password",
                access,
                refresh,
            ),
        );
    }
    return saveToken(access, refresh);
}

/** Make a login using the email and password informed by the user.
 *
 * This will save the tokens in memory and in local storage
 */
export async function brokerAdminOverrideLogin(
    email: string,
    brokerEmail: string,
    password: string,
): Promise<AuthState> {
    const url = `${BASE_URL}/api/users/admin-override-sign-in/`;
    const data = { email, password, brokerEmail };
    try {
        // eslint-disable-next-line no-var
        var response = await axios.post(url, data, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
        });
        extractTimeSkew(response);
    } catch (error) {
        logger.error(error);
        return Promise.reject(error);
    }
    const { refresh, access } = response.data;
    return saveToken(access, refresh);
}

/**
 * Refresh the access token using a refresh token, by default uses the refresh token
 * stored locally if there's one.
 * @param refresh (Optional) token to use as the refresh token
 */
export async function refreshAccessToken(refresh: string): Promise<AuthState> {
    const url = `${BASE_URL}/api/users/sign-in/refresh/`;
    const data = { refresh };
    try {
        logger.debug("Refreshing token...");
        const response = await axios.post(url, data, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
        });
        logger.debug("Refresh token response:", response.data);
        extractTimeSkew(response);
        const { access, refresh: newRefresh } = response.data;
        return saveToken(access, newRefresh || refresh);
    } catch (error) {
        if (isAxiosError(error) && error.response && error.response.status === 401) {
            // If we got a 401 while refreshing the token, we need the user to
            // enter his credentials again.
            logout();
        }
        return Promise.reject(error);
    } finally {
        requestingRefresh = undefined;
    }
}

/**
 * Logout the user
 * This will remove the token from memory and from localStorage, and invalidate
 * the tokens on the backend to prevent they to be used again.
 *
 * TODO: Call the backend API to invalidate the tokens
 */
export async function logout(): Promise<boolean> {
    logger.debug("Calling logout");
    if (window.confirm("Are you sure?")) {
        try {
            const url = `${BASE_URL}/api/users/logout/`;
            await axios.post(url, { refresh: getRefreshToken() ?? "" });
        } catch (error) {
            logger.error(error);
        }
        removeTokens();
        rootStore.clearUserInfo();
        //eslint-disable-next-line no-restricted-globals
        window.location.reload();
        return true;
    }
    return false;
}

/**
 * Returns a valid access token assuming that we have a valid
 */
export async function getAccessTokenAsync(): Promise<string | void> {
    const REFRESH_TOKEN = getRefreshToken();
    if (ACCESS_TOKEN && !isTokenExpired(ACCESS_TOKEN)) {
        return ACCESS_TOKEN;
    } else if (REFRESH_TOKEN && !isTokenExpired(REFRESH_TOKEN)) {
        if (!requestingRefresh) {
            requestingRefresh = refreshAccessToken(REFRESH_TOKEN);
        }
        const data = await requestingRefresh;
        return data.accessToken;
    }
}

/**
 * Force refresh a token.
 *
 * The reasoning for the existence of this is that the client clock is not always
 * right, and that being the case we cannot always rely on the expiration date
 * of the token to have it being correctly updated.
 *
 * So if we got back a "Invalid Token" response from the server, we call this
 * function to try getting a valid access token. However if we do not have a
 * refresh token we immediately logout the user.
 *
 * We also check if the token is already being refreshed as result of a previous
 * request, and in that case we just return the same Promise.
 */
export async function forceRefreshToken(): Promise<string> {
    const REFRESH_TOKEN = getRefreshToken();
    if (requestingRefresh === undefined && REFRESH_TOKEN) {
        requestingRefresh = refreshAccessToken(REFRESH_TOKEN);
    } else if (!REFRESH_TOKEN) {
        logout();
    }

    await requestingRefresh;

    // After requestingRefresh is resolved we can be sure that, ACCESS_TOKEN
    // contains the freshest token, so we can just return it
    return ACCESS_TOKEN;
}

/**
 * Saves the tokens in memory and in localStorage
 * @param accessToken
 * @param refreshToken
 */
export function saveToken(accessToken: string, refreshToken: string): AuthState {
    logger.debug("Saving new tokens:", { accessToken, refreshToken });
    setCookieValue(ACCESS_TOKEN_KEY, accessToken);
    setCookieValue(REFRESH_TOKEN_KEY, refreshToken);
    ACCESS_TOKEN = accessToken;
    requestingRefresh = undefined;
    STATE = getStateFromToken(accessToken, refreshToken);
    TOKEN_UPDATE_CALLBACKS.forEach((func) => {
        func(STATE as AuthState);
    });
    return STATE;
}

export function authChangePassword(values: ChangePassword, token: string) {
    const url = `${BASE_URL}/api/users/change_password/`;
    return axios.post(url, values, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
        },
    });
}

/**
 * Remove the tokens from memory and from localStorage
 */
function removeTokens(): void {
    logger.debug("Removing tokens...");
    removeCookieValue(ACCESS_TOKEN_KEY);
    removeCookieValue(REFRESH_TOKEN_KEY);
    removeCookieValue("appSecurityToken");
    ACCESS_TOKEN = "";
}

/**
 * Returns the initial state that will be used in the auth reducer
 * returns a empty object when the token are not present in localStorage or the
 * refreshToken is expired
 */
export function initialState(): AuthState {
    const accessToken = getCookieValue(ACCESS_TOKEN_KEY);
    const refreshToken = getCookieValue(REFRESH_TOKEN_KEY);
    const REFRESH_TOKEN = refreshToken || "";
    ACCESS_TOKEN = accessToken || "";
    if (accessToken && refreshToken && !isTokenExpired(refreshToken)) {
        STATE = getStateFromToken(ACCESS_TOKEN, REFRESH_TOKEN);
        return STATE;
    }
    removeTokens();
    return {};
}

function currentTime(skew = TIME_SKEW): number {
    return Date.now() / 1000 + skew;
}

/**
 * Extract the difference between server time and client time.
 * This allow us to detect the token expiration even if the client clock is out
 * of sync with the server.
 *
 * By default if the value skew value if less than 10 seconds we set the skew
 * to 0, this is to make sure that we don't add skew when a client has a slow
 * connection with a high latency overhead.
 *
 * @param response A axios response object
 * @param minValue The minimum acceptable skew
 */
export function extractTimeSkew(response: AxiosResponse, minValue = 10): void {
    try {
        const skew = (new Date(response.headers.Date).getTime() - Date.now()) / 1000;
        logger.debug("Extracting time skew from response", { skew });
        if (Math.abs(skew) > minValue) {
            TIME_SKEW = skew;
        } else {
            TIME_SKEW = 0;
        }
    } catch (error) {
        logger.error(error);
        TIME_SKEW = 0;
    }
}

/**
 * Returns a boolean indicating if the token has expired
 * @param token access or refresh token to check
 * @param skew difference in seconds between server and client clocks (optional)
 */
export function isTokenExpired(token: string, skew = TIME_SKEW): boolean {
    const data: DecodeToken | null = jwtDecode(token);
    if (!data) return true;
    return data.exp < currentTime(skew);
}

/**
 * Returns the UUID for the current user is one exists otherwise returns null
 */
export function getUserID(): string | null {
    if (!STATE) return null;
    return STATE.userID || null;
}

/**
 * Returns the info for the current user is one exists otherwise returns null
 */
export function getUserInfo(): DecodeToken | null {
    return STATE?.userInfo || null;
}

export function onAuthStateChange(func: AuthStateCallback) {
    STATE_CHANGE_LISTENERS.push(func);
    func(getUserInfo());
    return () => {
        const index = STATE_CHANGE_LISTENERS.findIndex((f) => f === func);
        if (index !== -1) {
            STATE_CHANGE_LISTENERS.splice(index, 1);
        }
    };
}

type RemoveUndefined<T> = T extends undefined ? never : T;

type RemoveUndefinedKeys<T> = T extends {}
    ? {
          [P in keyof T]-?: RemoveUndefined<T[P]>;
      }
    : T;

type ApiReturnType<T> = T extends Array<any> ? RemoveUndefinedKeys<T[0]>[] : RemoveUndefinedKeys<T>;

/**
 * requestMagicLink
 * ---
 *
 *
 * @api POST /api/users/request-magic-link/
 *
 * @param {apiTypes.RequestMagicLink} data
 */
export async function requestMagicLink(
    data: RequestMagicLink,
    apiOptions = {},
): Promise<ApiReturnType<any>> {
    try {
        const url = `/api/users/request-magic-link/`;
        const resp = await apiClient.post(url, data, apiOptions);
        return resp.data;
    } catch (error) {
        const msg = 'Error calling api method "requestMagicLink"';
        logger.error(msg, { data });
        return Promise.reject(error);
    }
}
