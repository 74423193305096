import React from "react";
import styled from "styled-components/macro";
import { Page, InputField, LightBlueButton } from "components/common";
import { H4, MuseoSansBaseCSS } from "components/typography";
import { copyToClipboard } from "utils";

const Text = styled.span`
    ${MuseoSansBaseCSS}
    line-height: 150%;
`;

const HoverInput = styled.div`
    width: 100%;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

const StyledDiv = styled.div`
    display: flex;
    width: 100%;

    & button {
        margin-left: 10px;
    }
    @media (max-width: 480px) {
        flex-direction: column;

        & button {
            margin-left: 0;
        }
    }
`;

interface Props {
    quoteId: string;
}

const SendLink: React.SFC<Props> = ({ quoteId }) => {
    const [linkWasCopied, setLinkWasCopied] = React.useState(false);
    const getSignApplicationHellosignUrl = () => {
        return `${window.location.protocol}//${window.location.host}/sign-application/${quoteId}`;
    };
    return (
        <Page>
            <StyledDiv style={{ flexDirection: "column" }}>
                <H4 style={{ fontWeight: 500 }}>Send Link</H4>
                <Text>Generate an eSignature link that you can send to the broker or insured.</Text>
            </StyledDiv>
            <StyledDiv style={{ width: "100%", marginTop: 30 }}>
                <HoverInput
                    style={{ flex: 1 }}
                    onClick={() => {
                        copyToClipboard(getSignApplicationHellosignUrl());
                        setLinkWasCopied(true);
                    }}
                >
                    <InputField
                        label="Link"
                        placeholder="eSignature Link"
                        disabled={true}
                        defaultValue={getSignApplicationHellosignUrl()}
                    />
                </HoverInput>
                <LightBlueButton
                    style={{
                        marginTop: 24,
                        height: 54,
                        paddingLeft: 60,
                        paddingRight: 60,
                    }}
                    onClick={() => {
                        copyToClipboard(getSignApplicationHellosignUrl());
                        setLinkWasCopied(true);
                    }}
                >
                    COPY LINK
                </LightBlueButton>
                <div
                    style={{
                        textAlign: "right",
                        position: "absolute",
                        right: "18px",
                        color: "forestgreen",
                        display: linkWasCopied ? "block" : "none",
                    }}
                >
                    Link Copied
                </div>
            </StyledDiv>
        </Page>
    );
};

export default React.memo(SendLink);
