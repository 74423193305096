import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { parseQueryString } from "utils"

import styled from "styled-components/macro";
import Cookies from "js-cookie";
import { Page } from "components/common/styled";
import { HeaderForApplication, HeaderSingle } from "components/common/Header";
import {
    ResponsiveContainer,
    StackedPaperPaddedContainer,
    SideApplicationContainer,
    Loading,
    Button,
    Modal,
    InputField,
    Footer,
} from "components/common";

import { WhiteButton, LightBlueButton } from "components/common/Button";
import { OnePaperBackground } from "counterpart-theme";

import SectionSelector from "features/Application/components/SectionSelector";
import SectionQuestions from "features/Application/components/SectionQuestions";
import {
    useApplicationContext,
    saveAnswer,
    saveAnswerNow,
    AnswerFormat,
    FullApplicationSection,
    shareApplication,
    useApplicationInfo,
    AnswersContext,
    getAnswersContext,
} from "services/application";
import { QuestionsStackedPaper, SubmitCTAContainer } from "./styled";
import { useRouteMatch, Route, Redirect, Switch, useHistory } from "react-router-dom";
import { getSectionUrl, canSubmitApplication } from "../sectionUtils";
import { toast, Zoom } from "react-toastify";
import { assert } from "utils";
import { MuseoSansBaseCSS, Body1Span } from "components/typography";

import { ReactComponent as ShareApplication } from "assets/share-application.svg";
import responsive from "services/responsive";
import { Helmet } from "react-helmet";

export const StyledShareIcon = styled(ShareApplication)`
    display: none;
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: 767px) {
        display: block;
    }
`;

const StyledPage = styled(Page as any)`
    @media (max-width: 767px) {
        padding-bottom: 120px;
    }
`;

export const StyledHeaderTitle = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    & > b {
        font-weight: 700;
    }
`;

export const StyledWhiteButton = styled(WhiteButton as any)`
    border-radius: 10px;
    &.light-blue {
        background-color: #15b3dc;
        text-align: center;
        &:hover {
            background-color: #0598bd;
            text-decoration: none;
        }
    }
    @media (max-width: 767px) {
        &,
        &.light-blue {
            background: none;
            width: 20px;
            min-width: 20px;
            margin-right: 20px;
        }
    }
`;

export const StyledLabel = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.textColor};
`;

export const StyledButtonText = styled.span`
    ${MuseoSansBaseCSS}
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.textColor};
    opacity: 0.6;

    &.light-blue {
        color: #ffffff;
        opacity: 1;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;

export const ShareApplicationDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -30;
    ${responsive.InternetExplorer} & {
        display: -ms-flexbox;
    }
`;

export const FlexComponentParent = styled.div`
    display: flex;
    margin-top: 19px;
    ${responsive.InternetExplorer} & {
        display: block;
    }
`;

export const FlexComponentInput = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    ${responsive.InternetExplorer} & {
        display: block;
    }
`;

const StyledSubmitCTAContainer = styled(SubmitCTAContainer as any)`
    padding-left: 10px;
`;

type ApplicationRouteMatch = { applicationID: string; sectionKey: string };

let { only_questions, user_id } = parseQueryString();
const showOnlyQuestions = only_questions === 'true';
const UserId = user_id;
if (showOnlyQuestions) {
    Cookies.set('ignoreStatusFilter', 'true', {
        expires: 1,
        path: '/',
        sameSite: 'None',
        secure: true
    });
    Cookies.set('userId', UserId, {
        expires: 1,
        path: '/',
        sameSite: 'None',
        secure: true
    });
} else {
    if (Cookies.get('ignoreStatusFilter')) {
        Cookies.remove('ignoreStatusFilter', { path: '/' });
    }
    if (Cookies.get('userId')) {
        Cookies.remove('userId', { path: '/' });
    }
}

const saveAndReview = (
    applicationID: string,
    appSecurityToken: any,
    sections: FullApplicationSection[],
    guidelines: Record<string, string> | null,
    setSections: (secs: FullApplicationSection[]) => void,
    history: ReturnType<typeof useHistory>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: AnswerFormat,
    setQuestionsOnError: any,
    questionsOnError: any,
) => async () => {
    const loggedIn = Cookies.get("$auth_accessToken");
    const appToken = Cookies.get("appSecurityToken");
    const securityToken = appSecurityToken ? appSecurityToken : appToken;
    const applicationReviewPath = loggedIn
        ? `/application/${applicationID}/review`
        : `/application/${applicationID}/${securityToken}/review`;

    try {
        setSubmitting(true);
        await saveAnswerNow(applicationID, values).then(() => {
            toast("Your application answers have been saved.", {
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: true,
                transition: Zoom,
            });
        });
        if (
            canSubmitApplication(
                sections,
                values,
                guidelines,
                setSections,
                setQuestionsOnError,
                questionsOnError,
            )
        ) {
            if (!showOnlyQuestions){
                setSubmitting(false);
                const activeInsurancelines = sections?.filter((section: any) => {
                    if (section.isActive && section.insuranceLine) {
                        return section;
                    }
                });
                if (activeInsurancelines.length) {
                    if (!showOnlyQuestions){
                        setTimeout(() => history.push(`${applicationReviewPath}`), 0);
                    }
                    else {
                        setTimeout(() => window.location.reload(), 0);
                    }
                } else {
                    toast.error(
                        "You must have one insurance line at a minimum active to submit your application.",
                    );
                }
            }
        }
        setSubmitting(false);
    } catch (error) {
        toast.error("There was an error while trying to save your application");
        setSubmitting(false);
    }
};

const _onSubmit = (
    applicationID: string,
    appSecurityToken: string,
    sectionKey: string,
    sections: FullApplicationSection[],
    guidelines: Record<string, string> | null,
    history: ReturnType<typeof useHistory>,
    setSections: (sec: FullApplicationSection[]) => void,
    setQuestionsOnError?: any,
    questionsOnError?: any,
) => async (values: AnswerFormat, actions: FormikHelpers<AnswerFormat>) => {
    const sectionIndex = sections.findIndex((sec) => sec.ratingEngineKey === sectionKey);
    const nextSection = sections[sectionIndex + 1];
    if (nextSection) {
        saveAnswerNow(applicationID, values).then(() => {
            toast("Your application answers have been saved.", {
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: true,
                transition: Zoom,
            });
        });
        // .catch((error) => {
        //     logger.error(error);
        //     toast.error("We got and error trying to save your application");
        // });
            const nextSectionUrl = getSectionUrl(nextSection);
            history.push(nextSectionUrl);
    } else {
        await saveAndReview(
            applicationID,
            appSecurityToken,
            sections,
            guidelines,
            setSections,
            history,
            actions.setSubmitting,
            values,
            setQuestionsOnError,
            questionsOnError,
        )();
    }
        window.scrollTo(0, 0);
};

const ApplicationEntry: React.SFC = () => {
    const [modalShareOpen, setModalShareOpen] = React.useState<boolean>(false);
    const [sendToEmail, setSendToEmail] = React.useState("");
    const [emailMessage, setEmailMessage] = React.useState("");
    const match = useRouteMatch<{
        applicationID: string;
        appSecurityToken: string;
        sectionKey?: string;
    }>();
    const history = useHistory();
    const {
        loading,
        sections,
        initialValues,
        guidelines,
        // eslint-disable-next-line
        setSections,
        questionsOnError,
        setQuestionsOnError,
    } = useApplicationContext();

    const appSecurityToken = Cookies.get("appSecurityToken");
    const loggedIn = Cookies.get("$auth_accessToken");
    const applicationSectionPath = loggedIn
        ? `/application/:applicationID/:sectionKey`
        : `/application/:applicationID/:appSecurityToken/:sectionKey`;

    const { companyName, brokerageName } = useApplicationInfo(
        match.params.applicationID,
        match.params.appSecurityToken,
    );
    const [yourName, setYourName] = React.useState<string>("");
    // const [textAreaMessage, setTextareaMessage] = React.useState<string>("");

    if (loading) {
        return (
            <Page>
                <HeaderSingle />
                <OnePaperBackground className="text-center" style={{ width: "80%" }}>
                    <div className="py-5">
                        <Loading message="Fetching Your Application Information..." />
                    </div>
                </OnePaperBackground>
                <Footer />
            </Page>
        );
    } else if (sections === undefined) {
        return (
            <Page>
                <HeaderForApplication />
                <OnePaperBackground className="text-center">
                    <div className="py-5">
                        <h3>Error could not load Application, please log out and try again.</h3>
                    </div>
                </OnePaperBackground>
                <Footer />
            </Page>
        );
    }

    assert(sections, "Missing sections values");
    assert(initialValues, "Missing initialValues");
    assert(setSections, "Missing setSections");

    const sectionIndex = sections?.findIndex((s) => s.ratingEngineKey === match.params.sectionKey);
    const section = sectionIndex ? sections[sectionIndex] : undefined;
    const sectionsWithError = !!sections.filter((sec) => sec.isActive && sec.onError).length;

    const handleChange = (event: any) => {
        setSendToEmail(event?.target?.value);
    };

    const handleName = (event: any) => {
        setYourName(event?.target?.value);
    };

    return (
        <StyledPage>
            <Helmet>
                <title>Counterpart | Application {companyName ? `for ${companyName}` : ""}</title>
            </Helmet>
            {
                !showOnlyQuestions && (
                    <HeaderForApplication
                        style={{ maxWidth: 1350, padding: "0 20px" }}
                        optionalButtons={
                            <StyledWhiteButton onClick={() => setModalShareOpen(true)}>
                                <StyledShareIcon />
                                <StyledButtonText>Finish Later</StyledButtonText>
                            </StyledWhiteButton>
                        }
                    >
                        <StyledHeaderTitle>
                            <span style={{ fontWeight: 700 }}>{brokerageName}</span>
                            {` | `}
                            Application for <b>{companyName}</b>
                        </StyledHeaderTitle>
                    </HeaderForApplication>
                )
            }
            <Formik
                initialValues={initialValues}
                onSubmit={_onSubmit(
                    match.params.applicationID,
                    match.params.appSecurityToken as string,
                    match.params.sectionKey as string,
                    sections,
                    guidelines ?? null,
                    history,
                    setSections,
                    setQuestionsOnError,
                    questionsOnError,
                )}
                validateOnBlur
                validateOnChange={false}
                validateOnMount={false}
                validate={(values: AnswerFormat) => {
                    if (guidelines === null && !showOnlyQuestions) {
                        saveAnswer(match.params.applicationID, values);
                    }
                }}
            >
                {({ values, setSubmitting }) => (
                    <AnswersContext.Provider value={{ context: getAnswersContext(values) }}>
                    {
                        !showOnlyQuestions ? (
                            <ResponsiveContainer
                                className="ie-styled"
                                style={{ flexWrap: "nowrap", padding: "0 10px" }}
                            >
                                <SideApplicationContainer>
                                    <SectionSelector />
                                    <StyledSubmitCTAContainer>
                                        <Button
                                            disabled={sectionsWithError || !!guidelines}
                                            onClick={saveAndReview(
                                                match.params.applicationID,
                                                match.params.appSecurityToken,
                                                sections,
                                                guidelines ?? null,
                                                setSections,
                                                history,
                                                setSubmitting,
                                                values,
                                                setQuestionsOnError,
                                                questionsOnError,
                                            )}
                                        >
                                            Continue to Review
                                        </Button>
                                    </StyledSubmitCTAContainer>
                                </SideApplicationContainer>
                                <StackedPaperPaddedContainer
                                    className={`application-section-questions ${
                                        section?.insuranceLine ? "is-insurance" : ""
                                    } ${section?.isActive ? "enabled" : "disabled"}`}
                                >
                                    <QuestionsStackedPaper>
                                        <Form>
                                            <Switch>
                                                <Route
                                                    exact
                                                    path={applicationSectionPath}
                                                    component={SectionQuestions}
                                                />
                                                <Redirect
                                                    to={`${match.url}/${sections[0].ratingEngineKey}`}
                                                />
                                            </Switch>
                                        </Form>
                                    </QuestionsStackedPaper>
                                </StackedPaperPaddedContainer>
                            </ResponsiveContainer>
                        )
                        :(
                            sections.map((section: any) => {
                                return (
                                    <StackedPaperPaddedContainer
                                        className={`application-section-questions ${
                                            section?.insuranceLine ? "is-insurance" : ""
                                        } ${showOnlyQuestions || section?.isActive ? "enabled" : "disabled"}`}
                                    >
                                        <QuestionsStackedPaper>
                                            <Form>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path={applicationSectionPath}
                                                        render={(routeProps) => (
                                                            <SectionQuestions {...routeProps} SectionRatingEngineKey={section.ratingEngineKey} showOnlyQuestions={showOnlyQuestions} />
                                                        )}
                                                    />
                                                    <Redirect
                                                        to={`${match.url}/${section.ratingEngineKey}?ratingEngineKey=${section.ratingEngineKey}`}
                                                    />
                                                </Switch>
                                            </Form>
                                        </QuestionsStackedPaper>
                                    </StackedPaperPaddedContainer>
                                )
                            })
                        )
                    }
                    {showOnlyQuestions ? (
                        <div>
                            <div
                            style={{
                                position: "fixed",
                                left: "50%",
                                transform: "translateX(-50%)",
                                top: 50,
                                zIndex: 1000,
                            }}
                        >
                            <Button
                                disabled={!!guidelines}
                                onClick={saveAndReview(
                                    match.params.applicationID,
                                    match.params.appSecurityToken,
                                    sections,
                                    guidelines ?? null,
                                    setSections,
                                    history,
                                    setSubmitting,
                                    values,
                                    setQuestionsOnError,
                                    questionsOnError,
                                )}
                                >
                                Save and Run Rating Engine
                            </Button>
                        </div>
                        <div
                        style={{
                            position: "fixed",
                            left: "50%",
                            transform: "translateX(-50%)",
                            bottom: 100,
                            zIndex: 1000,
                        }}
                    >
                        <Button
                            disabled={!!guidelines}
                            onClick={saveAndReview(
                                match.params.applicationID,
                                match.params.appSecurityToken,
                                sections,
                                guidelines ?? null,
                                setSections,
                                history,
                                setSubmitting,
                                values,
                                setQuestionsOnError,
                                questionsOnError,
                            )}
                            >
                            Save and Run Rating Engine
                        </Button>
                    </div>
                    </div>
                    ) : null
                    }
                    </AnswersContext.Provider>
                )}
            </Formik>
            <Modal
                isOpen={modalShareOpen}
                closeable={true}
                title="Save and Share the Application"
                titlePosition="left"
                buttons={[]}
                buttonStyle="vertical"
                closeAction={() => {
                    setModalShareOpen(false);
                    setSendToEmail("");
                }}
            >
                <ShareApplicationDiv>
                    <Body1Span style={{ lineHeight: "150%" }}>
                        {`You can send the application to yourself or share with your colleagues via email. All of the information in the application will be saved so whomever you send it to can pick up where you left off.`}
                    </Body1Span>
                    <div>
                        <FlexComponentParent>
                            <FlexComponentInput>
                                <StyledLabel>Your Name</StyledLabel>
                                <InputField
                                    label=""
                                    value={yourName}
                                    onChange={handleName}
                                    placeholder="e.g. John Smith"
                                    style={{ position: "relative" }}
                                />
                            </FlexComponentInput>
                        </FlexComponentParent>
                    </div>
                    <div>
                        <FlexComponentParent>
                            <FlexComponentInput>
                                <StyledLabel style={{ paddingBottom: 10 }}>Email</StyledLabel>
                                <InputField
                                    label=""
                                    value={sendToEmail}
                                    onChange={handleChange}
                                    placeholder="e.g. name@company.com"
                                    style={{ position: "relative" }}
                                />
                            </FlexComponentInput>

                            <LightBlueButton
                                style={{ marginLeft: 10, marginTop: 30, height: 54 }}
                                onClick={() => {
                                    if (yourName === "") {
                                        setEmailMessage("Please enter your name");
                                        return;
                                    }
                                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    if (
                                        sendToEmail === null ||
                                        sendToEmail === "" ||
                                        !re.test(sendToEmail)
                                    ) {
                                        setEmailMessage("Please enter a valid email");
                                        return;
                                    }
                                    shareApplication(
                                        {
                                            email: sendToEmail,
                                            sendersName: yourName,
                                            appSecurityToken,
                                        },
                                        match.params.applicationID,
                                    );
                                    setEmailMessage("Email Sent!");
                                    setModalShareOpen(false);
                                }}
                            >
                                Send
                            </LightBlueButton>
                        </FlexComponentParent>
                        <div
                            style={{
                                textAlign: "right",
                                position: "absolute",
                                right: "10px",
                                color: emailMessage === "Email Sent!" ? "forestgreen" : "red",
                                display: emailMessage !== "" ? "block" : "none",
                            }}
                        >
                            {emailMessage}
                        </div>
                    </div>
                </ShareApplicationDiv>
            </Modal>
            {!showOnlyQuestions ? <Footer /> : null}
        </StyledPage>
    );
};

export default ApplicationEntry;
