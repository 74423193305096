import React from "react";
import styled, { keyframes, css } from "styled-components";
import SmallLogo from "assets/small_logo.svg";
import { Button } from "components/common";

const StyledButton = styled(Button as any)<{ disabled: boolean, isLoading: boolean }>`
    ${({disabled, isLoading}) => (disabled && isLoading) && css`
        &:disabled {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    `}
    & {
        padding: 0 10px;
        min-height: 50px;
        min-width: 240px;
    }
`;

const LoadingAnimation = keyframes`
    0% {
      transform: scaleX(1);
    }

    50% {
      transform: scaleX(-1);
    }

    100% {
      transform: scaleX(1);
    }
`;

const Image = styled.img`
    animation: ${LoadingAnimation} 1s linear infinite;
`;

const Checkmark = styled.div`
    position: absolute;
    height: 11px;
    width: 14px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    &:before {
        content: "";
        position: absolute;
        background: transparent;
        display: inline-block;
        transform: rotate(45deg);
        height: 12px;
        width: 6px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        bottom: 1;
        left: 4px;
    }
`;

interface Props {
    children: any;
    loading: boolean;
    disabled?: boolean;
    success?: boolean;
    type?: string;
    style?: any;
}

const FormButton: React.FC<Props> = ({ children, loading, disabled, success, ...rest }) => {
    return (
        <StyledButton isLoading={loading} disabled={disabled} {...rest}>
            {success ? (
                <>
                    <Checkmark />
                </>
            ) : (
                <>{loading ? <Image src={SmallLogo} alt="small logo" /> : children}</>
            )}
        </StyledButton>
    );
};

export default FormButton;
